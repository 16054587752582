import React from 'react';
import { TableActions } from '@digi-tim-19/components';
import { changedByColumn } from '../../../components/utils/changedByColumn';

export const columns = [
  {
    title: 'Título da campanha',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'ClientKey',
    dataIndex: 'clientKey',
    key: 'clientKey',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Origem das carteiras',
    dataIndex: 'invoiceOrigin',
    key: 'invoiceOrigin',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text: string) => {
      return <p>{text}</p>;
    }
  },
  {
    title: 'Cadastrado',
    dataIndex: 'cadastrada',
    key: 'cadastrada'
  },
  changedByColumn(),
  {
    title: 'Ações',
    key: 'action',
    render: (text: DataKey, record: any) => (
      <TableActions
        data={record}
        confirmationMessage="Deseja excluir está campanha?"
      />
    )
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
  status: string;
}
