import React from 'react';
import { TableActions } from '@digi-tim-19/components';
import { changedByColumn } from '../../../components/utils/changedByColumn';

export const columns = [
  {
    title: 'Documentos',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Cadastrada',
    dataIndex: 'cadastrada',
    key: 'cadastrada'
  },
  {
    title: 'Publicada',
    dataIndex: 'publicada',
    key: 'publicada'
  },
  {
    title: 'Atualizada',
    dataIndex: 'atualizada',
    key: 'atualizada'
  },
  {
    title: 'Vigente',
    dataIndex: 'validity',
    key: 'validity'
  },
  {
    title: 'Criador',
    dataIndex: 'criador',
    key: 'criador'
  },
  changedByColumn(),
  {
    title: 'Ações',
    key: 'action',
    render: (text: DataKey, record: any) => (
      <TableActions
        data={record}
        confirmationMessage={'Deseja excluir este Documento?'}
      />
    )
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
}
