import { Radio as AntdRadio, Modal as AntdModal, message, Progress } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Questions } from '../Form/QuizAlternatives/QuizAlternativesField';
import { useClient } from '../../../autogenerated/client/client';
import { EnumAnalyticsEvent } from '../../../autogenerated/client/types';
import { LoadingIndicator } from '@digi-tim-19/components';
import { useAnalytics } from '@digi-tim-19/components';

interface Props {
  quizTitle?: string;
  visible?: boolean;
  questions?: Questions[];
  userChallenge: {
    _id: string;
  };
  loading?: boolean;
  onFinish: (refetchAfter: boolean) => void;
}

interface AnswerInterface {
  questionId?: string;
  alternativeIdSelected?: string;
}

interface AlternativeInterface {
  id: string;
  description?: string;
  isCorrect?: Boolean;
}
interface QuestionInterface {
  id?: string;
  description?: string;
  isCorrect?: Boolean;
  index?: number;
  alternatives?: AlternativeInterface[];
}

interface UserChallengeData {
  restarted: boolean;
  attempts: number;
  questions: QuestionInterface[];
  hasAttempts: boolean;
  lastAttemptHits: number;
  completed: boolean;
  win: boolean;
  challengeId?: string;
  answers?: AnswerInterface[];
}

const QuestionContainer = styled.div`
  width: 100%;
`;

const QuestionDescription = styled.p`
  margin: 10px 0px 5px 0px;
  font-weight: bold;
`;

const AnswerDescription = styled.p`
  text-transform: capitalize;
  font-weight: bold;
  margin: 0;
`;

const AnswerResponse = styled.p`
  display: inline;
`;

const AnswerResponseResult = styled.span<{ isCorrect: any }>`
  font-weight: bold;
  color: ${(props: any) => (props.isCorrect ? 'green' : 'red')};
`;

const Results = styled.div`
  text-align: start;
  margin: 10px 0px;
`;

const Radio = styled(AntdRadio)`
  display: block;
  padding: 8px;
  white-space: break-spaces !important;
  flex: 1;
`;

const RadioContainer = styled.div`
  display: flex;
  width: 100% !important;
  align-items: center;
  align-content: space-between;
  flex-wrap: wrap;
  &:hover {
    background-color: #ededed;
    border-radius: 10px;
  }
`;

const Modal = styled(AntdModal)`
  .ant-modal-body {
    max-height: 350px !important;
    padding: 8px 24px !important;
    overflow: auto;
  }

  .ant-radio-group {
    margin-top: 10px;
    width: 100% !important;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-center: center;
`;

const MessageContainer = styled.div`
  width: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ResultContainer = styled.div`
  width: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const ResultDescription = styled.p`
  margin: 10px 0;
`;

export const ModalQuizChallenge = ({
  userChallenge,
  quizTitle = 'Quiz',
  visible = false,
  questions = [],
  onFinish,
  loading = false,
}: Props) => {
  const analytics = useAnalytics();
  const getUserChallenge = useClient('UserChallengeFindById', {
    variables: { _id: userChallenge?._id },
    fragment: `
      challengeId
      userChallengeData
      challengeData {
        _id
        challengeData
      }`,
  });
  const updateUserChallengeAnwser = useClient('UserChallengeQuizAnswers');
  const updateUserChallenge = useClient('UserChallengeUpdateById');
  const [answers, setAnswers] = React.useState<AnswerInterface[]>([]);
  const [load, setLoad] = React.useState<boolean>(false);
  const [userChallengeData, setUserChallengeData] = React.useState<UserChallengeData>({
    restarted: true,
    attempts: 0,
    questions: [],
    hasAttempts: true,
    lastAttemptHits: 0,
    completed: false,
    win: false,
  });

  const addSelected = (alternativeIdSelected: string, questionId: string) => {
    setAnswers((prevState) => {
      const filtered = [...prevState].filter((answer) => answer.questionId !== questionId);
      return [
        ...filtered,
        {
          questionId,
          alternativeIdSelected,
        },
      ];
    });
  };

  const handlePercentage = (total: number, progress: number) => {
    if ((progress === 0 && total === 0) || total === 0) return 0;
    return Math.floor((progress / total) * 100);
  };

  const getAnswersAndQuestion = (questions: QuestionInterface[], answers: AnswerInterface[]): QuestionInterface[] => {
    if (answers) {
      let questionsData = questions;
      questionsData = questionsData.map((question: QuestionInterface, i: number) => {
        const awswer = answers.filter((answers: AnswerInterface) => answers.questionId === question.id)[0];
        const title = question.description;
        const isCorrect = question.alternatives?.filter(
          (alternative: AlternativeInterface) => alternative.id === awswer?.alternativeIdSelected,
        )[0]?.isCorrect;
        return {
          id: question.id,
          index: i + 1,
          description: title,
          isCorrect: Boolean(isCorrect),
        };
      });
      return questionsData;
    } else {
      return [];
    }
  };

  const saveAnwsers = (questionAnswers: AnswerInterface[]) => {
    setLoad(true);
    updateUserChallengeAnwser
      .fetch({
        appendToFragment: `data`,
        variables: {
          userChallengeId: userChallenge._id,
          answers: questionAnswers,
        },
      })
      .then((response) => {
        if (response?.result?.data) {
          let questionsData = getAnswersAndQuestion(
            response.result.data.challenge.questions,
            response?.result?.data?.answers,
          );

          setUserChallengeData({ ...response?.result?.data, questions: questionsData });
          if (response?.result?.data?.win || response?.result?.data?.completed) {
            finish(userChallenge._id, response?.result?.data, questionAnswers);
          }
        }
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
        message.error('Não foi possível registrar a resposta do quiz.');
      });
  };

  const getLabelOkButton = (userChallengeData: any) => {
    if (userChallengeData.hasAttempts && !userChallengeData.restarted && !userChallengeData.win) {
      return 'REINICIAR QUIZ';
    }

    return 'RESPONDER';
  };

  const restartQuiz = (_id: string, userChallengeData: any) => {
    updateUserChallenge
      .fetch({
        variables: {
          record: {
            _id,
            userChallengeData: {
              ...userChallengeData,
              restarted: true,
              answers: [],
            },
          },
        },
      })
      .then(() => {
        setUserChallengeData((prevState: any) => {
          return {
            ...prevState,
            restarted: true,
            answers: [],
          };
        });
        setAnswers([]);
      })
      .catch(() => {
        return message.error('Não foi possível reiniciar o quiz.');
      });
  };

  const finish = (_id: string, userChallengeData: any, answers: AnswerInterface[]) => {
    let record: any = {
      _id,
      progress: 1,
      userChallengeData: {
        ...userChallengeData,
        answers,
      },
      acceptDate: new Date(Date.now() - 108e5),
    };

    updateUserChallenge
      .fetch({
        variables: { record },
      })
      .then(() => {
        analytics.track({
          event: EnumAnalyticsEvent.Quiz,
          kind: EnumAnalyticsEvent.Quiz,
          entityName: 'UserChallenge',
          recordId: userChallenge._id,
          singleDoc: true,
        });
      });
  };

  React.useEffect(() => {
    if (visible) {
      setLoad(true);
      getUserChallenge.fetch().then((response) => {
        if (response?.result?.userChallengeData) {
          let questionsData = getAnswersAndQuestion(
            response?.result?.challengeData?.challengeData.questions,
            response?.result?.userChallengeData?.answers,
          );

          setUserChallengeData({
            questions: questionsData,
            restarted: Boolean(response?.result?.userChallengeData?.restarted),
            attempts: response?.result?.userChallengeData?.attempts || 0,
            hasAttempts: Boolean(response?.result?.userChallengeData?.hasAttempts),
            lastAttemptHits: response?.result?.userChallengeData?.lastAttemptHits || 0,
            completed: Boolean(response?.result?.userChallengeData?.completed),
            win: Boolean(response?.result?.userChallengeData?.win),
          });
        }

        if (!response.errors) setLoad(false);
      });
    }
  }, [visible]);

  return (
    <Modal
      title={quizTitle}
      visible={visible}
      okButtonProps={{
        disabled: !userChallengeData.hasAttempts,
        hidden: !userChallengeData.hasAttempts || userChallengeData.win,
      }}
      cancelText="FECHAR"
      okText={getLabelOkButton(userChallengeData)}
      confirmLoading={
        getUserChallenge.loading || updateUserChallenge.loading || updateUserChallengeAnwser.loading || loading || load
      }
      onOk={async () => {
        if (userChallengeData.hasAttempts && !userChallengeData.restarted && !userChallengeData.win) {
          restartQuiz(userChallenge._id, userChallengeData);
          return;
        }

        if (answers.length < questions.length || answers.length === 0) {
          return message.error('Por favor, responda todas as questões.');
        }

        saveAnwsers([...answers]);
      }}
      onCancel={() => onFinish(userChallengeData.win || userChallengeData.completed)}
    >
      {getUserChallenge.loading || updateUserChallengeAnwser.loading || updateUserChallenge.loading || load ? (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      ) : (
        <React.Fragment>
          {!userChallengeData.hasAttempts && !userChallengeData.win && (
            <ResultContainer>
              <Progress type="circle" percent={handlePercentage(questions.length, userChallengeData.lastAttemptHits)} />
              <ResultDescription>
                {userChallengeData.lastAttemptHits} de {questions.length}
              </ResultDescription>
              <ResultDescription>
                Você não atingiu a nota suficiente e não há mais tentativas para concluir este quiz.
              </ResultDescription>
            </ResultContainer>
          )}
          {userChallengeData.win && (
            <ResultContainer>
              <Progress type="circle" percent={handlePercentage(questions.length, userChallengeData.lastAttemptHits)} />
              <ResultDescription>
                Você acertou {userChallengeData.lastAttemptHits} de {questions.length}
              </ResultDescription>
              <ResultDescription>Você concluiu o quiz com sucesso!</ResultDescription>
            </ResultContainer>
          )}
          {userChallengeData.hasAttempts && !userChallengeData.restarted && !userChallengeData.win && (
            <ResultContainer>
              <Progress type="circle" percent={handlePercentage(questions.length, userChallengeData.lastAttemptHits)} />
              <ResultDescription>
                {userChallengeData.lastAttemptHits} de {questions.length}
              </ResultDescription>
              <ResultDescription>
                Resultado:
                {userChallengeData.questions &&
                  userChallengeData?.questions?.map((question) => (
                    <Results>
                      <AnswerDescription>
                        {question.index} - {question.description}
                      </AnswerDescription>
                      <AnswerResponse>
                        Resposta:{' '}
                        <AnswerResponseResult isCorrect={question.isCorrect}>
                          {question.isCorrect ? 'Correta' : 'Incorreta'}
                        </AnswerResponseResult>
                      </AnswerResponse>
                    </Results>
                  ))}
              </ResultDescription>
              <ResultDescription>
                Você não atingiu a nota suficiente para concluir este quiz, mas você ainda tem algumas tentativas.
              </ResultDescription>
            </ResultContainer>
          )}
          {userChallengeData.hasAttempts && userChallengeData.restarted && !userChallengeData.win && (
            <React.Fragment>
              {questions.map((question) => {
                return (
                  <QuestionContainer key={`question-quiz-${question.id}`}>
                    <QuestionDescription>{question.description}</QuestionDescription>
                    <Radio.Group
                      onChange={(e: any) => addSelected(e.target.value, question.id)}
                      value={
                        answers.find((el: AnswerInterface) => el.questionId === question.id)?.alternativeIdSelected
                      }
                    >
                      {question?.alternatives?.map((alternative) => {
                        return (
                          <RadioContainer>
                            <Radio key={`quiz-alternative-${alternative.id}`} value={alternative.id}>
                              {alternative.description}
                            </Radio>
                          </RadioContainer>
                        );
                      })}
                    </Radio.Group>
                  </QuestionContainer>
                );
              })}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Modal>
  );
};
