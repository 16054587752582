import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { MapaDeOfertasWrapper } from './stylesCadastrar';
import { routes } from '../../../config/routes';

export const MapaDeOfertasCadastrar = () => {
  return (
    <PageTemplate>
      <>
        <MapaDeOfertasWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Mapa de Ofertas' },
              { title: 'Cadastrar' }
            ]}
          />
          <TitleDescription
            iconType="environment"
            title="Mapa de Ofertas"
            description="Cadastramento e consulta de mapas de oferta."
          />
        </MapaDeOfertasWrapper>

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
