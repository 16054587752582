import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 20px 15px;
  margin-bottom: 25px;
  .ant-upload-list {
    position: absolute;
    bottom: -25px;
  }
  .ant-select-selection__placeholder {
    left: 9px;
  }

  .name-availableForUsers {
    margin-bottom: 15px !important;

    .ant-select {
      height: 170px;
    }
    .ant-select-selection--multiple {
      height: 170px !important;
      overflow: auto;
    }
  }

  .name-undefined {
    justify-content: flex-start !important;
  }

  .download-attachment {
    margin: 10px;
  }

  .Footer {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    .FieldWrapper:nth-of-type(1) {
      max-width: 210px;
      margin-right: 15px;
    }
    .FieldWrapper:nth-of-type(2) {
      max-width: 210px;
      margin-right: 15px;
      position: relative;
    }
  }
  .FieldWrapper:nth-of-type(1) {
    align-items: center !important;
    justify-content: flex-start;
    flex-wrap: wrap;

    .name-categories {
      margin-left: 15px;
    }
  }
  .name-status {
    margin-left: 15px;
  }
  .name-templateName {
    .ant-radio-group {
      height: auto;
      img {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
        margin-top: 15px;
      }
      p {
        display: none;
      }
    }
    .ant-radio-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .name-documento {
    max-width: 30% !important;
    justify-content: flex-end !important;
    > span {
      display: flex;

      .anticon-close {
        right: -2px;
      }
    }
  }
  .name-mailing {
    max-width: 210px !important;
    width: 100% !important;
    margin-top: -13px;
    span,
    button,
    div {
      width: 100%;
    }
    button.ant-btn {
      background: #00508c;
      color: white;
      max-width: 210px !important;
      width: 100% !important;
    }
  }
  .name-mailing {
    max-width: 210px !important;
    width: 100% !important;
    span,
    button,
    div {
      width: 100%;
    }
    button.ant-btn {
      background: ${({ theme }) => theme.red};
      color: white;
      max-width: 210px !important;
      width: 100% !important;
    }
  }

  .name-mailing .component-upload {
    margin-top: 26px;
  }
`;
export const Divider = styled.hr`
  border: none;
  background: #ccc;
  height: 1px;
  width: 100%;
  margin: 20px 0;
`;
export const InformativosWrapper = styled.div`
  width: 100%;
`;
