import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';

import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { TabelaPrecoWrapper } from './stylesCadastrar';

export const VejaMaisInstitucionalCadastrar = () => {
  return (
    <PageTemplate>
      <>
        <TabelaPrecoWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Veja Mais' },
              { title: 'Institucional', link: routes.institucionalListar.mount() },
              { title: 'Cadastrar' }
            ]}
          />
          <TitleDescription
            iconType="more"
            title="Institucional"
            description="Cadastrar os documentos específicos do seu institucional."
          />
        </TabelaPrecoWrapper>

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
