import React, { useState } from 'react';
import MaskedInput from 'antd-mask-input';
import styled from 'styled-components';
import {
  Breadcrumb,
  LoadingIndicator,
  Modal,
  TitleDescription,
  useClient
} from '@digi-tim-19/components';
import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { PageWrapper } from '../Processos/Listar/stylesGerenciar';
import { Button as AntButton } from 'antd';
import { onlyNumbers } from '@digi-tim-19/utils/build';

export class LogsResult {
  public success: string[] = [];
  public erros: string[] = [];
}

const Wrapper = styled.div`
  margin-bottom: 40px;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const Button = styled(AntButton)`
  border: none;
  background-color: ${({ theme }) => theme.blue};
  display: flex;
  justify-content: center;
  color: white;
  padding: 5px;
  border-radius: 4px;
  max-width: 250px;
  cursor: pointer;
  outline: none;
  margin-left: 15px;
  &:hover {
    background: ${({ theme }) => theme.red};
    outline: none !important;
    color: white;
  }
`;

export const Search = styled(MaskedInput)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

const configPage = {
  pageName: 'Sincronização GU',
  icon: 'schedule',
  description: 'Sincronizar usuários GU',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Admin Digi' },
    { title: 'Sincronizar usuários GU' }
  ]
};

export const SincronizarGU = () => {
  const [cpfSearch, setCpfSearch] = useState<string>();
  const [openResultModal, setOpenResultModal] = useState<boolean>(false);
  const [sincronize, setSincronize] = useState<LogsResult>(new LogsResult());

  const sendSincronize = useClient('SincronizeUserGU');

  const loading = sendSincronize.loading;

  const handleSincronize = () => {
    sendSincronize
      .fetch({
        variables: {
          CPFS: [cpfSearch]
        }
      })
      .then((ctx) => {
        setSincronize(ctx.result);
        setOpenResultModal(true);
      });
  };

  return (
    <PageTemplate>
      <>
        <PageWrapper>
          <Breadcrumb items={configPage.breadcrumb} />

          <TitleDescription
            iconType={configPage.icon}
            title={configPage.pageName}
            description={configPage.description}
          />

          <Modal
            title="Logs Sincronização"
            visible={openResultModal}
            onCancel={() => setOpenResultModal(false)}
            footer={[
              <Button type="primary" onClick={() => setOpenResultModal(false)}>
                Fechar
              </Button>
            ]}
          >
            <div>
              {sincronize.success.map((description, index) => (
                <p key={`sincronize-success - ${index}`}>{description}</p>
              ))}
              {sincronize.erros.map((description, index) => (
                <p key={`sincronize-error - ${index}`} style={{ color: 'red' }}>
                  {description}
                </p>
              ))}
            </div>
          </Modal>

          <Wrapper>
            <Search
              mask="111.111.111-11"
              placeholder="Digite o CPF"
              style={{ maxWidth: 250 }}
              value={cpfSearch}
              onChange={({ target }) => setCpfSearch(onlyNumbers(target.value))}
            />
            <Button disabled={loading} onClick={handleSincronize}>
              SINCRONIZAR
            </Button>
            {loading && <LoadingIndicator />}
          </Wrapper>
        </PageWrapper>
      </>
    </PageTemplate>
  );
};
