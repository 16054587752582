import React from 'react';
import { TableActions } from '@digi-tim-19/components';

export const columns = [
  {
    title: 'Título da campanha',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Protocolo',
    dataIndex: 'protocol',
    key: 'protocol',
    render: (text: string) => {
      return <p>{text}</p>;
    }
  },
  {
    title: 'Solicitado por',
    dataIndex: 'owner',
    key: 'owner'
  },
  {
    title: 'Canal',
    dataIndex: 'channel',
    key: 'channel'
  },
  {
    title: 'Regional',
    dataIndex: 'region',
    key: 'region'
  },
  {
    title: 'Cargo',
    dataIndex: 'role',
    key: 'role'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text: string) => <p style={{ color: 'red' }}>{text}</p>
  },
  {
    title: '',
    key: 'action',
    render: (_text: DataKey, record: any) => <TableActions data={record} />
  }
];

export interface DataKey {
  key: string;
  name: string;
  status: string;
}
