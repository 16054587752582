import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { useParams } from 'react-router';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { Form } from '../Form';
import { NotificationsWrapper, Container } from '../stylesForm';
import { useMaterial } from '../../../hooks/materials/useMaterial';
import { routes } from '../../../config/routes';

const appendToFragment = `
    title
    fileIds
    files {
      _id
      title
      kind
    }
    validity {
      start,
      end
    }
    categories {
      name
      parentId
      _id
    }
    status {
      _id
      value
    }
    availableAtRegions
    availableAtChannels
    availableAtRoleGroups
  `;

export const NotificacaoBackupEditar = () => {
  const { id } = useParams();
  const notificacaoItem = useMaterial(id!, appendToFragment);
  return (
    <PageTemplate>
      <>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              {
                title: 'Gerenciar conteúdo',
                link: routes.notificacaoBackupListar.mount()
              },
              {
                title: 'Notificações de backup',
                link: routes.notificacaoBackupListar.mount()
              },
              { title: 'Editar Notificação de backup' }
            ]}
          />
          <TitleDescription
            iconType="bell"
            title="Notificação de backup"
            description="Edição de notificação de backup"
          />
        </NotificationsWrapper>
        <h3>Editar Notificação de backup</h3>

        <Container>
          {notificacaoItem.result && (
            <ProviderSelecionarPublico>
              <Form initialValues={notificacaoItem.result} />
            </ProviderSelecionarPublico>
          )}
        </Container>
      </>
    </PageTemplate>
  );
};
