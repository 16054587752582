import { useClient, useCurrentUser } from '@digi-tim-19/components';
import { usePagination } from '../../../hooks/usePagination';
import { SortFindManyUserInput, FilterFindManyUserInput } from '../../../autogenerated/client/types';
import { useEffect, useMemo, useState } from 'react';

const rankinfOrderOptions = [
  {
    value: SortFindManyUserInput.RankingQtdChallengeDesc,
    label: 'Desafios Completos',
  },
  {
    value: SortFindManyUserInput.RankingLastCompletedChallengeDesc,
    label: 'Data do último desafio completo',
  },
  {
    value: SortFindManyUserInput.RankingLastLoginDesc,
    label: 'Login Mais Recente',
  },
  {
    value: SortFindManyUserInput.RankingLoginStreakChallengeDesc,
    label: 'Logins Consecutivos',
  },
];

const channels = [
  { value: 'todos', label: 'TODOS OS CANAIS' },
  { value: 'smb', label: 'SMB' },
  { value: 'mass_channel', label: 'MASS CHANNEL' },
  { value: 'lojas_tim', label: 'LOJAS TIM' },
  { value: 'varejo', label: 'VAREJO' },
  { value: 'retail', label: 'RETAIL' },
  // { value: 'trade', label: 'TRADE' },
];

const levels = [
  { value: undefined, label: 'TODOS OS NÍVEIS' },
  { value: 1, label: '3G' },
  { value: 2, label: '4G' },
  { value: 3, label: '5G' },
];

const regions = [
  { label: 'TODAS AS REGIONAIS', value: '' },
  { label: 'TCN', value: 'TCN' },
  { label: 'THQ', value: 'THQ' },
  { label: 'TNE', value: 'TNE' },
  { label: 'TSE', value: 'TSE' },
  { label: 'TSL', value: 'TSL' },
  { label: 'TSP', value: 'TSP' },
];

export const useRanking = () => {
  const user = useCurrentUser();
  const isTrade = !!user.result?.channels && user.result?.channels[0] === 'trade';
  const [filter, setFilter] = useState<FilterFindManyUserInput>({
    region: user.result?.region,
    channel: isTrade ? 'todos' : user.result?.channels,
  });
  const [sort, setRankingType] = useState<SortFindManyUserInput>(SortFindManyUserInput.RankingQtdChallengeDesc);

  const resetFilters = () => {
    setFilter({
      region: user.result?.region,
      channel: isTrade ? 'todos' : user.result?.channels,
    });
    setRankingType(SortFindManyUserInput.RankingLoginStreakChallengeDesc);
  };

  const {
    loading: positionLoading,
    result: positionResult,
    fetch,
  } = useClient('MyRankingPosition', {
    variables: { filter, sort },
    fragment: `
      myPosition
      rankingSlice {
        _id
        name
        region
        channels
        gamefication {
          chips
        }
        avatar {
          signedUrl
        }
        isSelf
      }
    `,
    fetchOnMount: !!user.result,
  });

  const { loading, parsed, pagination, updateFetch } = usePagination('UserPagination', {
    initial: {
      page: 1,
      perPage: 5,
      filter: { region: user.result?.region, channel: isTrade ? 'todos' : user.result?.channels, active: true },
      sort,
    },
    itemsFragment: `
      _id
      name
      region
      channels
      gamefication {
        chips,
        nivel
      }
      userLevel
      avatar {
        signedUrl
      }
      isSelf
    `,
    parseResult: (users, pag) =>
      users.map((el, i) => {
        const positionOnPage = i + 1;
        const previousPositions = ((pag?.current ?? 1) - 1) * (pag?.pageSize ?? 0);
        const position =  previousPositions + positionOnPage;
        return {
          _id: el._id,
          userContent: { ...el, position },
          isSelf: !!el.isSelf,
        };
      }),
    fetchOnMount: !!user.result,
  });

  useEffect(() => {
    updateFetch((state) => ({
      ...state,
      filter: { ...state.filter, ...filter },
      sort,
    }));

    fetch({ variables: { filter, sort } });
  }, [filter, sort]);

  const rankingSlice = useMemo(() => {
    const result = positionResult?.rankingSlice?.map((el: any, i: number) => {
      return {
        _id: el._id,
        userContent: { ...el, name: user?.result?._id !== el._id ? '_ _ _ _' : el.name },
        position:
          positionResult?.myPosition === 1 ? i + positionResult?.myPosition : i + (positionResult?.myPosition - 1),
      };
    });

    return result;
  }, [positionResult]);

  return {
    ranking: parsed,
    loading: loading || positionLoading,
    pagination,
    filter,
    setFilter,
    resetFilters,
    rankingType: sort,
    setRankingType,
    rankingSlice,
    rankinfOrderOptions,
    channels,
    regions,
    levels
  };
};
