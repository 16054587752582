import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { NotificationsWrapper, Container } from '../stylesForm';
import { Form } from '../Form';
import { routes } from '../../../config/routes';

export const CadastrarNotificacao = () => {
  return (
    <PageTemplate>
      <>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Notificações' },
              { title: 'Cadastrar Notificação' }
            ]}
          />
          <TitleDescription
            iconType="bell"
            title="Notificação"
            description="Cadastramento e consulta de notificações"
          />
        </NotificationsWrapper>
        <h3>Cadastrar Notificação</h3>

        <Container>
          <ProviderSelecionarPublico>
            <Form />
          </ProviderSelecionarPublico>
        </Container>
      </>
    </PageTemplate>
  );
};
