import { getRouterByMaterial, clickDownload } from '@digi-tim-19/utils/build';
import {
  EnumFileKind,
  Material,
  EnumMaterialKind,
  CommunicationKindFilter
} from '../autogenerated/client/types';
import { colors } from '@digi-tim-19/theme';
import { ActionButtonOptions } from '@digi-tim-19/components/build/main/ActionButton/MaterialActionButton';

export function acessarMaterial(material: any, history: any, routes: any) {
  const routesMaterial = getRouterByMaterial(material, routes);
  if (routesMaterial.routerAccess) {
    history.push(routesMaterial.routerAccess);
  } else if (routesMaterial.routerDownload) {
    clickDownload(routesMaterial.routerDownload);
  }
  return routesMaterial;
}

export function obterImagemPadraoPorMaterial(item: any, isList?: boolean) {
  let thumb = '';

  const imageFile = item?.files.find((file: any) => {
    if (!file.signedUrl) return;
    return (
      file.kind === EnumFileKind.CardThumb ||
      file.kind === EnumFileKind.VideoThumb
    );
  });

  if (imageFile?.signedUrl) return imageFile.signedUrl;

  switch (item?.kind) {
    case EnumMaterialKind.Regulation:
      thumb = '/defaultImages/Thumb-regulamento_C.png';
      break;
    case EnumMaterialKind.Process:
      if (isList) thumb = '/defaultImages/Thumb-PROCESSOS_C.png';
      else thumb = '/defaultImages/Thumb-PROCESSOS_C_card.png';
      break;
    case EnumMaterialKind.Video:
      thumb = '/defaultImages/Thumb-LIVE_C.png';
      break;
    case EnumMaterialKind.SupportMaterials:
      thumb = '/defaultImages/Thumb-MATERIAL-APOIO_C.png';
      break;
    case EnumMaterialKind.SalesArgument:
      thumb = '/defaultImages/Thumb-argumentacao_C.png';
      break;
    case EnumMaterialKind.OfferMap:
      thumb = '/defaultImages/Thumb-MAPA_C.png';
      break;
    case EnumMaterialKind.SeeMoreChannel:
      thumb = '/defaultImages/thumb-canal.jpeg';
      break;
    case EnumMaterialKind.Pilulas:
      thumb = '/defaultImages/Regional_Pilula.png';
      break;
    case EnumMaterialKind.MateriaisParaOPdv:
      thumb = '/defaultImages/Regional_Materiais.png';
      break;
    case EnumMaterialKind.Matinais:
      thumb = '/defaultImages/Regional_Matinais.png';
      break;
    case EnumMaterialKind.SeeMoreRegion:
      thumb = '/defaultImages/thumb-regional.jpeg';
      break;
    case EnumMaterialKind.PriceTable:
      thumb = '/defaultImages/thumb-tabela-precos.jpeg';
      break;
    case EnumMaterialKind.Communication:
      if (
        item?.communicationItemsConfig?.kind ===
        CommunicationKindFilter.Notification
      )
        thumb = '/defaultImages/image_card.png';
      else thumb = '/defaultImages/Thumb-Informativos_C.png';
      break;
    case EnumMaterialKind.PartsMap:
      thumb = '/defaultImages/thumb-mapa-de-pecas.jpeg';
      break;
    case EnumMaterialKind.DevicesComparator:
      thumb = '/defaultImages/thumb-comparativo-aparelhos.jpeg';
      break;
    case EnumMaterialKind.Tanamao:
      thumb = '/defaultImages/thumb-ta-na-mao.jpeg';
      break;
    default:
      thumb = '/defaultImages/thumb-ta-na-mao.jpeg';
      break;
  }

  return thumb;
}

export function actionsCardConsumer(
  material: Material,
  routes: any,
  history: any,
  listFolders: any,
  addFolder: (
    name: string,
    cb: (error: null | string, result?: any) => any
  ) => any,
  showButtonDownload?: boolean
): ActionButtonOptions {
  const routesMaterial = getRouterByMaterial(material, routes);

  const { routerAccess, routerEdit } = routesMaterial;

  const isOfferMap = !routerEdit.includes('mapa-de-oferta');

  const accessBTN = {
    pinFolder: {
      listFolders: listFolders,
      actionAddFolder: addFolder
    },
    share: true,
    download: showButtonDownload && isOfferMap,
    accessButton: getAccessButon(material, routes, history)
  };

  const downloadBTN = {
    pinFolder: {
      listFolders: listFolders,
      actionAddFolder: addFolder
    },
    share: true,
    download: showButtonDownload && isOfferMap
  };

  return routerAccess ? accessBTN : downloadBTN;
}

export function actionsButton(
  listFolders: any,
  addFolder: (
    name: string,
    cb: (error: null | string, result?: any) => any
  ) => any
): ActionButtonOptions {
  return {
    pinFolder: {
      listFolders: listFolders,
      actionAddFolder: addFolder
    },
    share: true,
    pin: false
  };
}

export function actionsButtonFavorites(
  material: Material,
  routes: any,
  history: any,
  listFolders: any,
  addFolder: (
    name: string,
    cb: (error: null | string, result?: any) => any
  ) => any
): ActionButtonOptions {
  return {
    pinFolder: {
      listFolders: listFolders,
      actionAddFolder: addFolder
    },
    folder: {
      listFolders: listFolders
    },
    accessButton: getAccessButon(material, routes, history),
    share: true,
    pin: false
  };
}

function getAccessButon(material: Material, routes: any, history: any) {
  const routesMaterial = getRouterByMaterial(material, routes);
  const { routerAccess, routerDownload } = routesMaterial;

  return {
    label: !!routerAccess ? 'ACESSAR' : 'DOWNLOAD',
    action: (materialAction: any) => {
      if (routerAccess) {
        history.push(routerAccess);
      } else if (routerDownload) {
        clickDownload(routerDownload);
      }
    },
    extraProps: {
      style: { background: colors.blue, borderColor: colors.blue }
    }
  };
}
