import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { Button } from 'antd';
import { colors } from '@digi-tim-19/theme';

const RecoverPasswordWrapper = styled.div`
  width: 100%;
  justify-content: flex-end;
`;

export const RecoveryPassword = (props: TRecoverPasswordProps) => {
  const { className } = props;

  return (
    <RecoverPasswordWrapper
      className={cx(
        className,
        'ui-flex RecoverPassword RecoverPassword_Wrapper'
      )}
    >
      <Button
        type={'link'}
        style={{
          color: colors.white
        }}
        onClick={props.onClick}
      >
        {props.children || 'Esqueci a senha'}
      </Button>
    </RecoverPasswordWrapper>
  );
};

// export default RecoverPassword;

export type TRecoverPasswordProps = {
  className?: string;
  onClick: () => void;
  children?: React.ReactNode;
};
