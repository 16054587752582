import React, { useEffect } from 'react';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { useParams } from 'react-router';

import { isImageExtension } from '@digi-tim-19/utils';
import styled from 'styled-components';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { ShareModalButton } from '@digi-tim-19/components';

const P = styled.p`
  margin-left: 10px;
  font-size: 30px;
  line-height: 37px;
  font-family: TimLight, fantasy;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #00508c;
`;

const ImageAction = styled.div`
  float: right;
`;

export const ImageViewer = () => {
  const { id } = useParams();
  const getMaterials = useClient('MaterialFindOne', {
    appendToFragment: `     
      files {
        _id
        title
        kind
        signedUrl
        extension
      }
    `,
    fetchOnMount: true
  });

  useEffect(() => {
    getMaterials.fetch({
      variables: {
        filter: {
          _id: id
        }
      }
    });
  }, [id]);
  return (
    <PageTemplate>
      <div>
        {getMaterials.result?.files?.map((item) => {
          if (isImageExtension(item?.extension)) {
            return (
              <>
                <TransformWrapper initialScale={1}>
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                      <ImageAction>
                        <button onClick={() => zoomIn()}>+</button>
                        <button onClick={() => zoomOut()}>-</button>
                        <button onClick={() => resetTransform()}>x</button>
                      </ImageAction>
                      <TransformComponent wrapperStyle={{ margin: 'auto' }}>
                        <P>{item?.title}</P>
                        <img src={item?.signedUrl || ''} />
                      </TransformComponent>
                    </React.Fragment>
                  )}
                </TransformWrapper>
              </>
            );
          }
        })}

        {getMaterials?.result && <ShareModalButton material={{
          _id: getMaterials?.result._id
        }} />}
      </div>
    </PageTemplate>
  );
};
