import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { PageWrapper } from './styles';
import { Breadcrumb, JoystickIconSVG, TitleDescription } from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { FormGameficationNivel } from '../Form/Form';
import { ContainerIcon } from '../../Gamefication/List/styles';

export const GameficationNivelCreate = () => {
  const configPage = {
    pageName: 'Gamificação (Níveis)',
    icon: 'bar-chart',
    description: 'Cadastramento e consulta de níveis da gamificação',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo', link: routes.home.mount() },
      { title: 'Gamificação (Níveis)', link: routes.gameficationNivelList.mount() },
      { title: 'Cadastrar', link: routes.gameficationNivelCreate.mount() },
    ],
  };

  return (
    <PageTemplate>
      <React.Fragment>
        <PageWrapper>
          <Breadcrumb items={configPage.breadcrumb} />
          <TitleDescription
            renderCustomIcon={
              <ContainerIcon>
                <JoystickIconSVG />
              </ContainerIcon>
            }
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>
        <FormGameficationNivel />
      </React.Fragment>
    </PageTemplate>
  );
};
