import { parseFragmentConfig, OpKind, useClientFactory } from 'graphql-clientgen';
import { Methods } from './types';

export const query = {
  CampaignContestationFindOne: (config?: any) => `
        query CampaignContestationFindOne($filter: FilterFindOneCampaignContestationInput, $skip: Int, $sort: SortFindOneCampaignContestationInput){
            CampaignContestationFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `protocol
                        indicator
                        message
                        owner
                        parentId
                        fileIds
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  CampaignContestationPagination: (config?: any) => `
        query CampaignContestationPagination($page: Int, $perPage: Int, $filter: FilterFindManyCampaignContestationInput, $sort: SortFindManyCampaignContestationInput){
            CampaignContestationPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  IncentiveCampaignFindMany: (config?: any) => `
        query IncentiveCampaignFindMany($filter: FilterFindManyIncentiveCampaignInput, $skip: Int, $limit: Int, $sort: SortFindManyIncentiveCampaignInput){
            IncentiveCampaignFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        reserveId
                        owner
                        title
                        category
                        kind
                        schedule
                        description
                        videoLink
                        enableSocialInteraction
                        cardImage
                        contestationPeriod
                        bannerContent
                        ranking
                        results
                        regulation
                        presentation
                        invoiceOrigin
                        walletId
                        channel
                        subChannel
                        campaignType
                        kpis
                        campaignParams
                        boosters
                        participantList
                        highlightedRejected
                        campaignPeriod
                        periodOption
                        faq
                        highlighted
                        fileIds
                        externalCampaign
                        externalId
                        _id
                        updatedAt
                        createdAt
                        validityEnum
                        pinned
                        rankingData
                        hasData
                        viewMode
                        pendingAccept`,
                  config,
                )}
            }
        }`,

  IncentiveCampaignPagination: (config?: any) => `
        query IncentiveCampaignPagination($page: Int, $perPage: Int, $filter: FilterFindManyIncentiveCampaignInput, $sort: SortFindManyIncentiveCampaignInput){
            IncentiveCampaignPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  IncentiveCampaignFindOne: (config?: any) => `
        query IncentiveCampaignFindOne($filter: FilterFindOneIncentiveCampaignInput, $skip: Int, $sort: SortFindOneIncentiveCampaignInput){
            IncentiveCampaignFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        reserveId
                        owner
                        title
                        category
                        kind
                        schedule
                        description
                        videoLink
                        enableSocialInteraction
                        cardImage
                        contestationPeriod
                        bannerContent
                        ranking
                        results
                        regulation
                        presentation
                        invoiceOrigin
                        walletId
                        channel
                        subChannel
                        campaignType
                        kpis
                        campaignParams
                        boosters
                        participantList
                        highlightedRejected
                        campaignPeriod
                        periodOption
                        faq
                        highlighted
                        fileIds
                        externalCampaign
                        externalId
                        _id
                        updatedAt
                        createdAt
                        validityEnum
                        pinned
                        rankingData
                        hasData
                        viewMode
                        pendingAccept`,
                  config,
                )}
            }
        }`,

  IncentiveCampaignFindById: (config?: any) => `
        query IncentiveCampaignFindById($_id: MongoID!, $filter: filter, $sort: SortIncentiveCampaignEnum){
            IncentiveCampaignFindById(_id: $_id, filter: $filter, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        reserveId
                        owner
                        title
                        category
                        kind
                        schedule
                        description
                        videoLink
                        enableSocialInteraction
                        cardImage
                        contestationPeriod
                        bannerContent
                        ranking
                        results
                        regulation
                        presentation
                        invoiceOrigin
                        walletId
                        channel
                        subChannel
                        campaignType
                        kpis
                        campaignParams
                        boosters
                        participantList
                        highlightedRejected
                        campaignPeriod
                        periodOption
                        faq
                        highlighted
                        fileIds
                        externalCampaign
                        externalId
                        _id
                        updatedAt
                        createdAt
                        validityEnum
                        pinned
                        rankingData
                        hasData
                        viewMode
                        pendingAccept`,
                  config,
                )}
            }
        }`,

  IncentiveCampaignStatusOptions: (config?: any) => `
        query IncentiveCampaignStatusOptions{
            IncentiveCampaignStatusOptions{
                ${parseFragmentConfig(
                  `label
                        _id
                        value
                        description`,
                  config,
                )}
            }
        }`,

  CampaignGetDetails: (config?: any) => `
        query CampaignGetDetails($campaignId: String!, $userId: String!){
                ${parseFragmentConfig(`CampaignGetDetails(campaignId: $campaignId, userId: $userId)`, config)}
        }`,

  CampaignGeRanking: (config?: any) => `
        query CampaignGeRanking($campaignId: String!, $reference: String!){
                ${parseFragmentConfig(`CampaignGeRanking(campaignId: $campaignId, reference: $reference)`, config)}
        }`,

  AnalyticsIncentiveCampaign: (config?: any) => `
        query AnalyticsIncentiveCampaign($page: Int, $perPage: Int){
            AnalyticsIncentiveCampaign(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `_id
                        title
                        status
                        start
                        end`,
                  config,
                )}
            }
        }`,

  IncentiveCampaignReport: (config?: any) => `
        query IncentiveCampaignReport($campaignId: String!){
            IncentiveCampaignReport(campaignId: $campaignId){
                ${parseFragmentConfig(
                  `schema
                        data`,
                  config,
                )}
            }
        }`,

  DownloadIncentiveReports: (config?: any) => `
        query DownloadIncentiveReports($mode: String!, $IncentiveCampaignIds: [String]){
            DownloadIncentiveReports(mode: $mode, IncentiveCampaignIds: $IncentiveCampaignIds){
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  IncentiveCampaignDataFindMany: (config?: any) => `
        query IncentiveCampaignDataFindMany($filter: FilterFindManyIncentiveCampaignDataInput, $skip: Int, $limit: Int, $sort: SortFindManyIncentiveCampaignDataInput){
            IncentiveCampaignDataFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        reference
                        incentiveCampaignId
                        incentiveCampaignTypeId
                        kpis
                        points
                        position
                        result
                        elegibility
                        results
                        userDetail
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  IncentiveCampaignTypeDataFindMany: (config?: any) => `
        query IncentiveCampaignTypeDataFindMany($filter: FilterFindManyIncentiveCampaignTypeDataInput, $skip: Int, $limit: Int, $sort: SortFindManyIncentiveCampaignTypeDataInput){
            IncentiveCampaignTypeDataFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        channel
                        type
                        kpis
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ExternalCampaignPagination: (config?: any) => `
        query ExternalCampaignPagination($page: Int, $perPage: Int, $filter: FilterFindManyExternalCampaignInput, $sort: SortFindManyExternalCampaignInput){
            ExternalCampaignPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ExternalCampaignFindById: (config?: any) => `
        query ExternalCampaignFindById($_id: MongoID!, $sort: SortExternalCampaignEnum, $filter: filter){
            ExternalCampaignFindById(_id: $_id, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `title
                        description
                        externalUrl
                        clientKey
                        status
                        regulation
                        invoiceOrigin
                        participantList
                        _id
                        updatedAt
                        createdAt
                        pendingAccept
                        hasCNPJ
                        redirectURL
                        endDate`,
                  config,
                )}
            }
        }`,

  RegionFindMany: (config?: any) => `
        query RegionFindMany($filter: FilterFindManyRegionInput, $skip: Int, $limit: Int, $sort: SortFindManyRegionInput){
            RegionFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        itemIds
                        isVirtualRegion
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  RegionFindOne: (config?: any) => `
        query RegionFindOne($filter: FilterFindOneRegionInput, $skip: Int, $sort: SortFindOneRegionInput){
            RegionFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        itemIds
                        isVirtualRegion
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  RegionPagination: (config?: any) => `
        query RegionPagination($page: Int, $perPage: Int, $filter: FilterFindManyRegionInput, $sort: SortFindManyRegionInput){
            RegionPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  RegionItemFindMany: (config?: any) => `
        query RegionItemFindMany($filter: FilterFindManyRegionItemInput, $skip: Int, $limit: Int, $sort: SortFindManyRegionItemInput){
            RegionItemFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        abbreviation
                        level
                        UF
                        DDD
                        parentId
                        IBGE
                        region
                        capital
                        isVirtualRegion
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  RegionItemFindOne: (config?: any) => `
        query RegionItemFindOne($filter: FilterFindOneRegionItemInput, $skip: Int, $sort: SortFindOneRegionItemInput){
            RegionItemFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        abbreviation
                        level
                        UF
                        DDD
                        parentId
                        IBGE
                        region
                        capital
                        isVirtualRegion
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  RegionItemPagination: (config?: any) => `
        query RegionItemPagination($page: Int, $perPage: Int, $filter: FilterFindManyRegionItemInput, $sort: SortFindManyRegionItemInput){
            RegionItemPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  RegionItemDistinctDDD: (config?: any) => `
        query RegionItemDistinctDDD{
            RegionItemDistinctDDD{
                ${parseFragmentConfig(`items`, config)}
            }
        }`,

  RegionItemDistinctRegionAndUF: (config?: any) => `
        query RegionItemDistinctRegionAndUF{
                ${parseFragmentConfig(``, config)}
        }`,

  JobTitleFindOne: (config?: any) => `
        query JobTitleFindOne($filter: FilterFindOneJobTitleInput, $skip: Int, $sort: SortFindOneJobTitleInput){
            JobTitleFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  JobTitleFindMany: (config?: any) => `
        query JobTitleFindMany($filter: FilterFindManyJobTitleInput, $skip: Int, $limit: Int, $sort: SortFindManyJobTitleInput){
            JobTitleFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  RoleGroupFindById: (config?: any) => `
        query RoleGroupFindById($_id: MongoID!){
            RoleGroupFindById(_id: $_id){
                ${parseFragmentConfig(
                  `_id
                        name
                        channelName
                        roles
                        sort
                        accessLevel
                        parentId
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  RoleGroupFindOne: (config?: any) => `
        query RoleGroupFindOne($filter: FilterFindOneRoleGroupInput, $skip: Int, $sort: SortFindOneRoleGroupInput){
            RoleGroupFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        channelName
                        roles
                        sort
                        accessLevel
                        parentId
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  RoleGroupFindMany: (config?: any) => `
        query RoleGroupFindMany($filter: FilterFindManyRoleGroupInput, $skip: Int, $limit: Int, $sort: SortFindManyRoleGroupInput){
            RoleGroupFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        channelName
                        roles
                        sort
                        accessLevel
                        parentId
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  RoleGroupPagination: (config?: any) => `
        query RoleGroupPagination($page: Int, $perPage: Int, $filter: FilterFindManyRoleGroupInput, $sort: SortFindManyRoleGroupInput){
            RoleGroupPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ChannelFindMany: (config?: any) => `
        query ChannelFindMany($filter: FilterFindManyChannelInput, $skip: Int, $limit: Int, $sort: SortFindManyChannelInput){
            ChannelFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        parentId
                        parentName
                        name
                        availableAtChannels
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ChannelFindOne: (config?: any) => `
        query ChannelFindOne($filter: FilterFindOneChannelInput, $skip: Int, $sort: SortFindOneChannelInput){
            ChannelFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        parentId
                        parentName
                        name
                        availableAtChannels
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ChannelPagination: (config?: any) => `
        query ChannelPagination($page: Int, $perPage: Int, $filter: FilterFindManyChannelInput, $sort: SortFindManyChannelInput){
            ChannelPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserContentPermissionFindOne: (config?: any) => `
        query UserContentPermissionFindOne($filter: FilterFindOneUserContentPermissionInput, $skip: Int, $sort: SortFindOneUserContentPermissionInput){
            UserContentPermissionFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        roleGroups
                        regions
                        siteContentTypes
                        _id
                        updatedAt
                        createdAt
                        DDDs`,
                  config,
                )}
            }
        }`,

  ContentPermissionsHelper: (config?: any) => `
        query ContentPermissionsHelper{
            ContentPermissionsHelper{
                ${parseFragmentConfig(
                  `id
                        label
                        value
                        pId
                        parentName
                        kind`,
                  config,
                )}
            }
        }`,

  UserPagination: (config?: any) => `
        query UserPagination($page: Int, $perPage: Int, $filter: FilterFindManyUserInput, $sort: SortFindManyUserInput){
            UserPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserFindByIds: (config?: any) => `
        query UserFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsUserInput, $filter: filter){
            UserFindByIds(_ids: $_ids, limit: $limit, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        consecutiveLogins
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts
                        isSelf
                        channel
                        userLevel
                        acceptsPending`,
                  config,
                )}
            }
        }`,

  UserFindById: (config?: any) => `
        query UserFindById($_id: MongoID!, $filter: filter){
            UserFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        consecutiveLogins
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts
                        isSelf
                        channel
                        userLevel
                        acceptsPending`,
                  config,
                )}
            }
        }`,

  UserFindOne: (config?: any) => `
        query UserFindOne($filter: FilterFindOneUserInput, $skip: Int, $sort: SortFindOneUserInput){
            UserFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        consecutiveLogins
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts
                        isSelf
                        channel
                        userLevel
                        acceptsPending`,
                  config,
                )}
            }
        }`,

  UserFindMany: (config?: any) => `
        query UserFindMany($filter: FilterFindManyUserInput, $skip: Int, $limit: Int, $sort: SortFindManyUserInput){
            UserFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        consecutiveLogins
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts
                        isSelf
                        channel
                        userLevel
                        acceptsPending`,
                  config,
                )}
            }
        }`,

  User: (config?: any) => `
        query User{
            User{
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        consecutiveLogins
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts
                        isSelf
                        channel
                        userLevel
                        acceptsPending`,
                  config,
                )}
            }
        }`,

  UserSearch: (config?: any) => `
        query UserSearch($search: String!, $roleGroup: [String]){
            UserSearch(search: $search, roleGroup: $roleGroup){
                ${parseFragmentConfig(
                  `_id
                        name
                        email`,
                  config,
                )}
            }
        }`,

  UserSearchPagination: (config?: any) => `
        query UserSearchPagination($page: Int, $perPage: Int, $filter: UserSearchPaginationFilterInput, $sort: SortFindManyUserInput){
            UserSearchPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  TextFindBlackList: (config?: any) => `
        query TextFindBlackList($text: String!){
            TextFindBlackList(text: $text){
                ${parseFragmentConfig(`isOk`, config)}
            }
        }`,

  UserFindByEmails: (config?: any) => `
        query UserFindByEmails($emails: [String]!){
            UserFindByEmails(emails: $emails){
                ${parseFragmentConfig(
                  `_id
                        name
                        email`,
                  config,
                )}
            }
        }`,

  UserFindByMatricula: (config?: any) => `
        query UserFindByMatricula($isMatriculaNull: Boolean, $email: String, $name: String, $page: Int, $perPage: Int){
            UserFindByMatricula(isMatriculaNull: $isMatriculaNull, email: $email, name: $name, page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        consecutiveLogins
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts
                        isSelf
                        channel
                        userLevel
                        acceptsPending`,
                  config,
                )}
            }
        }`,

  FileFindByIds: (config?: any) => `
        query FileFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsFileInput){
            FileFindByIds(_ids: $_ids, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `origin
                        parentIds
                        kind
                        title
                        isPublic
                        uploaded
                        url
                        destinationKey
                        extension
                        size
                        sizeDescription
                        description
                        tags
                        owner
                        _id
                        updatedAt
                        createdAt
                        signedUrl
                        downloadUrl`,
                  config,
                )}
            }
        }`,

  FileFindById: (config?: any) => `
        query FileFindById($_id: MongoID!){
            FileFindById(_id: $_id){
                ${parseFragmentConfig(
                  `origin
                        parentIds
                        kind
                        title
                        isPublic
                        uploaded
                        url
                        destinationKey
                        extension
                        size
                        sizeDescription
                        description
                        tags
                        owner
                        _id
                        updatedAt
                        createdAt
                        signedUrl
                        downloadUrl`,
                  config,
                )}
            }
        }`,

  FilePagination: (config?: any) => `
        query FilePagination($page: Int, $perPage: Int, $filter: FilterFindManyFileInput, $sort: SortFindManyFileInput){
            FilePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  MaterialFindById: (config?: any) => `
        query MaterialFindById($_id: MongoID!, $filter: filter, $sort: sort){
            MaterialFindById(_id: $_id, filter: $filter, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        approvalFlow
                        mailingApprovers
                        usersToApprove
                        awaitingApprovers
                        approvedBy
                        description
                        isDraft
                        detachedVideo
                        latestPublications
                        orderLatestPublications
                        mainIdCategory
                        kind
                        tags
                        title
                        link
                        owner
                        fileIds
                        enableSocialInteraction
                        messageToApprover
                        disapprovalMessage
                        notificationLink
                        previousStatus
                        publishedAt
                        spotlight
                        html
                        editorFiles
                        externalSrc
                        isLive
                        associatedEvents
                        _id
                        updatedAt
                        createdAt
                        title_fuzzy
                        tags_fuzzy
                        numericId
                        validityEnum
                        kindName
                        pinned
                        isOwner`,
                  config,
                )}
            }
        }`,

  MaterialFindMany: (config?: any) => `
        query MaterialFindMany($filter: FilterFindManyMaterialInput, $skip: Int, $limit: Int, $sort: SortFindManyMaterialInput){
            MaterialFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        approvalFlow
                        mailingApprovers
                        usersToApprove
                        awaitingApprovers
                        approvedBy
                        description
                        isDraft
                        detachedVideo
                        latestPublications
                        orderLatestPublications
                        mainIdCategory
                        kind
                        tags
                        title
                        link
                        owner
                        fileIds
                        enableSocialInteraction
                        messageToApprover
                        disapprovalMessage
                        notificationLink
                        previousStatus
                        publishedAt
                        spotlight
                        html
                        editorFiles
                        externalSrc
                        isLive
                        associatedEvents
                        _id
                        updatedAt
                        createdAt
                        title_fuzzy
                        tags_fuzzy
                        numericId
                        validityEnum
                        kindName
                        pinned
                        isOwner`,
                  config,
                )}
            }
        }`,

  MaterialFindOne: (config?: any) => `
        query MaterialFindOne($filter: FilterFindOneMaterialInput, $skip: Int, $sort: SortFindOneMaterialInput){
            MaterialFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        approvalFlow
                        mailingApprovers
                        usersToApprove
                        awaitingApprovers
                        approvedBy
                        description
                        isDraft
                        detachedVideo
                        latestPublications
                        orderLatestPublications
                        mainIdCategory
                        kind
                        tags
                        title
                        link
                        owner
                        fileIds
                        enableSocialInteraction
                        messageToApprover
                        disapprovalMessage
                        notificationLink
                        previousStatus
                        publishedAt
                        spotlight
                        html
                        editorFiles
                        externalSrc
                        isLive
                        associatedEvents
                        _id
                        updatedAt
                        createdAt
                        title_fuzzy
                        tags_fuzzy
                        numericId
                        validityEnum
                        kindName
                        pinned
                        isOwner`,
                  config,
                )}
            }
        }`,

  MaterialPagination: (config?: any) => `
        query MaterialPagination($page: Int, $perPage: Int, $filter: FilterFindManyMaterialInput, $sort: SortFindManyMaterialInput, $limit: Int){
            MaterialPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort, limit: $limit){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  MaterialCount: (config?: any) => `
        query MaterialCount($filter: FilterMaterialInput, $sort: sort){
                ${parseFragmentConfig(`MaterialCount(filter: $filter, sort: $sort)`, config)}
        }`,

  MaterialStatusOptions: (config?: any) => `
        query MaterialStatusOptions{
            MaterialStatusOptions{
                ${parseFragmentConfig(
                  `label
                        _id
                        value
                        description`,
                  config,
                )}
            }
        }`,

  MaterialSearchOwners: (config?: any) => `
        query MaterialSearchOwners($kind: EnumMaterialKind){
            MaterialSearchOwners(kind: $kind){
                ${parseFragmentConfig(
                  `_id
                        name`,
                  config,
                )}
            }
        }`,

  EmailFindMany: (config?: any) => `
        query EmailFindMany($filter: FilterFindManyEmailInput, $skip: Int, $limit: Int, $sort: SortFindManyEmailInput){
            EmailFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `kind
                        parentId
                        subject
                        from
                        templateProps
                        templateName
                        status
                        sent
                        sentAt
                        sentBody
                        schedule
                        attachmentIds
                        statsId
                        plataform
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  EmailPagination: (config?: any) => `
        query EmailPagination($page: Int, $perPage: Int, $filter: FilterFindManyEmailInput, $sort: SortFindManyEmailInput){
            EmailPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  EmailFindOne: (config?: any) => `
        query EmailFindOne($filter: FilterFindOneEmailInput, $skip: Int, $sort: SortFindOneEmailInput){
            EmailFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `kind
                        parentId
                        subject
                        from
                        templateProps
                        templateName
                        status
                        sent
                        sentAt
                        sentBody
                        schedule
                        attachmentIds
                        statsId
                        plataform
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  EmailCheckRead: (config?: any) => `
        query EmailCheckRead($emailId: String!, $userEmail: String!){
                ${parseFragmentConfig(`EmailCheckRead(emailId: $emailId, userEmail: $userEmail)`, config)}
        }`,

  MaterialSearch: (config?: any) => `
        query MaterialSearch($search: String!, $page: Int, $perPage: Int, $sort: SortFindManyMaterialInput, $category: String){
            MaterialSearch(search: $search, page: $page, perPage: $perPage, sort: $sort, category: $category){
                ${parseFragmentConfig(
                  `count
                        autocomplete`,
                  config,
                )}
            }
        }`,

  MaterialFindPinned: (config?: any) => `
        query MaterialFindPinned($page: Int, $perPage: Int, $filter: FilterFindManyMaterialInput, $sort: SortFindManyMaterialInput){
            MaterialFindPinned(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  NotificationFindMany: (config?: any) => `
        query NotificationFindMany($filter: FilterFindManyNotificationInput, $skip: Int, $limit: Int, $sort: SortFindManyNotificationInput){
            NotificationFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `kind
                        parentId
                        title
                        body
                        iconUrl
                        fileIds
                        redirectionLink
                        sent
                        sentAt
                        schedule
                        clientData
                        _id
                        createdAt
                        wasRead`,
                  config,
                )}
            }
        }`,

  NotificationFindOne: (config?: any) => `
        query NotificationFindOne($filter: FilterFindOneNotificationInput, $skip: Int, $sort: SortFindOneNotificationInput){
            NotificationFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `kind
                        parentId
                        title
                        body
                        iconUrl
                        fileIds
                        redirectionLink
                        sent
                        sentAt
                        schedule
                        clientData
                        _id
                        createdAt
                        wasRead`,
                  config,
                )}
            }
        }`,

  NotificationPagination: (config?: any) => `
        query NotificationPagination($page: Int, $perPage: Int, $filter: FilterFindManyNotificationInput, $sort: SortFindManyNotificationInput){
            NotificationPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  HelperAddress: (config?: any) => `
        query HelperAddress($zipCode: String!){
            HelperAddress(zipCode: $zipCode){
                ${parseFragmentConfig(
                  `zipCode
                        neighborhood
                        address
                        complement
                        DDD
                        UF
                        IBGE`,
                  config,
                )}
            }
        }`,

  CategoryFindOne: (config?: any) => `
        query CategoryFindOne($filter: FilterFindOneCategoryInput, $skip: Int, $sort: SortFindOneCategoryInput){
            CategoryFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        _id
                        description
                        availableAtRoles
                        name
                        sort
                        parentId
                        routeName
                        typeIcon
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  CategoryFindById: (config?: any) => `
        query CategoryFindById($_id: MongoID!, $sort: SortMenuEnum, $filter: filter){
            CategoryFindById(_id: $_id, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        _id
                        description
                        availableAtRoles
                        name
                        sort
                        parentId
                        routeName
                        typeIcon
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  CategoryFindMany: (config?: any) => `
        query CategoryFindMany($filter: FilterFindManyCategoryInput, $skip: Int, $limit: Int, $sort: SortFindManyCategoryInput){
            CategoryFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        _id
                        description
                        availableAtRoles
                        name
                        sort
                        parentId
                        routeName
                        typeIcon
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  CategoryFindByIds: (config?: any) => `
        query CategoryFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsCategoryInput, $filter: filter){
            CategoryFindByIds(_ids: $_ids, limit: $limit, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        _id
                        description
                        availableAtRoles
                        name
                        sort
                        parentId
                        routeName
                        typeIcon
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  WebPushPublicKey: (config?: any) => `
        query WebPushPublicKey{
                ${parseFragmentConfig(`WebPushPublicKey`, config)}
        }`,

  PinFindMany: (config?: any) => `
        query PinFindMany($filter: FilterFindManyPinInput, $skip: Int, $limit: Int, $sort: SortFindManyPinInput){
            PinFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        recordId
                        kind
                        url
                        description
                        folderId
                        otherData
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  PinPagination: (config?: any) => `
        query PinPagination($page: Int, $perPage: Int, $filter: FilterFindManyPinInput, $sort: SortFindManyPinInput){
            PinPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ShareFindMany: (config?: any) => `
        query ShareFindMany($filter: FilterFindManyShareInput, $skip: Int, $limit: Int, $sort: SortFindManyShareInput){
            ShareFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        kind
                        title
                        recordId
                        userIds
                        message
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  SharePagination: (config?: any) => `
        query SharePagination($page: Int, $perPage: Int, $filter: FilterFindManyShareInput, $sort: SortFindManyShareInput){
            SharePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  CommentPagination: (config?: any) => `
        query CommentPagination($page: Int, $perPage: Int, $filter: FilterFindManyCommentInput, $sort: SortFindManyCommentInput){
            CommentPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  CommentFindOne: (config?: any) => `
        query CommentFindOne($filter: FilterFindOneCommentInput, $skip: Int, $sort: SortFindOneCommentInput){
            CommentFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `parentId
                        roomId
                        owner
                        approved
                        content
                        replyRecipientId
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  CommentFindById: (config?: any) => `
        query CommentFindById($_id: MongoID!){
            CommentFindById(_id: $_id){
                ${parseFragmentConfig(
                  `parentId
                        roomId
                        owner
                        approved
                        content
                        replyRecipientId
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  CommentFindMany: (config?: any) => `
        query CommentFindMany($filter: FilterFindManyCommentInput, $skip: Int, $limit: Int, $sort: SortFindManyCommentInput){
            CommentFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `parentId
                        roomId
                        owner
                        approved
                        content
                        replyRecipientId
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  CommentSortPagination: (config?: any) => `
        query CommentSortPagination($page: Int, $perPage: Int, $filter: CommentSortPaginationFilterInput, $sort: SortFindManyCommentInput){
            CommentSortPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  EventLivePagination: (config?: any) => `
        query EventLivePagination($page: Int, $perPage: Int, $filter: FilterFindManyEventLiveInput, $sort: SortFindManyEventLiveInput){
            EventLivePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  EventLiveFindOne: (config?: any) => `
        query EventLiveFindOne($filter: FilterFindOneEventLiveInput, $skip: Int, $sort: SortFindOneEventLiveInput){
            EventLiveFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        owner
                        closedBy
                        isDraft
                        videoUrl
                        eventDate
                        endTime
                        title
                        kind
                        description
                        status
                        previousStatus
                        publishedAt
                        fileIds
                        _id
                        updatedAt
                        createdAt
                        pinned`,
                  config,
                )}
            }
        }`,

  EventLiveFindById: (config?: any) => `
        query EventLiveFindById($_id: MongoID!, $filter: filter){
            EventLiveFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        owner
                        closedBy
                        isDraft
                        videoUrl
                        eventDate
                        endTime
                        title
                        kind
                        description
                        status
                        previousStatus
                        publishedAt
                        fileIds
                        _id
                        updatedAt
                        createdAt
                        pinned`,
                  config,
                )}
            }
        }`,

  BannerFindMany: (config?: any) => `
        query BannerFindMany($filter: FilterFindManyBannerInput, $skip: Int, $limit: Int, $sort: SortFindManyBannerInput){
            BannerFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        position
                        title
                        link
                        status
                        textButton
                        sort
                        externalCampaign
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  BannerFindOne: (config?: any) => `
        query BannerFindOne($filter: FilterFindOneBannerInput, $skip: Int, $sort: SortFindOneBannerInput){
            BannerFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        position
                        title
                        link
                        status
                        textButton
                        sort
                        externalCampaign
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  BannerPagination: (config?: any) => `
        query BannerPagination($page: Int, $perPage: Int, $filter: FilterFindManyBannerInput, $sort: SortFindManyBannerInput){
            BannerPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  AnalyticsCount: (config?: any) => `
        query AnalyticsCount($filter: FilterAnalyticsInput){
                ${parseFragmentConfig(`AnalyticsCount(filter: $filter)`, config)}
        }`,

  AnalyticsPagination: (config?: any) => `
        query AnalyticsPagination($page: Int, $perPage: Int, $filter: FilterFindManyAnalyticsInput, $sort: SortFindManyAnalyticsInput){
            AnalyticsPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  AnalyticsUsersReport: (config?: any) => `
        query AnalyticsUsersReport($page: Int, $perPage: Int){
            AnalyticsUsersReport(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `_id
                        nome
                        cargo
                        canal
                        regional
                        uf
                        data_adesao
                        ativo
                        matricula
                        responsavelUOPDV
                        matriculaResponsavelUOPDV
                        cpf
                        email
                        data_criacao
                        data_atualizacao
                        data_ultimo_login`,
                  config,
                )}
            }
        }`,

  AnalyticsFindReport: (config?: any) => `
        query AnalyticsFindReport($filter: AnalyticsReportFilterInput, $page: Int, $perPage: Int){
            AnalyticsFindReport(filter: $filter, page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `event
                        userId
                        userName
                        userChannel
                        userRoleGroup
                        userRegion
                        userGrupoEconomicoPDV
                        userRazaoSocialPDV
                        userResponsavelUOPDV
                        userIdArea
                        title
                        categories
                        date
                        materialCreatedAt
                        materialPublishedAt
                        status
                        day
                        kind
                        device
                        deviceKind
                        referer
                        route`,
                  config,
                )}
            }
        }`,

  AnalyticsUserNotification: (config?: any) => `
        query AnalyticsUserNotification($page: Int, $perPage: Int){
            AnalyticsUserNotification(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `_id
                        name
                        roleGroup
                        channels`,
                  config,
                )}
            }
        }`,

  AnalyticsPin: (config?: any) => `
        query AnalyticsPin($page: Int, $perPage: Int){
            AnalyticsPin(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `userId
                        title
                        categories
                        createdAt`,
                  config,
                )}
            }
        }`,

  AnalyticsComment: (config?: any) => `
        query AnalyticsComment($page: Int, $perPage: Int){
            AnalyticsComment(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `_id
                        userId
                        materialTitle
                        content
                        createdAt
                        likesCount
                        subCommentsCount
                        commentId`,
                  config,
                )}
            }
        }`,

  AnalyticsPoints: (config?: any) => `
        query AnalyticsPoints($page: Int, $perPage: Int){
            AnalyticsPoints(page: $page, perPage: $perPage){
                ${parseFragmentConfig(`userId`, config)}
            }
        }`,

  AnalyticsDownloads: (config?: any) => `
        query AnalyticsDownloads($fileId: String, $materialId: String){
                ${parseFragmentConfig(`AnalyticsDownloads(fileId: $fileId, materialId: $materialId)`, config)}
        }`,

  SearchCountFindMany: (config?: any) => `
        query SearchCountFindMany($filter: FilterFindManySearchCountInput, $skip: Int, $limit: Int, $sort: SortFindManySearchCountInput){
            SearchCountFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        term
                        count
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  SearchCountPagination: (config?: any) => `
        query SearchCountPagination($page: Int, $perPage: Int, $filter: FilterFindManySearchCountInput, $sort: SortFindManySearchCountInput){
            SearchCountPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  FolderFindMany: (config?: any) => `
        query FolderFindMany($filter: FilterFindManyFolderInput, $skip: Int, $limit: Int, $sort: SortFindManyFolderInput){
            FolderFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        kind
                        name
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  FolderFindById: (config?: any) => `
        query FolderFindById($_id: MongoID!){
            FolderFindById(_id: $_id){
                ${parseFragmentConfig(
                  `owner
                        kind
                        name
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  FolderPagination: (config?: any) => `
        query FolderPagination($page: Int, $perPage: Int, $filter: FilterFindManyFolderInput, $sort: SortFindManyFolderInput){
            FolderPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  RecentsActivitiesPagination: (config?: any) => `
        query RecentsActivitiesPagination($page: Int, $perPage: Int, $filter: FilterFindManyRecentsActivitiesInput, $sort: SortFindManyRecentsActivitiesInput){
            RecentsActivitiesPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserWordsBlackListFindMany: (config?: any) => `
        query UserWordsBlackListFindMany($filter: FilterFindManyUserWordsBlackListInput, $skip: Int, $limit: Int, $sort: SortFindManyUserWordsBlackListInput){
            UserWordsBlackListFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        word
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  UserWordsBlackListFindById: (config?: any) => `
        query UserWordsBlackListFindById($_id: MongoID!){
            UserWordsBlackListFindById(_id: $_id){
                ${parseFragmentConfig(
                  `owner
                        word
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  DownloadSensitiveFilesPagination: (config?: any) => `
        query DownloadSensitiveFilesPagination($page: Int, $perPage: Int, $filter: FilterFindManyDownloadSensitiveFilesInput, $sort: SortFindManyDownloadSensitiveFilesInput){
            DownloadSensitiveFilesPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  DownloadUsersBase: (config?: any) => `
        query DownloadUsersBase{
            DownloadUsersBase{
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  ImportUsersPlanFindMany: (config?: any) => `
        query ImportUsersPlanFindMany($filter: FilterFindManyImportUsersPlanInput, $skip: Int, $limit: Int, $sort: SortFindManyImportUsersPlanInput){
            ImportUsersPlanFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `fileId
                        statusImportUser
                        origin
                        errorsFile
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ImportUsersPlanFindById: (config?: any) => `
        query ImportUsersPlanFindById($_id: MongoID!, $filter: filter){
            ImportUsersPlanFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `fileId
                        statusImportUser
                        origin
                        errorsFile
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ImportUsersPlanFindByIds: (config?: any) => `
        query ImportUsersPlanFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsImportUsersPlanInput, $filter: filter){
            ImportUsersPlanFindByIds(_ids: $_ids, limit: $limit, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `fileId
                        statusImportUser
                        origin
                        errorsFile
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ImportUsersPlanPagination: (config?: any) => `
        query ImportUsersPlanPagination($page: Int, $perPage: Int, $filter: FilterFindManyImportUsersPlanInput, $sort: SortFindManyImportUsersPlanInput){
            ImportUsersPlanPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserDeletedFindByIds: (config?: any) => `
        query UserDeletedFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsUserDeletedInput){
            UserDeletedFindByIds(_ids: $_ids, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `name
                        CPF
                        matricula
                        email
                        roleGroup
                        channels
                        CNPJ
                        UF
                        allowedDDDs
                        region
                        regionPath
                        optInDate
                        optIn
                        userRoles
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  UserDeletedFindOne: (config?: any) => `
        query UserDeletedFindOne($filter: FilterFindOneUserDeletedInput, $skip: Int, $sort: SortFindOneUserDeletedInput){
            UserDeletedFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `name
                        CPF
                        matricula
                        email
                        roleGroup
                        channels
                        CNPJ
                        UF
                        allowedDDDs
                        region
                        regionPath
                        optInDate
                        optIn
                        userRoles
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  UserDeletedFindMany: (config?: any) => `
        query UserDeletedFindMany($filter: FilterFindManyUserDeletedInput, $skip: Int, $limit: Int, $sort: SortFindManyUserDeletedInput){
            UserDeletedFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `name
                        CPF
                        matricula
                        email
                        roleGroup
                        channels
                        CNPJ
                        UF
                        allowedDDDs
                        region
                        regionPath
                        optInDate
                        optIn
                        userRoles
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  UserHistoryBlockFindMany: (config?: any) => `
        query UserHistoryBlockFindMany($filter: FilterFindManyUserHistoryBlockInput, $skip: Int, $limit: Int, $sort: SortFindManyUserHistoryBlockInput){
            UserHistoryBlockFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        userId
                        parentId
                        isBlocked
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  UserHistoryBlockPagination: (config?: any) => `
        query UserHistoryBlockPagination($page: Int, $perPage: Int, $filter: FilterFindManyUserHistoryBlockInput, $sort: SortFindManyUserHistoryBlockInput){
            UserHistoryBlockPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ReconheceWalletPagination: (config?: any) => `
        query ReconheceWalletPagination($page: Int, $perPage: Int, $filter: FilterFindManyReconheceWalletInput, $sort: SortFindManyReconheceWalletInput){
            ReconheceWalletPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ReconheceWalletFindMany: (config?: any) => `
        query ReconheceWalletFindMany($filter: FilterFindManyReconheceWalletInput, $skip: Int, $limit: Int, $sort: SortFindManyReconheceWalletInput){
            ReconheceWalletFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `createdBy
                        paidAt
                        initialCredit
                        usedCredit
                        availableCredit
                        status
                        POIdentifier
                        POImages
                        NFIdentifier
                        NFImages
                        invoiceOrigin
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ReconheceWalletFindById: (config?: any) => `
        query ReconheceWalletFindById($_id: MongoID!){
            ReconheceWalletFindById(_id: $_id){
                ${parseFragmentConfig(
                  `createdBy
                        paidAt
                        initialCredit
                        usedCredit
                        availableCredit
                        status
                        POIdentifier
                        POImages
                        NFIdentifier
                        NFImages
                        invoiceOrigin
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ReconheceWalletSum: (config?: any) => `
        query ReconheceWalletSum($invoiceOrigin: String, $createdAtRange: ReconheceWalletCreatedAtRange){
            ReconheceWalletSum(invoiceOrigin: $invoiceOrigin, createdAtRange: $createdAtRange){
                ${parseFragmentConfig(
                  `initialCredit
                        usedCredit
                        availableCredit`,
                  config,
                )}
            }
        }`,

  WalletMovementPagination: (config?: any) => `
        query WalletMovementPagination($page: Int, $perPage: Int, $start: String, $end: String, $invoiceOrigin: String){
            WalletMovementPagination(page: $page, perPage: $perPage, start: $start, end: $end, invoiceOrigin: $invoiceOrigin){
                ${parseFragmentConfig(
                  `count
                        downloadUrl`,
                  config,
                )}
            }
        }`,

  WalletMovementSum: (config?: any) => `
        query WalletMovementSum($start: String, $end: String, $invoiceOrigin: String){
            WalletMovementSum(start: $start, end: $end, invoiceOrigin: $invoiceOrigin){
                ${parseFragmentConfig(
                  `initialCredit
                        usedCredit
                        availableCredit`,
                  config,
                )}
            }
        }`,

  FutureOperationFindById: (config?: any) => `
        query FutureOperationFindById($_id: MongoID!){
            FutureOperationFindById(_id: $_id){
                ${parseFragmentConfig(
                  `userCreditOperationId
                        userId
                        operation
                        pointsLeft
                        expiresAt
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ReconheceTransactionPagination: (config?: any) => `
        query ReconheceTransactionPagination($page: Int, $perPage: Int, $filter: FilterFindManyReconheceTransactionInput, $sort: SortFindManyReconheceTransactionInput){
            ReconheceTransactionPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserCreditOperationFindById: (config?: any) => `
        query UserCreditOperationFindById($_id: MongoID!, $filter: filter){
            UserCreditOperationFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `userId
                        op
                        value
                        walletId
                        transactionId
                        reason
                        status
                        externalExtractId
                        externalRequestNumber
                        clientKey
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  UserCreditOperationPagination: (config?: any) => `
        query UserCreditOperationPagination($page: Int, $perPage: Int, $filter: FilterFindManyUserCreditOperationInput, $sort: SortFindManyUserCreditOperationInput){
            UserCreditOperationPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserCreditOperationFindByIncentiveCampaignId: (config?: any) => `
        query UserCreditOperationFindByIncentiveCampaignId($incentiveCampaignId: String!){
            UserCreditOperationFindByIncentiveCampaignId(incentiveCampaignId: $incentiveCampaignId){
                ${parseFragmentConfig(
                  `userId
                        op
                        value
                        walletId
                        transactionId
                        reason
                        status
                        externalExtractId
                        externalRequestNumber
                        clientKey
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ExtractUserCreditPoints: (config?: any) => `
        query ExtractUserCreditPoints($incentiveCampaignIds: [String], $cpf: String, $matricula: String, $userName: String){
            ExtractUserCreditPoints(incentiveCampaignIds: $incentiveCampaignIds, cpf: $cpf, matricula: $matricula, userName: $userName){
                ${parseFragmentConfig(
                  `userId
                        op
                        value
                        walletId
                        transactionId
                        reason
                        status
                        externalExtractId
                        externalRequestNumber
                        clientKey
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  DownloadtUserCreditPoints: (config?: any) => `
        query DownloadtUserCreditPoints($incentiveCampaignIds: [String], $cpf: String, $matricula: String, $userName: String){
            DownloadtUserCreditPoints(incentiveCampaignIds: $incentiveCampaignIds, cpf: $cpf, matricula: $matricula, userName: $userName){
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  UserCreditOperationAnalytics: (config?: any) => `
        query UserCreditOperationAnalytics($page: Int, $perPage: Int){
            UserCreditOperationAnalytics(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `userId
                        op
                        value
                        walletId
                        transactionId
                        reason
                        status
                        externalExtractId
                        externalRequestNumber
                        clientKey
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  EmailStatsFindOne: (config?: any) => `
        query EmailStatsFindOne($filter: FilterFindOneEmailStatsInput, $skip: Int, $sort: SortFindOneEmailStatsInput){
            EmailStatsFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `statsId
                        userEmail
                        smtpMessageId
                        extra
                        status
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  EmailStatsFindMany: (config?: any) => `
        query EmailStatsFindMany($filter: FilterFindManyEmailStatsInput, $skip: Int, $limit: Int, $sort: SortFindManyEmailStatsInput){
            EmailStatsFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `statsId
                        userEmail
                        smtpMessageId
                        extra
                        status
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  EmailStatsAnalytics: (config?: any) => `
        query EmailStatsAnalytics($day: String!, $month: String!, $year: String!){
            EmailStatsAnalytics(day: $day, month: $month, year: $year){
                ${parseFragmentConfig(
                  `id
                        subject
                        classification
                        menu
                        day
                        sent
                        error
                        open
                        delivered
                        undelivered
                        spam`,
                  config,
                )}
            }
        }`,

  EmailUnsubscribeFindOne: (config?: any) => `
        query EmailUnsubscribeFindOne($filter: FilterFindOneEmailUnsubscribeInput, $skip: Int, $sort: SortFindOneEmailUnsubscribeInput){
            EmailUnsubscribeFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `email
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  EmailUnsubscribeFindMany: (config?: any) => `
        query EmailUnsubscribeFindMany($filter: FilterFindManyEmailUnsubscribeInput, $skip: Int, $limit: Int, $sort: SortFindManyEmailUnsubscribeInput){
            EmailUnsubscribeFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `email
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  EmailUnsubscribeAction: (config?: any) => `
        query EmailUnsubscribeAction($id: String, $email: String){
            EmailUnsubscribeAction(id: $id, email: $email){
                ${parseFragmentConfig(
                  `id
                        email`,
                  config,
                )}
            }
        }`,

  FileContentCampaignFindMany: (config?: any) => `
        query FileContentCampaignFindMany($filter: FilterFindManyFileContentCampaignInput, $skip: Int, $limit: Int, $sort: SortFindManyFileContentCampaignInput){
            FileContentCampaignFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `typeFileUpload
                        fileId
                        incentiveCampaignId
                        statusFileContent
                        period
                        errorsFile
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  FileContentCampaignFindById: (config?: any) => `
        query FileContentCampaignFindById($_id: MongoID!, $filter: filter){
            FileContentCampaignFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `typeFileUpload
                        fileId
                        incentiveCampaignId
                        statusFileContent
                        period
                        errorsFile
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  FileContentCampaignFindByIds: (config?: any) => `
        query FileContentCampaignFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsFileContentCampaignInput, $filter: filter){
            FileContentCampaignFindByIds(_ids: $_ids, limit: $limit, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `typeFileUpload
                        fileId
                        incentiveCampaignId
                        statusFileContent
                        period
                        errorsFile
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  FileContentCampaignPagination: (config?: any) => `
        query FileContentCampaignPagination($page: Int, $perPage: Int, $filter: FilterFindManyFileContentCampaignInput, $sort: SortFindManyFileContentCampaignInput){
            FileContentCampaignPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserCampaignFindMany: (config?: any) => `
        query UserCampaignFindMany($filter: FilterFindManyUserCampaignInput, $skip: Int, $limit: Int, $sort: SortFindManyUserCampaignInput){
            UserCampaignFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `incentiveCampaignId
                        userId
                        fileContentCampaignId
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  UserCampaignPagination: (config?: any) => `
        query UserCampaignPagination($page: Int, $perPage: Int, $filter: FilterFindManyUserCampaignInput, $sort: SortFindManyUserCampaignInput){
            UserCampaignPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserEligibilities: (config?: any) => `
        query UserEligibilities($incentiveCampaignId: String!, $fileContentCampaignId: String){
            UserEligibilities(incentiveCampaignId: $incentiveCampaignId, fileContentCampaignId: $fileContentCampaignId){
                ${parseFragmentConfig(
                  `name
                        position
                        points
                        campaignType`,
                  config,
                )}
            }
        }`,

  UserGUCheckCPF: (config?: any) => `
        query UserGUCheckCPF($CPF: String!){
            UserGUCheckCPF(CPF: $CPF){
                ${parseFragmentConfig(
                  `active
                        exists
                        hasPassword
                        channels
                        tipoFuncionario
                        firstAccess`,
                  config,
                )}
            }
        }`,

  MeuDiaPdvTableAll: (config?: any) => `
        query MeuDiaPdvTableAll{
                ${parseFragmentConfig(``, config)}
        }`,

  ConsumerPublicUser: (config?: any) => `
        query ConsumerPublicUser($CPF: String!){
            ConsumerPublicUser(CPF: $CPF){
                ${parseFragmentConfig(
                  `email
                        msgWrogEmail
                        firstAccess
                        isTempPassword`,
                  config,
                )}
            }
        }`,

  DownloadBaseUsers: (config?: any) => `
        query DownloadBaseUsers{
            DownloadBaseUsers{
                ${parseFragmentConfig(`signedUrl`, config)}
            }
        }`,

  DownloadUsersProfile: (config?: any) => `
        query DownloadUsersProfile($isExample: Boolean){
            DownloadUsersProfile(isExample: $isExample){
                ${parseFragmentConfig(
                  `schema
                        data`,
                  config,
                )}
            }
        }`,

  MeuDiaPdvTableFindOne: (config?: any) => `
        query MeuDiaPdvTableFindOne($filter: FilterFindOneMeuDiaPdvTableInput, $skip: Int, $sort: SortFindOneMeuDiaPdvTableInput){
            MeuDiaPdvTableFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `regional
                        uf
                        municipio
                        canal
                        bairro
                        loja
                        endereco
                        complemento
                        cep
                        tipo
                        manha1011
                        manha1112
                        tarde1415
                        tarde1516
                        tarde1617
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  MeuDiaPdvTableFindById: (config?: any) => `
        query MeuDiaPdvTableFindById($_id: MongoID!){
            MeuDiaPdvTableFindById(_id: $_id){
                ${parseFragmentConfig(
                  `regional
                        uf
                        municipio
                        canal
                        bairro
                        loja
                        endereco
                        complemento
                        cep
                        tipo
                        manha1011
                        manha1112
                        tarde1415
                        tarde1516
                        tarde1617
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  MeuDiaPdvTableFindMany: (config?: any) => `
        query MeuDiaPdvTableFindMany($filter: FilterFindManyMeuDiaPdvTableInput, $skip: Int, $limit: Int, $sort: SortFindManyMeuDiaPdvTableInput){
            MeuDiaPdvTableFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `regional
                        uf
                        municipio
                        canal
                        bairro
                        loja
                        endereco
                        complemento
                        cep
                        tipo
                        manha1011
                        manha1112
                        tarde1415
                        tarde1516
                        tarde1617
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  MeuDiaPdvTableFindByIds: (config?: any) => `
        query MeuDiaPdvTableFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsMeuDiaPdvTableInput){
            MeuDiaPdvTableFindByIds(_ids: $_ids, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `regional
                        uf
                        municipio
                        canal
                        bairro
                        loja
                        endereco
                        complemento
                        cep
                        tipo
                        manha1011
                        manha1112
                        tarde1415
                        tarde1516
                        tarde1617
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  GameficationNivelFindById: (config?: any) => `
        query GameficationNivelFindById($_id: MongoID!, $filter: filter){
            GameficationNivelFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `title
                        nivel
                        amountChips
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  GameficationNivelFindMany: (config?: any) => `
        query GameficationNivelFindMany($filter: FilterFindManyGameficationNivelInput, $skip: Int, $limit: Int, $sort: SortFindManyGameficationNivelInput){
            GameficationNivelFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `title
                        nivel
                        amountChips
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  GameficationNivelFindOne: (config?: any) => `
        query GameficationNivelFindOne($filter: FilterFindOneGameficationNivelInput, $skip: Int, $sort: SortFindOneGameficationNivelInput){
            GameficationNivelFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `title
                        nivel
                        amountChips
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  GameficationNivelPagination: (config?: any) => `
        query GameficationNivelPagination($page: Int, $perPage: Int, $filter: FilterFindManyGameficationNivelInput, $sort: SortFindManyGameficationNivelInput){
            GameficationNivelPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  GameficationNivelCount: (config?: any) => `
        query GameficationNivelCount($filter: FilterGameficationNivelInput){
                ${parseFragmentConfig(`GameficationNivelCount(filter: $filter)`, config)}
        }`,

  GameficationNivelCustomFindById: (config?: any) => `
        query GameficationNivelCustomFindById($_id: MongoID!, $filter: filter){
            GameficationNivelCustomFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `title
                        position
                        nivelId
                        roleGroupId
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  GameficationNivelCustomFindMany: (config?: any) => `
        query GameficationNivelCustomFindMany($filter: FilterFindManyGameficationNivelCustomInput, $skip: Int, $limit: Int, $sort: SortFindManyGameficationNivelCustomInput){
            GameficationNivelCustomFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `title
                        position
                        nivelId
                        roleGroupId
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  GameficationNivelCustomFindOne: (config?: any) => `
        query GameficationNivelCustomFindOne($filter: FilterFindOneGameficationNivelCustomInput, $skip: Int, $sort: SortFindOneGameficationNivelCustomInput){
            GameficationNivelCustomFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `title
                        position
                        nivelId
                        roleGroupId
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  GameficationNivelCustomPagination: (config?: any) => `
        query GameficationNivelCustomPagination($page: Int, $perPage: Int, $filter: FilterFindManyGameficationNivelCustomInput, $sort: SortFindManyGameficationNivelCustomInput){
            GameficationNivelCustomPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ChallengeFindById: (config?: any) => `
        query ChallengeFindById($_id: MongoID!, $filter: filter, $sort: sort){
            ChallengeFindById(_id: $_id, filter: $filter, sort: $sort){
                ${parseFragmentConfig(
                  `challengeNumericId
                        title
                        description
                        faq
                        thumbnail
                        availableAtRegions
                        availableAtRoleGroups
                        availableAtChannels
                        expires
                        startDate
                        endDate
                        typeChallenge
                        typePayment
                        action
                        kind
                        amountChips
                        amountPoints
                        indeterminate
                        recordId
                        typeTime
                        category
                        amount
                        consecutive
                        externalLink
                        challengeData
                        redirect
                        url
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ChallengeFindMany: (config?: any) => `
        query ChallengeFindMany($filter: FilterFindManyChallengeInput, $skip: Int, $limit: Int, $sort: SortFindManyChallengeInput){
            ChallengeFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `challengeNumericId
                        title
                        description
                        faq
                        thumbnail
                        availableAtRegions
                        availableAtRoleGroups
                        availableAtChannels
                        expires
                        startDate
                        endDate
                        typeChallenge
                        typePayment
                        action
                        kind
                        amountChips
                        amountPoints
                        indeterminate
                        recordId
                        typeTime
                        category
                        amount
                        consecutive
                        externalLink
                        challengeData
                        redirect
                        url
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ChallengeFindOne: (config?: any) => `
        query ChallengeFindOne($filter: FilterFindOneChallengeInput, $skip: Int, $sort: SortFindOneChallengeInput){
            ChallengeFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `challengeNumericId
                        title
                        description
                        faq
                        thumbnail
                        availableAtRegions
                        availableAtRoleGroups
                        availableAtChannels
                        expires
                        startDate
                        endDate
                        typeChallenge
                        typePayment
                        action
                        kind
                        amountChips
                        amountPoints
                        indeterminate
                        recordId
                        typeTime
                        category
                        amount
                        consecutive
                        externalLink
                        challengeData
                        redirect
                        url
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ChallengePagination: (config?: any) => `
        query ChallengePagination($page: Int, $perPage: Int, $filter: FilterFindManyChallengeInput, $sort: SortFindManyChallengeInput){
            ChallengePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ChallengeCount: (config?: any) => `
        query ChallengeCount($filter: FilterChallengeInput, $sort: sort){
                ${parseFragmentConfig(`ChallengeCount(filter: $filter, sort: $sort)`, config)}
        }`,

  UserChallengeFindById: (config?: any) => `
        query UserChallengeFindById($_id: MongoID!, $filter: filter){
            UserChallengeFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `userId
                        complete
                        progress
                        challengeId
                        userChallengeData
                        status
                        acceptDate
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  UserChallengeFindMany: (config?: any) => `
        query UserChallengeFindMany($filter: FilterFindManyUserChallengeInput, $skip: Int, $limit: Int, $sort: SortFindManyUserChallengeInput){
            UserChallengeFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        complete
                        progress
                        challengeId
                        userChallengeData
                        status
                        acceptDate
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  UserChallengeFindOne: (config?: any) => `
        query UserChallengeFindOne($filter: FilterFindOneUserChallengeInput, $skip: Int, $sort: SortFindOneUserChallengeInput){
            UserChallengeFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        complete
                        progress
                        challengeId
                        userChallengeData
                        status
                        acceptDate
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  UserChallengePagination: (config?: any) => `
        query UserChallengePagination($page: Int, $perPage: Int, $filter: FilterFindManyUserChallengeInput, $sort: SortFindManyUserChallengeInput){
            UserChallengePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserChallengeAnswersDownload: (config?: any) => `
        query UserChallengeAnswersDownload($challengeId: String!){
            UserChallengeAnswersDownload(challengeId: $challengeId){
                ${parseFragmentConfig(
                  `schema
                        data`,
                  config,
                )}
            }
        }`,

  UserChallengeQuizDownload: (config?: any) => `
        query UserChallengeQuizDownload($challengeId: String!){
            UserChallengeQuizDownload(challengeId: $challengeId){
                ${parseFragmentConfig(
                  `schema
                        data`,
                  config,
                )}
            }
        }`,

  GameficationRankingFindById: (config?: any) => `
        query GameficationRankingFindById($_id: MongoID!){
            GameficationRankingFindById(_id: $_id){
                ${parseFragmentConfig(
                  `title
                        placements
                        availableAtRegions
                        availableAtRoleGroups
                        availableAtChannels
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  GameficationRankingFindMany: (config?: any) => `
        query GameficationRankingFindMany($filter: FilterFindManyGameficationRankingInput, $skip: Int, $limit: Int, $sort: SortFindManyGameficationRankingInput){
            GameficationRankingFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `title
                        placements
                        availableAtRegions
                        availableAtRoleGroups
                        availableAtChannels
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  GameficationRankingFindOne: (config?: any) => `
        query GameficationRankingFindOne($filter: FilterFindOneGameficationRankingInput, $skip: Int, $sort: SortFindOneGameficationRankingInput){
            GameficationRankingFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `title
                        placements
                        availableAtRegions
                        availableAtRoleGroups
                        availableAtChannels
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  GameficationRankingPagination: (config?: any) => `
        query GameficationRankingPagination($page: Int, $perPage: Int, $filter: FilterFindManyGameficationRankingInput, $sort: SortFindManyGameficationRankingInput){
            GameficationRankingPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  GameficationRankingCount: (config?: any) => `
        query GameficationRankingCount($filter: FilterGameficationRankingInput){
                ${parseFragmentConfig(`GameficationRankingCount(filter: $filter)`, config)}
        }`,

  GameficationUserRankingFindById: (config?: any) => `
        query GameficationUserRankingFindById($_id: MongoID!, $sort: sort){
            GameficationUserRankingFindById(_id: $_id, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        rankingId
                        position
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  GameficationUserRankingFindMany: (config?: any) => `
        query GameficationUserRankingFindMany($filter: FilterFindManyGameficationUserRankingInput, $skip: Int, $limit: Int, $sort: SortFindManyGameficationUserRankingInput){
            GameficationUserRankingFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        rankingId
                        position
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  GameficationUserRankingFindOne: (config?: any) => `
        query GameficationUserRankingFindOne($filter: FilterFindOneGameficationUserRankingInput, $skip: Int, $sort: SortFindOneGameficationUserRankingInput){
            GameficationUserRankingFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        rankingId
                        position
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  GameficationUserRankingPagination: (config?: any) => `
        query GameficationUserRankingPagination($page: Int, $perPage: Int, $filter: FilterFindManyGameficationUserRankingInput, $sort: SortFindManyGameficationUserRankingInput){
            GameficationUserRankingPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  GameficationUserRankingCount: (config?: any) => `
        query GameficationUserRankingCount($filter: FilterGameficationUserRankingInput, $sort: sort){
                ${parseFragmentConfig(`GameficationUserRankingCount(filter: $filter, sort: $sort)`, config)}
        }`,

  UserChallengeQuizAnswers: (config?: any) => `
        query UserChallengeQuizAnswers($userChallengeId: String!, $answers: JSON){
            UserChallengeQuizAnswers(userChallengeId: $userChallengeId, answers: $answers){
                ${parseFragmentConfig(`data`, config)}
            }
        }`,

  UserChallengePointsFindById: (config?: any) => `
        query UserChallengePointsFindById($_id: MongoID!, $filter: filter){
            UserChallengePointsFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `userId
                        challengeId
                        userChallengeId
                        points
                        status
                        _id
                        updatedAt
                        createdAt
                        challengeNumericId`,
                  config,
                )}
            }
        }`,

  UserChallengePointsFindMany: (config?: any) => `
        query UserChallengePointsFindMany($filter: FilterFindManyUserChallengePointsInput, $skip: Int, $limit: Int, $sort: SortFindManyUserChallengePointsInput){
            UserChallengePointsFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        challengeId
                        userChallengeId
                        points
                        status
                        _id
                        updatedAt
                        createdAt
                        challengeNumericId`,
                  config,
                )}
            }
        }`,

  UserChallengePointsFindOne: (config?: any) => `
        query UserChallengePointsFindOne($filter: FilterFindOneUserChallengePointsInput, $skip: Int, $sort: SortFindOneUserChallengePointsInput){
            UserChallengePointsFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        challengeId
                        userChallengeId
                        points
                        status
                        _id
                        updatedAt
                        createdAt
                        challengeNumericId`,
                  config,
                )}
            }
        }`,

  UserChallengePointsPagination: (config?: any) => `
        query UserChallengePointsPagination($page: Int, $perPage: Int, $filter: FilterFindManyUserChallengePointsInput, $sort: SortFindManyUserChallengePointsInput){
            UserChallengePointsPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  GameficationMaterialSearch: (config?: any) => `
        query GameficationMaterialSearch($search: String!){
            GameficationMaterialSearch(search: $search){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        approvalFlow
                        mailingApprovers
                        usersToApprove
                        awaitingApprovers
                        approvedBy
                        description
                        isDraft
                        detachedVideo
                        latestPublications
                        orderLatestPublications
                        mainIdCategory
                        kind
                        tags
                        title
                        link
                        owner
                        fileIds
                        enableSocialInteraction
                        messageToApprover
                        disapprovalMessage
                        notificationLink
                        previousStatus
                        publishedAt
                        spotlight
                        html
                        editorFiles
                        externalSrc
                        isLive
                        associatedEvents
                        _id
                        updatedAt
                        createdAt
                        title_fuzzy
                        tags_fuzzy
                        numericId
                        validityEnum
                        kindName
                        pinned
                        isOwner`,
                  config,
                )}
            }
        }`,

  MyRankingPosition: (config?: any) => `
        query MyRankingPosition($filter: FilterFindManyUserInput, $sort: SortFindManyUserInput){
            MyRankingPosition(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`myPosition`, config)}
            }
        }`,

  RankingDownload: (config?: any) => `
        query RankingDownload{
            RankingDownload{
                ${parseFragmentConfig(
                  `schema
                        data`,
                  config,
                )}
            }
        }`,

  KpiMapOptions: (config?: any) => `
        query KpiMapOptions{
                ${parseFragmentConfig(`KpiMapOptions`, config)}
        }`,

  ImportHierarchy: (config?: any) => `
        query ImportHierarchy{
                ${parseFragmentConfig(`ImportHierarchy`, config)}
        }`,

  ImportKPIs: (config?: any) => `
        query ImportKPIs{
                ${parseFragmentConfig(`ImportKPIs`, config)}
        }`,

  KpiDataFindMany: (config?: any) => `
        query KpiDataFindMany($filter: FilterFindManyKpiDataInput, $skip: Int, $limit: Int, $sort: SortFindManyKpiDataInput){
            KpiDataFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `fileName
                        reference
                        fileId
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  HierarchyHistoryFindMany: (config?: any) => `
        query HierarchyHistoryFindMany($filter: FilterFindManyHierarchyHistoryInput, $skip: Int, $limit: Int, $sort: SortFindManyHierarchyHistoryInput){
            HierarchyHistoryFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `fileName
                        reference
                        fileId
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ReconheceInvoiceOriginFindMany: (config?: any) => `
        query ReconheceInvoiceOriginFindMany($filter: FilterFindManyReconheceInvoiceOriginInput, $skip: Int, $limit: Int, $sort: SortFindManyReconheceInvoiceOriginInput){
            ReconheceInvoiceOriginFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        availableAtChannels
                        clientKey
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ReconheceUserBalance: (config?: any) => `
        query ReconheceUserBalance{
            ReconheceUserBalance{
                ${parseFragmentConfig(
                  `balance
                        expiredPoints
                        reservedPoints
                        usedPoints
                        sessionToken
                        tempToken`,
                  config,
                )}
            }
        }`,

  CampaignContestationCreateOne: (config?: any) => `
        mutation CampaignContestationCreateOne($record: CreateOneCampaignContestationInput!){
            CampaignContestationCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CampaignContestationResolve: (config?: any) => `
        mutation CampaignContestationResolve($_id: String!, $title: String!, $status: EnumCampaignContestationResponseStatus!, $body: String!, $file: String){
            CampaignContestationResolve(_id: $_id, title: $title, status: $status, body: $body, file: $file){
                ${parseFragmentConfig(
                  `protocol
                        indicator
                        message
                        owner
                        parentId
                        fileIds
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  IncentiveCampaignCreateOne: (config?: any) => `
        mutation IncentiveCampaignCreateOne($record: CreateOneIncentiveCampaignInput!, $filter: filter){
            IncentiveCampaignCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  IncentiveCampaignUpdateOne: (config?: any) => `
        mutation IncentiveCampaignUpdateOne($record: UpdateOneIncentiveCampaignInput!, $filter: FilterUpdateOneIncentiveCampaignInput, $sort: SortUpdateOneIncentiveCampaignInput, $skip: Int){
            IncentiveCampaignUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  IncentiveCampaignRemoveById: (config?: any) => `
        mutation IncentiveCampaignRemoveById($_id: MongoID!, $filter: filter){
            IncentiveCampaignRemoveById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  IncentiveCampaignPayPointsNew: (config?: any) => `
        mutation IncentiveCampaignPayPointsNew($campaignId: String!, $walletId: String!){
                ${parseFragmentConfig(
                  `IncentiveCampaignPayPointsNew(campaignId: $campaignId, walletId: $walletId)`,
                  config,
                )}
        }`,

  ParticipateCampaign: (config?: any) => `
        mutation ParticipateCampaign($campaignId: String!){
                ${parseFragmentConfig(`ParticipateCampaign(campaignId: $campaignId)`, config)}
        }`,

  HighlightedRejectedCampaign: (config?: any) => `
        mutation HighlightedRejectedCampaign($campaignId: String!){
                ${parseFragmentConfig(`HighlightedRejectedCampaign(campaignId: $campaignId)`, config)}
        }`,

  IncentiveCampaignLikeToggle: (config?: any) => `
        mutation IncentiveCampaignLikeToggle($recordId: String!){
                ${parseFragmentConfig(`IncentiveCampaignLikeToggle(recordId: $recordId)`, config)}
        }`,

  ExternalCampaignCreateOne: (config?: any) => `
        mutation ExternalCampaignCreateOne($record: CreateOneExternalCampaignInput!){
            ExternalCampaignCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ExternalCampaignUpdateOne: (config?: any) => `
        mutation ExternalCampaignUpdateOne($record: UpdateOneExternalCampaignInput!, $filter: FilterUpdateOneExternalCampaignInput, $sort: SortUpdateOneExternalCampaignInput, $skip: Int){
            ExternalCampaignUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  AcceptExternalCampaign: (config?: any) => `
        mutation AcceptExternalCampaign($externalTerm: String!){
                ${parseFragmentConfig(`AcceptExternalCampaign(externalTerm: $externalTerm)`, config)}
        }`,

  RegionRemoveOne: (config?: any) => `
        mutation RegionRemoveOne($filter: FilterRemoveOneRegionInput, $sort: SortRemoveOneRegionInput){
            RegionRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserContentPermissionToggle: (config?: any) => `
        mutation UserContentPermissionToggle($userId: String!, $roleGroups: [String]!, $regions: [String]!, $contentTypes: [String]!, $viewOtherUsersContent: Boolean){
                ${parseFragmentConfig(
                  `UserContentPermissionToggle(userId: $userId, roleGroups: $roleGroups, regions: $regions, contentTypes: $contentTypes, viewOtherUsersContent: $viewOtherUsersContent)`,
                  config,
                )}
        }`,

  UserUpdateOne: (config?: any) => `
        mutation UserUpdateOne($record: UpdateOneUserInput!, $filter: FilterUpdateOneUserInput, $sort: SortUpdateOneUserInput, $skip: Int){
            UserUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserRemoveById: (config?: any) => `
        mutation UserRemoveById($_id: MongoID!){
            UserRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserAcceptTerm: (config?: any) => `
        mutation UserAcceptTerm($term: String!){
                ${parseFragmentConfig(`UserAcceptTerm(term: $term)`, config)}
        }`,

  UserLoginWithPassword: (config?: any) => `
        mutation UserLoginWithPassword($user: String!, $password: String!){
            UserLoginWithPassword(user: $user, password: $password){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        consecutiveLogins
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts
                        isSelf
                        channel
                        userLevel
                        acceptsPending`,
                  config,
                )}
            }
        }`,

  UserLoginWithToken: (config?: any) => `
        mutation UserLoginWithToken($token: String!){
            UserLoginWithToken(token: $token){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        consecutiveLogins
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts
                        isSelf
                        channel
                        userLevel
                        acceptsPending`,
                  config,
                )}
            }
        }`,

  UserLoginRenewToken: (config?: any) => `
        mutation UserLoginRenewToken($token: String!){
            UserLoginRenewToken(token: $token){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        consecutiveLogins
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts
                        isSelf
                        channel
                        userLevel
                        acceptsPending`,
                  config,
                )}
            }
        }`,

  UserSaveLogoutAnalytics: (config?: any) => `
        mutation UserSaveLogoutAnalytics{
                ${parseFragmentConfig(`UserSaveLogoutAnalytics`, config)}
        }`,

  UserSaveLoginAnalyticsPCS: (config?: any) => `
        mutation UserSaveLoginAnalyticsPCS{
                ${parseFragmentConfig(`UserSaveLoginAnalyticsPCS`, config)}
        }`,

  UserFillEmail: (config?: any) => `
        mutation UserFillEmail($email: String!){
                ${parseFragmentConfig(`UserFillEmail(email: $email)`, config)}
        }`,

  UserFillAvatarId: (config?: any) => `
        mutation UserFillAvatarId($avatarId: String!){
                ${parseFragmentConfig(`UserFillAvatarId(avatarId: $avatarId)`, config)}
        }`,

  UserOptIn: (config?: any) => `
        mutation UserOptIn($accept: Boolean!){
                ${parseFragmentConfig(`UserOptIn(accept: $accept)`, config)}
        }`,

  UserMarkNotificationAsRead: (config?: any) => `
        mutation UserMarkNotificationAsRead($isRead: Boolean!, $notifications: [String]!){
                ${parseFragmentConfig(
                  `UserMarkNotificationAsRead(isRead: $isRead, notifications: $notifications)`,
                  config,
                )}
        }`,

  UserDeleteNotification: (config?: any) => `
        mutation UserDeleteNotification($notificationId: String!){
                ${parseFragmentConfig(`UserDeleteNotification(notificationId: $notificationId)`, config)}
        }`,

  UserDeleteManyNotification: (config?: any) => `
        mutation UserDeleteManyNotification($notificationIds: [String!]){
                ${parseFragmentConfig(`UserDeleteManyNotification(notificationIds: $notificationIds)`, config)}
        }`,

  UserRegistration: (config?: any) => `
        mutation UserRegistration($record: UserRegistrationTypeInput!){
            UserRegistration(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserBlockComments: (config?: any) => `
        mutation UserBlockComments($userId: String!, $isBlocked: Boolean, $materialId: String){
                ${parseFragmentConfig(
                  `UserBlockComments(userId: $userId, isBlocked: $isBlocked, materialId: $materialId)`,
                  config,
                )}
        }`,

  ChangeToUserTaNarede: (config?: any) => `
        mutation ChangeToUserTaNarede{
            ChangeToUserTaNarede{
                ${parseFragmentConfig(
                  `token
                        name`,
                  config,
                )}
            }
        }`,

  UserChangePassword: (config?: any) => `
        mutation UserChangePassword($newPassword: String!){
                ${parseFragmentConfig(`UserChangePassword(newPassword: $newPassword)`, config)}
        }`,

  UserAzureLogin: (config?: any) => `
        mutation UserAzureLogin($token: String!){
            UserAzureLogin(token: $token){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        consecutiveLogins
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts
                        isSelf
                        channel
                        userLevel
                        acceptsPending`,
                  config,
                )}
            }
        }`,

  FileCreateOne: (config?: any) => `
        mutation FileCreateOne($record: CreateOneFileInput!){
            FileCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FileRemoveById: (config?: any) => `
        mutation FileRemoveById($_id: MongoID!){
            FileRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FileUpdateById: (config?: any) => `
        mutation FileUpdateById($record: UpdateByIdFileInput!){
            FileUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MaterialCreateOne: (config?: any) => `
        mutation MaterialCreateOne($record: CreateOneMaterialInput!, $filter: filter, $sort: sort){
            MaterialCreateOne(record: $record, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MaterialUpdateOne: (config?: any) => `
        mutation MaterialUpdateOne($record: UpdateOneMaterialInput!, $filter: FilterUpdateOneMaterialInput, $sort: SortUpdateOneMaterialInput, $skip: Int){
            MaterialUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MaterialUpdateById: (config?: any) => `
        mutation MaterialUpdateById($record: UpdateByIdMaterialInput!, $filter: filter, $sort: sort){
            MaterialUpdateById(record: $record, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MaterialRemoveOne: (config?: any) => `
        mutation MaterialRemoveOne($filter: FilterRemoveOneMaterialInput, $sort: SortRemoveOneMaterialInput){
            MaterialRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommunicationCreateOne: (config?: any) => `
        mutation CommunicationCreateOne($record: CreateOneMaterialInput!){
            CommunicationCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommunicationUpdateOne: (config?: any) => `
        mutation CommunicationUpdateOne($communicationId: String!, $record: UpdateOneMaterialInput){
            CommunicationUpdateOne(communicationId: $communicationId, record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommunicationApproveChannel: (config?: any) => `
        mutation CommunicationApproveChannel($recordId: String!){
                ${parseFragmentConfig(`CommunicationApproveChannel(recordId: $recordId)`, config)}
        }`,

  CommunicationDisapproveChannel: (config?: any) => `
        mutation CommunicationDisapproveChannel($recordId: String!, $message: String!){
                ${parseFragmentConfig(`CommunicationDisapproveChannel(recordId: $recordId, message: $message)`, config)}
        }`,

  CommunicationRemoveApprover: (config?: any) => `
        mutation CommunicationRemoveApprover($communicationId: String!, $userId: String!){
                ${parseFragmentConfig(
                  `CommunicationRemoveApprover(communicationId: $communicationId, userId: $userId)`,
                  config,
                )}
        }`,

  EmailFillTemplate: (config?: any) => `
        mutation EmailFillTemplate($template: EmailTemplateNames, $props: MaterialCommunicationItemsConfigTemplatePropsInput){
                ${parseFragmentConfig(`EmailFillTemplate(template: $template, props: $props)`, config)}
        }`,

  EmailMarkAsRead: (config?: any) => `
        mutation EmailMarkAsRead($token: String!){
                ${parseFragmentConfig(`EmailMarkAsRead(token: $token)`, config)}
        }`,

  SendEmailFront: (config?: any) => `
        mutation SendEmailFront($subject: String, $text: String, $attach: [String], $categories: [String]){
                ${parseFragmentConfig(
                  `SendEmailFront(subject: $subject, text: $text, attach: $attach, categories: $categories)`,
                  config,
                )}
        }`,

  MaterialSendTestEmail: (config?: any) => `
        mutation MaterialSendTestEmail($recordId: String!){
                ${parseFragmentConfig(`MaterialSendTestEmail(recordId: $recordId)`, config)}
        }`,

  MaterialLikeToggle: (config?: any) => `
        mutation MaterialLikeToggle($recordId: String!){
                ${parseFragmentConfig(`MaterialLikeToggle(recordId: $recordId)`, config)}
        }`,

  UpdateDeteachedAndLatestVideo: (config?: any) => `
        mutation UpdateDeteachedAndLatestVideo($idDetachedVideo: String, $idLatestVideo1: String, $idLatestVideo2: String, $idLatestVideo3: String){
                ${parseFragmentConfig(
                  `UpdateDeteachedAndLatestVideo(idDetachedVideo: $idDetachedVideo, idLatestVideo1: $idLatestVideo1, idLatestVideo2: $idLatestVideo2, idLatestVideo3: $idLatestVideo3)`,
                  config,
                )}
        }`,

  NotificationCreateOne: (config?: any) => `
        mutation NotificationCreateOne($record: CreateOneNotificationInput!){
            NotificationCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  NotificationCreateMany: (config?: any) => `
        mutation NotificationCreateMany($records: [CreateManyNotificationInput!]!){
            NotificationCreateMany(records: $records){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config,
                )}
            }
        }`,

  NotificationUpdateOne: (config?: any) => `
        mutation NotificationUpdateOne($record: UpdateOneNotificationInput!, $filter: FilterUpdateOneNotificationInput, $sort: SortUpdateOneNotificationInput, $skip: Int){
            NotificationUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  NotificationRemoveById: (config?: any) => `
        mutation NotificationRemoveById($_id: MongoID!){
            NotificationRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CategoryCreateOne: (config?: any) => `
        mutation CategoryCreateOne($record: CreateOneCategoryInput!, $filter: filter){
            CategoryCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CategoryUpdateOne: (config?: any) => `
        mutation CategoryUpdateOne($record: UpdateOneCategoryInput!, $filter: FilterUpdateOneCategoryInput, $sort: SortUpdateOneCategoryInput, $skip: Int){
            CategoryUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CategoryRemoveById: (config?: any) => `
        mutation CategoryRemoveById($_id: MongoID!, $filter: filter){
            CategoryRemoveById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CategoryCreateMany: (config?: any) => `
        mutation CategoryCreateMany($records: [CreateManyCategoryInput!]!, $filter: filter){
            CategoryCreateMany(records: $records, filter: $filter){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config,
                )}
            }
        }`,

  WebPushSubscribe: (config?: any) => `
        mutation WebPushSubscribe($subscription: JSON!){
                ${parseFragmentConfig(`WebPushSubscribe(subscription: $subscription)`, config)}
        }`,

  WebPushSendNotification: (config?: any) => `
        mutation WebPushSendNotification($userIds: [String]!, $notificationOptions: JSON!, $sync: Boolean){
                ${parseFragmentConfig(
                  `WebPushSendNotification(userIds: $userIds, notificationOptions: $notificationOptions, sync: $sync)`,
                  config,
                )}
        }`,

  PinCreateOne: (config?: any) => `
        mutation PinCreateOne($record: CreateOnePinInput!){
            PinCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PinRemoveOne: (config?: any) => `
        mutation PinRemoveOne($filter: FilterRemoveOnePinInput, $sort: SortRemoveOnePinInput){
            PinRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PinRemoveMany: (config?: any) => `
        mutation PinRemoveMany($filter: FilterRemoveManyPinInput!){
            PinRemoveMany(filter: $filter){
                ${parseFragmentConfig(`numAffected`, config)}
            }
        }`,

  ShareCreateOne: (config?: any) => `
        mutation ShareCreateOne($record: CreateOneShareInput!){
            ShareCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ShareRemoveOne: (config?: any) => `
        mutation ShareRemoveOne($filter: FilterRemoveOneShareInput, $sort: SortRemoveOneShareInput){
            ShareRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ShareRemoveMe: (config?: any) => `
        mutation ShareRemoveMe($shareId: String){
                ${parseFragmentConfig(`ShareRemoveMe(shareId: $shareId)`, config)}
        }`,

  CommentCreateOne: (config?: any) => `
        mutation CommentCreateOne($record: CreateOneCommentInput!){
            CommentCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommentUpdateOne: (config?: any) => `
        mutation CommentUpdateOne($record: UpdateOneCommentInput!, $filter: FilterUpdateOneCommentInput, $sort: SortUpdateOneCommentInput, $skip: Int){
            CommentUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommentRemoveById: (config?: any) => `
        mutation CommentRemoveById($_id: MongoID!){
            CommentRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommentLikeToggle: (config?: any) => `
        mutation CommentLikeToggle($recordId: String!){
                ${parseFragmentConfig(`CommentLikeToggle(recordId: $recordId)`, config)}
        }`,

  EventLiveCreateOne: (config?: any) => `
        mutation EventLiveCreateOne($record: CreateOneEventLiveInput!, $filter: filter){
            EventLiveCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EventLiveRemoveById: (config?: any) => `
        mutation EventLiveRemoveById($_id: MongoID!, $filter: filter){
            EventLiveRemoveById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EventLiveUpdateOne: (config?: any) => `
        mutation EventLiveUpdateOne($record: UpdateOneEventLiveInput!, $filter: FilterUpdateOneEventLiveInput, $sort: SortUpdateOneEventLiveInput, $skip: Int){
            EventLiveUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EventLiveCloseRoom: (config?: any) => `
        mutation EventLiveCloseRoom($eventId: String!){
                ${parseFragmentConfig(`EventLiveCloseRoom(eventId: $eventId)`, config)}
        }`,

  EventLiveLikeToggle: (config?: any) => `
        mutation EventLiveLikeToggle($recordId: String!){
                ${parseFragmentConfig(`EventLiveLikeToggle(recordId: $recordId)`, config)}
        }`,

  BannerRemoveById: (config?: any) => `
        mutation BannerRemoveById($_id: MongoID!, $filter: filter){
            BannerRemoveById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  BannerCreateOne: (config?: any) => `
        mutation BannerCreateOne($record: CreateOneBannerInput!, $filter: filter){
            BannerCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  BannerUpdateOne: (config?: any) => `
        mutation BannerUpdateOne($record: UpdateOneBannerInput!, $filter: FilterUpdateOneBannerInput, $sort: SortUpdateOneBannerInput, $skip: Int){
            BannerUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  AnalyticsPageView: (config?: any) => `
        mutation AnalyticsPageView($title: String!, $host: String, $externalLink: String, $kind: String){
                ${parseFragmentConfig(
                  `AnalyticsPageView(title: $title, host: $host, externalLink: $externalLink, kind: $kind)`,
                  config,
                )}
        }`,

  AnalyticsTrackEvent: (config?: any) => `
        mutation AnalyticsTrackEvent($event: EnumAnalyticsEvent!, $kind: EnumAnalyticsParentKind!, $recordId: String, $entityName: String, $externalLink: String, $label: String){
                ${parseFragmentConfig(
                  `AnalyticsTrackEvent(event: $event, kind: $kind, recordId: $recordId, entityName: $entityName, externalLink: $externalLink, label: $label)`,
                  config,
                )}
        }`,

  FolderCreateOne: (config?: any) => `
        mutation FolderCreateOne($record: CreateOneFolderInput!){
            FolderCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FolderRemoveOne: (config?: any) => `
        mutation FolderRemoveOne($filter: FilterRemoveOneFolderInput, $sort: SortRemoveOneFolderInput){
            FolderRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FolderRemoveMany: (config?: any) => `
        mutation FolderRemoveMany($filter: FilterRemoveManyFolderInput!){
            FolderRemoveMany(filter: $filter){
                ${parseFragmentConfig(`numAffected`, config)}
            }
        }`,

  FolderUpdateOne: (config?: any) => `
        mutation FolderUpdateOne($record: UpdateOneFolderInput!, $filter: FilterUpdateOneFolderInput, $sort: SortUpdateOneFolderInput, $skip: Int){
            FolderUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  RecentsActivitiesCreateOne: (config?: any) => `
        mutation RecentsActivitiesCreateOne($record: CreateOneRecentsActivitiesInput!){
            RecentsActivitiesCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserWordsBlackListCreateOne: (config?: any) => `
        mutation UserWordsBlackListCreateOne($record: CreateOneUserWordsBlackListInput!){
            UserWordsBlackListCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ImportUsersPlanCreateOne: (config?: any) => `
        mutation ImportUsersPlanCreateOne($record: CreateOneImportUsersPlanInput!, $filter: filter){
            ImportUsersPlanCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ImportUsersPlanUpdateOne: (config?: any) => `
        mutation ImportUsersPlanUpdateOne($record: UpdateOneImportUsersPlanInput!, $filter: FilterUpdateOneImportUsersPlanInput, $sort: SortUpdateOneImportUsersPlanInput, $skip: Int){
            ImportUsersPlanUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserHistoryBlockCreateOne: (config?: any) => `
        mutation UserHistoryBlockCreateOne($record: CreateOneUserHistoryBlockInput!, $sort: sort){
            UserHistoryBlockCreateOne(record: $record, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ReconheceWalletRemoveById: (config?: any) => `
        mutation ReconheceWalletRemoveById($_id: MongoID!){
            ReconheceWalletRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ReconheceWalletCreateOne: (config?: any) => `
        mutation ReconheceWalletCreateOne($record: CreateOneReconheceWalletInput!){
            ReconheceWalletCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ReconheceWalletUpdateById: (config?: any) => `
        mutation ReconheceWalletUpdateById($record: UpdateByIdReconheceWalletInput!){
            ReconheceWalletUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  SetWarningAt: (config?: any) => `
        mutation SetWarningAt($idUserCreditOperation: String!){
            SetWarningAt(idUserCreditOperation: $idUserCreditOperation){
                ${parseFragmentConfig(`result`, config)}
            }
        }`,

  EmailStatsCreateMany: (config?: any) => `
        mutation EmailStatsCreateMany($records: [CreateManyEmailStatsInput!]!){
            EmailStatsCreateMany(records: $records){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config,
                )}
            }
        }`,

  EmailStatsCreateOne: (config?: any) => `
        mutation EmailStatsCreateOne($record: CreateOneEmailStatsInput!){
            EmailStatsCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EmailUnsubscribeCreateMany: (config?: any) => `
        mutation EmailUnsubscribeCreateMany($records: [CreateManyEmailUnsubscribeInput!]!){
            EmailUnsubscribeCreateMany(records: $records){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config,
                )}
            }
        }`,

  EmailUnsubscribeCreateOne: (config?: any) => `
        mutation EmailUnsubscribeCreateOne($record: CreateOneEmailUnsubscribeInput!){
            EmailUnsubscribeCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FileContentCampaignCreateOne: (config?: any) => `
        mutation FileContentCampaignCreateOne($record: CreateOneFileContentCampaignInput!, $filter: filter){
            FileContentCampaignCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FileContentCampaignUpdateOne: (config?: any) => `
        mutation FileContentCampaignUpdateOne($record: UpdateOneFileContentCampaignInput!, $filter: FilterUpdateOneFileContentCampaignInput, $sort: SortUpdateOneFileContentCampaignInput, $skip: Int){
            FileContentCampaignUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FileContentCampaignRemoveById: (config?: any) => `
        mutation FileContentCampaignRemoveById($_id: MongoID!, $filter: filter){
            FileContentCampaignRemoveById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserCampaignCreateOne: (config?: any) => `
        mutation UserCampaignCreateOne($record: CreateOneUserCampaignInput!, $filter: filter, $sort: sort){
            UserCampaignCreateOne(record: $record, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserCampaignUpdateOne: (config?: any) => `
        mutation UserCampaignUpdateOne($record: UpdateOneUserCampaignInput!, $filter: FilterUpdateOneUserCampaignInput, $sort: SortUpdateOneUserCampaignInput, $skip: Int){
            UserCampaignUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MeuPdvParamsMutation: (config?: any) => `
        mutation MeuPdvParamsMutation($nome: String, $endereco: String, $complemento: String, $bairro: String, $uf: String, $municipio: String, $regional: String, $canal: String, $tipo: String, $vaga: String, $cep: String, $loja: String, $matricula: String, $celular: String, $diretoria: String, $email: String, $question: String){
            MeuPdvParamsMutation(nome: $nome, endereco: $endereco, complemento: $complemento, bairro: $bairro, uf: $uf, municipio: $municipio, regional: $regional, canal: $canal, tipo: $tipo, vaga: $vaga, cep: $cep, loja: $loja, matricula: $matricula, celular: $celular, diretoria: $diretoria, email: $email, question: $question){
                ${parseFragmentConfig(
                  `nome
                        endereco
                        complemento
                        bairro
                        uf
                        municipio
                        regional
                        canal
                        tipo
                        vaga
                        cep
                        loja
                        matricula
                        celular
                        email
                        diretoria
                        question
                        _id
                        updatedAt
                        createdAt`,
                  config,
                )}
            }
        }`,

  ConsumerTempPassword: (config?: any) => `
        mutation ConsumerTempPassword($CPF: String!, $email: String!){
                ${parseFragmentConfig(`ConsumerTempPassword(CPF: $CPF, email: $email)`, config)}
        }`,

  ConsumerNewPassword: (config?: any) => `
        mutation ConsumerNewPassword($CPF: String!, $oldPassword: String!, $newPassword: String!){
                ${parseFragmentConfig(
                  `ConsumerNewPassword(CPF: $CPF, oldPassword: $oldPassword, newPassword: $newPassword)`,
                  config,
                )}
        }`,

  SincronizeUserGU: (config?: any) => `
        mutation SincronizeUserGU($CPFS: [String!]!){
            SincronizeUserGU(CPFS: $CPFS){
                ${parseFragmentConfig(
                  `success
                        erros`,
                  config,
                )}
            }
        }`,

  SincronizeRoleGroup: (config?: any) => `
        mutation SincronizeRoleGroup($idsCargos: [Int!]!){
            SincronizeRoleGroup(idsCargos: $idsCargos){
                ${parseFragmentConfig(
                  `success
                        erros`,
                  config,
                )}
            }
        }`,

  MeuDiaPdvTableCreateOne: (config?: any) => `
        mutation MeuDiaPdvTableCreateOne($record: CreateOneMeuDiaPdvTableInput!){
            MeuDiaPdvTableCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MeuDiaPdvTableUpdateOne: (config?: any) => `
        mutation MeuDiaPdvTableUpdateOne($record: UpdateOneMeuDiaPdvTableInput!, $filter: FilterUpdateOneMeuDiaPdvTableInput, $sort: SortUpdateOneMeuDiaPdvTableInput, $skip: Int){
            MeuDiaPdvTableUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MeuDiaPdvTableRemoveById: (config?: any) => `
        mutation MeuDiaPdvTableRemoveById($_id: MongoID!){
            MeuDiaPdvTableRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MeuDiaPdvTableCreateMany: (config?: any) => `
        mutation MeuDiaPdvTableCreateMany($records: [CreateManyMeuDiaPdvTableInput!]!){
            MeuDiaPdvTableCreateMany(records: $records){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config,
                )}
            }
        }`,

  GameficationNivelCreateOne: (config?: any) => `
        mutation GameficationNivelCreateOne($record: CreateOneGameficationNivelInput!, $filter: filter){
            GameficationNivelCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  GameficationNivelUpdateOne: (config?: any) => `
        mutation GameficationNivelUpdateOne($record: UpdateOneGameficationNivelInput!, $filter: FilterUpdateOneGameficationNivelInput, $sort: SortUpdateOneGameficationNivelInput, $skip: Int){
            GameficationNivelUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  GameficationNivelUpdateById: (config?: any) => `
        mutation GameficationNivelUpdateById($record: UpdateByIdGameficationNivelInput!, $filter: filter){
            GameficationNivelUpdateById(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  GameficationNivelRemoveOne: (config?: any) => `
        mutation GameficationNivelRemoveOne($filter: FilterRemoveOneGameficationNivelInput, $sort: SortRemoveOneGameficationNivelInput){
            GameficationNivelRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  GameficationNivelCustomCreateOne: (config?: any) => `
        mutation GameficationNivelCustomCreateOne($record: CreateOneGameficationNivelCustomInput!, $filter: filter){
            GameficationNivelCustomCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  GameficationNivelCustomUpdateOne: (config?: any) => `
        mutation GameficationNivelCustomUpdateOne($record: UpdateOneGameficationNivelCustomInput!, $filter: FilterUpdateOneGameficationNivelCustomInput, $sort: SortUpdateOneGameficationNivelCustomInput, $skip: Int){
            GameficationNivelCustomUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  GameficationNivelCustomUpdateById: (config?: any) => `
        mutation GameficationNivelCustomUpdateById($record: UpdateByIdGameficationNivelCustomInput!, $filter: filter){
            GameficationNivelCustomUpdateById(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  GameficationNivelCustomRemoveOne: (config?: any) => `
        mutation GameficationNivelCustomRemoveOne($filter: FilterRemoveOneGameficationNivelCustomInput, $sort: SortRemoveOneGameficationNivelCustomInput){
            GameficationNivelCustomRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ChallengeCreateOne: (config?: any) => `
        mutation ChallengeCreateOne($record: CreateOneChallengeInput!, $filter: filter, $sort: sort){
            ChallengeCreateOne(record: $record, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ChallengeUpdateOne: (config?: any) => `
        mutation ChallengeUpdateOne($record: UpdateOneChallengeInput!, $filter: FilterUpdateOneChallengeInput, $sort: SortUpdateOneChallengeInput, $skip: Int){
            ChallengeUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ChallengeUpdateById: (config?: any) => `
        mutation ChallengeUpdateById($record: UpdateByIdChallengeInput!, $filter: filter, $sort: sort){
            ChallengeUpdateById(record: $record, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ChallengeRemoveOne: (config?: any) => `
        mutation ChallengeRemoveOne($filter: FilterRemoveOneChallengeInput, $sort: SortRemoveOneChallengeInput){
            ChallengeRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserChallengeUpdateById: (config?: any) => `
        mutation UserChallengeUpdateById($record: UpdateByIdUserChallengeInput!){
            UserChallengeUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserChallengeUpdateMany: (config?: any) => `
        mutation UserChallengeUpdateMany($record: UpdateManyUserChallengeInput!, $filter: FilterUpdateManyUserChallengeInput, $sort: SortUpdateManyUserChallengeInput, $skip: Int, $limit: Int){
            UserChallengeUpdateMany(record: $record, filter: $filter, sort: $sort, skip: $skip, limit: $limit){
                ${parseFragmentConfig(`numAffected`, config)}
            }
        }`,

  MissionAction: (config?: any) => `
        mutation MissionAction($challengeId: String!, $usersCPF: [String!]!){
                ${parseFragmentConfig(`MissionAction(challengeId: $challengeId, usersCPF: $usersCPF)`, config)}
        }`,

  GameficationRankingCreateOne: (config?: any) => `
        mutation GameficationRankingCreateOne($record: CreateOneGameficationRankingInput!){
            GameficationRankingCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  GameficationRankingUpdateOne: (config?: any) => `
        mutation GameficationRankingUpdateOne($record: UpdateOneGameficationRankingInput!, $filter: FilterUpdateOneGameficationRankingInput, $sort: SortUpdateOneGameficationRankingInput, $skip: Int){
            GameficationRankingUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  GameficationRankingUpdateById: (config?: any) => `
        mutation GameficationRankingUpdateById($record: UpdateByIdGameficationRankingInput!){
            GameficationRankingUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  GameficationRankingRemoveOne: (config?: any) => `
        mutation GameficationRankingRemoveOne($filter: FilterRemoveOneGameficationRankingInput, $sort: SortRemoveOneGameficationRankingInput){
            GameficationRankingRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  GameficationUserRankingCreateOne: (config?: any) => `
        mutation GameficationUserRankingCreateOne($record: CreateOneGameficationUserRankingInput!, $sort: sort){
            GameficationUserRankingCreateOne(record: $record, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  GameficationUserRankingUpdateOne: (config?: any) => `
        mutation GameficationUserRankingUpdateOne($record: UpdateOneGameficationUserRankingInput!, $filter: FilterUpdateOneGameficationUserRankingInput, $sort: SortUpdateOneGameficationUserRankingInput, $skip: Int){
            GameficationUserRankingUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  GameficationUserRankingUpdateById: (config?: any) => `
        mutation GameficationUserRankingUpdateById($record: UpdateByIdGameficationUserRankingInput!, $sort: sort){
            GameficationUserRankingUpdateById(record: $record, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  GameficationUserRankingRemoveOne: (config?: any) => `
        mutation GameficationUserRankingRemoveOne($filter: FilterRemoveOneGameficationUserRankingInput, $sort: SortRemoveOneGameficationUserRankingInput){
            GameficationUserRankingRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserChallengePointsUpdateById: (config?: any) => `
        mutation UserChallengePointsUpdateById($record: UpdateByIdUserChallengePointsInput!){
            UserChallengePointsUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserChallengePointsUpdateMany: (config?: any) => `
        mutation UserChallengePointsUpdateMany($record: UpdateManyUserChallengePointsInput!, $filter: FilterUpdateManyUserChallengePointsInput, $sort: SortUpdateManyUserChallengePointsInput, $skip: Int, $limit: Int){
            UserChallengePointsUpdateMany(record: $record, filter: $filter, sort: $sort, skip: $skip, limit: $limit){
                ${parseFragmentConfig(`numAffected`, config)}
            }
        }`,

  UserCreditChallengePointsAction: (config?: any) => `
        mutation UserCreditChallengePointsAction($walletId: String!, $userChallengeId: String!){
                ${parseFragmentConfig(
                  `UserCreditChallengePointsAction(walletId: $walletId, userChallengeId: $userChallengeId)`,
                  config,
                )}
        }`,

  UserCreditChallengePointsForManyAction: (config?: any) => `
        mutation UserCreditChallengePointsForManyAction($walletId: String!, $userChallengeId: [String]!){
                ${parseFragmentConfig(
                  `UserCreditChallengePointsForManyAction(walletId: $walletId, userChallengeId: $userChallengeId)`,
                  config,
                )}
        }`,

  PortalCheck: (config?: any) => `
        mutation PortalCheck($challengeId: String!, $userChallengeId: String!, $userId: String!){
                ${parseFragmentConfig(
                  `PortalCheck(challengeId: $challengeId, userChallengeId: $userChallengeId, userId: $userId)`,
                  config,
                )}
        }`,

  ReconheceCreateTempToken: (config?: any) => `
        mutation ReconheceCreateTempToken{
                ${parseFragmentConfig(`ReconheceCreateTempToken`, config)}
        }`,
};

export const AppMethods: Methods = {
  CampaignContestationFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CampaignContestation',
      schemaKey: 'CampaignContestationFindOne',
      query: query.CampaignContestationFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  CampaignContestationPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CampaignContestationPagination',
      schemaKey: 'CampaignContestationPagination',
      query: query.CampaignContestationPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  IncentiveCampaignFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'IncentiveCampaign',
      schemaKey: 'IncentiveCampaignFindMany',
      query: query.IncentiveCampaignFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  IncentiveCampaignPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'IncentiveCampaignPagination',
      schemaKey: 'IncentiveCampaignPagination',
      query: query.IncentiveCampaignPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  IncentiveCampaignFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'IncentiveCampaign',
      schemaKey: 'IncentiveCampaignFindOne',
      query: query.IncentiveCampaignFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  IncentiveCampaignFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'IncentiveCampaign',
      schemaKey: 'IncentiveCampaignFindById',
      query: query.IncentiveCampaignFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  IncentiveCampaignStatusOptions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'IncentiveCampaignStatusOption',
        schemaKey: 'IncentiveCampaignStatusOptions',
        query: query.IncentiveCampaignStatusOptions(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  CampaignGetDetails: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JSON',
      schemaKey: 'CampaignGetDetails',
      query: query.CampaignGetDetails(config),
      kind: OpKind.query,
      ...config,
    });
  },

  CampaignGeRanking: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JSON',
      schemaKey: 'CampaignGeRanking',
      query: query.CampaignGeRanking(config),
      kind: OpKind.query,
      ...config,
    });
  },

  AnalyticsIncentiveCampaign: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsIncentiveCampaign',
      schemaKey: 'AnalyticsIncentiveCampaign',
      query: query.AnalyticsIncentiveCampaign(config),
      kind: OpKind.query,
      ...config,
    });
  },

  IncentiveCampaignReport: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'BaseDownload',
      schemaKey: 'IncentiveCampaignReport',
      query: query.IncentiveCampaignReport(config),
      kind: OpKind.query,
      ...config,
    });
  },

  DownloadIncentiveReports: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'IncentiveReportType',
      schemaKey: 'DownloadIncentiveReports',
      query: query.DownloadIncentiveReports(config),
      kind: OpKind.query,
      ...config,
    });
  },

  IncentiveCampaignDataFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'IncentiveCampaignData',
      schemaKey: 'IncentiveCampaignDataFindMany',
      query: query.IncentiveCampaignDataFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  IncentiveCampaignTypeDataFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'IncentiveCampaignTypeData',
      schemaKey: 'IncentiveCampaignTypeDataFindMany',
      query: query.IncentiveCampaignTypeDataFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ExternalCampaignPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ExternalCampaignPagination',
      schemaKey: 'ExternalCampaignPagination',
      query: query.ExternalCampaignPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ExternalCampaignFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ExternalCampaign',
      schemaKey: 'ExternalCampaignFindById',
      query: query.ExternalCampaignFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  RegionFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Region',
      schemaKey: 'RegionFindMany',
      query: query.RegionFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  RegionFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Region',
      schemaKey: 'RegionFindOne',
      query: query.RegionFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  RegionPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionPagination',
      schemaKey: 'RegionPagination',
      query: query.RegionPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  RegionItemFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionItem',
      schemaKey: 'RegionItemFindMany',
      query: query.RegionItemFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  RegionItemFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionItem',
      schemaKey: 'RegionItemFindOne',
      query: query.RegionItemFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  RegionItemPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionItemPagination',
      schemaKey: 'RegionItemPagination',
      query: query.RegionItemPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  RegionItemDistinctDDD: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'RegionItemDistinctDDD',
        schemaKey: 'RegionItemDistinctDDD',
        query: query.RegionItemDistinctDDD(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  RegionItemDistinctRegionAndUF: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'RegionItemDistinctRegionAndUF',
        schemaKey: 'RegionItemDistinctRegionAndUF',
        query: query.RegionItemDistinctRegionAndUF(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  JobTitleFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JobTitle',
      schemaKey: 'JobTitleFindOne',
      query: query.JobTitleFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  JobTitleFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JobTitle',
      schemaKey: 'JobTitleFindMany',
      query: query.JobTitleFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  RoleGroupFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RoleGroup',
      schemaKey: 'RoleGroupFindById',
      query: query.RoleGroupFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  RoleGroupFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RoleGroup',
      schemaKey: 'RoleGroupFindOne',
      query: query.RoleGroupFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  RoleGroupFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RoleGroup',
      schemaKey: 'RoleGroupFindMany',
      query: query.RoleGroupFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  RoleGroupPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RoleGroupPagination',
      schemaKey: 'RoleGroupPagination',
      query: query.RoleGroupPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ChannelFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Channel',
      schemaKey: 'ChannelFindMany',
      query: query.ChannelFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ChannelFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Channel',
      schemaKey: 'ChannelFindOne',
      query: query.ChannelFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ChannelPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ChannelPagination',
      schemaKey: 'ChannelPagination',
      query: query.ChannelPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserContentPermissionFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserContentPermission',
      schemaKey: 'UserContentPermissionFindOne',
      query: query.UserContentPermissionFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ContentPermissionsHelper: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'ContentPermissionsHelperOption',
        schemaKey: 'ContentPermissionsHelper',
        query: query.ContentPermissionsHelper(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  UserPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserPagination',
      schemaKey: 'UserPagination',
      query: query.UserPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindByIds',
      query: query.UserFindByIds(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindById',
      query: query.UserFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindOne',
      query: query.UserFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindMany',
      query: query.UserFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  User: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'User',
        schemaKey: 'User',
        query: query.User(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  UserSearch: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserSearch',
      schemaKey: 'UserSearch',
      query: query.UserSearch(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserSearchPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserPagination',
      schemaKey: 'UserSearchPagination',
      query: query.UserSearchPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  TextFindBlackList: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'TextFindBlackList',
      schemaKey: 'TextFindBlackList',
      query: query.TextFindBlackList(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserFindByEmails: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserSimpleResult',
      schemaKey: 'UserFindByEmails',
      query: query.UserFindByEmails(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserFindByMatricula: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindByMatricula',
      query: query.UserFindByMatricula(config),
      kind: OpKind.query,
      ...config,
    });
  },

  FileFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'File',
      schemaKey: 'FileFindByIds',
      query: query.FileFindByIds(config),
      kind: OpKind.query,
      ...config,
    });
  },

  FileFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'File',
      schemaKey: 'FileFindById',
      query: query.FileFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  FilePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FilePagination',
      schemaKey: 'FilePagination',
      query: query.FilePagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  MaterialFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Material',
      schemaKey: 'MaterialFindById',
      query: query.MaterialFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  MaterialFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Material',
      schemaKey: 'MaterialFindMany',
      query: query.MaterialFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  MaterialFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Material',
      schemaKey: 'MaterialFindOne',
      query: query.MaterialFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  MaterialPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MaterialPagination',
      schemaKey: 'MaterialPagination',
      query: query.MaterialPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  MaterialCount: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Int',
      schemaKey: 'MaterialCount',
      query: query.MaterialCount(config),
      kind: OpKind.query,
      ...config,
    });
  },

  MaterialStatusOptions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'MaterialStatusOption',
        schemaKey: 'MaterialStatusOptions',
        query: query.MaterialStatusOptions(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  MaterialSearchOwners: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MaterialSearchOwners',
      schemaKey: 'MaterialSearchOwners',
      query: query.MaterialSearchOwners(config),
      kind: OpKind.query,
      ...config,
    });
  },

  EmailFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Email',
      schemaKey: 'EmailFindMany',
      query: query.EmailFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  EmailPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailPagination',
      schemaKey: 'EmailPagination',
      query: query.EmailPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  EmailFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Email',
      schemaKey: 'EmailFindOne',
      query: query.EmailFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  EmailCheckRead: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'EmailCheckRead',
      query: query.EmailCheckRead(config),
      kind: OpKind.query,
      ...config,
    });
  },

  MaterialSearch: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MaterialSearch',
      schemaKey: 'MaterialSearch',
      query: query.MaterialSearch(config),
      kind: OpKind.query,
      ...config,
    });
  },

  MaterialFindPinned: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MaterialPagination',
      schemaKey: 'MaterialFindPinned',
      query: query.MaterialFindPinned(config),
      kind: OpKind.query,
      ...config,
    });
  },

  NotificationFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Notification',
      schemaKey: 'NotificationFindMany',
      query: query.NotificationFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  NotificationFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Notification',
      schemaKey: 'NotificationFindOne',
      query: query.NotificationFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  NotificationPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'NotificationPagination',
      schemaKey: 'NotificationPagination',
      query: query.NotificationPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  HelperAddress: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AddressHelperPayload',
      schemaKey: 'HelperAddress',
      query: query.HelperAddress(config),
      kind: OpKind.query,
      ...config,
    });
  },

  CategoryFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Category',
      schemaKey: 'CategoryFindOne',
      query: query.CategoryFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  CategoryFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Category',
      schemaKey: 'CategoryFindById',
      query: query.CategoryFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  CategoryFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Category',
      schemaKey: 'CategoryFindMany',
      query: query.CategoryFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  CategoryFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Category',
      schemaKey: 'CategoryFindByIds',
      query: query.CategoryFindByIds(config),
      kind: OpKind.query,
      ...config,
    });
  },

  WebPushPublicKey: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'String',
        schemaKey: 'WebPushPublicKey',
        query: query.WebPushPublicKey(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  PinFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Pin',
      schemaKey: 'PinFindMany',
      query: query.PinFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  PinPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PinPagination',
      schemaKey: 'PinPagination',
      query: query.PinPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ShareFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Share',
      schemaKey: 'ShareFindMany',
      query: query.ShareFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  SharePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'SharePagination',
      schemaKey: 'SharePagination',
      query: query.SharePagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  CommentPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CommentPagination',
      schemaKey: 'CommentPagination',
      query: query.CommentPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  CommentFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Comment',
      schemaKey: 'CommentFindOne',
      query: query.CommentFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  CommentFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Comment',
      schemaKey: 'CommentFindById',
      query: query.CommentFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  CommentFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Comment',
      schemaKey: 'CommentFindMany',
      query: query.CommentFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  CommentSortPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CommentPagination',
      schemaKey: 'CommentSortPagination',
      query: query.CommentSortPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  EventLivePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EventLivePagination',
      schemaKey: 'EventLivePagination',
      query: query.EventLivePagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  EventLiveFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EventLive',
      schemaKey: 'EventLiveFindOne',
      query: query.EventLiveFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  EventLiveFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EventLive',
      schemaKey: 'EventLiveFindById',
      query: query.EventLiveFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  BannerFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Banner',
      schemaKey: 'BannerFindMany',
      query: query.BannerFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  BannerFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Banner',
      schemaKey: 'BannerFindOne',
      query: query.BannerFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  BannerPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'BannerPagination',
      schemaKey: 'BannerPagination',
      query: query.BannerPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  AnalyticsCount: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Int',
      schemaKey: 'AnalyticsCount',
      query: query.AnalyticsCount(config),
      kind: OpKind.query,
      ...config,
    });
  },

  AnalyticsPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsPagination',
      schemaKey: 'AnalyticsPagination',
      query: query.AnalyticsPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  AnalyticsUsersReport: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsUser',
      schemaKey: 'AnalyticsUsersReport',
      query: query.AnalyticsUsersReport(config),
      kind: OpKind.query,
      ...config,
    });
  },

  AnalyticsFindReport: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsReportItem',
      schemaKey: 'AnalyticsFindReport',
      query: query.AnalyticsFindReport(config),
      kind: OpKind.query,
      ...config,
    });
  },

  AnalyticsUserNotification: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsUserNotification',
      schemaKey: 'AnalyticsUserNotification',
      query: query.AnalyticsUserNotification(config),
      kind: OpKind.query,
      ...config,
    });
  },

  AnalyticsPin: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsPin',
      schemaKey: 'AnalyticsPin',
      query: query.AnalyticsPin(config),
      kind: OpKind.query,
      ...config,
    });
  },

  AnalyticsComment: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsComment',
      schemaKey: 'AnalyticsComment',
      query: query.AnalyticsComment(config),
      kind: OpKind.query,
      ...config,
    });
  },

  AnalyticsPoints: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsPoints',
      schemaKey: 'AnalyticsPoints',
      query: query.AnalyticsPoints(config),
      kind: OpKind.query,
      ...config,
    });
  },

  AnalyticsDownloads: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'AnalyticsDownloads',
      query: query.AnalyticsDownloads(config),
      kind: OpKind.query,
      ...config,
    });
  },

  SearchCountFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'SearchCount',
      schemaKey: 'SearchCountFindMany',
      query: query.SearchCountFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  SearchCountPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'SearchCountPagination',
      schemaKey: 'SearchCountPagination',
      query: query.SearchCountPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  FolderFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Folder',
      schemaKey: 'FolderFindMany',
      query: query.FolderFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  FolderFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Folder',
      schemaKey: 'FolderFindById',
      query: query.FolderFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  FolderPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FolderPagination',
      schemaKey: 'FolderPagination',
      query: query.FolderPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  RecentsActivitiesPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RecentsActivitiesPagination',
      schemaKey: 'RecentsActivitiesPagination',
      query: query.RecentsActivitiesPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserWordsBlackListFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserWordsBlackList',
      schemaKey: 'UserWordsBlackListFindMany',
      query: query.UserWordsBlackListFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserWordsBlackListFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserWordsBlackList',
      schemaKey: 'UserWordsBlackListFindById',
      query: query.UserWordsBlackListFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  DownloadSensitiveFilesPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DownloadSensitiveFilesPagination',
      schemaKey: 'DownloadSensitiveFilesPagination',
      query: query.DownloadSensitiveFilesPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  DownloadUsersBase: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'DownloadUsersInfo',
        schemaKey: 'DownloadUsersBase',
        query: query.DownloadUsersBase(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  ImportUsersPlanFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ImportUsersPlan',
      schemaKey: 'ImportUsersPlanFindMany',
      query: query.ImportUsersPlanFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ImportUsersPlanFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ImportUsersPlan',
      schemaKey: 'ImportUsersPlanFindById',
      query: query.ImportUsersPlanFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ImportUsersPlanFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ImportUsersPlan',
      schemaKey: 'ImportUsersPlanFindByIds',
      query: query.ImportUsersPlanFindByIds(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ImportUsersPlanPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ImportUsersPlanPagination',
      schemaKey: 'ImportUsersPlanPagination',
      query: query.ImportUsersPlanPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserDeletedFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserDeleted',
      schemaKey: 'UserDeletedFindByIds',
      query: query.UserDeletedFindByIds(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserDeletedFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserDeleted',
      schemaKey: 'UserDeletedFindOne',
      query: query.UserDeletedFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserDeletedFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserDeleted',
      schemaKey: 'UserDeletedFindMany',
      query: query.UserDeletedFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserHistoryBlockFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserHistoryBlock',
      schemaKey: 'UserHistoryBlockFindMany',
      query: query.UserHistoryBlockFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserHistoryBlockPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserHistoryBlockPagination',
      schemaKey: 'UserHistoryBlockPagination',
      query: query.UserHistoryBlockPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ReconheceWalletPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReconheceWalletPagination',
      schemaKey: 'ReconheceWalletPagination',
      query: query.ReconheceWalletPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ReconheceWalletFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReconheceWallet',
      schemaKey: 'ReconheceWalletFindMany',
      query: query.ReconheceWalletFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ReconheceWalletFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReconheceWallet',
      schemaKey: 'ReconheceWalletFindById',
      query: query.ReconheceWalletFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ReconheceWalletSum: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReconheceWalletSum',
      schemaKey: 'ReconheceWalletSum',
      query: query.ReconheceWalletSum(config),
      kind: OpKind.query,
      ...config,
    });
  },

  WalletMovementPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'WalletMovementPagination',
      schemaKey: 'WalletMovementPagination',
      query: query.WalletMovementPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  WalletMovementSum: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'WalletMovementSum',
      schemaKey: 'WalletMovementSum',
      query: query.WalletMovementSum(config),
      kind: OpKind.query,
      ...config,
    });
  },

  FutureOperationFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FutureOperation',
      schemaKey: 'FutureOperationFindById',
      query: query.FutureOperationFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ReconheceTransactionPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReconheceTransactionPagination',
      schemaKey: 'ReconheceTransactionPagination',
      query: query.ReconheceTransactionPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserCreditOperationFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserCreditOperation',
      schemaKey: 'UserCreditOperationFindById',
      query: query.UserCreditOperationFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserCreditOperationPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserCreditOperationPagination',
      schemaKey: 'UserCreditOperationPagination',
      query: query.UserCreditOperationPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserCreditOperationFindByIncentiveCampaignId: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserCreditOperation',
      schemaKey: 'UserCreditOperationFindByIncentiveCampaignId',
      query: query.UserCreditOperationFindByIncentiveCampaignId(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ExtractUserCreditPoints: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserCreditOperation',
      schemaKey: 'ExtractUserCreditPoints',
      query: query.ExtractUserCreditPoints(config),
      kind: OpKind.query,
      ...config,
    });
  },

  DownloadtUserCreditPoints: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DownloadtUserCreditPoints',
      schemaKey: 'DownloadtUserCreditPoints',
      query: query.DownloadtUserCreditPoints(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserCreditOperationAnalytics: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserCreditOperation',
      schemaKey: 'UserCreditOperationAnalytics',
      query: query.UserCreditOperationAnalytics(config),
      kind: OpKind.query,
      ...config,
    });
  },

  EmailStatsFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailStats',
      schemaKey: 'EmailStatsFindOne',
      query: query.EmailStatsFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  EmailStatsFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailStats',
      schemaKey: 'EmailStatsFindMany',
      query: query.EmailStatsFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  EmailStatsAnalytics: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailStatsAnalyticsPayload',
      schemaKey: 'EmailStatsAnalytics',
      query: query.EmailStatsAnalytics(config),
      kind: OpKind.query,
      ...config,
    });
  },

  EmailUnsubscribeFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailUnsubscribe',
      schemaKey: 'EmailUnsubscribeFindOne',
      query: query.EmailUnsubscribeFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  EmailUnsubscribeFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailUnsubscribe',
      schemaKey: 'EmailUnsubscribeFindMany',
      query: query.EmailUnsubscribeFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  EmailUnsubscribeAction: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailUnsubscribeActionPayload',
      schemaKey: 'EmailUnsubscribeAction',
      query: query.EmailUnsubscribeAction(config),
      kind: OpKind.query,
      ...config,
    });
  },

  FileContentCampaignFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FileContentCampaign',
      schemaKey: 'FileContentCampaignFindMany',
      query: query.FileContentCampaignFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  FileContentCampaignFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FileContentCampaign',
      schemaKey: 'FileContentCampaignFindById',
      query: query.FileContentCampaignFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  FileContentCampaignFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FileContentCampaign',
      schemaKey: 'FileContentCampaignFindByIds',
      query: query.FileContentCampaignFindByIds(config),
      kind: OpKind.query,
      ...config,
    });
  },

  FileContentCampaignPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FileContentCampaignPagination',
      schemaKey: 'FileContentCampaignPagination',
      query: query.FileContentCampaignPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserCampaignFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserCampaign',
      schemaKey: 'UserCampaignFindMany',
      query: query.UserCampaignFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserCampaignPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserCampaignPagination',
      schemaKey: 'UserCampaignPagination',
      query: query.UserCampaignPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserEligibilities: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserEligibilities',
      schemaKey: 'UserEligibilities',
      query: query.UserEligibilities(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserGUCheckCPF: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserGUCheckCPFPayload',
      schemaKey: 'UserGUCheckCPF',
      query: query.UserGUCheckCPF(config),
      kind: OpKind.query,
      ...config,
    });
  },

  MeuDiaPdvTableAll: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'MeuPdvReturn',
        schemaKey: 'MeuDiaPdvTableAll',
        query: query.MeuDiaPdvTableAll(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  ConsumerPublicUser: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ConsumerPublicUser',
      schemaKey: 'ConsumerPublicUser',
      query: query.ConsumerPublicUser(config),
      kind: OpKind.query,
      ...config,
    });
  },

  DownloadBaseUsers: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'BaseDownloadReportUsersConsumer',
        schemaKey: 'DownloadBaseUsers',
        query: query.DownloadBaseUsers(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  DownloadUsersProfile: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'BaseDownload',
      schemaKey: 'DownloadUsersProfile',
      query: query.DownloadUsersProfile(config),
      kind: OpKind.query,
      ...config,
    });
  },

  MeuDiaPdvTableFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MeuDiaPdvTable',
      schemaKey: 'MeuDiaPdvTableFindOne',
      query: query.MeuDiaPdvTableFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  MeuDiaPdvTableFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MeuDiaPdvTable',
      schemaKey: 'MeuDiaPdvTableFindById',
      query: query.MeuDiaPdvTableFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  MeuDiaPdvTableFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MeuDiaPdvTable',
      schemaKey: 'MeuDiaPdvTableFindMany',
      query: query.MeuDiaPdvTableFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  MeuDiaPdvTableFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MeuDiaPdvTable',
      schemaKey: 'MeuDiaPdvTableFindByIds',
      query: query.MeuDiaPdvTableFindByIds(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationNivelFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationNivel',
      schemaKey: 'GameficationNivelFindById',
      query: query.GameficationNivelFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationNivelFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationNivel',
      schemaKey: 'GameficationNivelFindMany',
      query: query.GameficationNivelFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationNivelFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationNivel',
      schemaKey: 'GameficationNivelFindOne',
      query: query.GameficationNivelFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationNivelPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationNivelPagination',
      schemaKey: 'GameficationNivelPagination',
      query: query.GameficationNivelPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationNivelCount: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Int',
      schemaKey: 'GameficationNivelCount',
      query: query.GameficationNivelCount(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationNivelCustomFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationNivelCustom',
      schemaKey: 'GameficationNivelCustomFindById',
      query: query.GameficationNivelCustomFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationNivelCustomFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationNivelCustom',
      schemaKey: 'GameficationNivelCustomFindMany',
      query: query.GameficationNivelCustomFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationNivelCustomFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationNivelCustom',
      schemaKey: 'GameficationNivelCustomFindOne',
      query: query.GameficationNivelCustomFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationNivelCustomPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationNivelCustomPagination',
      schemaKey: 'GameficationNivelCustomPagination',
      query: query.GameficationNivelCustomPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ChallengeFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Challenge',
      schemaKey: 'ChallengeFindById',
      query: query.ChallengeFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ChallengeFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Challenge',
      schemaKey: 'ChallengeFindMany',
      query: query.ChallengeFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ChallengeFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Challenge',
      schemaKey: 'ChallengeFindOne',
      query: query.ChallengeFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ChallengePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ChallengePagination',
      schemaKey: 'ChallengePagination',
      query: query.ChallengePagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ChallengeCount: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Int',
      schemaKey: 'ChallengeCount',
      query: query.ChallengeCount(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserChallengeFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserChallenge',
      schemaKey: 'UserChallengeFindById',
      query: query.UserChallengeFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserChallengeFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserChallenge',
      schemaKey: 'UserChallengeFindMany',
      query: query.UserChallengeFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserChallengeFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserChallenge',
      schemaKey: 'UserChallengeFindOne',
      query: query.UserChallengeFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserChallengePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserChallengePagination',
      schemaKey: 'UserChallengePagination',
      query: query.UserChallengePagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserChallengeAnswersDownload: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'BaseDownload',
      schemaKey: 'UserChallengeAnswersDownload',
      query: query.UserChallengeAnswersDownload(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserChallengeQuizDownload: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'BaseDownload',
      schemaKey: 'UserChallengeQuizDownload',
      query: query.UserChallengeQuizDownload(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationRankingFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationRanking',
      schemaKey: 'GameficationRankingFindById',
      query: query.GameficationRankingFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationRankingFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationRanking',
      schemaKey: 'GameficationRankingFindMany',
      query: query.GameficationRankingFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationRankingFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationRanking',
      schemaKey: 'GameficationRankingFindOne',
      query: query.GameficationRankingFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationRankingPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationRankingPagination',
      schemaKey: 'GameficationRankingPagination',
      query: query.GameficationRankingPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationRankingCount: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Int',
      schemaKey: 'GameficationRankingCount',
      query: query.GameficationRankingCount(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationUserRankingFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationUserRanking',
      schemaKey: 'GameficationUserRankingFindById',
      query: query.GameficationUserRankingFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationUserRankingFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationUserRanking',
      schemaKey: 'GameficationUserRankingFindMany',
      query: query.GameficationUserRankingFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationUserRankingFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationUserRanking',
      schemaKey: 'GameficationUserRankingFindOne',
      query: query.GameficationUserRankingFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationUserRankingPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'GameficationUserRankingPagination',
      schemaKey: 'GameficationUserRankingPagination',
      query: query.GameficationUserRankingPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationUserRankingCount: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Int',
      schemaKey: 'GameficationUserRankingCount',
      query: query.GameficationUserRankingCount(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserChallengeQuizAnswers: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserChallengeQuizAnswersPayload',
      schemaKey: 'UserChallengeQuizAnswers',
      query: query.UserChallengeQuizAnswers(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserChallengePointsFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserChallengePoints',
      schemaKey: 'UserChallengePointsFindById',
      query: query.UserChallengePointsFindById(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserChallengePointsFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserChallengePoints',
      schemaKey: 'UserChallengePointsFindMany',
      query: query.UserChallengePointsFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserChallengePointsFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserChallengePoints',
      schemaKey: 'UserChallengePointsFindOne',
      query: query.UserChallengePointsFindOne(config),
      kind: OpKind.query,
      ...config,
    });
  },

  UserChallengePointsPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserChallengePointsPagination',
      schemaKey: 'UserChallengePointsPagination',
      query: query.UserChallengePointsPagination(config),
      kind: OpKind.query,
      ...config,
    });
  },

  GameficationMaterialSearch: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Material',
      schemaKey: 'GameficationMaterialSearch',
      query: query.GameficationMaterialSearch(config),
      kind: OpKind.query,
      ...config,
    });
  },

  MyRankingPosition: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MyRankingPositionResult',
      schemaKey: 'MyRankingPosition',
      query: query.MyRankingPosition(config),
      kind: OpKind.query,
      ...config,
    });
  },

  RankingDownload: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'BaseDownload',
        schemaKey: 'RankingDownload',
        query: query.RankingDownload(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  KpiMapOptions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'JSON',
        schemaKey: 'KpiMapOptions',
        query: query.KpiMapOptions(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  ImportHierarchy: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'Boolean',
        schemaKey: 'ImportHierarchy',
        query: query.ImportHierarchy(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  ImportKPIs: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'Boolean',
        schemaKey: 'ImportKPIs',
        query: query.ImportKPIs(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  KpiDataFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'KpiData',
      schemaKey: 'KpiDataFindMany',
      query: query.KpiDataFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  HierarchyHistoryFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'HierarchyHistory',
      schemaKey: 'HierarchyHistoryFindMany',
      query: query.HierarchyHistoryFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ReconheceInvoiceOriginFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReconheceInvoiceOrigin',
      schemaKey: 'ReconheceInvoiceOriginFindMany',
      query: query.ReconheceInvoiceOriginFindMany(config),
      kind: OpKind.query,
      ...config,
    });
  },

  ReconheceUserBalance: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'ReconheceUserBalancePayload',
        schemaKey: 'ReconheceUserBalance',
        query: query.ReconheceUserBalance(config),
        kind: OpKind.query,
        ...config,
      },
    );
  },

  CampaignContestationCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneCampaignContestationPayload',
      schemaKey: 'CampaignContestationCreateOne',
      query: query.CampaignContestationCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  CampaignContestationResolve: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CampaignContestation',
      schemaKey: 'CampaignContestationResolve',
      query: query.CampaignContestationResolve(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  IncentiveCampaignCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneIncentiveCampaignPayload',
      schemaKey: 'IncentiveCampaignCreateOne',
      query: query.IncentiveCampaignCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  IncentiveCampaignUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneIncentiveCampaignPayload',
      schemaKey: 'IncentiveCampaignUpdateOne',
      query: query.IncentiveCampaignUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  IncentiveCampaignRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdIncentiveCampaignPayload',
      schemaKey: 'IncentiveCampaignRemoveById',
      query: query.IncentiveCampaignRemoveById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  IncentiveCampaignPayPointsNew: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'IncentiveCampaignPayPointsNew',
      query: query.IncentiveCampaignPayPointsNew(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ParticipateCampaign: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'ParticipateCampaign',
      query: query.ParticipateCampaign(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  HighlightedRejectedCampaign: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'HighlightedRejectedCampaign',
      query: query.HighlightedRejectedCampaign(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  IncentiveCampaignLikeToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'IncentiveCampaignLikeToggle',
      query: query.IncentiveCampaignLikeToggle(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ExternalCampaignCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneExternalCampaignPayload',
      schemaKey: 'ExternalCampaignCreateOne',
      query: query.ExternalCampaignCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ExternalCampaignUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneExternalCampaignPayload',
      schemaKey: 'ExternalCampaignUpdateOne',
      query: query.ExternalCampaignUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  AcceptExternalCampaign: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'AcceptExternalCampaign',
      query: query.AcceptExternalCampaign(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  RegionRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneRegionPayload',
      schemaKey: 'RegionRemoveOne',
      query: query.RegionRemoveOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserContentPermissionToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserContentPermissionToggle',
      query: query.UserContentPermissionToggle(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneUserPayload',
      schemaKey: 'UserUpdateOne',
      query: query.UserUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdUserPayload',
      schemaKey: 'UserRemoveById',
      query: query.UserRemoveById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserAcceptTerm: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserAcceptTerm',
      query: query.UserAcceptTerm(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserLoginWithPassword: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserLoginWithPassword',
      query: query.UserLoginWithPassword(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserLoginWithToken: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserLoginWithToken',
      query: query.UserLoginWithToken(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserLoginRenewToken: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserLoginRenewToken',
      query: query.UserLoginRenewToken(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserSaveLogoutAnalytics: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'Boolean',
        schemaKey: 'UserSaveLogoutAnalytics',
        query: query.UserSaveLogoutAnalytics(config),
        kind: OpKind.mutation,
        ...config,
      },
    );
  },

  UserSaveLoginAnalyticsPCS: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'Boolean',
        schemaKey: 'UserSaveLoginAnalyticsPCS',
        query: query.UserSaveLoginAnalyticsPCS(config),
        kind: OpKind.mutation,
        ...config,
      },
    );
  },

  UserFillEmail: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserFillEmail',
      query: query.UserFillEmail(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserFillAvatarId: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserFillAvatarId',
      query: query.UserFillAvatarId(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserOptIn: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserOptIn',
      query: query.UserOptIn(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserMarkNotificationAsRead: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserMarkNotificationAsRead',
      query: query.UserMarkNotificationAsRead(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserDeleteNotification: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'String',
      schemaKey: 'UserDeleteNotification',
      query: query.UserDeleteNotification(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserDeleteManyNotification: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'String',
      schemaKey: 'UserDeleteManyNotification',
      query: query.UserDeleteManyNotification(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserRegistration: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserRegistrationPayload',
      schemaKey: 'UserRegistration',
      query: query.UserRegistration(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserBlockComments: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserBlockComments',
      query: query.UserBlockComments(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ChangeToUserTaNarede: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'UserTaNaRede',
        schemaKey: 'ChangeToUserTaNarede',
        query: query.ChangeToUserTaNarede(config),
        kind: OpKind.mutation,
        ...config,
      },
    );
  },

  UserChangePassword: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserChangePassword',
      query: query.UserChangePassword(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserAzureLogin: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserAzureLogin',
      query: query.UserAzureLogin(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  FileCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneFilePayload',
      schemaKey: 'FileCreateOne',
      query: query.FileCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  FileRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdFilePayload',
      schemaKey: 'FileRemoveById',
      query: query.FileRemoveById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  FileUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdFilePayload',
      schemaKey: 'FileUpdateById',
      query: query.FileUpdateById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  MaterialCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneMaterialPayload',
      schemaKey: 'MaterialCreateOne',
      query: query.MaterialCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  MaterialUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneMaterialPayload',
      schemaKey: 'MaterialUpdateOne',
      query: query.MaterialUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  MaterialUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdMaterialPayload',
      schemaKey: 'MaterialUpdateById',
      query: query.MaterialUpdateById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  MaterialRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneMaterialPayload',
      schemaKey: 'MaterialRemoveOne',
      query: query.MaterialRemoveOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  CommunicationCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneMaterialPayload',
      schemaKey: 'CommunicationCreateOne',
      query: query.CommunicationCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  CommunicationUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneMaterialPayload',
      schemaKey: 'CommunicationUpdateOne',
      query: query.CommunicationUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  CommunicationApproveChannel: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'CommunicationApproveChannel',
      query: query.CommunicationApproveChannel(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  CommunicationDisapproveChannel: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'CommunicationDisapproveChannel',
      query: query.CommunicationDisapproveChannel(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  CommunicationRemoveApprover: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'CommunicationRemoveApprover',
      query: query.CommunicationRemoveApprover(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  EmailFillTemplate: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'String',
      schemaKey: 'EmailFillTemplate',
      query: query.EmailFillTemplate(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  EmailMarkAsRead: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'EmailMarkAsRead',
      query: query.EmailMarkAsRead(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  SendEmailFront: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'SendEmailFront',
      query: query.SendEmailFront(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  MaterialSendTestEmail: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'MaterialSendTestEmail',
      query: query.MaterialSendTestEmail(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  MaterialLikeToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'MaterialLikeToggle',
      query: query.MaterialLikeToggle(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UpdateDeteachedAndLatestVideo: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UpdateDeteachedAndLatestVideo',
      query: query.UpdateDeteachedAndLatestVideo(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  NotificationCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneNotificationPayload',
      schemaKey: 'NotificationCreateOne',
      query: query.NotificationCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  NotificationCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyNotificationPayload',
      schemaKey: 'NotificationCreateMany',
      query: query.NotificationCreateMany(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  NotificationUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneNotificationPayload',
      schemaKey: 'NotificationUpdateOne',
      query: query.NotificationUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  NotificationRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdNotificationPayload',
      schemaKey: 'NotificationRemoveById',
      query: query.NotificationRemoveById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  CategoryCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneCategoryPayload',
      schemaKey: 'CategoryCreateOne',
      query: query.CategoryCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  CategoryUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneCategoryPayload',
      schemaKey: 'CategoryUpdateOne',
      query: query.CategoryUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  CategoryRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdCategoryPayload',
      schemaKey: 'CategoryRemoveById',
      query: query.CategoryRemoveById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  CategoryCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyCategoryPayload',
      schemaKey: 'CategoryCreateMany',
      query: query.CategoryCreateMany(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  WebPushSubscribe: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'WebPushSubscribe',
      query: query.WebPushSubscribe(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  WebPushSendNotification: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'String',
      schemaKey: 'WebPushSendNotification',
      query: query.WebPushSendNotification(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  PinCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOnePinPayload',
      schemaKey: 'PinCreateOne',
      query: query.PinCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  PinRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOnePinPayload',
      schemaKey: 'PinRemoveOne',
      query: query.PinRemoveOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  PinRemoveMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveManyPinPayload',
      schemaKey: 'PinRemoveMany',
      query: query.PinRemoveMany(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ShareCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneSharePayload',
      schemaKey: 'ShareCreateOne',
      query: query.ShareCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ShareRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneSharePayload',
      schemaKey: 'ShareRemoveOne',
      query: query.ShareRemoveOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ShareRemoveMe: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'ShareRemoveMe',
      query: query.ShareRemoveMe(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  CommentCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneCommentPayload',
      schemaKey: 'CommentCreateOne',
      query: query.CommentCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  CommentUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneCommentPayload',
      schemaKey: 'CommentUpdateOne',
      query: query.CommentUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  CommentRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdCommentPayload',
      schemaKey: 'CommentRemoveById',
      query: query.CommentRemoveById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  CommentLikeToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'CommentLikeToggle',
      query: query.CommentLikeToggle(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  EventLiveCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneEventLivePayload',
      schemaKey: 'EventLiveCreateOne',
      query: query.EventLiveCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  EventLiveRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdEventLivePayload',
      schemaKey: 'EventLiveRemoveById',
      query: query.EventLiveRemoveById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  EventLiveUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneEventLivePayload',
      schemaKey: 'EventLiveUpdateOne',
      query: query.EventLiveUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  EventLiveCloseRoom: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'EventLiveCloseRoom',
      query: query.EventLiveCloseRoom(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  EventLiveLikeToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'EventLiveLikeToggle',
      query: query.EventLiveLikeToggle(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  BannerRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdBannerPayload',
      schemaKey: 'BannerRemoveById',
      query: query.BannerRemoveById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  BannerCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneBannerPayload',
      schemaKey: 'BannerCreateOne',
      query: query.BannerCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  BannerUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneBannerPayload',
      schemaKey: 'BannerUpdateOne',
      query: query.BannerUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  AnalyticsPageView: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'AnalyticsPageView',
      query: query.AnalyticsPageView(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  AnalyticsTrackEvent: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'AnalyticsTrackEvent',
      query: query.AnalyticsTrackEvent(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  FolderCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneFolderPayload',
      schemaKey: 'FolderCreateOne',
      query: query.FolderCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  FolderRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneFolderPayload',
      schemaKey: 'FolderRemoveOne',
      query: query.FolderRemoveOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  FolderRemoveMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveManyFolderPayload',
      schemaKey: 'FolderRemoveMany',
      query: query.FolderRemoveMany(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  FolderUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneFolderPayload',
      schemaKey: 'FolderUpdateOne',
      query: query.FolderUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  RecentsActivitiesCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneRecentsActivitiesPayload',
      schemaKey: 'RecentsActivitiesCreateOne',
      query: query.RecentsActivitiesCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserWordsBlackListCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneUserWordsBlackListPayload',
      schemaKey: 'UserWordsBlackListCreateOne',
      query: query.UserWordsBlackListCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ImportUsersPlanCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneImportUsersPlanPayload',
      schemaKey: 'ImportUsersPlanCreateOne',
      query: query.ImportUsersPlanCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ImportUsersPlanUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneImportUsersPlanPayload',
      schemaKey: 'ImportUsersPlanUpdateOne',
      query: query.ImportUsersPlanUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserHistoryBlockCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneUserHistoryBlockPayload',
      schemaKey: 'UserHistoryBlockCreateOne',
      query: query.UserHistoryBlockCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ReconheceWalletRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdReconheceWalletPayload',
      schemaKey: 'ReconheceWalletRemoveById',
      query: query.ReconheceWalletRemoveById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ReconheceWalletCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneReconheceWalletPayload',
      schemaKey: 'ReconheceWalletCreateOne',
      query: query.ReconheceWalletCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ReconheceWalletUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdReconheceWalletPayload',
      schemaKey: 'ReconheceWalletUpdateById',
      query: query.ReconheceWalletUpdateById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  SetWarningAt: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'WarningAtResult',
      schemaKey: 'SetWarningAt',
      query: query.SetWarningAt(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  EmailStatsCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyEmailStatsPayload',
      schemaKey: 'EmailStatsCreateMany',
      query: query.EmailStatsCreateMany(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  EmailStatsCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneEmailStatsPayload',
      schemaKey: 'EmailStatsCreateOne',
      query: query.EmailStatsCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  EmailUnsubscribeCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyEmailUnsubscribePayload',
      schemaKey: 'EmailUnsubscribeCreateMany',
      query: query.EmailUnsubscribeCreateMany(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  EmailUnsubscribeCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneEmailUnsubscribePayload',
      schemaKey: 'EmailUnsubscribeCreateOne',
      query: query.EmailUnsubscribeCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  FileContentCampaignCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneFileContentCampaignPayload',
      schemaKey: 'FileContentCampaignCreateOne',
      query: query.FileContentCampaignCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  FileContentCampaignUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneFileContentCampaignPayload',
      schemaKey: 'FileContentCampaignUpdateOne',
      query: query.FileContentCampaignUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  FileContentCampaignRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdFileContentCampaignPayload',
      schemaKey: 'FileContentCampaignRemoveById',
      query: query.FileContentCampaignRemoveById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserCampaignCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneUserCampaignPayload',
      schemaKey: 'UserCampaignCreateOne',
      query: query.UserCampaignCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserCampaignUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneUserCampaignPayload',
      schemaKey: 'UserCampaignUpdateOne',
      query: query.UserCampaignUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  MeuPdvParamsMutation: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MeuDiaPdv',
      schemaKey: 'MeuPdvParamsMutation',
      query: query.MeuPdvParamsMutation(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ConsumerTempPassword: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'ConsumerTempPassword',
      query: query.ConsumerTempPassword(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ConsumerNewPassword: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'ConsumerNewPassword',
      query: query.ConsumerNewPassword(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  SincronizeUserGU: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'LogsImportGU',
      schemaKey: 'SincronizeUserGU',
      query: query.SincronizeUserGU(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  SincronizeRoleGroup: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'LogsImportGU',
      schemaKey: 'SincronizeRoleGroup',
      query: query.SincronizeRoleGroup(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  MeuDiaPdvTableCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneMeuDiaPdvTablePayload',
      schemaKey: 'MeuDiaPdvTableCreateOne',
      query: query.MeuDiaPdvTableCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  MeuDiaPdvTableUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneMeuDiaPdvTablePayload',
      schemaKey: 'MeuDiaPdvTableUpdateOne',
      query: query.MeuDiaPdvTableUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  MeuDiaPdvTableRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdMeuDiaPdvTablePayload',
      schemaKey: 'MeuDiaPdvTableRemoveById',
      query: query.MeuDiaPdvTableRemoveById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  MeuDiaPdvTableCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyMeuDiaPdvTablePayload',
      schemaKey: 'MeuDiaPdvTableCreateMany',
      query: query.MeuDiaPdvTableCreateMany(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationNivelCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneGameficationNivelPayload',
      schemaKey: 'GameficationNivelCreateOne',
      query: query.GameficationNivelCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationNivelUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneGameficationNivelPayload',
      schemaKey: 'GameficationNivelUpdateOne',
      query: query.GameficationNivelUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationNivelUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdGameficationNivelPayload',
      schemaKey: 'GameficationNivelUpdateById',
      query: query.GameficationNivelUpdateById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationNivelRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneGameficationNivelPayload',
      schemaKey: 'GameficationNivelRemoveOne',
      query: query.GameficationNivelRemoveOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationNivelCustomCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneGameficationNivelCustomPayload',
      schemaKey: 'GameficationNivelCustomCreateOne',
      query: query.GameficationNivelCustomCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationNivelCustomUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneGameficationNivelCustomPayload',
      schemaKey: 'GameficationNivelCustomUpdateOne',
      query: query.GameficationNivelCustomUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationNivelCustomUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdGameficationNivelCustomPayload',
      schemaKey: 'GameficationNivelCustomUpdateById',
      query: query.GameficationNivelCustomUpdateById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationNivelCustomRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneGameficationNivelCustomPayload',
      schemaKey: 'GameficationNivelCustomRemoveOne',
      query: query.GameficationNivelCustomRemoveOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ChallengeCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneChallengePayload',
      schemaKey: 'ChallengeCreateOne',
      query: query.ChallengeCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ChallengeUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneChallengePayload',
      schemaKey: 'ChallengeUpdateOne',
      query: query.ChallengeUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ChallengeUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdChallengePayload',
      schemaKey: 'ChallengeUpdateById',
      query: query.ChallengeUpdateById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ChallengeRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneChallengePayload',
      schemaKey: 'ChallengeRemoveOne',
      query: query.ChallengeRemoveOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserChallengeUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdUserChallengePayload',
      schemaKey: 'UserChallengeUpdateById',
      query: query.UserChallengeUpdateById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserChallengeUpdateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateManyUserChallengePayload',
      schemaKey: 'UserChallengeUpdateMany',
      query: query.UserChallengeUpdateMany(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  MissionAction: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'MissionAction',
      query: query.MissionAction(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationRankingCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneGameficationRankingPayload',
      schemaKey: 'GameficationRankingCreateOne',
      query: query.GameficationRankingCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationRankingUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneGameficationRankingPayload',
      schemaKey: 'GameficationRankingUpdateOne',
      query: query.GameficationRankingUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationRankingUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdGameficationRankingPayload',
      schemaKey: 'GameficationRankingUpdateById',
      query: query.GameficationRankingUpdateById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationRankingRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneGameficationRankingPayload',
      schemaKey: 'GameficationRankingRemoveOne',
      query: query.GameficationRankingRemoveOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationUserRankingCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneGameficationUserRankingPayload',
      schemaKey: 'GameficationUserRankingCreateOne',
      query: query.GameficationUserRankingCreateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationUserRankingUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneGameficationUserRankingPayload',
      schemaKey: 'GameficationUserRankingUpdateOne',
      query: query.GameficationUserRankingUpdateOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationUserRankingUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdGameficationUserRankingPayload',
      schemaKey: 'GameficationUserRankingUpdateById',
      query: query.GameficationUserRankingUpdateById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  GameficationUserRankingRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneGameficationUserRankingPayload',
      schemaKey: 'GameficationUserRankingRemoveOne',
      query: query.GameficationUserRankingRemoveOne(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserChallengePointsUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdUserChallengePointsPayload',
      schemaKey: 'UserChallengePointsUpdateById',
      query: query.UserChallengePointsUpdateById(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserChallengePointsUpdateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateManyUserChallengePointsPayload',
      schemaKey: 'UserChallengePointsUpdateMany',
      query: query.UserChallengePointsUpdateMany(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserCreditChallengePointsAction: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserCreditChallengePointsAction',
      query: query.UserCreditChallengePointsAction(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  UserCreditChallengePointsForManyAction: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserCreditChallengePointsForManyAction',
      query: query.UserCreditChallengePointsForManyAction(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  PortalCheck: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'PortalCheck',
      query: query.PortalCheck(config),
      kind: OpKind.mutation,
      ...config,
    });
  },

  ReconheceCreateTempToken: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'String',
        schemaKey: 'ReconheceCreateTempToken',
        query: query.ReconheceCreateTempToken(config),
        kind: OpKind.mutation,
        ...config,
      },
    );
  },
};

export const AppMethodsInfo = {
  CampaignContestationFindOne: {
    type: 'CampaignContestation',
    schemaKey: 'CampaignContestationFindOne',
    entityName: 'CampaignContestation',
    isList: false,
    argsTSName: 'QueryCampaignContestationFindOneArgs',
    returnTSName: "Query['CampaignContestationFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CampaignContestation',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneCampaignContestationInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneCampaignContestationInput' },
      ],
      name: 'CampaignContestationFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  CampaignContestationPagination: {
    type: 'CampaignContestationPagination',
    schemaKey: 'CampaignContestationPagination',
    entityName: 'CampaignContestationPagination',
    isList: false,
    argsTSName: 'QueryCampaignContestationPaginationArgs',
    returnTSName: "Query['CampaignContestationPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CampaignContestationPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyCampaignContestationInput' },
        { name: 'sort', description: null, type: 'SortFindManyCampaignContestationInput' },
      ],
      name: 'CampaignContestationPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  IncentiveCampaignFindMany: {
    type: '[IncentiveCampaign]',
    schemaKey: 'IncentiveCampaignFindMany',
    entityName: 'IncentiveCampaign',
    isList: true,
    argsTSName: 'QueryIncentiveCampaignFindManyArgs',
    returnTSName: "Query['IncentiveCampaignFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[IncentiveCampaign]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyIncentiveCampaignInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyIncentiveCampaignInput' },
      ],
      name: 'IncentiveCampaignFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  IncentiveCampaignPagination: {
    type: 'IncentiveCampaignPagination',
    schemaKey: 'IncentiveCampaignPagination',
    entityName: 'IncentiveCampaignPagination',
    isList: false,
    argsTSName: 'QueryIncentiveCampaignPaginationArgs',
    returnTSName: "Query['IncentiveCampaignPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'IncentiveCampaignPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyIncentiveCampaignInput' },
        { name: 'sort', description: null, type: 'SortFindManyIncentiveCampaignInput' },
      ],
      name: 'IncentiveCampaignPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  IncentiveCampaignFindOne: {
    type: 'IncentiveCampaign',
    schemaKey: 'IncentiveCampaignFindOne',
    entityName: 'IncentiveCampaign',
    isList: false,
    argsTSName: 'QueryIncentiveCampaignFindOneArgs',
    returnTSName: "Query['IncentiveCampaignFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'IncentiveCampaign',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneIncentiveCampaignInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneIncentiveCampaignInput' },
      ],
      name: 'IncentiveCampaignFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  IncentiveCampaignFindById: {
    type: 'IncentiveCampaign',
    schemaKey: 'IncentiveCampaignFindById',
    entityName: 'IncentiveCampaign',
    isList: false,
    argsTSName: 'QueryIncentiveCampaignFindByIdArgs',
    returnTSName: "Query['IncentiveCampaignFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'IncentiveCampaign',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'SortIncentiveCampaignEnum' },
      ],
      name: 'IncentiveCampaignFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  IncentiveCampaignStatusOptions: {
    type: '[IncentiveCampaignStatusOption]',
    schemaKey: 'IncentiveCampaignStatusOptions',
    entityName: 'IncentiveCampaignStatusOption',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['IncentiveCampaignStatusOptions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[IncentiveCampaignStatusOption]',
      args: [],
      name: 'IncentiveCampaignStatusOptions',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  CampaignGetDetails: {
    type: 'JSON',
    schemaKey: 'CampaignGetDetails',
    entityName: 'JSON',
    isList: false,
    argsTSName: 'QueryCampaignGetDetailsArgs',
    returnTSName: "Query['CampaignGetDetails']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'JSON',
      args: [
        { name: 'campaignId', description: null, type: 'String!' },
        { name: 'userId', description: null, type: 'String!' },
      ],
      name: 'CampaignGetDetails',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  CampaignGeRanking: {
    type: 'JSON',
    schemaKey: 'CampaignGeRanking',
    entityName: 'JSON',
    isList: false,
    argsTSName: 'QueryCampaignGeRankingArgs',
    returnTSName: "Query['CampaignGeRanking']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'JSON',
      args: [
        { name: 'campaignId', description: null, type: 'String!' },
        { name: 'reference', description: null, type: 'String!' },
      ],
      name: 'CampaignGeRanking',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  AnalyticsIncentiveCampaign: {
    type: '[AnalyticsIncentiveCampaign]',
    schemaKey: 'AnalyticsIncentiveCampaign',
    entityName: 'AnalyticsIncentiveCampaign',
    isList: true,
    argsTSName: 'QueryAnalyticsIncentiveCampaignArgs',
    returnTSName: "Query['AnalyticsIncentiveCampaign']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[AnalyticsIncentiveCampaign]',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int', defaultValue: 20 },
      ],
      name: 'AnalyticsIncentiveCampaign',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  IncentiveCampaignReport: {
    type: 'BaseDownload',
    schemaKey: 'IncentiveCampaignReport',
    entityName: 'BaseDownload',
    isList: false,
    argsTSName: 'QueryIncentiveCampaignReportArgs',
    returnTSName: "Query['IncentiveCampaignReport']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BaseDownload',
      args: [{ name: 'campaignId', description: null, type: 'String!' }],
      name: 'IncentiveCampaignReport',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  DownloadIncentiveReports: {
    type: 'IncentiveReportType!',
    schemaKey: 'DownloadIncentiveReports',
    entityName: 'IncentiveReportType',
    isList: false,
    argsTSName: 'QueryDownloadIncentiveReportsArgs',
    returnTSName: "Query['DownloadIncentiveReports']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'IncentiveReportType!',
      args: [
        { name: 'mode', description: null, type: 'String!' },
        { name: 'IncentiveCampaignIds', description: null, type: '[String]' },
      ],
      name: 'DownloadIncentiveReports',
      isDeprecated: false,
    },
    isNonNull: true,
    kind: 'query',
  },

  IncentiveCampaignDataFindMany: {
    type: '[IncentiveCampaignData]',
    schemaKey: 'IncentiveCampaignDataFindMany',
    entityName: 'IncentiveCampaignData',
    isList: true,
    argsTSName: 'QueryIncentiveCampaignDataFindManyArgs',
    returnTSName: "Query['IncentiveCampaignDataFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[IncentiveCampaignData]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyIncentiveCampaignDataInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyIncentiveCampaignDataInput' },
      ],
      name: 'IncentiveCampaignDataFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  IncentiveCampaignTypeDataFindMany: {
    type: '[IncentiveCampaignTypeData]',
    schemaKey: 'IncentiveCampaignTypeDataFindMany',
    entityName: 'IncentiveCampaignTypeData',
    isList: true,
    argsTSName: 'QueryIncentiveCampaignTypeDataFindManyArgs',
    returnTSName: "Query['IncentiveCampaignTypeDataFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[IncentiveCampaignTypeData]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyIncentiveCampaignTypeDataInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyIncentiveCampaignTypeDataInput' },
      ],
      name: 'IncentiveCampaignTypeDataFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ExternalCampaignPagination: {
    type: 'ExternalCampaignPagination',
    schemaKey: 'ExternalCampaignPagination',
    entityName: 'ExternalCampaignPagination',
    isList: false,
    argsTSName: 'QueryExternalCampaignPaginationArgs',
    returnTSName: "Query['ExternalCampaignPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ExternalCampaignPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyExternalCampaignInput' },
        { name: 'sort', description: null, type: 'SortFindManyExternalCampaignInput' },
      ],
      name: 'ExternalCampaignPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ExternalCampaignFindById: {
    type: 'ExternalCampaign',
    schemaKey: 'ExternalCampaignFindById',
    entityName: 'ExternalCampaign',
    isList: false,
    argsTSName: 'QueryExternalCampaignFindByIdArgs',
    returnTSName: "Query['ExternalCampaignFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ExternalCampaign',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'SortExternalCampaignEnum' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'ExternalCampaignFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  RegionFindMany: {
    type: '[Region]',
    schemaKey: 'RegionFindMany',
    entityName: 'Region',
    isList: true,
    argsTSName: 'QueryRegionFindManyArgs',
    returnTSName: "Query['RegionFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Region]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyRegionInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyRegionInput' },
      ],
      name: 'RegionFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  RegionFindOne: {
    type: 'Region',
    schemaKey: 'RegionFindOne',
    entityName: 'Region',
    isList: false,
    argsTSName: 'QueryRegionFindOneArgs',
    returnTSName: "Query['RegionFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Region',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneRegionInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneRegionInput' },
      ],
      name: 'RegionFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  RegionPagination: {
    type: 'RegionPagination',
    schemaKey: 'RegionPagination',
    entityName: 'RegionPagination',
    isList: false,
    argsTSName: 'QueryRegionPaginationArgs',
    returnTSName: "Query['RegionPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegionPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyRegionInput' },
        { name: 'sort', description: null, type: 'SortFindManyRegionInput' },
      ],
      name: 'RegionPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  RegionItemFindMany: {
    type: '[RegionItem]',
    schemaKey: 'RegionItemFindMany',
    entityName: 'RegionItem',
    isList: true,
    argsTSName: 'QueryRegionItemFindManyArgs',
    returnTSName: "Query['RegionItemFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[RegionItem]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyRegionItemInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyRegionItemInput' },
      ],
      name: 'RegionItemFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  RegionItemFindOne: {
    type: 'RegionItem',
    schemaKey: 'RegionItemFindOne',
    entityName: 'RegionItem',
    isList: false,
    argsTSName: 'QueryRegionItemFindOneArgs',
    returnTSName: "Query['RegionItemFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegionItem',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneRegionItemInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneRegionItemInput' },
      ],
      name: 'RegionItemFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  RegionItemPagination: {
    type: 'RegionItemPagination',
    schemaKey: 'RegionItemPagination',
    entityName: 'RegionItemPagination',
    isList: false,
    argsTSName: 'QueryRegionItemPaginationArgs',
    returnTSName: "Query['RegionItemPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegionItemPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyRegionItemInput' },
        { name: 'sort', description: null, type: 'SortFindManyRegionItemInput' },
      ],
      name: 'RegionItemPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  RegionItemDistinctDDD: {
    type: 'RegionItemDistinctDDD',
    schemaKey: 'RegionItemDistinctDDD',
    entityName: 'RegionItemDistinctDDD',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['RegionItemDistinctDDD']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca dos itens de região',
      deprecationReason: null,
      type: 'RegionItemDistinctDDD',
      args: [],
      name: 'RegionItemDistinctDDD',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  RegionItemDistinctRegionAndUF: {
    type: 'RegionItemDistinctRegionAndUF',
    schemaKey: 'RegionItemDistinctRegionAndUF',
    entityName: 'RegionItemDistinctRegionAndUF',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['RegionItemDistinctRegionAndUF']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Executa a busca dos itens distintos por regional e UF (não inclui THQ para isso será necessário adicionar um filtro)',
      deprecationReason: null,
      type: 'RegionItemDistinctRegionAndUF',
      args: [],
      name: 'RegionItemDistinctRegionAndUF',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  JobTitleFindOne: {
    type: 'JobTitle',
    schemaKey: 'JobTitleFindOne',
    entityName: 'JobTitle',
    isList: false,
    argsTSName: 'QueryJobTitleFindOneArgs',
    returnTSName: "Query['JobTitleFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'JobTitle',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneJobTitleInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneJobTitleInput' },
      ],
      name: 'JobTitleFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  JobTitleFindMany: {
    type: '[JobTitle]',
    schemaKey: 'JobTitleFindMany',
    entityName: 'JobTitle',
    isList: true,
    argsTSName: 'QueryJobTitleFindManyArgs',
    returnTSName: "Query['JobTitleFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[JobTitle]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyJobTitleInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyJobTitleInput' },
      ],
      name: 'JobTitleFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  RoleGroupFindById: {
    type: 'RoleGroup',
    schemaKey: 'RoleGroupFindById',
    entityName: 'RoleGroup',
    isList: false,
    argsTSName: 'QueryRoleGroupFindByIdArgs',
    returnTSName: "Query['RoleGroupFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RoleGroup',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'RoleGroupFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  RoleGroupFindOne: {
    type: 'RoleGroup',
    schemaKey: 'RoleGroupFindOne',
    entityName: 'RoleGroup',
    isList: false,
    argsTSName: 'QueryRoleGroupFindOneArgs',
    returnTSName: "Query['RoleGroupFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RoleGroup',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneRoleGroupInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneRoleGroupInput' },
      ],
      name: 'RoleGroupFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  RoleGroupFindMany: {
    type: '[RoleGroup]',
    schemaKey: 'RoleGroupFindMany',
    entityName: 'RoleGroup',
    isList: true,
    argsTSName: 'QueryRoleGroupFindManyArgs',
    returnTSName: "Query['RoleGroupFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[RoleGroup]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyRoleGroupInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyRoleGroupInput' },
      ],
      name: 'RoleGroupFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  RoleGroupPagination: {
    type: 'RoleGroupPagination',
    schemaKey: 'RoleGroupPagination',
    entityName: 'RoleGroupPagination',
    isList: false,
    argsTSName: 'QueryRoleGroupPaginationArgs',
    returnTSName: "Query['RoleGroupPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RoleGroupPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyRoleGroupInput' },
        { name: 'sort', description: null, type: 'SortFindManyRoleGroupInput' },
      ],
      name: 'RoleGroupPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ChannelFindMany: {
    type: '[Channel]',
    schemaKey: 'ChannelFindMany',
    entityName: 'Channel',
    isList: true,
    argsTSName: 'QueryChannelFindManyArgs',
    returnTSName: "Query['ChannelFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Channel]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyChannelInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyChannelInput' },
      ],
      name: 'ChannelFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ChannelFindOne: {
    type: 'Channel',
    schemaKey: 'ChannelFindOne',
    entityName: 'Channel',
    isList: false,
    argsTSName: 'QueryChannelFindOneArgs',
    returnTSName: "Query['ChannelFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Channel',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneChannelInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneChannelInput' },
      ],
      name: 'ChannelFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ChannelPagination: {
    type: 'ChannelPagination',
    schemaKey: 'ChannelPagination',
    entityName: 'ChannelPagination',
    isList: false,
    argsTSName: 'QueryChannelPaginationArgs',
    returnTSName: "Query['ChannelPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ChannelPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyChannelInput' },
        { name: 'sort', description: null, type: 'SortFindManyChannelInput' },
      ],
      name: 'ChannelPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserContentPermissionFindOne: {
    type: 'UserContentPermission',
    schemaKey: 'UserContentPermissionFindOne',
    entityName: 'UserContentPermission',
    isList: false,
    argsTSName: 'QueryUserContentPermissionFindOneArgs',
    returnTSName: "Query['UserContentPermissionFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserContentPermission',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneUserContentPermissionInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneUserContentPermissionInput' },
      ],
      name: 'UserContentPermissionFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ContentPermissionsHelper: {
    type: '[ContentPermissionsHelperOption]',
    schemaKey: 'ContentPermissionsHelper',
    entityName: 'ContentPermissionsHelperOption',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['ContentPermissionsHelper']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Lista de possiveis content-permissions que os usuários podem ter.',
      deprecationReason: null,
      type: '[ContentPermissionsHelperOption]',
      args: [],
      name: 'ContentPermissionsHelper',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserPagination: {
    type: 'UserPagination',
    schemaKey: 'UserPagination',
    entityName: 'UserPagination',
    isList: false,
    argsTSName: 'QueryUserPaginationArgs',
    returnTSName: "Query['UserPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyUserInput' },
        { name: 'sort', description: null, type: 'SortFindManyUserInput' },
      ],
      name: 'UserPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserFindByIds: {
    type: '[User]',
    schemaKey: 'UserFindByIds',
    entityName: 'User',
    isList: true,
    argsTSName: 'QueryUserFindByIdsArgs',
    returnTSName: "Query['UserFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[User]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsUserInput' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'UserFindByIds',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserFindById: {
    type: 'User',
    schemaKey: 'UserFindById',
    entityName: 'User',
    isList: false,
    argsTSName: 'QueryUserFindByIdArgs',
    returnTSName: "Query['UserFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'UserFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserFindOne: {
    type: 'User',
    schemaKey: 'UserFindOne',
    entityName: 'User',
    isList: false,
    argsTSName: 'QueryUserFindOneArgs',
    returnTSName: "Query['UserFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneUserInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneUserInput' },
      ],
      name: 'UserFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserFindMany: {
    type: '[User]',
    schemaKey: 'UserFindMany',
    entityName: 'User',
    isList: true,
    argsTSName: 'QueryUserFindManyArgs',
    returnTSName: "Query['UserFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[User]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyUserInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyUserInput' },
      ],
      name: 'UserFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  User: {
    type: 'User',
    schemaKey: 'User',
    entityName: 'User',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['User']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: { description: '', deprecationReason: null, type: 'User', args: [], name: 'User', isDeprecated: false },
    isNonNull: false,
    kind: 'query',
  },

  UserSearch: {
    type: '[UserSearch]',
    schemaKey: 'UserSearch',
    entityName: 'UserSearch',
    isList: true,
    argsTSName: 'QueryUserSearchArgs',
    returnTSName: "Query['UserSearch']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca do usuario',
      deprecationReason: null,
      type: '[UserSearch]',
      args: [
        { name: 'search', description: 'Nome parcial, email parcial ou ObjectId', type: 'String!' },
        { name: 'roleGroup', description: null, type: '[String]' },
      ],
      name: 'UserSearch',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserSearchPagination: {
    type: 'UserPagination',
    schemaKey: 'UserSearchPagination',
    entityName: 'UserPagination',
    isList: false,
    argsTSName: 'QueryUserSearchPaginationArgs',
    returnTSName: "Query['UserSearchPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'UserSearchPaginationFilterInput' },
        { name: 'sort', description: null, type: 'SortFindManyUserInput' },
      ],
      name: 'UserSearchPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  TextFindBlackList: {
    type: 'TextFindBlackList',
    schemaKey: 'TextFindBlackList',
    entityName: 'TextFindBlackList',
    isList: false,
    argsTSName: 'QueryTextFindBlackListArgs',
    returnTSName: "Query['TextFindBlackList']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'TextFindBlackList',
      args: [{ name: 'text', description: null, type: 'String!' }],
      name: 'TextFindBlackList',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserFindByEmails: {
    type: '[UserSimpleResult]',
    schemaKey: 'UserFindByEmails',
    entityName: 'UserSimpleResult',
    isList: true,
    argsTSName: 'QueryUserFindByEmailsArgs',
    returnTSName: "Query['UserFindByEmails']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca do usuario',
      deprecationReason: null,
      type: '[UserSimpleResult]',
      args: [{ name: 'emails', description: 'Lista de emails de usuários', type: '[String]!' }],
      name: 'UserFindByEmails',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserFindByMatricula: {
    type: '[User]',
    schemaKey: 'UserFindByMatricula',
    entityName: 'User',
    isList: true,
    argsTSName: 'QueryUserFindByMatriculaArgs',
    returnTSName: "Query['UserFindByMatricula']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[User]',
      args: [
        { name: 'isMatriculaNull', description: null, type: 'Boolean' },
        { name: 'email', description: null, type: 'String' },
        { name: 'name', description: null, type: 'String' },
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
      ],
      name: 'UserFindByMatricula',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  FileFindByIds: {
    type: '[File]',
    schemaKey: 'FileFindByIds',
    entityName: 'File',
    isList: true,
    argsTSName: 'QueryFileFindByIdsArgs',
    returnTSName: "Query['FileFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[File]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsFileInput' },
      ],
      name: 'FileFindByIds',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  FileFindById: {
    type: 'File',
    schemaKey: 'FileFindById',
    entityName: 'File',
    isList: false,
    argsTSName: 'QueryFileFindByIdArgs',
    returnTSName: "Query['FileFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'File',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'FileFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  FilePagination: {
    type: 'FilePagination',
    schemaKey: 'FilePagination',
    entityName: 'FilePagination',
    isList: false,
    argsTSName: 'QueryFilePaginationArgs',
    returnTSName: "Query['FilePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'FilePagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyFileInput' },
        { name: 'sort', description: null, type: 'SortFindManyFileInput' },
      ],
      name: 'FilePagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MaterialFindById: {
    type: 'Material',
    schemaKey: 'MaterialFindById',
    entityName: 'Material',
    isList: false,
    argsTSName: 'QueryMaterialFindByIdArgs',
    returnTSName: "Query['MaterialFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Material',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' },
      ],
      name: 'MaterialFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MaterialFindMany: {
    type: '[Material]',
    schemaKey: 'MaterialFindMany',
    entityName: 'Material',
    isList: true,
    argsTSName: 'QueryMaterialFindManyArgs',
    returnTSName: "Query['MaterialFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Material]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyMaterialInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyMaterialInput' },
      ],
      name: 'MaterialFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MaterialFindOne: {
    type: 'Material',
    schemaKey: 'MaterialFindOne',
    entityName: 'Material',
    isList: false,
    argsTSName: 'QueryMaterialFindOneArgs',
    returnTSName: "Query['MaterialFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Material',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneMaterialInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneMaterialInput' },
      ],
      name: 'MaterialFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MaterialPagination: {
    type: 'MaterialPagination',
    schemaKey: 'MaterialPagination',
    entityName: 'MaterialPagination',
    isList: false,
    argsTSName: 'QueryMaterialPaginationArgs',
    returnTSName: "Query['MaterialPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MaterialPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyMaterialInput' },
        { name: 'sort', description: null, type: 'SortFindManyMaterialInput' },
        { name: 'limit', description: null, type: 'Int' },
      ],
      name: 'MaterialPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MaterialCount: {
    type: 'Int',
    schemaKey: 'MaterialCount',
    entityName: 'Int',
    isList: false,
    argsTSName: 'QueryMaterialCountArgs',
    returnTSName: "Query['MaterialCount']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Int',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterMaterialInput' },
        { name: 'sort', description: null, type: 'sort' },
      ],
      name: 'MaterialCount',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MaterialStatusOptions: {
    type: '[MaterialStatusOption]',
    schemaKey: 'MaterialStatusOptions',
    entityName: 'MaterialStatusOption',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['MaterialStatusOptions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[MaterialStatusOption]',
      args: [],
      name: 'MaterialStatusOptions',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MaterialSearchOwners: {
    type: '[MaterialSearchOwners]',
    schemaKey: 'MaterialSearchOwners',
    entityName: 'MaterialSearchOwners',
    isList: true,
    argsTSName: 'QueryMaterialSearchOwnersArgs',
    returnTSName: "Query['MaterialSearchOwners']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[MaterialSearchOwners]',
      args: [{ name: 'kind', description: null, type: 'EnumMaterialKind' }],
      name: 'MaterialSearchOwners',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  EmailFindMany: {
    type: '[Email]',
    schemaKey: 'EmailFindMany',
    entityName: 'Email',
    isList: true,
    argsTSName: 'QueryEmailFindManyArgs',
    returnTSName: "Query['EmailFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Email]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyEmailInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyEmailInput' },
      ],
      name: 'EmailFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  EmailPagination: {
    type: 'EmailPagination',
    schemaKey: 'EmailPagination',
    entityName: 'EmailPagination',
    isList: false,
    argsTSName: 'QueryEmailPaginationArgs',
    returnTSName: "Query['EmailPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EmailPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyEmailInput' },
        { name: 'sort', description: null, type: 'SortFindManyEmailInput' },
      ],
      name: 'EmailPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  EmailFindOne: {
    type: 'Email',
    schemaKey: 'EmailFindOne',
    entityName: 'Email',
    isList: false,
    argsTSName: 'QueryEmailFindOneArgs',
    returnTSName: "Query['EmailFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Email',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneEmailInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneEmailInput' },
      ],
      name: 'EmailFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  EmailCheckRead: {
    type: 'Boolean',
    schemaKey: 'EmailCheckRead',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'QueryEmailCheckReadArgs',
    returnTSName: "Query['EmailCheckRead']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Retorna true se o email foi marcado como lido pelo destinatario informado',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'emailId', description: null, type: 'String!' },
        { name: 'userEmail', description: null, type: 'String!' },
      ],
      name: 'EmailCheckRead',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MaterialSearch: {
    type: 'MaterialSearch',
    schemaKey: 'MaterialSearch',
    entityName: 'MaterialSearch',
    isList: false,
    argsTSName: 'QueryMaterialSearchArgs',
    returnTSName: "Query['MaterialSearch']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'MaterialSearch',
      args: [
        { name: 'search', description: null, type: 'String!' },
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int', defaultValue: 20 },
        { name: 'sort', description: null, type: 'SortFindManyMaterialInput' },
        { name: 'category', description: null, type: 'String' },
      ],
      name: 'MaterialSearch',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MaterialFindPinned: {
    type: 'MaterialPagination',
    schemaKey: 'MaterialFindPinned',
    entityName: 'MaterialPagination',
    isList: false,
    argsTSName: 'QueryMaterialFindPinnedArgs',
    returnTSName: "Query['MaterialFindPinned']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MaterialPagination',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int' },
        { name: 'filter', description: null, type: 'FilterFindManyMaterialInput' },
        { name: 'sort', description: null, type: 'SortFindManyMaterialInput' },
      ],
      name: 'MaterialFindPinned',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  NotificationFindMany: {
    type: '[Notification]',
    schemaKey: 'NotificationFindMany',
    entityName: 'Notification',
    isList: true,
    argsTSName: 'QueryNotificationFindManyArgs',
    returnTSName: "Query['NotificationFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Notification]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyNotificationInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyNotificationInput' },
      ],
      name: 'NotificationFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  NotificationFindOne: {
    type: 'Notification',
    schemaKey: 'NotificationFindOne',
    entityName: 'Notification',
    isList: false,
    argsTSName: 'QueryNotificationFindOneArgs',
    returnTSName: "Query['NotificationFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Notification',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneNotificationInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneNotificationInput' },
      ],
      name: 'NotificationFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  NotificationPagination: {
    type: 'NotificationPagination',
    schemaKey: 'NotificationPagination',
    entityName: 'NotificationPagination',
    isList: false,
    argsTSName: 'QueryNotificationPaginationArgs',
    returnTSName: "Query['NotificationPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'NotificationPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyNotificationInput' },
        { name: 'sort', description: null, type: 'SortFindManyNotificationInput' },
      ],
      name: 'NotificationPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  HelperAddress: {
    type: 'AddressHelperPayload',
    schemaKey: 'HelperAddress',
    entityName: 'AddressHelperPayload',
    isList: false,
    argsTSName: 'QueryHelperAddressArgs',
    returnTSName: "Query['HelperAddress']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'AddressHelperPayload',
      args: [{ name: 'zipCode', description: null, type: 'String!' }],
      name: 'HelperAddress',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  CategoryFindOne: {
    type: 'Category',
    schemaKey: 'CategoryFindOne',
    entityName: 'Category',
    isList: false,
    argsTSName: 'QueryCategoryFindOneArgs',
    returnTSName: "Query['CategoryFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Category',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneCategoryInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneCategoryInput' },
      ],
      name: 'CategoryFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  CategoryFindById: {
    type: 'Category',
    schemaKey: 'CategoryFindById',
    entityName: 'Category',
    isList: false,
    argsTSName: 'QueryCategoryFindByIdArgs',
    returnTSName: "Query['CategoryFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Category',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'SortMenuEnum' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'CategoryFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  CategoryFindMany: {
    type: '[Category]',
    schemaKey: 'CategoryFindMany',
    entityName: 'Category',
    isList: true,
    argsTSName: 'QueryCategoryFindManyArgs',
    returnTSName: "Query['CategoryFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Category]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyCategoryInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyCategoryInput' },
      ],
      name: 'CategoryFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  CategoryFindByIds: {
    type: '[Category]',
    schemaKey: 'CategoryFindByIds',
    entityName: 'Category',
    isList: true,
    argsTSName: 'QueryCategoryFindByIdsArgs',
    returnTSName: "Query['CategoryFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Category]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsCategoryInput' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'CategoryFindByIds',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  WebPushPublicKey: {
    type: 'String!',
    schemaKey: 'WebPushPublicKey',
    entityName: 'String',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['WebPushPublicKey']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Retorna a chave publica (VAPID key) pra a subscription de web push notifications',
      deprecationReason: null,
      type: 'String!',
      args: [],
      name: 'WebPushPublicKey',
      isDeprecated: false,
    },
    isNonNull: true,
    kind: 'query',
  },

  PinFindMany: {
    type: '[Pin]',
    schemaKey: 'PinFindMany',
    entityName: 'Pin',
    isList: true,
    argsTSName: 'QueryPinFindManyArgs',
    returnTSName: "Query['PinFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Pin]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyPinInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyPinInput' },
      ],
      name: 'PinFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  PinPagination: {
    type: 'PinPagination',
    schemaKey: 'PinPagination',
    entityName: 'PinPagination',
    isList: false,
    argsTSName: 'QueryPinPaginationArgs',
    returnTSName: "Query['PinPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PinPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyPinInput' },
        { name: 'sort', description: null, type: 'SortFindManyPinInput' },
      ],
      name: 'PinPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ShareFindMany: {
    type: '[Share]',
    schemaKey: 'ShareFindMany',
    entityName: 'Share',
    isList: true,
    argsTSName: 'QueryShareFindManyArgs',
    returnTSName: "Query['ShareFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Share]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyShareInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyShareInput' },
      ],
      name: 'ShareFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  SharePagination: {
    type: 'SharePagination',
    schemaKey: 'SharePagination',
    entityName: 'SharePagination',
    isList: false,
    argsTSName: 'QuerySharePaginationArgs',
    returnTSName: "Query['SharePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'SharePagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyShareInput' },
        { name: 'sort', description: null, type: 'SortFindManyShareInput' },
      ],
      name: 'SharePagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  CommentPagination: {
    type: 'CommentPagination',
    schemaKey: 'CommentPagination',
    entityName: 'CommentPagination',
    isList: false,
    argsTSName: 'QueryCommentPaginationArgs',
    returnTSName: "Query['CommentPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CommentPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyCommentInput' },
        { name: 'sort', description: null, type: 'SortFindManyCommentInput' },
      ],
      name: 'CommentPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  CommentFindOne: {
    type: 'Comment',
    schemaKey: 'CommentFindOne',
    entityName: 'Comment',
    isList: false,
    argsTSName: 'QueryCommentFindOneArgs',
    returnTSName: "Query['CommentFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Comment',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneCommentInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneCommentInput' },
      ],
      name: 'CommentFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  CommentFindById: {
    type: 'Comment',
    schemaKey: 'CommentFindById',
    entityName: 'Comment',
    isList: false,
    argsTSName: 'QueryCommentFindByIdArgs',
    returnTSName: "Query['CommentFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Comment',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'CommentFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  CommentFindMany: {
    type: '[Comment]',
    schemaKey: 'CommentFindMany',
    entityName: 'Comment',
    isList: true,
    argsTSName: 'QueryCommentFindManyArgs',
    returnTSName: "Query['CommentFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Comment]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyCommentInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyCommentInput' },
      ],
      name: 'CommentFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  CommentSortPagination: {
    type: 'CommentPagination',
    schemaKey: 'CommentSortPagination',
    entityName: 'CommentPagination',
    isList: false,
    argsTSName: 'QueryCommentSortPaginationArgs',
    returnTSName: "Query['CommentSortPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CommentPagination',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int' },
        { name: 'filter', description: null, type: 'CommentSortPaginationFilterInput' },
        { name: 'sort', description: null, type: 'SortFindManyCommentInput' },
      ],
      name: 'CommentSortPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  EventLivePagination: {
    type: 'EventLivePagination',
    schemaKey: 'EventLivePagination',
    entityName: 'EventLivePagination',
    isList: false,
    argsTSName: 'QueryEventLivePaginationArgs',
    returnTSName: "Query['EventLivePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EventLivePagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyEventLiveInput' },
        { name: 'sort', description: null, type: 'SortFindManyEventLiveInput' },
      ],
      name: 'EventLivePagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  EventLiveFindOne: {
    type: 'EventLive',
    schemaKey: 'EventLiveFindOne',
    entityName: 'EventLive',
    isList: false,
    argsTSName: 'QueryEventLiveFindOneArgs',
    returnTSName: "Query['EventLiveFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EventLive',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneEventLiveInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneEventLiveInput' },
      ],
      name: 'EventLiveFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  EventLiveFindById: {
    type: 'EventLive',
    schemaKey: 'EventLiveFindById',
    entityName: 'EventLive',
    isList: false,
    argsTSName: 'QueryEventLiveFindByIdArgs',
    returnTSName: "Query['EventLiveFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EventLive',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'EventLiveFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  BannerFindMany: {
    type: '[Banner]',
    schemaKey: 'BannerFindMany',
    entityName: 'Banner',
    isList: true,
    argsTSName: 'QueryBannerFindManyArgs',
    returnTSName: "Query['BannerFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Banner]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyBannerInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyBannerInput' },
      ],
      name: 'BannerFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  BannerFindOne: {
    type: 'Banner',
    schemaKey: 'BannerFindOne',
    entityName: 'Banner',
    isList: false,
    argsTSName: 'QueryBannerFindOneArgs',
    returnTSName: "Query['BannerFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Banner',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneBannerInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneBannerInput' },
      ],
      name: 'BannerFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  BannerPagination: {
    type: 'BannerPagination',
    schemaKey: 'BannerPagination',
    entityName: 'BannerPagination',
    isList: false,
    argsTSName: 'QueryBannerPaginationArgs',
    returnTSName: "Query['BannerPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BannerPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyBannerInput' },
        { name: 'sort', description: null, type: 'SortFindManyBannerInput' },
      ],
      name: 'BannerPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  AnalyticsCount: {
    type: 'Int',
    schemaKey: 'AnalyticsCount',
    entityName: 'Int',
    isList: false,
    argsTSName: 'QueryAnalyticsCountArgs',
    returnTSName: "Query['AnalyticsCount']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Int',
      args: [{ name: 'filter', description: 'Filter by fields', type: 'FilterAnalyticsInput' }],
      name: 'AnalyticsCount',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  AnalyticsPagination: {
    type: 'AnalyticsPagination',
    schemaKey: 'AnalyticsPagination',
    entityName: 'AnalyticsPagination',
    isList: false,
    argsTSName: 'QueryAnalyticsPaginationArgs',
    returnTSName: "Query['AnalyticsPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'AnalyticsPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyAnalyticsInput' },
        { name: 'sort', description: null, type: 'SortFindManyAnalyticsInput' },
      ],
      name: 'AnalyticsPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  AnalyticsUsersReport: {
    type: '[AnalyticsUser]',
    schemaKey: 'AnalyticsUsersReport',
    entityName: 'AnalyticsUser',
    isList: true,
    argsTSName: 'QueryAnalyticsUsersReportArgs',
    returnTSName: "Query['AnalyticsUsersReport']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[AnalyticsUser]',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int', defaultValue: 20 },
      ],
      name: 'AnalyticsUsersReport',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  AnalyticsFindReport: {
    type: '[AnalyticsReportItem]',
    schemaKey: 'AnalyticsFindReport',
    entityName: 'AnalyticsReportItem',
    isList: true,
    argsTSName: 'QueryAnalyticsFindReportArgs',
    returnTSName: "Query['AnalyticsFindReport']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsReportItem]',
      args: [
        { name: 'filter', description: null, type: 'AnalyticsReportFilterInput' },
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
      ],
      name: 'AnalyticsFindReport',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  AnalyticsUserNotification: {
    type: '[AnalyticsUserNotification]',
    schemaKey: 'AnalyticsUserNotification',
    entityName: 'AnalyticsUserNotification',
    isList: true,
    argsTSName: 'QueryAnalyticsUserNotificationArgs',
    returnTSName: "Query['AnalyticsUserNotification']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsUserNotification]',
      args: [
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
      ],
      name: 'AnalyticsUserNotification',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  AnalyticsPin: {
    type: '[AnalyticsPin]',
    schemaKey: 'AnalyticsPin',
    entityName: 'AnalyticsPin',
    isList: true,
    argsTSName: 'QueryAnalyticsPinArgs',
    returnTSName: "Query['AnalyticsPin']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsPin]',
      args: [
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
      ],
      name: 'AnalyticsPin',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  AnalyticsComment: {
    type: '[AnalyticsComment]',
    schemaKey: 'AnalyticsComment',
    entityName: 'AnalyticsComment',
    isList: true,
    argsTSName: 'QueryAnalyticsCommentArgs',
    returnTSName: "Query['AnalyticsComment']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsComment]',
      args: [
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
      ],
      name: 'AnalyticsComment',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  AnalyticsPoints: {
    type: '[AnalyticsPoints]',
    schemaKey: 'AnalyticsPoints',
    entityName: 'AnalyticsPoints',
    isList: true,
    argsTSName: 'QueryAnalyticsPointsArgs',
    returnTSName: "Query['AnalyticsPoints']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsPoints]',
      args: [
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
      ],
      name: 'AnalyticsPoints',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  AnalyticsDownloads: {
    type: 'Boolean',
    schemaKey: 'AnalyticsDownloads',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'QueryAnalyticsDownloadsArgs',
    returnTSName: "Query['AnalyticsDownloads']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'fileId', description: null, type: 'String' },
        { name: 'materialId', description: null, type: 'String' },
      ],
      name: 'AnalyticsDownloads',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  SearchCountFindMany: {
    type: '[SearchCount]',
    schemaKey: 'SearchCountFindMany',
    entityName: 'SearchCount',
    isList: true,
    argsTSName: 'QuerySearchCountFindManyArgs',
    returnTSName: "Query['SearchCountFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[SearchCount]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManySearchCountInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManySearchCountInput' },
      ],
      name: 'SearchCountFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  SearchCountPagination: {
    type: 'SearchCountPagination',
    schemaKey: 'SearchCountPagination',
    entityName: 'SearchCountPagination',
    isList: false,
    argsTSName: 'QuerySearchCountPaginationArgs',
    returnTSName: "Query['SearchCountPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'SearchCountPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManySearchCountInput' },
        { name: 'sort', description: null, type: 'SortFindManySearchCountInput' },
      ],
      name: 'SearchCountPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  FolderFindMany: {
    type: '[Folder]',
    schemaKey: 'FolderFindMany',
    entityName: 'Folder',
    isList: true,
    argsTSName: 'QueryFolderFindManyArgs',
    returnTSName: "Query['FolderFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Folder]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyFolderInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyFolderInput' },
      ],
      name: 'FolderFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  FolderFindById: {
    type: 'Folder',
    schemaKey: 'FolderFindById',
    entityName: 'Folder',
    isList: false,
    argsTSName: 'QueryFolderFindByIdArgs',
    returnTSName: "Query['FolderFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Folder',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'FolderFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  FolderPagination: {
    type: 'FolderPagination',
    schemaKey: 'FolderPagination',
    entityName: 'FolderPagination',
    isList: false,
    argsTSName: 'QueryFolderPaginationArgs',
    returnTSName: "Query['FolderPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'FolderPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyFolderInput' },
        { name: 'sort', description: null, type: 'SortFindManyFolderInput' },
      ],
      name: 'FolderPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  RecentsActivitiesPagination: {
    type: 'RecentsActivitiesPagination',
    schemaKey: 'RecentsActivitiesPagination',
    entityName: 'RecentsActivitiesPagination',
    isList: false,
    argsTSName: 'QueryRecentsActivitiesPaginationArgs',
    returnTSName: "Query['RecentsActivitiesPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RecentsActivitiesPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyRecentsActivitiesInput' },
        { name: 'sort', description: null, type: 'SortFindManyRecentsActivitiesInput' },
      ],
      name: 'RecentsActivitiesPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserWordsBlackListFindMany: {
    type: '[UserWordsBlackList]',
    schemaKey: 'UserWordsBlackListFindMany',
    entityName: 'UserWordsBlackList',
    isList: true,
    argsTSName: 'QueryUserWordsBlackListFindManyArgs',
    returnTSName: "Query['UserWordsBlackListFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserWordsBlackList]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyUserWordsBlackListInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyUserWordsBlackListInput' },
      ],
      name: 'UserWordsBlackListFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserWordsBlackListFindById: {
    type: 'UserWordsBlackList',
    schemaKey: 'UserWordsBlackListFindById',
    entityName: 'UserWordsBlackList',
    isList: false,
    argsTSName: 'QueryUserWordsBlackListFindByIdArgs',
    returnTSName: "Query['UserWordsBlackListFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserWordsBlackList',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'UserWordsBlackListFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  DownloadSensitiveFilesPagination: {
    type: 'DownloadSensitiveFilesPagination',
    schemaKey: 'DownloadSensitiveFilesPagination',
    entityName: 'DownloadSensitiveFilesPagination',
    isList: false,
    argsTSName: 'QueryDownloadSensitiveFilesPaginationArgs',
    returnTSName: "Query['DownloadSensitiveFilesPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DownloadSensitiveFilesPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyDownloadSensitiveFilesInput' },
        { name: 'sort', description: null, type: 'SortFindManyDownloadSensitiveFilesInput' },
      ],
      name: 'DownloadSensitiveFilesPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  DownloadUsersBase: {
    type: 'DownloadUsersInfo',
    schemaKey: 'DownloadUsersBase',
    entityName: 'DownloadUsersInfo',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['DownloadUsersBase']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadUsersInfo',
      args: [],
      name: 'DownloadUsersBase',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ImportUsersPlanFindMany: {
    type: '[ImportUsersPlan]',
    schemaKey: 'ImportUsersPlanFindMany',
    entityName: 'ImportUsersPlan',
    isList: true,
    argsTSName: 'QueryImportUsersPlanFindManyArgs',
    returnTSName: "Query['ImportUsersPlanFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[ImportUsersPlan]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyImportUsersPlanInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyImportUsersPlanInput' },
      ],
      name: 'ImportUsersPlanFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ImportUsersPlanFindById: {
    type: 'ImportUsersPlan',
    schemaKey: 'ImportUsersPlanFindById',
    entityName: 'ImportUsersPlan',
    isList: false,
    argsTSName: 'QueryImportUsersPlanFindByIdArgs',
    returnTSName: "Query['ImportUsersPlanFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ImportUsersPlan',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'ImportUsersPlanFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ImportUsersPlanFindByIds: {
    type: '[ImportUsersPlan]',
    schemaKey: 'ImportUsersPlanFindByIds',
    entityName: 'ImportUsersPlan',
    isList: true,
    argsTSName: 'QueryImportUsersPlanFindByIdsArgs',
    returnTSName: "Query['ImportUsersPlanFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[ImportUsersPlan]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsImportUsersPlanInput' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'ImportUsersPlanFindByIds',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ImportUsersPlanPagination: {
    type: 'ImportUsersPlanPagination',
    schemaKey: 'ImportUsersPlanPagination',
    entityName: 'ImportUsersPlanPagination',
    isList: false,
    argsTSName: 'QueryImportUsersPlanPaginationArgs',
    returnTSName: "Query['ImportUsersPlanPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ImportUsersPlanPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyImportUsersPlanInput' },
        { name: 'sort', description: null, type: 'SortFindManyImportUsersPlanInput' },
      ],
      name: 'ImportUsersPlanPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserDeletedFindByIds: {
    type: '[UserDeleted]',
    schemaKey: 'UserDeletedFindByIds',
    entityName: 'UserDeleted',
    isList: true,
    argsTSName: 'QueryUserDeletedFindByIdsArgs',
    returnTSName: "Query['UserDeletedFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserDeleted]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsUserDeletedInput' },
      ],
      name: 'UserDeletedFindByIds',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserDeletedFindOne: {
    type: 'UserDeleted',
    schemaKey: 'UserDeletedFindOne',
    entityName: 'UserDeleted',
    isList: false,
    argsTSName: 'QueryUserDeletedFindOneArgs',
    returnTSName: "Query['UserDeletedFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserDeleted',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneUserDeletedInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneUserDeletedInput' },
      ],
      name: 'UserDeletedFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserDeletedFindMany: {
    type: '[UserDeleted]',
    schemaKey: 'UserDeletedFindMany',
    entityName: 'UserDeleted',
    isList: true,
    argsTSName: 'QueryUserDeletedFindManyArgs',
    returnTSName: "Query['UserDeletedFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserDeleted]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyUserDeletedInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyUserDeletedInput' },
      ],
      name: 'UserDeletedFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserHistoryBlockFindMany: {
    type: '[UserHistoryBlock]',
    schemaKey: 'UserHistoryBlockFindMany',
    entityName: 'UserHistoryBlock',
    isList: true,
    argsTSName: 'QueryUserHistoryBlockFindManyArgs',
    returnTSName: "Query['UserHistoryBlockFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserHistoryBlock]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyUserHistoryBlockInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyUserHistoryBlockInput' },
      ],
      name: 'UserHistoryBlockFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserHistoryBlockPagination: {
    type: 'UserHistoryBlockPagination',
    schemaKey: 'UserHistoryBlockPagination',
    entityName: 'UserHistoryBlockPagination',
    isList: false,
    argsTSName: 'QueryUserHistoryBlockPaginationArgs',
    returnTSName: "Query['UserHistoryBlockPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserHistoryBlockPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyUserHistoryBlockInput' },
        { name: 'sort', description: null, type: 'SortFindManyUserHistoryBlockInput' },
      ],
      name: 'UserHistoryBlockPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ReconheceWalletPagination: {
    type: 'ReconheceWalletPagination',
    schemaKey: 'ReconheceWalletPagination',
    entityName: 'ReconheceWalletPagination',
    isList: false,
    argsTSName: 'QueryReconheceWalletPaginationArgs',
    returnTSName: "Query['ReconheceWalletPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ReconheceWalletPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyReconheceWalletInput' },
        { name: 'sort', description: null, type: 'SortFindManyReconheceWalletInput' },
      ],
      name: 'ReconheceWalletPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ReconheceWalletFindMany: {
    type: '[ReconheceWallet]',
    schemaKey: 'ReconheceWalletFindMany',
    entityName: 'ReconheceWallet',
    isList: true,
    argsTSName: 'QueryReconheceWalletFindManyArgs',
    returnTSName: "Query['ReconheceWalletFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[ReconheceWallet]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyReconheceWalletInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyReconheceWalletInput' },
      ],
      name: 'ReconheceWalletFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ReconheceWalletFindById: {
    type: 'ReconheceWallet',
    schemaKey: 'ReconheceWalletFindById',
    entityName: 'ReconheceWallet',
    isList: false,
    argsTSName: 'QueryReconheceWalletFindByIdArgs',
    returnTSName: "Query['ReconheceWalletFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ReconheceWallet',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'ReconheceWalletFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ReconheceWalletSum: {
    type: 'ReconheceWalletSum',
    schemaKey: 'ReconheceWalletSum',
    entityName: 'ReconheceWalletSum',
    isList: false,
    argsTSName: 'QueryReconheceWalletSumArgs',
    returnTSName: "Query['ReconheceWalletSum']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ReconheceWalletSum',
      args: [
        { name: 'invoiceOrigin', description: null, type: 'String' },
        { name: 'createdAtRange', description: null, type: 'ReconheceWalletCreatedAtRange' },
      ],
      name: 'ReconheceWalletSum',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  WalletMovementPagination: {
    type: 'WalletMovementPagination',
    schemaKey: 'WalletMovementPagination',
    entityName: 'WalletMovementPagination',
    isList: false,
    argsTSName: 'QueryWalletMovementPaginationArgs',
    returnTSName: "Query['WalletMovementPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'WalletMovementPagination',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int' },
        { name: 'start', description: null, type: 'String' },
        { name: 'end', description: null, type: 'String' },
        { name: 'invoiceOrigin', description: null, type: 'String' },
      ],
      name: 'WalletMovementPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  WalletMovementSum: {
    type: 'WalletMovementSum',
    schemaKey: 'WalletMovementSum',
    entityName: 'WalletMovementSum',
    isList: false,
    argsTSName: 'QueryWalletMovementSumArgs',
    returnTSName: "Query['WalletMovementSum']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'WalletMovementSum',
      args: [
        { name: 'start', description: null, type: 'String' },
        { name: 'end', description: null, type: 'String' },
        { name: 'invoiceOrigin', description: null, type: 'String' },
      ],
      name: 'WalletMovementSum',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  FutureOperationFindById: {
    type: 'FutureOperation',
    schemaKey: 'FutureOperationFindById',
    entityName: 'FutureOperation',
    isList: false,
    argsTSName: 'QueryFutureOperationFindByIdArgs',
    returnTSName: "Query['FutureOperationFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'FutureOperation',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'FutureOperationFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ReconheceTransactionPagination: {
    type: 'ReconheceTransactionPagination',
    schemaKey: 'ReconheceTransactionPagination',
    entityName: 'ReconheceTransactionPagination',
    isList: false,
    argsTSName: 'QueryReconheceTransactionPaginationArgs',
    returnTSName: "Query['ReconheceTransactionPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ReconheceTransactionPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyReconheceTransactionInput' },
        { name: 'sort', description: null, type: 'SortFindManyReconheceTransactionInput' },
      ],
      name: 'ReconheceTransactionPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserCreditOperationFindById: {
    type: 'UserCreditOperation',
    schemaKey: 'UserCreditOperationFindById',
    entityName: 'UserCreditOperation',
    isList: false,
    argsTSName: 'QueryUserCreditOperationFindByIdArgs',
    returnTSName: "Query['UserCreditOperationFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserCreditOperation',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'UserCreditOperationFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserCreditOperationPagination: {
    type: 'UserCreditOperationPagination',
    schemaKey: 'UserCreditOperationPagination',
    entityName: 'UserCreditOperationPagination',
    isList: false,
    argsTSName: 'QueryUserCreditOperationPaginationArgs',
    returnTSName: "Query['UserCreditOperationPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserCreditOperationPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyUserCreditOperationInput' },
        { name: 'sort', description: null, type: 'SortFindManyUserCreditOperationInput' },
      ],
      name: 'UserCreditOperationPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserCreditOperationFindByIncentiveCampaignId: {
    type: '[UserCreditOperation]',
    schemaKey: 'UserCreditOperationFindByIncentiveCampaignId',
    entityName: 'UserCreditOperation',
    isList: true,
    argsTSName: 'QueryUserCreditOperationFindByIncentiveCampaignIdArgs',
    returnTSName: "Query['UserCreditOperationFindByIncentiveCampaignId']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserCreditOperation]',
      args: [{ name: 'incentiveCampaignId', description: null, type: 'String!' }],
      name: 'UserCreditOperationFindByIncentiveCampaignId',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ExtractUserCreditPoints: {
    type: '[UserCreditOperation]',
    schemaKey: 'ExtractUserCreditPoints',
    entityName: 'UserCreditOperation',
    isList: true,
    argsTSName: 'QueryExtractUserCreditPointsArgs',
    returnTSName: "Query['ExtractUserCreditPoints']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserCreditOperation]',
      args: [
        { name: 'incentiveCampaignIds', description: null, type: '[String]' },
        { name: 'cpf', description: null, type: 'String' },
        { name: 'matricula', description: null, type: 'String' },
        { name: 'userName', description: null, type: 'String' },
      ],
      name: 'ExtractUserCreditPoints',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  DownloadtUserCreditPoints: {
    type: 'DownloadtUserCreditPoints',
    schemaKey: 'DownloadtUserCreditPoints',
    entityName: 'DownloadtUserCreditPoints',
    isList: false,
    argsTSName: 'QueryDownloadtUserCreditPointsArgs',
    returnTSName: "Query['DownloadtUserCreditPoints']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DownloadtUserCreditPoints',
      args: [
        { name: 'incentiveCampaignIds', description: null, type: '[String]' },
        { name: 'cpf', description: null, type: 'String' },
        { name: 'matricula', description: null, type: 'String' },
        { name: 'userName', description: null, type: 'String' },
      ],
      name: 'DownloadtUserCreditPoints',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserCreditOperationAnalytics: {
    type: '[UserCreditOperation]',
    schemaKey: 'UserCreditOperationAnalytics',
    entityName: 'UserCreditOperation',
    isList: true,
    argsTSName: 'QueryUserCreditOperationAnalyticsArgs',
    returnTSName: "Query['UserCreditOperationAnalytics']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserCreditOperation]',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int' },
      ],
      name: 'UserCreditOperationAnalytics',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  EmailStatsFindOne: {
    type: 'EmailStats',
    schemaKey: 'EmailStatsFindOne',
    entityName: 'EmailStats',
    isList: false,
    argsTSName: 'QueryEmailStatsFindOneArgs',
    returnTSName: "Query['EmailStatsFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EmailStats',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneEmailStatsInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneEmailStatsInput' },
      ],
      name: 'EmailStatsFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  EmailStatsFindMany: {
    type: '[EmailStats]',
    schemaKey: 'EmailStatsFindMany',
    entityName: 'EmailStats',
    isList: true,
    argsTSName: 'QueryEmailStatsFindManyArgs',
    returnTSName: "Query['EmailStatsFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[EmailStats]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyEmailStatsInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyEmailStatsInput' },
      ],
      name: 'EmailStatsFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  EmailStatsAnalytics: {
    type: '[EmailStatsAnalyticsPayload]',
    schemaKey: 'EmailStatsAnalytics',
    entityName: 'EmailStatsAnalyticsPayload',
    isList: true,
    argsTSName: 'QueryEmailStatsAnalyticsArgs',
    returnTSName: "Query['EmailStatsAnalytics']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Status de envio dos e-mails',
      deprecationReason: null,
      type: '[EmailStatsAnalyticsPayload]',
      args: [
        { name: 'day', description: null, type: 'String!' },
        { name: 'month', description: null, type: 'String!' },
        { name: 'year', description: null, type: 'String!' },
      ],
      name: 'EmailStatsAnalytics',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  EmailUnsubscribeFindOne: {
    type: 'EmailUnsubscribe',
    schemaKey: 'EmailUnsubscribeFindOne',
    entityName: 'EmailUnsubscribe',
    isList: false,
    argsTSName: 'QueryEmailUnsubscribeFindOneArgs',
    returnTSName: "Query['EmailUnsubscribeFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EmailUnsubscribe',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneEmailUnsubscribeInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneEmailUnsubscribeInput' },
      ],
      name: 'EmailUnsubscribeFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  EmailUnsubscribeFindMany: {
    type: '[EmailUnsubscribe]',
    schemaKey: 'EmailUnsubscribeFindMany',
    entityName: 'EmailUnsubscribe',
    isList: true,
    argsTSName: 'QueryEmailUnsubscribeFindManyArgs',
    returnTSName: "Query['EmailUnsubscribeFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[EmailUnsubscribe]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyEmailUnsubscribeInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyEmailUnsubscribeInput' },
      ],
      name: 'EmailUnsubscribeFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  EmailUnsubscribeAction: {
    type: 'EmailUnsubscribeActionPayload',
    schemaKey: 'EmailUnsubscribeAction',
    entityName: 'EmailUnsubscribeActionPayload',
    isList: false,
    argsTSName: 'QueryEmailUnsubscribeActionArgs',
    returnTSName: "Query['EmailUnsubscribeAction']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Descadastra o e-mail para recebimento futuro de e-mails',
      deprecationReason: null,
      type: 'EmailUnsubscribeActionPayload',
      args: [
        { name: 'id', description: null, type: 'String' },
        { name: 'email', description: null, type: 'String' },
      ],
      name: 'EmailUnsubscribeAction',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  FileContentCampaignFindMany: {
    type: '[FileContentCampaign]',
    schemaKey: 'FileContentCampaignFindMany',
    entityName: 'FileContentCampaign',
    isList: true,
    argsTSName: 'QueryFileContentCampaignFindManyArgs',
    returnTSName: "Query['FileContentCampaignFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[FileContentCampaign]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyFileContentCampaignInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyFileContentCampaignInput' },
      ],
      name: 'FileContentCampaignFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  FileContentCampaignFindById: {
    type: 'FileContentCampaign',
    schemaKey: 'FileContentCampaignFindById',
    entityName: 'FileContentCampaign',
    isList: false,
    argsTSName: 'QueryFileContentCampaignFindByIdArgs',
    returnTSName: "Query['FileContentCampaignFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'FileContentCampaign',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'FileContentCampaignFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  FileContentCampaignFindByIds: {
    type: '[FileContentCampaign]',
    schemaKey: 'FileContentCampaignFindByIds',
    entityName: 'FileContentCampaign',
    isList: true,
    argsTSName: 'QueryFileContentCampaignFindByIdsArgs',
    returnTSName: "Query['FileContentCampaignFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[FileContentCampaign]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsFileContentCampaignInput' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'FileContentCampaignFindByIds',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  FileContentCampaignPagination: {
    type: 'FileContentCampaignPagination',
    schemaKey: 'FileContentCampaignPagination',
    entityName: 'FileContentCampaignPagination',
    isList: false,
    argsTSName: 'QueryFileContentCampaignPaginationArgs',
    returnTSName: "Query['FileContentCampaignPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'FileContentCampaignPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyFileContentCampaignInput' },
        { name: 'sort', description: null, type: 'SortFindManyFileContentCampaignInput' },
      ],
      name: 'FileContentCampaignPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserCampaignFindMany: {
    type: '[UserCampaign]',
    schemaKey: 'UserCampaignFindMany',
    entityName: 'UserCampaign',
    isList: true,
    argsTSName: 'QueryUserCampaignFindManyArgs',
    returnTSName: "Query['UserCampaignFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserCampaign]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyUserCampaignInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyUserCampaignInput' },
      ],
      name: 'UserCampaignFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserCampaignPagination: {
    type: 'UserCampaignPagination',
    schemaKey: 'UserCampaignPagination',
    entityName: 'UserCampaignPagination',
    isList: false,
    argsTSName: 'QueryUserCampaignPaginationArgs',
    returnTSName: "Query['UserCampaignPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserCampaignPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyUserCampaignInput' },
        { name: 'sort', description: null, type: 'SortFindManyUserCampaignInput' },
      ],
      name: 'UserCampaignPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserEligibilities: {
    type: 'UserEligibilities',
    schemaKey: 'UserEligibilities',
    entityName: 'UserEligibilities',
    isList: false,
    argsTSName: 'QueryUserEligibilitiesArgs',
    returnTSName: "Query['UserEligibilities']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'UserEligibilities',
      args: [
        { name: 'incentiveCampaignId', description: null, type: 'String!' },
        { name: 'fileContentCampaignId', description: null, type: 'String' },
      ],
      name: 'UserEligibilities',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserGUCheckCPF: {
    type: 'UserGUCheckCPFPayload',
    schemaKey: 'UserGUCheckCPF',
    entityName: 'UserGUCheckCPFPayload',
    isList: false,
    argsTSName: 'QueryUserGuCheckCpfArgs',
    returnTSName: "Query['UserGUCheckCPF']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'UserGUCheckCPFPayload',
      args: [{ name: 'CPF', description: null, type: 'String!' }],
      name: 'UserGUCheckCPF',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MeuDiaPdvTableAll: {
    type: 'MeuPdvReturn',
    schemaKey: 'MeuDiaPdvTableAll',
    entityName: 'MeuPdvReturn',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['MeuDiaPdvTableAll']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MeuPdvReturn',
      args: [],
      name: 'MeuDiaPdvTableAll',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ConsumerPublicUser: {
    type: 'ConsumerPublicUser!',
    schemaKey: 'ConsumerPublicUser',
    entityName: 'ConsumerPublicUser',
    isList: false,
    argsTSName: 'QueryConsumerPublicUserArgs',
    returnTSName: "Query['ConsumerPublicUser']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'ConsumerPublicUser!',
      args: [{ name: 'CPF', description: null, type: 'String!' }],
      name: 'ConsumerPublicUser',
      isDeprecated: false,
    },
    isNonNull: true,
    kind: 'query',
  },

  DownloadBaseUsers: {
    type: 'BaseDownloadReportUsersConsumer',
    schemaKey: 'DownloadBaseUsers',
    entityName: 'BaseDownloadReportUsersConsumer',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['DownloadBaseUsers']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BaseDownloadReportUsersConsumer',
      args: [],
      name: 'DownloadBaseUsers',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  DownloadUsersProfile: {
    type: 'BaseDownload',
    schemaKey: 'DownloadUsersProfile',
    entityName: 'BaseDownload',
    isList: false,
    argsTSName: 'QueryDownloadUsersProfileArgs',
    returnTSName: "Query['DownloadUsersProfile']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BaseDownload',
      args: [{ name: 'isExample', description: null, type: 'Boolean' }],
      name: 'DownloadUsersProfile',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MeuDiaPdvTableFindOne: {
    type: 'MeuDiaPdvTable',
    schemaKey: 'MeuDiaPdvTableFindOne',
    entityName: 'MeuDiaPdvTable',
    isList: false,
    argsTSName: 'QueryMeuDiaPdvTableFindOneArgs',
    returnTSName: "Query['MeuDiaPdvTableFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MeuDiaPdvTable',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneMeuDiaPdvTableInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneMeuDiaPdvTableInput' },
      ],
      name: 'MeuDiaPdvTableFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MeuDiaPdvTableFindById: {
    type: 'MeuDiaPdvTable',
    schemaKey: 'MeuDiaPdvTableFindById',
    entityName: 'MeuDiaPdvTable',
    isList: false,
    argsTSName: 'QueryMeuDiaPdvTableFindByIdArgs',
    returnTSName: "Query['MeuDiaPdvTableFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MeuDiaPdvTable',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'MeuDiaPdvTableFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MeuDiaPdvTableFindMany: {
    type: '[MeuDiaPdvTable]',
    schemaKey: 'MeuDiaPdvTableFindMany',
    entityName: 'MeuDiaPdvTable',
    isList: true,
    argsTSName: 'QueryMeuDiaPdvTableFindManyArgs',
    returnTSName: "Query['MeuDiaPdvTableFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[MeuDiaPdvTable]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyMeuDiaPdvTableInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyMeuDiaPdvTableInput' },
      ],
      name: 'MeuDiaPdvTableFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MeuDiaPdvTableFindByIds: {
    type: '[MeuDiaPdvTable]',
    schemaKey: 'MeuDiaPdvTableFindByIds',
    entityName: 'MeuDiaPdvTable',
    isList: true,
    argsTSName: 'QueryMeuDiaPdvTableFindByIdsArgs',
    returnTSName: "Query['MeuDiaPdvTableFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[MeuDiaPdvTable]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsMeuDiaPdvTableInput' },
      ],
      name: 'MeuDiaPdvTableFindByIds',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationNivelFindById: {
    type: 'GameficationNivel',
    schemaKey: 'GameficationNivelFindById',
    entityName: 'GameficationNivel',
    isList: false,
    argsTSName: 'QueryGameficationNivelFindByIdArgs',
    returnTSName: "Query['GameficationNivelFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'GameficationNivel',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'GameficationNivelFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationNivelFindMany: {
    type: '[GameficationNivel]',
    schemaKey: 'GameficationNivelFindMany',
    entityName: 'GameficationNivel',
    isList: true,
    argsTSName: 'QueryGameficationNivelFindManyArgs',
    returnTSName: "Query['GameficationNivelFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[GameficationNivel]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyGameficationNivelInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyGameficationNivelInput' },
      ],
      name: 'GameficationNivelFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationNivelFindOne: {
    type: 'GameficationNivel',
    schemaKey: 'GameficationNivelFindOne',
    entityName: 'GameficationNivel',
    isList: false,
    argsTSName: 'QueryGameficationNivelFindOneArgs',
    returnTSName: "Query['GameficationNivelFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'GameficationNivel',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneGameficationNivelInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneGameficationNivelInput' },
      ],
      name: 'GameficationNivelFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationNivelPagination: {
    type: 'GameficationNivelPagination',
    schemaKey: 'GameficationNivelPagination',
    entityName: 'GameficationNivelPagination',
    isList: false,
    argsTSName: 'QueryGameficationNivelPaginationArgs',
    returnTSName: "Query['GameficationNivelPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'GameficationNivelPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyGameficationNivelInput' },
        { name: 'sort', description: null, type: 'SortFindManyGameficationNivelInput' },
      ],
      name: 'GameficationNivelPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationNivelCount: {
    type: 'Int',
    schemaKey: 'GameficationNivelCount',
    entityName: 'Int',
    isList: false,
    argsTSName: 'QueryGameficationNivelCountArgs',
    returnTSName: "Query['GameficationNivelCount']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Int',
      args: [{ name: 'filter', description: 'Filter by fields', type: 'FilterGameficationNivelInput' }],
      name: 'GameficationNivelCount',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationNivelCustomFindById: {
    type: 'GameficationNivelCustom',
    schemaKey: 'GameficationNivelCustomFindById',
    entityName: 'GameficationNivelCustom',
    isList: false,
    argsTSName: 'QueryGameficationNivelCustomFindByIdArgs',
    returnTSName: "Query['GameficationNivelCustomFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'GameficationNivelCustom',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'GameficationNivelCustomFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationNivelCustomFindMany: {
    type: '[GameficationNivelCustom]',
    schemaKey: 'GameficationNivelCustomFindMany',
    entityName: 'GameficationNivelCustom',
    isList: true,
    argsTSName: 'QueryGameficationNivelCustomFindManyArgs',
    returnTSName: "Query['GameficationNivelCustomFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[GameficationNivelCustom]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyGameficationNivelCustomInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyGameficationNivelCustomInput' },
      ],
      name: 'GameficationNivelCustomFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationNivelCustomFindOne: {
    type: 'GameficationNivelCustom',
    schemaKey: 'GameficationNivelCustomFindOne',
    entityName: 'GameficationNivelCustom',
    isList: false,
    argsTSName: 'QueryGameficationNivelCustomFindOneArgs',
    returnTSName: "Query['GameficationNivelCustomFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'GameficationNivelCustom',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneGameficationNivelCustomInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneGameficationNivelCustomInput' },
      ],
      name: 'GameficationNivelCustomFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationNivelCustomPagination: {
    type: 'GameficationNivelCustomPagination',
    schemaKey: 'GameficationNivelCustomPagination',
    entityName: 'GameficationNivelCustomPagination',
    isList: false,
    argsTSName: 'QueryGameficationNivelCustomPaginationArgs',
    returnTSName: "Query['GameficationNivelCustomPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'GameficationNivelCustomPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyGameficationNivelCustomInput' },
        { name: 'sort', description: null, type: 'SortFindManyGameficationNivelCustomInput' },
      ],
      name: 'GameficationNivelCustomPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ChallengeFindById: {
    type: 'Challenge',
    schemaKey: 'ChallengeFindById',
    entityName: 'Challenge',
    isList: false,
    argsTSName: 'QueryChallengeFindByIdArgs',
    returnTSName: "Query['ChallengeFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Challenge',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' },
      ],
      name: 'ChallengeFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ChallengeFindMany: {
    type: '[Challenge]',
    schemaKey: 'ChallengeFindMany',
    entityName: 'Challenge',
    isList: true,
    argsTSName: 'QueryChallengeFindManyArgs',
    returnTSName: "Query['ChallengeFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Challenge]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyChallengeInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyChallengeInput' },
      ],
      name: 'ChallengeFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ChallengeFindOne: {
    type: 'Challenge',
    schemaKey: 'ChallengeFindOne',
    entityName: 'Challenge',
    isList: false,
    argsTSName: 'QueryChallengeFindOneArgs',
    returnTSName: "Query['ChallengeFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Challenge',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneChallengeInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneChallengeInput' },
      ],
      name: 'ChallengeFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ChallengePagination: {
    type: 'ChallengePagination',
    schemaKey: 'ChallengePagination',
    entityName: 'ChallengePagination',
    isList: false,
    argsTSName: 'QueryChallengePaginationArgs',
    returnTSName: "Query['ChallengePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ChallengePagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyChallengeInput' },
        { name: 'sort', description: null, type: 'SortFindManyChallengeInput' },
      ],
      name: 'ChallengePagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ChallengeCount: {
    type: 'Int',
    schemaKey: 'ChallengeCount',
    entityName: 'Int',
    isList: false,
    argsTSName: 'QueryChallengeCountArgs',
    returnTSName: "Query['ChallengeCount']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Int',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterChallengeInput' },
        { name: 'sort', description: null, type: 'sort' },
      ],
      name: 'ChallengeCount',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserChallengeFindById: {
    type: 'UserChallenge',
    schemaKey: 'UserChallengeFindById',
    entityName: 'UserChallenge',
    isList: false,
    argsTSName: 'QueryUserChallengeFindByIdArgs',
    returnTSName: "Query['UserChallengeFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserChallenge',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'UserChallengeFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserChallengeFindMany: {
    type: '[UserChallenge]',
    schemaKey: 'UserChallengeFindMany',
    entityName: 'UserChallenge',
    isList: true,
    argsTSName: 'QueryUserChallengeFindManyArgs',
    returnTSName: "Query['UserChallengeFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserChallenge]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyUserChallengeInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyUserChallengeInput' },
      ],
      name: 'UserChallengeFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserChallengeFindOne: {
    type: 'UserChallenge',
    schemaKey: 'UserChallengeFindOne',
    entityName: 'UserChallenge',
    isList: false,
    argsTSName: 'QueryUserChallengeFindOneArgs',
    returnTSName: "Query['UserChallengeFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserChallenge',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneUserChallengeInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneUserChallengeInput' },
      ],
      name: 'UserChallengeFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserChallengePagination: {
    type: 'UserChallengePagination',
    schemaKey: 'UserChallengePagination',
    entityName: 'UserChallengePagination',
    isList: false,
    argsTSName: 'QueryUserChallengePaginationArgs',
    returnTSName: "Query['UserChallengePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserChallengePagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyUserChallengeInput' },
        { name: 'sort', description: null, type: 'SortFindManyUserChallengeInput' },
      ],
      name: 'UserChallengePagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserChallengeAnswersDownload: {
    type: 'BaseDownload',
    schemaKey: 'UserChallengeAnswersDownload',
    entityName: 'BaseDownload',
    isList: false,
    argsTSName: 'QueryUserChallengeAnswersDownloadArgs',
    returnTSName: "Query['UserChallengeAnswersDownload']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BaseDownload',
      args: [{ name: 'challengeId', description: null, type: 'String!' }],
      name: 'UserChallengeAnswersDownload',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserChallengeQuizDownload: {
    type: 'BaseDownload',
    schemaKey: 'UserChallengeQuizDownload',
    entityName: 'BaseDownload',
    isList: false,
    argsTSName: 'QueryUserChallengeQuizDownloadArgs',
    returnTSName: "Query['UserChallengeQuizDownload']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BaseDownload',
      args: [{ name: 'challengeId', description: null, type: 'String!' }],
      name: 'UserChallengeQuizDownload',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationRankingFindById: {
    type: 'GameficationRanking',
    schemaKey: 'GameficationRankingFindById',
    entityName: 'GameficationRanking',
    isList: false,
    argsTSName: 'QueryGameficationRankingFindByIdArgs',
    returnTSName: "Query['GameficationRankingFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'GameficationRanking',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'GameficationRankingFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationRankingFindMany: {
    type: '[GameficationRanking]',
    schemaKey: 'GameficationRankingFindMany',
    entityName: 'GameficationRanking',
    isList: true,
    argsTSName: 'QueryGameficationRankingFindManyArgs',
    returnTSName: "Query['GameficationRankingFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[GameficationRanking]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyGameficationRankingInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyGameficationRankingInput' },
      ],
      name: 'GameficationRankingFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationRankingFindOne: {
    type: 'GameficationRanking',
    schemaKey: 'GameficationRankingFindOne',
    entityName: 'GameficationRanking',
    isList: false,
    argsTSName: 'QueryGameficationRankingFindOneArgs',
    returnTSName: "Query['GameficationRankingFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'GameficationRanking',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneGameficationRankingInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneGameficationRankingInput' },
      ],
      name: 'GameficationRankingFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationRankingPagination: {
    type: 'GameficationRankingPagination',
    schemaKey: 'GameficationRankingPagination',
    entityName: 'GameficationRankingPagination',
    isList: false,
    argsTSName: 'QueryGameficationRankingPaginationArgs',
    returnTSName: "Query['GameficationRankingPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'GameficationRankingPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyGameficationRankingInput' },
        { name: 'sort', description: null, type: 'SortFindManyGameficationRankingInput' },
      ],
      name: 'GameficationRankingPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationRankingCount: {
    type: 'Int',
    schemaKey: 'GameficationRankingCount',
    entityName: 'Int',
    isList: false,
    argsTSName: 'QueryGameficationRankingCountArgs',
    returnTSName: "Query['GameficationRankingCount']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Int',
      args: [{ name: 'filter', description: 'Filter by fields', type: 'FilterGameficationRankingInput' }],
      name: 'GameficationRankingCount',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationUserRankingFindById: {
    type: 'GameficationUserRanking',
    schemaKey: 'GameficationUserRankingFindById',
    entityName: 'GameficationUserRanking',
    isList: false,
    argsTSName: 'QueryGameficationUserRankingFindByIdArgs',
    returnTSName: "Query['GameficationUserRankingFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'GameficationUserRanking',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'sort' },
      ],
      name: 'GameficationUserRankingFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationUserRankingFindMany: {
    type: '[GameficationUserRanking]',
    schemaKey: 'GameficationUserRankingFindMany',
    entityName: 'GameficationUserRanking',
    isList: true,
    argsTSName: 'QueryGameficationUserRankingFindManyArgs',
    returnTSName: "Query['GameficationUserRankingFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[GameficationUserRanking]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyGameficationUserRankingInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyGameficationUserRankingInput' },
      ],
      name: 'GameficationUserRankingFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationUserRankingFindOne: {
    type: 'GameficationUserRanking',
    schemaKey: 'GameficationUserRankingFindOne',
    entityName: 'GameficationUserRanking',
    isList: false,
    argsTSName: 'QueryGameficationUserRankingFindOneArgs',
    returnTSName: "Query['GameficationUserRankingFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'GameficationUserRanking',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneGameficationUserRankingInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneGameficationUserRankingInput' },
      ],
      name: 'GameficationUserRankingFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationUserRankingPagination: {
    type: 'GameficationUserRankingPagination',
    schemaKey: 'GameficationUserRankingPagination',
    entityName: 'GameficationUserRankingPagination',
    isList: false,
    argsTSName: 'QueryGameficationUserRankingPaginationArgs',
    returnTSName: "Query['GameficationUserRankingPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'GameficationUserRankingPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyGameficationUserRankingInput' },
        { name: 'sort', description: null, type: 'SortFindManyGameficationUserRankingInput' },
      ],
      name: 'GameficationUserRankingPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationUserRankingCount: {
    type: 'Int',
    schemaKey: 'GameficationUserRankingCount',
    entityName: 'Int',
    isList: false,
    argsTSName: 'QueryGameficationUserRankingCountArgs',
    returnTSName: "Query['GameficationUserRankingCount']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Int',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterGameficationUserRankingInput' },
        { name: 'sort', description: null, type: 'sort' },
      ],
      name: 'GameficationUserRankingCount',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserChallengeQuizAnswers: {
    type: 'UserChallengeQuizAnswersPayload',
    schemaKey: 'UserChallengeQuizAnswers',
    entityName: 'UserChallengeQuizAnswersPayload',
    isList: false,
    argsTSName: 'QueryUserChallengeQuizAnswersArgs',
    returnTSName: "Query['UserChallengeQuizAnswers']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserChallengeQuizAnswersPayload',
      args: [
        { name: 'userChallengeId', description: null, type: 'String!' },
        { name: 'answers', description: null, type: 'JSON' },
      ],
      name: 'UserChallengeQuizAnswers',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserChallengePointsFindById: {
    type: 'UserChallengePoints',
    schemaKey: 'UserChallengePointsFindById',
    entityName: 'UserChallengePoints',
    isList: false,
    argsTSName: 'QueryUserChallengePointsFindByIdArgs',
    returnTSName: "Query['UserChallengePointsFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserChallengePoints',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'UserChallengePointsFindById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserChallengePointsFindMany: {
    type: '[UserChallengePoints]',
    schemaKey: 'UserChallengePointsFindMany',
    entityName: 'UserChallengePoints',
    isList: true,
    argsTSName: 'QueryUserChallengePointsFindManyArgs',
    returnTSName: "Query['UserChallengePointsFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserChallengePoints]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyUserChallengePointsInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyUserChallengePointsInput' },
      ],
      name: 'UserChallengePointsFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserChallengePointsFindOne: {
    type: 'UserChallengePoints',
    schemaKey: 'UserChallengePointsFindOne',
    entityName: 'UserChallengePoints',
    isList: false,
    argsTSName: 'QueryUserChallengePointsFindOneArgs',
    returnTSName: "Query['UserChallengePointsFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserChallengePoints',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindOneUserChallengePointsInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneUserChallengePointsInput' },
      ],
      name: 'UserChallengePointsFindOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  UserChallengePointsPagination: {
    type: 'UserChallengePointsPagination',
    schemaKey: 'UserChallengePointsPagination',
    entityName: 'UserChallengePointsPagination',
    isList: false,
    argsTSName: 'QueryUserChallengePointsPaginationArgs',
    returnTSName: "Query['UserChallengePointsPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserChallengePointsPagination',
      args: [
        { name: 'page', description: 'Page number for displaying', type: 'Int' },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyUserChallengePointsInput' },
        { name: 'sort', description: null, type: 'SortFindManyUserChallengePointsInput' },
      ],
      name: 'UserChallengePointsPagination',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  GameficationMaterialSearch: {
    type: '[Material]',
    schemaKey: 'GameficationMaterialSearch',
    entityName: 'Material',
    isList: true,
    argsTSName: 'QueryGameficationMaterialSearchArgs',
    returnTSName: "Query['GameficationMaterialSearch']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[Material]',
      args: [{ name: 'search', description: null, type: 'String!' }],
      name: 'GameficationMaterialSearch',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  MyRankingPosition: {
    type: 'MyRankingPositionResult!',
    schemaKey: 'MyRankingPosition',
    entityName: 'MyRankingPositionResult',
    isList: false,
    argsTSName: 'QueryMyRankingPositionArgs',
    returnTSName: "Query['MyRankingPosition']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MyRankingPositionResult!',
      args: [
        { name: 'filter', description: null, type: 'FilterFindManyUserInput' },
        { name: 'sort', description: null, type: 'SortFindManyUserInput' },
      ],
      name: 'MyRankingPosition',
      isDeprecated: false,
    },
    isNonNull: true,
    kind: 'query',
  },

  RankingDownload: {
    type: 'BaseDownload',
    schemaKey: 'RankingDownload',
    entityName: 'BaseDownload',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['RankingDownload']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BaseDownload',
      args: [],
      name: 'RankingDownload',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  KpiMapOptions: {
    type: 'JSON',
    schemaKey: 'KpiMapOptions',
    entityName: 'JSON',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['KpiMapOptions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'JSON',
      args: [],
      name: 'KpiMapOptions',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ImportHierarchy: {
    type: 'Boolean',
    schemaKey: 'ImportHierarchy',
    entityName: 'Boolean',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['ImportHierarchy']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [],
      name: 'ImportHierarchy',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ImportKPIs: {
    type: 'Boolean',
    schemaKey: 'ImportKPIs',
    entityName: 'Boolean',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['ImportKPIs']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [],
      name: 'ImportKPIs',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  KpiDataFindMany: {
    type: '[KpiData]',
    schemaKey: 'KpiDataFindMany',
    entityName: 'KpiData',
    isList: true,
    argsTSName: 'QueryKpiDataFindManyArgs',
    returnTSName: "Query['KpiDataFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[KpiData]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyKpiDataInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyKpiDataInput' },
      ],
      name: 'KpiDataFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  HierarchyHistoryFindMany: {
    type: '[HierarchyHistory]',
    schemaKey: 'HierarchyHistoryFindMany',
    entityName: 'HierarchyHistory',
    isList: true,
    argsTSName: 'QueryHierarchyHistoryFindManyArgs',
    returnTSName: "Query['HierarchyHistoryFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[HierarchyHistory]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyHierarchyHistoryInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyHierarchyHistoryInput' },
      ],
      name: 'HierarchyHistoryFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ReconheceInvoiceOriginFindMany: {
    type: '[ReconheceInvoiceOrigin]',
    schemaKey: 'ReconheceInvoiceOriginFindMany',
    entityName: 'ReconheceInvoiceOrigin',
    isList: true,
    argsTSName: 'QueryReconheceInvoiceOriginFindManyArgs',
    returnTSName: "Query['ReconheceInvoiceOriginFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[ReconheceInvoiceOrigin]',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterFindManyReconheceInvoiceOriginInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyReconheceInvoiceOriginInput' },
      ],
      name: 'ReconheceInvoiceOriginFindMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  ReconheceUserBalance: {
    type: 'ReconheceUserBalancePayload',
    schemaKey: 'ReconheceUserBalance',
    entityName: 'ReconheceUserBalancePayload',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['ReconheceUserBalance']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ReconheceUserBalancePayload',
      args: [],
      name: 'ReconheceUserBalance',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'query',
  },

  CampaignContestationCreateOne: {
    type: 'CreateOneCampaignContestationPayload',
    schemaKey: 'CampaignContestationCreateOne',
    entityName: 'CreateOneCampaignContestationPayload',
    isList: false,
    argsTSName: 'MutationCampaignContestationCreateOneArgs',
    returnTSName: "Mutation['CampaignContestationCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneCampaignContestationPayload',
      args: [{ name: 'record', description: null, type: 'CreateOneCampaignContestationInput!' }],
      name: 'CampaignContestationCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  CampaignContestationResolve: {
    type: 'CampaignContestation',
    schemaKey: 'CampaignContestationResolve',
    entityName: 'CampaignContestation',
    isList: false,
    argsTSName: 'MutationCampaignContestationResolveArgs',
    returnTSName: "Mutation['CampaignContestationResolve']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CampaignContestation',
      args: [
        { name: '_id', description: null, type: 'String!' },
        { name: 'title', description: null, type: 'String!' },
        { name: 'status', description: null, type: 'EnumCampaignContestationResponseStatus!' },
        { name: 'body', description: null, type: 'String!' },
        { name: 'file', description: null, type: 'String' },
      ],
      name: 'CampaignContestationResolve',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  IncentiveCampaignCreateOne: {
    type: 'CreateOneIncentiveCampaignPayload',
    schemaKey: 'IncentiveCampaignCreateOne',
    entityName: 'CreateOneIncentiveCampaignPayload',
    isList: false,
    argsTSName: 'MutationIncentiveCampaignCreateOneArgs',
    returnTSName: "Mutation['IncentiveCampaignCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneIncentiveCampaignPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneIncentiveCampaignInput!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'IncentiveCampaignCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  IncentiveCampaignUpdateOne: {
    type: 'UpdateOneIncentiveCampaignPayload',
    schemaKey: 'IncentiveCampaignUpdateOne',
    entityName: 'UpdateOneIncentiveCampaignPayload',
    isList: false,
    argsTSName: 'MutationIncentiveCampaignUpdateOneArgs',
    returnTSName: "Mutation['IncentiveCampaignUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneIncentiveCampaignPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneIncentiveCampaignInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneIncentiveCampaignInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneIncentiveCampaignInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'IncentiveCampaignUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  IncentiveCampaignRemoveById: {
    type: 'RemoveByIdIncentiveCampaignPayload',
    schemaKey: 'IncentiveCampaignRemoveById',
    entityName: 'RemoveByIdIncentiveCampaignPayload',
    isList: false,
    argsTSName: 'MutationIncentiveCampaignRemoveByIdArgs',
    returnTSName: "Mutation['IncentiveCampaignRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdIncentiveCampaignPayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'IncentiveCampaignRemoveById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  IncentiveCampaignPayPointsNew: {
    type: 'Boolean',
    schemaKey: 'IncentiveCampaignPayPointsNew',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationIncentiveCampaignPayPointsNewArgs',
    returnTSName: "Mutation['IncentiveCampaignPayPointsNew']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'campaignId', description: null, type: 'String!' },
        { name: 'walletId', description: null, type: 'String!' },
      ],
      name: 'IncentiveCampaignPayPointsNew',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ParticipateCampaign: {
    type: 'Boolean',
    schemaKey: 'ParticipateCampaign',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationParticipateCampaignArgs',
    returnTSName: "Mutation['ParticipateCampaign']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'campaignId', description: null, type: 'String!' }],
      name: 'ParticipateCampaign',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  HighlightedRejectedCampaign: {
    type: 'Boolean',
    schemaKey: 'HighlightedRejectedCampaign',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationHighlightedRejectedCampaignArgs',
    returnTSName: "Mutation['HighlightedRejectedCampaign']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'campaignId', description: null, type: 'String!' }],
      name: 'HighlightedRejectedCampaign',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  IncentiveCampaignLikeToggle: {
    type: 'Boolean',
    schemaKey: 'IncentiveCampaignLikeToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationIncentiveCampaignLikeToggleArgs',
    returnTSName: "Mutation['IncentiveCampaignLikeToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'IncentiveCampaignLikeToggle',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ExternalCampaignCreateOne: {
    type: 'CreateOneExternalCampaignPayload',
    schemaKey: 'ExternalCampaignCreateOne',
    entityName: 'CreateOneExternalCampaignPayload',
    isList: false,
    argsTSName: 'MutationExternalCampaignCreateOneArgs',
    returnTSName: "Mutation['ExternalCampaignCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneExternalCampaignPayload',
      args: [{ name: 'record', description: null, type: 'CreateOneExternalCampaignInput!' }],
      name: 'ExternalCampaignCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ExternalCampaignUpdateOne: {
    type: 'UpdateOneExternalCampaignPayload',
    schemaKey: 'ExternalCampaignUpdateOne',
    entityName: 'UpdateOneExternalCampaignPayload',
    isList: false,
    argsTSName: 'MutationExternalCampaignUpdateOneArgs',
    returnTSName: "Mutation['ExternalCampaignUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneExternalCampaignPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneExternalCampaignInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneExternalCampaignInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneExternalCampaignInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'ExternalCampaignUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  AcceptExternalCampaign: {
    type: 'Boolean',
    schemaKey: 'AcceptExternalCampaign',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationAcceptExternalCampaignArgs',
    returnTSName: "Mutation['AcceptExternalCampaign']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'externalTerm', description: null, type: 'String!' }],
      name: 'AcceptExternalCampaign',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  RegionRemoveOne: {
    type: 'RemoveOneRegionPayload',
    schemaKey: 'RegionRemoveOne',
    entityName: 'RemoveOneRegionPayload',
    isList: false,
    argsTSName: 'MutationRegionRemoveOneArgs',
    returnTSName: "Mutation['RegionRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneRegionPayload',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterRemoveOneRegionInput' },
        { name: 'sort', description: null, type: 'SortRemoveOneRegionInput' },
      ],
      name: 'RegionRemoveOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserContentPermissionToggle: {
    type: 'Boolean',
    schemaKey: 'UserContentPermissionToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserContentPermissionToggleArgs',
    returnTSName: "Mutation['UserContentPermissionToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'userId', description: null, type: 'String!' },
        { name: 'roleGroups', description: null, type: '[String]!' },
        { name: 'regions', description: null, type: '[String]!' },
        { name: 'contentTypes', description: null, type: '[String]!' },
        { name: 'viewOtherUsersContent', description: null, type: 'Boolean' },
      ],
      name: 'UserContentPermissionToggle',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserUpdateOne: {
    type: 'UpdateOneUserPayload',
    schemaKey: 'UserUpdateOne',
    entityName: 'UpdateOneUserPayload',
    isList: false,
    argsTSName: 'MutationUserUpdateOneArgs',
    returnTSName: "Mutation['UserUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneUserPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneUserInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneUserInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneUserInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'UserUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserRemoveById: {
    type: 'RemoveByIdUserPayload',
    schemaKey: 'UserRemoveById',
    entityName: 'RemoveByIdUserPayload',
    isList: false,
    argsTSName: 'MutationUserRemoveByIdArgs',
    returnTSName: "Mutation['UserRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdUserPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'UserRemoveById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserAcceptTerm: {
    type: 'Boolean',
    schemaKey: 'UserAcceptTerm',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserAcceptTermArgs',
    returnTSName: "Mutation['UserAcceptTerm']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'term', description: null, type: 'String!' }],
      name: 'UserAcceptTerm',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserLoginWithPassword: {
    type: 'User',
    schemaKey: 'UserLoginWithPassword',
    entityName: 'User',
    isList: false,
    argsTSName: 'MutationUserLoginWithPasswordArgs',
    returnTSName: "Mutation['UserLoginWithPassword']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [
        { name: 'user', description: 'email, CPF, CNPJ ou matricula', type: 'String!' },
        { name: 'password', description: null, type: 'String!' },
      ],
      name: 'UserLoginWithPassword',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserLoginWithToken: {
    type: 'User',
    schemaKey: 'UserLoginWithToken',
    entityName: 'User',
    isList: false,
    argsTSName: 'MutationUserLoginWithTokenArgs',
    returnTSName: "Mutation['UserLoginWithToken']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [{ name: 'token', description: null, type: 'String!' }],
      name: 'UserLoginWithToken',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserLoginRenewToken: {
    type: 'User',
    schemaKey: 'UserLoginRenewToken',
    entityName: 'User',
    isList: false,
    argsTSName: 'MutationUserLoginRenewTokenArgs',
    returnTSName: "Mutation['UserLoginRenewToken']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [{ name: 'token', description: null, type: 'String!' }],
      name: 'UserLoginRenewToken',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserSaveLogoutAnalytics: {
    type: 'Boolean',
    schemaKey: 'UserSaveLogoutAnalytics',
    entityName: 'Boolean',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Mutation['UserSaveLogoutAnalytics']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [],
      name: 'UserSaveLogoutAnalytics',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserSaveLoginAnalyticsPCS: {
    type: 'Boolean',
    schemaKey: 'UserSaveLoginAnalyticsPCS',
    entityName: 'Boolean',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Mutation['UserSaveLoginAnalyticsPCS']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [],
      name: 'UserSaveLoginAnalyticsPCS',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserFillEmail: {
    type: 'Boolean',
    schemaKey: 'UserFillEmail',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserFillEmailArgs',
    returnTSName: "Mutation['UserFillEmail']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'email', description: null, type: 'String!' }],
      name: 'UserFillEmail',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserFillAvatarId: {
    type: 'Boolean',
    schemaKey: 'UserFillAvatarId',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserFillAvatarIdArgs',
    returnTSName: "Mutation['UserFillAvatarId']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'avatarId', description: null, type: 'String!' }],
      name: 'UserFillAvatarId',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserOptIn: {
    type: 'Boolean',
    schemaKey: 'UserOptIn',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserOptInArgs',
    returnTSName: "Mutation['UserOptIn']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'accept', description: null, type: 'Boolean!' }],
      name: 'UserOptIn',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserMarkNotificationAsRead: {
    type: 'Boolean',
    schemaKey: 'UserMarkNotificationAsRead',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserMarkNotificationAsReadArgs',
    returnTSName: "Mutation['UserMarkNotificationAsRead']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'isRead', description: null, type: 'Boolean!' },
        { name: 'notifications', description: null, type: '[String]!' },
      ],
      name: 'UserMarkNotificationAsRead',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserDeleteNotification: {
    type: 'String',
    schemaKey: 'UserDeleteNotification',
    entityName: 'String',
    isList: false,
    argsTSName: 'MutationUserDeleteNotificationArgs',
    returnTSName: "Mutation['UserDeleteNotification']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'String',
      args: [{ name: 'notificationId', description: null, type: 'String!' }],
      name: 'UserDeleteNotification',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserDeleteManyNotification: {
    type: '[String]',
    schemaKey: 'UserDeleteManyNotification',
    entityName: 'String',
    isList: true,
    argsTSName: 'MutationUserDeleteManyNotificationArgs',
    returnTSName: "Mutation['UserDeleteManyNotification']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[String]',
      args: [{ name: 'notificationIds', description: null, type: '[String!]' }],
      name: 'UserDeleteManyNotification',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserRegistration: {
    type: 'UserRegistrationPayload',
    schemaKey: 'UserRegistration',
    entityName: 'UserRegistrationPayload',
    isList: false,
    argsTSName: 'MutationUserRegistrationArgs',
    returnTSName: "Mutation['UserRegistration']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserRegistrationPayload',
      args: [{ name: 'record', description: null, type: 'UserRegistrationTypeInput!' }],
      name: 'UserRegistration',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserBlockComments: {
    type: 'Boolean',
    schemaKey: 'UserBlockComments',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserBlockCommentsArgs',
    returnTSName: "Mutation['UserBlockComments']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'userId', description: null, type: 'String!' },
        { name: 'isBlocked', description: null, type: 'Boolean' },
        { name: 'materialId', description: null, type: 'String' },
      ],
      name: 'UserBlockComments',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ChangeToUserTaNarede: {
    type: 'UserTaNaRede',
    schemaKey: 'ChangeToUserTaNarede',
    entityName: 'UserTaNaRede',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Mutation['ChangeToUserTaNarede']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'UserTaNaRede',
      args: [],
      name: 'ChangeToUserTaNarede',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserChangePassword: {
    type: 'Boolean',
    schemaKey: 'UserChangePassword',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserChangePasswordArgs',
    returnTSName: "Mutation['UserChangePassword']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'newPassword', description: null, type: 'String!' }],
      name: 'UserChangePassword',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserAzureLogin: {
    type: 'User',
    schemaKey: 'UserAzureLogin',
    entityName: 'User',
    isList: false,
    argsTSName: 'MutationUserAzureLoginArgs',
    returnTSName: "Mutation['UserAzureLogin']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Esta mutation autentica o usuário e cria um token bearer, retornando o usuário logado',
      deprecationReason: null,
      type: 'User',
      args: [{ name: 'token', description: null, type: 'String!' }],
      name: 'UserAzureLogin',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  FileCreateOne: {
    type: 'CreateOneFilePayload',
    schemaKey: 'FileCreateOne',
    entityName: 'CreateOneFilePayload',
    isList: false,
    argsTSName: 'MutationFileCreateOneArgs',
    returnTSName: "Mutation['FileCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneFilePayload',
      args: [{ name: 'record', description: null, type: 'CreateOneFileInput!' }],
      name: 'FileCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  FileRemoveById: {
    type: 'RemoveByIdFilePayload',
    schemaKey: 'FileRemoveById',
    entityName: 'RemoveByIdFilePayload',
    isList: false,
    argsTSName: 'MutationFileRemoveByIdArgs',
    returnTSName: "Mutation['FileRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdFilePayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'FileRemoveById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  FileUpdateById: {
    type: 'UpdateByIdFilePayload',
    schemaKey: 'FileUpdateById',
    entityName: 'UpdateByIdFilePayload',
    isList: false,
    argsTSName: 'MutationFileUpdateByIdArgs',
    returnTSName: "Mutation['FileUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdFilePayload',
      args: [{ name: 'record', description: null, type: 'UpdateByIdFileInput!' }],
      name: 'FileUpdateById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  MaterialCreateOne: {
    type: 'CreateOneMaterialPayload',
    schemaKey: 'MaterialCreateOne',
    entityName: 'CreateOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationMaterialCreateOneArgs',
    returnTSName: "Mutation['MaterialCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneMaterialPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneMaterialInput!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' },
      ],
      name: 'MaterialCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  MaterialUpdateOne: {
    type: 'UpdateOneMaterialPayload',
    schemaKey: 'MaterialUpdateOne',
    entityName: 'UpdateOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationMaterialUpdateOneArgs',
    returnTSName: "Mutation['MaterialUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneMaterialPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneMaterialInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneMaterialInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneMaterialInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'MaterialUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  MaterialUpdateById: {
    type: 'UpdateByIdMaterialPayload',
    schemaKey: 'MaterialUpdateById',
    entityName: 'UpdateByIdMaterialPayload',
    isList: false,
    argsTSName: 'MutationMaterialUpdateByIdArgs',
    returnTSName: "Mutation['MaterialUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdMaterialPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdMaterialInput!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' },
      ],
      name: 'MaterialUpdateById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  MaterialRemoveOne: {
    type: 'RemoveOneMaterialPayload',
    schemaKey: 'MaterialRemoveOne',
    entityName: 'RemoveOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationMaterialRemoveOneArgs',
    returnTSName: "Mutation['MaterialRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneMaterialPayload',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterRemoveOneMaterialInput' },
        { name: 'sort', description: null, type: 'SortRemoveOneMaterialInput' },
      ],
      name: 'MaterialRemoveOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  CommunicationCreateOne: {
    type: 'CreateOneMaterialPayload',
    schemaKey: 'CommunicationCreateOne',
    entityName: 'CreateOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationCommunicationCreateOneArgs',
    returnTSName: "Mutation['CommunicationCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CreateOneMaterialPayload',
      args: [{ name: 'record', description: null, type: 'CreateOneMaterialInput!' }],
      name: 'CommunicationCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  CommunicationUpdateOne: {
    type: 'UpdateOneMaterialPayload',
    schemaKey: 'CommunicationUpdateOne',
    entityName: 'UpdateOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationCommunicationUpdateOneArgs',
    returnTSName: "Mutation['CommunicationUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UpdateOneMaterialPayload',
      args: [
        { name: 'communicationId', description: null, type: 'String!' },
        { name: 'record', description: null, type: 'UpdateOneMaterialInput' },
      ],
      name: 'CommunicationUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  CommunicationApproveChannel: {
    type: 'Boolean',
    schemaKey: 'CommunicationApproveChannel',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationCommunicationApproveChannelArgs',
    returnTSName: "Mutation['CommunicationApproveChannel']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'CommunicationApproveChannel',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  CommunicationDisapproveChannel: {
    type: 'Boolean',
    schemaKey: 'CommunicationDisapproveChannel',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationCommunicationDisapproveChannelArgs',
    returnTSName: "Mutation['CommunicationDisapproveChannel']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'recordId', description: null, type: 'String!' },
        { name: 'message', description: null, type: 'String!' },
      ],
      name: 'CommunicationDisapproveChannel',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  CommunicationRemoveApprover: {
    type: 'Boolean',
    schemaKey: 'CommunicationRemoveApprover',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationCommunicationRemoveApproverArgs',
    returnTSName: "Mutation['CommunicationRemoveApprover']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'communicationId', description: null, type: 'String!' },
        { name: 'userId', description: null, type: 'String!' },
      ],
      name: 'CommunicationRemoveApprover',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  EmailFillTemplate: {
    type: 'String!',
    schemaKey: 'EmailFillTemplate',
    entityName: 'String',
    isList: false,
    argsTSName: 'MutationEmailFillTemplateArgs',
    returnTSName: "Mutation['EmailFillTemplate']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'String!',
      args: [
        { name: 'template', description: null, type: 'EmailTemplateNames' },
        { name: 'props', description: null, type: 'MaterialCommunicationItemsConfigTemplatePropsInput' },
      ],
      name: 'EmailFillTemplate',
      isDeprecated: false,
    },
    isNonNull: true,
    kind: 'mutation',
  },

  EmailMarkAsRead: {
    type: 'Boolean',
    schemaKey: 'EmailMarkAsRead',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationEmailMarkAsReadArgs',
    returnTSName: "Mutation['EmailMarkAsRead']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Marca um email como lido pelo destinatario informado pelo token recebido no e-mail',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'token', description: null, type: 'String!' }],
      name: 'EmailMarkAsRead',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  SendEmailFront: {
    type: 'Boolean',
    schemaKey: 'SendEmailFront',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationSendEmailFrontArgs',
    returnTSName: "Mutation['SendEmailFront']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'subject', description: null, type: 'String' },
        { name: 'text', description: null, type: 'String' },
        { name: 'attach', description: null, type: '[String]' },
        { name: 'categories', description: null, type: '[String]' },
      ],
      name: 'SendEmailFront',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  MaterialSendTestEmail: {
    type: 'Boolean!',
    schemaKey: 'MaterialSendTestEmail',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationMaterialSendTestEmailArgs',
    returnTSName: "Mutation['MaterialSendTestEmail']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean!',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'MaterialSendTestEmail',
      isDeprecated: false,
    },
    isNonNull: true,
    kind: 'mutation',
  },

  MaterialLikeToggle: {
    type: 'Boolean',
    schemaKey: 'MaterialLikeToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationMaterialLikeToggleArgs',
    returnTSName: "Mutation['MaterialLikeToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'MaterialLikeToggle',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UpdateDeteachedAndLatestVideo: {
    type: 'Boolean',
    schemaKey: 'UpdateDeteachedAndLatestVideo',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUpdateDeteachedAndLatestVideoArgs',
    returnTSName: "Mutation['UpdateDeteachedAndLatestVideo']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'idDetachedVideo', description: null, type: 'String' },
        { name: 'idLatestVideo1', description: null, type: 'String' },
        { name: 'idLatestVideo2', description: null, type: 'String' },
        { name: 'idLatestVideo3', description: null, type: 'String' },
      ],
      name: 'UpdateDeteachedAndLatestVideo',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  NotificationCreateOne: {
    type: 'CreateOneNotificationPayload',
    schemaKey: 'NotificationCreateOne',
    entityName: 'CreateOneNotificationPayload',
    isList: false,
    argsTSName: 'MutationNotificationCreateOneArgs',
    returnTSName: "Mutation['NotificationCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneNotificationPayload',
      args: [{ name: 'record', description: null, type: 'CreateOneNotificationInput!' }],
      name: 'NotificationCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  NotificationCreateMany: {
    type: 'CreateManyNotificationPayload',
    schemaKey: 'NotificationCreateMany',
    entityName: 'CreateManyNotificationPayload',
    isList: false,
    argsTSName: 'MutationNotificationCreateManyArgs',
    returnTSName: "Mutation['NotificationCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyNotificationPayload',
      args: [{ name: 'records', description: null, type: '[CreateManyNotificationInput!]!' }],
      name: 'NotificationCreateMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  NotificationUpdateOne: {
    type: 'UpdateOneNotificationPayload',
    schemaKey: 'NotificationUpdateOne',
    entityName: 'UpdateOneNotificationPayload',
    isList: false,
    argsTSName: 'MutationNotificationUpdateOneArgs',
    returnTSName: "Mutation['NotificationUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneNotificationPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneNotificationInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneNotificationInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneNotificationInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'NotificationUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  NotificationRemoveById: {
    type: 'RemoveByIdNotificationPayload',
    schemaKey: 'NotificationRemoveById',
    entityName: 'RemoveByIdNotificationPayload',
    isList: false,
    argsTSName: 'MutationNotificationRemoveByIdArgs',
    returnTSName: "Mutation['NotificationRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdNotificationPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'NotificationRemoveById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  CategoryCreateOne: {
    type: 'CreateOneCategoryPayload',
    schemaKey: 'CategoryCreateOne',
    entityName: 'CreateOneCategoryPayload',
    isList: false,
    argsTSName: 'MutationCategoryCreateOneArgs',
    returnTSName: "Mutation['CategoryCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneCategoryPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneCategoryInput!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'CategoryCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  CategoryUpdateOne: {
    type: 'UpdateOneCategoryPayload',
    schemaKey: 'CategoryUpdateOne',
    entityName: 'UpdateOneCategoryPayload',
    isList: false,
    argsTSName: 'MutationCategoryUpdateOneArgs',
    returnTSName: "Mutation['CategoryUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneCategoryPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneCategoryInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneCategoryInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneCategoryInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'CategoryUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  CategoryRemoveById: {
    type: 'RemoveByIdCategoryPayload',
    schemaKey: 'CategoryRemoveById',
    entityName: 'RemoveByIdCategoryPayload',
    isList: false,
    argsTSName: 'MutationCategoryRemoveByIdArgs',
    returnTSName: "Mutation['CategoryRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdCategoryPayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'CategoryRemoveById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  CategoryCreateMany: {
    type: 'CreateManyCategoryPayload',
    schemaKey: 'CategoryCreateMany',
    entityName: 'CreateManyCategoryPayload',
    isList: false,
    argsTSName: 'MutationCategoryCreateManyArgs',
    returnTSName: "Mutation['CategoryCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyCategoryPayload',
      args: [
        { name: 'records', description: null, type: '[CreateManyCategoryInput!]!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'CategoryCreateMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  WebPushSubscribe: {
    type: 'Boolean',
    schemaKey: 'WebPushSubscribe',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationWebPushSubscribeArgs',
    returnTSName: "Mutation['WebPushSubscribe']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Adiciona uma "PushSubscriptionJSON" ao cadastro do usuario.',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'subscription', description: null, type: 'JSON!' }],
      name: 'WebPushSubscribe',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  WebPushSendNotification: {
    type: '[String]',
    schemaKey: 'WebPushSendNotification',
    entityName: 'String',
    isList: true,
    argsTSName: 'MutationWebPushSendNotificationArgs',
    returnTSName: "Mutation['WebPushSendNotification']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Envia uma web push notification para uma lista de userIds',
      deprecationReason: null,
      type: '[String]',
      args: [
        { name: 'userIds', description: null, type: '[String]!' },
        { name: 'notificationOptions', description: null, type: 'JSON!' },
        { name: 'sync', description: null, type: 'Boolean' },
      ],
      name: 'WebPushSendNotification',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  PinCreateOne: {
    type: 'CreateOnePinPayload',
    schemaKey: 'PinCreateOne',
    entityName: 'CreateOnePinPayload',
    isList: false,
    argsTSName: 'MutationPinCreateOneArgs',
    returnTSName: "Mutation['PinCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOnePinPayload',
      args: [{ name: 'record', description: null, type: 'CreateOnePinInput!' }],
      name: 'PinCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  PinRemoveOne: {
    type: 'RemoveOnePinPayload',
    schemaKey: 'PinRemoveOne',
    entityName: 'RemoveOnePinPayload',
    isList: false,
    argsTSName: 'MutationPinRemoveOneArgs',
    returnTSName: "Mutation['PinRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOnePinPayload',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterRemoveOnePinInput' },
        { name: 'sort', description: null, type: 'SortRemoveOnePinInput' },
      ],
      name: 'PinRemoveOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  PinRemoveMany: {
    type: 'RemoveManyPinPayload',
    schemaKey: 'PinRemoveMany',
    entityName: 'RemoveManyPinPayload',
    isList: false,
    argsTSName: 'MutationPinRemoveManyArgs',
    returnTSName: "Mutation['PinRemoveMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ',
      deprecationReason: null,
      type: 'RemoveManyPinPayload',
      args: [{ name: 'filter', description: 'Filter by fields', type: 'FilterRemoveManyPinInput!' }],
      name: 'PinRemoveMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ShareCreateOne: {
    type: 'CreateOneSharePayload',
    schemaKey: 'ShareCreateOne',
    entityName: 'CreateOneSharePayload',
    isList: false,
    argsTSName: 'MutationShareCreateOneArgs',
    returnTSName: "Mutation['ShareCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneSharePayload',
      args: [{ name: 'record', description: null, type: 'CreateOneShareInput!' }],
      name: 'ShareCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ShareRemoveOne: {
    type: 'RemoveOneSharePayload',
    schemaKey: 'ShareRemoveOne',
    entityName: 'RemoveOneSharePayload',
    isList: false,
    argsTSName: 'MutationShareRemoveOneArgs',
    returnTSName: "Mutation['ShareRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneSharePayload',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterRemoveOneShareInput' },
        { name: 'sort', description: null, type: 'SortRemoveOneShareInput' },
      ],
      name: 'ShareRemoveOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ShareRemoveMe: {
    type: 'Boolean!',
    schemaKey: 'ShareRemoveMe',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationShareRemoveMeArgs',
    returnTSName: "Mutation['ShareRemoveMe']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Remove o usuário atual de um compartilhamento.',
      deprecationReason: null,
      type: 'Boolean!',
      args: [{ name: 'shareId', description: null, type: 'String' }],
      name: 'ShareRemoveMe',
      isDeprecated: false,
    },
    isNonNull: true,
    kind: 'mutation',
  },

  CommentCreateOne: {
    type: 'CreateOneCommentPayload',
    schemaKey: 'CommentCreateOne',
    entityName: 'CreateOneCommentPayload',
    isList: false,
    argsTSName: 'MutationCommentCreateOneArgs',
    returnTSName: "Mutation['CommentCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneCommentPayload',
      args: [{ name: 'record', description: null, type: 'CreateOneCommentInput!' }],
      name: 'CommentCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  CommentUpdateOne: {
    type: 'UpdateOneCommentPayload',
    schemaKey: 'CommentUpdateOne',
    entityName: 'UpdateOneCommentPayload',
    isList: false,
    argsTSName: 'MutationCommentUpdateOneArgs',
    returnTSName: "Mutation['CommentUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneCommentPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneCommentInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneCommentInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneCommentInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'CommentUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  CommentRemoveById: {
    type: 'RemoveByIdCommentPayload',
    schemaKey: 'CommentRemoveById',
    entityName: 'RemoveByIdCommentPayload',
    isList: false,
    argsTSName: 'MutationCommentRemoveByIdArgs',
    returnTSName: "Mutation['CommentRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdCommentPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'CommentRemoveById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  CommentLikeToggle: {
    type: 'Boolean',
    schemaKey: 'CommentLikeToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationCommentLikeToggleArgs',
    returnTSName: "Mutation['CommentLikeToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'CommentLikeToggle',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  EventLiveCreateOne: {
    type: 'CreateOneEventLivePayload',
    schemaKey: 'EventLiveCreateOne',
    entityName: 'CreateOneEventLivePayload',
    isList: false,
    argsTSName: 'MutationEventLiveCreateOneArgs',
    returnTSName: "Mutation['EventLiveCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneEventLivePayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneEventLiveInput!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'EventLiveCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  EventLiveRemoveById: {
    type: 'RemoveByIdEventLivePayload',
    schemaKey: 'EventLiveRemoveById',
    entityName: 'RemoveByIdEventLivePayload',
    isList: false,
    argsTSName: 'MutationEventLiveRemoveByIdArgs',
    returnTSName: "Mutation['EventLiveRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdEventLivePayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'EventLiveRemoveById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  EventLiveUpdateOne: {
    type: 'UpdateOneEventLivePayload',
    schemaKey: 'EventLiveUpdateOne',
    entityName: 'UpdateOneEventLivePayload',
    isList: false,
    argsTSName: 'MutationEventLiveUpdateOneArgs',
    returnTSName: "Mutation['EventLiveUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneEventLivePayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneEventLiveInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneEventLiveInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneEventLiveInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'EventLiveUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  EventLiveCloseRoom: {
    type: 'Boolean',
    schemaKey: 'EventLiveCloseRoom',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationEventLiveCloseRoomArgs',
    returnTSName: "Mutation['EventLiveCloseRoom']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'eventId', description: null, type: 'String!' }],
      name: 'EventLiveCloseRoom',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  EventLiveLikeToggle: {
    type: 'Boolean',
    schemaKey: 'EventLiveLikeToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationEventLiveLikeToggleArgs',
    returnTSName: "Mutation['EventLiveLikeToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'EventLiveLikeToggle',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  BannerRemoveById: {
    type: 'RemoveByIdBannerPayload',
    schemaKey: 'BannerRemoveById',
    entityName: 'RemoveByIdBannerPayload',
    isList: false,
    argsTSName: 'MutationBannerRemoveByIdArgs',
    returnTSName: "Mutation['BannerRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdBannerPayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'BannerRemoveById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  BannerCreateOne: {
    type: 'CreateOneBannerPayload',
    schemaKey: 'BannerCreateOne',
    entityName: 'CreateOneBannerPayload',
    isList: false,
    argsTSName: 'MutationBannerCreateOneArgs',
    returnTSName: "Mutation['BannerCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneBannerPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneBannerInput!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'BannerCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  BannerUpdateOne: {
    type: 'UpdateOneBannerPayload',
    schemaKey: 'BannerUpdateOne',
    entityName: 'UpdateOneBannerPayload',
    isList: false,
    argsTSName: 'MutationBannerUpdateOneArgs',
    returnTSName: "Mutation['BannerUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneBannerPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneBannerInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneBannerInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneBannerInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'BannerUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  AnalyticsPageView: {
    type: 'Boolean',
    schemaKey: 'AnalyticsPageView',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationAnalyticsPageViewArgs',
    returnTSName: "Mutation['AnalyticsPageView']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'title', description: null, type: 'String!' },
        { name: 'host', description: null, type: 'String' },
        { name: 'externalLink', description: null, type: 'String' },
        { name: 'kind', description: null, type: 'String' },
      ],
      name: 'AnalyticsPageView',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  AnalyticsTrackEvent: {
    type: 'Boolean',
    schemaKey: 'AnalyticsTrackEvent',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationAnalyticsTrackEventArgs',
    returnTSName: "Mutation['AnalyticsTrackEvent']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'event', description: null, type: 'EnumAnalyticsEvent!' },
        { name: 'kind', description: null, type: 'EnumAnalyticsParentKind!' },
        { name: 'recordId', description: null, type: 'String' },
        { name: 'entityName', description: null, type: 'String' },
        { name: 'externalLink', description: null, type: 'String' },
        { name: 'label', description: null, type: 'String' },
      ],
      name: 'AnalyticsTrackEvent',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  FolderCreateOne: {
    type: 'CreateOneFolderPayload',
    schemaKey: 'FolderCreateOne',
    entityName: 'CreateOneFolderPayload',
    isList: false,
    argsTSName: 'MutationFolderCreateOneArgs',
    returnTSName: "Mutation['FolderCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneFolderPayload',
      args: [{ name: 'record', description: null, type: 'CreateOneFolderInput!' }],
      name: 'FolderCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  FolderRemoveOne: {
    type: 'RemoveOneFolderPayload',
    schemaKey: 'FolderRemoveOne',
    entityName: 'RemoveOneFolderPayload',
    isList: false,
    argsTSName: 'MutationFolderRemoveOneArgs',
    returnTSName: "Mutation['FolderRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneFolderPayload',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterRemoveOneFolderInput' },
        { name: 'sort', description: null, type: 'SortRemoveOneFolderInput' },
      ],
      name: 'FolderRemoveOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  FolderRemoveMany: {
    type: 'RemoveManyFolderPayload',
    schemaKey: 'FolderRemoveMany',
    entityName: 'RemoveManyFolderPayload',
    isList: false,
    argsTSName: 'MutationFolderRemoveManyArgs',
    returnTSName: "Mutation['FolderRemoveMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ',
      deprecationReason: null,
      type: 'RemoveManyFolderPayload',
      args: [{ name: 'filter', description: 'Filter by fields', type: 'FilterRemoveManyFolderInput!' }],
      name: 'FolderRemoveMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  FolderUpdateOne: {
    type: 'UpdateOneFolderPayload',
    schemaKey: 'FolderUpdateOne',
    entityName: 'UpdateOneFolderPayload',
    isList: false,
    argsTSName: 'MutationFolderUpdateOneArgs',
    returnTSName: "Mutation['FolderUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneFolderPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneFolderInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneFolderInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneFolderInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'FolderUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  RecentsActivitiesCreateOne: {
    type: 'CreateOneRecentsActivitiesPayload',
    schemaKey: 'RecentsActivitiesCreateOne',
    entityName: 'CreateOneRecentsActivitiesPayload',
    isList: false,
    argsTSName: 'MutationRecentsActivitiesCreateOneArgs',
    returnTSName: "Mutation['RecentsActivitiesCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneRecentsActivitiesPayload',
      args: [{ name: 'record', description: null, type: 'CreateOneRecentsActivitiesInput!' }],
      name: 'RecentsActivitiesCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserWordsBlackListCreateOne: {
    type: 'CreateOneUserWordsBlackListPayload',
    schemaKey: 'UserWordsBlackListCreateOne',
    entityName: 'CreateOneUserWordsBlackListPayload',
    isList: false,
    argsTSName: 'MutationUserWordsBlackListCreateOneArgs',
    returnTSName: "Mutation['UserWordsBlackListCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneUserWordsBlackListPayload',
      args: [{ name: 'record', description: null, type: 'CreateOneUserWordsBlackListInput!' }],
      name: 'UserWordsBlackListCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ImportUsersPlanCreateOne: {
    type: 'CreateOneImportUsersPlanPayload',
    schemaKey: 'ImportUsersPlanCreateOne',
    entityName: 'CreateOneImportUsersPlanPayload',
    isList: false,
    argsTSName: 'MutationImportUsersPlanCreateOneArgs',
    returnTSName: "Mutation['ImportUsersPlanCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneImportUsersPlanPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneImportUsersPlanInput!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'ImportUsersPlanCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ImportUsersPlanUpdateOne: {
    type: 'UpdateOneImportUsersPlanPayload',
    schemaKey: 'ImportUsersPlanUpdateOne',
    entityName: 'UpdateOneImportUsersPlanPayload',
    isList: false,
    argsTSName: 'MutationImportUsersPlanUpdateOneArgs',
    returnTSName: "Mutation['ImportUsersPlanUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneImportUsersPlanPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneImportUsersPlanInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneImportUsersPlanInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneImportUsersPlanInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'ImportUsersPlanUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserHistoryBlockCreateOne: {
    type: 'CreateOneUserHistoryBlockPayload',
    schemaKey: 'UserHistoryBlockCreateOne',
    entityName: 'CreateOneUserHistoryBlockPayload',
    isList: false,
    argsTSName: 'MutationUserHistoryBlockCreateOneArgs',
    returnTSName: "Mutation['UserHistoryBlockCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneUserHistoryBlockPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneUserHistoryBlockInput!' },
        { name: 'sort', description: null, type: 'sort' },
      ],
      name: 'UserHistoryBlockCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ReconheceWalletRemoveById: {
    type: 'RemoveByIdReconheceWalletPayload',
    schemaKey: 'ReconheceWalletRemoveById',
    entityName: 'RemoveByIdReconheceWalletPayload',
    isList: false,
    argsTSName: 'MutationReconheceWalletRemoveByIdArgs',
    returnTSName: "Mutation['ReconheceWalletRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdReconheceWalletPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'ReconheceWalletRemoveById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ReconheceWalletCreateOne: {
    type: 'CreateOneReconheceWalletPayload',
    schemaKey: 'ReconheceWalletCreateOne',
    entityName: 'CreateOneReconheceWalletPayload',
    isList: false,
    argsTSName: 'MutationReconheceWalletCreateOneArgs',
    returnTSName: "Mutation['ReconheceWalletCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneReconheceWalletPayload',
      args: [{ name: 'record', description: null, type: 'CreateOneReconheceWalletInput!' }],
      name: 'ReconheceWalletCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ReconheceWalletUpdateById: {
    type: 'UpdateByIdReconheceWalletPayload',
    schemaKey: 'ReconheceWalletUpdateById',
    entityName: 'UpdateByIdReconheceWalletPayload',
    isList: false,
    argsTSName: 'MutationReconheceWalletUpdateByIdArgs',
    returnTSName: "Mutation['ReconheceWalletUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdReconheceWalletPayload',
      args: [{ name: 'record', description: null, type: 'UpdateByIdReconheceWalletInput!' }],
      name: 'ReconheceWalletUpdateById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  SetWarningAt: {
    type: 'WarningAtResult',
    schemaKey: 'SetWarningAt',
    entityName: 'WarningAtResult',
    isList: false,
    argsTSName: 'MutationSetWarningAtArgs',
    returnTSName: "Mutation['SetWarningAt']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'WarningAtResult',
      args: [{ name: 'idUserCreditOperation', description: null, type: 'String!' }],
      name: 'SetWarningAt',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  EmailStatsCreateMany: {
    type: 'CreateManyEmailStatsPayload',
    schemaKey: 'EmailStatsCreateMany',
    entityName: 'CreateManyEmailStatsPayload',
    isList: false,
    argsTSName: 'MutationEmailStatsCreateManyArgs',
    returnTSName: "Mutation['EmailStatsCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyEmailStatsPayload',
      args: [{ name: 'records', description: null, type: '[CreateManyEmailStatsInput!]!' }],
      name: 'EmailStatsCreateMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  EmailStatsCreateOne: {
    type: 'CreateOneEmailStatsPayload',
    schemaKey: 'EmailStatsCreateOne',
    entityName: 'CreateOneEmailStatsPayload',
    isList: false,
    argsTSName: 'MutationEmailStatsCreateOneArgs',
    returnTSName: "Mutation['EmailStatsCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneEmailStatsPayload',
      args: [{ name: 'record', description: null, type: 'CreateOneEmailStatsInput!' }],
      name: 'EmailStatsCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  EmailUnsubscribeCreateMany: {
    type: 'CreateManyEmailUnsubscribePayload',
    schemaKey: 'EmailUnsubscribeCreateMany',
    entityName: 'CreateManyEmailUnsubscribePayload',
    isList: false,
    argsTSName: 'MutationEmailUnsubscribeCreateManyArgs',
    returnTSName: "Mutation['EmailUnsubscribeCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyEmailUnsubscribePayload',
      args: [{ name: 'records', description: null, type: '[CreateManyEmailUnsubscribeInput!]!' }],
      name: 'EmailUnsubscribeCreateMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  EmailUnsubscribeCreateOne: {
    type: 'CreateOneEmailUnsubscribePayload',
    schemaKey: 'EmailUnsubscribeCreateOne',
    entityName: 'CreateOneEmailUnsubscribePayload',
    isList: false,
    argsTSName: 'MutationEmailUnsubscribeCreateOneArgs',
    returnTSName: "Mutation['EmailUnsubscribeCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneEmailUnsubscribePayload',
      args: [{ name: 'record', description: null, type: 'CreateOneEmailUnsubscribeInput!' }],
      name: 'EmailUnsubscribeCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  FileContentCampaignCreateOne: {
    type: 'CreateOneFileContentCampaignPayload',
    schemaKey: 'FileContentCampaignCreateOne',
    entityName: 'CreateOneFileContentCampaignPayload',
    isList: false,
    argsTSName: 'MutationFileContentCampaignCreateOneArgs',
    returnTSName: "Mutation['FileContentCampaignCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneFileContentCampaignPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneFileContentCampaignInput!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'FileContentCampaignCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  FileContentCampaignUpdateOne: {
    type: 'UpdateOneFileContentCampaignPayload',
    schemaKey: 'FileContentCampaignUpdateOne',
    entityName: 'UpdateOneFileContentCampaignPayload',
    isList: false,
    argsTSName: 'MutationFileContentCampaignUpdateOneArgs',
    returnTSName: "Mutation['FileContentCampaignUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneFileContentCampaignPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneFileContentCampaignInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneFileContentCampaignInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneFileContentCampaignInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'FileContentCampaignUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  FileContentCampaignRemoveById: {
    type: 'RemoveByIdFileContentCampaignPayload',
    schemaKey: 'FileContentCampaignRemoveById',
    entityName: 'RemoveByIdFileContentCampaignPayload',
    isList: false,
    argsTSName: 'MutationFileContentCampaignRemoveByIdArgs',
    returnTSName: "Mutation['FileContentCampaignRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdFileContentCampaignPayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'FileContentCampaignRemoveById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserCampaignCreateOne: {
    type: 'CreateOneUserCampaignPayload',
    schemaKey: 'UserCampaignCreateOne',
    entityName: 'CreateOneUserCampaignPayload',
    isList: false,
    argsTSName: 'MutationUserCampaignCreateOneArgs',
    returnTSName: "Mutation['UserCampaignCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneUserCampaignPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneUserCampaignInput!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' },
      ],
      name: 'UserCampaignCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserCampaignUpdateOne: {
    type: 'UpdateOneUserCampaignPayload',
    schemaKey: 'UserCampaignUpdateOne',
    entityName: 'UpdateOneUserCampaignPayload',
    isList: false,
    argsTSName: 'MutationUserCampaignUpdateOneArgs',
    returnTSName: "Mutation['UserCampaignUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneUserCampaignPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneUserCampaignInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneUserCampaignInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneUserCampaignInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'UserCampaignUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  MeuPdvParamsMutation: {
    type: 'MeuDiaPdv',
    schemaKey: 'MeuPdvParamsMutation',
    entityName: 'MeuDiaPdv',
    isList: false,
    argsTSName: 'MutationMeuPdvParamsMutationArgs',
    returnTSName: "Mutation['MeuPdvParamsMutation']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MeuDiaPdv',
      args: [
        { name: 'nome', description: null, type: 'String' },
        { name: 'endereco', description: null, type: 'String' },
        { name: 'complemento', description: null, type: 'String' },
        { name: 'bairro', description: null, type: 'String' },
        { name: 'uf', description: null, type: 'String' },
        { name: 'municipio', description: null, type: 'String' },
        { name: 'regional', description: null, type: 'String' },
        { name: 'canal', description: null, type: 'String' },
        { name: 'tipo', description: null, type: 'String' },
        { name: 'vaga', description: null, type: 'String' },
        { name: 'cep', description: null, type: 'String' },
        { name: 'loja', description: null, type: 'String' },
        { name: 'matricula', description: null, type: 'String' },
        { name: 'celular', description: null, type: 'String' },
        { name: 'diretoria', description: null, type: 'String' },
        { name: 'email', description: null, type: 'String' },
        { name: 'question', description: null, type: 'String' },
      ],
      name: 'MeuPdvParamsMutation',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ConsumerTempPassword: {
    type: 'Boolean!',
    schemaKey: 'ConsumerTempPassword',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationConsumerTempPasswordArgs',
    returnTSName: "Mutation['ConsumerTempPassword']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean!',
      args: [
        { name: 'CPF', description: null, type: 'String!' },
        { name: 'email', description: null, type: 'String!' },
      ],
      name: 'ConsumerTempPassword',
      isDeprecated: false,
    },
    isNonNull: true,
    kind: 'mutation',
  },

  ConsumerNewPassword: {
    type: 'Boolean!',
    schemaKey: 'ConsumerNewPassword',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationConsumerNewPasswordArgs',
    returnTSName: "Mutation['ConsumerNewPassword']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean!',
      args: [
        { name: 'CPF', description: null, type: 'String!' },
        { name: 'oldPassword', description: null, type: 'String!' },
        { name: 'newPassword', description: null, type: 'String!' },
      ],
      name: 'ConsumerNewPassword',
      isDeprecated: false,
    },
    isNonNull: true,
    kind: 'mutation',
  },

  SincronizeUserGU: {
    type: 'LogsImportGU',
    schemaKey: 'SincronizeUserGU',
    entityName: 'LogsImportGU',
    isList: false,
    argsTSName: 'MutationSincronizeUserGuArgs',
    returnTSName: "Mutation['SincronizeUserGU']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'LogsImportGU',
      args: [{ name: 'CPFS', description: null, type: '[String!]!' }],
      name: 'SincronizeUserGU',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  SincronizeRoleGroup: {
    type: 'LogsImportGU',
    schemaKey: 'SincronizeRoleGroup',
    entityName: 'LogsImportGU',
    isList: false,
    argsTSName: 'MutationSincronizeRoleGroupArgs',
    returnTSName: "Mutation['SincronizeRoleGroup']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'LogsImportGU',
      args: [{ name: 'idsCargos', description: null, type: '[Int!]!' }],
      name: 'SincronizeRoleGroup',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  MeuDiaPdvTableCreateOne: {
    type: 'CreateOneMeuDiaPdvTablePayload',
    schemaKey: 'MeuDiaPdvTableCreateOne',
    entityName: 'CreateOneMeuDiaPdvTablePayload',
    isList: false,
    argsTSName: 'MutationMeuDiaPdvTableCreateOneArgs',
    returnTSName: "Mutation['MeuDiaPdvTableCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneMeuDiaPdvTablePayload',
      args: [{ name: 'record', description: null, type: 'CreateOneMeuDiaPdvTableInput!' }],
      name: 'MeuDiaPdvTableCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  MeuDiaPdvTableUpdateOne: {
    type: 'UpdateOneMeuDiaPdvTablePayload',
    schemaKey: 'MeuDiaPdvTableUpdateOne',
    entityName: 'UpdateOneMeuDiaPdvTablePayload',
    isList: false,
    argsTSName: 'MutationMeuDiaPdvTableUpdateOneArgs',
    returnTSName: "Mutation['MeuDiaPdvTableUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneMeuDiaPdvTablePayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneMeuDiaPdvTableInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneMeuDiaPdvTableInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneMeuDiaPdvTableInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'MeuDiaPdvTableUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  MeuDiaPdvTableRemoveById: {
    type: 'RemoveByIdMeuDiaPdvTablePayload',
    schemaKey: 'MeuDiaPdvTableRemoveById',
    entityName: 'RemoveByIdMeuDiaPdvTablePayload',
    isList: false,
    argsTSName: 'MutationMeuDiaPdvTableRemoveByIdArgs',
    returnTSName: "Mutation['MeuDiaPdvTableRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdMeuDiaPdvTablePayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'MeuDiaPdvTableRemoveById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  MeuDiaPdvTableCreateMany: {
    type: 'CreateManyMeuDiaPdvTablePayload',
    schemaKey: 'MeuDiaPdvTableCreateMany',
    entityName: 'CreateManyMeuDiaPdvTablePayload',
    isList: false,
    argsTSName: 'MutationMeuDiaPdvTableCreateManyArgs',
    returnTSName: "Mutation['MeuDiaPdvTableCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyMeuDiaPdvTablePayload',
      args: [{ name: 'records', description: null, type: '[CreateManyMeuDiaPdvTableInput!]!' }],
      name: 'MeuDiaPdvTableCreateMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationNivelCreateOne: {
    type: 'CreateOneGameficationNivelPayload',
    schemaKey: 'GameficationNivelCreateOne',
    entityName: 'CreateOneGameficationNivelPayload',
    isList: false,
    argsTSName: 'MutationGameficationNivelCreateOneArgs',
    returnTSName: "Mutation['GameficationNivelCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneGameficationNivelPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneGameficationNivelInput!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'GameficationNivelCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationNivelUpdateOne: {
    type: 'UpdateOneGameficationNivelPayload',
    schemaKey: 'GameficationNivelUpdateOne',
    entityName: 'UpdateOneGameficationNivelPayload',
    isList: false,
    argsTSName: 'MutationGameficationNivelUpdateOneArgs',
    returnTSName: "Mutation['GameficationNivelUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneGameficationNivelPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneGameficationNivelInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneGameficationNivelInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneGameficationNivelInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'GameficationNivelUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationNivelUpdateById: {
    type: 'UpdateByIdGameficationNivelPayload',
    schemaKey: 'GameficationNivelUpdateById',
    entityName: 'UpdateByIdGameficationNivelPayload',
    isList: false,
    argsTSName: 'MutationGameficationNivelUpdateByIdArgs',
    returnTSName: "Mutation['GameficationNivelUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdGameficationNivelPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdGameficationNivelInput!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'GameficationNivelUpdateById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationNivelRemoveOne: {
    type: 'RemoveOneGameficationNivelPayload',
    schemaKey: 'GameficationNivelRemoveOne',
    entityName: 'RemoveOneGameficationNivelPayload',
    isList: false,
    argsTSName: 'MutationGameficationNivelRemoveOneArgs',
    returnTSName: "Mutation['GameficationNivelRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneGameficationNivelPayload',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterRemoveOneGameficationNivelInput' },
        { name: 'sort', description: null, type: 'SortRemoveOneGameficationNivelInput' },
      ],
      name: 'GameficationNivelRemoveOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationNivelCustomCreateOne: {
    type: 'CreateOneGameficationNivelCustomPayload',
    schemaKey: 'GameficationNivelCustomCreateOne',
    entityName: 'CreateOneGameficationNivelCustomPayload',
    isList: false,
    argsTSName: 'MutationGameficationNivelCustomCreateOneArgs',
    returnTSName: "Mutation['GameficationNivelCustomCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneGameficationNivelCustomPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneGameficationNivelCustomInput!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'GameficationNivelCustomCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationNivelCustomUpdateOne: {
    type: 'UpdateOneGameficationNivelCustomPayload',
    schemaKey: 'GameficationNivelCustomUpdateOne',
    entityName: 'UpdateOneGameficationNivelCustomPayload',
    isList: false,
    argsTSName: 'MutationGameficationNivelCustomUpdateOneArgs',
    returnTSName: "Mutation['GameficationNivelCustomUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneGameficationNivelCustomPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneGameficationNivelCustomInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneGameficationNivelCustomInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneGameficationNivelCustomInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'GameficationNivelCustomUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationNivelCustomUpdateById: {
    type: 'UpdateByIdGameficationNivelCustomPayload',
    schemaKey: 'GameficationNivelCustomUpdateById',
    entityName: 'UpdateByIdGameficationNivelCustomPayload',
    isList: false,
    argsTSName: 'MutationGameficationNivelCustomUpdateByIdArgs',
    returnTSName: "Mutation['GameficationNivelCustomUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdGameficationNivelCustomPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdGameficationNivelCustomInput!' },
        { name: 'filter', description: null, type: 'filter' },
      ],
      name: 'GameficationNivelCustomUpdateById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationNivelCustomRemoveOne: {
    type: 'RemoveOneGameficationNivelCustomPayload',
    schemaKey: 'GameficationNivelCustomRemoveOne',
    entityName: 'RemoveOneGameficationNivelCustomPayload',
    isList: false,
    argsTSName: 'MutationGameficationNivelCustomRemoveOneArgs',
    returnTSName: "Mutation['GameficationNivelCustomRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneGameficationNivelCustomPayload',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterRemoveOneGameficationNivelCustomInput' },
        { name: 'sort', description: null, type: 'SortRemoveOneGameficationNivelCustomInput' },
      ],
      name: 'GameficationNivelCustomRemoveOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ChallengeCreateOne: {
    type: 'CreateOneChallengePayload',
    schemaKey: 'ChallengeCreateOne',
    entityName: 'CreateOneChallengePayload',
    isList: false,
    argsTSName: 'MutationChallengeCreateOneArgs',
    returnTSName: "Mutation['ChallengeCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneChallengePayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneChallengeInput!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' },
      ],
      name: 'ChallengeCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ChallengeUpdateOne: {
    type: 'UpdateOneChallengePayload',
    schemaKey: 'ChallengeUpdateOne',
    entityName: 'UpdateOneChallengePayload',
    isList: false,
    argsTSName: 'MutationChallengeUpdateOneArgs',
    returnTSName: "Mutation['ChallengeUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneChallengePayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneChallengeInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneChallengeInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneChallengeInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'ChallengeUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ChallengeUpdateById: {
    type: 'UpdateByIdChallengePayload',
    schemaKey: 'ChallengeUpdateById',
    entityName: 'UpdateByIdChallengePayload',
    isList: false,
    argsTSName: 'MutationChallengeUpdateByIdArgs',
    returnTSName: "Mutation['ChallengeUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdChallengePayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdChallengeInput!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' },
      ],
      name: 'ChallengeUpdateById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ChallengeRemoveOne: {
    type: 'RemoveOneChallengePayload',
    schemaKey: 'ChallengeRemoveOne',
    entityName: 'RemoveOneChallengePayload',
    isList: false,
    argsTSName: 'MutationChallengeRemoveOneArgs',
    returnTSName: "Mutation['ChallengeRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneChallengePayload',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterRemoveOneChallengeInput' },
        { name: 'sort', description: null, type: 'SortRemoveOneChallengeInput' },
      ],
      name: 'ChallengeRemoveOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserChallengeUpdateById: {
    type: 'UpdateByIdUserChallengePayload',
    schemaKey: 'UserChallengeUpdateById',
    entityName: 'UpdateByIdUserChallengePayload',
    isList: false,
    argsTSName: 'MutationUserChallengeUpdateByIdArgs',
    returnTSName: "Mutation['UserChallengeUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdUserChallengePayload',
      args: [{ name: 'record', description: null, type: 'UpdateByIdUserChallengeInput!' }],
      name: 'UserChallengeUpdateById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserChallengeUpdateMany: {
    type: 'UpdateManyUserChallengePayload',
    schemaKey: 'UserChallengeUpdateMany',
    entityName: 'UpdateManyUserChallengePayload',
    isList: false,
    argsTSName: 'MutationUserChallengeUpdateManyArgs',
    returnTSName: "Mutation['UserChallengeUpdateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ',
      deprecationReason: null,
      type: 'UpdateManyUserChallengePayload',
      args: [
        { name: 'record', description: null, type: 'UpdateManyUserChallengeInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateManyUserChallengeInput' },
        { name: 'sort', description: null, type: 'SortUpdateManyUserChallengeInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
      ],
      name: 'UserChallengeUpdateMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  MissionAction: {
    type: 'Boolean',
    schemaKey: 'MissionAction',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationMissionActionArgs',
    returnTSName: "Mutation['MissionAction']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'challengeId', description: null, type: 'String!' },
        { name: 'usersCPF', description: null, type: '[String!]!' },
      ],
      name: 'MissionAction',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationRankingCreateOne: {
    type: 'CreateOneGameficationRankingPayload',
    schemaKey: 'GameficationRankingCreateOne',
    entityName: 'CreateOneGameficationRankingPayload',
    isList: false,
    argsTSName: 'MutationGameficationRankingCreateOneArgs',
    returnTSName: "Mutation['GameficationRankingCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneGameficationRankingPayload',
      args: [{ name: 'record', description: null, type: 'CreateOneGameficationRankingInput!' }],
      name: 'GameficationRankingCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationRankingUpdateOne: {
    type: 'UpdateOneGameficationRankingPayload',
    schemaKey: 'GameficationRankingUpdateOne',
    entityName: 'UpdateOneGameficationRankingPayload',
    isList: false,
    argsTSName: 'MutationGameficationRankingUpdateOneArgs',
    returnTSName: "Mutation['GameficationRankingUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneGameficationRankingPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneGameficationRankingInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneGameficationRankingInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneGameficationRankingInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'GameficationRankingUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationRankingUpdateById: {
    type: 'UpdateByIdGameficationRankingPayload',
    schemaKey: 'GameficationRankingUpdateById',
    entityName: 'UpdateByIdGameficationRankingPayload',
    isList: false,
    argsTSName: 'MutationGameficationRankingUpdateByIdArgs',
    returnTSName: "Mutation['GameficationRankingUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdGameficationRankingPayload',
      args: [{ name: 'record', description: null, type: 'UpdateByIdGameficationRankingInput!' }],
      name: 'GameficationRankingUpdateById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationRankingRemoveOne: {
    type: 'RemoveOneGameficationRankingPayload',
    schemaKey: 'GameficationRankingRemoveOne',
    entityName: 'RemoveOneGameficationRankingPayload',
    isList: false,
    argsTSName: 'MutationGameficationRankingRemoveOneArgs',
    returnTSName: "Mutation['GameficationRankingRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneGameficationRankingPayload',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterRemoveOneGameficationRankingInput' },
        { name: 'sort', description: null, type: 'SortRemoveOneGameficationRankingInput' },
      ],
      name: 'GameficationRankingRemoveOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationUserRankingCreateOne: {
    type: 'CreateOneGameficationUserRankingPayload',
    schemaKey: 'GameficationUserRankingCreateOne',
    entityName: 'CreateOneGameficationUserRankingPayload',
    isList: false,
    argsTSName: 'MutationGameficationUserRankingCreateOneArgs',
    returnTSName: "Mutation['GameficationUserRankingCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneGameficationUserRankingPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneGameficationUserRankingInput!' },
        { name: 'sort', description: null, type: 'sort' },
      ],
      name: 'GameficationUserRankingCreateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationUserRankingUpdateOne: {
    type: 'UpdateOneGameficationUserRankingPayload',
    schemaKey: 'GameficationUserRankingUpdateOne',
    entityName: 'UpdateOneGameficationUserRankingPayload',
    isList: false,
    argsTSName: 'MutationGameficationUserRankingUpdateOneArgs',
    returnTSName: "Mutation['GameficationUserRankingUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneGameficationUserRankingPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneGameficationUserRankingInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateOneGameficationUserRankingInput' },
        { name: 'sort', description: null, type: 'SortUpdateOneGameficationUserRankingInput' },
        { name: 'skip', description: null, type: 'Int' },
      ],
      name: 'GameficationUserRankingUpdateOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationUserRankingUpdateById: {
    type: 'UpdateByIdGameficationUserRankingPayload',
    schemaKey: 'GameficationUserRankingUpdateById',
    entityName: 'UpdateByIdGameficationUserRankingPayload',
    isList: false,
    argsTSName: 'MutationGameficationUserRankingUpdateByIdArgs',
    returnTSName: "Mutation['GameficationUserRankingUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdGameficationUserRankingPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdGameficationUserRankingInput!' },
        { name: 'sort', description: null, type: 'sort' },
      ],
      name: 'GameficationUserRankingUpdateById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  GameficationUserRankingRemoveOne: {
    type: 'RemoveOneGameficationUserRankingPayload',
    schemaKey: 'GameficationUserRankingRemoveOne',
    entityName: 'RemoveOneGameficationUserRankingPayload',
    isList: false,
    argsTSName: 'MutationGameficationUserRankingRemoveOneArgs',
    returnTSName: "Mutation['GameficationUserRankingRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneGameficationUserRankingPayload',
      args: [
        { name: 'filter', description: 'Filter by fields', type: 'FilterRemoveOneGameficationUserRankingInput' },
        { name: 'sort', description: null, type: 'SortRemoveOneGameficationUserRankingInput' },
      ],
      name: 'GameficationUserRankingRemoveOne',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserChallengePointsUpdateById: {
    type: 'UpdateByIdUserChallengePointsPayload',
    schemaKey: 'UserChallengePointsUpdateById',
    entityName: 'UpdateByIdUserChallengePointsPayload',
    isList: false,
    argsTSName: 'MutationUserChallengePointsUpdateByIdArgs',
    returnTSName: "Mutation['UserChallengePointsUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdUserChallengePointsPayload',
      args: [{ name: 'record', description: null, type: 'UpdateByIdUserChallengePointsInput!' }],
      name: 'UserChallengePointsUpdateById',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserChallengePointsUpdateMany: {
    type: 'UpdateManyUserChallengePointsPayload',
    schemaKey: 'UserChallengePointsUpdateMany',
    entityName: 'UpdateManyUserChallengePointsPayload',
    isList: false,
    argsTSName: 'MutationUserChallengePointsUpdateManyArgs',
    returnTSName: "Mutation['UserChallengePointsUpdateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ',
      deprecationReason: null,
      type: 'UpdateManyUserChallengePointsPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateManyUserChallengePointsInput!' },
        { name: 'filter', description: 'Filter by fields', type: 'FilterUpdateManyUserChallengePointsInput' },
        { name: 'sort', description: null, type: 'SortUpdateManyUserChallengePointsInput' },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
      ],
      name: 'UserChallengePointsUpdateMany',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserCreditChallengePointsAction: {
    type: 'Boolean',
    schemaKey: 'UserCreditChallengePointsAction',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserCreditChallengePointsActionArgs',
    returnTSName: "Mutation['UserCreditChallengePointsAction']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'walletId', description: null, type: 'String!' },
        { name: 'userChallengeId', description: null, type: 'String!' },
      ],
      name: 'UserCreditChallengePointsAction',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  UserCreditChallengePointsForManyAction: {
    type: 'Boolean',
    schemaKey: 'UserCreditChallengePointsForManyAction',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserCreditChallengePointsForManyActionArgs',
    returnTSName: "Mutation['UserCreditChallengePointsForManyAction']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'walletId', description: null, type: 'String!' },
        { name: 'userChallengeId', description: null, type: '[String]!' },
      ],
      name: 'UserCreditChallengePointsForManyAction',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  PortalCheck: {
    type: 'Boolean',
    schemaKey: 'PortalCheck',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationPortalCheckArgs',
    returnTSName: "Mutation['PortalCheck']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'challengeId', description: null, type: 'String!' },
        { name: 'userChallengeId', description: null, type: 'String!' },
        { name: 'userId', description: null, type: 'String!' },
      ],
      name: 'PortalCheck',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },

  ReconheceCreateTempToken: {
    type: 'String',
    schemaKey: 'ReconheceCreateTempToken',
    entityName: 'String',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Mutation['ReconheceCreateTempToken']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Cria um "one time roken" que será enviado via url ao Reconhece',
      deprecationReason: null,
      type: 'String',
      args: [],
      name: 'ReconheceCreateTempToken',
      isDeprecated: false,
    },
    isNonNull: false,
    kind: 'mutation',
  },
};

export const useClient = useClientFactory<any, Methods>();
