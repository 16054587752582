import React, { useState } from 'react';
import styled from 'styled-components';
import { Breadcrumb, TitleDescription, CardCategory } from '@digi-tim-19/components';
import { useParams, useHistory } from 'react-router';
import { routes } from '../../config/routes';
import { imgResidencial } from './images';

import { PageTemplate } from '../../components/Layout/PageTemplate';
import { findCorrectName } from '../../utils/productNames';

const useData = () => {
  const history = useHistory();
  const { product, category } = useParams();

  if (!category || !product) {
    return [];
  }

  const cards = getCards(history, category, product);

  if (category === 'cardapio_smb' && product === 'para_o_cliente') {
    return [cards.mapaPecas, cards.materiaisTimEmpresa];
  }

  if (category === 'cardapio_smb' && (product === 'ampli' || product === 'c6_bank')) {
    return [cards.informativos, cards.videos, cards.materiaisApoio];
  }

  if (category === 'cardapio_smb') {
    return [cards.regulamentosNIIC, cards.informativos, cards.videos, cards.materiaisApoio];
  }

  if (category === 'cardapio_de_ofertas' && ['controle', 'pos_pago'].includes(product)) {
    return [
      cards.regulamentosNIIC,
      cards.informativos,
      cards.videos,
      cards.processos,
      cards.argumentacaoVendas,
      cards.comparaAi,
    ];
  }

  if (category === 'cardapio_de_ofertas' && (product === 'c6_bank' || product === 'ampli')) {
    return [cards.informativos, cards.videos, cards.argumentacaoVendas];
  }

  return [cards.regulamentosNIIC, cards.informativos, cards.videos, cards.processos, cards.argumentacaoVendas];
};

const StyledContent = styled.section`
  background: transparent;
  display: flex;
  flex-wrap: wrap;
`;

export const SelecaoCategoria = () => {
  const { product, category } = useParams();

  const setIcon = () => {
    switch (product) {
      case 'internet':
        return 'iconat-sign';
      case 'solucoes':
        return 'bulb';
      case 'voz':
        return 'iconvoice';
      case 'residencial':
      case 'fixo':
      case 'tim_fixo':
        return 'phone';
      case 'planos_corporativos':
      case 'tim_empresa_digital':
      case 'tim_black_empresa__':
        return 'iconnecktie';
      case 'servicos':
        return 'play-circle';
      case 'para_o_cliente':
        return 'user';
      case 'c6_bank':
        return 'bank';
      default:
        return 'shopping';
    }
  };

  const setCustomIcon = () => {
    if (category == 'planos_corporativos' || product == 'internet' || product == 'voz') {
      return true;
    } else {
      return false;
    }
  };

  const setDescription = () => {
    if (product === 'servicos') {
      return `Aqui você encontra todas as informações necessárias sobre ${
        product ? 'os serviços de valor agregado (VAS).' : ''
      }`;
    } else if (product == 'para_o_cliente') {
      return `${product ? 'Aqui você irá encontrar todos os materiais necessários para o cliente ' : ''}`;
    } else if (product == 'voz') {
      return `Aqui você encontra todas as informações necessárias sobre ${product ? 'os produtos de voz.' : ''}`;
    } else if (product == 'internet') {
      return `${product ? 'Aqui você encontra todas as informações necessárias sobre os produtos de internet.' : ''}`;
    } else if (product == 'solucoes') {
      return `${product ? 'Aqui você encontra todas as informações necessárias sobre os produtos de Soluções.' : ''}`;
    } else if (product == 'ampli') {
      return `${
        product ? 'Aqui você encontra todas as informações necessárias sobre a parceria da TIM com a Ampli.' : ''
      }`;
    } else {
      return `Aqui você encontra todas as informações necessárias sobre ${product ? findCorrectName(product) : ''}`;
    }
  };

  const items = useData();
  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: category ? findCorrectName(category) : '' },
            {
              title: product ? findCorrectName(product) : '',
              link: category && product ? routes.selectFilter.mount(category, product) : '',
            },
          ]}
        />

        <TitleDescription
          iconType={setIcon()}
          customIcon={setCustomIcon()}
          title={product ? findCorrectName(product) : ''}
          description={setDescription()}
        />
        <StyledContent>
          {items.map((card, index) => (
            <CardCategory key={index} {...card} />
          ))}
        </StyledContent>
      </React.Fragment>
    </PageTemplate>
  );
};

function getCards(history: any, category: any, product: any) {
  return {
    regulamentosNIIC: {
      title: 'Regulamentos\nNIIC',
      description: 'Aqui você encontrará os documentos detalhados das ofertas',
      urlBackground: '/defaultImages/5356_CardRegulamentos.jpg',
      icon: 'iconcheckList-copy',
      customIcon: true,
      url: () => history.push(routes.regulamento.mount(category, product)),
    },
    informativos: {
      title: 'Informativos',
      description: 'Conteúdos resumidos que você recebeu por e-mail.',
      urlBackground: '/defaultImages/5356_CardInformativos.jpg',
      icon: 'info-circle',
      url: () => history.push(routes.catProdinformativos.mount(category, product)),
    },
    videos: {
      title: 'Vídeos',
      description: 'Reforço, argumentação e processos, LIVEs disponíveis no OnTube sobre esse serviço',
      urlBackground: '/defaultImages/5356_CardVideos.jpg',
      icon: 'play-square',
      url: () => history.push(routes.OnTubePlaylist.mount(product)),
    },
    materiaisApoio: {
      title: 'Materiais de Apoio',
      description: 'Matinais, guias rápidos e outros materiais de consulta sobre oferta.',
      urlBackground: '/defaultImages/5356_CardMateriaisApoio.jpg',
      icon: 'book',
      url: () => history.push(routes.materiaisApoio.mount(category, product)),
    },
    processos: {
      title: 'Processos',
      description:
        'Documentos de vendas, pós vendas e administrativos referente a Suporte a Vendas, HDC e operações comerciais',
      urlBackground: imgResidencial.bgProcessos,
      icon: 'schedule',
      url: () => history.push(routes.catProdProcessos.mount(category, product)),
    },
    argumentacaoVendas: {
      title: 'Argumentação de vendas',
      description: 'Matinais, guias rápidos e outros materiais de consulta sobre oferta.',
      urlBackground: '/defaultImages/5356_CardArgumentacaoVendas.jpg',
      icon: 'sound',
      url: () => history.push(routes.argumentacaoFDV.mount(category, product)),
    },
    mapaPecas: {
      customIcon: true,
      title: 'Book de Soluções e Mapa de Peças',
      description: 'Soluções da TIM e guia dos materiais pra clientes.',
      urlBackground: '/defaultImages/5356_CardMapaPecas.jpg',
      icon: 'iconmap2',
      url: () => history.push(routes.mapaPecas.mount(category, product)),
    },
    materiaisTimEmpresa: {
      title: 'Materiais TIM Empresas',
      description: 'Todos os materiais para impressão necessários para o cliente',
      urlBackground: '/defaultImages/5356_CardMateriaisTIMEmpresas.jpg',
      icon: 'file-text',
      url: () => history.push(routes.materiaisTimEmpresas.mount(category, product)),
    },
    comparaAi: {
      title: 'Compara Aí',
      description: 'Veja o comparativo das nossas ofertas com a concorrência.',
      urlBackground: '/defaultImages/Card_compare_1.jpg',
      icon: 'column-width',
      url: () => history.push(routes.comparaAi.mount(category, product)),
    },
  };
}
