"use strict";

var _slicedToArray = require("/home/jenkins/tim-consumer/packages/site-consumer/node_modules/@babel/runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var antd_1 = require("antd");

var __1 = require("..");

exports.LikeVideo = function (_ref) {
  var videoId = _ref.videoId,
      liked = _ref.liked,
      count = _ref.count;

  var _react_1$useState = react_1.useState(liked),
      _react_1$useState2 = _slicedToArray(_react_1$useState, 2),
      likedState = _react_1$useState2[0],
      setLiked = _react_1$useState2[1];

  var _react_1$useState3 = react_1.useState(count || 0),
      _react_1$useState4 = _slicedToArray(_react_1$useState3, 2),
      likesCount = _react_1$useState4[0],
      setLikesCount = _react_1$useState4[1];

  var MaterialLikeToggle = __1.useClient('MaterialLikeToggle');

  react_1.useEffect(function () {
    if (count) setLikesCount(count);
  }, [count]);
  return react_1.default.createElement("div", {
    style: {
      color: '#6c757d',
      alignItems: 'center',
      display: 'flex'
    }
  }, react_1.default.createElement(antd_1.Icon, {
    onClick: function onClick() {
      MaterialLikeToggle.fetch({
        variables: {
          recordId: videoId
        }
      }).then(thenLikeToggle).catch(catchLikeToggle);

      function thenLikeToggle(ctx) {
        ctx.result && setLiked(!likedState);
        setLikesCount(likedState ? likesCount - 1 : likesCount + 1);
      }

      function catchLikeToggle(errors) {
        console.error('catchLikeToggle', errors);
      }
    },
    className: "like-msg",
    type: "heart",
    theme: likedState ? 'filled' : 'outlined',
    style: {
      fontSize: '20px',
      color: likedState ? '#dc3545' : '#6c757d',
      marginRight: '2px'
    }
  }), react_1.default.createElement("span", {
    style: {
      fontSize: '18px',
      marginRight: '15px'
    }
  }, likesCount));
};