import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { FormInformativos } from '../FormInformativos/FormInformativos';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { InformativosWrapper } from './stylesCadastrar';
import { routes } from '../../../config/routes';

export const InformativosCadastrar = () => {
  return (
    <PageTemplate>
      <>
        <InformativosWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              {
                title: 'Informativos',
                link: routes.informativosListar.mount()
              },
              { title: 'Cadastrar', link: routes.informativosCadastrar.mount() }
            ]}
          />
          <TitleDescription
            iconType="info-circle"
            title="Informativos"
            description="Cadastramento e consulta de Informativos"
          />
        </InformativosWrapper>

        <ProviderSelecionarPublico>
          <FormInformativos />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
