import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { NotificationsWrapper, Container } from '../stylesForm';
import { Form } from '../Form';
import { routes } from '../../../config/routes';

export const NotificacaoBackupCadastrar = () => {
  return (
    <PageTemplate>
      <>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              {
                title: 'Gerenciar conteúdo',
                link: routes.notificacaoBackupListar.mount()
              },
              {
                title: 'Notificações',
                link: routes.notificacaoBackupListar.mount()
              },
              {
                title: 'Cadastrar Notificação de backup',
                link: routes.notificacaoBackupCadastrar.mount()
              }
            ]}
          />
          <TitleDescription
            iconType="bell"
            title="Notificação de backup"
            description="Cadastramento e consulta de notificações de backup"
          />
        </NotificationsWrapper>
        <h3>Cadastrar Notificação de backup</h3>

        <Container>
          <ProviderSelecionarPublico>
            <Form />
          </ProviderSelecionarPublico>
        </Container>
      </>
    </PageTemplate>
  );
};
