import React, { useEffect, useMemo } from 'react';
import { Former } from '@digi-tim-19/components';

import {
  IncentiveCampaign,
  EnumCampaignContestationResponseStatus
} from '../../autogenerated/client/types';
import { FormContainer, Divider } from './styles';

import { useParams, useHistory } from 'react-router';
import { formatDate } from '@digi-tim-19/utils/build';
import { message } from 'antd';
import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';
import Download from './Download';
import { EnumFileKind } from '@digi-tim-19/components/build/main/DataProviders/types';

const appendToFragment = `
campaign {
  title
  status {
    label
  }
}
user {
  name
  channel
  region
  roleGroup
  matricula
}
files {
  downloadUrl
  signedUrl
  title
}
response {
  status
  title
  body
}
`;

const options = [
  {
    label: 'Procedente',
    value: EnumCampaignContestationResponseStatus.Founded
  },

  {
    label: 'Não Procedente',
    value: EnumCampaignContestationResponseStatus.Unfounded
  }
];

export const Form = (props: TFormIncentiveCampaignEditProps) => {
  const { id } = useParams();
  const history = useHistory();
  const getContestation = useClient('CampaignContestationFindOne');
  const CampaignContestationResolve = useClient('CampaignContestationResolve');

  useEffect(() => {
    getContestation.fetch({
      variables: {
        filter: {
          _id: id
        }
      },
      appendToFragment
    });
  }, [id]); //eslint-disable-line

  const contestation = getContestation?.result;

  const initValues = useMemo(
    () => ({
      campaignTitle: contestation?.campaign?.title,
      message: contestation?.message,
      protocol: contestation?.protocol,
      name: contestation?.user?.name,
      channel: contestation?.user?.channel,
      region: contestation?.user?.region,
      role: contestation?.user?.roleGroup,
      subscription: contestation?.user?.matricula,
      indicator: contestation?.indicator,
      createdAt: formatDate(contestation?.createdAt, 'DD [de] MMMM [de] YYYY'),
      evidence: contestation?.files?.find((file) => file)?.title,
      title:  contestation?.response?.title || `Protocolo n ${contestation?.protocol} - Retorno contestação`,
      status: contestation?.response?.status,
      body: contestation?.response?.body,
    }),
    [contestation]
  );

  return (
    <FormContainer>
      <Former
        initialValues={initValues}
        config={{
          fields: [
            {
              list: [
                {
                  inline: true,
                  list: [
                    {
                      name: 'campaignTitle',
                      label: 'Título da Campanha',
                      type: 'text',
                      extraProps: {
                        readOnly: true
                      }
                    },
                    {
                      name: 'protocol',
                      label: 'Protocolo',
                      type: 'text',

                      maxWidth: '20%',
                      extraProps: {
                        readOnly: true
                      }
                    }
                  ]
                },
                {
                  inline: true,
                  list: [
                    {
                      name: 'name',
                      label: 'Nome',
                      type: 'text',

                      extraProps: {
                        readOnly: true
                      }
                    },

                    {
                      name: 'channel',
                      label: 'Canal',
                      type: 'text',

                      extraProps: {
                        readOnly: true
                      }
                    },

                    {
                      name: 'region',
                      label: 'Região',
                      type: 'text',

                      extraProps: {
                        readOnly: true
                      }
                    },
                    {
                      name: 'role',
                      label: 'Cargo',
                      type: 'text',

                      extraProps: {
                        readOnly: true
                      }
                    }
                  ]
                },
                {
                  inline: true,
                  list: [
                    {
                      name: 'subscription',
                      label: 'Matrícula',
                      type: 'text',

                      extraProps: {
                        readOnly: true
                      }
                    },
                    {
                      name: 'indicator',
                      label: 'Indicador',
                      type: 'text',

                      extraProps: {
                        readOnly: true
                      }
                    },
                    {
                      custom: (
                        <Download
                          downloadUrl={
                            contestation?.files?.find((file) => file)
                              ?.signedUrl
                          }
                        />
                      )
                    },
                    {
                      name: 'createdAt',
                      label: 'Data de envio',
                      type: 'text',

                      extraProps: {
                        readOnly: true
                      }
                    }
                  ]
                },
                {
                  name: 'message',
                  label: 'Mensagem',
                  type: 'textarea',

                  extraProps: {
                    readOnly: true
                  }
                }
              ]
            },
            {
              custom: <Divider />
            },
            {
              name: 'status',
              label: 'Status',
              type: 'select',
              options,
              required: true
            },
            {
              name: 'file',
              label: '',
              type: 'upload',
              required: false,
              extraProps: {
                CTA: 'Evidência',
                kind: EnumFileKind.AnyDoc
              }
            },
            {
              name: 'title',
              label: 'Título',
              type: 'text',
              required: true
            },
            {
              name: 'body',
              label: 'Justifique',
              type: 'textarea',
              extraProps: {
                rows: 6
              },
              required: true
            }
          ],
          submitButton: {
            disabled: Boolean(contestation?.response),
            label: 'enviar'
          }
        }}
        onSubmit={async ({ data }) => {
          const { title, status, body, file } = data;

          await CampaignContestationResolve.fetch({
            variables: {
              _id: getContestation?.result?._id,
              title,
              file,
              status,
              body
            }
          }).then((ctx) => {
            if (!ctx.result) {
              message.error('Não foi possível realizar essa ação');
            } else {
              message.success('Contestação solucionada com sucesso');
              history.push(routes.contestacaoListar.mount());
            }
          });
        }}
      />
    </FormContainer>
  );
};

export type TFormIncentiveCampaignEditProps = {
  initialValues?: Partial<IncentiveCampaign>;
};
