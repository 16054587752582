import React, { useState, useEffect } from 'react';
import { Pagination, Icon } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { ListGridItemProps } from '@digi-tim-19/components/build/main/ListGrid/PropsListGrid';
import { getRouterByMaterial } from '@digi-tim-19/utils/build/routes/routesConsumer';
import {
  Breadcrumb,
  TitleDescription,
  ListGrid,
  SelectFilter,
  LoadingIndicator,
  MaterialProvider,
  ShareModal,
  PinFolderModal,
} from '@digi-tim-19/components';
import { clickDownload } from '@digi-tim-19/utils/build';
import { useClient } from '../../autogenerated/client/client';
import moment from 'moment';
import { routes } from '../../config/routes';
import { isImageExtension } from '@digi-tim-19/utils';
import { SortFindManyMaterialInput, EnumMaterialKind } from '../../autogenerated/client/types';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';

const SearchWrapper = styled.div``;

const ResultSearchWrapper = styled.div``;

const AmountResultsFound = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.gray0};
`;

const AmountResults = styled.span`
  text-transform: uppercase;
  color: ${(props) => props.theme.blue};
  font-weight: bold;
  margin-left: 3px;
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const OptionsListResultWrapper = styled.div`
  margin: 30px 0;
`;

const fragment = `
  pageInfo {
    pageCount
    itemCount
  }
  items {
    _id
    createdAt
    updatedAt
    title
    description
    kindName
    kind
    html
    fileIds
    files {
      _id
      title
      url
      signedUrl
      kind
      extension
      downloadUrl
    }
    communicationItemsConfig{
      kind
      templateName
    }
    categories {
      _id
      name
      parentId
    }
  }`;

export const Busca = () => {
  const { params } = useRouteMatch();
  const history = useHistory();
  const [sort, setSort] = useState<SortFindManyMaterialInput | null>(null);
  const [items, setItems] = useState<ListGridItemProps[]>([]);
  const [itemCount, setItemCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState(params.search);

  const apiAnalyticsDownload = useClient('AnalyticsDownloads');

  const pageSize = 10;

  useEffect(() => {
    setSearch(params.search);
  }, [params]);

  const getMateriais = useClient('MaterialSearch');

  const selectsOrders = [
    {
      defaultValue: 'Ordenar Por',
      action: (value: SortFindManyMaterialInput) => {
        setSort(value);
        setPageNumber(1);
      },
      options: [
        {
          name: 'Mais antigo',
          value: SortFindManyMaterialInput.IdAsc,
        },
        {
          name: 'Mais recente',
          value: SortFindManyMaterialInput.IdDesc,
        },
      ],
    },
  ];

  const { listFolders, addFolder } = useFoldersPin();

  function viewFile(id: any, material: any) {
    if (material.length) {
      if (isImageExtension(material[0].extension)) {
        history.push(routes.imageViewer.mount(id));
      } else {
        history.push(routes.pdfViewer.mount(id));
      }
    } else {
      history.push(routes.conteudoMaterial.mount(id));
    }
  }

  useEffect(() => {
    getMateriais
      .fetch({
        appendToFragment: fragment,
        variables: {
          perPage: pageSize,
          page: pageNumber,
          search: search,
          sort,
        },
      })
      .then((ctx) => {
        if (ctx.result) {
          const itemsResult = ctx.result.items?.map(
            (material): ListGridItemProps => {
              let imgBody =
                (material?.kind === EnumMaterialKind.CommunicationBackup &&
                  material?.files?.find((el) => el)?.extension !== 'pdf' &&
                  material?.files?.find((el) => el)?.signedUrl) ||
                (material?.kind === EnumMaterialKind.Communication &&
                  material?.files?.find((el) => el)?.extension !== 'pdf' &&
                  material?.files?.find((el) => el)?.signedUrl) ||
                undefined;

              if (material?.files && material.files.length > 1) {
                if (material.files[1]?.title)
                  //&& isImage(material.files[1]?.title)
                  imgBody = material.files[1]?.signedUrl || '';
              }

              const routesMaterial = getRouterByMaterial(material, routes);

              return {
                data: moment(material?.createdAt)
                  .format('DD/MM/YY')
                  .toString(),
                prefixDate: 'Cadastrado em - ',
                dataUpdate: moment(material?.updatedAt)
                  .format('DD/MM/YY')
                  .toString(),
                prefixDateUpdate: 'Atualizado em - ',
                tituloHeader: material?.kindName || '',
                tituloCard: material?.title || '',
                descricao: material?.description || '',
                imgBody: undefined,
                categories: material?.categories,
                renderShare: () => (
                  <MaterialProvider material={material as any}>
                    <ShareModal />
                  </MaterialProvider>
                ),
                actions: {
                  pin: false,
                  share: true,
                  image: material?.files?.[0]?.kind === 'any_doc',
                  download: material?.files?.[0]?.kind === 'any_doc',
                  access: material?.files?.[0]?.kind !== 'any_doc',
                  onAccess: () => {
                    if (routesMaterial.routerAccess) {
                      history.push(routesMaterial.routerAccess);
                    } else if (routesMaterial.routerDownload) {
                      clickDownload(routesMaterial.routerDownload);
                    }
                  },
                  onDownload: () => {
                    const fileResult = material?.files ? material?.files[0] : null;
                    if (fileResult !== null && fileResult !== undefined) {
                      let a = document.createElement('a');
                      a.href = fileResult?.signedUrl || '';
                      a.download = fileResult?.title || '';
                      a.target = '_blank';
                      a.click();
                      apiAnalyticsDownload.fetch({ variables: { fileId: fileResult?._id || '' } });
                    }
                  },
                },
              };
            },
          );

          setItems(itemsResult || []);
          setItemCount(ctx.result.count || 0);
        }
      });
  }, [search, sort, pageNumber]);

  return (
    <PageTemplate>
      <SearchWrapper>
        <Breadcrumb items={[{ title: 'Home', link: routes.home.mount() }, { title: 'Busca' }]} />

        <TitleDescription
          iconType={'search'}
          title={'Busca'}
          description={'Busque por tipos de conteúdo, palavras-chave, nome do documento, título e descrição'}
        />

        {!getMateriais.loading ? (
          <ResultSearchWrapper>
            <AmountResultsFound>
              Foram encontrados
              <AmountResults>{itemCount} resultados</AmountResults>
            </AmountResultsFound>

            <OptionsListResultWrapper>
              <SelectFilter selects={selectsOrders} />
            </OptionsListResultWrapper>

            <ListGrid items={items} />

            <PaginationWrapper>
              <Pagination
                current={pageNumber}
                total={itemCount}
                pageSize={pageSize}
                onChange={(page) => setPageNumber(page)}
              />
            </PaginationWrapper>
          </ResultSearchWrapper>
        ) : (
          <LoadingIndicator />
        )}

        {items.length === 0 && (
          <ResultSearchWrapper>
            <AmountResultsFound>
              Foram encontrados
              <AmountResults>0 resultados</AmountResults>
            </AmountResultsFound>
          </ResultSearchWrapper>
        )}
      </SearchWrapper>
    </PageTemplate>
  );
};
