import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { Breadcrumb, JoystickIconSVG, LoadingIndicator, Table, TitleDescription } from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { CleanButton, FilterContainer, NotificationsWrapper, Search } from './styles';
import { columns } from './columns';
import { useClient } from '../../../autogenerated/client/client';
import { Challenge } from '../../../autogenerated/client/types';
import { TypeChallenge } from '../../Gamefication/Form/Form';
import { ContainerIcon } from '../../Gamefication/List/styles';
import { escapeStringRegexp } from '../../../utils/formatters';

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    title
    action
    kind
    typeChallenge
    category
    startDate
    endDate
    amountChips
    createdAt
    updatedAt
    challengeData
  }
`;

export const GameficationMissionList = () => {
  const getChallenges = useClient('ChallengePagination');
  const [page, setPage] = React.useState(1);
  const [filterSearch, setFilterSearch] = React.useState();
  const [data, setData] = React.useState();
  const perPage = 10;
  const total = getChallenges?.result?.pageInfo?.itemCount || 0;

  const resetFilters = () => {
    setFilterSearch(undefined);
    setPage(1);
  };

  const handleGetChallenges = (filterSearch: any) => {
    getChallenges
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
            action: TypeChallenge.mission,
          },
        },
      })
      .then((response: any) => {
        setData(
          response?.result?.items?.map((challengeEl: Partial<Challenge>) => ({
            ...challengeEl,
            typeChallenge: TypeChallenge.mission,
          })),
        );
      });
  };

  React.useEffect(() => {
    handleGetChallenges(filterSearch);
  }, [filterSearch]);

  return (
    <PageTemplate>
      <React.Fragment>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Missões' },
              { title: 'Listar' },
            ]}
          />
          <TitleDescription
            renderCustomIcon={
              <ContainerIcon>
                <JoystickIconSVG />
              </ContainerIcon>
            }
            title="Missões"
            description="Listar e consultar as missões"
          />
        </NotificationsWrapper>
        <FilterContainer>
          <Search
            placeholder="Busca por nome"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e: any) => {
              setFilterSearch(e.target.value);
              setPage(1);
            }}
          />

          <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
        </FilterContainer>
        {getChallenges.loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e: any) => setPage(e),
            }}
          />
        )}
      </React.Fragment>
    </PageTemplate>
  );
};
