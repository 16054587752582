import React from 'react';

import {
  Former,
  SelecionarPublico,
  usePublico,
  SearchUser,
  LoadingIndicator
} from '@digi-tim-19/components';

import {
  EnumFileKind,
  EnumMaterialKind
} from '../../autogenerated/client/types';
import { message } from 'antd';
import { routes } from '../../config/routes';
import { useHistory } from 'react-router';
import { Container } from './Cadastrar/stylesCadastrar';
import { useClient } from '../../autogenerated/client/client';
import { Material } from '../../autogenerated/client/types';
import { useDimensions } from '@digi-tim-19/utils/build';
import { useContentTypePermissions } from '../../hooks/useContentTypePermissions';
import { selectedChannels } from '../../utils/selectedChannels';
const selectedChannelsConsumer = selectedChannels();

const DocKind = EnumFileKind.Pdf;
const ImageKind = EnumFileKind.CardThumb;

export const Form = (props: TFormMaterialEditProps) => {
  const permissions = useContentTypePermissions();
  const {
    availableAtChannels,
    availableAtRegions = [],
    availableAtRoleGroups = []
  } = usePublico();
  const history = useHistory();

  const isUpdate = !!props.initialValues;

  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const createMaterial = useClient('MaterialCreateOne');

  const initialValues = {
    ...props.initialValues,
    documento: (props.initialValues?.files || []).find(
      (el) => el && el.kind === DocKind
    )?._id,
    cardimage: (props.initialValues?.files || []).find(
      (el) => el && el.kind === ImageKind
    )?._id,
    notifyEmailsBeforeExpire:
      props.initialValues?.validity?.notifyEmailsBeforeExpire
  };

  const { isMobile } = useDimensions();

  if (permissions.loading) return <LoadingIndicator />;

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              inline: !isMobile,
              list: [
                {
                  name: 'validity',
                  label: 'Data de Vigência',
                  type: 'rangePicker',
                  required: true,
                  extraProps: {
                    format: 'DD/MM/YYYY'
                  },
                  maxWidth: '250px'
                },
                {
                  name: 'documento',
                  label: '',
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: DocKind,
                    CTA: 'IMPORTAR MAPA'
                  }
                },
                {
                  name: 'cardimage',
                  label: '',
                  type: 'upload',
                  extraProps: {
                    kind: ImageKind,
                    CTA: 'IMPORTAR IMAGEM'
                  }
                }
              ]
            },
            {
              list: [
                {
                  name: 'title',
                  label: 'TÍTULO',
                  required: true,
                  validate: (value: any) => {
                    const format = /[#%&;]/;
                    if (format.test(value))
                      return 'Os caracteres #%&; não são permitidos';

                    if (value)
                      return !(value.length > 110)
                        ? undefined
                        : 'Máximo de 110 caracteres';

                    return undefined;
                  }
                }
              ]
            },
            {
              name: 'tags',
              label: 'tags',
              type: 'tags'
            },
            {
              name: 'notifyEmailsBeforeExpire',
              label: 'Notificar antes de expirar',
              placeholder: 'Notificar antes de expirar',
              extraProps: {
                returnProp: 'email'
              },
              component: SearchUser
            },
            {
              className: 'Footer',
              inline: true,
              list: [
                {
                  custom: (
                    <SelecionarPublico
                      initialValues={props.initialValues}
                      selectedChannels={
                        !isUpdate ? selectedChannelsConsumer : []
                      }
                      {...permissions.filterOptions}
                    />
                  )
                }
              ]
            }
          ],
          submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(routes.mapaDeOfertaListar.path)
          }
        }}
        onSubmit={async function ({ data }) {
          const record = {
            availableAtRegions,
            availableAtChannels: availableAtChannels.filter(
              (item) => item !== 'Selecionar Todos'
            ),
            availableAtRoleGroups,
            kind: EnumMaterialKind.OfferMap,
            categories: ['menu:mapa_de_ofertas'],
            validity: {
              start: data.validity[0],
              end: data.validity[1],
              notifyEmailsBeforeExpire: data.notifyEmailsBeforeExpire
            },
            fileIds: [data.documento, data.cardimage].filter(Boolean),
            title: data.title,
            tags: data.tags
          };
          if (isUpdate && data._id) {
            await MaterialUpdateOne.fetch({
              variables: { record, filter: { _id: data._id } }
            }).then((ctx) => {
              if (!ctx.errors) {
                history.push(routes.mapaDeOfertaListar.mount());
              }
            });
          } else {
            await createMaterial
              .fetch({
                variables: { record }
              })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success('Mapa de oferta cadastrado com sucesso');
                  history.push(routes.mapaDeOfertaListar.path);
                } else {
                  message.error('Mapa de oferta não cadastrado');
                }
              });
          }
        }}
      />
    </Container>
  );
};

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
};
