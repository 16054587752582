import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';
import {
  Breadcrumb,
  CardInternoFragment,
  ListGrid,
  TitleDescription,
  parseCardsData,
  MaybeMaterialList,
  LoadingIndicator,
  MaterialProvider,
  ShareModal,
  PinFolderModal,
  CardConsumer
} from '@digi-tim-19/components';
import { colors } from '@digi-tim-19/theme';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import {
  acessarMaterial,
  obterImagemPadraoPorMaterial,
  actionsCardConsumer
} from '../../utils/material';
import { useClient } from '../../autogenerated/client/client';

import {
  EnumFolderKind,
  SortFindManyRecentsActivitiesInput,
  ValidityEnum,
  EnumMaterialKind
} from '../../autogenerated/client/types';

import { useMaterialActions } from '@digi-tim-19/components/build/main/DataProviders/MaterialActionsProvider';
import { useHistory } from 'react-router';
import { routes } from '../../config/routes';
import { EmptyData } from '../../components/utils/Void';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const StyledContent = styled.section`
  background: transparent;
  display: flex;
  flex-wrap: wrap;
`;
const OrderWrapper = styled.div`
  margin-bottom: 40px;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

const ChooseVisualWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  i {
    padding-right: 20px;
  }
`;

const CardsVisual = styled.div`
  width: 100%;

  & > div {
    align-items: flex-end;
  }
`;

enum ListMode {
  card,
  list
}

export const AtividadesRecentes = () => {
  const [visualMode, setVisualMode] = useState<ListMode>(ListMode.card);

  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb
          items={[
            { title: 'Home', link: 'home' },
            { title: 'Atividades Recentes', link: 'atividades-recentes' }
          ]}
        />

        <TitleDescription
          iconType={'clock-circle'}
          title={'Atividades Recentes'}
          description={
            'Encontre aqui as suas últimas atividades realizadas dentro da plataforma.'
          }
        />
        <StyledContent>
          <OrderWrapper>
            <ChooseVisualWrapper>
              <Icon
                onClick={() => setVisualMode(ListMode.card)}
                type="appstore"
                style={{
                  fontSize: '35px',
                  color:
                    visualMode === ListMode.card ? colors.blue : colors.gray
                }}
              />
              <Icon
                onClick={() => setVisualMode(ListMode.list)}
                type="unordered-list"
                style={{
                  fontSize: '35px',
                  color:
                    visualMode === ListMode.list ? colors.blue : colors.gray
                }}
              />
            </ChooseVisualWrapper>
          </OrderWrapper>

          <CardsVisual>
            <InnerPageData mode={visualMode} />
          </CardsVisual>
        </StyledContent>
      </React.Fragment>
    </PageTemplate>
  );
};

const InnerPageData = (props: { mode: ListMode }) => {
  const history = useHistory();
  const actions = useMaterialActions();
  const [recentsMaterials, setRecentsMaterials] = useState<any[]>([]);
  const [materials, setMaterials] = useState<any>();

  const getMaterials = useClient('MaterialFindMany', {
    appendToFragment: CardInternoFragment
  });

  const recentsActivities = useClient('RecentsActivitiesPagination');

  useEffect(() => {
    recentsActivities
      .fetch({
        appendToFragment: `
      items {
        parentId
      }`,
        variables: {
          page: 1,
          perPage: 10,
          sort: SortFindManyRecentsActivitiesInput.UpdatedatDesc
        }
      })
      .then((ctx) => {
        const ids = ctx.result?.items?.map((item) => item?.parentId);
        setRecentsMaterials(ids || []);
      });
  }, []);

  const { listFolders, addFolder } = useFoldersPin();

  React.useEffect(() => {
    if (recentsMaterials.length > 0) {
      getMaterials
        .fetch({
          variables: {
            filter: {
              _ids: recentsMaterials,
              validityEnum: ValidityEnum.Any
            },
            limit: 10
          }
        })
        .then((ctx) => {
          let resultMaterials: any = [];

          if (ctx.result) {
            recentsMaterials.forEach((item) => {
              const material = ctx.result?.find((el) => el?._id === item);
              resultMaterials.push(material);
            });
          }

          setMaterials({
            result: resultMaterials.filter(Boolean)
          });
        });
    }
  }, [recentsMaterials]);

  const data = React.useMemo(() => {
    if (materials?.result) {
      const dataParsed = parseCardsData(materials.result as MaybeMaterialList);
      return dataParsed.map((item) => {
        return {
          ...item,
          cardData: {
            ...item.cardData,
            tituloHeader:
              item.material?.categories[0]?.name ||
              item.cardData?.tituloHeader ||
              '',
            hideImgBody: true,
            prefixDate: 'CADASTRADO EM:'
          }
        };
      });
    }

    return [];
  }, [materials]);

  if (!getMaterials.result || getMaterials.loading) {
    return <LoadingIndicator />;
  }

  if (getMaterials.result.length === 0) {
    return <EmptyData />;
  }
  if (props.mode === ListMode.card) {
    return (
      <CardsWrapper>
        {materials?.result?.map((item: any) => {
          let thumb = obterImagemPadraoPorMaterial(item);

          if (item?.kind === EnumMaterialKind.Tanamao)
            thumb = '/defaultImages/thumb-ta-na-mao.jpeg';

          return (
            <CardConsumer
              material={item}
              defaultImage={thumb}
              actions={(material) =>
                actionsCardConsumer(
                  material,
                  routes,
                  history,
                  listFolders,
                  addFolder
                )
              }
            />
          );
        })}
      </CardsWrapper>
    );
  } else {
    {
      /* TODO ACTIONS PARA LIST GRID */
    }
    const items = data.map((el: any) => {
      const material = el.material || {};

      return {
        ...el.cardData,
        renderPin: () => (
          <MaterialProvider material={material as any}>
            <PinFolderModal
              listFolders={listFolders}
              actionAddFolder={addFolder}
              pinned={material.pinned}
            />
          </MaterialProvider>
        ),
        renderShare: () => (
          <MaterialProvider material={material as any}>
            <ShareModal />
          </MaterialProvider>
        ),
        actions: {
          pin: true,
          share: true,
          access: true,
          onAccess: () => {
            acessarMaterial(el.material, history, routes);
          },
          onPin: () => {
            actions.pin({ recordId: el.material._id });
          }
        }
      };
    });

    return <ListGrid items={items} />;
  }
};
