import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { TabelaPrecoWrapper } from './stylesCadastrar';
import { routes } from '../../../config/routes';

export const TabelaPrecoCadastrar = () => {
  return (
    <PageTemplate>
      <>
        <TabelaPrecoWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'TIM Tech' },
              { title: 'Tabela de Preços' },
              { title: 'Cadastrar' }
            ]}
          />
          <TitleDescription
            iconType="mobile"
            title="Tabela de Preços"
            description="Cadastramento e consulta de tabela de preço"
          />
        </TabelaPrecoWrapper>

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
