import React from 'react';

import {
  Breadcrumb,
  TitleDescription,
  Table,
  LoadingIndicator,
  Select
} from '@digi-tim-19/components';

import { columns } from './columns';
import { PageTemplate } from '../../../components/Layout/PageTemplate';

import {
  NotificationsWrapper,
  FilterContainer,
  CleanButton,
  Search,
  InputFilter
} from './stylesListar';

import { routes } from '../../../config/routes';
import { useUsersPagination } from './useUsersPagination';
import { useChannelFindMany } from '../../../hooks/channels/channelFindMany';
import { useRegionFindMany } from '../../../hooks/regions/useRegionsFindMany';

export const PermissionsListUsers: React.FC = () => {
  const users = useUsersPagination();
  const ChannelFindMany = useChannelFindMany();
  const RegionFindMany = useRegionFindMany({ filter: {} }); //filtro em branco para retornar também as regions virtuais

  const channels = toSelect(ChannelFindMany.result || [], 'Canal');
  const regions = toSelect(
    RegionFindMany.result?.filter((x) => x?._id !== 'HQ') || [],
    'Regional'
  );

  const isBooleanOptions = toSelect(
    [
      {
        _id: 'true',
        name: 'Sim'
      },
      {
        _id: 'false',
        name: 'Não'
      }
    ],
    'Opcão'
  );

  let totalPagination = users.count;
  const maxNumPage = users.count / users.perPage;

  if (users.result?.pageInfo.hasNextPage && users.currentPage >= maxNumPage) {
    totalPagination = (users.currentPage + 1) * users.perPage;
  }

  return (
    <PageTemplate>
      <>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Gerenciar Permissões' }
            ]}
          />
          <TitleDescription
            iconType="user"
            title="Gerenciar permissões"
            description="Listar usuários"
          />
        </NotificationsWrapper>
        <FilterContainer>
          {/**
             4.5.1- Filtro por canal e regional;
             4.5.2- Busca por usuário;
             4.5.3- Busca por matrícula;
             4.5.4- Botão limpar filtros;
             */}
          <Select
            placeholder="Canal"
            options={channels}
            value={users.channel || undefined}
            onChange={(e) => {
              users.setChannel(e);
            }}
          />

          <Select
            placeholder="Regional"
            options={regions}
            value={users.region || undefined}
            onChange={(e) => {
              users.setRegion(e);
            }}
          />

          <Search
            placeholder="Nome"
            style={{ width: 200 }}
            value={users.searchInput || undefined}
            onChange={(e) => {
              users.onSearch(e.target.value);
            }}
          />

          <InputFilter
            placeholder="Matricula"
            value={users.matricula || undefined}
            onChange={(e) => {
              users.onChangeMatricula(e.target.value);
            }}
          />

          <Select
            placeholder="Bloqueado de comentar"
            options={isBooleanOptions}
            value={users.isBlocked || undefined}
            onChange={(e) => {
              users.setIsblocked(e);
            }}
          />

          <Select
            placeholder="Inativos"
            options={isBooleanOptions}
            value={users.isDisabled || undefined}
            onChange={(e) => {
              users.setIsDisabled(e);
            }}
          />

          <CleanButton onClick={() => users.resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>

        {users.loading && <LoadingIndicator />}
        {!users.loading && (
          <Table
            columns={columns}
            dataSource={users.data}
            pagination={{
              total: totalPagination,
              current: users.currentPage,
              pageSize: users.perPage,
              onChange: (page: number) => {
                users.goTo(page);
              }
            }}
          />
        )}
      </>
    </PageTemplate>
  );
};

const toSelect = (items: any[] = [], field: string) => {
  return [{ label: `-- Qualquer ${field} --`, value: '' }].concat(
    items.map((item) => ({
      label: item?.name || [],
      value: item?._id || []
    })) || []
  );
};
