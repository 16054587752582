import React from 'react';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  TitleDescription,
  useCurrentUser,
  LoadingIndicator
} from '@digi-tim-19/components';
import { routes } from '../../config/routes';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { MultLineChart } from './Components/MultLineChart';
import { Speedometer } from './Components/Speedometer';
import { useMetas } from './useMetas';

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 15px;
`;

export const ListarMetas = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const currentUser = useCurrentUser();

  const configPage = {
    pageName: 'Campanhas de Incentivo',
    icon: 'trophy',
    description: '',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      {
        title: 'Campanhas de Incentivo',
        link: routes.campanhasIncentivoListar.mount()
      },
      {
        title: 'Campanha Vigente',
        link: routes.campanhasIncentivoVigente.mount(params.id)
      },
      { title: 'Detalhamento de resultados' }
    ],
    routeList: routes.campanhasIncentivoListar.mount()
  };

  const { goalsUserSpeeder, datesChart, showSpeeder, series, maxValue } =
    useMetas(params.id, currentUser.result?._id);

  return (
    <PageTemplate>
      <>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />
        <Grid>
          {showSpeeder && <Speedometer goals={goalsUserSpeeder || []} />}

          {/* {!showSpeeder && <p>Você não está participando dessa campanha</p>} */}
        </Grid>
        {series.length > 0 && (
          <MultLineChart
            series={series}
            xaxis={{
              categories: ['Início', ...datesChart],
              title: { text: 'Data de Upload' }
            }}
            maxValue={maxValue}
          />
        )}
      </>
    </PageTemplate>
  );
};
