import React from 'react';
import { TableActions } from '@digi-tim-19/components';
import { colors } from '@digi-tim-19/theme';
import { changedByColumn } from '../../../../components/utils/changedByColumn';

export const columns = [
  {
    title: 'Título',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Posição',
    dataIndex: 'position',
    key: 'position',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Cadastrado',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: 'Atualizada',
    dataIndex: 'atualizada',
    key: 'atualizada'
  },
  {
    title: 'Criador',
    dataIndex: 'author',
    key: 'author'
  },
  {
    title: 'Vigência',
    dataIndex: 'validity',
    key: 'validity'
  },
  {
    title: 'Ordem',
    dataIndex: 'sort',
    key: 'sort'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text: string) => (
      <p style={{ color: text === 'Ativo' ? colors.green : colors.red }}>
        {text}
      </p>
    )
  },
  changedByColumn(),
  {
    title: '',
    key: 'action',
    render: (text: DataKey, record: any) => <TableActions data={record} />
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
  status: string;
}
