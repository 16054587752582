import { usePagination } from '../../../hooks/usePagination';

interface Props {
  userId: string;
  status?: 'pending' | 'completed' | 'expired';
  fragment?: string;
}

const itemsFragmentDefault = `
_id
userId
userChallengeData
challengeId
progress
status
challengeData {
  action
  challengeNumericId
  title
  amount
  description
  amountPoints
  amountChips
  thumbnail
  category
  typePayment
  typeChallenge
  kind
  externalLink
  expires
  endDate
  startDate
  challengeData
  thumbnailData {
    url
    signedUrl
  }
  redirect
  url
  faq
}
updatedAt
createdAt
`;

export const useUserChallenges = (props: Props) => {
  const { loading, parsed, refetch } = usePagination('UserChallengePagination', {
    initial: {
      page: 1,
      perPage: 1000,
      filter: {
        userId: props.userId,
        ...(props?.status ? { status_filter: props?.status } : {}),
      },
    },
    itemsFragment: props.fragment || itemsFragmentDefault,
    parseResult: (data) =>
      data.map((el) => {
        return {
          _id: el._id,
          challengeId: el.challengeId,
          challengeNumericId: el.challengeData?.challengeNumericId,
          userId: el.userId,
          description: el.challengeData?.description,
          amount: el.challengeData?.amount,
          amountPoints: el.challengeData?.amountPoints,
          amountChips: el.challengeData?.amountChips,
          title: el.challengeData?.title,
          thumbnail: el.challengeData?.thumbnailData?.signedUrl ?? el.challengeData?.thumbnailData?.url,
          progress: {
            amount: el.progress,
            total: el.challengeData?.amount || 1,
          },
          action: el.challengeData?.action,
          category: el.challengeData?.category,
          kind: el.challengeData?.kind,
          typePayment: el.challengeData?.typePayment,
          typeChallenge: el?.challengeData?.typeChallenge,
          challengeData: el.challengeData?.challengeData,
          userChallengeData: el.userChallengeData,
          status: props.status ? props.status : el.status,
          externalLink: el?.challengeData?.externalLink,
          updatedAt: el.updatedAt,
          expires: Boolean(el?.challengeData?.expires),
          endDate: el.challengeData?.endDate,
          startDate: el.challengeData?.startDate,
          redirect: el?.challengeData?.redirect,
          url: el?.challengeData?.url,
          faq: el?.challengeData?.faq,
        };
      }),
  });

  return { loading, parsed, refetch };
};
