import React, { useEffect, useState, useCallback } from 'react';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select,
  LoadingIndicator
} from '@digi-tim-19/components';
import { DatePicker, message } from 'antd';
import { status, order, dateFormatList } from '../../../utils';
import { useHistory } from 'react-router';
import { columns } from './columns';
import { formatDate } from '@digi-tim-19/utils/build';
import { useClient } from '../../../autogenerated/client/client';
import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyMaterialInput,
  CommunicationKindFilter
} from '../../../autogenerated/client/types';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  NotificationsWrapper,
  FilterContainer,
  Button,
  Search,
  CleanButton
} from './styleListar';
import moment from 'moment';
import { routes } from '../../../config/routes';
import { useFilterAuthor } from '../../../components/FilterAuthor/useFilterAuthor';
import { escapeStringRegexp } from '../../../utils/formatters';

const { RangePicker } = DatePicker;

export const ListarNotificacoes: React.FC<TListarNotificacoes> = () => {
  const filterAuthor = useFilterAuthor();
  const [createdAt, setCreatedAt] = useState();
  const [publishedAt, setPublishedAt] = useState();
  const [sort, setSort] = useState(SortFindManyMaterialInput.IdDesc);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterStatus, setFilterStatus] = useState();
  const history = useHistory();

  const [items, setItems] = useState<any>([]);
  const [updateTable, setUpdateTable] = useState('');
  const [page, setPage] = useState(1);
  const perPage = 10;

  const getMaterials = useClient('MaterialPagination');
  const MaterialDeleteOne = useClient('MaterialRemoveOne');

  const total = getMaterials.result?.pageInfo.itemCount;

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          perPage: perPage,
          page: page,
          filter: {
            kind: EnumMaterialKind.Communication,
            validityEnum: ValidityEnum.Any,
            communicationKind: CommunicationKindFilter.Notification,
            status: filterStatus,
            createdAtRange: createdAt,
            publishedAtRange: publishedAt,
            includeDrafts: true,
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
            filterAuthor: filterAuthor.filter,
            owner: filterAuthor.ownerFilter
          },
          sort: sort
        }
      })
      .then((response: any) => {
        setItems(response && response.result ? response.result.items : []);
      });
  }, [
    sort,
    filterStatus,
    createdAt,
    publishedAt,
    page,
    updateTable,
    filterSearch,
    filterAuthor.signature
  ]);

  const resetFilters = () => {
    setSort(SortFindManyMaterialInput.IdDesc);
    setFilterStatus(undefined);
    setFilterSearch('');
    setPage(1);
    filterAuthor.reset();
  };

  const data = items?.map((material: any) => {
    const category = material?.categories?.[0];
    const categoryName = category?.name || '';

    return {
      changedBy: material?.changedBy,
      key: material?._id,
      id: material?.numericId,
      title: material?.title,
      cadastrada: formatDate(material?.createdAt, 'DD/MM/YYYY'),
      user: material?.author?.name,
      categoryName: categoryName,
      criador: material?.author?.name,
      publicada: material.validity?.start
        ? formatDate(material.validity?.start, 'DD/MM/YYYY')
        : '-',
      status: material?.status?.label,
      onEdit: () =>
        history.push(routes.notificacoesEditar.mount(material?._id)),
      onRead: () => history.push(routes.notificacoes.mount()),
      onDelete: () =>
        MaterialDeleteOne.fetch({
          variables: { filter: { _id: material?._id } },
          afterMutate: /^Material/
        }).then((ctx) => {
          message.success('Notificação deletada com sucesso');
          setUpdateTable(ctx.result?.recordId);
        })
    };
  });

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
    },
    [data]
  );

  const getCategories = useClient('CategoryFindMany', {
    fetchOnMount: true,
    variables: { limit: 123, filter: { parentId: 'menu' } }
  });
  const category = getCategories?.result?.map((item) => ({
    label: item?.name?.toUpperCase(),
    value: item?._id
  }));
  return (
    <PageTemplate>
      <>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Notificação' }
            ]}
          />
          <TitleDescription
            iconType="bell"
            title="Notificação"
            description="Cadastramento e consulta de notificações"
          />
        </NotificationsWrapper>
        <FilterContainer>
          <Button to={routes.notificacoesCadastrar.path}>
            CADASTRAR NOTIFICAÇÃO
          </Button>
          <Select
            placeholder="Ordenar por:"
            options={order}
            onChange={(e) => {
              setPage(1);
              setSort(e);
            }}
            value={sort}
          />
          {/* <Select
            placeholder="status"
            options={status}
            value={filterStatus}
            onChange={e => setFilterStatus(e)}
          /> */}
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={dateFormatList}
            placeholder={['PERÍODO', '']}
            onChange={(e) =>
              setCreatedAt({
                start: moment(e[0]!).startOf('day')?.toISOString(),
                end: moment(e[1]!).endOf('day')?.toISOString()
              })
            }
          />
          <Search
            placeholder="Busca título"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e) => {
              onSearch(e.target.value);
              setPage(1);
            }}
          />

          {filterAuthor.render()}

          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>

        {!data ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e) => setPage(e)
            }}
          />
        )}
      </>
    </PageTemplate>
  );
};

export type TListarNotificacoes = {};

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    changedBy {
      name
      dateText
    },
    _id
    title
    numericId
    createdAt
    fileIds
    files {
      _id
      extension
      kind
      signedUrl
      downloadUrl
    }
    categories {
      _id
      name
    }
    validity { 
      start 
      end 
    } 
    author { 
      _id, 
      name 
    }
  }
`;
