export const getReconheceUrl = (token?: string) => {
  const urlReconhece = process.env.REACT_APP_URL_RECONHECE;
  return `${urlReconhece}${token}`;
};

export const getAzureData = () => {
  const tenant = process.env.AZURE_TENTANT ?? '57b8c96e-ac2f-4d78-a149-f1fc6817d3c4';
  const client_id = process.env.AZURE_CLIENT_AD ?? '0ecbd75f-5919-44e3-83c5-dbdef81e8466';
  const redirect_uri = process.env.AZURE_REDIRECT_URI ?? 'https://tanarede.timbrasil.com.br/';

  return {
    tenant,
    client_id,
    redirect_uri,
  };
};
