import { Progress as AntdProgress } from 'antd';
import styled from 'styled-components';
import React, { useMemo } from 'react';
import { LoadingIndicator, useCurrentUser } from '@digi-tim-19/components';

export interface Niveis {
  title?: string;
  nivel?: string;
  amountChips?: string;
}

export interface GameficationUserNivelProps {
  niveis?: Niveis[];
  onClickChallenge?: () => void;
}

const Container = styled.div`
  width: 100%;
  height: 70px;
  position: relative;
  margin-bottom: 10px;

  button {
    font-size: 9px;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;

  i {
    font-size: 20px !important;
  }
`;

const LevelContainer = styled.div`
  width: 100%;
  display: flex;
`;

const LevelImgContainer = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
`;

const ProgressContainer = styled.div<{ disablePadding?: boolean }>`
  flex: 1;
  padding: ${(props) => (props?.disablePadding ? '0 10px' : '0 10px 0px 50px')};
  margin-top: -3px;
`;

const LevelDescription = styled.div<{ disableMargin?: boolean }>`
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  padding: 2px 16px;
  color: white;
  background-color: #001ba3;
  width: max-content;
  margin-left: ${(props) => (props?.disableMargin ? '0px' : '10px')};
`;

const Progress = styled(AntdProgress)`
  .ant-progress-inner {
    border: 1px solid #00aaff;
    background-color: white;
    height: 16px;
  }

  .ant-progress-bg {
    background-color: #002bff;
    height: 14px !important;
  }
`;

const ChipsContainer = styled.div`
  width: 100%;
  display: flex;
`;

const Chips = styled.span`
  color: #0015af;
  flex: 1;
  padding-left: 10px;
  font-size: 10pt;
  margin-top: 2px;

  > img {
    height: 20px;
    margin-right: 3px;
    margin-top: -2px;
  }
`;

const ChallengeButtonImg = styled.img`
  height: 45px;
  margin-top: -13px;
  margin-right: -10px;
  cursor: pointer;
`;

export const Gamefication = ({ niveis = [], onClickChallenge = () => {} }: GameficationUserNivelProps) => {
  const user = useCurrentUser();

  const { title, nivelChips, percent, imageSrc, imageAlt, userChips } = useMemo(() => {
    const userChips = user.result?.gamefication?.chips ?? 0;
    const userNivel = user.result?.gamefication?.nivel ?? 1;

    const imgsMap: Record<number, string> = {
      1: '/defaultImages/gamefication/start-level.png',
      2: '/defaultImages/gamefication/mid-level.png',
      3: '/defaultImages/gamefication/final-level.png',
    };

    const altImgMap: Record<number, string> = {
      1: 'Nível inicial',
      2: 'Nível médio',
      3: 'Nível final',
    };

    const userlevel = niveis.find((el) => `${el.nivel}` === `${userNivel}`);
    const nextLevel = niveis.find((el) => `${el.nivel}` === `${userNivel + 1}`);
    const nivelChips = parseInt(nextLevel?.amountChips ?? `${userChips}`);

    return {
      userChips,
      title: userlevel?.title ?? '3G',
      percent: (userChips / nivelChips) * 100,
      imageSrc: imgsMap[userNivel],
      imageAlt: altImgMap[userNivel],
      nivelChips,
    };
  }, [niveis, user.result?.gamefication]);

  return (
    <Container>
      {user.loading ? (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      ) : (
        <LevelContainer>
          <LevelImgContainer>
            <img src={imageSrc} alt={imageAlt} />
          </LevelImgContainer>
          <ProgressContainer disablePadding={false}>
            <LevelDescription disableMargin={false}>{title}</LevelDescription>
            <Progress percent={percent} showInfo={false} />
            <ChipsContainer>
              <Chips>
                <img src="/defaultImages/gamefication/chip.png" alt="Chips" />
                {`${userChips}/${nivelChips}`}
              </Chips>
              <ChallengeButtonImg
                src="/defaultImages/gamefication/challenge-button.png"
                alt="Botão de desafios"
                onClick={() => onClickChallenge()}
              />
            </ChipsContainer>
          </ProgressContainer>
        </LevelContainer>
      )}
    </Container>
  );
};
