import React from 'react';
import { TableActions } from '@digi-tim-19/components';

import { LiveColumnContainer } from './LiveColumnContainer';
import { changedByColumn } from '../../../components/utils/changedByColumn';

export const columns = [
  {
    title: 'Live',
    dataIndex: 'title',
    key: 'title',
    render: (text: string, record: any) => <LiveColumnContainer data={record} />
  },
  {
    title: 'Data da Live',
    dataIndex: 'dataEvento',
    key: 'dataEvento',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Cadastrada',
    dataIndex: 'cadastrada',
    key: 'cadastrada'
  },
  {
    title: 'Atualizada',
    dataIndex: 'atualizada',
    key: 'atualizada'
  },
  {
    title: 'Criador',
    dataIndex: 'author',
    key: 'author'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  changedByColumn(),
  {
    title: 'Ações',
    key: 'action',
    render: (text: DataKey, record: any) => (
      <TableActions
        data={record}
        confirmationMessage={'Deseja excluir essa live?'}
      />
    )
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
}
