import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { LiveWrapper } from '../Cadastrar/stylesCadastrar';
import { useRouteMatch } from 'react-router';
import { useClient } from '../../../autogenerated/client/client';
import { routes } from '../../../config/routes';

export const LiveEditar = () => {
  const { params } = useRouteMatch();
  const appendToFragment = `
    title
    fileIds
    files {
      _id
      title
      kind
      signedUrl
    }
    eventDate
    categories {
      name
      parentId
      _id
    }
    availableAtRegions
    availableAtChannels
    availableAtRoleGroups
  `;
  const variables = { filter: { _id: params?.id } };

  const MaterialFindOne = useClient('EventLiveFindOne', {
    fetchOnMount: true,
    variables,
    appendToFragment
  });

  return (
    <PageTemplate>
      <>
        <LiveWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Live' },
              { title: 'Editar' }
            ]}
          />
          <TitleDescription
            iconType="environment"
            title="Live"
            description="Editar Live"
          />
        </LiveWrapper>

        {MaterialFindOne.result && (
          <ProviderSelecionarPublico>
            <Form initialValues={MaterialFindOne.result} />
          </ProviderSelecionarPublico>
        )}
      </>
    </PageTemplate>
  );
};
