import React, { useState } from 'react';
import { Divider, Modal } from 'antd';
import { Button } from '@digi-tim-19/components';
import { IncentiveCampaign } from '../../autogenerated/client/types';
import moment from 'moment';
import 'moment/locale/pt-br';
import { CampanhaTitleWrapper, CampanhaVigenteImage } from './stylesCampanhaVigente';
import { ButtonPeriod, ButtonRegulation, ButtonsCampaignContainer2 } from './stylesCampanhaRanking';
import { useClient } from '../../autogenerated/client/client';

moment.locale('pt-BR');

interface Props {
  campaign: IncentiveCampaign;
}

export const CampanhaVigenteExterna = ({ campaign }: Props) => {
  const [viewModalRegulation, setViewModalRegulation] = useState(false);
  const [showModalRegulation, setShowModalRegulation] = useState(false);
  const acceptExternalCampaign = useClient('AcceptExternalCampaign');

  return (
    <>
      <CampanhaTitleWrapper>{campaign.title}</CampanhaTitleWrapper>

      <Divider />

      <div
        style={{
          display: 'flex',
          gap: '20px',
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2 style={{ textAlign: 'center' }}>
          Fique por dentro! Clique no botão abaixo para acessar a campanha e acompanhar o seu desempenho:
        </h2>
        <Button
          style={{ fontSize: '25px', height: '40px' }}
          onClick={() => {
            // if (campaign.externalData?.pendingAccept) {
            //   setShowModalRegulation(true);
            // } else {
            // Se o usuário abriu essa pag podemos assumir que ele aceitou os termos
            window.open(campaign.externalData?.redirectURL, '_blank');
            // }
          }}
        >
          Acessar campanha
        </Button>
        {campaign.bannerContentFile?.signedUrl && (
          // <CampanhaVigenteImage>
          <img
            src={campaign?.bannerContentFile?.signedUrl || ''}
            alt="thumb"
            height={
              campaign?.bannerContentFile?.imageInfo?.height
                ? Number(campaign?.bannerContentFile?.imageInfo?.height) / 1.5
                : undefined
            }
            width={
              campaign?.bannerContentFile?.imageInfo?.width
                ? Number(campaign?.bannerContentFile?.imageInfo?.width) / 1.5
                : undefined
            }
          />
          // </CampanhaVigenteImage>
        )}

        <ButtonsCampaignContainer2>
          <ButtonRegulation onClick={() => setViewModalRegulation(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
              style={{ width: '35px' }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
              />
            </svg>

            <span>REGULAMENTO</span>
          </ButtonRegulation>
          <ButtonPeriod>
            <div>
              <span>PERÍODO:</span>
            </div>
            <div>
              <span>{`DE: ${moment(campaign.validity?.start).format('DD/MM/YYYY')}`}</span>
              <span>{`ATÉ: ${moment(campaign.validity?.end).format('DD/MM/YYYY')}`}</span>
            </div>
          </ButtonPeriod>
        </ButtonsCampaignContainer2>
      </div>

      <Modal
        title="Regulamento"
        visible={showModalRegulation}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setShowModalRegulation(false)}
      >
        {campaign.externalData?.hasCNPJ ? (
          <>
            <div style={{ height: '400px', overflow: 'hidden' }}>
              <iframe
                style={{ width: '100%', height: '100%' }}
                id={campaign.externalData?.regulationFile?.signedUrl ?? ''}
                src={campaign.externalData?.regulationFile?.signedUrl ?? ''}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '12px' }}>
              <Button
                onClick={() => {
                  let a = document.createElement('a');
                  a.href = campaign.externalData?.regulationFile?.signedUrl ?? '';
                  a.download = campaign.externalData?.regulationFile?.signedUrl ?? '';
                  a.click();
                }}
              >
                Download
              </Button>
              <Button onClick={() => setShowModalRegulation(false)}>Cancelar</Button>
              <Button
                onClick={() => {
                  acceptExternalCampaign
                    .fetch({
                      variables: {
                        externalTerm: campaign.externalData?._id,
                      },
                    })
                    .then(() => {
                      setShowModalRegulation(false);
                      window.open(campaign.externalData?.redirectURL, '_blank');
                    });
                }}
              >
                Concordo com os termos
              </Button>
            </div>
          </>
        ) : (
          <>
            <div style={{ height: '400px', overflow: 'hidden' }}>
              <p>No momento os dados necessários para participar da campanha ainda não estão atualizados no portal.</p>
              <p>Este problema deve se resolver em até 24h, caso contrário entre em contato com o SAC.</p>
            </div>
          </>
        )}
      </Modal>

      <Modal
        title="Regulamento"
        visible={viewModalRegulation}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setViewModalRegulation(false)}
      >
        <>
          <div style={{ height: '400px', overflow: 'hidden' }}>
            <iframe
              style={{ width: '100%', height: '100%' }}
              id={campaign.externalData?.regulationFile?.signedUrl ?? ''}
              src={campaign.externalData?.regulationFile?.signedUrl ?? ''}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '12px' }}>
            <Button
              onClick={() => {
                let a = document.createElement('a');
                a.href = campaign.externalData?.regulationFile?.signedUrl ?? '';
                a.download = campaign.externalData?.regulationFile?.signedUrl ?? '';
                a.click();
              }}
            >
              Download
            </Button>
            <Button onClick={() => setViewModalRegulation(false)}>Ok</Button>
          </div>
        </>
      </Modal>
    </>
  );
};
