import React from 'react';
import { Badge } from 'antd';

export const columns = [
  {
    title: 'Nome do participante',
    dataIndex: 'user.name',
    key: 'name',
    render: (text: string) => {
      return <p>{text}</p>;
    },
  },
  {
    title: 'Matricula',
    dataIndex: 'user.matricula',
    key: 'matricula',
    render: (text: string) => {
      return <p>{text}</p>;
    },
  },
  {
    title: 'CPF',
    dataIndex: 'user.CPF',
    key: 'cpf',
    render: (text: string) => {
      return <p>{text}</p>;
    },
  },
  {
    title: 'Canal',
    dataIndex: 'user.channels[0]',
    key: 'channel',
    render: (text: string) => {
      return <p>{text?.replace('_', ' ').toUpperCase()}</p>;
    },
  },
  {
    title: 'Regional',
    dataIndex: 'user.region',
    key: 'region',
    render: (text: string, context: any) => {
      return <p>{text}</p>;
    },
  },
  {
    title: 'TIM Coins',
    dataIndex: 'points',
    key: 'points',
    render: (text: string) => {
      return <Badge status="processing" text={text} />;
    },
  },
];

export interface DataKey {
  key: string;
}
