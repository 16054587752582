import { createXLSX, download, sheetParser } from '@digi-tim-19/utils';

export const downloadXLSX = (fileName: string, schema: any, data: any[]) => {
  const { rows } = sheetParser<any>().attachSchema(schema ?? {}).toSheetData(data ?? []);

  const file = createXLSX([{ name: 'modelo', json: rows }], {}).write('base64');

  download(
    `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${file}`,
    fileName
  );
};
