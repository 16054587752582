import { useClient } from '../../autogenerated/client/client';
import {
  QueryRegionFindManyArgs,
  SortFindManyRegionInput
} from '@digi-tim-19/components/build/main/DataProviders/types';

export function useRegionFindMany(
  customVariables?: Partial<QueryRegionFindManyArgs>
) {
  const RegionFindMany = useClient('RegionFindMany', {
    fragment: `
      _id
      createdAt
      isVirtualRegion
      itemIds
      name
      updatedAt
      items {
        _id
        name
        abbreviation
        level
        UF
        DDD
        region
        items {
          _id
          name
          abbreviation
          level
          UF
          DDD
          region
        }
      }
    `,
    variables: {
      filter: { isVirtualRegion: null },
      limit: 100,
      sort: SortFindManyRegionInput.NameAsc,
      ...customVariables
    },
    fetchOnMount: true,
    cache: true
  });

  return { ...RegionFindMany };
}

export function useUfData(region: string) {
  const variables = { filter: { region, level: 1 }, limit: 100 };

  const ufData = useClient('RegionItemFindMany', {
    variables,
    fetchOnMount: true
  });

  return { ...ufData };
}
