import React from 'react';
import styled from 'styled-components';
import { Icon, Progress as AntdProgress } from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-BR');
interface IBateuLevou {
  firstElement?: boolean;
  lastElement?: boolean;
  description: string;
  percent: number;
  result: number;
  goal: number;
  tend: number;
  points: number | string;
  userBooster: number;
  lastUpdate: string;
}

export const BateuLevouElegibility = (props: IBateuLevou) => {
  const {
    firstElement,
    lastElement,
    description,
    percent,
    result,
    goal,
    tend,
    points,
    userBooster,
    lastUpdate,
  } = props;

  return (
    <CardContainer first={firstElement} last={lastElement}>
      <InfoContainer first={firstElement} last={lastElement}>
        {firstElement && <Title>CRITÉRIO DE ELEGIBILIDADE</Title>}
        <Description>{description}</Description>
        <Content>
          <IconContainer>
            <Icon type="user" style={{ fontSize: '24px' }} />
          </IconContainer>
          <ProgressContainer>
            <Progress percent={percent} showInfo={false} />
            <PercentInfo>{`${result}/${goal}`}</PercentInfo>
          </ProgressContainer>
          <EmojiContainer>
            <Icon type="smile" />
          </EmojiContainer>
        </Content>
        {tend ? <TrendContainer>{`TENDÊNCIA: ${tend}`}</TrendContainer> : null}
        {lastElement && (
          <ResultInfoContainer>
            <ResultInfo>Resultado Parcial</ResultInfo>
            <ResultDate>{`Última atualização: ${moment(lastUpdate).format('DD/MM/YYYY')}`}</ResultDate>
          </ResultInfoContainer>
        )}
      </InfoContainer>
      <ExtraInfoContainer first={firstElement} last={lastElement}>
        <ExtraInfoBox first={firstElement}>
          <ExtraInfoTitle>TIM COINS POR ATINGIMENTO</ExtraInfoTitle>
          <ExtraInfoPoints>{points}</ExtraInfoPoints>
        </ExtraInfoBox>
      </ExtraInfoContainer>
    </CardContainer>
  );
};

export const BateuLevouResults = (props: IBateuLevou) => {
  const {
    firstElement,
    lastElement,
    description,
    percent,
    result,
    goal,
    tend,
    points,
    userBooster,
    lastUpdate,
  } = props;

  return (
    <CardContainer first={firstElement} last={lastElement}>
      <InfoContainer first={firstElement} last={lastElement}>
        {firstElement && <Title>MEU PROGRESSO</Title>}
        <Description>{description}</Description>
        <Content>
          <IconContainer>
            <Icon type="user" style={{ fontSize: '24px' }} />
          </IconContainer>
          <ProgressContainer>
            <Progress percent={percent} showInfo={false} />
            <PercentInfo>{`${result}/${goal}`}</PercentInfo>
          </ProgressContainer>
          <EmojiContainer>
            <Icon type="smile" />
          </EmojiContainer>
        </Content>
        {tend ? <TrendContainer>{`TENDÊNCIA: ${tend}`}</TrendContainer> : null}
        {lastElement && (
          <ResultInfoContainer>
            <ResultInfo>Resultado Parcial</ResultInfo>
            <ResultDate>{`Última atualização: ${moment(lastUpdate).format('DD/MM/YYYY')}`}</ResultDate>
          </ResultInfoContainer>
        )}
      </InfoContainer>
      <ExtraInfoContainer first={firstElement} last={lastElement}>
        <ExtraInfoBox first={firstElement}>
          <ExtraInfoTitle>{isNaN(points as number) ? 'PREMIAÇÃO' : 'TIM COINS POR ATINGIMENTO'}</ExtraInfoTitle>
          {isNaN(points as number) ? (
            <ExtraInfoAward>{points}</ExtraInfoAward>
          ) : (
            <ExtraInfoPoints>{points}</ExtraInfoPoints>
          )}
        </ExtraInfoBox>
      </ExtraInfoContainer>
    </CardContainer>
  );
};

const CardContainer = styled.div<{ first?: boolean; last?: boolean }>`
  display: flex;
  width: 100%;
  background-color: #3939e1;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  ${(props) =>
    props?.first &&
    `
        border-top-right-radius: 24px;
    `}
  ${(props) =>
    props?.last &&
    `
        border-bottom-right-radius: 24px;
    `}
`;

const InfoContainer = styled.div<{ first?: boolean; last?: boolean }>`
  padding: 16px 22px;
  width: 90%;
  background-color: #f5f5f5;
  ${(props) =>
    props?.first &&
    `
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
    `}
  ${(props) =>
    props?.last &&
    `
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
    `}
  
  @media (max-width: 630px) {
    width: 70%;
    padding: 16px;
  }
`;

const ExtraInfoContainer = styled.div<{ first?: boolean; last?: boolean }>`
  ${(props) =>
    props?.first &&
    `
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
    `}
  ${(props) =>
    props?.last &&
    `
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
    `}
  
  @media (max-width: 630px) {
    width: 30%;
  }
`;

const ExtraInfoBox = styled.div<{ first?: boolean }>`
  margin: auto;
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 22px;
  color: #f5f5f5;
  gap: 10px;
  ${(props) =>
    !props?.first &&
    `
        border-top: 2px dashed white;
    `}
`;

const ExtraInfoTitle = styled.h3`
  margin: 0;
  text-align: center;
  font-size: 12pt;
  color: white;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 9pt;
  }
`;

const ExtraInfoPoints = styled.span`
  font-size: 40pt;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 30pt;
  }
`;
const ExtraInfoAward = styled.span`
  text-align: center;
  font-size: 15pt;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 10pt;
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 12pt;
  color: ${(props) => props.theme.blue};

  @media (max-width: 450px) {
    font-size: 10pt;
  }
`;

export const Description = styled.span`
  font-size: 11pt;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 9pt;
  }
`;

export const Content = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1d1d1;
`;

export const ProgressContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 7px;
`;

export const Progress = styled(AntdProgress)`
  .ant-progress-inner {
    background-color: #e3e3e3;
  }
`;

export const PercentInfo = styled.span`
  font-size: 10pt;
`;

export const EmojiContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 0 5px 2px #7e7e7e;
  margin-top: -20px;
`;

const TrendContainer = styled.div`
  width: 100%;
  margin: auto;
  padding: 5px 0;
  background-color: white;
  color: ${(props) => props.theme.red};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 9pt;
    padding: 2px 0;
  }
`;

const ResultInfoContainer = styled.div`
  width: 100%;
  padding-top: 10px;
`;

const ResultInfo = styled.div`
  text-align: end;
  color: ${(props) => props.theme.red};
  font-size: 10pt;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 8pt;
  }
`;

const ResultDate = styled.div`
  text-align: end;
  color: #5e5e5e;
  font-size: 8pt;

  @media (max-width: 450px) {
    font-size: 7pt;
  }
`;
