import React, { useEffect, useState, useCallback } from 'react';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select,
  LoadingIndicator
} from '@digi-tim-19/components';
import { DatePicker, message } from 'antd';
import { order, dateFormatList } from '../../../utils';
import { useHistory } from 'react-router';
import { columns } from './columns';
import { formatDate } from '@digi-tim-19/utils/build';
import { useClient } from '../../../autogenerated/client/client';
import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyMaterialInput,
  CommunicationKindFilter
} from '../../../autogenerated/client/types';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  NotificationsWrapper,
  FilterContainer,
  Button,
  Search,
  CleanButton
} from './styleListar';
import moment from 'moment';
import { routes } from '../../../config/routes';

const { RangePicker } = DatePicker;

export const NotificacaoBackupListar: React.FC<TListarNotificacaoBackup> =
  () => {
    const [createdAt, setCreatedAt] = useState();
    const [filterData, setFilterData] = useState<any>([]);
    const [sort, setSort] = useState(SortFindManyMaterialInput.IdDesc);
    const [filterSearch, setFilterSearch] = useState('');
    const [filterStatus, setFilterStatus] = useState();
    const history = useHistory();

    const appendToFragment = `
  changedBy {
    name
    dateText
  }
  status { 
    _id 
    label 
  } 
  author { 
    name 
  } 
  validity { 
    start 
    end 
  }
  categories { 
    _id, 
    name, 
    parentId, 
    parentCategory { 
      name, 
      _id 
    } 
  }`;

    const getMaterials = useClient('MaterialFindMany', {
      appendToFragment: appendToFragment,
      fetchOnMount: true,
      variables: {
        filter: {
          kind: EnumMaterialKind.CommunicationBackup,
          validityEnum: ValidityEnum.Any,
          includeDrafts: true
        },
        limit: 123,
        sort: sort
      }
    });
    const MaterialDeleteOne = useClient('MaterialRemoveOne');
    useEffect(() => {
      getMaterials.fetch({
        variables: {
          filter: {
            kind: EnumMaterialKind.CommunicationBackup,
            validityEnum: ValidityEnum.Any,
            status: filterStatus,
            createdAtRange: createdAt,
            includeDrafts: true
          },
          limit: 123,
          sort: sort
        }
      });
    }, [sort, filterStatus, createdAt]);

    const resetFilters = () => {
      setSort(SortFindManyMaterialInput.IdDesc);
      setFilterStatus(undefined);
      setCreatedAt(undefined);
      setFilterSearch('');
    };

    const data = getMaterials.result?.map((material: any) => {
      const category = material?.categories?.[0];
      const categoryName = category?.name || '';

      return {
        changedBy: material?.changedBy,
        key: material?._id,
        id: material?.numericId,
        title: material?.title,
        cadastrada: formatDate(material?.createdAt, 'DD/MM/YYYY'),
        user: material?.author?.name,
        categoryName: categoryName,
        criador: material?.author?.name,
        publicada: material.validity?.start
          ? formatDate(material.validity?.start, 'DD/MM/YYYY')
          : '-',
        status: material?.status?.label,
        onEdit: () =>
          history.push(routes.notificacaoBackupEditar.mount(material?._id)),
        onDelete: () =>
          MaterialDeleteOne.fetch({
            variables: { filter: { _id: material?._id } }
          }).then((ctx) => {
            ctx?.result && getMaterials.fetch();
            message.success('Notificação de backup deletada com sucesso');
          })
      };
    });

    const onSearch = useCallback(
      (e) => {
        setFilterSearch(e);
        setFilterData(
          data?.filter((material) =>
            material.title.toLowerCase().includes(e.toLowerCase())
          )
        );
      },
      [data]
    );

    const getCategories = useClient('CategoryFindMany', {
      fetchOnMount: true,
      variables: { limit: 123, filter: { parentId: 'menu' } }
    });
    const category = getCategories?.result?.map((item) => ({
      label: item?.name?.toUpperCase(),
      value: item?._id
    }));
    return (
      <PageTemplate>
        <>
          <NotificationsWrapper>
            <Breadcrumb
              items={[
                { title: 'Home', link: routes.home.mount() },
                { title: 'Gerenciar conteúdo', link: routes.home.mount() },
                {
                  title: 'Notificação de backup',
                  link: routes.notificacaoBackupListar.mount()
                }
              ]}
            />
            <TitleDescription
              iconType="bell"
              title="Notificação de backup"
              description="Cadastramento e consulta de notificação de backup"
            />
          </NotificationsWrapper>
          <FilterContainer>
            <Button to={routes.notificacaoBackupCadastrar.path}>
              CADASTRAR NOTIFICAÇÃO
            </Button>
            <Select
              placeholder="Ordenar por:"
              options={order}
              onChange={(e) => setSort(e)}
              value={sort}
            />
            <RangePicker
              getCalendarContainer={(triggerNode: any) =>
                triggerNode.parentNode
              }
              format={dateFormatList}
              placeholder={['PERÍODO', '']}
              onChange={(e) =>
                setCreatedAt({
                  start: moment(e[0]!).startOf('day')?.toISOString(),
                  end: moment(e[1]!).endOf('day')?.toISOString()
                })
              }
            />
            <Search
              placeholder="Busca título"
              style={{ width: 200 }}
              value={filterSearch}
              onChange={(e) => onSearch(e.target.value)}
            />
            <CleanButton onClick={() => resetFilters()}>
              Limpar filtros
            </CleanButton>
          </FilterContainer>

          {!data ? (
            <LoadingIndicator />
          ) : (
            <Table
              columns={columns}
              dataSource={filterData.length > 0 ? filterData : data}
            />
          )}
        </>
      </PageTemplate>
    );
  };

export type TListarNotificacaoBackup = {};
