import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import {
  Breadcrumb,
  TitleDescription,
  LoadingIndicator,
  CardPinFolder,
  SelectFilter
} from '@digi-tim-19/components';
import { usePin } from './usePin';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { routes } from '../../config/routes';
import { groupBy, differenceBy } from 'lodash';
import moment from 'moment';
import { formatDate } from '@digi-tim-19/utils/build';
import { useHistory } from 'react-router';
import { Input, message } from 'antd';
import { useClient } from '../../autogenerated/client/client';
const { Search: AntdSearch } = Input;

const PastaFavoritosWrapper = styled.div``;
const ContainerCards = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const OrderWrapper = styled.div`
  margin-bottom: 40px;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

function getUpdatedAt(value: any) {
  const result = value
    .sort((a: any, b: any) => {
      if (moment(a?.updatedAt).isBefore(b?.updatedAt, 'day')) return 1;
      if (moment(b?.updatedAt).isBefore(a?.updatedAt, 'day')) return -1;
      return 0;
    })
    .map((item: any) => item?.updatedAt)
    .find((item: any) => item);

  return result;
}

export const Favoritos = () => {
  const pinUser = usePin();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [sort, setSort] = useState(false);
  const [filterSearch, setFilterSearch] = useState();

  const history = useHistory();
  const deleteFolder = useClient('FolderRemoveOne');
  const updateFolder = useClient('FolderUpdateOne');
  const getFolder = useClient('FolderFindMany', {
    fetchOnMount: true
  });

  const selectsOrders = useMemo(() => {
    return [
      {
        defaultValue: sort ? 'Mais antigo' : 'Mais recente',
        action: (value: string) => {
          setSort(value === 'true');
        },
        options: [
          {
            name: 'Mais antigo',
            value: 'true'
          },
          {
            name: 'Mais recente',
            value: 'false'
          }
        ]
      }
    ];
  }, []);

  const deleteFunc = (item: any) => {
    deleteFolder
      .fetch({
        variables: { filter: { _id: item?._id } },
        afterMutate: /^Pin/
      })
      .then((ctx: any) => {
        message.success('Pasta deletada com sucesso');
        getFolder.fetch();
      });
  };

  const editFunc = (item: any, folderName: string) => {
    updateFolder
      .fetch({
        variables: {
          filter: { _id: item?._id },
          record: {
            name: folderName
          }
        },
        afterMutate: /^Pin/
      })
      .then((ctx: any) => {
        message.success('Pasta atualizada com sucesso');
      });
  };

  useEffect(() => {
    if (pinUser.result && getFolder.result) {
      const pins = pinUser.result.filter((x) => !!x?.material || !!x?.campaign);
      const dataGrouped = groupBy(pins, (item) => item?.folder?.name);
      let data = [];

      for (var [key, value] of Object.entries(dataGrouped)) {
        if (key !== 'undefined') {
          const updatedAt = getUpdatedAt(value);
          const idFolder = value
            .map((item) => item?.folderId)
            .find((item) => item);

          const createdAt = getFolder.result.find(
            (item) => item?._id === idFolder
          )?.createdAt;

          data.push({
            folderName: key,
            _id: idFolder,
            updatedAt: formatDate(updatedAt, 'DD/MM/YYYY'),
            updated: updatedAt,
            createdAt: createdAt,
            countItems: value.length,
            actionDelete: deleteFunc,
            actionEdit: editFunc,
            actionAccess: (item: any) => {
              history.push(routes.pastaFavoritos.mount(item._id));
            }
          });
        }
      }

      const diff = differenceBy(getFolder.result, data, '_id');

      if (diff && diff.length > 0) {
        diff.forEach((item) => {
          data.push({
            folderName: item?.name,
            _id: item?._id,
            createdAt: item?.createdAt,
            updatedAt: formatDate(item?.updatedAt, 'DD/MM/YYYY'),
            countItems: 0,
            actionDelete: deleteFunc,
            actionEdit: editFunc
          });
        });
      }

      setDataSource(data);
    }
  }, [pinUser.result, getFolder.result]);

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
      setFilterData(
        dataSource?.filter((item) =>
          item.folderName.toLowerCase().includes(e.toLowerCase())
        )
      );
    },
    [dataSource]
  );

  function sortDataSource(a: any, b: any): number {
    const validation1 = moment(a?.createdAt).isBefore(b?.createdAt, 'second');
    const validation2 = moment(b?.createdAt).isBefore(a?.createdAt, 'second');

    if (validation1) return sort ? -1 : 1;
    if (validation2) return sort ? 1 : -1;
    return 0;
  }

  const renderCards = (data: any[]) => {
    const sortedData = data.sort(sortDataSource);
    return (
      <ContainerCards>
        {sortedData.map((item, index) => (
          <CardPinFolder key={`CardPinFolder${index}`} {...item} />
        ))}
      </ContainerCards>
    );
  };

  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Favoritos', link: routes.favoritos.mount() }
          ]}
        />

        <TitleDescription
          iconType={'pushpin'}
          title={'Favoritos'}
          description={'Encontre seus conteúdos favoritados aqui.'}
        />

        <PastaFavoritosWrapper>
          <OrderWrapper>
            <SelectFilter selects={selectsOrders} />

            <Search
              placeholder="Busca"
              style={{ width: 200 }}
              value={filterSearch}
              onChange={(e) => onSearch(e.target.value)}
            />
          </OrderWrapper>

          {pinUser.loading ? (
            <LoadingIndicator />
          ) : (
            renderCards(filterData.length > 0 ? filterData : dataSource)
          )}
        </PastaFavoritosWrapper>
      </React.Fragment>
    </PageTemplate>
  );
};
