import React, { useState, useEffect } from 'react';
import uniqBy from 'lodash/uniqBy';
import { useDimensions, consumerRoutes } from '@digi-tim-19/utils';
import { useHistory } from 'react-router';
import { Former } from '@digi-tim-19/components';

import { EnumFileKind, ValidityEnum } from '../../../autogenerated/client/types';
import { useClient } from '../../../autogenerated/client/client';
import { Container } from '../Cadastrar/stylesCadastrar';
import { EnumIncentiveCampaignStatus } from '@digi-tim-19/components/build/main/DataProviders/types';
import { Alert, message } from 'antd';

let scrollTimeout: any = null;

export const FormUploadPontos = () => {
  const history = useHistory();
  const { isMobile } = useDimensions();
  const [errors, setErrors] = useState([]);

  const { options, loading: loadingCampaigns, fetchMore } = useCampaignOptions();
  const IncentiveCampaignPayPoints = useClient('IncentiveCampaignPayPoints');
  const loading = loadingCampaigns || IncentiveCampaignPayPoints.loading;

  useEffect(() => {
    if (IncentiveCampaignPayPoints.error) {
      const errorsBreak: any = IncentiveCampaignPayPoints.error.split('\\n');

      if (errorsBreak) {
        setErrors(
          errorsBreak.map((item: any, index: number) => {
            return <p key={`${index}-error`}>{item}</p>;
          }),
        );
      }
    }
  }, [IncentiveCampaignPayPoints.error]);

  return (
    <Container>
      <Former
        showLoadingState={loading}
        config={{
          fields: [
            {
              inline: !isMobile,
              list: [
                {
                  name: 'paymentFileId',
                  label: '',
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: EnumFileKind.Xlsx,
                    CTA: 'UPLOAD DA PLANILHA',
                  },
                },
              ],
            },

            {
              name: 'campaignId',
              label: 'Nome da campanha',
              type: 'select',
              options,
              required: true,
              extraProps: {
                loading,
                onPopupScroll: function () {
                  clearTimeout(scrollTimeout);
                  scrollTimeout = setTimeout(() => {
                    fetchMore();
                  }, 500);
                },
              },
            },
          ],
          submitButton: { label: 'Salvar' },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push('/'),
          },
        }}
        onSubmit={async function ({ data }) {
          IncentiveCampaignPayPoints.fetch({
            variables: {
              campaignId: data.campaignId,
              paymentFileId: data.paymentFileId,
            },
          }).then((ctx) => {
            if (ctx.errors) {
              message.error(ctx.errors.join('\n'));
            } else {
              message.success('TIM Coins distribuídos');
              history.push(consumerRoutes.campanhasIncentivoListar.mount());
            }
          });
        }}
      />

      {!!IncentiveCampaignPayPoints.error && (
        <Alert message="Erro" description={<div>{errors.map((item) => item)}</div>} type="error" showIcon />
      )}
    </Container>
  );
};

function useCampaignOptions() {
  const itemsRef = React.useRef([] as { label: string; value: string }[]);

  const data = useClient('IncentiveCampaignPagination', {
    fetchOnMount: true,
    appendToFragment: `
      pageInfo {
        hasNextPage
        currentPage
      }
      
      items {
        _id
        title
      }
    `,
    variables: {
      filter: {
        validityEnum: ValidityEnum.Any,
        status: EnumIncentiveCampaignStatus.WaitingPointsDistribution,
      },
      perPage: 500,
      page: 1,
    },
  });

  let options = React.useMemo(() => {
    const newItems = (data.result?.items || []).map((el) => ({
      label: el?.title || '',
      value: el?._id || '',
    }));

    return (itemsRef.current = uniqBy([...itemsRef.current, ...newItems], 'value'));
  }, [data.result]);

  let hasNextPage = false;
  let loading = data.loading;

  if (data.result?.pageInfo?.hasNextPage) {
    hasNextPage = true;
  }

  function fetchMore() {
    if (!loading && hasNextPage) {
      let variables: any = data.context?.variables || {};

      variables = {
        ...variables,
        page: (data.result?.pageInfo?.currentPage || 0) + 1,
      };

      data.fetch({
        variables,
      });
    }
  }

  return { options, loading, hasNextPage, fetchMore };
}
