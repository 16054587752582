import React, { useState } from 'react';
import styled from 'styled-components';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { Breadcrumb, TitleDescription, Button, LoadingIndicator } from '@digi-tim-19/components';
import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';
import { downloadXLSX } from '../../utils/downloadXlsx';
import { message } from 'antd';
import moment from 'moment';
import { clickDownload } from '@digi-tim-19/utils';

const DownloadSensitiveWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ItemDownload = styled.div`
  width: 45%;
  margin: 10px 0;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DownloadBases = () => {
  const downloadUsersProfile = useClient('DownloadUsersProfile');
  const downloadUsersBase = useClient('DownloadBaseUsers');
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadBaseUsers = async () => {
    setIsLoading(true);

    const { result } = (await downloadUsersBase.fetch()) || {};

    setIsLoading(false);

    if (!result?.signedUrl) return;

    clickDownload(result?.signedUrl);
  };

  const handleDownloadUsersProfile = async () => {
    try {
      const { result } = await downloadUsersProfile.fetch();
      downloadXLSX('usuarios-perfis', result?.schema, result?.data as Record<string, string>[]);
    } catch (error) {
      message.error('Não foi possível concluir o download');
    }
  };

  return (
    <PageTemplate>
      <>
        <Breadcrumb items={[{ title: 'Home', link: routes.home.mount() }, { title: 'Download de base' }]} />
        <TitleDescription iconType="download" title="Download de base" description="" />

        <DownloadSensitiveWrapper>
          <ItemDownload>
            <h2>Geral</h2>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <p>Download da base de usuários do portal</p>
              <Button
                loading={isLoading}
                style={{ width: 'max-content' }}
                disabled={isLoading}
                onClick={() => handleDownloadBaseUsers()}
              >
                Download
              </Button>
            </div>
          </ItemDownload>

          <ItemDownload>
            <h2>Perfis</h2>
            <div>
              <p>Download relatório perfis de usuários</p>
              <Button
                loading={downloadUsersProfile.loading}
                disabled={downloadUsersProfile.loading}
                onClick={handleDownloadUsersProfile}
              >
                Download
              </Button>
            </div>
          </ItemDownload>

          {isLoading && (
            <LoadingWrapper>
              <LoadingIndicator />
            </LoadingWrapper>
          )}
        </DownloadSensitiveWrapper>
      </>
    </PageTemplate>
  );
};
