import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Breadcrumb, LoadingIndicator, TitleDescription, useClient } from '@digi-tim-19/components';
import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { PageWrapper } from '../Processos/Listar/stylesGerenciar';
import { Button as AntButton, Input, Table } from 'antd';

const columns = [
  {
    title: 'Observação',
    dataIndex: 'obs',
    key: 'observaçaõ',
  },
];

export class LogsResult {
  public success: string[] = [];
  public erros: string[] = [];
}

const Wrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
`;

const BoxWrapper = styled.div`
  display: flex;
`;

export const Button = styled(AntButton)`
  border: none;
  background-color: ${({ theme }) => theme.blue};
  display: flex;
  justify-content: center;
  color: white;
  padding: 5px;
  border-radius: 4px;
  max-width: 250px;
  cursor: pointer;
  outline: none;
  margin-left: 15px;
  &:hover {
    background: ${({ theme }) => theme.red};
    outline: none !important;
    color: white;
  }
`;

export const Search = styled(Input)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

const configPage = {
  pageName: 'Sincronização GU',
  icon: 'schedule',
  description: 'Sincronizar usuários GU',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Admin Digi' },
    { title: 'Sincronizar usuários GU' },
  ],
};

export const SincronizarCargos = () => {
  const [ids, setIds] = useState<string>();
  const [tableReport, setTableReport] = useState<Array<{ obs: string }>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<string>('');

  const sendSincronize = useClient('SincronizeRoleGroup');

  const handleSincronize = () => {
    setLoading(true);

    const idsCargos = ids?.split(',')?.map((id) => Number(id));

    sendSincronize
      .fetch({
        variables: {
          idsCargos: idsCargos,
        },
      })
      .then((ctx) => {
        const result: LogsResult = ctx.result;
        const serialize = [...result.erros, ...result.success].map((log) => ({
          obs: log,
        }));

        setTableReport(serialize);
      })
      .finally(() => setLoading(false));
  };

  const tableReportFilter = useMemo(
    () => tableReport.filter((obj) => !globalSearch || new RegExp(globalSearch, 'gim').test(JSON.stringify(obj))),
    [globalSearch, tableReport?.length],
  );

  return (
    <PageTemplate>
      <>
        <PageWrapper>
          <Breadcrumb items={configPage.breadcrumb} />

          <TitleDescription
            iconType={configPage.icon}
            title={configPage.pageName}
            description={configPage.description}
          />

          <Wrapper>
            <BoxWrapper>
              <Search
                placeholder="Insira os ids no seguinte formato: 56,120,30"
                value={ids}
                onChange={({ target }) => setIds(target.value)}
              />
              <Button disabled={loading} onClick={handleSincronize}>
                SINCRONIZAR
              </Button>
            </BoxWrapper>
            {tableReport?.length ? (
              <BoxWrapper style={{ marginTop: 15, flexDirection: 'column' }}>
                <h1>{tableReport?.length} Usuários atualizados</h1>
                <Search
                  style={{ margin: '20px 0' }}
                  placeholder="Digite sua pesquisa"
                  value={globalSearch}
                  onChange={({ target }) => setGlobalSearch(target.value)}
                />
              </BoxWrapper>
            ) : null}

            {!loading ? (
              <Table columns={columns} dataSource={tableReportFilter} style={{ width: '100%' }} />
            ) : (
              <LoadingIndicator />
            )}
          </Wrapper>
        </PageWrapper>
      </>
    </PageTemplate>
  );
};
