import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
//import bannerFooter from './assets/banner-footer.png';
import { Cards, TypeCard, CardConfig } from '@digi-tim-19/components';
import { useClient } from '../../autogenerated/client/client';
import { EnumBannerImagesDisplay } from '@digi-tim-19/components/build/main/DataProviders/types';
import { EnumAnalyticsParentKind, SortFindManyBannerInput } from '../../autogenerated/client/types';
import { useHistory } from 'react-router';
import { useDimensions } from '@digi-tim-19/utils/build';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-BR');

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: ${(props) => props.theme.white};
  border-radius: 20px;
  padding-top: 20px;
  width: 320px;
  margin-bottom: 20px;
  margin-top: -30px;
  z-index: 998;

  @media screen and (min-width: 768px) {
    justify-content: space-between;
    width: 590px;
    border-radius: 20px;
    padding: 20px;
  }
  @media screen and (min-width: 1024px) {
    width: 840px;
    margin-top: -150px;
  }
  @media screen and (min-width: 1250px) {
    width: 940px;
    max-width: 1150px;
  }
  @media screen and (min-width: 1366px) {
    width: 1050px;
    max-width: 1150px;
  }
  @media screen and (min-width: 1440px) {
    width: 1115px;
    max-width: 1150px;
  }
  @media screen and (min-width: 1920px) {
    width: 1550px;
    max-width: 1150px;
  }
`;

const MiniCardsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  @media screen and (min-width: 768px) {
    width: auto;
  }
`;

function buildObjectCard(banner: any, history: any, analitcsPageView: any): CardConfig {
  const imageDesktop = banner?.images?.find((item: any) => item.display === EnumBannerImagesDisplay.Desktop);
  const imageMobile = banner?.images?.find((item: any) => item.display === EnumBannerImagesDisplay.Mobile);
  const hasLinkCard = banner.link !== undefined && banner.link !== '' && banner.link !== null ? true : false;

  const result = {
    title: '',
    description: '',
    imgDesk: imageDesktop?.signedUrl,
    imgMobile: imageMobile?.signedUrl,
    textButton: banner?.textButton,
    colorDescription: '#00f',
    hasLinkCard: hasLinkCard,
    functionButton: () => {
      if (banner.link) {
        if (banner.link.indexOf('https://') === 0 || banner.link.indexOf('http://') === 0) {
          window.open(banner?.link, '_blank');
          analitcsPageView.fetch({
            variables: {
              title: 'Link Externo',
              externalLink: banner?.link,
              kind: EnumAnalyticsParentKind.ExternalPageView,
            },
          });
        } else {
          history.push(banner?.link);
        }
      }
    },
  };

  return result;
}

export const CardsHome = () => {
  const history = useHistory();
  const analitcsPageView = useClient('AnalyticsPageView');
  const getBanner = useClient('BannerFindMany');
  const [cardsLarge, setCardsLarge] = useState<any[]>([]);
  const [cardsMedium1, setCardsMedium1] = useState<any[]>([]);
  const [cardsMedium2, setCardsMedium2] = useState<any[]>([]);
  const [cardsSmall, setCardsSmall] = useState<any[]>([]);
  const [cardsSmall2, setCardsSmall2] = useState<any[]>([]);
  const [bannerFooter, setBannerFooter] = useState<any[]>([]);

  useEffect(() => {
    getBanner
      .fetch({
        appendToFragment: ` images { link fileId display signedUrl }  validity { start end } `,
        fetchOnMount: true,
        variables: {
          limit: 100,
          sort: SortFindManyBannerInput.SortAsc,
        },
      })
      .then((ctx) => {
        const start = moment().startOf('day').toISOString();
        const data =
          ctx?.result?.filter((banner: any) => {
            if (banner?.status && banner?.status !== 'published') {
              return false;
            }

            if (!banner?.validity || !banner?.validity?.end) {
              return true;
            } else if (banner?.validity && banner?.validity?.end && moment(banner.validity.end).isValid()) {
              return moment(banner.validity.end).isAfter(start);
            }

            return false;
          }) || [];

        if (data.length > 0) {
          const imagesCardLarge = data.filter((item) => item?.position && item.position === TypeCard.LARGE.toString());
          const resultImagesLarge = imagesCardLarge?.map((item) => buildObjectCard(item, history, analitcsPageView));
          setCardsLarge(resultImagesLarge || []);

          const imagesCardMedium1 = data.filter(
            (item) => item?.position && item.position === TypeCard.MEDIUM1.toString(),
          );
          const resultImagesMedium1 = imagesCardMedium1?.map((item) =>
            buildObjectCard(item, history, analitcsPageView),
          );
          setCardsMedium1(resultImagesMedium1 || []);

          const imagesCardMedium2 = data.filter(
            (item) => item?.position && item.position === TypeCard.MEDIUM2.toString(),
          );
          const resultImagesMedium2 = imagesCardMedium2?.map((item) =>
            buildObjectCard(item, history, analitcsPageView),
          );
          setCardsMedium2(resultImagesMedium2 || []);

          const imagesCardSmall = data.filter((item) => item?.position && item.position === TypeCard.SMALL.toString());
          const resultImagesSmall = imagesCardSmall?.map((item) => buildObjectCard(item, history, analitcsPageView));
          setCardsSmall(resultImagesSmall || []);

          const imagesCardSmall2 = data.filter(
            (item) => item?.position && item.position === TypeCard.SMALL2.toString(),
          );
          const resultImagesSmall2 = imagesCardSmall2?.map((item) => buildObjectCard(item, history, analitcsPageView));
          setCardsSmall2(resultImagesSmall2 || []);

          const imagesBannerFooter = data.filter(
            (item) => item?.position && item.position === TypeCard.BANNER_FOOTER.toString(),
          );
          const resultBannerFooter = imagesBannerFooter?.map((item) =>
            buildObjectCard(item, history, analitcsPageView),
          );
          setBannerFooter(resultBannerFooter || []);
        }
      });
  }, []); //eslint-disable-line

  const { isMobile } = useDimensions();
  const [imgBannerFooter, setImgBannerFooter] = useState('');

  useEffect(() => {
    if (bannerFooter.length > 0) {
      if (isMobile) setImgBannerFooter(bannerFooter[0].imgMobile);
      else setImgBannerFooter(bannerFooter[0].imgDesk);
    }
  }, [isMobile, bannerFooter.length, bannerFooter]);

  return (
    <CardsWrapper>
      {cardsLarge.length > 0 && (
        <Cards type={TypeCard.LARGE} items={cardsLarge} backgroundColor="#fff" plataform="consumer" />
      )}
      {cardsMedium1.length > 0 && <Cards type={TypeCard.MEDIUM1} items={cardsMedium1} plataform="consumer" />}
      {cardsMedium2.length > 0 && (
        <Cards type={TypeCard.MEDIUM2} items={cardsMedium2} backgroundColor="#fff" plataform="consumer" />
      )}
      <MiniCardsWrapper>
        {cardsSmall.length > 0 && (
          <Cards type={TypeCard.SMALL} items={cardsSmall} backgroundColor="#fff" plataform="consumer" />
        )}
        {cardsSmall2.length > 0 && (
          <Cards type={TypeCard.SMALL} items={cardsSmall2} backgroundColor="#fff" plataform="consumer" />
        )}
      </MiniCardsWrapper>
      {bannerFooter.length > 0 && (
        <img
          src={imgBannerFooter}
          alt="banner footer"
          style={{ cursor: 'pointer' }}
          onClick={bannerFooter[0].functionButton}
        />
      )}
    </CardsWrapper>
  );
};
