import React from 'react';
import { useRouteMatch } from 'react-router';
import { message } from 'antd';

import { parseUser } from '../../../utils/parseUser';
import { useClient } from '../../../autogenerated/client/client';
import { verdade } from '@digi-tim-19/components';

export function useUserEditPermissions() {
  const user = useUser();
  const togglePermissions = useClient('UserContentPermissionToggle');

  function submit(data: FormData) {
    const permissions = data.permissions || {};

    const roleGroups = permissions.roleGroups || [];
    const regions = permissions.regions || [];
    const contentTypes = permissions.contentTypes || [];

    togglePermissions
      .fetch({
        variables: {
          userId: data._id,
          roleGroups,
          regions,
          contentTypes,
          viewOtherUsersContent: !!data.viewOtherUsersContent
        },
        afterMutate: /UserContentPermission/
      })
      .then((ctx) => {
        if (ctx.errors) {
          message.error(ctx.errors.join(', '));
        } else {
          message.success('Permissões salvas');
          user.fetch();
        }
      });
  }

  return {
    user,
    loadingSave: togglePermissions.loading,
    loadingUser: user.loading,
    submit
  };
}

function useUser() {
  const { params } = useRouteMatch();
  const _id = params.id;

  const UserFindById = useClient('UserFindById', {
    variables: {
      _id
    },
    fetchOnMount: true,
    appendToFragment: `
    roleGroup {
      name
    }
    `
  });

  const ContentPermissions = useClient('UserContentPermissionFindOne', {
    variables: {
      filter: { userId: _id }
    },
    fetchOnMount: true
  });

  const permissions = React.useMemo(() => {
    let roleGroups = verdade(ContentPermissions.result?.roleGroups);
    let contentTypes = verdade(ContentPermissions.result?.siteContentTypes);
    let regions = verdade(ContentPermissions.result?.regions);

    return {
      roleGroups,
      contentTypes,
      regions
    };
  }, [ContentPermissions.result]);

  return {
    loading: UserFindById.loading || ContentPermissions.loading,
    permissions,
    fetch: UserFindById.fetch,
    ...parseUser(UserFindById.result)
  };
}

interface FormData {
  _id: string;
  permissions: Permissions;
  viewOtherUsersContent?: Boolean;
}

interface Permissions {
  roleGroups?: string[] | null;
  regions?: string[] | null;
  contentTypes?: string[] | null;
}
