import React from 'react';
import { WatchButton } from '@digi-tim-19/components';
import { Icon } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import moment from 'moment';
import { routes } from '../../../config/routes';
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin-right: 20px;
  }
`;

const DisableButton = styled(WatchButton)`
  background: #f5f5f5;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const LiveColumnContainer: React.FC<TLiveColumnContainerProps> = ({
  data
}) => {
  const subtractMinutes = moment(data.eventTime).subtract('minutes', 3);

  const isEventCurrent =
    moment(Date.now()).isBefore(data.eventTime) && !data.endTime;

  const isEventActive =
    moment(Date.now()).isAfter(subtractMinutes) && !data.endTime;

  const history = useHistory();

  return (
    <Container>
      <p>{data.title}</p>
      {isEventCurrent && !isEventActive && (
        <DisableButton disabled>
          Entrar
          <Icon
            type="play-circle"
            style={{ paddingLeft: '6px', fontSize: '20px' }}
          />
        </DisableButton>
      )}
      {isEventActive && (
        <WatchButton
          onClick={() =>
            history.push(`${routes.OnTubePlayer.mount(data.id)}?kind=live`)
          }
        >
          Entrar
          <Icon
            type="play-circle"
            style={{ paddingLeft: '6px', fontSize: '20px' }}
          />
        </WatchButton>
      )}
    </Container>
  );
};
interface DataKey {
  key: string;
  name: string;
  onDelete?: VoidFunction;
  onEdit?: VoidFunction;
  id: string;
  [key: string]: any;
}
export type TLiveColumnContainerProps = {
  data: DataKey;
};
