import React from 'react';
import { makeField } from '@digi-tim-19/components';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  .FieldWrapper {
    margin-bottom: 0 !important;
  }
`;
type Props = {
  downloadUrl?: string | null;
};

export const Download: React.FC<Props> = ({ downloadUrl }) => {
  const name = 'evidence';
  return (
    <Wrapper>
      {makeField({
        field: {
          name,
          type: 'text',
          label: 'Evidência',
          extraProps: {
            readOnly: true
          }
        },
        key: name
      })}
      <a href={downloadUrl || '#'} className="download">
        Download
      </a>
    </Wrapper>
  );
};

export default Download;
