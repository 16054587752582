import React, { useState } from 'react';
import MaskedInput from 'antd-mask-input';
import { Icon, LoadingIndicator, UserSession } from '@digi-tim-19/components';
import styled from 'styled-components';
import { useClient } from '../../autogenerated/client/client';
import { cpf, onlyNumbers, OriginUserDataSession } from '@digi-tim-19/utils';
import ScreenLogin from './Screens';
import { RecoveryPassword } from './RecoveryPassword';

const WrapperCPF = styled.div`
  input {
    height: 50px;
  }
`;

const Label = styled.div`
  color: red;
  font-weight: bold;
`;

const LoadingIndicatorWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export type AccessCPFProps = {
  value?: string;
  setScreen: Function;
  password?: string;
  setPassword?: Function;
  setValue: Function;
  disabled?: boolean;
};

export const AccessCPF = (props: AccessCPFProps) => {
  const { value, setScreen, setValue } = props;

  const [suffix, setSuffix] = useState(false);
  const [error, setError] = useState('');

  const UserGUCheckCPF = useClient('UserGUCheckCPF');

  return (
    <WrapperCPF>
      <MaskedInput
        disabled={UserGUCheckCPF.loading || props.disabled}
        value={value}
        placeholder="Preencha seu CPF"
        mask="111.111.111-11"
        addonAfter={
          suffix ? <Icon type="check-circle" style={{ color: 'green' }} /> : ''
        }
        onChange={(e) => {
          const currentValue = onlyNumbers(e.target.value);
          const finishedTyping = currentValue.length === 11;
          const isValid = finishedTyping && cpf.isValidCPF(currentValue);

          if (!finishedTyping && error) {
            setError('');
          }

          if (finishedTyping && !isValid) {
            setError(isValid ? '' : 'CPF inválido');
          }

          if (!isValid) {
            return;
          }

          if (finishedTyping) {
            setSuffix(true);
          }

          UserSession.clear();
          OriginUserDataSession.clear();

          UserGUCheckCPF.fetch({
            variables: { CPF: currentValue }
          }).then((ctx) => {
            const result = ctx.result;

            if (!result) return; // se ocorreu algum erro, é notificado pelo graphqlMiddleware

            if (!result.exists) {
              setError('Este CPF não possui acesso a plataforma');
              return;
            }

            if (result.firstAccess) {
              setValue(currentValue);
              setScreen(ScreenLogin.NEW_REGISTER);
            } else {
              setValue(currentValue);
              setScreen(ScreenLogin.LOGIN);
            }
          });
        }}
      />

      <RecoveryPassword onClick={() => setScreen(ScreenLogin.TEMP_PASSWORD)} />

      {!!error && <Label>{error}</Label>}

      {UserGUCheckCPF.loading && (
        <LoadingIndicatorWrapper>
          <LoadingIndicator small />
        </LoadingIndicatorWrapper>
      )}
    </WrapperCPF>
  );
};
