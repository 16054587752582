import React, { useState, useMemo } from 'react';

import {
  Former,
  LoadingIndicator,
  SelecionarPublico,
  usePublico
} from '@digi-tim-19/components';

import {
  EnumFileKind,
  EnumEventLiveKind
} from '../../autogenerated/client/types';
import { message } from 'antd';
import { routes } from '../../config/routes';
import { useHistory } from 'react-router';
import { Container } from './Cadastrar/stylesCadastrar';
import { useClient } from '../../autogenerated/client/client';
import { useDimensions } from '@digi-tim-19/utils/build';
import { useContentTypePermissions } from '../../hooks/useContentTypePermissions';
import { useMaterialCategoriesSelect } from '../../hooks/useMaterialCategories';
import { selectedChannels } from '../../utils/selectedChannels';
const selectedChannelsConsumer = selectedChannels();

const ThumbKind = EnumFileKind.VideoThumb;
const BannerMobileKind = EnumFileKind.BannerMaterialMobile;
const BannerDesktopKind = EnumFileKind.BannerMaterialDesktop;

export const Form = (props: TFormLiveEditProps) => {
  const permissions = useContentTypePermissions();
  const { availableAtChannels, availableAtRegions, availableAtRoleGroups } =
    usePublico();
  const history = useHistory();
  const playlistOptions = useMaterialCategoriesSelect('video_playlist');
  const isUpdate = !!props.initialValues;
  const [categoriesSelected, setCategoriesSelected] = useState<string[]>([]);

  const LivelUpdateOne = useClient('EventLiveUpdateOne');
  const createLive = useClient('EventLiveCreateOne');

  const initialValues = useMemo(() => {
    return {
      ...props.initialValues,
      playlist: (props.initialValues?.categories || []).map(
        (el: any) => el?._id
      ),
      cardimage: (props.initialValues?.files || []).find(
        (el: any) => el && el.kind === ThumbKind
      )?._id,
      bannerMobile: (props.initialValues?.files || []).find(
        (el: any) => el && el.kind === BannerMobileKind
      )?._id,
      bannerDesktop: (props.initialValues?.files || []).find(
        (el: any) => el && el.kind === BannerDesktopKind
      )?._id
    };
  }, [props.initialValues]);

  const imageDesktop = props.initialValues?.files?.find(
    (item: any) => item?.kind === BannerDesktopKind
  );
  const imageMobile = props.initialValues?.files?.find(
    (item: any) => item?.kind === BannerMobileKind
  );

  const imageUrlDesktop = imageDesktop?.signedUrl;
  const imageUrlMobile = imageMobile?.signedUrl;

  const { isMobile } = useDimensions();

  if (permissions.loading) return <LoadingIndicator />;

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              label: 'URL do vídeo',
              name: 'videoUrl',
              validate: (value) => {
                if (value) {
                  const id = value?.match?.(/vimeo.com\/(\d*)\/?/)?.[1] || '';
                  const valid = id?.length >= 9;
                  if (!valid) {
                    return 'Url inválida.';
                  }
                }
              }
            },
            {
              inline: !isMobile,
              list: [
                {
                  name: 'eventDate',
                  label: 'Data da Live',
                  type: 'datePicker',
                  required: true,
                  extraProps: {
                    format: 'DD/MM/YYYY HH:mm',
                    showTime: true
                  },
                  maxWidth: '250px'
                },
                {
                  name: 'cardimage',
                  label: '',
                  type: 'upload',
                  extraProps: {
                    kind: ThumbKind,
                    CTA: 'IMPORTAR THUMB'
                  }
                }
              ]
            },
            {
              inline: !isMobile,
              list: [
                {
                  name: 'bannerMobile',
                  label: '',
                  type: 'upload',
                  extraProps: {
                    kind: BannerMobileKind,
                    CTA: 'Banner Mobile',
                    hasPreview: true,
                    imageUrl: imageUrlMobile,
                    widthPreview: '100px'
                  }
                },
                {
                  name: 'bannerDesktop',
                  label: '',
                  type: 'upload',
                  extraProps: {
                    kind: BannerDesktopKind,
                    CTA: 'Banner Desktop',
                    hasPreview: true,
                    imageUrl: imageUrlDesktop,
                    widthPreview: '100px'
                  }
                }
              ]
            },
            {
              list: [
                {
                  name: 'title',
                  label: 'TÍTULO',
                  required: true,
                  validate: (value: any) => {
                    const format = /[#%&;]/;
                    if (format.test(value))
                      return 'Os caracteres #%&; não são permitidos';

                    if (value)
                      return !(value.length > 110)
                        ? undefined
                        : 'Máximo de 110 caracteres';

                    return undefined;
                  }
                }
              ]
            },
            {
              name: 'description',
              label: 'DESCRIÇÃO',
              type: 'textarea',
              required: true,
              extraProps: {
                rows: 5
              },
              validate: (value: any) => {
                const format = /[#%&]/;
                if (format.test(value))
                  return 'Os caracteres #%& não são permitidos';

                if (value)
                  return !(value.length > 1200)
                    ? undefined
                    : 'Máximo de 1200 caracteres';

                return undefined;
              }
            },
            {
              name: 'playlist',
              label: 'PLAYLIST',
              options: playlistOptions?.sort(function (a: any, b: any) {
                if (a.label > b.label) return 1;
                if (a.label < b.label) return -1;
                return 0;
              }),
              required: true,
              extraProps: {
                mode: 'tags',
                onSelect: (value: string) =>
                  setCategoriesSelected((prevState) => [...prevState, value]),
                onDeselect: (value: string) =>
                  setCategoriesSelected(
                    categoriesSelected.filter((item) => item !== value)
                  )
              },
              validate: (value: any) => {
                const format = /[#%&;]/;
                if (format.test(value))
                  return 'Os caracteres #%&; não são permitidos';

                if (value) {
                  const hasInvalidWord = value.map((word: string) => {
                    return !(word.length > 110)
                      ? undefined
                      : 'Máximo de 110 caracteres';
                  });

                  return hasInvalidWord;
                }

                return undefined;
              }
            },
            {
              className: 'Footer',
              inline: true,
              list: [
                {
                  custom: (
                    <SelecionarPublico
                      initialValues={props.initialValues}
                      selectedChannels={
                        !isUpdate ? selectedChannelsConsumer : []
                      }
                      {...permissions.filterOptions}
                    />
                  )
                }
              ]
            }
          ],
          submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(routes.liveListar.path)
          }
        }}
        onSubmit={async function ({ data }) {
          const record = {
            videoUrl: data.videoUrl,
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            description: data.description,
            title: data.title,
            fileIds: [
              data.cardimage,
              data.bannerMobile,
              data.bannerDesktop
            ].filter(Boolean),
            eventDate: data.eventDate,
            categories: data.playlist,
            kind: EnumEventLiveKind.Live
          };

          if (isUpdate && data._id) {
            await LivelUpdateOne.fetch({
              variables: { record, filter: { _id: data._id } }
            }).then((ctx) => {
              if (!ctx.errors) {
                history.push(routes.liveListar.mount());
              }
            });
          } else {
            await createLive
              .fetch({
                variables: { record }
              })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success('Live cadastrado com sucesso');
                  history.push(routes.liveListar.path);
                } else {
                  message.error('Live não cadastrado');
                }
              });
          }
        }}
      />
    </Container>
  );
};

export type TFormLiveEditProps = {
  initialValues?: any;
};
