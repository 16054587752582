import { Radio as AntdRadio, Modal as AntdModal, Icon, Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Alternatives } from './QuizAlternativesField';
import { Button } from '@digi-tim-19/components';

interface Props {
  disabled?: boolean;
  alternatives?: Alternatives[];
  onChange?: (data: Alternatives[]) => void;
}

const Radio = styled(AntdRadio)`
  display: flex;
  align-items: center;
  padding: 8px;
  white-space: break-spaces !important;
  flex: 1;
`;

const RadioContainer = styled.div`
  display: flex;
  width: 100% !important;
  align-items: center;
  align-content: space-between;
  flex-wrap: wrap;
  &:hover {
    background-color: #ededed;
    border-radius: 10px;
  }
`;

const Modal = styled(AntdModal)`
  .ant-modal-body {
    max-height: 350px !important;
    padding: 16px 24px !important;
    overflow: auto;
  }

  .ant-radio-group {
    margin-top: 10px;
    width: 100% !important;
  }
`;

export const ModalAlternatives = ({ alternatives = [], disabled = true, onChange }: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [alternativesState, setAlternatives] = React.useState<Alternatives[]>([])

  const handleEditAlternative = (id: string, value: string) => {
    setAlternatives(prevState => {
      const alternativeIndex = prevState.findIndex(al => al.id === id);
      const alternative = prevState.filter(al => al.id === id)[0]
      alternative.description = value
      prevState.splice(alternativeIndex, 1, alternative)
      if (onChange) onChange([...prevState]);
      return prevState
    })
  }

  React.useEffect(() => {
    setAlternatives([...alternatives])
  },[alternatives])

  return (
    <React.Fragment>
      <Button onClick={() => setOpen(true)}>
        <Icon type="ordered-list" /> ({alternatives.length})
      </Button>
      <Modal
        title="Alternativas"
        visible={open}
        okButtonProps={{
          hidden: true,
        }}
        cancelText="FECHAR"
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      >
        {alternatives.length > 0 && (
          <React.Fragment>
            <Radio.Group disabled={disabled} value={alternatives.find((alternative) => alternative.isCorrect)?.id}>
              {alternativesState.map((alternative) => {
                return (
                  <RadioContainer key={`rc-alternative-${alternative.id}`}>
                    <Radio key={`alternative-${alternative.id}`} value={alternative.id}>
                      {/* {alternative.description} */}
                      <Input
                        id="question-field"
                        placeholder="Questão"
                        value={alternative.description || ''}
                        onChange={(e: any) => {
                          handleEditAlternative(alternative.id, e?.target?.value)
                        }}
                      />
                    </Radio>
                  </RadioContainer>
                );
              })}
            </Radio.Group>
          </React.Fragment>
        )}
      </Modal>
    </React.Fragment>
  );
};
