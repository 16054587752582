export enum ScreenLogin {
  BUTTONS = 1,
  ACCESS_CPF = 2,
  NEW_REGISTER = 3,
  TEMP_PASSWORD = 4,
  NEW_PASSWORD = 5,
  LOGIN = 6
}

export default ScreenLogin;
