import * as React from 'react';
import { useHistory } from 'react-router';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { extractUserChannelName } from '../../../utils/extractUserChannelName';
import { message } from 'antd';

let typingThrottle: any = null;

const initialState = {
  searchInput: '',
  searching: '',
  currentPage: 1,
  matricula: '',
  searchingMatricula: '',
  channel: '',
  region: '',
  isBlocked: '',
  isDisabled: ''
};

export function useUsersPagination() {
  const perPage = 20;

  const stateRef = React.useRef(initialState);
  const [state, _setState] = React.useState(stateRef.current);
  const [usersIsLoadingBlocking, setUsersIsBlocking] = React.useState<string[]>(
    []
  );

  const {
    searchInput,
    searching,
    currentPage,
    matricula,
    searchingMatricula,
    channel,
    region,
    isBlocked,
    isDisabled
  } = state;

  function setState(newState: Partial<typeof state>) {
    stateRef.current = {
      ...stateRef.current,
      ...newState
    };
    _setState(stateRef.current);
  }

  const history = useHistory();

  const UserBlockComment = useClient('UserBlockComments');

  function blockUser(userId: string, isBlocked: boolean) {
    setUsersIsBlocking((prev) => {
      return [...prev, userId];
    });
    UserBlockComment.fetch({
      variables: {
        userId: userId,
        isBlocked: isBlocked
      }
    }).then((ctx) => {
      if (ctx.errors) {
        console.error(ctx.errors);
        message.error('Ocorreu um erro ao bloquear o usuário.');
      }
      if (ctx.result) {
        const textBlocked = isBlocked ? 'bloqueado' : 'desbloqueado';
        message.success(`Usuário ${textBlocked} com sucesso.`);
        users.refetch();
      }

      setUsersIsBlocking((prev) => {
        return prev.filter((x) => x !== userId);
      });
    });
  }

  const users = useClient('UserSearchPagination', {
    appendToFragment: `
      pageInfo {
        hasNextPage
        currentPage
        pageCount
        hasPreviousPage
      }
      items {
        _id
        name
        matricula
        CPF
        region
        channels
        blockedCommenting
        disabled
      } 
    `
  });

  React.useEffect(() => {
    let _isBlocked: boolean | undefined = !!isBlocked;
    if (isBlocked === '') _isBlocked = undefined;
    if (isBlocked === 'false') _isBlocked = false;
    let _isDisabled: boolean | undefined = !!isDisabled;
    if (isDisabled === '') _isDisabled = undefined;
    if (isDisabled === 'false') _isDisabled = false;

    users.fetch({
      variables: {
        filter: {
          name: !searching ? undefined : searching,
          region: !region ? undefined : region,
          channel: !channel ? undefined : channel,
          matricula: !searchingMatricula ? undefined : searchingMatricula,
          isBlocked: _isBlocked,
          isDisabled: _isDisabled
        },
        perPage: perPage,
        page: currentPage
      }
    });
  }, [
    currentPage,
    searching,
    region,
    channel,
    searchingMatricula,
    isBlocked,
    isDisabled
  ]);

  function resetFilters() {
    setState(initialState);
  }

  const data = React.useMemo(() => {
    return users.result?.items?.map((userElement) => {
      const disabledSwitchBlockUser = usersIsLoadingBlocking.some(
        (x) => x === userElement?._id
      );

      return {
        _id: userElement?._id,
        matricula: userElement?.matricula || '-',
        nome: userElement?.name || '-',
        CPF: userElement?.CPF || '-',
        region: userElement?.region || '-',
        blockedCommenting: !!userElement?.blockedCommenting,
        disabled: !!userElement?.disabled,
        channel: extractUserChannelName(userElement),
        hideOnRead: true,
        onEdit: () =>
          history.push(
            routes.gerenciarPermissoesEditar.mount(userElement?._id!)
          ),
        disabledSwitchBlockUser: disabledSwitchBlockUser,
        onBlockComment: (data: any) => {
          blockUser(data._id, !data.blockedCommenting);
        },
        onHistory: () =>
          history.push(routes.listUserBlock.mount(userElement?._id!))
      };
    });
  }, [users.result, usersIsLoadingBlocking]);

  function goTo(currentPage: number) {
    setState({ currentPage });
  }

  function setRegion(region: string) {
    setState({ region });
    goTo(1);
  }

  function setIsblocked(isBlocked: string) {
    setState({ isBlocked });
    goTo(1);
  }

  function setIsDisabled(isDisabled: string) {
    setState({ isDisabled });
    goTo(1);
  }

  function setChannel(channel: string) {
    setState({ channel });
    goTo(1);
  }

  function onSearch(value: string) {
    setState({ searchInput: value });
    clearTimeout(typingThrottle);
    typingThrottle = setTimeout(function () {
      setState({ searching: value });
      goTo(1);
    }, 500);
  }

  function onChangeMatricula(value: string) {
    setState({ matricula: value });
    clearTimeout(typingThrottle);
    if (value.length > 0 && value.length < 4) return;
    typingThrottle = setTimeout(function () {
      setState({ searchingMatricula: value.length === 0 ? undefined : value });
      goTo(1);
    }, 500);
  }

  return {
    ...users,
    resetFilters,
    searchInput,
    onSearch,
    data,
    count: users.result?.count || 0,
    currentPage,
    goTo,
    perPage,
    onChangeMatricula,
    setRegion,
    region,
    isBlocked,
    setIsblocked,
    isDisabled,
    setIsDisabled,
    channel,
    setChannel,
    matricula
  };
}
