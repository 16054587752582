import * as React from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import ScreenLogin from './Screens';
import { uniqueId } from 'lodash';
import { getAzureData } from '../../config/appConfig';

const ButtonsWrapper = styled.div`
  width: 100%;
  & > * {
    display: block;
    text-transform: uppercase;
    color: ${({ theme }) => theme.blue};
    font-size: 16px;
    width: 270px;
    font-family: TimMedium;
    margin: 30px auto;
  }
`;

export type AccessButtonsProps = {
  onClickSetScreen: Function;
};

export const AcessButtons = ({ onClickSetScreen }: AccessButtonsProps) => {
  const msUrl = React.useMemo(() => {
    const { tenant, client_id, redirect_uri } = getAzureData();
    const uuID = uniqueId();
    const searchParams = new URLSearchParams({
      client_id,
      redirect_uri,
      response_type: 'id_token',
      scope: 'openid email',
      state: uuID,
      nonce: uuID
    });

    return `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?${searchParams}`;
  }, []);

  return (
    <ButtonsWrapper>
      <Tooltip placement="top" title="Usuários com e-mail @timbrasil">
        <Button onClick={() => window.open(msUrl, '_self')}>
          Acesso domínio @TIMBRASIL
        </Button>
      </Tooltip>
      <Tooltip placement="bottom" title="Terceiros sem e-mail @timbrasil">
        <Button onClick={() => onClickSetScreen(ScreenLogin.ACCESS_CPF)}>
          acesso cpf
        </Button>
      </Tooltip>
    </ButtonsWrapper>
  );
};
