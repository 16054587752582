import React, { useMemo } from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';

import { Form } from '../Form';
import { useHistory, useRouteMatch } from 'react-router';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { TabelaPrecoWrapper } from './stylesCadastrar';
import { findCorrectName } from '../../../utils/productNames';

export const RegionalCadastrar = () => {
  const { params } = useRouteMatch();

  const kindName = useMemo(() => {
    if (params.kind) return findCorrectName(params.kind);

    return '';
  }, [params]);

  return (
    <PageTemplate>
      <>
        <TabelaPrecoWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Veja Mais' },
              {
                title: `Regional (${kindName})`,
                link: routes.regionalListar.mount(params.kind ?? '')
              },
              { title: 'Cadastrar' }
            ]}
          />
          <TitleDescription
            iconType="more"
            title={`Regional (${kindName})`}
            description="Cadastrar os documentos específicos da sua regional"
          />
        </TabelaPrecoWrapper>

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
