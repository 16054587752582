import React, { useMemo } from 'react';
import {
  ListGrid,
  MaterialProvider,
  ShareModal,
  useMaterialActions,
  parseCardsData,
  parseCardsDataIncentiveCampaignConsumer,
  MaybeMaterialList,
  MaybeIncentiveCampaignList
} from '@digi-tim-19/components';
import { obterImagemPadraoPorMaterial } from '../../utils/material';
import { Icon as IconAction } from '@digi-tim-19/components/build/main/ActionButton/ActionButton';
import { useIncentiveCampaignActions } from '@digi-tim-19/components/build/main/CardIncentiveCampaign/DataProviders/IncentiveCampaignActionsProvider';
import { IncentiveCampaignProvider } from '@digi-tim-19/components/build/main/CardIncentiveCampaign/DataProviders/IncentiveCampaignProvider';
import { ShareModal as ShareModalCampaign } from '@digi-tim-19/components/build/main/CardIncentiveCampaign/ActionButton/ShareModal';

export const ListGridFavoritos = (props: {
  materials?: any[];
  incentiveCampaigns?: any[];
}) => {
  const hasIncentiveCampaigns = !!props?.incentiveCampaigns?.length;
  const hasMaterials = !!props?.materials?.length;

  const dataMaterial = useMemo(() => {
    if (props.materials && props.materials.length)
      return parseCardsData(props.materials as MaybeMaterialList);

    return [];
  }, [props?.materials]);

  const dataIncentiveCampaign = useMemo(() => {
    if (props.incentiveCampaigns && props.incentiveCampaigns.length)
      return parseCardsDataIncentiveCampaignConsumer(
        props.incentiveCampaigns as MaybeIncentiveCampaignList
      );

    return [];
  }, [props?.incentiveCampaigns]);

  const actionsMaterials = useMaterialActions();
  const actionsCampaign = useIncentiveCampaignActions();

  const listGridDataMaterials = loadDataMaterials(
    dataMaterial,
    actionsMaterials
  );
  const listGridDataIncentiveCampaigns = loadDataIncentiveCampaigns(
    dataIncentiveCampaign,
    actionsCampaign
  );

  return (
    <>
      {hasIncentiveCampaigns && hasMaterials && <h2>Meus documentos</h2>}

      {hasMaterials && <ListGrid items={listGridDataMaterials} />}

      {hasIncentiveCampaigns && hasMaterials && <h2>Minhas campanhas</h2>}
      {hasIncentiveCampaigns && (
        <ListGrid items={listGridDataIncentiveCampaigns} />
      )}
    </>
  );
};

function loadDataMaterials(data: any[], actions: any) {
  const listGridData = data.map((el) => {
    const material = el.material || {};
    const thumb = obterImagemPadraoPorMaterial(material);

    return {
      ...el.cardData,
      defaultImage: thumb,
      renderPin: () => (
        <MaterialProvider material={material as any}>
          <IconAction
            isFilled={material.pinned}
            name={'pushpin'}
            onClick={() => {
              actions.deletePin({ recordId: material._id });
            }}
          />
        </MaterialProvider>
      ),
      renderShare: () => (
        <MaterialProvider material={material as any}>
          <ShareModal />
        </MaterialProvider>
      ),
      actions: {
        pin: true,
        share: true,
        onPin: () => {
          actions.pin({ recordId: el.material._id });
        }
      }
    };
  });

  return listGridData;
}

function loadDataIncentiveCampaigns(data: any[], actions: any) {
  const listGridData = data.map((el) => {
    const incentiveCampaign = el.incentiveCampaign || {};

    return {
      ...el.cardData,
      defaultImage: incentiveCampaign.cardImageFile.signedUrl,
      renderPin: () => (
        <IncentiveCampaignProvider incentiveCampaign={incentiveCampaign as any}>
          <IconAction
            isFilled={incentiveCampaign.pinned}
            name={'pushpin'}
            onClick={() => {
              actions.deletePin({ recordId: incentiveCampaign._id });
            }}
          />
        </IncentiveCampaignProvider>
      ),
      renderShare: () => (
        <IncentiveCampaignProvider incentiveCampaign={incentiveCampaign as any}>
          <ShareModalCampaign />
        </IncentiveCampaignProvider>
      ),
      actions: {
        pin: true,
        share: true,
        onPin: () => {
          actions.pin({ recordId: incentiveCampaign._id });
        }
      }
    };
  });

  return listGridData;
}
