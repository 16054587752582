import styled from 'styled-components';
import { Button } from '@digi-tim-19/components';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';

export const CampanhaVigenteTitleWrapper = styled.h3`
  color: #00508c;
`;

export const CampanhaPeriodTitleWrapper = styled.h3`
  color: #00508c;
  margin-top: 20px;
`;

export const CampanhaVigenteImage = styled.div`
  border-radius: 3px;
  margin-bottom: 10px;
  height: 400px;
  display: flex;

  img {
    width: auto;
  }

  @media screen and (max-width: 768px) {
    height: 300px;
    padding: 50px;
  }
`;

export const ContestButton = styled(Button)`
  margin-top: 20px;
  background: #fe0326 !important;
  &:hover {
    border: none;
  }
`;

export const CampanhaVigenteButtonsContainer = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;

  .ant-btn {
    margin-right: 15px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    .ant-btn {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
`;

export const CampanhaVigenteDataPrevista = styled.div`
  text-align: right;
  width: 100%;
`;

export const Position = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  i {
    font-size: 24px;
  }
`;
export const ContestationModal = styled(Modal)`
  max-width: 900px !important;
  width: 100% !important;
  .ant-modal-body,
  .ant-modal-footer {
    background: #ededed;
  }
  .ant-modal-close-x {
    svg {
      fill: #fff;
    }
  }
  .ant-modal-header {
    background: #00508c;
    .ant-modal-title {
      color: #fff;
    }
  }
  input:read-only {
    background: #f8f8f8;
  }
`;
type TName = {
  active: boolean;
};

export const Name = styled.div<TName>`
  display: flex;
  flex-direction: column;
  color: ${(props) => (props.active ? '#75C3F7' : '#707070')};
`;

export const ContestationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: space-between;
  p {
    margin: 0;
    margin-right: 5px;
  }
  button {
    margin: 0;
  }
`;

export const ButtonLink = styled(Button)`
  border: none;
  background: ${(props) => props.theme.blue};
  display: flex;
  justify-content: center;

  color: ${(props) => props.theme.white};
  padding: 5px 10px;
  border-radius: 4px;
  font-family: TimRegular;
  cursor: pointer;
  outline: none;
  margin-right: 15px;

  &:hover {
    background: ${(props) => props.theme.red};
    color: ${(props) => props.theme.white};
    outline: none !important;
  }
`;

export const ButtonLinkTo = styled(Link)`
  border: none;
  background: ${(props) => props.theme.blue};
  display: flex;
  justify-content: center;

  color: ${(props) => props.theme.white};
  padding: 5px 10px;
  border-radius: 4px;
  font-family: TimRegular;
  cursor: pointer;
  outline: none;
  margin-right: 15px;

  &:hover {
    background: ${(props) => props.theme.red};
    color: ${(props) => props.theme.white};
    outline: none !important;
  }
`;

export const CampanhaButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

export const CampanhaTitleWrapper = styled.div`
  font-size: 18pt;
  font-weight: bold;
  color: #5c5c5c;
  margin-bottom: 18px;

  @media (max-width: 450px) {
    font-size: 15pt;
  }
`;
export const CampanhaSubTitleWrapper = styled.div`
  font-size: 14pt;
  font-weight: bold;
  color: #014996;

  @media (max-width: 450px) {
    font-size: 15pt;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;

export const TableContainer = styled.div`
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 36px 12px;
  width: 95%;

  @media (max-width: 450px) {
    margin: 36px 0;
    /* max-width: 400px; */
    overflow: hidden;
  }
`;

export const GradientButton = styled(Button)`
  background-image: linear-gradient(30deg, #0000c9, #0095ff, #60bcfd);
  border: none !important;

  &:hover {
    border: none !important;
    border-color: inherit;
    background-image: linear-gradient(30deg, #0000c9, #0095ff, #60bcfd);
    box-shadow: 0 0 5px 2px #727272;
  }

  &:focus {
    border: none !important;
    border-color: inherit;
    background-image: linear-gradient(30deg, #0000c9, #0095ff, #60bcfd);
    box-shadow: 0 0 5px 2px #727272;
  }
`;

export const ButtonsCampaignContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  border: 2px dashed #eeeeee;
  border-radius: 5px;

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

export const ButtonBox = styled.div`
  width: max-content;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;

  @media (max-width: 1030px) {
    align-items: inherit;
    flex-direction: column;
  }

  @media (max-width: 660px) {
    width: 100%;
  }
`;

export const DateBox = styled.div`
  flex: 1;
  display: flex;
  padding: 0 20px;
  gap: 10px;

  @media (max-width: 1030px) {
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 660px) {
    padding-top: 20px;
  }
`;

export const DateText = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  font-weight: bold;
`;

export const DateInfo = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: gray;

  div span {
    font-weight: bold;
  }
`;
