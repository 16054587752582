import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Icon } from 'antd';
import {
  Breadcrumb,
  TitleDescription,
  SelectFilter,
  useCurrentUser,
  LoadingIndicator
} from '@digi-tim-19/components';

import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { SortFindManyPinInput } from '../../autogenerated/client/types';
import {
  StyledContent,
  OrderWrapper,
  ChooseVisualWrapper,
  CardsVisual
} from './styles';
import { ListMode, useSelectsOrders } from './utilsFavoritos';
import { InnerPageData } from './InnerPageDataFavoritos';

export const PastaFavoritos = () => {
  const [sort, setSort] = React.useState<SortFindManyPinInput>(
    SortFindManyPinInput.IdDesc
  );
  const [visualMode, setVisualMode] = useState<ListMode>(ListMode.card);
  const [folderName, setFolderName] = useState('');
  const userId = useCurrentUser().result?._id;
  const { id } = useParams<{ id: string }>();

  const getFolder = useClient('FolderFindById', {
    fetchOnMount: true,
    variables: {
      _id: id
    }
  });

  useEffect(() => {
    if (getFolder.result) setFolderName(getFolder.result.name || '');
  }, [getFolder.result]);

  const { selectsOrders } = useSelectsOrders(setSort);

  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Favoritos', link: routes.favoritos.mount() },
            { title: folderName, link: routes.pastaFavoritos.mount(id || '') }
          ]}
        />

        <TitleDescription
          iconType={'pushpin'}
          title={folderName}
          description={''}
        />
        <StyledContent>
          <OrderWrapper>
            <SelectFilter selects={selectsOrders} />
            <ChooseVisualWrapper>
              <Icon
                onClick={() => setVisualMode(ListMode.card)}
                type="appstore"
                style={{ fontSize: '35px' }}
              />
              <Icon
                onClick={() => setVisualMode(ListMode.list)}
                type="unordered-list"
                style={{ fontSize: '35px' }}
              />
            </ChooseVisualWrapper>
          </OrderWrapper>
          <CardsVisual>
            {userId && (
              <InnerPageData
                mode={visualMode}
                userId={userId}
                folderId={id || ''}
                sort={sort}
              />
            )}
          </CardsVisual>
        </StyledContent>
      </React.Fragment>
    </PageTemplate>
  );
};
