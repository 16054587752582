import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  Breadcrumb,
  TitleDescription,
  IconListConsumer,
  verdade
} from '@digi-tim-19/components';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { EnumMaterialKind } from '../../autogenerated/client/types';

const StyledList = styled.ul`
  display: flex;
  padding-left: 0;
  list-style-type: none;
  padding-bottom: 30px;
  flex-wrap: wrap;
  /* border-bottom: 1px solid #ccc; */
  & li {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
  }
  & img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
  & a {
    color: ${(props) => props.theme.blue};
    text-transform: uppercase;
    font-family: TimBold;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: flex-start;
    & li {
      padding-right: 5px;
    }
  }
`;
const PageContent = styled.div`
  display: block;
  width: 100%;
`;
const TitleList = styled.h2`
  color: ${(props) => props.theme.red};
  font-family: TimBold;
  margin: 5px;
  padding-top: 10px;
  text-transform: uppercase;
  padding-bottom: 10px;
`;
export const SistemasVendas2020 = () => {
  const getMaterialsAdministrativo = useClient('MaterialFindMany', {
    appendToFragment: `
      title
      _id
      description
      pinned
      categories {
        _id
        name
      }
      files {
        signedUrl
        kind
      }
    `
  });
  const getMaterialsVendas = useClient('MaterialFindMany', {
    appendToFragment: `
      title
      _id
      description
      pinned
      categories {
        _id
        name
      }
      files {
        signedUrl
        kind
      }
    `
  });

  const getMaterialsVendasSMB = useClient('MaterialFindMany', {
    appendToFragment: `
      title
      _id
      description
      pinned
      categories {
        _id
        name
      }
      files {
        signedUrl
        kind
      }
    `
  });

  useEffect(() => {
    getMaterialsAdministrativo.fetch({
      variables: {
        filter: {
          kind: EnumMaterialKind.LinksUteis,
          inCategories: ['sistemas_vendas:administrativo']
        },
        limit: 123
      }
    });
    getMaterialsVendas.fetch({
      variables: {
        filter: {
          kind: EnumMaterialKind.LinksUteis,
          inCategories: ['sistemas_vendas:vendas']
        },
        limit: 123
      }
    });
    getMaterialsVendasSMB.fetch({
      variables: {
        filter: {
          kind: EnumMaterialKind.LinksUteis,
          inCategories: ['sistemas_vendas:sistemas_de_vendas_smb']
        },
        limit: 123
      }
    });
  }, []); //eslint-disable-line

  return (
    <PageTemplate>
      <>
        <Breadcrumb
          items={[
            {
              title: 'Home',
              link: '/home'
            },
            {
              title: 'Sistemas de Vendas',
              link: '/sistemas-de-vendas'
            }
          ]}
        />
        <TitleDescription
          iconType={'laptop'}
          title={'Sistema de Vendas'}
          description={
            'Nesta página constam os links para acessar os sistemas, pós vendas e administrativos.'
          }
        />
        <PageContent>
          {verdade(getMaterialsVendas?.result).length > 0 ? (
            <TitleList>VENDAS</TitleList>
          ) : null}
          <StyledList>
            {verdade(getMaterialsVendas?.result).map((item: any) => (
              <IconListConsumer
                defaultImage="/defaultImages/default-card-thumb.png"
                list={item}
              />
            ))}
          </StyledList>

          {verdade(getMaterialsAdministrativo?.result).length > 0 ? (
            <TitleList>ADMINISTRATIVOS</TitleList>
          ) : null}
          <StyledList>
            {verdade(getMaterialsAdministrativo?.result).map((item: any) => (
              <IconListConsumer
                defaultImage="/defaultImages/default-card-thumb.png"
                list={item}
              />
            ))}
          </StyledList>

          {verdade(getMaterialsVendasSMB?.result).length > 0 ? (
            <TitleList>Sistemas de vendas SMB</TitleList>
          ) : null}
          <StyledList>
            {verdade(getMaterialsVendasSMB?.result).map((item: any) => (
              <IconListConsumer
                defaultImage="/defaultImages/default-card-thumb.png"
                list={item}
              />
            ))}
          </StyledList>
        </PageContent>
      </>
    </PageTemplate>
  );
};
