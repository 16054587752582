import React from 'react';
import { ErrorsModal } from '../Cadastrar/styles';
import { ValidationErrosType } from './utilsArquivoCampanha';
import { Table } from '@digi-tim-19/components';

type ModalErrorsProps = {
  isOpen: boolean;
  errors: ValidationErrosType[];
  onCancel: Function;
  onOk: Function;
};

export const ModalErrors = ({
  isOpen,
  onCancel,
  onOk,
  errors
}: ModalErrorsProps) => {
  return (
    <ErrorsModal
      okText="OK"
      cancelText="SAIR"
      title="Verifique os seguintes campos na planilha"
      visible={isOpen}
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        onOk();
      }}
    >
      <div>
        <Table dataSource={errors} columns={columns} pagination={false} />
      </div>
    </ErrorsModal>
  );
};

const columns = [
  {
    title: 'Linha',
    dataIndex: 'line',
    key: 'line'
  },
  {
    title: 'Mensagem',
    dataIndex: 'message',
    key: 'message'
  }
];
