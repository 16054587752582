import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select,
  LoadingIndicator
} from '@digi-tim-19/components';
import { message, DatePicker } from 'antd';
import { useHistory, useRouteMatch } from 'react-router';
import { columns } from './columns';
import { formatDate, dayjs } from '@digi-tim-19/utils/build';
import { useClient } from '../../../autogenerated/client/client';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyMaterialInput
} from '../../../autogenerated/client/types';

import {
  NotificationsWrapper,
  FilterContainer,
  Search,
  Button,
  CleanButton
} from './stylesListar';
import { routes } from '../../../config/routes';

import moment from 'moment';
import { useFilterAuthor } from '../../../components/FilterAuthor/useFilterAuthor';
import { findCorrectName } from '../../../utils/productNames';

const { RangePicker } = DatePicker;

export const RegionalListar: React.FC<TVejaMaisRegionalListarProps> = () => {
  const filterAuthor = useFilterAuthor();
  const [createdAt, setCreatedAt] = useState();
  const [filterData, setFilterData] = useState<any>([]);
  const [show, setShow] = useState([]);
  const [filterSearch, setFilterSearch] = useState();
  const [sort, setSort] = useState(SortFindManyMaterialInput.IdDesc);
  const [vigenteFilter, setVigenteFilter] = useState<ValidityEnum>(
    ValidityEnum.Any
  );

  const [updatedAt, setUpdatedAt] = useState();
  const [showUpdatedAt, setShowUpdatedAt] = useState([]);

  const { params } = useRouteMatch();

  const kind = useMemo(() => params.kind ?? '', [params]);

  const kindName = useMemo(() => {
    if (kind) return findCorrectName(kind);

    return '';
  }, [kind]);

  function isVigente(validity: any) {
    const validityEnd = validity ? validity.end : null;
    if (validityEnd) {
      const isPast = dayjs(validityEnd).isBefore(new Date());
      return isPast ? 'Não' : 'Sim';
    }
  }
  const history = useHistory();

  const MaterialDeleteOne = useClient('MaterialRemoveOne');

  const getMaterials = useClient('MaterialFindMany', {
    appendToFragment: `
    validity { start, end }
    author { _id, name },
    categories {
      _id
      name
      parentId
    }
    changedBy {
      name
      dateText
    },`
  });

  useEffect(() => {
    getMaterials.fetch({
      variables: {
        filter: {
          kind,
          validityEnum: vigenteFilter,
          includeDrafts: true,
          createdAtRange: createdAt,
          updatedAtRange: updatedAt,
          filterAuthor: filterAuthor.filter,
          owner: filterAuthor.ownerFilter
        },
        limit: 123,
        sort: sort
      }
    });
  }, [sort, vigenteFilter, createdAt, filterAuthor.signature, kind, updatedAt]); //eslint-disable-line

  const resetFilters = () => {
    setSort(SortFindManyMaterialInput.IdDesc);
    setCreatedAt(undefined);
    setFilterSearch(undefined);
    setFilterData([]);
    setShow([]);
    filterAuthor.reset();
    setUpdatedAt(undefined);
    setShowUpdatedAt([]);
  };

  const data = getMaterials.result?.map((material: any) => ({
    changedBy: material?.changedBy,
    key: material?._id,
    id: material?.numericId,
    title: material?.title,
    validity: isVigente(material?.validity),
    cadastrada: formatDate(material?.createdAt, 'DD/MM/YYYY'),
    atualizada: formatDate(material?.updatedAt, 'DD/MM/YYYY'),
    publicada: material.validity?.start
      ? formatDate(material.validity?.start, 'DD/MM/YYYY')
      : '-',
    categories: material?.categories
      .filter((item: any) => item._id.split(':').length === 5)
      .map((item: any) => item.name)
      .join(', '),
    criador: material?.author?.name,
    onRead: () => {
      if (material.fileIds.length) {
        history.push(routes.pdfViewer.mount(material?._id));
      } else {
        history.push(routes.conteudoMaterial.mount(material?._id));
      }
    },
    onEdit: () =>
      history.push(routes.regionalEditar.mount(params?.kind, material?._id)),
    onDelete: () =>
      MaterialDeleteOne.fetch({
        variables: { filter: { _id: material?._id } },
        afterMutate: /^Material/
      }).then((ctx) => {
        message.success('Documento deletado com sucesso');
      })
  }));

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
      setFilterData(
        data?.filter((material) =>
          material.title.toLowerCase().includes(e.toLowerCase())
        )
      );
    },
    [data]
  );
  const onChange = (e: any) => {
    if (e.length > 0) {
      setCreatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setShow(e);
    } else {
      setShow([]);
      setCreatedAt(undefined);
    }
  };

  const onChangeUpdatedAt = (e: any) => {
    if (e.length > 0) {
      setUpdatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setShowUpdatedAt(e);
    } else {
      setShowUpdatedAt([]);
      setUpdatedAt(undefined);
    }
  };

  return (
    <PageTemplate>
      <>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Veja Mais' },
              { title: `Regional (${kindName})` },
              { title: 'Listar' }
            ]}
          />
          <TitleDescription
            iconType="more"
            title={`Regional (${kindName})`}
            description="Listar e consultar os documentos específicos da sua regional."
          />
        </NotificationsWrapper>
        <FilterContainer>
          <Button to={routes.regionalCadastrar.mount(params?.kind)}>
            CADASTRAR DOCUMENTO
          </Button>
          <Select
            placeholder="Ordenar por:"
            options={order}
            onChange={(e) => setSort(e)}
            value={sort}
          />
          <Select
            placeholder="Vigente:"
            options={vigente}
            onChange={(e) => setVigenteFilter(e)}
            value={vigenteFilter}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format="DD/MM/YYYY"
            placeholder={['PERÍODO', '']}
            onChange={(e) => onChange(e)}
            value={show}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={'DD/MM/YYYY'}
            placeholder={['ATUALIZAÇÃO', '']}
            onChange={(e) => {
              onChangeUpdatedAt(e);
            }}
            value={showUpdatedAt}
          />
          <Search
            placeholder="Busca título"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e) => onSearch(e.target.value)}
          />

          {filterAuthor.render()}

          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>

        {!data ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={filterData.length > 0 ? filterData : data}
          />
        )}
      </>
    </PageTemplate>
  );
};

export type TVejaMaisRegionalListarProps = {};

const order = [
  { label: 'MAIS ANTIGO', value: SortFindManyMaterialInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyMaterialInput.IdDesc }
];
const vigente = [
  { label: 'TODOS', value: ValidityEnum.Any },
  { label: 'VIGENTE', value: ValidityEnum.Current },
  { label: 'NÃO VIGENTE', value: ValidityEnum.Expired }
];
