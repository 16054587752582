import React from 'react';
import { Badge, Tooltip } from 'antd';
import { formatNumberToPtBr } from '../../../utils/formatters';

export const columns = [
  {
    title: 'Nome do participante',
    dataIndex: 'name',
    key: 'name',
    render: (text: string, context: any) => {
      return <p style={{ color: context.isRemoved ? 'red' : '' }}>{text}</p>;
    },
  },
  {
    title: 'Matricula',
    dataIndex: 'matricula',
    key: 'matricula',
    render: (text: string, context: any) => {
      return <p style={{ color: context.isRemoved ? 'red' : '' }}>{text}</p>;
    },
  },
  {
    title: 'CPF',
    dataIndex: 'cpf',
    key: 'cpf',
    render: (text: string, context: any) => {
      return <p style={{ color: context.isRemoved ? 'red' : '' }}>{text}</p>;
    },
  },
  {
    title: 'Canal',
    dataIndex: 'channel',
    key: 'channel',
    render: (text: string, context: any) => {
      return <p style={{ color: context.isRemoved ? 'red' : '' }}>{text}</p>;
    },
  },
  {
    title: 'Regional',
    dataIndex: 'region',
    key: 'region',
    render: (text: string, context: any) => {
      return <p style={{ color: context.isRemoved ? 'red' : '' }}>{text}</p>;
    },
  },
  {
    title: 'TIM Coins recebidos',
    dataIndex: 'receivedPoints',
    key: 'receivedPoints',
    render: (points: number, data: any) => {
      return <Badge status={getColorByOp(data.op)} text={formatNumberToPtBr(Number(points))} />;
    },
  },
  {
    title: 'Origem',
    dataIndex: 'campaignName',
    key: 'campaignName',
    render: (text: string, data: any) => {
      let textx = `${text ? text.substring(0, 15) : ''}...`;

      if (data.op === 'debit') textx = 'Pontos Utilizados';

      if (data.op === 'expired') textx = 'Pontos expirados';

      return (
        <Tooltip placement="top" title={text}>
          <p>{textx}</p>
        </Tooltip>
      );
    },
  },
  {
    title: 'Data da operação',
    dataIndex: 'pointsCreditDate',
    key: 'pointsCreditDate',
    render: (text: string) => {
      return <p>{text}</p>;
    },
  },
];

export interface DataKey {
  key: string;
}

function getColorByOp(op: string) {
  switch (op) {
    case 'credit':
      return 'success';
    case 'expired':
      return 'warning';
    case 'debit':
      return 'error';
    default:
      return 'processing';
  }
}
