import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Breadcrumb,
  TitleDescription,
  useCurrentUser,
  Select
} from '@digi-tim-19/components';

import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';

const RelatorioViewer = styled.iframe`
  border-radius: 5px;
  height: 430px;
  margin: 25px 0;
  overflow: auto;
  width: 100%;

  @media screen and (min-width: 768px) {
    height: 573px;
  }

  @media screen and (min-width: 1024px) {
    height: 860px;
  }
`;

type TOption = {
  label?: string;
  value?: string | number;
};

export const Relatorios = () => {
  const currentYear = new Date().getFullYear();

  const [relatorioLink, setRelatorioLink] = useState('');

  const getUser = useCurrentUser();

  const [selectedYear, setSelectedYear] = useState(currentYear.toString());

  const [options, setOptions] = useState<TOption[]>();

  useEffect(() => {
    if (getUser.result && relatorioLink == '') {
      setRelatorioLink(
        `https://powerbi.digi.ag/TimConsumer/?i=${getUser.result._id}&a=${currentYear}`
      );
    }

    initData();
  }, [relatorioLink]);

  function initData() {
    //vai criando links de 2020 até o ano atual
    const currentYear = new Date().getFullYear();
    let years = [];

    for (let i = 2020; i <= currentYear; i++) {
      years.push({
        label: i.toString(),
        value: i.toString()
      });
    }

    setOptions(years);
  }

  function handleChange(option: any) {
    setSelectedYear(option);
    setRelatorioLink(
      `https://powerbi.digi.ag/TimConsumer/?i=${getUser?.result?._id}&a=${option}`
    );
  }

  return (
    <PageTemplate>
      <>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo' },
            { title: 'Relatórios' }
          ]}
        />
        <TitleDescription
          iconType="bar-chart"
          title="Relatórios"
          description="Selecione o ano para exibir o relatório"
        />
        <Select
          style={{ width: '250px' }}
          placeholder="Selecione o ano"
          value={selectedYear}
          onChange={handleChange}
          options={options}
        />
        <RelatorioViewer src={relatorioLink} frameBorder={0} />
      </>
    </PageTemplate>
  );
};
