import React from 'react';
import styled from 'styled-components';
import ReactSpeedometer from 'react-d3-speedometer';

const SpeedContainer = styled.div`
  background: #f4f4f4;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  strong {
    color: #00508c;
  }
  div {
    margin-top: 70px;
  }
`;

type SpeedometerProps = {
  goals: any[];
};

export const Speedometer = ({ goals }: SpeedometerProps) => {
  return (
    <>
      {goals.map((goal) => {
        return (
          goal?.name && (
            <SpeedContainer key={goal.name}>
              <strong>{goal?.name}</strong>
              <ReactSpeedometer
                maxValue={100}
                value={Math.floor(
                  ((goal?.reached || 0) / (goal?.goal || 0)) * 100
                )}
                needleColor="#4B80B6"
                startColor="#FE481A"
                segments={6}
                endColor="#6AD72C"
              />
            </SpeedContainer>
          )
        );
      })}
    </>
  );
};
