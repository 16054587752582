import ReactApexChart from 'react-apexcharts';
import * as React from 'react';

function getDefatulData(maxValue: Number) {
  return {
    options: {
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: [
        '#540d6e',
        '#ee4266',
        '#ffd23f',
        '#3bceac',
        '#0ead69',
        '#ffa69e'
      ],
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'Acompanhe seu progresso',
        align: 'left'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      yaxis: {
        title: {
          text: '% Atingida'
        },
        min: 0,
        max: maxValue
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
        onItemClick: {
          toggleDataSeries: false
        }
      }
    }
  };
}

const MultLineChart: React.FC<Props> = ({ series, xaxis, maxValue }) => {
  const data = getDefatulData(maxValue);

  return (
    <ReactApexChart
      options={{ ...data.options, xaxis }}
      series={series}
      type="line"
      height={350}
    />
  );
};

interface Props {
  series?: {
    name?: string;
    data?: any[];
  }[];
  xaxis: {
    categories?: string[];
    title?: {
      text?: string;
    };
  };
  maxValue: Number;
}

export { MultLineChart };
