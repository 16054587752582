import video from './assets/Thumbnails (desafios)/videos-ontube.png';
import login from './assets/Thumbnails (desafios)/Acesso-ao-portal.png';
import communication from './assets/Thumbnails (desafios)/Informativos.png';
import offer_map from './assets/Thumbnails (desafios)/Mapa de ofertas.png';
import incentive from './assets/Thumbnails (desafios)/campanha-de-incentivo.png';
import time from './assets/Thumbnails (desafios)/tempo-de-portal.png';
import share from './assets/Thumbnails (desafios)/compartilhar-conteudo.png';
import research from './assets/Thumbnails (desafios)/pesquisa.png';
import externalLink from './assets/Thumbnails (desafios)/acesso-link-externo.png';
import quiz from './assets/Thumbnails (desafios)/Quiz.png';
import view from './assets/Thumbnails (desafios)/Acesso-a-conteudo.png';

export const challengesImage: Map<string, string> = new Map([
  ['video', video],
  ['login', login],
  ['communication', communication],
  ['offer_map', offer_map],
  ['incentive', incentive],
  ['time', time],
  ['share', share],
  ['research', research],
  ['externalPageView', externalLink],
  ['mission', time] /* ALTERAR */,
  ['quiz', quiz],
  ['view', view],
  ['externalPageView', view],
]);

export const challengesName: Map<string, string> = new Map([
  ['video', 'OnTube'],
  ['login', 'Acesso Portal'],
  ['communication', 'Informativo'],
  ['offer_map', 'Mapa de Ofertas'],
  ['incentive', 'Incentivo'],
  ['time', 'Tempo de portal'],
  ['share', 'Compartilhamento'],
  ['research', 'Pesquisa'],
  ['externalPageView', 'Acesso Externo'],
  ['mission', 'Missão'] /* ALTERAR */,
  ['quiz', 'Quiz'],
  ['view', 'Conteúdo'],
  ['externalPageView', 'Visualização de link externo'],
]);
