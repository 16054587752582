import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { Breadcrumb, TitleDescription, Button, Select, LoadingIndicator } from '@digi-tim-19/components';
import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';
import { clickDownload } from '@digi-tim-19/utils/build';
import {
  EnumIncentiveCampaignStatus,
  SortFindManyIncentiveCampaignInput,
  ValidityEnum,
} from '../../autogenerated/client/types';
import { DatePicker } from 'antd';
import moment from 'moment';
import { usePagination } from '../../hooks/usePagination';

const { RangePicker } = DatePicker;

const DownloadSensitiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 750px;
  gap: 20px;
`;

type TOption = {
  label?: string;
  value?: string | number;
  end?: string;
};

const modeOptions = [
  {
    label: 'Campanhas em Andamento e Apuração',
    value: 'total',
  },
  {
    label: 'Campanhas Encerradas',
    value: 'closed',
  },
];

export const DownloadIncentivo = () => {
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [dateRange, setDateRange] = useState<any>();
  const [loadingFetch, setLoadingFetch] = useState<boolean>(false);
  const { loading, parsed, updateFetch } = usePagination('IncentiveCampaignPagination', {
    initial: {
      page: 1,
      perPage: 500,
    },
    cache: true,
    fetchOnMount: false,
    itemsFragment: '_id title validity { end }',
    parseResult: (result) =>
      result.map((el) => ({ label: el.title, value: el._id, end: el.validity?.end })) as TOption[],
  });

  const reportClient = useClient('DownloadIncentiveReports');

  useEffect(() => {
    if (!selectedStatus || selectedStatus === 'total' || (selectedStatus === 'closed' && !dateRange)) return;
    updateFetch((state) => ({
      ...state,
      filter: {
        status: selectedStatus as EnumIncentiveCampaignStatus,
        validityEnum: ValidityEnum.Any,
      },
      sort: SortFindManyIncentiveCampaignInput.CreatedatDesc,
    }));
  }, [selectedStatus, dateRange]);

  const disabled = !selectedStatus || loadingFetch || loading;

  return (
    <PageTemplate>
      <>
        <Breadcrumb
          items={[{ title: 'Home', link: routes.home.mount() }, { title: 'Relatórios de Participação e Aceites' }]}
        />
        <TitleDescription
          iconType="download"
          title="Relatórios de Participação e Aceites"
          description="Relatórios de Participação e Aceites"
        />

        <DownloadSensitiveWrapper>
          <Select
            onChange={(id) => {
              setSelectedStatus(id);
            }}
            value={selectedStatus}
            options={modeOptions}
            placeholder="Status de Campanha"
            style={{
              width: '100%',
              maxWidth: '750px',
            }}
          />

          {selectedStatus === 'total' && (
            <p>{`O processamento do relatório da opção: "Campanhas em Andamento e Apuração" foi realizado em ${moment()
              .subtract(1, 'day')
              .format('DD/MM/YYYY')}.`}</p>
          )}

          {selectedStatus === 'closed' && (
            <div style={{ display: 'flex', gap: '20px' }}>
              <p>Campanhas encerradas no periodo:</p>
              <RangePicker
                format={'DD/MM/YYYY'}
                disabledDate={(current) => {
                  return !!current?.isSameOrBefore(moment().subtract(6, 'months'));
                }}
                placeholder={['de', 'até']}
                onChange={([start, end]) => setDateRange({ start: start, end: end })}
              />
            </div>
          )}

          {(loading || loadingFetch) && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '32px' }}>
              <LoadingIndicator />
            </div>
          )}

          <Button
            disabled={disabled}
            onClick={async () => {
              setLoadingFetch(true);
              if (!selectedStatus) return;
              const campaignOptions = dateRange
                ? parsed.filter((el) => moment(el.end).isBetween(dateRange.start, dateRange.end))
                : parsed;

              const { result } =
                (await reportClient.fetch({
                  variables: {
                    mode: `${selectedStatus}`,
                    IncentiveCampaignIds: campaignOptions.map((el) => `${el.value}`),
                  },
                })) || {};

              if (!result?.url) return;

              clickDownload(result.url, true);

              setLoadingFetch(false);
            }}
            style={{ width: '300px' }}
          >
            Download
          </Button>
        </DownloadSensitiveWrapper>
      </>
    </PageTemplate>
  );
};
