"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.setImagePlaylist = function (playlist, area, isMobile) {
  var breakpoint = isMobile ? 'mobile' : 'desktop';
  var partPath = "/".concat(area, "/");
  if (area === 'banners-ontube') partPath = "/".concat(area, "/").concat(breakpoint, "/");

  switch (playlist) {
    case 'video_playlist:radar_consumer':
      return "/defaultImages".concat(partPath, "playlist-radar-consumer.jpg");

    case 'video_playlist:radar_consumer_mais':
      return "/defaultImages".concat(partPath, "playlist-radar-consumer-mais.jpg");

    case 'video_playlist:radar_smb_mais':
      return "/defaultImages".concat(partPath, "playlist-radar-smb-mais.jpg");

    case 'video_playlist:live_consumer':
      return "/defaultImages".concat(partPath, "playlist-live-consumer.jpg");

    case 'video_playlist:da_um_suporte_ai':
      return "/defaultImages".concat(partPath, "playlist-da-um-suporte-ai.jpg");

    case 'video_playlist:portabilidade':
      return "/defaultImages".concat(partPath, "playlist-portabilidade.jpg");

    case 'video_playlist:tim_black':
      return "/defaultImages".concat(partPath, "playlist-tim-black.jpg");

    case 'video_playlist:xp___experience_point':
      return "/defaultImages".concat(partPath, "playlist-xp.jpg");

    case 'video_playlist:smb_voz':
      return "/defaultImages".concat(partPath, "playlist-smb-voz.jpg");

    case 'video_playlist:smb_veja_mais':
      return "/defaultImages".concat(partPath, "playlist-smb-veja-mais.jpg");

    case 'video_playlist:residencial':
      return "/defaultImages".concat(partPath, "playlist-residencial.jpg");

    case 'video_playlist:smb_campanhas_de_incentivo':
      return "/defaultImages".concat(partPath, "playlist-smb-campanhas.jpg");

    case 'video_playlist:tim_pre':
      return "/defaultImages".concat(partPath, "playlist-tim-pre.jpg");

    case 'video_playlist:smb_para_o_cliente':
      return "/defaultImages".concat(partPath, "playlist-smb-para-clientes.jpg");

    case 'video_playlist:lojas_tim':
      return "/defaultImages".concat(partPath, "playlist-lojas-tim.jpg");

    case 'video_playlist:tim_tech':
      return "/defaultImages".concat(partPath, "playlist-tim-tech.jpg");

    case 'video_playlist:campanhas_consumer':
      return "/defaultImages".concat(partPath, "playlist-campanhas.jpg");

    case 'video_playlist:tim_black_familia':
      return "/defaultImages".concat(partPath, "playlist-tim-black-familia.jpg");

    case 'video_playlist:regionais':
      return "/defaultImages".concat(partPath, "playlist-regionais.jpg");

    case 'video_playlist:tim_controle':
      return "/defaultImages".concat(partPath, "playlist-tim-controle.jpg");

    case 'video_playlist:smb_solucoes':
      return "/defaultImages".concat(partPath, "playlist-smb-solucoes.jpg");

    case 'video_playlist:smb_internet':
      return "/defaultImages".concat(partPath, "playlist-smb-internet.jpg");

    case 'video_playlist:eventos':
      return "/defaultImages".concat(partPath, "playlist-eventos.jpg");

    case 'video_playlist:smb_servicos':
      return "/defaultImages".concat(partPath, "playlist-smb-servicos.jpg");

    case 'video_playlist:smb_processos':
      return "/defaultImages".concat(partPath, "playlist-processos-smb.jpg");

    case 'video_playlist:ta_na_rede':
      return "/defaultImages".concat(partPath, "playlist-ta-na-rede.jpg");

    case 'video_playlist:tim___c6_bank':
      return "/defaultImages".concat(partPath, "playlist-tim-c6-bank.jpg");

    case 'video_playlist:tutoriais_para_o_cliente':
      return "/defaultImages".concat(partPath, "playlist-tutoriais-para-o-cliente.jpg");

    case 'video_playlist:convencao_tim':
      return "/defaultImages".concat(partPath, "playlist-convencao-tim.jpg");

    case 'video_playlist:canal_evolution':
      return "/defaultImages".concat(partPath, "playlist-canal-evolution.png");

    case 'video_playlist:canal_evolution_residencial':
      return "/defaultImages".concat(partPath, "playlist-canal-evolution-residencial.png");

    case 'video_playlist:meu_tim':
      return "/defaultImages".concat(partPath, "playlist-meu-tim.png");

    case 'video_playlist:diversidade___inclusao':
      return "/defaultImages".concat(partPath, "playlist-diversidade-inclusao.jpeg");

    case 'video_playlist:veja_mais_consumer':
      return "/defaultImages".concat(partPath, "playlist-veja_mais_consumer.png");

    case 'video_playlist:tim___ampli':
      return "/defaultImages".concat(partPath, "playlist-tim___ampli.jpg");

    case 'video_playlist:conquista_premiada':
      return "/defaultImages".concat(partPath, "playlist-conquista-premiada.png");

    case 'video_playlist:convencao_tim_2021':
      return "/defaultImages".concat(partPath, "playlist-convencao-tim-2021.png");

    case 'video_playlist:excellence_group':
      return "/defaultImages".concat(partPath, "playlist-excellence-group.png");

    case 'video_playlist:premium_league':
      return "/defaultImages".concat(partPath, "playlist-premium-league.png");

    case 'video_playlist:black_friday':
      return "/defaultImages".concat(partPath, "playlist-black-friday.jpg");

    case 'video_playlist:startim':
      return "/defaultImages".concat(partPath, "playlist-startim.jpg");

    case 'video_playlist:papo_de_fibra':
      return "/defaultImages".concat(partPath, "playlist-podcast-nacao-fibra.jpg");

    case 'video_playlist:jornada':
      return "/defaultImages".concat(partPath, "playlist-jornada.jpg");

    case 'video_playlist:tim_black_com_apple_one':
      return "/defaultImages".concat(partPath, "playlist-tim-black-apple-one.jpg");

    case 'video_playlist:giro_de_campanhas':
      return "/defaultImages".concat(partPath, "playlist-giro-de-campanhas.png");

    case 'video_playlist:retencao_em_loja':
      return "/defaultImages".concat(partPath, "playlist-retencao-em-loja.jpg");

    case 'video_playlist:retail':
      return "/defaultImages".concat(partPath, "playlist-retail.png");

    case 'video_playlist:valoriza__':
      return "/defaultImages".concat(partPath, "playlist-valoriza.png");

    default:
      return "/defaultImages/default".concat(partPath, "tim.jpg");
  }
};