import * as React from 'react';
import { colors } from '@digi-tim-19/theme';
import { Header } from '@digi-tim-19/components';

import { siteImages } from '../../assets/images';
import { routes } from '../../config/routes';
import { useHistory } from 'react-router';
import { MainSocialFeed } from './MainSocialFeed';

export type MainHeaderProps = {
  toggleMenu: Function;
  menuIsOpen: boolean;
};

export const MainHeader = ({ toggleMenu, menuIsOpen }: MainHeaderProps) => {
  const history = useHistory();

  return (
    <Header
      {...{
        hasMenuWord: true,
        primaryColor: colors.gray0,
        secondColor: colors.grayMedium2,
        thirdColor: colors.blue,
        iconsColor: colors.blue,
        borderColor: colors.gray0,
        hasBtnMapaOfertas: () => history.push(routes.mapaDeOfertas.mount()),
        img: siteImages.logoConsumer,
        alt: 'logo',
        iconType: 'user',
        menuIsOpen: menuIsOpen,
        toggleMenu: (ev: any) => {
          toggleMenu(ev);
        },
        onSelect: (value: string) => {
          history.push(routes.busca.mount(encodeURIComponent(value)));
        },
        renderSocialFeed: () => <MainSocialFeed />
      }}
    />
  );
};
