import React from 'react';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

const ButtonShow = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #014996;
  border-radius: 10px;
  font-size: 14px;
  color: #014996;
  text-transform: uppercase;
  padding: 10px;
  cursor: pointer;
`;

interface Props {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const ButtonShowRanking = ({ show, setShow }: Props) => {
  if (show) {
    return (
      <ButtonShow onClick={() => setShow((value) => !value)}>
        <span>ESCONDER RANKING</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
          style={{ width: '15px' }}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
        </svg>
      </ButtonShow>
    );
  }

  return (
    <ButtonShow onClick={() => setShow((value) => !value)}>
      <span>VER RANKING</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
        style={{ width: '15px' }}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
      </svg>
    </ButtonShow>
  );
};
