import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { Breadcrumb, TitleDescription, Table } from '@digi-tim-19/components';
import { routes } from '../../config/routes';
import { DatePicker } from 'antd';
import { colors } from '@digi-tim-19/theme';
import { formatDate } from '@digi-tim-19/utils/build';
import { useClient } from '../../autogenerated/client/client';
import moment from 'moment';
import { formatNumberToPtBr } from '../../utils/formatters';
// import { ValidityEnum } from '@digi-tim-19/components/build/main/DataProviders/types';

const { RangePicker } = DatePicker;

const InformacoesExtratoWrapper = styled.div``;

const BoxSaldoDisponivel = styled.div`
  background: ${colors.white};
  width: 250px;
  height: auto;
  border-radius: 10px;
  border: 1px solid ${colors.gray1};
  padding: 20px;
  margin: 20px 0;
`;

const TituloSaldo = styled.div`
  color: ${colors.grayMedium2};
  font-family: TimBold;
  font-weight: bold;
  font-size: 17px;
`;

const ValorSaldo = styled.div`
  font-size: 30px;
`;

const PontosExpirar = styled.div`
  color: ${colors.red};
  font-size: 14px;
`;

const FiltersWrapper = styled.div`
  margin: 20px 0;
`;

const DescriptionTableWrapper = styled.div``;
const DescriptionTableDate = styled.div``;

const columns = [
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
    render: (text: string, record: any) => {
      const textDescription = text && text.length > 100 ? `${text.substr(0, 100)}...` : text;

      let color = colors.green;

      if (record.operation === 'debit' || record.operation === 'expired') color = colors.red;

      if (record.operation === 'reserve') color = colors.yellow;

      return (
        <DescriptionTableWrapper>
          <div style={{ color }}>{textDescription}</div>
          <DescriptionTableDate>{formatDate(record.dateDescription, 'DD/MM/YYYY')}</DescriptionTableDate>
        </DescriptionTableWrapper>
      );
    },
  },
  {
    title: 'TIM Coins',
    dataIndex: 'dots',
    key: 'dots',
    render: (text: number, record: any) => {
      let color = colors.green;

      if (record.operation === 'debit' || record.operation === 'expired') color = colors.red;
      if (record.operation === 'reserve') color = colors.yellow;
      return <p style={{ color }}>{formatNumberToPtBr(text)}</p>;
    },
  },
  {
    title: 'Expirar em',
    dataIndex: 'expiresAt',
    key: 'expiresAt',
    render: (text: number) => <PontosExpirar>{formatDate(text, 'DD/MM/YYYY')}</PontosExpirar>,
  },
  {
    title: 'Número do pedido',
    dataIndex: 'requestNumber',
    key: 'requestNumber',
    render: (text: number) => <p>{text}</p>,
  },
];

export const InformacoesExtrato = () => {
  const [data, setData] = React.useState<any>([]);
  const [range, setRange] = React.useState<any>([]);
  const perPage = 10;
  const [page, setPage] = React.useState<number>(1);
  const UserCreditOperationPagination = useClient('UserCreditOperationPagination');
  const ReconheceUserBalance = useClient('ReconheceUserBalance', {
    fetchOnMount: true,
  });
  const total = ReconheceUserBalance.result?.balance || 0;

  useEffect(() => {
    let filter: any = {};

    if (range && range.length > 1) {
      filter = {
        range: {
          start: moment(range[0]).startOf('day').toISOString(),
          end: moment(range[1]).endOf('day').toISOString(),
        },
      };
    }

    UserCreditOperationPagination.fetch({
      variables: {
        page,
        perPage,
        filter,
      },
      appendToFragment,
    }).then((ctx) => {
      const parseToTable = ctx.result?.items?.map((item) => {
        let dots = item?.creditOriginCampaignData?.points;
        let dateDescription = item?.creditOriginCampaignData?.addedAt;

        if ((item?.op && item.op !== 'credit') || (item?.op && item.op !== 'debit')) {
          dots = item.value;
          dateDescription = item.createdAt;
        }

        return {
          description: item?.creditOriginCampaignData?.campaignTitle || getNameOp(item?.op || ''),
          dateDescription,
          operation: item?.op,
          dots,
          expiresAt: item?.creditOriginCampaignData?.expiresAt,
          requestNumber: item?.externalRequestNumber,
        };
      });

      setData(parseToTable);
    });
  }, [page, range]);

  function getNameOp(op: string) {
    switch (op) {
      case 'credit':
        return 'Crédito';
      case 'reserve':
        return 'Reserva';
      case 'debit':
        return 'Débito';
      case 'debit':
        return 'Débito';
      case 'release':
        return 'Liberação';
      case 'chargeback':
        return 'Estorno';
      case 'expired':
        return 'Expirado';
      default:
        return 'Operação';
    }
  }

  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb items={[{ title: 'Home', link: routes.home.mount() }, { title: 'Informações Extrato' }]} />

        <TitleDescription
          iconType={'solution'}
          title={'Informações do extrato'}
          description={'Todos os seus débitos e créditos realizados.'}
        />

        <InformacoesExtratoWrapper>
          <BoxSaldoDisponivel>
            <TituloSaldo>Saldo disponível</TituloSaldo>
            <ValorSaldo>{formatNumberToPtBr(total)}</ValorSaldo>
          </BoxSaldoDisponivel>

          <FiltersWrapper>
            <RangePicker
              getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
              format="DD/MM/YYYY"
              placeholder={['PERÍODO', '']}
              value={range}
              onChange={(e) => {
                setRange(e);
              }}
            />
          </FiltersWrapper>

          <Table
            loading={UserCreditOperationPagination.loading}
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: UserCreditOperationPagination.result?.count!,
              onChange: (e) => setPage(e),
            }}
          />
        </InformacoesExtratoWrapper>
      </React.Fragment>
    </PageTemplate>
  );
};

const appendToFragment = `
items {
  op
  creditOriginCampaignData {
    campaignTitle
    points
    addedAt
    expiresAt
  }
  value
  externalRequestNumber
  createdAt
}
`;
