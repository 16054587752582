import React from 'react';
import { useRouteMatch } from 'react-router';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';

import { Form } from '../FormManage';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { OnTubeCadastroWrapper } from '../Cadastrar/stylesCadastrar';
import { useMaterial } from '../../../hooks/materials/useMaterial';
import { routes } from '../../../config/routes';

const appendToFragment = `
    _id
    title
    description
    isLive
    categories {
      _id
      name
    }
    mainCategory { _id name }
    mainIdCategory
    validity { 
      notifyEmailsBeforeExpire 
      start
      end
    }
    isLive
    fileIds
    files {
      _id
      title
      kind
    }

    availableAtRegions
    availableAtChannels
    availableAtRoleGroups
  `;

export const OnTubeEditar = () => {
  const { params } = useRouteMatch();

  const OnTubeItem = useMaterial(params?.id, appendToFragment);

  return (
    <PageTemplate>
      <OnTubeCadastroWrapper>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo', link: '' },
            { title: 'OnTube' },
            { title: 'Listar', link: routes.OnTubeListar.mount() },
            { title: 'Editar' }
          ]}
        />
        <TitleDescription
          iconType="play-square"
          title="OnTube"
          description="Editar vídeo"
        />
        {OnTubeItem.result && (
          <ProviderSelecionarPublico>
            <Form initialValues={OnTubeItem.result} />
          </ProviderSelecionarPublico>
        )}
      </OnTubeCadastroWrapper>
    </PageTemplate>
  );
};
