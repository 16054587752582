"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var routeNames;

(function (routeNames) {
  routeNames["home"] = "home";
  routeNames["homePortal"] = "homePortal";
  routeNames["EmConstrucao"] = "EmConstrucao";
  routeNames["catInformativos"] = "catInformativos";
  routeNames["catProdinformativos"] = "catProdinformativos";
  routeNames["informativos"] = "informativos";
  routeNames["busca"] = "busca";
  routeNames["OnTube"] = "OnTube";
  routeNames["OnTubePlayer"] = "OnTubePlayer";
  routeNames["OnTubePlaylist"] = "OnTubePlaylist";
  routeNames["OnTubeListar"] = "OnTubeListar";
  routeNames["OnTubeCadastrar"] = "OnTubeCadastrar";
  routeNames["OnTubeEditar"] = "OnTubeEditar";
  routeNames["catProcessos"] = "catProcessos";
  routeNames["catProdProcessos"] = "catProdProcessos";
  routeNames["processos"] = "processos";
  routeNames["processosListar"] = "processosListar";
  routeNames["processosCadastrar"] = "processosCadastrar";
  routeNames["processosEditar"] = "processosEditar";
  routeNames["conteudoMaterial"] = "conteudoMaterial";
  routeNames["taNaMao"] = "taNaMao";
  routeNames["taNaMaoListar"] = "taNaMaoListar";
  routeNames["taNaMaoEditar"] = "taNaMaoEditar";
  routeNames["taNaMaoCadastrar"] = "taNaMaoCadastrar";
  routeNames["regional"] = "regional";
  routeNames["regionalListar"] = "regionalListar";
  routeNames["regionalEditar"] = "regionalEditar";
  routeNames["regionalCadastrar"] = "regionalCadastrar";
  routeNames["canal"] = "canal";
  routeNames["canalListar"] = "canalListar";
  routeNames["canalEditar"] = "canalEditar";
  routeNames["canalCadastrar"] = "canalCadastrar";
  routeNames["institucional"] = "institucional";
  routeNames["institucionalListar"] = "institucionalListar";
  routeNames["institucionalEditar"] = "institucionalEditar";
  routeNames["institucionalCadastrar"] = "institucionalCadastrar";
  routeNames["vejaMais"] = "vejaMais";
  routeNames["sistemasVendas"] = "sistemasVendas";
  routeNames["sistemasVendasListar"] = "sistemasVendasListar";
  routeNames["sistemasVendasEditar"] = "sistemasVendasEditar";
  routeNames["sistemasVendasCadastrar"] = "sistemasVendasCadastrar";
  routeNames["selectFilter"] = "selectFilter";
  routeNames["selectFilterPDV"] = "selectFilterPDV";
  routeNames["materiaisPDV"] = "materiaisPDV";
  routeNames["materiaisPDVListar"] = "materiaisPDVListar";
  routeNames["materiaisPDVEditar"] = "materiaisPDVEditar";
  routeNames["materiaisPDVCadastrar"] = "materiaisPDVCadastrar";
  routeNames["regulamento"] = "regulamento";
  routeNames["regulamentoListar"] = "regulamentoListar";
  routeNames["regulamentoEditar"] = "regulamentoEditar";
  routeNames["regulamentoCadastrar"] = "regulamentoCadastrar";
  routeNames["mapaDeOfertas"] = "mapaDeOfertas";
  routeNames["mapaDeOfertaListar"] = "mapaDeOfertaListar";
  routeNames["mapaDeOfertaEditar"] = "mapaDeOfertaEditar";
  routeNames["mapaDeOfertaCadastrar"] = "mapaDeOfertaCadastrar";
  routeNames["pdfViewer"] = "pdfViewer";
  routeNames["htmlViewer"] = "htmlViewer";
  routeNames["imageViewer"] = "imageViewer";
  routeNames["liveListar"] = "liveListar";
  routeNames["liveEditar"] = "liveEditar";
  routeNames["liveCadastrar"] = "liveCadastrar";
  routeNames["informativosListar"] = "informativosListar";
  routeNames["informativosEditar"] = "informativosEditar";
  routeNames["informativosDuplicar"] = "informativosDuplicar";
  routeNames["informativosCadastrar"] = "informativosCadastrar";
  routeNames["smTabelaPrecos"] = "smTabelaPrecos";
  routeNames["tabelaPrecoListar"] = "tabelaPrecoListar";
  routeNames["tabelaPrecoCadastrar"] = "tabelaPrecoCadastrar";
  routeNames["tabelaPrecoEditar"] = "tabelaPrecoEditar";
  routeNames["smComparadorAparelhos"] = "smComparadorAparelhos";
  routeNames["comparadorAparelhosListar"] = "comparadorAparelhosListar";
  routeNames["comparadorAparelhosEditar"] = "comparadorAparelhosEditar";
  routeNames["comparadorAparelhosCadastrar"] = "comparadorAparelhosCadastrar";
  routeNames["argumentacaoFDV"] = "argumentacaoFDV";
  routeNames["argumentacaoFDVListar"] = "argumentacaoFDVListar";
  routeNames["argumentacaoFDVEditar"] = "argumentacaoFDVEditar";
  routeNames["argumentacaoFDVCadastrar"] = "argumentacaoFDVCadastrar";
  routeNames["mapaPecas"] = "mapaPecas";
  routeNames["mapaPecasListar"] = "mapaPecasListar";
  routeNames["mapaPecasEditar"] = "mapaPecasEditar";
  routeNames["mapaPecasCadastrar"] = "mapaPecasCadastrar";
  routeNames["materiaisApoio"] = "materiaisApoio";
  routeNames["materiaisApoioListar"] = "materiaisApoioListar";
  routeNames["materiaisApoioEditar"] = "materiaisApoioEditar";
  routeNames["materiaisApoioCadastrar"] = "materiaisApoioCadastrar";
  routeNames["bannersListar"] = "bannersListar";
  routeNames["bannersCadastrar"] = "bannersCadastrar";
  routeNames["bannersEditar"] = "bannersEditar";
  routeNames["materiaisTimEmpresas"] = "materiaisTimEmpresas";
  routeNames["materiaisTimEmpresasListar"] = "materiaisTimEmpresasListar";
  routeNames["materiaisTimEmpresasEditar"] = "materiaisTimEmpresasEditar";
  routeNames["materiaisTimEmpresasCadastrar"] = "materiaisTimEmpresasCadastrar";
  routeNames["relatorios"] = "relatorios";
  routeNames["relatorioAceites"] = "relatorioAceites";
  routeNames["notificacoes"] = "notificacoes";
  routeNames["notificacoesListar"] = "notificacoesListar";
  routeNames["notificacoesCadastrar"] = "notificacoesCadastrar";
  routeNames["notificacoesEditar"] = "notificacoesEditar";
  routeNames["noticiasConteudo"] = "noticiasConteudo";
  routeNames["atividadesRecentes"] = "atividadesRecentes";
  routeNames["pastaFavoritos"] = "pastaFavoritos";
  routeNames["favoritos"] = "favoritos";
  routeNames["campanhasIncentivoExternas"] = "campanhasIncentivoExternas";
  routeNames["campanhasIncentivoExternasEditar"] = "campanhasIncentivoExternasEditar";
  routeNames["campanhasIncentivoVigentes"] = "campanhasIncentivoVigentes";
  routeNames["campanhasIncentivoVigentesGestor"] = "campanhasIncentivoVigentesGestor";
  routeNames["campanhasIncentivoEncerradas"] = "campanhasIncentivoEncerradas";
  routeNames["campanhasIncentivoVigente"] = "campanhasIncentivoVigente";
  routeNames["campanhasIncentivoVigenteRegulamento"] = "campanhasIncentivoVigenteRegulamento";
  routeNames["campanhasIncentivoVigenteOld"] = "campanhasIncentivoVigenteOld";
  routeNames["campanhasIncentivoEncerrada"] = "campanhasIncentivoEncerrada";
  routeNames["campanhasIncentivoListar"] = "campanhasIncentivoListar";
  routeNames["campanhasIncentivoVisualizar"] = "campanhasIncentivoVisualizar";
  routeNames["campanhasIncentivoListarMetas"] = "campanhasIncentivoListarMetas";
  routeNames["campanhasIncentivoEditar"] = "campanhasIncentivoEditar";
  routeNames["campanhasIncentivoCadastrar"] = "campanhasIncentivoCadastrar";
  routeNames["campanhasIncentivoUploadPontos"] = "campanhasIncentivoUploadPontos";
  routeNames["contestacaoListar"] = "contestacaoListar";
  routeNames["contestacaoEditar"] = "contestacaoEditar";
  routeNames["notificacaoBackupListar"] = "notificacaoBackupListar";
  routeNames["notificacaoBackupEditar"] = "notificacaoBackupEditar";
  routeNames["notificacaoBackupCadastrar"] = "notificacaoBackupCadastrar";
  routeNames["informacoesExtrato"] = "informacoesExtrato";
  routeNames["ordemPagamentoListar"] = "ordemPagamentoListar";
  routeNames["ordemPagamentoCadastrar"] = "ordemPagamentoCadastrar";
  routeNames["ordemPagamentoEditar"] = "ordemPagamentoEditar";
  routeNames["visualizarArquivosCampanha"] = "visualizarArquivosCampanha";
  routeNames["cadastrarArquivosCampanha"] = "cadastrarArquivosCampanha";
  routeNames["editarArquivosCampanha"] = "editarArquivosCampanha";
  routeNames["downloadBases"] = "downloadBases";
  routeNames["importPlanUsers"] = "importPlanUsers";
  routeNames["distribuicaoPontos"] = "distribuicaoPontos";
  routeNames["downloadIncentivo"] = "downloadIncentivo";
  routeNames["gerenciarPermissoesListar"] = "gerenciarPermissoesListar";
  routeNames["gerenciarPermissoesEditar"] = "gerenciarPermissoesEditar";
  routeNames["listUserBlock"] = "listUserBlock";
  routeNames["regionais"] = "regionais";
  routeNames["mapaDeProcessos"] = "mapaDeProcessos";
  routeNames["mapaDeProcessosListar"] = "mapaDeProcessosListar";
  routeNames["mapaDeProcessosEditar"] = "mapaDeProcessosEditar";
  routeNames["mapaDeProcessosCadastrar"] = "mapaDeProcessosCadastrar";
  routeNames["sincronizarGu"] = "sincronizarGu";
  routeNames["sincronizarCargosGu"] = "sincronizarCargosGu";
  routeNames["gameficationList"] = "gameficationList";
  routeNames["gameficationEdit"] = "gameficationEdit";
  routeNames["gameficationCreate"] = "gameficationCreate";
  routeNames["gameficationNivelCreate"] = "gameficationNivelCreate";
  routeNames["gameficationNivelEdit"] = "gameficationNivelEdit";
  routeNames["gameficationNivelList"] = "gameficationNivelList";
  routeNames["gameficationNivelParticipant"] = "gameficationNivelParticipant";
  routeNames["gameficationNivelCustomCreate"] = "gameficationNivelCustomCreate";
  routeNames["gameficationNivelCustomEdit"] = "gameficationNivelCustomEdit";
  routeNames["gameficationNivelCustomList"] = "gameficationNivelCustomList";
  routeNames["gamefication"] = "gamefication";
  routeNames["gameficationResearchesList"] = "gameficationResearchesList";
  routeNames["gameficationResearchesAnswersList"] = "gameficationResearchesAnswersList";
  routeNames["gameficationQuizList"] = "gameficationQuizList";
  routeNames["gameficationQuizAnswersList"] = "gameficationQuizAnswersList";
  routeNames["gameficationMissions"] = "gameficationMissions";
  routeNames["gameficationVerificationMissions"] = "gameficationVerificationMissions";
  routeNames["gameficationRankingCreate"] = "gameficationRankingCreate";
  routeNames["gameficationRankingEdit"] = "gameficationRankingEdit";
  routeNames["gameficationRankingList"] = "gameficationRankingList";
  routeNames["gameficationRankingParticipant"] = "gameficationRankingParticipant";
  routeNames["gameficationRankingAdmin"] = "gameficationRankingAdmin";
  routeNames["gameficationChallengePoints"] = "gameficationChallengePoints";
  routeNames["timTech"] = "timTech";
  routeNames["timTechInfo"] = "timTechInfo";
  routeNames["timTechPrices"] = "timTechPrices";
  routeNames["timTechVideos"] = "timTechVideos";
  routeNames["timTechProccess"] = "timTechProccess";
  routeNames["timTechMaterialDeApoio"] = "timTechMaterialDeApoio";
  routeNames["adminMapaOfertas"] = "adminMapaOfertas";
  routeNames["comparaAi"] = "comparaAi";
  routeNames["comparaAiListar"] = "comparaAiListar";
  routeNames["comparaAiEditar"] = "comparaAiEditar";
  routeNames["comparaAiCadastrar"] = "comparaAiCadastrar";
  routeNames["jornada"] = "jornada";
  routeNames["jornadaInfo"] = "jornadaInfo";
  routeNames["jornadaVideos"] = "jornadaVideos";
  routeNames["jornadaMaterialDeApoio"] = "jornadaMaterialDeApoio";
  routeNames["faturaListar"] = "faturaListar";
})(routeNames = exports.routeNames || (exports.routeNames = {}));

exports.default = routeNames;