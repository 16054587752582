import React, { useState, useEffect, useCallback } from 'react';
import { Select, Input, DatePicker, Pagination } from 'antd';
import styled from 'styled-components';
import { Breadcrumb, TitleDescription, MiniCard } from '@digi-tim-19/components';
import moment from 'moment';
import { useParams, useHistory } from 'react-router';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';
import { EnumMaterialKind, ValidityEnum, SortFindManyMaterialInput } from '../../autogenerated/client/types';
import { actionsButton } from '../../utils/material';
import { findCorrectName, findRedirect } from '../../utils/productNames';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';
import { PaginationWrapper } from '../../components/Page/PaginationWrapper';
import { getRouterByMaterial } from '@digi-tim-19/utils/build/routes/routesConsumer';
import { escapeStringRegexp } from '../../utils/formatters';

const { Search: AntdSearch } = Input;
const { RangePicker } = DatePicker;

const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;

  .ant-calendar-range-picker-separator {
    display: none !important;
  }
  @media only screen and (max-width: 900px) {
    .ant-calendar-picker-container {
      width: 100%;
      padding: 0 15px;
    }
    .ant-calendar-range {
      width: 100% !important;
    }
    .ant-calendar-date-panel {
      display: flex !important;
      flex-direction: column !important;
      .ant-calendar-range-part {
        width: 100%;
      }
    }
  }
`;
export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;

  cursor: pointer;
`;
export const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;
const StyledContent = styled.section`
  background: transparent;
  .actions {
    display: block;
    & > * {
      width: 100%;
      margin-left: 0;
      margin-bottom: 5px;
    }
    @media screen and (min-width: 1024px) {
      display: flex;
      align-items: center;
      & > * {
        margin-right: 20px;
      }
    }
  }
  .cards {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
  }
`;

export const MateriaisApoio = () => {
  const [createdAt, setCreatedAt] = useState<{ start: string; end: string }>();
  const [show, setShow] = useState([]);
  const [filterSearch, setFilterSearch] = useState();
  const [filterSelectInitial, setFilterSelectInitial] = useState('Status');

  const [plans, setPlans] = useState<any[]>([]);
  const [planSelected, setPlanSelected] = useState('all');
  const [sortSelected, setSortSelected] = useState(SortFindManyMaterialInput.CreatedatDesc);

  const { Option } = Select;
  const { product, category } = useParams<{
    product: string;
    category: string;
  }>();
  const history = useHistory();

  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(1);
  const perPage = 10;

  const categoryPage = `menu:${category || ''}:${product || ''}`;

  const getCategories = useClient('CategoryFindOne');
  useEffect(() => {
    if (category && product) {
      getCategories
        .fetch({
          variables: { filter: { _id: categoryPage } },
          appendToFragment: ` subCategories (sort:SORT_ASC) { _id name }`,
        })
        .then((ctx) => {
          if (ctx.result) {
            const subCategories = ctx.result?.subCategories || [];

            setPlans(
              subCategories.map((item) => ({
                label: item?.name,
                value: item?._id,
              })),
            );
          }
        });
    }
  }, [category, product]);

  const [validityEnumFilter, setValidityEnumFilter] = useState<string>();
  const filterSelected = (v: string) => {
    if (v === 'active') {
      setValidityEnumFilter(ValidityEnum.Current);
      setFilterSelectInitial('Ativo');
    }
    if (v === 'inactive') {
      setValidityEnumFilter(ValidityEnum.Expired);
      setFilterSelectInitial('Inativo');
    }
    if (v === 'all') {
      setValidityEnumFilter(ValidityEnum.Any);
      setFilterSelectInitial('Todos');
    }
  };

  const getMaterials = useClient('MaterialPagination');

  const total = getMaterials.result?.pageInfo.itemCount;

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          sort: sortSelected,
          page: page,
          perPage: perPage,
          filter: {
            kind: EnumMaterialKind.SupportMaterials,
            validityEnum: validityEnumFilter as ValidityEnum,
            allCategories: [categoryPage, planSelected === 'all' ? '' : planSelected].filter(Boolean),
            createdAtRange: createdAt,
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
          },
        },
      })
      .then((response: any) => {
        setItems(response && response.result ? response.result.items : []);
      });
  }, [category, product, validityEnumFilter, createdAt, planSelected, sortSelected, page, filterSearch]); //eslint-disable-line

  const resetFilters = () => {
    setCreatedAt(undefined);
    setFilterSearch(undefined);
    setValidityEnumFilter(ValidityEnum.Any);
    setFilterSelectInitial('all');
    setShow([]);
    setPlanSelected('all');
    setPage(1);
    setSortSelected(SortFindManyMaterialInput.CreatedatDesc);
  };

  const setDescription = () => {
    switch (product) {
      case 'solucoes':
        return `Acesse os materiais de apoio dos produtos de soluções.`;
      case 'fixo':
        return `Acesse os materiais de apoio dos produtos do Fixo.`;
      case 'servicos':
        return `Acesse os materiais de apoio dos serviços de valor agregado (VAS).`;
      case 'voz':
      case 'internet':
        return `Acesse os materiais de apoio dos produtos de internet.`;
      default:
        return `Acesse os materiais de apoio dos ${product ? findCorrectName(product) : ''} de valor agregado (VAS)`;
    }
  };

  const data = getMaterials?.result;

  const { listFolders, addFolder } = useFoldersPin();

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
    },
    [data],
  );

  const onChange = (e: any) => {
    setCreatedAt({
      start: moment(e[0]!)
        .startOf('day')
        ?.toISOString(),
      end: moment(e[1]!)
        .endOf('day')
        ?.toISOString(),
    });
    setShow(e);
  };

  const getRedirectUrl = (category: string, product: string) => {
    const routeRedirect = findRedirect(product);

    if (routeRedirect) return routeRedirect;

    if (category && product) return routes.selectFilter.mount(category, product);

    return '';
  };

  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: category ? findCorrectName(category) : '' },
            {
              title: product ? findCorrectName(product) : '',
              link: getRedirectUrl(category, product),
            },
            { title: 'Materiais de Apoio' },
          ]}
        />

        <TitleDescription iconType={'book'} title={'Materiais de Apoio'} description={setDescription()} />
        <StyledContent>
          <FilterContainer>
            <Select
              defaultValue="Status"
              value={filterSelectInitial}
              onChange={(value: string) => {
                setPage(1);
                filterSelected(value);
              }}
            >
              <Option value="active">Ativo</Option>
              <Option value="inactive">Inativo</Option>
              <Option value="all">Todos</Option>
            </Select>

            <Select
              placeholder="Ordenar"
              value={sortSelected}
              onChange={(value: any) => {
                setPage(1);
                setSortSelected(value);
              }}
            >
              <Option value={SortFindManyMaterialInput.CreatedatDesc}>Mais recentes</Option>
              <Option value={SortFindManyMaterialInput.CreatedatAsc}>Mais antigos</Option>
            </Select>

            <RangePicker
              getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
              format="DD/MM/YYYY"
              placeholder={['PERÍODO', '']}
              onChange={(e) => {
                setPage(1);
                onChange(e);
              }}
              value={show}
            />
            <Search
              placeholder="Busca por título"
              style={{ width: 200 }}
              value={filterSearch}
              onChange={(e) => {
                setPage(1);
                onSearch(e.target.value);
              }}
            />

            <Select
              placeholder="Planos"
              value={planSelected}
              onChange={(value: string) => {
                setPage(1);
                setPlanSelected(value);
              }}
            >
              <Option value="all">Todos</Option>
              {plans.map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>

            <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
          </FilterContainer>

          <div className="cards">
            {items.map((item: any, index: number) => {
              const [file] = item?.files;
              item.files[0].downloadUrl = file.signedUrl;

              const hideAccessButton = file?.extension !== 'pdf';

              const routesMaterial = getRouterByMaterial(item, routes);

              return (
                <MiniCard
                  key={index}
                  material={item}
                  btn={() => history.push(routesMaterial.routerAccess)}
                  actions={() => actionsButton(listFolders, addFolder)}
                  hideAccessButton={hideAccessButton}
                />
              );
            })}
          </div>

          <PaginationWrapper>
            <Pagination pageSize={perPage} current={page} total={total!} onChange={(page) => setPage(page)} />
          </PaginationWrapper>
        </StyledContent>
      </React.Fragment>
    </PageTemplate>
  );
};

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    pinned
    title
    numericId
    createdAt
    updatedAt
    fileIds
    files {
      _id
      extension
      kind
      signedUrl
      downloadUrl
    }
    categories {
      _id
      name
    }
    validity { 
      start 
      end 
    } 
    author { 
      _id, 
      name 
    }
  }
`;
