import React, { useState } from 'react';
import styled from 'styled-components';
import { SubHeader } from '@digi-tim-19/components';

import { MainHeader } from './MainHeader';
import { MainMenu } from '../../components/Layout/MainMenu';
import { SiteFooter } from '../../components/Layout/SiteFooter';
import { siteImages } from '../../assets/images';

const StyledContent = styled.div`
  @media screen and (min-width: 768px) {
    padding-left: 80px;
  }
`;

export const PageTemplateNoMargin = ({
  children
}: {
  children: React.ReactChild;
}) => {
  const [menuIsOpen, toggleMenu] = useState(false);
  return (
    <>
      <SubHeader logoURL={siteImages.logoTim} />
      <MainHeader menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
      <MainMenu menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
      <StyledContent>{children}</StyledContent>
      <SiteFooter />
    </>
  );
};
