import React, { useState, useEffect } from 'react';

import {
  Former,
  SelecionarPublico,
  usePublico,
  SearchUser,
  LoadingIndicator
} from '@digi-tim-19/components';

import {
  EnumFileKind,
  EnumMaterialKind,
  SortFindManyCategoryInput
} from '../../autogenerated/client/types';
import { message } from 'antd';
import { routes } from '../../config/routes';
import { useHistory } from 'react-router';
import { Container } from './Cadastrar/stylesCadastrar';
import { useClient } from '../../autogenerated/client/client';
import { Material } from '../../autogenerated/client/types';
import { useDimensions } from '@digi-tim-19/utils/build';
import { useContentTypePermissions } from '../../hooks/useContentTypePermissions';
import { selectedChannels } from '../../utils/selectedChannels';
const selectedChannelsConsumer = selectedChannels();

const DocKind = EnumFileKind.AnyDoc;

export const Form = (props: TFormMaterialEditProps) => {
  const permissions = useContentTypePermissions();
  const {
    availableAtChannels,
    availableAtRegions = [],
    availableAtRoleGroups = []
  } = usePublico();
  const history = useHistory();

  const isUpdate = !!props.initialValues;

  const initialValues = {
    ...props.initialValues,
    documento: (props.initialValues?.files || []).find(
      (el) => el && el.kind === DocKind
    )?._id,
    category: (props.initialValues?.categories || []).find((el) => el && el._id)
      ?._id,
    notifyEmailsBeforeExpire:
      props.initialValues?.validity?.notifyEmailsBeforeExpire
  };

  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const createMaterial = useClient('MaterialCreateOne');

  const [categories, setCategories] = useState<any>([]);

  const getCategoriesCardapioSMB = useClient('CategoryFindMany', {
    fetchOnMount: true,
    variables: {
      limit: 123,
      filter: {
        parentId: 'menu:cardapio_smb:para_o_cliente:materiais_tim_empresas'
      },
      sort: SortFindManyCategoryInput.SortAsc
    }
  });

  useEffect(() => {
    if (getCategoriesCardapioSMB.result) {
      setCategories(
        getCategoriesCardapioSMB.result.map((item) => ({
          label: item?.name,
          value: item?._id
        }))
      );
    }
  }, [getCategoriesCardapioSMB.result]);

  const { isMobile } = useDimensions();

  if (permissions.loading) return <LoadingIndicator />;

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              inline: !isMobile,
              list: [
                {
                  name: 'validity',
                  label: 'Data de Vigência',
                  type: 'rangePicker',
                  required: true,
                  extraProps: {
                    format: 'DD/MM/YYYY'
                  },
                  maxWidth: '250px'
                },
                {
                  name: 'documento',
                  label: '',
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: DocKind,
                    CTA: 'IMPORTAR MATERIAL'
                  }
                }
              ]
            },
            {
              custom: <div style={{ width: '100%', margin: '1px 0' }}></div>
            },
            {
              list: [
                {
                  name: 'category',
                  label: 'Categoria',
                  required: true,
                  options: categories
                }
              ]
            },
            {
              list: [
                {
                  name: 'title',
                  label: 'TÍTULO',
                  required: true,
                  validate: (value: any) => {
                    const format = /[#%&;]/;
                    if (format.test(value))
                      return 'Os caracteres #%&; não são permitidos';

                    if (value)
                      return !(value.length > 110)
                        ? undefined
                        : 'Máximo de 110 caracteres';

                    return undefined;
                  }
                },
                {
                  name: 'description',
                  label: 'DESCRIÇÃO',
                  type: 'textarea',
                  extraProps: {
                    rows: 5
                  },
                  validate: (value: any) => {
                    const format = /[#%&]/;
                    if (format.test(value))
                      return 'Os caracteres #%& não são permitidos';

                    if (value)
                      return !(value.length > 1200)
                        ? undefined
                        : 'Máximo de 1200 caracteres';

                    return undefined;
                  }
                }
              ]
            },
            {
              name: 'tags',
              label: 'tags',
              type: 'tags'
            },
            {
              name: 'notifyEmailsBeforeExpire',
              label: 'Notificar antes de expirar',
              placeholder: 'Notificar antes de expirar',
              extraProps: {
                returnProp: 'email'
              },
              component: SearchUser
            },
            {
              className: 'Footer',
              inline: true,
              list: [
                {
                  custom: (
                    <SelecionarPublico
                      initialValues={props.initialValues}
                      selectedChannels={
                        !isUpdate ? selectedChannelsConsumer : []
                      }
                      {...permissions.filterOptions}
                    />
                  )
                }
              ]
            }
          ],
          submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(routes.materiaisTimEmpresasListar.path)
          }
        }}
        onSubmit={async function ({ data }) {
          const record = {
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            kind: EnumMaterialKind.CompaniesMaterials,
            validity: {
              start: data.validity[0],
              end: data.validity[1],
              notifyEmailsBeforeExpire: data.notifyEmailsBeforeExpire
            },
            categories: [data.category],
            fileIds: [data.documento].filter(Boolean),
            title: data.title,
            description: data.description,
            tags: data.tags
          };

          if (isUpdate && data._id) {
            await MaterialUpdateOne.fetch({
              variables: { record, filter: { _id: data._id } }
            }).then((ctx) => {
              if (!ctx.errors) {
                history.push(routes.materiaisTimEmpresasListar.mount());
              }
            });
          } else {
            await createMaterial
              .fetch({
                variables: { record }
              })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success('Material cadastrado com sucesso');
                  history.push(routes.materiaisTimEmpresasListar.path);
                } else {
                  message.error('Material não cadastrado');
                }
              });
          }
        }}
      />
    </Container>
  );
};

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
};
