import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select,
  LoadingIndicator
} from '@digi-tim-19/components';
import { message, DatePicker } from 'antd';
import { useHistory } from 'react-router';
import { columns } from './columns';
import { formatDate } from '@digi-tim-19/utils/build';
import { useClient } from '../../../autogenerated/client/client';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { SortFindManyEventLiveInput } from '../../../autogenerated/client/types';
import moment from 'moment';

import {
  NotificationsWrapper,
  FilterContainer,
  Button,
  CleanButton
} from './stylesListar';
import { routes } from '../../../config/routes';
import { useFilterAuthor } from '../../../components/FilterAuthor/useFilterAuthor';
const { RangePicker } = DatePicker;

export const LiveListar: React.FC<TLiveListarProps> = () => {
  const filterAuthor = useFilterAuthor();
  const [sortFilter, setSortFilter] = useState(
    SortFindManyEventLiveInput.IdDesc
  );

  const history = useHistory();

  const [updatedAt, setUpdatedAt] = useState();
  const [showUpdatedAt, setShowUpdatedAt] = useState([]);

  const [createdAt, setCreatedAt] = useState();
  const [show, setShow] = useState([]);

  const MaterialDeleteOne = useClient('EventLiveRemoveById');

  const getLives = useClient('EventLivePagination', {
    appendToFragment: `items { title, description, _id, eventDate, status, createdAt, updatedAt, videoUrl, user { name }
    changedBy {
      name
      dateText
    },} `
  });

  useEffect(() => {
    getLives.fetch({
      variables: {
        page: 1,
        perPage: 20,
        sort: sortFilter,
        filter: {
          createdAtRange: createdAt,
          updatedAtRange: updatedAt,
          filterAuthor: filterAuthor.filter,
          owner: filterAuthor.ownerFilter
        }
      }
    });
  }, [sortFilter, filterAuthor.signature, createdAt, updatedAt]); //eslint-disable-line

  const resetFilters = () => {
    setSortFilter(SortFindManyEventLiveInput.IdDesc);
    filterAuthor.reset();

    setUpdatedAt(undefined);
    setShowUpdatedAt([]);

    setCreatedAt(undefined);
    setShow([]);
  };

  const data = getLives.result?.items?.map((material) => ({
    changedBy: material?.changedBy,
    id: material?._id,
    title: material?.title,
    cadastrada: formatDate(material?.createdAt, 'DD/MM/YYYY'),
    atualizada: formatDate(material?.updatedAt, 'DD/MM/YYYY'),
    dataEvento: formatDate(material?.eventDate, 'DD/MM/YYYY HH:mm'),
    status: material?.status,
    author: material?.user?.name,
    onRead: () => {
      history.push(routes.OnTubePlayer.mount(material?._id));
    },
    onEdit: () => history.push(routes.liveEditar.mount(material?._id)),
    onDelete: () =>
      MaterialDeleteOne.fetch({
        variables: { _id: material?._id },
        afterMutate: /^EventLive/
      }).then((ctx) => {
        message.success('Live deletada com sucesso');
      }),
    eventTime: material?.eventDate
  }));

  const onChange = (e: any) => {
    if (e.length > 0) {
      setCreatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setShow(e);
    } else {
      setShow([]);
      setCreatedAt(undefined);
    }
  };

  const onChangeUpdatedAt = (e: any) => {
    if (e.length > 0) {
      setUpdatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setShowUpdatedAt(e);
    } else {
      setShowUpdatedAt([]);
      setUpdatedAt(undefined);
    }
  };

  return (
    <PageTemplate>
      <>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Live' },
              { title: 'Listar' }
            ]}
          />
          <TitleDescription
            iconType="environment"
            title="Live"
            description="Listar e consultar as Lives criadas"
          />
        </NotificationsWrapper>
        <FilterContainer>
          <Button to={routes.liveCadastrar.path}>CADASTRAR LIVE</Button>
          <Select
            placeholder="Ordenar por:"
            options={order}
            onChange={(e) => setSortFilter(e)}
            value={sortFilter}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={'DD/MM/YYYY'}
            placeholder={['CADASTRO', '']}
            onChange={(e) => {
              onChange(e);
            }}
            value={show}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={'DD/MM/YYYY'}
            placeholder={['ATUALIZAÇÃO', '']}
            onChange={(e) => {
              onChangeUpdatedAt(e);
            }}
            value={showUpdatedAt}
          />
          {filterAuthor.render()}

          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>
        {!data ? (
          <LoadingIndicator />
        ) : (
          <Table columns={columns} dataSource={data} />
        )}
      </>
    </PageTemplate>
  );
};

export type TLiveListarProps = {};

const order = [
  { label: 'MAIS ANTIGO', value: SortFindManyEventLiveInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyEventLiveInput.IdDesc }
];
