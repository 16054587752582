import React, { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import { Input, Modal } from 'antd';
import { IncentiveCampaign } from '../../autogenerated/client/types';
import { useClient } from '../../autogenerated/client/client';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  CampanhaVigenteImage,
  CampanhaTitleWrapper,
  CampanhaButtonsContainer,
  TableContainer,
} from './stylesCampanhaVigente';
import { useDimensions } from '@digi-tim-19/utils';
import styled from 'styled-components';
import { Table, Wrapper } from '@digi-tim-19/components';

moment.locale('pt-BR');

interface Props {
  campaign: IncentiveCampaign;
  setDetailData: Dispatch<SetStateAction<any>>;
}

export const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 25px;
`;

export const CampanhaVigenteManager = ({ campaign, setDetailData }: Props) => {
  const divRef = useRef<HTMLElement>();
  const [page, setPage] = useState<number>(1);
  const [filterParticipant, setFilterParticipant] = useState<string>();
  const [showDataModal, setShowDataModal] = useState<boolean>(false);
  const { isMobile } = useDimensions();
  const clientDetails = useClient('CampaignGetDetails');
  const getUserCampaignDetais = (userId: string) =>
    clientDetails.fetch({
      variables: { campaignId: campaign._id, userId },
    });

  const columns = [
    {
      title: 'NOME',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => {
        return <p>{isMobile && text.length > 15 ? `${String(text).substring(0, 15)}...` : text}</p>;
      },
    },
    {
      title: 'CARGO',
      dataIndex: 'roleGroup._id',
      key: 'roleGroup',
      render: (text: string) => {
        return (
          <p>
            {text
              .split(':')
              .pop()
              ?.replace(/_/g, ' ')}
          </p>
        );
      },
    },
    ...(isMobile
      ? []
      : [
          {
            title: 'REGIONAL',
            dataIndex: 'region',
            key: 'region',
            render: (text: string) => {
              return <p>{text}</p>;
            },
          },
          {
            title: 'CANAL',
            dataIndex: 'roleGroup._id',
            key: 'channel',
            render: (text: string) => {
              return (
                <p>
                  {text
                    .split(':')
                    .shift()
                    ?.replace(/_/g, ' ')}
                </p>
              );
            },
          },
        ]),
    {
      title: '',
      dataIndex: 'details',
      key: 'details',
      render: (_text: string, context: any) => {
        return (
          <div
            style={{
              color: '#16d523',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              marginRight: '10px',
            }}
            onClick={() => {
              getUserCampaignDetais(context._id).then((ctx) => {
                if (ctx.result?.length) {
                  setDetailData({ ...context, ...ctx.result });
                } else {
                  setShowDataModal(true);
                }
              });
            }}
          >
            Detalhar
          </div>
        );
      },
    },
  ];

  const filterParticipantes = useMemo(
    () => campaign?.participants.filter((p) => new RegExp(filterParticipant as string, 'gim').test(JSON.stringify(p))),
    [filterParticipant],
  );

  return (
    <>
      <Modal
        title="Sem dados"
        visible={!campaign.pendingAccept && !campaign.hasData && showDataModal}
        okText="Ok"
        onOk={() => setShowDataModal(false)}
        cancelButtonProps={{ hidden: true }}
      >
        <p>Sem dados atualizados no momento, aguarde a próxima atualização.</p>
      </Modal>

      <CampanhaButtonsContainer>
        <CampanhaTitleWrapper ref={divRef as any}>{campaign.title}</CampanhaTitleWrapper>
      </CampanhaButtonsContainer>

      <TableContainer>
        <Wrapper>
          <Input
            placeholder="Pesquisar"
            style={{ maxWidth: 250 }}
            value={filterParticipant}
            onChange={(event) => setFilterParticipant(event.target.value)}
          />
        </Wrapper>

        <Table
          columns={columns}
          dataSource={filterParticipantes}
          pagination={{
            pageSize: 10,
            current: page,
            total: filterParticipantes?.length,
            onChange: (e) => {
              setPage(e);
              divRef.current?.scrollIntoView({ behavior: 'smooth' });
            },
          }}
        />
      </TableContainer>

      <p>{`Atualizado em ${moment(campaign?.resultData?.[0]?.updatedAt).format('DD/MM/YYYY')}`}</p>

      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        {campaign.bannerContentFile?.signedUrl && (
          <CampanhaVigenteImage>
            <img src={campaign?.bannerContentFile?.signedUrl || ''} alt="thumb" />
          </CampanhaVigenteImage>
        )}
      </div>
    </>
  );
};
