import * as React from 'react';
import styled from 'styled-components';

import {
  PriceTable,
  Breadcrumb,
  TitleDescription,
  MaterialSample,
  LoadingIndicator
} from '@digi-tim-19/components';
import { routes } from '../../config/routes';
import { Checkbox, DatePicker, Pagination } from 'antd';
import moment from 'moment';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { actionsButton } from '../../utils/material';

import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyMaterialInput,
  EnumFolderKind
} from '../../autogenerated/client/types';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';
import { PaginationWrapper } from '../../components/Page/PaginationWrapper';
import { useHistory } from 'react-router';

const { RangePicker } = DatePicker;

const ConsumerContent = styled.section`
  .actions {
    & .action-controls {
      margin-top: 20px;
      .ant-checkbox-wrapper {
        margin-left: 0px;
        margin-bottom: 5px;
        .ant-checkbox + span {
          font-size: 16px;
          font-family: TimMedium;
          color: ${(props) => props.theme.grayMedium2};
        }
      }
      @media only screen and (max-width: 900px) {
        .ant-calendar-picker-container {
          width: 100%;
          padding: 0 15px;
        }
        .ant-calendar-range {
          width: 100% !important;
        }
        .ant-calendar-date-panel {
          display: flex !important;
          flex-direction: column !important;
          .ant-calendar-range-part {
            width: 100%;
          }
        }
      }
    }
  }
`;

type TCreatedAt = {
  start?: string;
  end?: string;
};

export const TabelaPrecos = () => {
  const [activeTables, setActiveTables] = React.useState(true);
  const [inactiveTables, setInactiveTables] = React.useState(false);
  const [createdAt, setCreatedAt] = React.useState<TCreatedAt>();
  const [show, setShow] = React.useState<any[]>([]);
  let total;
  const [page, setPage] = React.useState(1);
  const perPage = 20;
  const [currentValidity, setCurrentValidity] = React.useState(
    ValidityEnum.Current
  );
  const { past, current, loading } = usePriceTableData(createdAt);
  const history = useHistory();

  function handleValidity(validity: string) {
    if (validity === 'current') {
      setCurrentValidity(ValidityEnum.Current);
      setActiveTables(true);
      setInactiveTables(false);
    }

    if (validity === 'past') {
      setCurrentValidity(ValidityEnum.Expired);
      setActiveTables(false);
      setInactiveTables(true);
    }
  }

  function usePriceTableData(createdAt: TCreatedAt | undefined) {
    const variables = {
      page,
      perPage: perPage,
      filter: {
        kind: EnumMaterialKind.PriceTable,
        validityEnum: currentValidity,
        createdAtRange: createdAt
      },
      sort: SortFindManyMaterialInput.CreatedatDesc
    };

    const appendToFragment = `
    pageInfo {
      perPage
      hasNextPage
      hasPreviousPage
      itemCount
      pageCount
    }
    
    items {
      validityEnum
      isDraft
      description
      kind
      tags
      title
      fileIds
      _id
      createdAt
      numericId
      pinned
      validity {
        start
        end
      }
      
      files {
        signedUrl
        downloadUrl
        _id
        title
      }
    }`;

    const getMaterial = useClient('MaterialPagination', {
      variables,
      fetchOnMount: true,
      appendToFragment
    });
    total = getMaterial.result?.pageInfo.itemCount;
    React.useEffect(() => {
      getMaterial.fetch({
        variables,
        appendToFragment
      });
    }, [createdAt, page, currentValidity]);

    const { result, loading } = getMaterial;

    //
    const past = React.useMemo(() => {
      let items: MaterialSample[] = [];
      result?.items?.forEach((el: any) => {
        const is = el && el?.validityEnum !== ValidityEnum.Current;
        if (is && el) {
          items.push(el);
        }
      });

      return items;
    }, [result]); //eslint-disable-line

    //
    const current = React.useMemo(() => {
      let items: MaterialSample[] = [];

      result?.items?.forEach((el: any) => {
        const is = el && el?.validityEnum === ValidityEnum.Current;
        if (is && el) {
          items.push(el);
        }
      });

      return items;
    }, [result]); //eslint-disable-line

    return { past, current, loading };
  }

  const onChangeRangePicker = (e: any[]) => {
    if (e.length === 0) setCreatedAt(undefined);
    else {
      setCreatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
    }
    setShow(e);
  };

  const { listFolders, addFolder } = useFoldersPin();

  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Tim Tech', link: routes.timTech.path },
            { title: 'Tabela de preços' }
          ]}
        />

        <TitleDescription
          iconType={'mobile'}
          title={'Tabela de Preços'}
          description={'Acesse as tabelas de preços vigentes e anteriores.'}
        />

        <ConsumerContent>
          <div className="actions">
            <div className="action-controls">
              <Checkbox
                onChange={() => handleValidity('current')}
                checked={activeTables}
              >
                Tabelas Vigentes
              </Checkbox>
              <Checkbox
                onChange={() => handleValidity('past')}
                checked={inactiveTables}
              >
                Tabelas Anteriores
              </Checkbox>
              <RangePicker
                getCalendarContainer={(triggerNode: any) =>
                  triggerNode.parentNode
                }
                format="DD/MM/YYYY"
                placeholder={['PERÍODO', '']}
                onChange={(e) => onChangeRangePicker(e)}
                value={show}
              />
            </div>
          </div>

          {loading && <LoadingIndicator />}
          {!loading && activeTables && (
            <PriceTable
              items={current}
              actions={({ _id, files }) => ({
                ...actionsButton(listFolders, addFolder),
                download: true,
                accessButton: (files && `${files[0]?.title}`.includes('.pdf')) ? {
                  action: () => {
                    if (files) {
                      history.push(routes.pdfViewer.mount(`${_id}`))
                    }
                  },
                  label: 'Acessar',
                }: undefined
              })}
            />
          )}

          {!loading && inactiveTables && (
            <PriceTable
              title="Tabelas Anteriores"
              items={past}
              actions={() => actionsButton(listFolders, addFolder)}
            />
          )}
          {total && (
            <PaginationWrapper>
              <Pagination
                pageSize={perPage}
                current={page}
                total={total ? total : 0}
                onChange={(page) => setPage(page)}
              />
            </PaginationWrapper>
          )}
        </ConsumerContent>
      </React.Fragment>
    </PageTemplate>
  );
};
