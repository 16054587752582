import { useState, useEffect } from 'react';
import { TUserType } from '@digi-tim-19/components';
import { useUserElegibilities } from '../../hooks/incentiveCampaign/useIncentiveCampaign';
import {
  FileContentCampaignFileStructureEligibilities,
  EnumIncentiveCampaignCampaignType,
} from '@digi-tim-19/components/build/main/DataProviders/types';

export function useCampanhaVigente(incentiveCampaignId: string, fileContentCampaignId: string = '') {
  const [toIncentiveTable, setToIncentiveTable] = useState<TUserType>();
  const userElegibilities = useUserElegibilities(
    incentiveCampaignId,
    fileContentCampaignId,
    appendToFragmentUserCampaign,
  );

  useEffect(() => {
    const { name, position, points, eligibilities, first, second, third, last, campaignType } =
      userElegibilities.result || {};

    setToIncentiveTable({
      name: name,
      points: points,
      eligibilities: eligibilities as FileContentCampaignFileStructureEligibilities[],
      position: position,
      first: {
        points: first?.points || 0,
        eligibilities: first?.eligibilities as FileContentCampaignFileStructureEligibilities[],
      },
      second: {
        points: second?.points || 0,
        eligibilities: second?.eligibilities as FileContentCampaignFileStructureEligibilities[],
      },
      third: {
        points: third?.points || 0,
        eligibilities: third?.eligibilities as FileContentCampaignFileStructureEligibilities[],
      },
      last: {
        position: last?.position || 0,
        points: last?.points || 0,
        eligibilities: last?.eligibilities as FileContentCampaignFileStructureEligibilities[],
      },
      campaignType: campaignType as EnumIncentiveCampaignCampaignType,
    });
  }, [userElegibilities.result]);

  return {
    toIncentiveTable,
  };
}

const appendToFragmentUserCampaign = `
  eligibilities {
    name
    value
  }
  first {
    eligibilities {
      name
      value
    }
    points
  }
  second {
    eligibilities {
      name
      value
    }
    points
  }
  third {
    eligibilities {
      name
      value
    }
    points
  }
  last {
    position
    eligibilities {
      name
      value
    }
    points
  }
`;
