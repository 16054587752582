import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useContentTypePermissions } from '../../../hooks/useContentTypePermissions';
import { Container } from '../Create/styles';
import { Former, LoadingIndicator } from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { message } from 'antd';
import { GameficationNivel, Maybe, RoleGroup } from '../../../autogenerated/client/types';

type TOption = {
  label: string;
  value: string | number;
};

interface FormGameficationNivelProps {
  initialValues?: any;
}

export const FormGameficationNivelCustom = (props: FormGameficationNivelProps) => {
  const permissions = useContentTypePermissions();
  const history = useHistory();
  const [values, setValues] = React.useState(props?.initialValues || {});
  const [roleGroupOptions, setRoleGroupOptions] = useState<TOption[]>([]);
  const [nivelOptions, setNivelOptions] = useState<TOption[]>([]);
  const createGameficationNivel = useClient('GameficationNivelCustomCreateOne');
  const updateGameficationNivel = useClient('GameficationNivelCustomUpdateById');
  const findNiveis = useClient('GameficationNivelFindMany');
  const findRoleGroups = useClient('RoleGroupFindMany');

  const serializeRoleGroup = (data: Maybe<Maybe<RoleGroup>[]>) =>
    data?.map((d) => ({ value: d?._id as string, label: `${d?.name} / ${d?.channelName}` })) || [];

  const serializeNivel = (data: Maybe<Maybe<GameficationNivel>[]>) =>
    data?.map((d) => ({ value: d?._id as string, label: `${d?.nivel}` })) || [];

  useEffect(() => {
    findRoleGroups.fetch().then((ctx) => {
      if (!ctx.result?.length) return;
      const serialized = serializeRoleGroup(ctx.result);
      setRoleGroupOptions(serialized);
    });

    findNiveis.fetch().then((ctx) => {
      if (!ctx.result?.length) return;
      const serialized = serializeNivel(ctx.result);
      setNivelOptions(serialized);
    });
  }, []);

  const isUpdate = !!props.initialValues;

  const initialValues = React.useMemo(() => {
    return {
      title: values?.title,
      nivelId: values?.nivelId,
      roleGroupId: values?.roleGroupId,
    };
  }, [values]);

  if (permissions.loading) return <LoadingIndicator />;

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={() => {
          return {
            fields: [
              {
                inline: true,
                name: 'title',
                label: 'TÍTULO',
                required: true,
                validate: (value: any) => {
                  const format = /[#%&;]/;
                  if (format.test(value)) return 'Os caracteres #%&; não são permitidos';

                  if (value) return !(value.length > 110) ? undefined : 'Máximo de 110 caracteres';

                  return undefined;
                },
              },
              {
                inline: true,
                name: 'nivel',
                label: 'Posição',
                type: 'select',
                required: true,
                options: nivelOptions,
                defaultValue: initialValues?.nivelId,
              },
              {
                inline: true,
                name: 'rolegroup',
                label: 'Cargo / Canal',
                type: 'select',
                required: true,
                options: roleGroupOptions,
                defaultValue: initialValues?.roleGroupId,
              },
            ],
            submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
            cancelButton: {
              label: 'CANCELAR',
              onClick: () => history.push(routes.gameficationNivelCustomList.path),
            },
          };
        }}
        onSubmit={async ({ data }) => {
          const record: any = {
            position: Number(nivelOptions.find((n) => n.value === data.nivel)?.label),
            title: data.title,
            nivelId: data.nivel,
            roleGroupId: data.rolegroup,
          };

          const callback = (ctx: { result?: any }) => {
            if (ctx?.result) {
              message.success(`Nível ${isUpdate ? 'atualizado' : 'cadastrado'} com sucesso.`);
              history.push(routes.gameficationNivelCustomList.mount());
            } else {
              setValues({ ...record });
            }
          };

          console.log({ record });
          if (isUpdate) {
            await updateGameficationNivel
              .fetch({ variables: { record: { ...record, _id: props.initialValues!._id } } })
              .then(callback);
          } else {
            await createGameficationNivel.fetch({ variables: { record } }).then(callback);
          }

          return;
        }}
      />
    </Container>
  );
};
