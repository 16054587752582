import * as React from 'react';
import { EnumIncentiveCampaignStatus } from '../../../autogenerated/client/types';

const actionTypes = {
  with_results: 'RESULTS',
  in_progress: 'PREVIEW',
  closed: 'CLOSED', // O status closed é setado no server quando a campanha é paga aos usuarios
  in_verification: 'VERIFICATION',
  waiting_points_distribution: 'WAITING_POINTS_DISTRIBUTION',
  toggle: 'TOGGLE',
  current: 'CURRENT',
  closed_without_winners: 'CLOSED_WITHOUT_WINNERS',
  closed_partner: 'CLOSED_PARTNER'
};

export type StatusOptions =
  | EnumIncentiveCampaignStatus
  | 'closed'
  | 'with_results'
  | 'in_verification'
  | 'in_progress'
  | 'toggle'
  | 'current'
  | 'waiting_points_distribution';

export type CampaignPeriodOptions =
  | any;

type State = {
  results: boolean;
  preview: boolean;
  status: EnumIncentiveCampaignStatus;
};

interface Action {
  type: string;
}

const INITIAL_STATE = {
  results: false,
  preview: true,
  status: EnumIncentiveCampaignStatus.InProgress
};

function statusReducer(state: State = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case actionTypes.with_results: {
      return {
        results: true,
        preview: false,
        status: EnumIncentiveCampaignStatus.WithResults
      };
    }
    case actionTypes.in_progress: {
      return {
        results: false,
        preview: true,
        status: EnumIncentiveCampaignStatus.InProgress
      };
    }
    case actionTypes.closed: {
      return {
        results: true,
        preview: false,
        status: EnumIncentiveCampaignStatus.Closed
      };
    }
    case actionTypes.waiting_points_distribution: {
      return {
        results: true,
        preview: false,
        status: EnumIncentiveCampaignStatus.WaitingPointsDistribution
      };
    }
    case actionTypes.in_verification: {
      return {
        results: true,
        preview: false,
        status: EnumIncentiveCampaignStatus.InVerification
      };
    }
    case actionTypes.toggle: {
      return {
        results: !state.results,
        preview: !state.preview,
        status: !state.results
          ? EnumIncentiveCampaignStatus.WithResults
          : EnumIncentiveCampaignStatus.InProgress
      };
    }
    case actionTypes.closed_without_winners: {
      return {
        results: true,
        preview: false,
        status: EnumIncentiveCampaignStatus.ClosedWithoutWinners
      };
    }
    case actionTypes.closed_partner: {
      return {
        results: true,
        preview: false,
        status: EnumIncentiveCampaignStatus.ClosedPartner
      };
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`);
    }
  }
}

function useStatus(initialValue = INITIAL_STATE) {
  const [status, dispatch] = React.useReducer(statusReducer, initialValue);
  const execute = (e: StatusOptions) => dispatch({ type: actionTypes[e] });

  return { status, execute };
}

export { useStatus };
