import React, { useState, useMemo, useEffect } from 'react';
import { Modal, Divider, Icon } from 'antd';
import fields from './fields';
import { Former, Select, useCurrentUser } from '@digi-tim-19/components';
import {
  EnumIncentiveCampaignStatus,
  IncentiveCampaign,
  IncentiveCampaignData,
} from '../../autogenerated/client/types';
import { useClient } from '../../autogenerated/client/client';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  ContestationContainer,
  ContestButton,
  ContestationModal,
  CampanhaTitleWrapper,
  CampanhaVigenteImage,
  CampanhaPeriodTitleWrapper,
} from './stylesCampanhaVigente';
import { BateuLevouElegibility, BateuLevouResults } from './Components/BateuLevou';
import { CanvassRangeOfReach } from './Components/Canvass';
import { RankingIncentive } from './Components/Ranking';
import { VendeuGanhou } from './Components/VendeuGanhou';

moment.locale('pt-BR');

interface Props {
  campaign: IncentiveCampaign;
}

type Option = { label: string; value: string };

type ModalAcceptConfig = { title: string; conditional: boolean; messages: string[] };

export const CampanhaVigenteParticipant = ({ campaign }: Props) => {
  const currentUser = useCurrentUser();
  const [showModalContestation, setShowModalContestation] = useState<boolean>(false);
  const [showDataModal, setShowDataModal] = useState(true);
  const addContestationPeriod = moment(campaign.updatedAt).add(Number(campaign.contestationPeriod), 'day');
  const range = addContestationPeriod.fromNow(true);
  const canContestate = moment(addContestationPeriod).isAfter(new Date());
  const results = Object.values(campaign.resultData)
    .filter((el) => el?.reference)
    .sort((a, b) => (a?.reference! < b?.reference! ? -1 : 1));
  const [detail, setDetail] = useState<IncentiveCampaignData>(results[0]!);
  const rankingApi = useClient('CampaignGeRanking');
  const CampaignContestationCreateOne = useClient('CampaignContestationCreateOne');

  const isRanking = useMemo(
    () => new Set(['ranking']).has(campaign?.campaignType!),
    [],
  );

  useEffect(() => {
    if (!isRanking || !detail?.reference) return;

    rankingApi.fetch({
      variables: {
        reference: detail?.reference || '',
        campaignId: campaign._id,
      },
    });
  }, [detail]);

  const initValues = useMemo(
    () => ({
      title: campaign?.title,
      name: currentUser?.result?.name,
      channel: currentUser?.result?.channels?.find((el) => el),
      region: currentUser?.result?.region,
      role: currentUser?.result?.roleGroup?.name,
      subscription: currentUser?.result?.matricula,
      cpf: currentUser?.result?.CPF,
    }),
    [currentUser?.result, campaign], //eslint-disable-line
  );

  function success(protocol: number | null | undefined) {
    Modal.success({
      content: `Contestação realizada com sucesso, para acompanhá-la anote o protocolo de número: ${protocol}`,
    });
  }

  const periodOptions = useMemo(
    () => results?.map((el) => ({ label: moment(el?.reference!).format('MM/YYYY'), value: el?.reference })),
    [],
  );

  const isAccept = !campaign.pendingAccept;

  const modalConfigs: Record<string, ModalAcceptConfig[]> = {
    [EnumIncentiveCampaignStatus.InProgress]: [
      {
        title: 'Sem dados',
        conditional: isAccept && !campaign.hasData,
        messages: [
          'Seu aceite foi concluído com sucesso!',
          'Sem dados atualizados no momento, aguarde a próxima atualização.',
        ],
      },
    ],
    [EnumIncentiveCampaignStatus.InVerification]: [
      {
        conditional: isAccept && !campaign.hasData,
        title: 'Campanha em Apuração',
        messages: [
          'Seu aceite foi concluído com sucesso!',
          'Sem dados atualizados no momento, aguarde a próxima atualização.',
        ],
      },
      {
        conditional: !isAccept,
        title: 'Período de Vigência Encerrado',
        messages: ['O período de vigência está encerrado.', 'Não é possível mais dar aceite nesta campanha.'],
      },
    ],
    [EnumIncentiveCampaignStatus.Closed]: [
      {
        conditional: isAccept && !campaign.hasData,
        title: 'Campanha Encerrada',
        messages: ['Campanha encerrada, obrigado pela participação.'],
      },
      {
        conditional: !isAccept,
        title: 'Campanha Encerrada',
        messages: ['Campanha encerrada, não é possível mais dar aceite nesta campanha.'],
      },
    ],
  };

  const modalConfig = modalConfigs[campaign.status?.value as string].find((el) => el.conditional);

  return (
    <>
      <Modal
        title={modalConfig?.title}
        visible={modalConfig && showDataModal}
        okText="Ok"
        onOk={() => setShowDataModal(false)}
        cancelButtonProps={{ hidden: true }}
      >
        {modalConfig?.messages.map((message) => (
          <p>{message}</p>
        ))}
      </Modal>

      <ContestationModal
        title="Contestação"
        visible={showModalContestation}
        onCancel={() => setShowModalContestation(false)}
        footer={[]}
      >
        <Former
          initialValues={initValues}
          config={{
            fields,
            submitButton: {
              label: 'enviar',
            },
          }}
          onSubmit={async ({ data }: any) => {
            const { indicator, evidence, message } = data;
            const record = {
              indicator,
              message,
              fileIds: [evidence],
              parentId: campaign?._id,
            };
            await CampaignContestationCreateOne.fetch({
              variables: { record },
              appendToFragment: `
                record {
                  protocol
                }`,
            }).then((ctx) => {
              if (!ctx.result?.record) {
                message.error('Não foi possível realizar essa ação');
              } else {
                setShowModalContestation(false);
                success(ctx?.result?.record?.protocol);
              }
            });
          }}
        />
      </ContestationModal>

      <CampanhaTitleWrapper>{campaign.title}</CampanhaTitleWrapper>

      {periodOptions?.length ? (
        <>
          <CampanhaPeriodTitleWrapper>Selecione o período</CampanhaPeriodTitleWrapper>
          <Select
            placeholder="Período"
            onChange={(period) => {
              const detailSelected = results.find((el) => el?.reference === period);
              setDetail(detailSelected!);
            }}
            options={periodOptions as Option[]}
            value={detail?.reference!}
          />
        </>
      ) : null}

      <Divider />

      {/* {campaign?.campaignType === 'record_de_vendas' && (
        <ContestationContainer>
          <p>
            No caso de não-concordância com o resultado divulgado da campanha, clique no botão vermelho ao lado para
            contestar.
          </p>
          <ContestButton onClick={() => setShowModalContestation(true)}>CLIQUE AQUI PARA CONTESTAR</ContestButton>
        </ContestationContainer>
      )} */}

      {campaign?.campaignType === 'bateu_levou' && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
          <div>
            {(detail?.elegibility ?? []).map((el: any, index: number, data: any) => (
              <BateuLevouElegibility
                key={el.kpiId}
                firstElement={index === 0}
                lastElement={index === data.length - 1}
                points={el.points}
                tend={el.tend}
                userBooster={el.userBooster}
                goal={el.goal}
                result={el.result}
                percent={(el.result / el.goal) * 100}
                description={el.description}
                lastUpdate={detail?.updatedAt}
              />
            ))}
          </div>

          <div>
            {(detail?.results ?? []).map((el: any, index: number, data: any) => (
              <BateuLevouResults
                key={el.kpiId}
                firstElement={index === 0}
                lastElement={index === data.length - 1}
                points={el.points || detail?.points}
                tend={el.tend}
                userBooster={el.userBooster}
                goal={el.goal}
                result={el.result}
                percent={(el.result / el.goal) * 100}
                description={el.description}
                lastUpdate={detail?.updatedAt}
              />
            ))}
          </div>
        </div>
      )}

      {campaign?.campaignType === 'canvass' && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
          {detail?.results?.map((el: any) => (
            <CanvassRangeOfReach
              key={el.kpiId}
              faixa1={el.resultados1}
              faixa2={el.resultados2}
              faixa3={el.resultados3}
              points={el.points}
              tend={el.tend}
              userBooster={el.userBooster}
              description={el.description}
              lastUpdate={detail?.updatedAt}
              eligibility={el.eligibility}
            />
          ))}
        </div>
      )}

      {isRanking && (
        <RankingIncentive detail={detail!} loadingRanking={rankingApi.loading} rankingData={rankingApi.result} />
      )}

      {campaign?.campaignType === 'valoriza' && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
          <div>
            {(detail?.elegibility ?? []).map((el: any, index: number, data: any) => (
              <BateuLevouElegibility
                key={el.kpiId}
                firstElement={index === 0}
                lastElement={index === data.length - 1}
                points={el.points}
                tend={el.tend}
                userBooster={el.userBooster}
                goal={el.goal}
                result={el.result}
                percent={(el.result / el.goal) * 100}
                description={el.description}
                lastUpdate={detail?.updatedAt}
              />
            ))}
          </div>

          <div>
            {(detail?.results ?? []).map((el: any, index: number, data: any) => (
              <BateuLevouResults
                key={el.kpiId}
                firstElement={index === 0}
                lastElement={index === data.length - 1}
                points={el.points || detail?.points}
                tend={el.tend}
                userBooster={el.userBooster}
                goal={el.goal}
                result={el.result}
                percent={(el.result / el.goal) * 100}
                description={el.description}
                lastUpdate={detail?.updatedAt}
              />
            ))}
          </div>
        </div>
      )}

      {campaign?.campaignType === 'conversao' && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
          <div>
            {(detail?.elegibility ?? []).map((el: any, index: number, data: any) => (
              <BateuLevouElegibility
                key={el.kpiId}
                firstElement={index === 0}
                lastElement={index === data.length - 1}
                points={el.points}
                tend={el.tend}
                userBooster={el.userBooster}
                goal={el.goal}
                result={el.result}
                percent={(el.result / el.goal) * 100}
                description={el.description}
                lastUpdate={detail?.updatedAt}
              />
            ))}
          </div>

          <div>
            {(detail?.results ?? []).map((el: any, index: number, data: any) => (
              <BateuLevouResults
                key={el.kpiId}
                firstElement={index === 0}
                lastElement={index === data.length - 1}
                points={el.points || detail?.points}
                tend={el.tend}
                userBooster={el.userBooster}
                goal={el.goal}
                result={el.result}
                percent={(el.result / el.goal) * 100}
                description={el.description}
                lastUpdate={detail?.updatedAt}
              />
            ))}
          </div>
        </div>
      )}

      {(campaign?.campaignType === 'vendeu_ganhou' || campaign?.campaignType === 'free_style') && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
          <div>
            <VendeuGanhou details={detail?.results ?? []} points={detail?.points as string} />
          </div>
        </div>
      )}

      {campaign?.campaignType === 'coringa' && (
        <CampanhaVigenteImage>
          <img src={campaign?.bannerContentFile?.signedUrl || ''} alt="thumb" />
        </CampanhaVigenteImage>
      )}

      {campaign?.campaignType === 'estrutuout' && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
          <div>
            {(detail?.results ?? []).map((el: any, index: number, data: any) => (
              <BateuLevouResults
                key={el.kpiId}
                firstElement={index === 0}
                lastElement={index === data.length - 1}
                points={el.points || detail?.points}
                tend={el.tend}
                userBooster={el.userBooster}
                goal={el.goal}
                result={el.result}
                percent={(el.result / el.goal) * 100}
                description={el.description}
                lastUpdate={detail?.updatedAt}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
