import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 20px 15px;
  margin-bottom: 25px;
  .ant-upload-list {
    position: absolute;
    bottom: -25px;
  }
  .ant-select-selection__placeholder {
    left: 9px;
  }

  .Footer {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    .FieldWrapper:nth-of-type(1) {
      max-width: 210px;
      margin-right: 15px;
    }
    .FieldWrapper:nth-of-type(2) {
      max-width: 210px;
      margin-right: 15px;
      position: relative;
    }
  }
  .FieldWrapper:nth-of-type(1) {
    align-items: center !important;
    justify-content: flex-start;
    flex-wrap: wrap;
    .name-categories {
      margin-left: 15px;
    }
  }
  .name-status {
    margin-left: 15px;
  }
  .name-cardimage {
    max-width: 30% !important;
    margin-left: 10px;
    > span {
      display: flex;

      .anticon-loading {
        display: none;
      }
      .ant-upload-list-item-progress {
        display: none;
      }
      .anticon-close {
        right: -2px;
      }
    }
  }
  .name-mailing {
    max-width: 210px !important;
    width: 100% !important;
    span,
    button,
    div {
      width: 100%;
    }
    svg {
      display: none;
    }
    button.ant-btn {
      background: #00508c;
      color: white;
      max-width: 210px !important;
      width: 100% !important;
    }
  }
  .name-mailing {
    max-width: 210px !important;
    width: 100% !important;
    span,
    button,
    div {
      width: 100%;
    }
    svg {
      display: none;
    }
    button.ant-btn {
      background: #00508c;
      color: white;
      max-width: 210px !important;
      width: 100% !important;
    }
  }
`;
export const Divider = styled.hr`
  border: none;
  background: #ccc;
  height: 1px;
  width: 100%;
  margin: 20px 0;
`;
export const LiveWrapper = styled.div`
  width: 100%;
`;
