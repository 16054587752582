import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { message } from 'antd';
import {
  Former,
  SelecionarPublico,
  usePublico,
  SearchUser,
  LoadingIndicator
} from '@digi-tim-19/components';

import { OnTubeCadastroFormer } from './Cadastrar/stylesCadastrar';
import { useMaterialCategoriesSelect } from '../../hooks/useMaterialCategories';
import {
  EnumFileKind,
  EnumMaterialKind
} from '../../autogenerated/client/types';
import { useClient } from '../../autogenerated/client/client';
import { Material } from '../../autogenerated/client/types';
import { routes } from '../../config/routes';
import { useContentTypePermissions } from '../../hooks/useContentTypePermissions';
import { useDimensions } from '@digi-tim-19/utils/build';
import { selectedChannels } from '../../utils/selectedChannels';
const selectedChannelsConsumer = selectedChannels();

const VideoMp4Kind = EnumFileKind.VideoMp4;
const VideoThumbKind = EnumFileKind.VideoThumb;
const BannerMobileKind = EnumFileKind.BannerMaterialMobile;
const BannerDesktopKind = EnumFileKind.BannerMaterialDesktop;

export const Form = (props: TFormMaterialEditProps) => {
  const permissions = useContentTypePermissions();
  const { availableAtChannels, availableAtRegions, availableAtRoleGroups } =
    usePublico();
  const history = useHistory();
  const playlistOptions = useMaterialCategoriesSelect('video_playlist');
  const { isMobile } = useDimensions();

  const isUpdate = !!props.initialValues;

  const playlist: any = (props.initialValues?.categories || []).map(
    (el) => el?._id
  );

  const [categoriesSelected, setCategoriesSelected] =
    useState<string[]>(playlist);
  const [showMainPlaylist, setMainPlayList] = useState(false);

  const initialValues = useMemo(
    () => ({
      ...props.initialValues,
      playlist: (props.initialValues?.categories || []).map((el) => el?._id),
      video: (props.initialValues?.files || []).find(
        (el) => el && el.kind === VideoMp4Kind
      )?._id,
      thumbnail: (props.initialValues?.files || []).find(
        (el) => el && el.kind === VideoThumbKind
      )?._id,
      bannerMobile: (props.initialValues?.files || []).find(
        (el: any) => el && el.kind === BannerMobileKind
      )?._id,
      bannerDesktop: (props.initialValues?.files || []).find(
        (el: any) => el && el.kind === BannerDesktopKind
      )?._id,
      notifyEmailsBeforeExpire:
        props.initialValues?.validity?.notifyEmailsBeforeExpire
    }),
    [props.initialValues]
  );

  useEffect(() => {
    if (categoriesSelected.length > 1) setMainPlayList(true);
    else setMainPlayList(false);
  }, [categoriesSelected]);

  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const createMaterial = useClient('MaterialCreateOne');

  if (permissions.loading) return <LoadingIndicator />;

  return (
    <OnTubeCadastroFormer>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              list: [
                {
                  inline: !isMobile,
                  list: [
                    {
                      name: 'validity.start',
                      label: 'Data de início',
                      type: 'datePicker',
                      required: true,
                      extraProps: {
                        showTime: true,
                        format: 'DD/MM/YYYY HH:mm:ss'
                      },
                      maxWidth: '250px',
                      enableDatePriorToCurrent: isUpdate
                    },
                    {
                      name: 'validity.end',
                      label: 'Data de expiração',
                      type: 'datePicker',
                      required: true,
                      extraProps: {
                        showTime: true,
                        format: 'DD/MM/YYYY HH:mm:ss'
                      },
                      maxWidth: '250px'
                    }
                  ]
                },
                {
                  className: 'formHeader',
                  inline: !isMobile,
                  list: [
                    !props.initialValues?.isLive && {
                      name: 'video',
                      label: 'VÍDEO',
                      type: 'upload',
                      required: !props.initialValues?.isLive,
                      extraProps: {
                        kind: 'video_mp4',
                        CTA: 'IMPORTAR VÍDEO',
                        allowedExtensions: ['video/mp4']
                      }
                    },

                    {
                      name: 'thumbnail',
                      label: 'THUMBNAIL',
                      type: 'upload',
                      extraProps: {
                        kind: 'video_thumb',
                        CTA: 'IMPORTAR THUMBNAIL'
                      }
                    },
                    {
                      name: 'bannerMobile',
                      label: 'Banner Mobile',
                      type: 'upload',
                      extraProps: {
                        kind: BannerMobileKind,
                        CTA: '425 (L) X 240 (A)'
                      }
                    },
                    {
                      name: 'bannerDesktop',
                      label: 'Banner Desktop',
                      type: 'upload',
                      extraProps: {
                        kind: BannerDesktopKind,
                        CTA: '1920 (L) X 440 (A)'
                      }
                    }
                  ]
                },
                {
                  name: 'playlist',
                  label: 'PLAYLIST',
                  options: playlistOptions?.sort(function (a: any, b: any) {
                    if (a.label > b.label) return 1;
                    if (a.label < b.label) return -1;
                    return 0;
                  }),
                  required: true,
                  extraProps: {
                    maxlength: 110,
                    mode: 'tags',
                    onSelect: (value: string) =>
                      setCategoriesSelected((prevState) => [
                        ...prevState,
                        value
                      ]),
                    onDeselect: (value: string) =>
                      setCategoriesSelected(
                        categoriesSelected.filter((item) => item !== value)
                      )
                  }
                },
                showMainPlaylist && {
                  name: 'mainIdCategory',
                  label: 'PLAYLIST PRINCIPAL',
                  options: playlistOptions
                    ?.filter((playlistOption) =>
                      categoriesSelected.some(
                        (item) => item === playlistOption.value
                      )
                    )
                    ?.sort(function (a: any, b: any) {
                      if (a.label > b.label) return 1;
                      if (a.label < b.label) return -1;
                      return 0;
                    }),
                  required: true
                },
                {
                  name: 'title',
                  label: 'TÍTULO',
                  required: true,
                  validate: (value: any) => {
                    const format = /[#%&;]/;
                    if (format.test(value))
                      return 'Os caracteres #%&; não são permitidos';

                    if (value)
                      return !(value.length > 110)
                        ? undefined
                        : 'Máximo de 110 caracteres';

                    return undefined;
                  }
                },
                {
                  name: 'description',
                  label: 'DESCRIÇÃO',
                  type: 'textarea',
                  extraProps: {
                    rows: 5
                  },
                  validate: (value: any) => {
                    const format = /[#%&]/;
                    if (format.test(value))
                      return 'Os caracteres #%& não são permitidos';

                    if (value)
                      return !(value.length > 1200)
                        ? undefined
                        : 'Máximo de 1200 caracteres';

                    return undefined;
                  }
                },
                {
                  name: 'tags',
                  label: 'tags',
                  type: 'tags'
                },
                {
                  name: 'notifyEmailsBeforeExpire',
                  label: 'Notificar antes de expirar',
                  placeholder: 'Notificar antes de expirar',
                  extraProps: {
                    returnProp: 'email'
                  },
                  component: SearchUser
                },
                {
                  className: 'formFooter',
                  inline: true,
                  list: [
                    {
                      custom: (
                        <SelecionarPublico
                          initialValues={props.initialValues}
                          selectedChannels={
                            !isUpdate ? selectedChannelsConsumer : []
                          }
                          {...permissions.filterOptions}
                        />
                      )
                    }
                  ]
                }
              ]
            }
          ],
          submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(routes.OnTubeListar.path)
          }
        }}
        onSubmit={async function ({ data }) {
          const record = {
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            kind: EnumMaterialKind.Video,
            validity: {
              notifyEmailsBeforeExpire: data.notifyEmailsBeforeExpire,
              start: data?.validity?.start,
              end: data?.validity?.end
            },
            fileIds: [
              data.video,
              data.thumbnail,
              data.bannerMobile,
              data.bannerDesktop
            ].filter(Boolean),
            categories: data.playlist,
            title: data.title,
            description: data.description,
            tags: data.tags,
            mainIdCategory:
              data.playlist.length > 1 ? data.mainIdCategory : undefined
          };

          if (isUpdate && data._id) {
            await MaterialUpdateOne.fetch({
              variables: { record, filter: { _id: data._id } }
            }).then((ctx) => {
              if (!ctx.errors) history.push(routes.OnTubeListar.mount());
            });
          } else {
            await createMaterial
              .fetch({ variables: { record } })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success('Vídeo cadastrado com sucesso!');
                  history.push(routes.OnTubeListar.mount());
                } else {
                  message.error('Opss, seu vídeo não foi cadastrado!');
                }
              });
          }
        }}
      />
    </OnTubeCadastroFormer>
  );
};

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
};
