import React from 'react';
import { CardsWrapper } from './styles';
import {
  CardConsumer,
  CardIncentiveCampaignConsumer
} from '@digi-tim-19/components';
import {
  obterImagemPadraoPorMaterial,
  actionsButtonFavorites
} from '../../utils/material';
import { routes } from '../../config/routes';
import { useHistory } from 'react-router';

export const CardFavoritos = (props: {
  materials?: any[];
  incentiveCampaigns?: any[];
  listFolders: any[];
  addFolder: (
    name: string,
    cb: (error: null | string, result?: any) => any
  ) => any;
}) => {
  const history = useHistory();

  const hasIncentiveCampaigns = !!props?.incentiveCampaigns?.length;
  const hasMaterials = !!props?.materials?.length;

  return (
    <>
      {hasIncentiveCampaigns && hasMaterials && <h2>Meus documentos</h2>}
      <CardsWrapper>
        {props.materials?.map((item: any) => {
          const thumb = obterImagemPadraoPorMaterial(item);

          return (
            <CardConsumer
              material={item}
              defaultImage={thumb}
              actions={(material) =>
                actionsButtonFavorites(
                  material,
                  routes,
                  history,
                  props.listFolders,
                  props.addFolder
                )
              }
            />
          );
        })}
      </CardsWrapper>

      {hasIncentiveCampaigns && hasMaterials && <h2>Minhas campanhas</h2>}

      <CardIncentiveCampaignConsumer
        actions={{
          accessButton: {
            label: 'Acessar',
            action: (campaign) => {
              history.push(
                routes.campanhasIncentivoVigente.mount(campaign?._id)
              );
            }
          },
          pinFolder: {
            listFolders: props.listFolders,
            actionAddFolder: props.addFolder
          },
          share: false,
          pin: false
        }}
        items={props.incentiveCampaigns!}
      />
    </>
  );
};
