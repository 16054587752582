import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { Breadcrumb, JoystickIconSVG, LoadingIndicator, Table, TitleDescription } from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { Button, CleanButton, FilterContainer, NotificationsWrapper, Search } from './styles';
import { columns } from './columns';
import { useClient } from '../../../autogenerated/client/client';
import { useHistory } from 'react-router';
import { message } from 'antd';
import { ContainerIcon } from '../../Gamefication/List/styles';
import { escapeStringRegexp } from '../../../utils/formatters';

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    title
    placements
    createdAt
    updatedAt
  }
`;

export const GameficationRankinglList = () => {
  const history = useHistory();
  const getGameficationRanking = useClient('GameficationRankingPagination');
  const GameficationRankingDeleteOne = useClient('GameficationRankingRemoveOne');
  const [page, setPage] = React.useState(1);
  const [filterSearch, setFilterSearch] = React.useState();
  const [data, setData] = React.useState<any>([]);
  const perPage = 10;
  const total = getGameficationRanking?.result?.pageInfo?.itemCount || 0;

  const resetFilters = () => {
    setFilterSearch(undefined);
    setPage(1);
  };

  const handleGetGameficationRanking = (filterSearch: any) => {
    getGameficationRanking
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
          },
        },
      })
      .then((response: any) => {
        const items = response?.result?.items?.map((ranking: any) => {
          return {
            ...ranking,
            onEdit: () => {
              history.push(routes.gameficationRankingEdit.mount(ranking?._id));
            },
            onDelete: () => {
              GameficationRankingDeleteOne.fetch({
                variables: { filter: { _id: ranking?._id } },
              }).then((_: any) => {
                handleGetGameficationRanking(filterSearch);
                message.success('Ranking deletado com sucesso.');
              });
            },
            onRead: () => history.push(routes.gameficationRankingAdmin.mount(ranking?._id)),
          };
        });

        setData(items || []);
      });
  };

  React.useEffect(() => {
    handleGetGameficationRanking(filterSearch);
  }, [filterSearch]);

  return (
    <PageTemplate>
      <React.Fragment>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Gamificação (Ranking)' },
              { title: 'Listar' },
            ]}
          />
          <TitleDescription
            renderCustomIcon={
              <ContainerIcon>
                <JoystickIconSVG />
              </ContainerIcon>
            }
            title="Gamificação (Ranking)"
            description="Listar e consultar os ranking da Gamificação"
          />
        </NotificationsWrapper>
        <FilterContainer>
          <Button to={routes.gameficationRankingCreate.mount()}>CADASTRAR RANKING</Button>
          <Search
            placeholder="Busca por título"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e: any) => {
              setFilterSearch(e.target.value);
              setPage(1);
            }}
          />

          <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
        </FilterContainer>
        {getGameficationRanking.loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e: any) => setPage(e),
            }}
          />
        )}
      </React.Fragment>
    </PageTemplate>
  );
};
