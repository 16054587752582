import { Input } from 'antd';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

const { Search: AntdSearch } = Input;

export const NotificationsWrapper = styled.div`
  width: 100%;
`;

export const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;
export const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;

  .ant-calendar-range-picker-separator {
    display: none !important;
  }
  @media only screen and (max-width: 900px) {
    .ant-calendar-picker-container {
      width: 100%;
      padding: 0 15px;
    }
    .ant-calendar-range {
      width: 100% !important;
    }
    .ant-calendar-date-panel {
      display: flex !important;
      flex-direction: column !important;
      .ant-calendar-range-part {
        width: 100%;
      }
    }
  }
`;
export const Button = styled(Link)`
  border: none;
  background: red;
  display: flex;
  justify-content: center;

  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  font-family: TimBold;
  width: 100%;
  cursor: pointer;
  outline: none;
  margin-right: 15px;
  &:hover {
    background: white;
    color: red;
    outline: none !important;
  }
`;

export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;

  cursor: pointer;
`;
