import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { Breadcrumb, JoystickIconSVG, LoadingIndicator, Table, TitleDescription } from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { Button, ButtonVerification, CleanButton, FilterContainer, NotificationsWrapper, Search } from './styles';
import { columns } from './columns';
import { useClient } from '../../../autogenerated/client/client';
import { useHistory, useParams } from 'react-router';
import nodeXlsx from 'node-xlsx';
import { fileToBuffer } from '../../../utils/xlsx';
import { ValidationErrosType } from '../../CampanhaIncentivo/ArquivosCampanha/utilsArquivoCampanha';
import { ModalErrors } from '../../CampanhaIncentivo/ArquivosCampanha/ModalErrors';
import { message, Modal } from 'antd';
import { TypeChallenge } from '../../Gamefication/Form/Form';
import { downloadXLSX } from '../../../utils/downloadXlsx';
import { ContainerIcon } from '../../Gamefication/List/styles';
import { formatDate } from '@digi-tim-19/utils';

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    progress
    challengeId
    status
    acceptDate
    challenge {
      action
    }
    userChallengeData
  }
`;

export const GameficationVerificationList = () => {
  const getUserChallenges = useClient('UserChallengePagination');
  const { challengeId } = useParams<{ challengeId: string }>();
  const challenge = useClient('ChallengeFindById', { variables: { _id: challengeId }, fetchOnMount: true });
  const [page, setPage] = React.useState(1);
  const [filterSearch, setFilterSearch] = React.useState();
  const [isUpload, setIsUpload] = React.useState<boolean>();
  const [dataset, setDataset] = React.useState<any[]>([]);
  const perPage = 10;
  const total = getUserChallenges?.result?.pageInfo?.itemCount || 0;
  const [usersCPFAllow, setUsersCpfAllow] = React.useState<string[]>([]);
  const [isOpenModalVerification, setIsOpenModalVerification] = React.useState<boolean>(false);
  const [isOpenModalErros, setIsOpenModalErros] = React.useState<boolean>(false);
  const [errorsValidationFile, setErrorsValidationFiles] = React.useState<ValidationErrosType[]>([]);
  const history = useHistory();

  const MissionActionApi = useClient('MissionAction');
  const UpdateUserChallengeApi = useClient('UserChallengeUpdateMany');
  const UpdateChallengeApi = useClient('ChallengeUpdateById');
  const userChallenges = useClient('UserChallengeFindMany', {
    fetchOnMount: true,
    variables: {
      limit: 1e8,
      filter: {
        acceptDate: true,
        challengeId,
      },
    },
  });

  const resetFilters = () => {
    setFilterSearch(undefined);
    setPage(1);
  };

  const handleGetChallenges = () => {
    getUserChallenges
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            acceptDate: true,
            challengeId,
            challenge: {
              action: TypeChallenge.mission,
            },
          },
        },
      })
      .then((response: any) => {
        setDataset(response?.result?.items);
      });
  };

  React.useEffect(() => {
    handleGetChallenges();
  }, [page]);

  const handleDownload = () => {
    if (!userChallenges.result?.length) return message.error('Não há registro para download de planilha');

    const schema = { name: { label: 'Nome' }, cpf: { label: 'CPF' }, acceptDate: { label: 'Aceite' } };

    const data = userChallenges?.result
      ?.filter((el) => !!el?.acceptDate)
      ?.map((d: any) => ({
        name: d.userChallengeData.name,
        cpf: d.userChallengeData.cpf,
        acceptDate: formatDate(d.acceptDate, 'DD/MM/YYYY'),
      }));

    downloadXLSX('relatorio-aceites', schema, data);
  };

  const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.[0]) return;

    const file = await fileToBuffer(event.target.files?.[0]);

    const [{ data }] = nodeXlsx.parse(file);

    const rows = data.splice(1, data.length) as Array<string[]>;

    const usersCPF: string[] = [];
    const erros: ValidationErrosType[] = [];

    const usersChallenge: Set<string> = new Set(userChallenges.result?.map((d: any) => d.userChallengeData.cpf));

    for (const [key, columns] of rows.entries()) {
      if (!columns.filter(Boolean).length) continue;

      const line = key + 2;
      const [nome, cpfV] = columns;

      const cpf = `${cpfV}`?.trim();

      if (!nome) erros.push({ line, message: 'Coluna "Nome" é obrigatório' });
      if (!cpf) erros.push({ line, message: 'Coluna "CPF" é obrigatório' });
      if (!nome || !cpf) continue;

      if (usersChallenge.has(cpf)) usersCPF.push(cpf);
    }

    if (!usersCPF.length) erros.push({ line: 0, message: 'Nenhum participante aceitou o desafio' });

    if (erros.length) {
      setErrorsValidationFiles(erros);
      setIsOpenModalErros(true);
      return;
    }

    try {
      await UpdateUserChallengeApi.fetch({
        variables: {
          filter: {
            userChallengeData: {
              cpf: { $in: usersCPF },
            },
          },
          record: {
            status: 'awaiting_verification',
          },
          limit: 10,
        },
      });

      setUsersCpfAllow(usersCPF);
      setIsUpload(true);

      message.success('Planilha importada com sucesso');
    } catch (error) {
      message.error('Não foi possível importar planilha');
    }
  };

  const handleVerification = async () => {
    try {
      await UpdateChallengeApi.fetch({
        variables: {
          record: {
            _id: challengeId,
            challengeData: {
              status: 'finished',
            },
          },
        },
      });

      await MissionActionApi.fetch({
        variables: { challengeId, usersCPF: usersCPFAllow },
      });

      message.success('Missão apurada com sucesso');
      history.push(routes.gameficationMissions.mount());
    } catch (error) {
      message.success('Não foi possível apurar missão');
    } finally {
      setIsOpenModalVerification(false);
    }
  };

  const isDisableButtons = challenge.result?.challengeData?.status === 'finished';

  return (
    <PageTemplate>
      <React.Fragment>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Missões' },
              { title: 'Listar', link: routes.gameficationMissions.mount() },
              { title: 'Apurar Resultado' },
            ]}
          />
          <TitleDescription
            renderCustomIcon={
              <ContainerIcon>
                <JoystickIconSVG />
              </ContainerIcon>
            }
            title="Apuração Missões"
            description="Listar e consultar registros dos participantes"
          />
          <a style={{ textDecoration: 'underline' }} href="/files/Importação Resultados - Missão.xlsx" download>
            Baixar Planilha Modelo
          </a>
          <a style={{ marginLeft: 15, textDecoration: 'underline' }} onClick={handleDownload}>
            Exportar relatório
          </a>
        </NotificationsWrapper>
        <Modal
          title="Finalizar Apuração"
          visible={isOpenModalVerification}
          okText="Confirmar"
          onCancel={() => setIsOpenModalVerification(false)}
          onOk={handleVerification}
        >
          <div>
            <p>Apenas os participantes inseridos na planilha importada receberão os CHIPS configurados.</p>
            <p>Deseja prosseguir?</p>
          </div>
        </Modal>
        <ModalErrors
          isOpen={isOpenModalErros}
          errors={errorsValidationFile}
          onCancel={() => {
            setIsOpenModalErros(false);
            setErrorsValidationFiles([]);
          }}
          onOk={() => {
            setIsOpenModalErros(false);
            setErrorsValidationFiles([]);
          }}
        />
        <FilterContainer>
          <Button type="file" onChange={handleImport} accept={'.xlsx'} disabled={isDisableButtons || isUpload} />
          <ButtonVerification onClick={() => setIsOpenModalVerification(true)} disabled={isDisableButtons || !isUpload}>
            Finalizar Apuração
          </ButtonVerification>
          <Search
            placeholder="Busca por nome"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e: any) => {
              setFilterSearch(e.target.value);
              setPage(1);
            }}
          />

          <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
        </FilterContainer>
        {getUserChallenges.loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={dataset}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e: any) => setPage(e),
            }}
          />
        )}
      </React.Fragment>
    </PageTemplate>
  );
};
