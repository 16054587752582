import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { TabelaPrecoWrapper } from './stylesCadastrar';
import { routes } from '../../../config/routes';

export const MapaPecasCadastrar = () => {
  return (
    <PageTemplate>
      <>
        <TabelaPrecoWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Mapa de Peças' },
              { title: 'Cadastrar' }
            ]}
          />
          <TitleDescription
            customIcon
            iconType="iconmap2"
            title="Mapa de Peças"
            description="Listar e consultar Mapas de Peças"
          />
        </TabelaPrecoWrapper>

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
