import React from 'react';
import moment from 'moment';
import { TableActions } from '@digi-tim-19/components';

export const columns = [
  {
    title: 'Desafio',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>,
  },
  {
    title: 'Pesquisa',
    dataIndex: 'challengeData',
    key: 'challengeDataTitle',
    render: (data: any) => <p>{data?.title || ''}</p>,
  },
  {
    title: 'Questões',
    dataIndex: 'challengeData',
    key: 'challengeDataQuestions',
    render: (data: any) => <p>{data?.questions?.length || ''}</p>,
  },
  {
    title: 'Data de criação',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string) => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
  },
  {
    title: 'Data de atualização',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (text: string) => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
  },
  {
    title: 'Ações',
    key: 'action',
    render: (_: any, record: any) => <TableActions data={record} />,
  },
];
