"use strict";

var _taggedTemplateLiteral = require("/home/jenkins/tim-consumer/packages/site-consumer/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  font-size: 23px;\n  font-weight: bold;\n  align-items: center;\n  justify-content: center;\n  p {\n    margin: 0 5px;\n  }\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  .ant-slider {\n    width: 100% !important;\n  }\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  text-align: center;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n      color: red;\n      font-weight: normal;\n    "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n      color: #05a927;\n      font-weight: normal;\n    "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n  ", "\n\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  .ant-slider-track {\n    background: #05a927 !important;\n  }\n  .ant-slider-handle {\n    border-color: #05a927 !important;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  max-width: 220px;\n  width: 100%;\n  font-size: 23px;\n  font-weight: bold;\n  justify-content: center;\n\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  strong {\n    margin-bottom: 10px;\n    color: #aaa;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  strong {\n    margin-bottom: 10px;\n    color: #aaa;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n      opacity: 0.6;\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n      border: 1px solid #ccc;\n      background: #fff;\n      border-radius: 3px;\n      box-shadow: 3px 3px 13px 0px rgba(0, 0, 0, 0.2);\n    "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  padding: 18px 25px;\n  background: #f4f4f4;\n\n  ", "\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var styled_components_1 = __importStar(require("styled-components"));

var antd_1 = require("antd");

exports.Container = styled_components_1.default.div(_templateObject());
exports.Row = styled_components_1.default.div(_templateObject2(), function (_ref) {
  var active = _ref.active;
  return active && styled_components_1.css(_templateObject3());
}, function (_ref2) {
  var last = _ref2.last;
  return last && styled_components_1.css(_templateObject4());
});
exports.Col = styled_components_1.default.div(_templateObject5());
exports.Name = styled_components_1.default.div(_templateObject6());
exports.Slider = styled_components_1.default(antd_1.Slider)(_templateObject7());
exports.Points = styled_components_1.default.p(_templateObject8(), function (_ref3) {
  var active = _ref3.active;
  return active && styled_components_1.css(_templateObject9());
}, function (_ref4) {
  var negative = _ref4.negative;
  return negative && styled_components_1.css(_templateObject10());
});
exports.EligibilityContainer = styled_components_1.default.div(_templateObject11());
exports.Flex = styled_components_1.default.div(_templateObject12());
exports.PositionContainer = styled_components_1.default.div(_templateObject13());