import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  Button,
  JoystickIconSVG,
  LoadingIndicator,
  Table,
  TitleDescription,
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { FilterContainer, Wrapper } from './styles';
import { columns } from './columns';
import { useClient } from '../../../autogenerated/client/client';
import { useRouteMatch } from 'react-router';
import { message } from 'antd';
import { downloadXLSX } from '../../../utils/downloadXlsx';
import { ContainerIcon } from '../../Gamefication/List/styles';
import { usePagination } from '../../../hooks/usePagination';
import { flatMap } from 'lodash';

const itemsFragment = `
  _id
  userId
  userChallengeData
  challengeId
  createdAt
  updatedAt
  challengeData {
    title
    amountChips
    challengeData
  }
  user {
    name
    matricula
    channel
  }
`;

export const GameficationQuizAnswersList = () => {
  const { params }: any = useRouteMatch();
  const downloadUserChallenges = useClient('UserChallengeQuizDownload');

  const { parsed, loading, pagination } = usePagination('UserChallengePagination', {
    initial: {
      page: 1,
      perPage: 10,
      filter: {
        challengeId: params.id,
        acceptDate: true,
      },
    },
    fetchOnMount: !!params.id,
    parseResult: (data) => {
      const questions = data[0]?.challengeData?.challengeData?.questions;
      if (!questions) return [];
      const alternatives = flatMap(questions, (el: any) =>
        el?.alternatives.filter((el: any) => el?.isCorrect).map((el: any) => el?.id),
      );
      return data.map((el: any) => {
        const selected = el?.userChallengeData?.answers
          .map((el: any) => el?.alternativeIdSelected)
          .map((el: any) => alternatives.includes(el));
        const correct = selected.filter((el: any) => el).length;
        const error = selected.filter((el: any) => !el).length;
        return { ...el, correct, error };
      });
    },
    itemsFragment,
  });

  const handleDownloadBase = () => {
    downloadUserChallenges.fetch({ variables: { challengeId: params.id } }).then((response: any) => {
      if (!response?.result?.data || response?.result?.data?.length === 0) {
        message.error('Não há registro para download de planilha');
        return;
      }

      downloadXLSX('quiz-respostas', response?.result?.schema, response?.result?.data);
    });
  };

  return (
    <PageTemplate>
      <React.Fragment>
        <Wrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Gamificação (Quiz)', link: routes.gameficationQuizList.mount() },
              { title: 'Respostas' },
            ]}
          />
          <TitleDescription
            renderCustomIcon={
              <ContainerIcon>
                <JoystickIconSVG />
              </ContainerIcon>
            }
            title="Gamificação (Quiz)"
            description="Listar e consultar as respostas da quiz da gamificação"
          />
        </Wrapper>
        <FilterContainer>
          <Button
            loading={downloadUserChallenges.loading}
            style={{ width: 'max-content' }}
            onClick={() => handleDownloadBase()}
          >
            EXPORTAR RESPOSTAS
          </Button>
        </FilterContainer>
        {loading ? <LoadingIndicator /> : <Table columns={columns} dataSource={parsed} pagination={pagination} />}
      </React.Fragment>
    </PageTemplate>
  );
};
