import React from 'react';
import { Breadcrumb, ProviderSelecionarPublico, TitleDescription } from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { TabelaPrecoWrapper } from './stylesCadastrar';
import { routes } from '../../../config/routes';

export const ComparaAiCadastrar = () => {
  return (
    <PageTemplate>
      <>
        <TabelaPrecoWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Compara Aí', link: routes.comparaAiListar.mount() },
              { title: 'Cadastrar' },
            ]}
          />
          <TitleDescription iconType="column-width" title="Compara Aí" description="Cadastrar" />
        </TabelaPrecoWrapper>

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
