import { useClient } from '../../autogenerated/client/client';

import {
  QueryChannelFindManyArgs,
  SortFindManyChannelInput
} from '../../autogenerated/client/types';

export function useChannelFindMany(
  variables?: Partial<QueryChannelFindManyArgs>
) {
  const ChannelFindMany = useClient('ChannelFindMany', {
    fragment: `
    _id
    createdAt
    name
    parentId
    parentName
    updatedAt
    roleGroups {
      _id
    }
    `,
    variables: {
      limit: 100,
      sort: SortFindManyChannelInput.NameAsc,
      ...variables
    },
    fetchOnMount: true,
    cache: true
  });

  return { ...ChannelFindMany };
}
