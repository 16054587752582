import * as React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { TreeSelect } from 'antd';
import { useContentPermissionsOptionsHelper } from './useContentPermissionsOptionsHelper';
import { makeField } from '@digi-tim-19/components';
import { TreeSelectProps } from 'antd/es/tree-select';
const { SHOW_CHILD } = TreeSelect;

const SelectContentPermissionsWrapper = styled.div`
  width: 100%;
  &&&& {
    .ant-select {
      height: auto;
    }
  }
`;

export const SelectContentPermissions = (
  props: TSelectContentPermissionsProps
) => {
  const { className, data } = props;
  console.log('data', data);

  const extraProps: TreeSelectProps<any> = {
    treeCheckable: true,
    showCheckedStrategy: SHOW_CHILD,
    defaultOpen: false,
    treeDefaultExpandAll: true
  };

  return (
    <SelectContentPermissionsWrapper
      className={cx(
        className,
        'ui-flex SelectContentPermissions SelectContentPermissions_Wrapper'
      )}
    >
      {makeField({
        key: 'permissions.regions',
        field: {
          name: 'permissions.regions',
          label: 'Regionais',
          required: true,
          treeOptions: data.items.regions,
          type: 'treeSelect',
          extraProps
        }
      })}

      {makeField({
        key: 'permissions.contentTypes',
        field: {
          name: 'permissions.contentTypes',
          label: 'Conteúdo',
          required: true,
          treeOptions: data.items.contentTypes,
          type: 'treeSelect',
          extraProps
        }
      })}

      {makeField({
        key: 'permissions.roleGroups',
        field: {
          name: 'permissions.roleGroups',
          label: 'Cargos',
          required: true,
          treeOptions: data.items.roleGroups,
          type: 'treeSelect',
          extraProps
        }
      })}
    </SelectContentPermissionsWrapper>
  );
};

export type TSelectContentPermissionsProps = {
  className?: string;
  children?: React.ReactNode;
  data: ReturnType<typeof useContentPermissionsOptionsHelper>;
};
