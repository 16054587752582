import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 100%;
  & > .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    color: #ffffff;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding: 0 20px;
`;

export const Message = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
