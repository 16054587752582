import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  LoadingIndicator,
  Modal,
  Select
} from '@digi-tim-19/components';
import { useClient } from '../../../autogenerated/client/client';
import {
  EnumMaterialKind,
  ValidityEnum
} from '../../../autogenerated/client/types';
import { message } from 'antd';

const Label = styled.div`
  font-weight: bold;
  font-size: 14px;
  width: 200px;
`;

const LabelSmall = styled.div`
  font-size: 12px;
`;

const GroupField = styled.div`
  margin: 10px 0;
`;

const ModalVideoDestaques = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [detachedVideo, setDetachedVideo] = useState('');
  const [lastVideo1, setLastVideo1] = useState('');
  const [lastVideo2, setLastVideo2] = useState('');
  const [lastVideo3, setLastVideo3] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const getMaterialsOptions = useClient('MaterialFindMany');
  const getVideos = useClient('MaterialFindMany');
  const updateMaterails = useClient('UpdateDeteachedAndLatestVideo');

  const onSubmit = () => {
    setIsLoading(true);
    updateMaterails
      .fetch({
        variables: {
          idDetachedVideo: detachedVideo,
          idLatestVideo1: lastVideo1,
          idLatestVideo2: lastVideo2,
          idLatestVideo3: lastVideo3
        }
      })
      .then((ctx) => {
        setIsLoading(false);
        if (ctx.result) {
          setIsOpenModal(false);
          message.success('Registros salvos com sucesso.');
        } else message.error('Erro ao salvar registros.');
      });
  };

  useEffect(() => {
    if (!isOpenModal) return;
    setIsLoading(true);

    getMaterialsOptions
      .fetch({
        variables: {
          filter: {
            kind: EnumMaterialKind.Video,
            validityEnum: ValidityEnum.Current
          },
          limit: 1000
        }
      })
      .then((response: any) => {
        if (response.result) {
          const optionsVideo = response.result.map((item: any) => ({
            label: item?.title,
            value: item._id
          }));
          const all: any = [
            {
              label: 'Nenhum vídeo',
              value: undefined
            },
            ...optionsVideo
          ];
          setOptions(all);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [isOpenModal]);

  useEffect(() => {
    if (!options.length) return;

    setIsLoading(true);

    getVideos
      .fetch({
        variables: {
          filter: {
            OR: [
              {
                latestPublications: true
              },
              {
                detachedVideo: true
              }
            ],
            kind: EnumMaterialKind.Video
          },
          limit: 4
        }
      })
      .then((ctx) => {
        if (ctx.result) {
          const { result } = ctx;
          setDetachedVideo(result.find((x) => x?.detachedVideo)?._id || '');
          setLastVideo1(
            result.find(
              (x) => x?.latestPublications && x.orderLatestPublications === 1
            )?._id || ''
          );
          setLastVideo2(
            result.find(
              (x) => x?.latestPublications && x.orderLatestPublications === 2
            )?._id || ''
          );
          setLastVideo3(
            result.find(
              (x) => x?.latestPublications && x.orderLatestPublications === 3
            )?._id || ''
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [options]);

  return (
    <div>
      <Button onClick={() => setIsOpenModal(true)}>Destaques</Button>
      <Modal
        title="Destaques e últimas publicações"
        visible={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        footer={[
          <Button type="default" onClick={() => onSubmit()}>
            Salvar
          </Button>
        ]}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <GroupField>
              <Label>Video em destaque</Label>
              <Select
                placeholder="Video em destaque"
                showSearch
                options={options.filter((x: any) => !!x.value)}
                onChange={setDetachedVideo}
                value={detachedVideo}
              />
            </GroupField>
            <GroupField>
              <GroupField>
                <LabelSmall>Vídeo 1</LabelSmall>
                <Select
                  placeholder="Video 1"
                  showSearch
                  options={options}
                  onChange={setLastVideo1}
                  value={lastVideo1}
                />
              </GroupField>

              <GroupField>
                <LabelSmall>Vídeo 2</LabelSmall>
                <Select
                  placeholder="Video 2"
                  showSearch
                  options={options}
                  onChange={setLastVideo2}
                  value={lastVideo2}
                />
              </GroupField>
              <GroupField>
                <LabelSmall>Vídeo 3</LabelSmall>
                <Select
                  placeholder="Video 3"
                  showSearch
                  options={options}
                  onChange={setLastVideo3}
                  value={lastVideo3}
                />
              </GroupField>
            </GroupField>
          </>
        )}
      </Modal>
    </div>
  );
};

export default ModalVideoDestaques;
