import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { TaNaMaoWrapper } from '../Cadastrar/stylesCadastrar';
import { useRouteMatch } from 'react-router';
import { useMaterial } from '../../../hooks/materials/useMaterial';
import { routes } from '../../../config/routes';

export const TaNaMaoEditar = () => {
  const { params } = useRouteMatch();
  const appendToFragment = `
    title
    fileIds
    files {
      _id
      title
      kind
    }
    validity {
      start,
      end,
      notifyEmailsBeforeExpire
    }
    categories {
      name
      parentId
      _id
    }
    description
    availableAtRegions
    availableAtChannels
    availableAtRoleGroups
`;
  const taNaMaoItem = useMaterial(params?.id, appendToFragment);

  return (
    <PageTemplate>
      <>
        <TaNaMaoWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Tá Na Mão' },
              { title: 'Editar' }
            ]}
          />
          <TitleDescription
            iconType="read"
            title="Tá Na Mão"
            description="Editar as revistas 'Tá Na Mão'"
          />
        </TaNaMaoWrapper>

        {taNaMaoItem.result && (
          <ProviderSelecionarPublico>
            <Form initialValues={taNaMaoItem.result} />
          </ProviderSelecionarPublico>
        )}
      </>
    </PageTemplate>
  );
};
