import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useDimensions } from '@digi-tim-19/utils';

export const IncentiveCampaignCard = (props: any) => {
  const { isMobile } = useDimensions();
  const { onClickCard, campaign } = props;
  const titleInfo = isMobile && campaign.title.length >= 15 ? `${campaign.title.slice(0, 15)}...` : campaign.title;
  const textInfo = campaign.description.length <= 15 ? campaign.description : `${campaign.description.slice(0, 15)}...`;
  const dateInfo = `Válido até ${moment(campaign.validity?.end).format('DD/MM/YYYY')}`;
  return (
    <CardConteiner onClick={() => onClickCard(campaign._id)}>
      <ThumbContainer>
        <Thumbnail src={campaign.cardImageFile?.signedUrl ?? ''} />
      </ThumbContainer>
      <InfoContainer>
        <TitleInfo>{titleInfo}</TitleInfo>
        <DescriptionInfo>{textInfo}</DescriptionInfo>
        <DateInfo>{dateInfo}</DateInfo>
      </InfoContainer>
    </CardConteiner>
  );
};

const CardConteiner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  height: 300px;
  width: 200px;
  border-radius: 32px;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: 500px) {
    flex-direction: row;
    width: 100%;
    height: max-content;
  }
`;

const ThumbContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 32px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    width: 100px;
    height: 100px;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 32px;

  @media (max-width: 500px) {
    height: 100%;
  }
`;

const InfoContainer = styled.div`
  width: 200px;
  height: 200px;
  padding: 8px;
  font-size: 12px;

  @media (max-width: 500px) {
    flex: 1;
    width: auto;
    height: auto;
  }
`;

const TitleInfo = styled.h2`
  font-weight: bold;
  font-size: 16px;
  color: #575757;
  margin: 0;
`;

const DescriptionInfo = styled.p`
  margin: 5px 0;
`;

const DateInfo = styled.p`
  margin: 0;
`;
