import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { routes } from '../../config/routes';
import { Input, DatePicker, Pagination } from 'antd';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useHistory, useParams } from 'react-router';
import { Breadcrumb, TitleDescription, CardConsumer, LoadingIndicator } from '@digi-tim-19/components';
import moment from 'moment';
import { useClient } from '../../autogenerated/client/client';
import { EnumMaterialKind, SortFindManyMaterialInput } from '../../autogenerated/client/types';
import { findCorrectName } from '../../utils/productNames';
import { obterImagemPadraoPorMaterial, actionsCardConsumer } from '../../utils/material';
import { EmptyData } from '../../components/utils/Void';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';
import { PaginationWrapper } from '../../components/Page/PaginationWrapper';
import { escapeStringRegexp } from '../../utils/formatters';

const { Search: AntdSearch } = Input;
const { RangePicker } = DatePicker;
const PageContent = styled.div``;

const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

const WrapperCard = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;

  .ant-calendar-range-picker-separator {
    display: none !important;
  }
  @media only screen and (max-width: 900px) {
    .ant-calendar-picker-container {
      width: 100%;
      padding: 0 15px;
    }
    .ant-calendar-range {
      width: 100% !important;
    }
    .ant-calendar-date-panel {
      display: flex !important;
      flex-direction: column !important;
      .ant-calendar-range-part {
        width: 100%;
      }
    }
  }
`;
export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;
  cursor: pointer;
`;

export const ComparaAi = () => {
  const history = useHistory();
  const { category, product } = useParams<{
    category: string;
    product: string;
  }>();

  const perPage = 20;
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);

  const paramCategory = category?.toLowerCase().replace(/-/g, '_');
  const paramProduct = product?.toLowerCase().replace(/-/g, '_');
  const firstFilter = paramCategory && paramProduct ? `menu:${paramCategory}:${paramProduct}` : `menu:${paramCategory}`;

  const [categoryFilter, setCategoryFilter] = useState(firstFilter);

  const [createdAt, setCreatedAt] = useState<{ start: string; end: string }>();
  const [show, setShow] = useState([]);

  const [filterSearch, setFilterSearch] = useState();

  const { listFolders, addFolder } = useFoldersPin();

  const onChange = (e: any) => {
    setCreatedAt({
      start: moment(e[0]!).startOf('day')?.toISOString(),
      end: moment(e[1]!).endOf('day')?.toISOString(),
    });
    setShow(e);
  };

  const getMaterials = useClient('MaterialPagination');

  const total = getMaterials.result?.pageInfo.itemCount;

  const resetFilters = () => {
    setCreatedAt(undefined);
    setFilterSearch(undefined);
    setCategoryFilter(firstFilter);
    setShow([]);
    setPage(1);
  };

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            kind: EnumMaterialKind.CompareThere,
            createdAtRange: createdAt,
            inCategories: [categoryFilter],
            title: !filterSearch ? undefined : `regex(${escapeStringRegexp(filterSearch)})`,
          },
          sort: SortFindManyMaterialInput.IdDesc,
        },
      })
      .then((response: any) => {
        setItems(response.result?.items);
      });
  }, [createdAt, categoryFilter, page, filterSearch]); //eslint-disable-line

  const data = getMaterials?.result;

  const correctName = product ? findCorrectName(product) : '';

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
    },
    [data],
  );

  return (
    <PageTemplate>
      <>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: category ? findCorrectName(category) : '' },
            {
              title: product ? findCorrectName(product) : '',
              link: category && product ? routes.selectFilter.mount(category, product) : '',
            },
            { title: 'Compara Aí' },
          ]}
        />

        <TitleDescription
          iconType={'column-width'}
          title={'Compara Aí'}
          description={`Acesse o conteúdo Compara Aí do ${correctName}`}
        />
        <PageContent>
          <FilterContainer>
            <RangePicker
              getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
              format="DD/MM/YYYY"
              placeholder={['PERÍODO', '']}
              onChange={(e) => {
                setPage(1);
                onChange(e);
              }}
              value={show}
            />
            <Search
              placeholder="Busca título"
              style={{ width: 200 }}
              value={filterSearch}
              onChange={(e) => {
                setPage(1);
                onSearch(e.target.value);
              }}
            />
            <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
          </FilterContainer>
          <WrapperCard>
            {getMaterials.loading ? (
              <LoadingIndicator />
            ) : (
              items.map((item: any, index: number) => {
                const thumb = obterImagemPadraoPorMaterial(item);
                const showButtonDownload = true;
                return (
                  <CardConsumer
                    key={index}
                    hideTitle={true}
                    material={item}
                    defaultImage={thumb}
                    actions={(material) =>
                      actionsCardConsumer(material, routes, history, listFolders, addFolder, showButtonDownload)
                    }
                  />
                );
              })
            )}
          </WrapperCard>
          <PaginationWrapper>
            <Pagination pageSize={perPage} current={page} total={total!} onChange={(page) => setPage(page)} />
          </PaginationWrapper>
        </PageContent>
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    fileIds
    _id
    title
    numericId
    createdAt
    updatedAt
    pinned
    kind
    files {
      _id
      extension
      signedUrl
      kind
      downloadUrl
      url
    }
    validity { 
      start 
      end 
    }
    author { 
      _id
      name 
    }
  }
`;
