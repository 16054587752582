import React from 'react';
import styled from 'styled-components';
import { Breadcrumb, TitleDescription, CardCategory } from '@digi-tim-19/components';
import { useHistory } from 'react-router';
import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';

const StyledContent = styled.section`
  background: transparent;
  display: flex;
  flex-wrap: wrap;
`;

export const Jornada = () => {
  const history = useHistory();

  const items = [
    {
      title: 'Informativos',
      description: 'Conteúdos resumidos que você recebeu por e-mail.',
      urlBackground: '/defaultImages/5356_CardInformativos.jpg',
      icon: 'info-circle',
      url: () => history.push(routes.catInformativos.mount('jornada')),
    },
    {
      title: 'Vídeos',
      description: 'Reforço, argumentação e processos, LIVEs disponíveis no OnTube sobre esse serviço',
      urlBackground: '/defaultImages/5356_CardVideos.jpg',
      icon: 'play-square',
      url: () => history.push(routes.OnTubePlaylist.mount('jornada')),
    },
    {
      title: 'Materiais de Apoio',
      description: 'Aqui você encontra guias de conteúdo e orientações.',
      urlBackground: '/defaultImages/5356_CardMateriaisApoio.jpg',
      icon: 'book',
      url: () => history.push(routes.materiaisApoio.mount('cardapio_smb', 'jornada')),
    },
  ];

  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb items={[{ title: 'Home', link: routes.home.mount() }, { title: 'Jornada' }]} />

        <TitleDescription
          iconType="interaction"
          customIcon={false}
          title="Jornada"
          description="Aqui você encontra materiais que vão te ajudar a ter uma venda com excelência."
        />
        <StyledContent>
          {items.map((card, index) => (
            <CardCategory key={index} {...card} />
          ))}
        </StyledContent>
      </React.Fragment>
    </PageTemplate>
  );
};
