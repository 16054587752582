import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageWrapper } from '../styleForm';
import { routes } from '../../../../config/routes';
import { PageTemplate } from '../../../../components/Layout/PageTemplate';

export const BannerCadastrar = () => {
  const configPage = {
    pageName: 'Banners e cards',
    icon: 'file-image',
    description: 'Gerenciamento de banners e cards da home',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo' },
      { title: 'Banners e cards' },
      { title: 'Cadastrar' }
    ]
  };

  return (
    <PageTemplate>
      <>
        <PageWrapper>
          <Breadcrumb items={configPage.breadcrumb} />
          <TitleDescription
            iconType={configPage.icon}
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
