import React, { useState } from 'react';
import {
  Breadcrumb,
  LoadingIndicator,
  Table,
  TitleDescription
} from '@digi-tim-19/components';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import { useRouteMatch } from 'react-router';
import { useUserContestacao } from '../../../hooks/contestacao/useContestacao';
import { columns } from './columns';
import { ModalContestation } from './ModalContestation';

export const AcompanharContestacao = () => {
  const { params } = useRouteMatch<{ id: string }>();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [dataModal, setDataModal] = useState<any>();

  const { contestacoes, loading } = useUserContestacao(params.id, setInfoModal);

  function setInfoModal(data: any) {
    setModalIsOpen(true);
    setDataModal(data);
  }

  return (
    <PageTemplate>
      <>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        {loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={contestacoes}
            pagination={{
              pageSize: 20,
              current: 1,
              total: contestacoes.length
            }}
          />
        )}

        <ModalContestation
          setModalIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          dataModal={dataModal}
        />
      </>
    </PageTemplate>
  );
};

const configPage = {
  pageName: 'Acompanhar contestação',
  icon: 'trophy',
  description: 'Aqui você pode acompanhar as suas contestações desta campanha',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    {
      title: 'Campanhas Vigentes',
      link: routes.campanhasIncentivoVigentes.mount()
    },
    { title: 'Acompanhar contestação' }
  ]
};
