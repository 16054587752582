import React, { useState, useEffect } from 'react';
import { ListMode } from './utilsFavoritos';
import { SortFindManyPinInput } from '../../autogenerated/client/types';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';
import {
  useGetMaterial,
  useFavoritos,
  useGetIncentiveCampaign
} from './usePin';
import { ListGridFavoritos } from './ListGridFavoritos';
import { CardFavoritos } from './CardFavoritos';
import { EmptyData } from '../../components/utils/Void';
import { LoadingIndicator } from '@digi-tim-19/components';

export const InnerPageData = (props: {
  mode: ListMode;
  userId: string;
  folderId: string;
  sort: SortFindManyPinInput;
}) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const { listFolders, addFolder } = useFoldersPin();
  const { recordIds } = useFavoritos(props.userId, props.folderId, props.sort);

  const { materials, isLoadingMaterial } = useGetMaterial(recordIds);
  const { incentiveCampaigns, isLoadingIncentiveCampaign } =
    useGetIncentiveCampaign(recordIds);

  useEffect(() => {
    setIsEmpty(!materials.length && !incentiveCampaigns.length);
  }, [materials, incentiveCampaigns]);

  useEffect(() => {
    setIsLoading(isLoadingMaterial || isLoadingIncentiveCampaign);
  }, [isLoadingMaterial, isLoadingIncentiveCampaign]);

  if (isLoading) return <LoadingIndicator />;

  if (isEmpty) return <EmptyData />;

  if (props.mode === ListMode.card) {
    return (
      <CardFavoritos
        materials={materials}
        incentiveCampaigns={incentiveCampaigns}
        listFolders={listFolders}
        addFolder={addFolder}
      />
    );
  } else {
    return (
      <ListGridFavoritos
        materials={materials}
        incentiveCampaigns={incentiveCampaigns}
      />
    );
  }
};
