"use strict";

var _slicedToArray = require("/home/jenkins/tim-consumer/packages/site-consumer/node_modules/@babel/runtime/helpers/slicedToArray");

var _defineProperty = require("/home/jenkins/tim-consumer/packages/site-consumer/node_modules/@babel/runtime/helpers/defineProperty");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

function useLess() {
  var _parseArgs = parseArgs.apply(void 0, arguments),
      factory = _parseArgs.factory,
      actions = _parseArgs.actions,
      onChange = _parseArgs.onChange,
      deps = _parseArgs.deps;

  var isFirstRender = React.useRef(true);
  React.useEffect(function () {
    isFirstRender.current = true;
  }, deps);

  var _previousState = React.useRef(null);

  var initial = React.useMemo(function () {
    var reactiveObject = factory(_previousState.current);
    var api = {
      previousState: function previousState() {
        return _previousState.current ? Object.assign({}, _previousState.current) : null;
      },
      getState: function getState() {
        return Object.assign({}, ref.current);
      },
      setState: function setState(s) {
        Object.keys(s).forEach(function (k) {
          reactiveObject[k] = s[k];
        });
        return reactiveObject;
      }
    };
    Object.keys(reactiveObject).forEach(function (key) {
      var color = nextColor();
      var internalValue = reactiveObject[key];
      Object.defineProperty(reactiveObject, key, {
        get: function get() {
          return internalValue;
        },
        set: function set(value) {
          if (internalValue === value) return;
          internalValue = value;
          ref.current = Object.assign(Object.assign({}, ref.current), _defineProperty({}, key, value));
          setState(ref.current);
          onChange && onChange( // @ts-ignore
          key, ref.current, api);

          if (CAN_DEBUG()) {
            requestAnimationFrame(function () {
              var _a, _b;

              var valueString = ellipsis(((_a = value) === null || _a === void 0 ? void 0 : _a.toString) ? value.toString() : value + '');

              if (Array.isArray(value)) {
                valueString = "[".concat(valueString, "]");
              }

              if ((_b = valueString) === null || _b === void 0 ? void 0 : _b.startsWith('[object Object]')) {
                valueString = '<<object>> 🔽';
              }

              console.groupCollapsed("%c set ".concat(key, " to ").concat(valueString, " ").concat(new Date().toLocaleTimeString(), " "), "background: ".concat(color, "; color: #000000; padding: 3px; width: 100%; display: block;"));
              console.log(value);
              console.trace();
              console.groupEnd();
            });
          }
        },
        enumerable: true
      });
    });

    if (!isFirstRender.current) {
      setState(reactiveObject);
    }

    isFirstRender.current = false;
    _previousState.current = Object.assign({}, reactiveObject);
    Object.keys(api).forEach(function (apiEntry) {
      Object.defineProperty(reactiveObject, apiEntry, {
        get: function get() {
          return api[apiEntry];
        },
        enumerable: false
      });
    });
    Object.defineProperty(reactiveObject, 'api', {
      get: function get() {
        return api;
      },
      enumerable: false
    });

    if (actions) {
      var currentActions = actions(api);
      Object.keys(currentActions).forEach(function (key) {
        if (reactiveObject[key]) {
          return console.error("".concat(key, " already defined as ").concat(reactiveObject[key]));
        }

        Object.defineProperty(reactiveObject, key, {
          get: function get() {
            return currentActions[key];
          },
          enumerable: false
        });
      });
    }

    return reactiveObject;
  }, deps);
  var ref = React.useRef(initial);

  var _React$useState = React.useState(initial),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      setState = _React$useState2[1];

  return initial;
}

exports.useLess = useLess;

function nextColor() {
  ++colorIndex;

  if (colorIndex >= colors.length) {
    colorIndex = 0;
  }

  return colors[colorIndex];
}

var colorIndex = -1;
var colors = ['#3366E6', '#99FF99', '#E6B3B3', '#FF99E6', '#CCFF1A', '#FF1A66', '#33FFCC', '#991AFF', '#4DB3FF', '#1AB399', '#E666B3', '#CC9999', '#E6FF80', '#1AFF33', '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', '#FF6633', '#FFB399', '#FF33FF', '#00B3E6', '#E6B333', '#E64D66', '#FF4D4D', '#6666FF'];

var CAN_DEBUG = function CAN_DEBUG() {
  if (typeof window !== 'undefined') {
    return localStorage.debug || window.location.hostname === 'localhost';
  }
};

function parseArgs() {
  var factory;
  var actions;
  var deps = [];

  if (typeof (arguments.length <= 0 ? undefined : arguments[0]) === 'function') {
    factory = arguments.length <= 0 ? undefined : arguments[0];

    if (typeof (arguments.length <= 1 ? undefined : arguments[1]) === 'function') {
      actions = arguments.length <= 1 ? undefined : arguments[1];
    }

    if (Array.isArray(arguments.length <= 1 ? undefined : arguments[1])) {
      deps = arguments.length <= 1 ? undefined : arguments[1];
    }

    if (Array.isArray(arguments.length <= 2 ? undefined : arguments[2])) {
      deps = arguments.length <= 2 ? undefined : arguments[2];
    }

    return {
      factory: factory,
      actions: actions,
      deps: deps
    };
  }

  return arguments.length <= 0 ? undefined : arguments[0];
}

var ellipsis = function ellipsis(str) {
  var max = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  return str.length > max ? "".concat(str.substring(0, max), "...") : str;
};

function createLessContext(args) {
  function _useState(useDepsHook) {
    var deps = useDepsHook();
    return useLess(Object.assign(Object.assign({}, args), {
      deps: deps
    }));
  }

  var Context = React.createContext(null);

  function useState() {
    var state = React.useContext(Context);

    if (!state) {
      debugger;
      throw new Error("Check if Provider was added to react tree");
    }

    return state;
  }

  function useDefaultDepsHook() {
    return [];
  }

  function Provider(props) {
    var state = _useState(props.useDepsHook || useDefaultDepsHook);

    return React.createElement(Context.Provider, {
      value: state
    }, props.children(state));
  }

  return {
    useState: useState,
    Provider: Provider
  };
}

exports.createLessContext = createLessContext;