import { useEffect } from 'react';
import { useClient } from '../../autogenerated/client/client';

const formatCurrency = (value: number) =>
  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(value);

export function useWalletOptions(invoiceOrigin: string) {
  const data = useClient('ReconheceWalletFindMany');

  useEffect(() => {
    data.fetch({
      variables: {
        filter: {
          inInvoiceOrigin: [invoiceOrigin],
        },
        limit: 100,
      },
    });
  }, [invoiceOrigin]);

  return (data.result || [])
    .filter((w) => Number(w?.availableCredit) > 0)
    .map((item) => {
      return {
        value: item?._id || '',
        label: `${item?.POIdentifier?.substring(0, 13)} - ${formatCurrency(item?.availableCredit as number)}` || '',
      };
    });
}
