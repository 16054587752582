import React from 'react';

import {
  Former,
  LoadingIndicator,
  SelecionarPublico,
  usePublico
} from '@digi-tim-19/components';
import styled from 'styled-components';

import {
  EnumFileKind,
  EnumMaterialKind,
  EnumMaterialStatus
} from '../../autogenerated/client/types';
import { message } from 'antd';
import { routes } from '../../config/routes';
import { useHistory } from 'react-router';
import { Container } from './stylesForm';
import { useClient } from '../../autogenerated/client/client';
import { Material } from '../../autogenerated/client/types';
import { useDimensions } from '@digi-tim-19/utils/build';
import { useInformativoProdutos } from '../Informativos/FormInformativos/useInformativoProdutos';
import moment from 'moment';
import { useContentTypePermissions } from '../../hooks/useContentTypePermissions';
import { selectedChannels } from '../../utils/selectedChannels';
const selectedChannelsConsumer = selectedChannels();

const DocKind = EnumFileKind.AnyImage;

export const Divider = styled.hr`
  border: none;
  background: #ccc;
  height: 1px;
  width: 100%;
  margin: 20px 0;
`;

export const Form = (props: TFormMaterialEditProps) => {
  const permissions = useContentTypePermissions();
  const { availableAtChannels, availableAtRegions, availableAtRoleGroups } =
    usePublico();
  const history = useHistory();

  const isUpdate = !!props.initialValues;

  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const createMaterial = useClient('MaterialCreateOne');

  const produtos = useInformativoProdutos();

  const initialValues = {
    ...props.initialValues,
    image: (props.initialValues?.files || []).find(
      (el) => el && el.kind === DocKind
    )?._id,
    category: (props.initialValues?.categories || []).find(
      (el) => el && el.parentId && el._id !== 'menu:informativos'
    )?._id,
    status: props.initialValues?.status?._id,
    validity: props.initialValues?.validity?.start,
    dateExpired: props.initialValues?.validity?.end
  };

  const start = props.initialValues?.validity?.start;
  const end = props.initialValues?.validity?.end;

  let validity: any[] = [];

  if (start && end) validity = [moment(start), moment(end)];

  const { isMobile } = useDimensions();

  if (permissions.loading) return <LoadingIndicator />;

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              list: [
                {
                  className: 'header',
                  inline: !isMobile,
                  list: [
                    {
                      name: 'validity',
                      label: 'Data de Vigência',
                      type: 'rangePicker',
                      extraProps: {
                        format: 'DD/MM/YYYY',
                        defaultValue: validity,
                        getCalendarContainer: (triggerNode: any) =>
                          triggerNode.parentNode
                      },
                      maxWidth: '250px'
                    },
                    {
                      name: 'category',
                      label: 'Categoria do Menu',
                      value: initialValues.category,
                      treeOptions: produtos,
                      type: 'treeSelect',
                      required: true
                    }
                  ]
                },
                {
                  name: 'image',
                  label: '',
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: DocKind,
                    CTA: 'IMPORTAR IMAGEM'
                  }
                },
                {
                  custom: <Divider />
                },
                {
                  name: 'title',
                  label: 'Título',
                  required: true
                },
                {
                  custom: <Divider />
                },
                {
                  className: 'Footer',
                  inline: !isMobile,
                  list: [
                    {
                      custom: (
                        <SelecionarPublico
                          initialValues={props.initialValues}
                          selectedChannels={
                            !isUpdate ? selectedChannelsConsumer : []
                          }
                          {...permissions.filterOptions}
                        />
                      )
                    }
                  ]
                }
              ]
            }
          ],
          submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(routes.notificacaoBackupListar.path)
          }
        }}
        onSubmit={async function ({ data }) {
          const start = data.validity
            ? data.validity[0] || data.validity.start
            : null;
          const end = data.validity
            ? data.validity[1] || data.validity.end
            : null;

          const record = {
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            categories: [data.category].filter(Boolean),
            kind: EnumMaterialKind.CommunicationBackup,
            validity:
              start === null || end === null ? undefined : { start, end },
            fileIds: [data.image].filter(Boolean),
            title: data.title,
            status: EnumMaterialStatus.Approved
          };

          if (isUpdate && data._id) {
            await MaterialUpdateOne.fetch({
              variables: { record, filter: { _id: data._id } }
            }).then((ctx) => {
              if (!ctx.errors) {
                history.push(routes.notificacaoBackupListar.mount());
              }
            });
          } else {
            await createMaterial
              .fetch({
                variables: { record }
              })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success(
                    'Notificação de backup cadastrada com sucesso'
                  );
                  history.push(routes.notificacaoBackupListar.path);
                } else {
                  message.error('Notificação de backup não cadastrada');
                }
              });
          }
        }}
      />
    </Container>
  );
};

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
};
