import imgMapaDeProcessos from './12747_Card_Mapa_de_Processos_TNR (1).jpg';
import imgMapaDeProcessosSMB from './A9156_Card_Medio_Mapa_de_Processos_SMB_MOBILE.jpg';
import imgTaNoApp from './A19680_Card_Mapa_de_Processos_atualização_Tá no App.jpg';
import imgMeuTim from './A19680_Card_Mapa_de_Processos_atualização_Meu TIM.jpg';

export const imgsMapaDeProcessos = {
  imgMapaDeProcessos,
  imgMapaDeProcessosSMB,
  imgTaNoApp,
  imgMeuTim
};
