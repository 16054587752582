// ****************************
//  * Configurações de rotas *
// ****************************

import { registerRoutes } from '@digi-tim-19/components';
import { isDev, consumerRoutes, UrlUtils } from '@digi-tim-19/utils';

declare global {
  interface Window {
    _APP_ROUTES_PREFIX_?: string;
  }
}

// o valor é injetado via scripts/server.js
// export const ROUTES_PREFIX = isDev() ? '' : window._APP_ROUTES_PREFIX_ || '';
export const ROUTES_PREFIX = ""

export const routes = registerRoutes(consumerRoutes, ROUTES_PREFIX, [
  '/',
  '/portal',
  '/login',
  '/open'
]);

export const openRoutes = [
  '/',
  '/login',
  '/open' // <-- usar para rotas abertas - ex: recuperar senha
];
