import React from 'react';
import { Breadcrumb, ProviderSelecionarPublico, TitleDescription } from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { TabelaPrecoWrapper } from '../Cadastrar/stylesCadastrar';
import { useRouteMatch } from 'react-router';
import { useMaterial } from '../../../hooks/materials/useMaterial';
import { routes } from '../../../config/routes';

export const ComparaAiEditar = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const appendToFragment = `
    title
    fileIds
    files {
      _id
      title
      kind
    }
    validity {
      start,
      end,
      notifyEmailsBeforeExpire
    }
    categories {
      _id
      name
      parentId
    }
    description
    availableAtRegions
    availableAtChannels
    availableAtRoleGroups
`;
  const tabelaItem = useMaterial(params?.id, appendToFragment);

  return (
    <PageTemplate>
      <>
        <TabelaPrecoWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Compara Aí', link: routes.comparaAiListar.mount() },
              { title: 'Editar' },
            ]}
          />
          <TitleDescription iconType="column-width" title="Compara Aí" description="Listar e consultar Compara Aí" />
        </TabelaPrecoWrapper>

        {tabelaItem.result && (
          <ProviderSelecionarPublico>
            <Form initialValues={tabelaItem.result} />
          </ProviderSelecionarPublico>
        )}
      </>
    </PageTemplate>
  );
};
