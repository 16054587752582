import React from 'react';
import { Modal as AntdModal, Icon, Input, Radio as AntdRadio } from 'antd';
import styled from 'styled-components';
import { ChallengeProps } from './UserChallengeCard';

const { TextArea } = Input;

export interface QuestionInterface {
  id: string;
  description?: string;
  type?: string;
  alternatives?: {
    id: string;
    description: string;
  }[];
}

export interface AnswerInterface {
  questionId?: string;
  answer?: string | string[];
}

interface ModalResearchChallengeProps {
  visible?: boolean;
  challenge?: Partial<ChallengeProps>;
  loading?: boolean;
  onOk: (answers: AnswerInterface[]) => Promise<void>;
  onCancel: () => void;
}

interface SelectRadioMultipleProps {
  questionId: string;
  alternatives?: {
    id: string;
    description: string;
  }[];
  onChange: (answer: AnswerInterface) => void;
}

const Modal = styled(AntdModal)`
  .ant-modal-body {
    max-height: 350px !important;
    padding: 16px 24px !important;
    overflow: auto;
  }

  .ant-radio-group {
    width: 100% !important;
  }
`;

const Question = styled.p`
  margin: 0;
`;

const TextAreaInput = styled(TextArea)`
  margin: 20px 0;
`;

const RangeContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 20px;
`;

const Radio = styled(AntdRadio)`
  display: block;
  padding: 8px;
  white-space: break-spaces !important;
  flex: 1;
`;

const RadioGroupContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const RadioContainer = styled.div`
  display: flex;
  width: 100% !important;
  align-items: center;
  align-content: space-between;
  flex-wrap: wrap;
  &:hover {
    background-color: #ededed;
    border-radius: 10px;
  }
`;

const RangeOption = styled.div<{ $checked?: boolean }>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #dddddd;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  ${(props) => (props?.$checked ? 'border: 1px solid gray;' : '')}
  &:hover {
    border: 1px solid gray;
  }

  > i {
    position: absolute;
    top: -7px;
    right: -9px;
  }

  > i svg {
    fill: gray !important;
  }
`;

const SelectRadioMultiple = ({ questionId, alternatives, onChange }: SelectRadioMultipleProps) => {
  const [checkeds, setCheckeds] = React.useState<string[]>([]);

  const handleCheckRadio = (questionId: string, description: string) => {
    setCheckeds((prevState) => {
      if (prevState.includes(description)) {
        const filtered = prevState.filter((el) => el !== description);
        onChange({
          questionId,
          answer: filtered.length > 0 ? [...filtered] : undefined,
        });
        return [...filtered];
      }
      onChange({
        questionId,
        answer: [...prevState, description],
      });
      return [...prevState, description];
    });
  };

  return (
    <RadioGroupContainer>
      {alternatives &&
        alternatives.map((alternative) => {
          return (
            <RadioContainer>
              <Radio
                checked={checkeds.includes(alternative.description)}
                key={`alternative-${alternative.id}`}
                value={alternative.description}
                onClick={() => handleCheckRadio(questionId, alternative.description)}
              >
                {alternative.description}
              </Radio>
            </RadioContainer>
          );
        })}
    </RadioGroupContainer>
  );
};

export const ModalResearchChallenge = ({
  visible,
  challenge,
  onOk,
  onCancel,
  loading,
}: ModalResearchChallengeProps) => {
  const [answers, setAnswers] = React.useState<AnswerInterface[]>([]);

  const handleChangeAnswer = (value: AnswerInterface) => {
    setAnswers((prevState: AnswerInterface[]) => {
      const filtered = prevState.filter((el: AnswerInterface) => el.questionId !== value.questionId);
      if (value?.answer) {
        return [...filtered, { ...value }];
      }
      return [...filtered];
    });
  };

  const handleCheckAllAnswers = (questions: QuestionInterface[], answers: AnswerInterface[]) => {
    return Boolean(
      questions.find((question) => {
        return !Boolean(answers.find((answer) => answer.questionId === question.id));
      }),
    );
  };

  return (
    <Modal
      okButtonProps={{
        disabled: handleCheckAllAnswers(challenge?.challengeData?.questions || [], answers),
      }}
      okText="Finalizar"
      cancelText="Fechar"
      title={challenge?.challengeData?.title || `Pesquisa`}
      visible={visible}
      onOk={async () => {
        await onOk([...answers]);
      }}
      onCancel={() => onCancel()}
      confirmLoading={loading}
    >
      {challenge?.challengeData?.questions?.map((question: QuestionInterface, index: number) => {
        return (
          <React.Fragment key={`question-quiz-${index}`}>
            <Question>{question?.description}</Question>
            {question?.type === 'text' && (
              <TextAreaInput
                value={answers?.find((answer: AnswerInterface) => answer.questionId === question.id)?.answer}
                onChange={(e: any) =>
                  handleChangeAnswer({
                    questionId: question.id,
                    answer: e?.target?.value,
                  })
                }
              />
            )}
            {question?.type === 'select' && question && question?.alternatives && (
              <Radio.Group
                value={answers?.find((answer: AnswerInterface) => answer.questionId === question.id)?.answer}
                onChange={(e: any) => {
                  handleChangeAnswer({
                    questionId: question.id,
                    answer: e?.target?.value,
                  });
                }}
              >
                <RadioGroupContainer>
                  {question?.alternatives.map((alternative) => {
                    return (
                      <RadioContainer>
                        <Radio key={`alternative-${alternative.id}`} value={alternative.description}>
                          {alternative.description}
                        </Radio>
                      </RadioContainer>
                    );
                  })}
                </RadioGroupContainer>
              </Radio.Group>
            )}
            {question?.type === 'multiple-select' && question && question?.alternatives && (
              <SelectRadioMultiple
                questionId={question.id}
                alternatives={question.alternatives}
                onChange={(answer) => {
                  handleChangeAnswer(answer);
                }}
              />
            )}
            {question?.type === 'range' && (
              <RangeContainer>
                {[...Array(11).keys()].map((el: any, index: number) => {
                  return (
                    <RangeOption
                      key={`range-${index}`}
                      onClick={() =>
                        handleChangeAnswer({
                          questionId: question.id,
                          answer: String(el),
                        })
                      }
                      $checked={
                        answers?.find((answer: AnswerInterface) => answer.questionId === question.id)?.answer ===
                        String(el)
                      }
                    >
                      {el}
                      {answers?.find((answer: AnswerInterface) => answer.questionId === question.id)?.answer ===
                        String(el) && <Icon type="check-circle" theme="filled" />}
                    </RangeOption>
                  );
                })}
              </RangeContainer>
            )}
          </React.Fragment>
        );
      })}
    </Modal>
  );
};
