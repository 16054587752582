import React, { useEffect, useState, useCallback } from 'react';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select,
  LoadingIndicator
} from '@digi-tim-19/components';
import { message, DatePicker } from 'antd';
import { useHistory } from 'react-router';
import { columns } from './columns';
import { formatDate } from '@digi-tim-19/utils/build';
import { useClient } from '../../../autogenerated/client/client';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyMaterialInput
} from '../../../autogenerated/client/types';

import {
  NotificationsWrapper,
  FilterContainer,
  Button,
  CleanButton,
  Search
} from './stylesListar';
import { routes } from '../../../config/routes';
import moment from 'moment';
import { useFilterAuthor } from '../../../components/FilterAuthor/useFilterAuthor';
import { formatCategoriesNamesList } from '../../../utils/categories';
import { escapeStringRegexp } from '../../../utils/formatters';

const { RangePicker } = DatePicker;

export const InformativosListar: React.FC<TInformativosListarProps> = () => {
  const filterAuthor = useFilterAuthor();
  const perPage = 10;
  const [createdAt, setCreatedAt] = useState();
  const [sort, setSort] = useState(SortFindManyMaterialInput.IdDesc);
  const [page, setPage] = useState(1);
  const [updateTable, setUpdateTable] = useState('');
  //const [search, setSearch] = useState('');
  //const [filterData, setFilterData] = useState<any>([])
  const [filterSearch, setFilterSearch] = useState();
  const [show, setShow] = useState([]);

  const [items, setItems] = useState([]);

  const [updatedAt, setUpdatedAt] = useState();
  const [showUpdatedAt, setShowUpdatedAt] = useState([]);

  const history = useHistory();

  const MaterialDeleteOne = useClient('MaterialRemoveOne');

  const getMaterials = useClient('MaterialPagination');

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          limit: 50000,
          filter: {
            kind: EnumMaterialKind.Communication,
            validityEnum: ValidityEnum.Any,
            includeDrafts: true,
            createdAtRange: createdAt,
            updatedAtRange: updatedAt,
            title: !filterSearch ? undefined : `regex(${escapeStringRegexp(filterSearch)})`,
            inCategories: ['informativos', 'menu:informativos'],
            filterAuthor: filterAuthor.filter,
            owner: filterAuthor.ownerFilter
          },
          sort: sort
        }
      })
      .then((response: any) => {
        setItems(response?.result?.items);
      });
  }, [
    sort,
    filterSearch,
    createdAt,
    page,
    updateTable,
    filterAuthor.signature,
    updatedAt
  ]);

  const resetFilters = () => {
    setSort(SortFindManyMaterialInput.IdDesc);
    setFilterSearch(undefined);
    setCreatedAt(undefined);
    // setSearch('');
    // setFilterData([]);
    setShow([]);
    setPage(1);
    filterAuthor.reset();

    setUpdatedAt(undefined);
    setShowUpdatedAt([]);
  };

  const total = getMaterials?.result?.pageInfo.itemCount;

  const data = items?.map((material: any) => {
    const categoriesSelect = material?.categories
      ?.filter(
        (item: any) =>
          item?._id !== 'menu:informativos' && item._id.split(':').length === 2
      )
      .map((item: any) => item.name);

    const subCategoriesSelect = material?.categories
      ?.filter(
        (item: any) =>
          item?._id !== 'menu:informativos' && item._id.split(':').length > 2
      )
      .map((item: any) => item.name);

    let categoriesName = formatCategoriesNamesList(categoriesSelect);
    if (material.categories.length === 2) {
      const isOthers = material.categories.some(
        (item: any) => item._id === 'menu:outros'
      );
      if (isOthers) categoriesName = 'Outros';
      else {
        let categoryFind = material.categories.find(
          (item: any) => item.parentId !== 'menu'
        );
        if (!categoryFind)
          categoryFind = material.categories.find(
            (item: any) => item.parentId !== 'menu:informativos'
          );

        if (categoryFind) categoriesName = categoryFind.parentCategory.name;
      }
    }

    const subCategoriesName = formatCategoriesNamesList(subCategoriesSelect);

    const dataStart = material?.validity?.start
      ? formatDate(material?.validity?.start)
      : 'Data inválida';

    return {
      changedBy: material.changedBy,
      key: material?._id,
      id: material?.numericId,
      title: material?.title,
      sendDate: dataStart,
      category: categoriesName,
      product: subCategoriesName,
      criador: material?.author?.name,
      cadastrada: formatDate(material?.createdAt, 'DD/MM/YYYY'),
      atualizada: formatDate(material?.updatedAt, 'DD/MM/YYYY'),
      onDuplicate: () => {
        history.push(routes.informativosDuplicar.mount(material?._id));
      },
      onRead: () =>
        history.push(routes.visualizarInformativo.mount(material?._id)),
      onEdit: () =>
        history.push(routes.informativosEditar.mount(material?._id)),
      onDelete: () =>
        MaterialDeleteOne.fetch({
          variables: { filter: { _id: material?._id } },
          afterMutate: /^Material/
        }).then((ctx) => {
          message.success('Informativo deletado com sucesso');
          setUpdateTable(ctx.result?.recordId);
        })
    };
  });

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
      //setFilterData(data?.filter(material => material.title.toLowerCase().includes(e.toLowerCase())))
    },
    [data]
  );

  const onChange = (e: any) => {
    if (e.length > 0) {
      setCreatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setShow(e);
    } else {
      setShow([]);
      setCreatedAt(undefined);
    }
  };

  const onChangeUpdatedAt = (e: any) => {
    if (e.length > 0) {
      setUpdatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setShowUpdatedAt(e);
    } else {
      setShowUpdatedAt([]);
      setUpdatedAt(undefined);
    }
  };

  return (
    <PageTemplate>
      <>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Informativos' },
              { title: 'Listar' }
            ]}
          />
          <TitleDescription
            iconType="info-circle"
            title="Informativos"
            description="Listar e consultar os informativos"
          />
        </NotificationsWrapper>
        <FilterContainer>
          <Button to={routes.informativosCadastrar.path}>
            CADASTRAR INFORMATIVOS
          </Button>
          <Select
            placeholder="Ordenar por:"
            options={order}
            onChange={(e) => {
              setSort(e);
              setPage(1);
            }}
            value={sort}
          />
          <Search
            placeholder="Busca título"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e) => {
              setPage(1);
              onSearch(e.target.value);
            }}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format="DD/MM/YYYY"
            placeholder={['PERÍODO', '']}
            onChange={(e) => {
              setPage(1);
              onChange(e);
            }}
            value={show}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={'DD/MM/YYYY'}
            placeholder={['ATUALIZAÇÃO', '']}
            onChange={(e) => {
              setPage(1);
              onChangeUpdatedAt(e);
            }}
            value={showUpdatedAt}
          />

          {filterAuthor.render()}

          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>

        {getMaterials.loading && <LoadingIndicator />}
        {!getMaterials.loading && (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e) => setPage(e)
            }}
          />
        )}
      </>
    </PageTemplate>
  );
};

export type TInformativosListarProps = {};

const order = [
  { label: 'MAIS ANTIGO', value: SortFindManyMaterialInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyMaterialInput.IdDesc }
];

const appendToFragment = `
pageInfo {
  perPage
  hasNextPage
  hasPreviousPage
  itemCount
  pageCount
}
items {
    changedBy {
      name
      dateText
    },
  _id
  title
  createdAt
  updatedAt
  numericId
  validity { 
    start 
  }
  categories { 
    _id, 
    name, 
    parentId, 
    parentCategory { 
      name, 
      _id
    }
  }
  author { 
    _id, 
    name 
  }, 
}
`;
