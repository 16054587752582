import React from 'react';
import { colors } from '@digi-tim-19/theme';
import { Avatar } from 'antd';
import { AvatarCustom } from './stylesRankingTable';
import chipIcon from '../../Gamefication/Participant/assets/GAMEFICATION_CHIP.png';

type ConfigColumnRankingTable = {
  id: string;
  key: string;
  label: string;
  style: {
    xl?: number;
    sm?: number;
    align?: string;
  };
  renderRowCell?: (item: any, obj: any, index?: number) => JSX.Element;
};

export type ConfigRankingTable = {
  header?: {
    style?: Record<string, any>;
  };
  body?: {
    style?: Record<string, any>;
  };
  myPosition?: {
    style?: Record<string, any>;
  };
  lastPosition?: {
    style?: Record<string, any>;
  };
  columns: ConfigColumnRankingTable[];
};

export const configRankiingTable: ConfigRankingTable = {
  header: {
    style: { backgroundColor: '#1E60BE', color: colors.white },
  },
  body: {
    style: { padding: 5, color: colors.blue },
  },
  myPosition: {
    style: { backgroundColor: '#cfebff', color: '#1E60BE', padding: 5 },
  },
  lastPosition: {
    style: { backgroundColor: '#1E60BE', color: colors.white, padding: 5 },
  },
  columns: [
    {
      id: 'user',
      key: 'userContent',
      label: 'Usuário',
      style: { xl: 10, sm: 16, align: 'center' },
      renderRowCell: (userContent: any) => {
        const url = userContent?.avatar?.signedUrl;
        const position = userContent?.position;
        const name = userContent?.name?.toUpperCase();
        return (
          <div
            className="userInfo"
            style={{ display: 'flex', width: '100%', padding: '0 20px', alignItems: 'center', gap: '20px' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '50px',
                border: '1px solid #28508C',
                borderRadius: '10px',
                fontSize: '24px',
                fontWeight: 'bold',
                height: '55px',
              }}
            >
              {position}
            </div>
            {url ? (
              <AvatarCustom src={url} />
            ) : (
              <Avatar size={50} icon="user" style={{ border: '2px solid #F80000' }} />
            )}
            {name}
          </div>
        );
      },
    },
    {
      id: 'region',
      key: 'userContent.region',
      label: 'Regional',
      style: { xl: 4, sm: 16 },
      renderRowCell: (value: string) => <p>{value?.toUpperCase()}</p>,
    },
    {
      id: 'channel',
      key: 'userContent.channels[0]',
      label: 'Canal',
      style: { xl: 4, sm: 16 },
      renderRowCell: (value: string) => <p>{value?.replace(/_/g, ' ').toUpperCase()}</p>,
    },
    {
      id: 'nivel',
      key: 'userContent.userLevel',
      label: 'Nível',
      style: { xl: 1, sm: 1 },
      renderRowCell: (value: string) => <p>{value}</p>,
    },
    {
      id: 'chips',
      key: 'userContent.gamefication.chips',
      label: 'Total de chips',
      style: { xl: 4, sm: 16, align: 'center' },
      renderRowCell: (chips) => (
        <div className="userChips" style={{ display: 'flex', alignItems: 'center' }}>
          <img style={{ height: '40px' }} src={chipIcon}></img>
          <p style={{ margin: 0, color: '#F80000', fontWeight: 'bold', fontSize: '22px', marginLeft: '10px' }}>
            {chips}
          </p>
        </div>
      ),
    },
  ],
};
