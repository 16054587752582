import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Checkbox, Button, Pagination, message } from 'antd';
import { Breadcrumb, TitleDescription, ListGrid, SelectFilter, LoadingIndicator } from '@digi-tim-19/components';
import { formatDate } from '@digi-tim-19/utils/build';
import { ListGridItemProps } from '@digi-tim-19/components/build/main/ListGrid/PropsListGrid';

import { useClient } from '../../autogenerated/client/client';
import { SortFindManyNotificationInput } from '../../autogenerated/client/types';
import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { acessarMaterial } from '../../utils/material';
import { EmptyData } from '../../components/utils/Void';

// Styles -----------------------------------------------------------------------------------------

const NotificationsWrapper = styled.div`
  .actions {
    display: block;
    margin-top: 60px;
    margin-bottom: 30px;
    .ant-select {
      width: 200px;
      i {
        color: ${({ theme }) => theme.blue};
      }
    }
    .actions-group {
      @media screen and (min-width: 1024px) {
        display: flex;
        background-color: #f6f6f6;
        flex: 1;
        align-items: center;
        border-radius: 4px;
        margin-left: 40px;
      }
    }
    .action {
      margin: 8px 0;
    }
    @media screen and (min-width: 768px) {
      display: flex;
      & .action {
        margin: 0;
        margin-left: 20px;
      }
    }
    & .action {
      & button {
        outline: 0;
        border: none;
        background: transparent;
      }
    }
  }
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

// Config -----------------------------------------------------------------------------------------
const configPage = {
  pageName: 'Notificações',
  icon: 'bell',
  description: 'Lista de Notificações',
  breadcrumb: [{ title: 'Home', link: routes.home.mount() }, { title: 'Notificações' }],
};

// Component -----------------------------------------------------------------------------------------
export const Notificacoes = () => {
  const history = useHistory();
  const [readIds, setReadIds] = useState<any>();
  const [allNotificationsUser, setAllNotificationUser] = useState<string[]>([]);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [sortNotifications, setSortNotifications] = useState(SortFindManyNotificationInput.CreatedatDesc);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataListGridItems, setDataListGridItems] = useState<ListGridItemProps[]>([]);
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [checkedAll, setCheckedAll] = useState(false);
  const [selectItems, setSelectItems] = useState<string[]>([]);
  const perPage = 10;

  const getUser = useClient('User', {
    fetchOnMount: true,
    appendToFragment: `
    notifications {
      readIds
      unreadIds
    }`,
  });

  const getNotifications = useClient('NotificationPagination');
  const markReadNotifications = useClient('UserMarkNotificationAsRead');
  const deleteNotification = useClient('UserDeleteNotification');
  const deleteManyNotification = useClient('UserDeleteManyNotification');

  useEffect(() => {
    if (getUser.result) {
      const { notifications } = getUser.result;
      if (notifications && !readIds) {
        const reads = getUser.result.notifications?.readIds?.map((item: any) => item) || [];
        const unreads = getUser.result.notifications?.unreadIds?.map((item: any) => item) || [];
        setReadIds(reads);
        setAllNotificationUser([...reads, ...unreads]);
      }
    }
  }, [getUser.result]);

  function updateUserNotifications(notificationsIds: string[]) {
    markReadNotifications
      .fetch({
        variables: {
          isRead: true,
          notifications: notificationsIds,
        },
        afterMutate: /^User/,
      })
      .then(() => {
        getUser.fetch();
      });
  }

  useEffect(() => {
    getNotifications
      .fetch({
        appendToFragment: appendToFragmentNotifications,
        variables: {
          sort: sortNotifications,
          perPage: perPage,
          page: currentPage,
          filter: {
            _ids: allNotificationsUser,
          },
        },
      })
      .then((ctx) => {
        if (ctx.result) {
          setNotifications(ctx.result?.items!);
          setTotalRegisters(ctx.result?.count || 0);

          if (ctx.result.items) {
            const notificationIds = ctx.result.items?.map((item) => item?._id);
            updateUserNotifications(notificationIds);
          }
        }
      });
  }, [allNotificationsUser, sortNotifications, currentPage]);

  function accessNotification(notification: any) {
    if (!notification?.material && notification?.title && String(notification.title).substring(0, 9) === 'O Desafio') {
      return history.push(routes.gamefication.mount());
    }

    const notificationLink = notification?.material?.notificationLink;

    if (notificationLink) {
      const isHttpLink = notificationLink.startsWith('http');

      if (isHttpLink) {
        const linkWithHttp = isHttpLink ? notificationLink : 'http://' + notificationLink;

        return (window.location.href = linkWithHttp);
      }
    }

    if (notification?.event?.kind === 'live') {
      const _id = notification?.parentId || '';
      return history.push(routes.OnTubePlayer.mount(`${_id}?kind=live`));
    }

    return acessarMaterial(notification?.material, history, routes);
  }

  function resetFilters(pageSelected = 1) {
    setCheckedAll(false);
    setSelectItems([]);
    setCurrentPage(pageSelected);
    setReadIds(undefined);
    getUser.fetch();
  }

  useEffect(() => {
    if (notifications.length) {
      const items: ListGridItemProps[] = notifications.map((notification) => {
        const isRead = readIds?.find((item: any) => item === notification._id) || false;
        const isSelected = selectItems.includes(notification?._id);
        let titleCard = '';

        if (notification.material) {
          if (notification.material.categories) {
            titleCard = notification.material.categories
              .filter((item: any) => item._id !== 'menu:notificacoes')
              .map((item: any) => item.name)
              .join(' - ');
          }
        }

        const canAccess = () => {
          const isCommunication = notification?.material?.kind === 'communication';
          const hasNotificationLink = notification?.material?.notificationLink;

          if (isCommunication && !hasNotificationLink) {
            return false;
          }

          return true;
        };

        return {
          data: formatDate(notification?.sentAt, 'DD/MM/YYYY HH:mm'),
          tituloHeader: notification?.title || '',
          tituloCard: titleCard,
          descricao: notification?.body || '',
          imgBody: notification?.iconUrl || '/defaultImages/thumb-listgrid-notificacao.jpg',
          isRead: !isRead,
          allowBullet: true,
          renderWithCheckbox: true,
          isSelected,
          clickCheckbox: () => {
            if (isSelected) {
              return setSelectItems(selectItems.filter((item) => item !== notification?._id));
            }
            return setSelectItems([...selectItems, notification?._id]);
          },
          actions: {
            access: canAccess(),
            onAccess: () => accessNotification(notification),
            delete: true,
            onDelete: () => {
              deleteNotification
                .fetch({
                  variables: { notificationId: notification?._id },
                  afterMutate: /^User/,
                })
                .then((ctx) => {
                  message.success('Notificação excluida com sucesso!');
                  resetFilters(currentPage);
                });
            },
          },
        };
      });

      setDataListGridItems(items);
    } else {
      setDataListGridItems([]);
    }
  }, [notifications, selectItems]);

  function selectAllItems() {
    setCheckedAll((value) => !value);
    const items = getNotifications.result?.items?.map((item) => item?._id);

    if (items?.length === selectItems.length) {
      return setSelectItems([]);
    }
    return items && setSelectItems(items);
  }

  return (
    <PageTemplate>
      <NotificationsWrapper>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription iconType={configPage.icon} title={configPage.pageName} description={configPage.description} />

        <div className="actions">
          <SelectFilter
            selects={selectsOrders}
            onChange={(e: any) => {
              setSortNotifications(e);
              resetFilters();
            }}
          />

          <div className="actions-group">
            <div className="action">
              <Checkbox checked={checkedAll} onChange={selectAllItems}>
                selecionar todas
              </Checkbox>
            </div>
            {selectItems.length > 0 && (
              <div className="action">
                <Button
                  type="link"
                  icon="delete"
                  onClick={() => {
                    deleteManyNotification
                      .fetch({
                        variables: { notificationIds: selectItems },
                        afterMutate: /^User/,
                      })
                      .then(() => {
                        resetFilters();
                        message.success('Notificações deletadas com sucesso!');
                      });
                  }}
                >
                  Deletar notificações selecionadas
                </Button>
              </div>
            )}
          </div>
        </div>

        {getNotifications.loading ? (
          <LoadingIndicator />
        ) : dataListGridItems.length ? (
          <React.Fragment>
            <ListGrid items={dataListGridItems as ListGridItemProps[]} />

            <PaginationWrapper>
              <Pagination
                current={currentPage}
                total={totalRegisters}
                pageSize={perPage}
                onChange={(page) => {
                  resetFilters(page);
                  window.scroll(0, 0);
                }}
              />
            </PaginationWrapper>
          </React.Fragment>
        ) : (
          <EmptyData />
        )}
      </NotificationsWrapper>
    </PageTemplate>
  );
};

// Utils -----------------------------------------------------------------------------------------
const appendToFragmentNotifications = `
  items {
    _id
    title
    body
    iconUrl
    parentId
    sentAt
    event {
      kind
    }
    material {
      _id
      notificationLink
      kind
      html
      categories {
        _id
        name
        sort
      }
      files {
        extension
        kind
        downloadUrl
      }
    }
  }
`;

const selectsOrders = [
  {
    defaultValue: 'Ordenar Por',
    action: '',
    options: [
      {
        name: 'Mais antigo',
        value: SortFindManyNotificationInput.CreatedatAsc,
      },
      {
        name: 'Mais recente',
        value: SortFindManyNotificationInput.CreatedatDesc,
      },
    ],
  },
];
