import styled from 'styled-components';
import { Modal as AntdModal, Icon as AntdIcon } from 'antd';

export const Wrapper = styled.div`
  width: 100%;
`;

export const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;
  .ant-calendar-range-picker-separator {
    display: none !important;
  }
  @media only screen and (max-width: 900px) {
    .ant-calendar-picker-container {
      width: 100%;
      padding: 0 15px;
    }
    .ant-calendar-range {
      width: 100% !important;
    }
    .ant-calendar-date-panel {
      display: flex !important;
      flex-direction: column !important;
      .ant-calendar-range-part {
        width: 100%;
      }
    }
  }
`;

export const Modal = styled(AntdModal)`
  .ant-modal-body {
    max-height: 350px !important;
    padding: 16px 24px !important;
    overflow: auto;
  }
`;

export const Icon = styled(AntdIcon)`
  font-size: 23px;
  cursor: pointer;
`;

export const ModalQuestion = styled.p`
  margin-bottom: 5px;
  font-weight: 600;
`;

export const ModalAnswer = styled.p`
  margin-bottom: 10px;
  font-style: italic;
  font-size: 11pt;
`;
