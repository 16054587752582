import React, { useState } from 'react';
import { SubHeader } from '@digi-tim-19/components';
import styled from 'styled-components';

import { MainHeader } from './MainHeader';
import { MainMenu } from './MainMenu';
import { SiteFooter } from './SiteFooter';
import { siteImages } from '../../assets/images';
import { MainSocialFeed } from './MainSocialFeed';
import { useDimensions } from '@digi-tim-19/utils/build';

const StyledContainer = styled.div`
  display: flex;
`;

const StyledContent = styled.section`
  padding: 20px;
  width: 100%;
  min-height: 710px;
  @media screen and (min-width: 768px) {
    padding: 20px 10px 20px 100px;
  }
`;

export const PageTemplate = ({ children }: { children: React.ReactChild }) => {
  const [menuIsOpen, toggleMenu] = useState(false);

  const { isMobile } = useDimensions();

  return (
    <React.Fragment>
      <SubHeader logoURL={siteImages.logoTim} />

      <MainHeader menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />

      <StyledContainer>
        <MainMenu menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />

        {!isMobile && <MainSocialFeed />}

        <StyledContent>{children}</StyledContent>
      </StyledContainer>

      <SiteFooter />
    </React.Fragment>
  );
};
