import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { Breadcrumb, JoystickIconSVG, LoadingIndicator, Table, TitleDescription } from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { CleanButton, FilterContainer, Wrapper, Search } from './styles';
import { columns } from './columns';
import { useClient } from '../../../autogenerated/client/client';
import { useHistory } from 'react-router';
import { orderBy } from 'lodash';
import { TypeChallenge } from '../../Gamefication/Form/Form';
import { ContainerIcon } from '../../Gamefication/List/styles';
import { escapeStringRegexp } from '../../../utils/formatters';

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    title
    amountChips
    challengeData
    createdAt
    updatedAt
  }
`;

export const GameficationResearchList = () => {
  const history = useHistory();
  const getChallenges = useClient('ChallengePagination');
  const [page, setPage] = React.useState(1);
  const [filterChallengeTitle, setFilterChallengeTitle] = React.useState();
  const [data, setData] = React.useState<any>([]);
  const perPage = 10;
  const total = getChallenges?.result?.pageInfo?.itemCount || 0;

  const resetFilters = () => {
    setFilterChallengeTitle(undefined);
    setPage(1);
  };

  const handleGetGameficationChallenge = (filterChallengeTitle: any) => {
    getChallenges
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            typeChallenge: TypeChallenge.research,
            title: !filterChallengeTitle || filterChallengeTitle === '' ? undefined : `regex(${escapeStringRegexp(filterChallengeTitle)})`,
          },
        },
      })
      .then((response: any) => {
        const items = response?.result?.items?.map((challenge: any) => {
          return {
            ...challenge,
            onRead: () => {
              history.push(routes.gameficationResearchesAnswersList.mount(challenge?._id));
            },
          };
        });

        setData([...items]);
      });
  };

  React.useEffect(() => {
    handleGetGameficationChallenge(filterChallengeTitle);
  }, [filterChallengeTitle, page]);

  return (
    <PageTemplate>
      <React.Fragment>
        <Wrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Gamificação (Pesquisas)' },
              { title: 'Listar' },
            ]}
          />
          <TitleDescription
            renderCustomIcon={
              <ContainerIcon>
                <JoystickIconSVG />
              </ContainerIcon>
            }
            title="Gamificação (Pesquisas)"
            description="Listar e consultar os desafios de pesquisas da gamificação"
          />
        </Wrapper>
        <FilterContainer>
          <Search
            placeholder="Busca por título do desafio"
            style={{ width: 150 }}
            value={filterChallengeTitle}
            onChange={(e: any) => {
              setFilterChallengeTitle(e.target.value);
              setPage(1);
            }}
          />
          <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
        </FilterContainer>
        {getChallenges.loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e: any) => setPage(e),
            }}
          />
        )}
      </React.Fragment>
    </PageTemplate>
  );
};
