import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router';
import ReactHtmlParser from 'react-html-parser';
import { useMaterial } from '../../hooks/materials/useMaterial';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { TitleDescription, Button } from '@digi-tim-19/components';
import { colors } from '@digi-tim-19/theme';
import { EnumFileKind } from '../../autogenerated/client/types';

const PageContent = styled.div``;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const TitleMaterialWrapper = styled.div`
  color: ${colors.blue};
  font-size: 25px;
  font-family: TimBold;
`;

export const ConteudoHTML = () => {
  const { params } = useRouteMatch();

  const material = useMaterial(
    params?.id,
    ' files { title url signedUrl kind downloadUrl signedUrl } categories { _id name } ',
  ).result;

  const [fileDownload, setFileDownload] = useState<any>();

  useEffect(() => {
    if (material) {
      if (material?.files?.length) {
        const file = material.files.find(
          (el) => el && (el.kind === EnumFileKind.AnyDoc || el.kind === EnumFileKind.Pdf),
        );
        setFileDownload(file);
      }
    }
  }, [material]);

  const configPage = {
    pageName: 'Processos',
    icon: 'schedule',
  };

  return (
    <PageTemplate>
      <React.Fragment>
        <TitleDescription iconType={configPage.icon} title={configPage.pageName} description={''} />
        <PageContent>
          <HeaderWrapper>
            <TitleMaterialWrapper>{material?.title}</TitleMaterialWrapper>
            {fileDownload ? (
              <Button
                onClick={() => {
                  if (fileDownload) {
                    let a = document.createElement('a');
                    a.href = fileDownload?.signedUrl || '';
                    a.download = fileDownload?.title || '';
                    a.target = '_blank';
                    a.click();
                  }
                }}
              >
                Download
              </Button>
            ) : null}
          </HeaderWrapper>
          {ReactHtmlParser(material?.html || '')}
        </PageContent>
      </React.Fragment>
    </PageTemplate>
  );
};
