import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select,
  LoadingIndicator
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';

import { Wrapper, FilterContainer, Search, CleanButton } from './styles';
import { columns } from './columns';

import {
  SortFindManyCampaignContestationInput,
  ContestationStatusFilterEnum
} from '../../../autogenerated/client/types';
import { useClient } from '../../../autogenerated/client/client';
import { useChannelFindMany } from '../../../hooks/channels/channelFindMany';
import { useRoleGroupFindMany } from '../../../hooks/roleGroups/roleGroupFindMany';
import { useRegionFindMany } from '../../../hooks/regions/useRegionsFindMany';
import { getStatusTranslation } from '../../../hooks/contestacao/useContestacao';
const configPage = {
  pageName: 'Contestação',
  icon: 'audit',
  description:
    'Solucionar as contestações solicitadas pelos usuários nas páginas internas das campanhas',
  routeNew: '',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Incentivo', link: routes.campanhasIncentivoListar.mount() },
    { title: 'Contestação', link: routes.contestacaoListar.mount() }
  ],
  perPage: 9,
  sort: SortFindManyCampaignContestationInput.IdDesc
};
const { pageName, icon, description, breadcrumb, perPage, sort } = configPage;

const appendToFragment = `
  pageInfo {
    itemCount
  }
  items {
    protocol
    _id
    indicator
    owner
    parentId
    campaign {
      title
    }
    _id
    user {
      name
      channel
      region
      roleGroup
      matricula
    }
    response {
      status
    }
  }
`;

export const ContestacaoListar = () => {
  const history = useHistory();
  const ChannelFindMany = useChannelFindMany();
  const RoleGroupFindMany = useRoleGroupFindMany();
  const RegionFindMany = useRegionFindMany();
  const CampaignContestationPagination = useClient(
    'CampaignContestationPagination'
  );
  const [page, setPage] = useState(1);
  const [channel, setChannel] = useState();
  const [region, setRegion] = useState();
  const [role, setRole] = useState();
  const [search, setSearch] = useState<number>();
  const [status, setStatus] = useState<
    ContestationStatusFilterEnum | undefined
  >();

  const toSelect = (Materials: any = []) =>
    Materials?.map((Material: any) => ({
      label: Material?.name,
      value: Material?._id
    }));

  const regionOption = toSelect(RegionFindMany?.result);
  const channelOption = toSelect(ChannelFindMany?.result);
  const roleOption = toSelect(RoleGroupFindMany?.result);
  const statusOption = [
    {
      label: 'Procedente',
      value: ContestationStatusFilterEnum.Founded
    },
    {
      label: 'Improcedente',
      value: ContestationStatusFilterEnum.Unfounded
    }
  ];

  useEffect(() => {
    CampaignContestationPagination.fetch({
      appendToFragment,
      variables: {
        filter: {
          status,
          protocol: search,
          user:
            (role && { roleGroup: role }) ||
            (channel && { channel }) ||
            (region && { region })
        },
        sort,
        perPage,
        page
      }
    });
  }, [page, role, region, channel, status, search]); //eslint-disable-line

  const contestations = CampaignContestationPagination?.result?.items;
  const total = CampaignContestationPagination?.result?.pageInfo.itemCount;

  const data = contestations?.map((contestation) => {
    return {
      key: contestation?._id,
      title: contestation?.campaign?.title,
      status: getStatusTranslation(contestation?.response?.status),
      owner: contestation?.user?.name,
      region: contestation?.user?.region,
      channel: contestation?.user?.channel,
      role: contestation?.user?.roleGroup,
      protocol: contestation?.protocol,
      onRead: () =>
        history.push(routes.contestacaoEditar.mount(contestation?._id))
    };
  });

  const resetFilters = () => {
    setSearch(undefined);
    setPage(1);
    setRegion(undefined);
    setRole(undefined);
    setStatus(undefined);
    setChannel(undefined);
  };

  return (
    <PageTemplate>
      <Wrapper>
        <Breadcrumb items={breadcrumb} />
        <TitleDescription
          iconType={icon}
          title={pageName}
          description={description}
        />

        <FilterContainer>
          <Select
            placeholder="canal"
            options={channelOption}
            value={channel}
            onChange={(e) => {
              setPage(1);
              setChannel(e);
            }}
          />
          <Select
            placeholder="regional"
            options={regionOption}
            value={region}
            onChange={(e) => {
              setPage(1);
              setRegion(e);
            }}
          />
          <Select
            placeholder="cargo"
            options={roleOption}
            value={role}
            onChange={(e) => {
              setPage(1);
              setRole(e);
            }}
          />
          <Select
            placeholder="status"
            options={statusOption}
            value={status}
            onChange={(e) => {
              setPage(1);
              setStatus(e);
            }}
          />
          <Search
            placeholder="Buscar pelo protocolo"
            value={search}
            onChange={(e) => {
              setPage(1);
              setSearch(Number(e.target.value));
            }}
          />
          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>
        {CampaignContestationPagination?.loading === true ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e) => setPage(e)
            }}
          />
        )}
      </Wrapper>
    </PageTemplate>
  );
};
