import React, { useState } from 'react';
import { rem } from '@digi-tim-19/components';
import styled from 'styled-components';

import { PageWrapper } from '../../components/Page/PageWrapper';
import { AcessButtons } from './AccessButtons';
import { LoginForm } from './LoginForm';
import { AccessCPF } from './AccessCPF';

// import casal from '../../assets/images/casal.png';
// import blackFriday2021 from '../../assets/images/black_friday_2021.png';
// import natal from '../../assets/images/TNR_Ano_novo.jpg';
// import fundo2022 from '../../7570-tela-login-02_B_limpo.jpg'
//import tnr2022 from '../../assets/images/tela-login-consumer-09-06-22-01.jpg';
import timLogo from '../../assets/images/tim_logo.png';
import timZeDelivery from '../../assets/images/login_ze_delivery.png';

//import consumer from '../../assets/images/consumerBranco.png';
import consumer from '../../assets/images/consumerLogoLogin.png';

import ScreenLogin from './Screens';
import { NewRegister } from './NewRegister';
import { NewPassword } from './NewPassword';
import { TempPassword } from './TempPassword';

const RenderScreen = (
  screen: ScreenLogin,
  setScreen: Function,
  value: string,
  setValue: Function,
  password: string,
  setPassword: Function,
) => {
  switch (screen) {
    case ScreenLogin.BUTTONS:
      return <AcessButtons onClickSetScreen={setScreen} />;
    case ScreenLogin.ACCESS_CPF:
      return <AccessCPF setScreen={setScreen} setValue={setValue} />;
    case ScreenLogin.NEW_REGISTER:
      return (
        <NewRegister
          value={value}
          accessCPFProps={{
            value: value,
            setScreen: setScreen,
            setValue: setValue,
            password: password,
            setPassword: setPassword,
          }}
        />
      );
    case ScreenLogin.NEW_PASSWORD:
      return (
        <NewPassword
          value={value}
          accessCPFProps={{
            value: value,
            setScreen: setScreen,
            setValue: setValue,
            password: password,
            setPassword: setPassword,
          }}
        />
      );
    case ScreenLogin.TEMP_PASSWORD:
      return (
        <TempPassword
          accessCPFProps={{
            value: value,
            setScreen: setScreen,
            setValue: setValue,
            password: password,
            setPassword: setPassword,
          }}
        />
      );
    case ScreenLogin.LOGIN:
      return (
        <LoginForm
          value={value}
          accessCPFProps={{
            value: value,
            setScreen: setScreen,
            setValue: setValue,
            password: password,
            setPassword: setPassword,
          }}
        />
      );
    default:
      return <AcessButtons onClickSetScreen={setScreen} />;
  }
};

export const Login = (props: TLoginProps) => {
  const [screen, setScreen] = useState(ScreenLogin.BUTTONS);
  const [CPF, setCPF] = useState('');
  const [password, setPassword] = useState('');

  return (
    <StyledPageWrapper>
      <Main>
        <MainRow>
          <ImageConsumer>{/* <img src={timZeDelivery} className="Login_casal" alt={'Imagem'} /> */}</ImageConsumer>

          <Right>
            <LogoConsumer>{<img src={consumer} alt={'Consumer'} />}</LogoConsumer>

            <ContentLogin>
              {screen !== ScreenLogin.BUTTONS && (
                <BackLink
                  onClick={() => {
                    setScreen(ScreenLogin.BUTTONS);
                  }}
                >
                  {'< voltar'}
                </BackLink>
              )}

              {RenderScreen(screen, setScreen, CPF, setCPF, password, setPassword)}
            </ContentLogin>
          </Right>
        </MainRow>
        <LogoTim>
          <img src={timLogo} />
        </LogoTim>
      </Main>
    </StyledPageWrapper>
  );
};

export type TLoginProps = {
  className?: string;
  children?: React.ReactNode;
};

// Styled
const StyledPageWrapper = styled(PageWrapper)`
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${require('./assets/O13902_Tela_de_Login_Tá_Na_Rede_D.jpg')});

  .Login_logo {
    width: 76px;
  }

  .Login_casal {
    object-fit: cover;
    margin-top: -1px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  @media (max-width: 960px) {
    .Login_casal {
      width: 90%;
    }
  }

  @media (max-width: 480px) {
    background-image: url(${require('./assets/7570-tela-login-02_B_limpo.jpg')});
  }

  @media (max-width: 768px) {
    .Login_form {
      padding: 30px 30px 0 30px;
    }
  }
`;

const Main = styled.main`
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex: 1;
  flex-direction: column;
  display: flex;
`;

const MainRow = styled.main`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const LogoTim = styled.main`
  padding-bottom: 25px;
  width: 100%;
  position: relative;
  bottom: 25px;
  img {
    position: absolute;
    right: 5%;
    max-height: 25px;
  }
`;

const ImageConsumer = styled.div`
  width: 100%;
  display: none;

  @media screen and (min-width: 1024px) {
    max-width: 35vw;
    display: flex;
  }
`;

const LogoConsumer = styled.div`
  margin-bottom: 1rem;

  img {
    width: 100%;
    max-width: 390px;
    height: 190px;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const Right = styled.div`
  width: 100%;
  padding: ${rem(20)};
  max-width: ${rem(430)};
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

  @media screen and (min-width: 1023px) {
    margin-left: 250px;
    margin-right: 0;
  }
`;

const ContentLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BackLink = styled.div`
  color: ${({ theme }) => theme.white};
  text-decoration: underline;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
  }
`;
