import React, { useMemo } from 'react';

import {
  Former,
  SelecionarPublico,
  usePublico,
  SearchUser,
  LoadingIndicator
} from '@digi-tim-19/components';

import {
  EnumFileKind,
  EnumMaterialKind
} from '../../autogenerated/client/types';
import { message } from 'antd';
import { routes } from '../../config/routes';
import { useHistory, useRouteMatch } from 'react-router';
import { Container } from './Cadastrar/stylesCadastrar';
import { useClient } from '../../autogenerated/client/client';
import { Material } from '../../autogenerated/client/types';
import { useContentTypePermissions } from '../../hooks/useContentTypePermissions';
import { useCategoriesSubCategoriesForm } from '../../hooks/categories/useCategoriesSubCategoriesForm';
import { findCorrectName, findCorrectKind } from '../../utils/productNames';
import { selectedChannels } from '../../utils/selectedChannels';
const selectedChannelsConsumer = selectedChannels();

const DocKind = EnumFileKind.AnyDoc;
const ImageKind = EnumFileKind.CardThumb;

export const Form = (props: TFormMaterialEditProps) => {
  const { params } = useRouteMatch();
  const permissions = useContentTypePermissions();
  const {
    availableAtChannels,
    availableAtRegions = [],
    availableAtRoleGroups = []
  } = usePublico();
  const history = useHistory();

  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const createMaterial = useClient('MaterialCreateOne');

  const { categories, categoriesSelected, selectCategory } =
    useCategoriesSubCategoriesForm(
      `menu:veja_mais:regional:${params.kind ?? ''}`,
      props.initialValues
    );

  const isUpdate = !!props.initialValues;

  const initialValues = useMemo(() => {
    const categories = props?.initialValues?.categories?.map(
      (item: any) => item._id
    );
    return {
      ...props.initialValues,
      documento: (props.initialValues?.files || []).find(
        (el) => el && el.kind === DocKind
      )?._id,
      cardimage: (props.initialValues?.files || []).find(
        (el) => el && el.kind === ImageKind
      )?._id,
      categories:
        props?.initialValues?.categories?.map((item: any) => item._id) || [],
      notifyEmailsBeforeExpire:
        props.initialValues?.validity?.notifyEmailsBeforeExpire
    };
  }, [props.initialValues]);

  if (permissions.loading) return <LoadingIndicator />;

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              className: 'message-line-wrapper-form',
              inline: true,
              list: [
                {
                  name: 'validity',
                  label: 'Data de Vigência',
                  type: 'rangePicker',
                  required: true,
                  extraProps: {
                    format: 'DD/MM/YYYY'
                  },
                  maxWidth: '250px'
                },
                {
                  name: 'documento',
                  label: '',
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: DocKind,
                    CTA: 'IMPORTAR Documento'
                  }
                },
                {
                  name: 'cardimage',
                  label: '',
                  type: 'upload',
                  extraProps: {
                    kind: ImageKind,
                    CTA: 'IMPORTAR IMAGEM (240x180)'
                  }
                }
              ]
            },

            {
              name: 'kind',
              label: 'Conteúdo',
              required: true,
              defaultValue: findCorrectName(params?.kind),
              disabled: true
            },
            {
              name: 'categories',
              label: 'Categorias',
              options: categories,
              required: true,
              extraProps: {
                mode: 'tags',
                onSelect: (value: string) => selectCategory(value, true),
                onDeselect: (value: string) => selectCategory(value, false)
              }
              // defaultValue: findCorrectName(params?.kind),
            },
            {
              list: [
                {
                  name: 'title',
                  label: 'TÍTULO',
                  required: true,
                  validate: (value: any) => {
                    const format = /[#%&;]/;
                    if (format.test(value))
                      return 'Os caracteres #%&; não são permitidos';

                    if (value)
                      return !(value.length > 110)
                        ? undefined
                        : 'Máximo de 110 caracteres';

                    return undefined;
                  }
                },
                {
                  name: 'description',
                  label: 'DESCRIÇÃO',
                  type: 'textarea',
                  extraProps: {
                    rows: 5
                  },
                  validate: (value: any) => {
                    const format = /[#%&]/;
                    if (format.test(value))
                      return 'Os caracteres #%& não são permitidos';

                    if (value)
                      return !(value.length > 1200)
                        ? undefined
                        : 'Máximo de 1200 caracteres';

                    return undefined;
                  }
                }
              ]
            },
            {
              name: 'tags',
              label: 'tags',
              type: 'tags'
            },
            {
              name: 'notifyEmailsBeforeExpire',
              label: 'Notificar antes de expirar',
              placeholder: 'Notificar antes de expirar',
              extraProps: {
                returnProp: 'email'
              },
              component: SearchUser
            },
            {
              className: 'Footer',
              inline: true,
              list: [
                {
                  custom: (
                    <SelecionarPublico
                      initialValues={props.initialValues}
                      selectedChannels={
                        !isUpdate ? selectedChannelsConsumer : []
                      }
                      {...permissions.filterOptions}
                    />
                  )
                }
              ]
            }
          ],
          submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(routes.regionalListar.path)
          }
        }}
        onSubmit={async function ({ data }) {
          const subCategories = data.subCategories || [];

          const record = {
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            kind: findCorrectKind(params?.kind || ''),
            categories: categoriesSelected,
            validity: {
              start: data.validity[0],
              end: data.validity[1],
              notifyEmailsBeforeExpire: data.notifyEmailsBeforeExpire
            },
            fileIds: [data.documento, data.cardimage].filter(Boolean),
            title: data.title,
            description: data.description,
            tags: data.tags
          };

          if (isUpdate && data._id) {
            await MaterialUpdateOne.fetch({
              variables: { record, filter: { _id: data._id } }
            }).then((ctx) => {
              if (!ctx.errors) {
                history.push(routes.regionalListar.mount(params.kind ?? ''));
              }
            });
          } else {
            await createMaterial
              .fetch({
                variables: { record }
              })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success('Documento cadastrada com sucesso');
                  history.push(routes.regionalListar.mount(params.kind ?? ''));
                } else {
                  message.error('Documento não cadastrada');
                }
              });
          }
        }}
      />
    </Container>
  );
};

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
};
