import { UrlUtils } from '@digi-tim-19/utils';

const urlFromEnv = process.env.REACT_APP_API_URL;

if (!urlFromEnv) {
  throw new Error(
    `cria um arquivo .env na raiz do projeto seguindo o exemplo no arquivo .env.example`
  );
}

export const API_URL = urlFromEnv;
export const API_DOMAIN_URL = UrlUtils.getDomainUrl(
  urlFromEnv,
  window.location.href
);
