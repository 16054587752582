import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;
  background: #ededed;
  padding: 20px;
  input:read-only {
    background: #f8f8f8;
  }
  textarea:read-only {
    background: #f8f8f8;
  }
`;

export const Divider = styled.hr`
  border: none;
  background: #ccc;
  height: 1px;
  width: 100%;
  margin: 20px 0;
`;
