import React from 'react';
import { useHistory } from 'react-router';
import { Breadcrumb, TitleDescription, Table, LoadingIndicator } from '@digi-tim-19/components';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';

import { CampanhaIncentivoListWrapper, Button, FilterContainer } from './styles';
import { columns } from './columns';
import { formatDate } from '@digi-tim-19/utils/build';
import { usePagination } from '../../../hooks/usePagination';

const configPage = {
  pageName: 'Campanhas Externas',
  icon: 'trophy',
  description: 'Cadastramento de campanhas de parceiros.',
  messageDeletedSuccess: 'Campanha excluida com sucesso',
  textButtonNew: 'CADASTRAR NOVA',
  routeNew: routes.campanhasIncentivoCadastrar.mount(),
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo' },
    { title: 'Campanhas Externas' },
    { title: 'Listar' },
  ],
};

export const CampanhaExternaListar = () => {
  const history = useHistory();
  const { parsed, pagination, loading } = usePagination('ExternalCampaignPagination', {
    initial: {
      page: 1,
      perPage: 10,
    },
    itemsFragment: `
      _id
      title
      createdAt
      clientKey
      invoiceOrigin
      changedBy {
        name
        dateText
      }
      status
    `,
    parseResult: (data) =>
      data.map((campaign) => ({
        key: campaign._id,
        title: campaign.title,
        status: campaign.status?.label,
        clientKey: campaign.clientKey,
        invoiceOrigin: campaign.invoiceOrigin,
        changedBy: campaign.changedBy,
        cadastrada: formatDate(campaign?.createdAt, 'DD/MM/YYYY'),
        onEdit: () => history.push(routes.campanhasIncentivoExternasEditar.mount(campaign._id)),
      })),
  });

  return (
    <PageTemplate>
      <CampanhaIncentivoListWrapper>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription iconType={configPage.icon} title={configPage.pageName} description={configPage.description} />
        <FilterContainer>
          <Button to={routes.campanhasIncentivoExternasEditar.mount('new')}>{configPage.textButtonNew}</Button>
        </FilterContainer>
        {loading ? <LoadingIndicator /> : <Table columns={columns} dataSource={parsed} pagination={pagination} />}
      </CampanhaIncentivoListWrapper>
    </PageTemplate>
  );
};
