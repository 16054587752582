import React from 'react';
import { Breadcrumb, ProviderSelecionarPublico, TitleDescription } from '@digi-tim-19/components';
import { FormUploadPontos } from '../Form/FormUploadPontos';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { UploadWrapper } from './stylesCadastrar';
import { routes } from '../../../config/routes';

export const UploadPontosCampanha = () => {
  return (
    <PageTemplate>
      <>
        <UploadWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Incentivo' },
              { title: 'Upload de TIM Coins e estorno' },
            ]}
          />
          <TitleDescription
            iconType=""
            title="Upload de TIM Coins"
            description="Upload da planilha de crédito de TIM Coins das campanhas."
          />
        </UploadWrapper>

        <ProviderSelecionarPublico>
          <FormUploadPontos />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
