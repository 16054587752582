import React, { useState } from 'react';
import { AccessCPFProps } from './AccessCPF';
import { Input, notification } from 'antd';
import styled from 'styled-components';
import { Button, LoadingIndicator } from '@digi-tim-19/components';
import { useClient } from '../../autogenerated/client/client';
import ScreenLogin from './Screens';
import { MaskedInput } from 'antd-mask-input';
import { cpf, onlyNumbers } from '@digi-tim-19/utils/build';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export type NewRegisterProps = {
  accessCPFProps: AccessCPFProps;
};

export const TempPassword = ({ accessCPFProps }: NewRegisterProps) => {
  const [email, setEmail] = useState('');

  const maskedEmail = useClient('ConsumerPublicUser');

  const tempPassword = useClient('ConsumerTempPassword');

  const loading = maskedEmail.loading || tempPassword.loading;

  return (
    <Wrapper className="ui-flex NewRegister_Wrapper">
      <p
        style={{ color: 'white' }}
      >{`Digite seu CPF e confirme seu e-mail para receber uma senha temporária válida por 24h:`}</p>

      <MaskedInput
        disabled={maskedEmail.loading}
        value={accessCPFProps.value}
        placeholder="Preencha seu CPF"
        mask="111.111.111-11"
        onChange={(e) => {
          const currentValue = onlyNumbers(e.target.value);
          const finishedTyping = currentValue.length === 11;
          const isValid = finishedTyping && cpf.isValidCPF(currentValue);
          accessCPFProps.setValue(currentValue);

          if (isValid) {
            maskedEmail.fetch({ variables: { CPF: currentValue } });
          }
        }}
      />

      <Input
        placeholder={maskedEmail.result?.email ?? ''}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />

      {maskedEmail.result && (
        <p style={{ color: 'white', fontSize: '10px' }}>
          Caso o e-mail não esteja correto, entre em contato com o{' '}
          <b>{maskedEmail.result?.msgWrogEmail}</b> para atualizar o cadastro.
        </p>
      )}

      <Button
        disabled={!email}
        onClick={() => {
          if (email) {
            tempPassword
              .fetch({
                variables: { CPF: accessCPFProps.value!, email }
              })
              .then((ctx) => {
                if (ctx.errors?.length) {
                  return;
                }

                accessCPFProps.setScreen(ScreenLogin.NEW_PASSWORD);
                notification.success({
                  message: `Um e-mail foi enviado contendo a senha temporária`
                });
              });
          }
        }}
      >
        Confirmar E-mail
      </Button>

      {loading && <LoadingIndicator small color={'white'} />}
    </Wrapper>
  );
};
