import React from 'react';
import { useHistory } from 'react-router';
import { useContentTypePermissions } from '../../../hooks/useContentTypePermissions';
import { Container } from '../Create/styles';
import { Former, LoadingIndicator } from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { message } from 'antd';

interface FormGameficationNivelProps {
  initialValues?: any;
}

export const FormGameficationNivel = (props: FormGameficationNivelProps) => {
  const permissions = useContentTypePermissions();
  const history = useHistory();
  const [values, setValues] = React.useState(props?.initialValues || {});
  const createGameficationNivel = useClient('GameficationNivelCreateOne');
  const updateGameficationNivel = useClient('GameficationNivelUpdateById');
  const isUpdate = !!props.initialValues;

  const initialValues = React.useMemo(() => {
    return {
      title: values?.title,
      nivel: values?.nivel,
      amountChips: values?.amountChips,
    };
  }, [values]);

  if (permissions.loading) return <LoadingIndicator />;

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={() => {
          return {
            fields: [
              {
                inline: true,
                list: [
                  {
                    name: 'title',
                    label: 'TÍTULO',
                    required: true,
                    validate: (value: any) => {
                      const format = /[#%&;]/;
                      if (format.test(value)) return 'Os caracteres #%&; não são permitidos';

                      if (value) return !(value.length > 110) ? undefined : 'Máximo de 110 caracteres';

                      return undefined;
                    },
                  },
                ],
              },
              {
                inline: true,
                list: [
                  {
                    name: 'nivel',
                    label: 'Posição',
                    type: 'number',
                    required: true,
                    validate: (value: any) => {
                      if (value) {
                        if (value < 1) {
                          return 'Posição não pode ser menor que 1.';
                        } else if (value > 999) {
                          return 'Posição precisa ser menor que 1000.';
                        }
                      }

                      return undefined;
                    },
                  },
                ],
              },
              {
                inline: true,
                list: [
                  {
                    name: 'amountChips',
                    label: 'Quantidade de chips',
                    type: 'number',
                    required: true,
                    validate: (value: any) => {
                      if (value) {
                        if (value < 1) {
                          return 'Quantidade de chips não pode ser menor que 1.';
                        } else if (value > 99999) {
                          return 'Quantidade de chips precisa ser menor que 100000.';
                        }
                      }

                      return undefined;
                    },
                  },
                ],
              },
            ],
            submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
            cancelButton: {
              label: 'CANCELAR',
              onClick: () => history.push(routes.gameficationNivelList.path),
            },
          };
        }}
        onSubmit={async ({ data }) => {
          const record: any = {
            title: data.title,
            nivel: data.nivel,
            amountChips: data.amountChips,
          };

          const callback = (ctx: { result?: any }) => {
            if (ctx?.result) {
              message.success(`Nível ${isUpdate ? 'atualizado' : 'cadastrado'} com sucesso.`);
              history.push(routes.gameficationNivelList.mount());
            } else {
              setValues({ ...record });
            }
          };

          if (isUpdate) {
            await updateGameficationNivel
              .fetch({ variables: { record: { ...record, _id: props.initialValues!._id } } })
              .then(callback);
          } else {
            await createGameficationNivel.fetch({ variables: { record } }).then(callback);
          }

          return;
        }}
      />
    </Container>
  );
};
