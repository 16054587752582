import React from 'react';
import { TemplatesEmailItem } from '@digi-tim-19/components/build/main/TemplatesEmail/TemplatesEmailProps';
import {
  TemplateEmailConsumer01Visualization,
  TemplateEmailConsumer02Visualization,
  TemplateEmailConsumer03Visualization,
  TemplateEmailConsumer04Visualization,
  TemplateEmailConsumer05Visualization
} from '@digi-tim-19/components';
import styled from 'styled-components';

const Iframe = styled.iframe.attrs({
  className: 'ui-flex PageLoadHTML_Iframe'
})`
  border: none;
  width: 100%;
  min-height: 100vh;
`;

type PropsSwitchTemplate = {
  templateName: string;
  templateProps: TemplatesEmailItem;
  html: string;
  ref?: HTMLDivElement;
};

export const SwitchTemplate = ({
  templateName,
  templateProps,
  html
}: PropsSwitchTemplate) => {
  const iframeRef = React.useRef<HTMLFrameElement | null>(null);

  React.useEffect(() => {
    if (html) {
      iframeRef.current?.contentDocument?.write(html);
      iframeRef.current?.contentDocument?.close();
    }
  }, [html]);

  switch (templateName) {
    case 'templateConsumer01':
      return <TemplateEmailConsumer01Visualization {...templateProps} />;
    case 'templateConsumer02':
      return <TemplateEmailConsumer02Visualization {...templateProps} />;
    case 'templateConsumer03':
      return <TemplateEmailConsumer03Visualization {...templateProps} />;
    case 'templateConsumer04':
      return <TemplateEmailConsumer04Visualization {...templateProps} />;
    case 'templateConsumer05':
      return <TemplateEmailConsumer05Visualization {...templateProps} />;
    default:
      if (html !== '')
        return (
          <Iframe
            ref={(ref: any) => {
              if (ref) {
                iframeRef.current = ref;
              }
            }}
            id={'iframe-html-preview'}
          />
        );

      return <React.Fragment></React.Fragment>;
  }
};
