import React from 'react';
import { typesChallenge } from '../Form/Form';
import moment from 'moment';
import { TableActions } from '@digi-tim-19/components';

const getTypeChallengeLabel = (typeChallenge: any) => {
  if (!typeChallenge) return '';
  const filtered = typesChallenge.find((el: any) => el.value === typeChallenge);
  return filtered?.label || '';
};

export const columns = [
  {
    title: 'ID',
    dataIndex: 'challengeNumericId',
    key: 'challengeNumericId',
    render: (text: string) => <p>{text || '-'}</p>,
  },
  {
    title: 'Desafio',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>,
  },
  {
    title: 'Ação',
    dataIndex: 'typeChallenge',
    key: 'kind',
    render: (text: string, obj: any) => <p>{getTypeChallengeLabel(text || obj?.action)}</p>,
  },
  {
    title: 'Data de início',
    dataIndex: 'startDate',
    key: 'startDate',
    render: (text: string) => <p>{moment(text).format('DD/MM/YYYY')}</p>,
  },
  {
    title: 'Data de expiração',
    dataIndex: 'endDate',
    key: 'endDate',
    render: (text: string) => <p>{text ? moment(text).format('DD/MM/YYYY') : '-'}</p>,
  },
  {
    title: 'Premiação',
    dataIndex: 'amountChips',
    key: 'amountChips',
    render: (_: string, record: any) => {
      return (
        <p>{record?.typePayment === 'chips' ? `${record.amountChips} Chip(s)` : `${record.amountPoints} Ponto(s)`}</p>
      );
    },
  },
  {
    title: 'Atualizado por',
    dataIndex: 'updatedBy',
    key: 'updatedBy',
    render: (_: string, record: any) => {
      return <p>{record?.updatedBy?.name ? record?.updatedBy?.name : '-'}</p>;
    },
  },
  {
    title: 'Criado por',
    dataIndex: 'owner',
    key: 'owner',
    render: (_: string, record: any) => {
      return <p>{record?.owner?.name ? record?.owner?.name : '-'}</p>;
    },
  },
  {
    title: 'Ações',
    key: 'action',
    render: (_: any, record: any) => (
      <TableActions data={record} confirmationMessage={'Deseja excluir esse desafio?'} />
    ),
  },
];
