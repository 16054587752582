import { useClient } from '../../autogenerated/client/client';

export function useRoleGroupFindMany() {
  const variables = { limit: 100 };

  const RoleGroupFindMany = useClient('RoleGroupFindMany', {
    variables,
    fetchOnMount: true
  });

  return { ...RoleGroupFindMany };
}
