import React from 'react';
import { Modal } from '@digi-tim-19/components';
import { OriginUserDataSession, UserSession } from '@digi-tim-19/utils/build';
import { Button } from 'antd';
import { useRegulations } from '../../hooks/regulations/useRegulations';

export const Regulamento: React.FC<TRegulamentoProps> = () => {
  const { pendingTerm, visible, updateAcceptTerm } = useRegulations();

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        UserSession.clear();
        OriginUserDataSession.clear();
        document.location.href = '/';
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            UserSession.clear();
            OriginUserDataSession.clear();
            document.location.href = '/';
          }}
        >
          Sair
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            if (pendingTerm) updateAcceptTerm(pendingTerm);
          }}
        >
          Concordar
        </Button>,
      ]}
    >
      <p>
        Eu li e concordo com os termos do{' '}
        <a href="/termos/LGPD20062023.pdf" target={'_blank'}>
          Regulamento
        </a>
      </p>
    </Modal>
  );
};

export type TRegulamentoProps = {};
