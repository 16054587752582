import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { TreeSelect, Input, Empty, Pagination } from 'antd';
import {
  Breadcrumb,
  TitleDescription,
  ListGrid,
  LoadingIndicator,
  verdade,
  Select,
  MaterialProvider,
  ShareModal,
  PinFolderModal,
  useCurrentUser,
} from '@digi-tim-19/components';
import { formatDate } from '@digi-tim-19/utils/build';

import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { findCorrectName, findRedirect } from '../../utils/productNames';
import {
  SortFindManyCategoryInput,
  EnumMaterialKind,
  EnumFileKind,
  ValidityEnum,
  Maybe,
  Material,
  SortFindManyMaterialInput,
} from '../../autogenerated/client/types';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';
import { PaginationWrapper } from '../../components/Page/PaginationWrapper';
import { getRouterByMaterial } from '@digi-tim-19/utils/build';
import { escapeStringRegexp } from '../../utils/formatters';

const PageContent = styled.div``;

const Search = styled(Input.Search)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;

  .ant-calendar-range-picker-separator {
    display: none !important;
  }
  @media only screen and (max-width: 900px) {
    .ant-calendar-picker-container {
      width: 100%;
      padding: 0 15px;
    }
    .ant-calendar-range {
      width: 100% !important;
    }
    .ant-calendar-date-panel {
      display: flex !important;
      flex-direction: column !important;
      .ant-calendar-range-part {
        width: 100%;
      }
    }
  }
`;

export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;
  cursor: pointer;
`;

export const Processos = () => {
  const { category, product } = useParams<{
    category: string;
    product: string;
  }>();
  const [productFilter, setProductFilter] = useState('');
  const [procedureFilter, setProcedureFilter] = useState('');
  const [inCategories, setInCategories] = useState([productFilter, productFilter]);
  const currentUser = useCurrentUser();
  
  const apiAnalyticsDownload = useClient('AnalyticsDownloads');

  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(1);
  const perPage = 20;

  useEffect(() => {
    setInCategories([procedureFilter, productFilter]);
  }, [procedureFilter, productFilter]);

  const history = useHistory();

  const paramsFilter = product
    ? `regex(${escapeStringRegexp(product)})`
    : `menu:processos:${category && category === 'tim_tech' ? `vendas:produtos:${category}` : category}`;

  const [filterSearch, setFilterSearch] = useState('');
  const [sort, setSort] = useState(SortFindManyMaterialInput.IdDesc);

  const resetFilters = () => {
    setProcedureFilter('');
    setProductFilter('');
    setFilterSearch('');
    setSort(SortFindManyMaterialInput.IdDesc);
    setPage(1);
  };

  const getMaterials = useClient('MaterialPagination');

  const total = getMaterials.result?.pageInfo.itemCount;

  const getCategories = useClient('CategoryFindMany', {
    fetchOnMount: true,
    appendToFragment: categoryFragment,
    variables: {
      filter: {
        parentId: paramsFilter,
      },
      sort: SortFindManyCategoryInput.SortAsc,
      limit: 123,
    },
  });

  useEffect(() => {
    const allCategories = inCategories.concat(paramsFilter);

    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            kind: EnumMaterialKind.Process,
            inAvailableAtRoleGroups: [currentUser?.result?.roleGroup?._id || ''],
            validityEnum: ValidityEnum.Current,
            allCategories: allCategories.filter(Boolean),
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
          },
        },
      })
      .then((response: any) => {
        setItems(response && response.result ? response.result.items : []);
      });
  }, [paramsFilter, sort, procedureFilter, productFilter, inCategories, filterSearch, page]); //eslint-disable-line

  useEffect(() => {
    resetFilters();

    getCategories.fetch({
      variables: {
        filter: {
          parentId: paramsFilter,
        },
        sort: SortFindManyCategoryInput.SortAsc,
        limit: 123,
      },
    });
  }, [paramsFilter]); //eslint-disable-line

  const { listFolders, addFolder } = useFoldersPin();

  const getData = () => {
    const mountListGrid = (materialArray: Maybe<Material>[]) => {
      return materialArray?.map((material) => {
        const thumb = (material?.files || []).find((item) => item?.kind === EnumFileKind.AnyImage)?.signedUrl;
        const fileDoc = (material?.files || []).find(
          (item) => item?.kind === EnumFileKind.Pdf || item?.kind === EnumFileKind.AnyDoc,
        );

        const routesMaterial = getRouterByMaterial(material, routes);
        return {
          tituloCard: material?.title,
          descricao: material?.description,
          data: formatDate(material?.createdAt, 'DD/MM/YYYY'),
          prefixDate: 'Cadastrado em - ',
          dataUpdate: formatDate(material?.updatedAt, 'DD/MM/YYYY'),
          prefixDateUpdate: 'Atualizado em - ',
          imgBody: thumb,
          defaultImage: '/defaultImages/Thumb-PROCESSOS_C.png',
          kind: material?.kind,
          renderPin: () => (
            <MaterialProvider material={material as any}>
              <PinFolderModal listFolders={listFolders} actionAddFolder={addFolder} pinned={material?.pinned} />
            </MaterialProvider>
          ),
          renderShare: () => (
            <MaterialProvider material={material as any}>
              <ShareModal />
            </MaterialProvider>
          ),
          actions: {
            pin: true,
            share: true,
            delete: false,
            access: !!routesMaterial.routerAccess,
            download: material?.html === null || !!fileDoc,
            onDownload: () => {
              if (fileDoc) {
                let a = document.createElement('a');
                a.href = fileDoc?.signedUrl || '#';
                a.download = fileDoc?.title || '';
                a.target = '_blank';
                a.click();
                apiAnalyticsDownload.fetch({ variables: { fileId: fileDoc?._id || '' } });
              }
            },
            onAccess: () => {
              history.push(routesMaterial.routerAccess);
            },
          },
        };
      });
    };

    if (getMaterials.loading) return [];

    if (filterSearch.length > 0) return mountListGrid(items);

    return mountListGrid(items);
  };

  const corretPrefix = () => {
    switch (category) {
      case 'administrativo':
      case 'hdc_op__comerciais':
      case 'suporte':
        return 'do';
      default:
        return 'de';
    }
  };

  const setTitle = () => {
    switch (category) {
      case 'vendas':
        return 'Vendas';
      case 'pos_vendas':
        return 'Pós-Vendas';
      case 'administrativo':
        return 'Administrativo';
      case 'hdc_op__comerciais':
        return 'HDC/OP. Comerciais';
      case 'pedidos':
        return 'Pedidos';
      case 'suporte':
        return 'Suporte';
      case 'outros_documentos':
        return 'Outros documentos';
      default:
        return 'Processos';
    }
  };

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
    },
    [getMaterials?.result],
  );

  const categories = React.useMemo(() => {
    const cat = verdade(getCategories.result);

    return cat.map((category) => {
      const selectable = category.subCategories?.length === 0;

      return {
        title: category?.name,
        value: category?._id,
        key: category?._id,
        selectable,
        children: category?.subCategories?.map((subCategory) => ({
          title: subCategory?.name,
          value: subCategory?._id,
          key: subCategory?._id,
          children: subCategory?.subCategories?.map((subSubCategory) => ({
            title: subSubCategory?.name,
            value: subSubCategory?._id,
            key: subSubCategory?._id,
          })),
        })),
      };
    });
  }, [getCategories.result]);

  const treeDataProcedures = () => {
    if (categories[1] != undefined) {
      let data = categories[1].children?.sort(function(a: any, b: any) {
        return a?.title > b.title ? 1 : b.title > a.title ? -1 : 0;
      });
      return data;
    }
  };

  const treeDataProducts = () => {
    if (categories[0] != undefined) {
      let data = categories[0].children;
      data?.sort(function(a, b) {
        if (a?.title! > b?.title!) {
          return 1;
        }
        if (b?.title! > a?.title!) {
          return -1;
        }
        return 0;
      });

      return data;
    }
  };

  const showFilter = () => {
    if (category === 'vendas' || category === 'pos_vendas') {
      return (
        <>
          <TreeSelect
            dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
            treeData={treeDataProducts()}
            placeholder="Filtrar por produto"
            treeDefaultExpandAll={false}
            onChange={(e: any) => setProductFilter(e)}
            value={productFilter.length !== 0 ? productFilter : undefined}
            disabled={getCategories.loading}
          />
          <TreeSelect
            dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
            treeData={treeDataProcedures()}
            placeholder="Filtrar por procedimento"
            treeDefaultExpandAll={false}
            onChange={(e: any) => setProcedureFilter(e)}
            value={procedureFilter.length !== 0 ? procedureFilter : undefined}
            disabled={getCategories.loading}
          />
          <Select placeholder="Ordenar por:" options={order} onChange={(e) => setSort(e)} value={sort} />
          <Search
            placeholder="Busca título"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e) => onSearch(e.target.value)}
          />
          <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
        </>
      );
    } else {
      return (
        <>
          <TreeSelect
            dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
            treeData={categories}
            placeholder="Filtrar"
            treeDefaultExpandAll={false}
            onChange={(e: any) => {
              setPage(1);
              setProductFilter(e);
            }}
            value={productFilter.length !== 0 ? productFilter : undefined}
            disabled={getCategories.loading}
          />
          <Select
            placeholder="Ordenar por:"
            options={order}
            onChange={(e) => {
              setPage(1);
              setSort(e);
            }}
            value={sort}
          />
          <Search
            placeholder="Busca título"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e) => {
              setPage(1);
              onSearch(e.target.value);
            }}
          />
          <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
        </>
      );
    }
  };

  return (
    <PageTemplate>
      <>
        {category && product && (
          <>
            <Breadcrumb
              items={[
                { title: 'Home', link: routes.home.mount() },
                { title: category ? findCorrectName(category) : '' },
                {
                  title: product ? findCorrectName(product) : '',
                  link: category && product ? routes.selectFilter.mount(category, product) : '',
                },
                { title: 'Processos' },
              ]}
            />
            <TitleDescription
              iconType="schedule"
              title={setTitle()}
              description={`Aqui você encontra todos os processos ${corretPrefix()} ${product &&
                findCorrectName(product)}`}
            />
          </>
        )}
        {category && !product && (
          <>
            <Breadcrumb
              items={[
                { title: 'Home', link: routes.home.mount() },
                { title: 'Processos' },
                { title: category ? findCorrectName(category) : '', link: findRedirect(category) || '' },
              ]}
            />
            <TitleDescription
              iconType="schedule"
              title={setTitle()}
              description={`Aqui você encontra todos os processos ${corretPrefix()} ${category &&
                findCorrectName(category)}`}
            />
          </>
        )}

        <PageContent>
          <FilterContainer>{!product ? showFilter() : null}</FilterContainer>

          {!getMaterials.loading && getData()?.length === 0 && <Empty />}
          {getMaterials.loading ? <LoadingIndicator /> : getData() && <ListGrid items={getData() as any} />}

          <PaginationWrapper>
            <Pagination pageSize={perPage} current={page} total={total!} onChange={(page) => setPage(page)} />
          </PaginationWrapper>
        </PageContent>
      </>
    </PageTemplate>
  );
};

const order = [
  { label: 'MAIS ANTIGO', value: SortFindManyMaterialInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyMaterialInput.IdDesc },
];

const categoryFragment = `
  parentId
  _id
  subCategories {
    name
    _id
    subCategories {
      name
      _id
      subCategories {
        name
        _id
      }
    }
  }
`;

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    pinned
    title
    numericId
    createdAt
    updatedAt
    fileIds
    html
    kind
    availableAtRoleGroups
    files {
      _id
      extension
      kind
      signedUrl
      downloadUrl
    }
    categories {
      _id
      name
    }
    validity { 
      start 
      end 
    } 
    author { 
      _id, 
      name 
    }
  }
`;
