import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { TabelaPrecoWrapper } from '../Cadastrar/stylesCadastrar';
import { useRouteMatch } from 'react-router';
import { useMaterial } from '../../../hooks/materials/useMaterial';
import { routes } from '../../../config/routes';

export const ArgumentacaoFDVEditar = () => {
  const { params } = useRouteMatch();
  const appendToFragment = `
    title
    fileIds
    files {
      _id
      title
      kind
    }
    validity {
      start,
      end,
      notifyEmailsBeforeExpire
    }
    categories {
      _id
      name
      parentId
    }
    description
    availableAtRegions
    availableAtChannels
    availableAtRoleGroups
`;
  const tabelaItem = useMaterial(params?.id, appendToFragment);

  return (
    <PageTemplate>
      <>
        <TabelaPrecoWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Argumentação de Vendas' },
              { title: 'Editar' }
            ]}
          />
          <TitleDescription
            iconType="sound"
            title="Argumentação de Vendas"
            description="Listar e consultar Argumentações de Vendas"
          />
        </TabelaPrecoWrapper>

        {tabelaItem.result && (
          <ProviderSelecionarPublico>
            <Form initialValues={tabelaItem.result} />
          </ProviderSelecionarPublico>
        )}
      </>
    </PageTemplate>
  );
};
