import React, { useEffect, useState } from 'react';
import { Container } from './styleForm';
import { useHistory } from 'react-router';
import { routes } from '../../../config/routes';
import { formatDate, useDimensions } from '@digi-tim-19/utils';
import {
  EnumFileKind,
  EnumBannerImagesDisplay,
  Banner,
  EnumExternalCampaignStatus,
} from '../../../autogenerated/client/types';
import { Former, SelecionarPublico, usePublico, TypeCard, SearchUser, LoadingIndicator } from '@digi-tim-19/components';
import { useClient } from '../../../autogenerated/client/client';
import { message } from 'antd';
import { useContentTypePermissions } from '../../../hooks/useContentTypePermissions';
import { selectedChannels } from '../../../utils/selectedChannels';
import { usePagination } from '../../../hooks/usePagination';
const selectedChannelsConsumer = selectedChannels();

const configPage = {
  routeList: routes.bannersListar.mount(),
  messageSuccess: 'Banner cadastrado com sucesso',
  messageError: 'Banner não cadastrado',
  kindFile: EnumFileKind.BannerImage,
};

export const Form = (props: TFormBannerEditProps) => {
  const permissions = useContentTypePermissions();
  const { availableAtChannels, availableAtRegions, availableAtRoleGroups } = usePublico();
  const history = useHistory();
  const { isMobile } = useDimensions();
  const createBanner = useClient('BannerCreateOne');
  const updateBanner = useClient('BannerUpdateOne');
  const { parsed, loading } = usePagination('ExternalCampaignPagination', {
    initial: {
      page: 1,
      perPage: 1000,
      filter: { status: EnumExternalCampaignStatus.InProgress },
    },
    itemsFragment: ` _id title endDate`,
    parseResult: (data) =>
      data.map((campaign) => ({
        label: campaign.title,
        value: campaign._id,
        end: formatDate(campaign.endDate, 'DD/MM/YYYY'),
      })),
  });

  const isUpdate = !!props.initialValues;

  const imageDesktop = props.initialValues?.images?.find((item) => item?.display === EnumBannerImagesDisplay.Desktop);
  const imageMobile = props.initialValues?.images?.find((item) => item?.display === EnumBannerImagesDisplay.Mobile);

  const imageUrlDesktop = imageDesktop?.signedUrl;
  const imageUrlMobile = imageMobile?.signedUrl;

  const initialValues = {
    position: props.initialValues?.position,
    externalCampaign: props.initialValues?.externalCampaign,
    externalCampaignFlag: !!props.initialValues?.externalCampaign,
    imageDesktop: imageDesktop?.fileId,
    imageMobile: imageMobile?.fileId,
    notifyEmailsBeforeExpire: props.initialValues?.validity?.notifyEmailsBeforeExpire,
    ...props.initialValues,
  };

  const [position, setPosition] = useState('');
  const [showExternal, setShowExternal] = useState(!!props.initialValues?.externalCampaign);
  const [CTAImageUpload, setCTAImageUpload] = useState('');
  const [CTAImageUploadMobile, setCTAImageUploadMobile] = useState('');
  const [endValidity, setEndValidity] = useState<string>();

  if (initialValues?.position && CTAImageUpload === '') changePosition(initialValues?.position);

  function changePosition(value: string) {
    setPosition(value);

    switch (parseInt(value)) {
      case TypeCard.BANNER:
        setCTAImageUpload('1920 (L) X 650 (A)');
        setCTAImageUploadMobile('736 (L) X 310 (A)');
        break;
      case TypeCard.LARGE:
        setCTAImageUpload('404 (L) X 260 (A)');
        setCTAImageUploadMobile('280 (L) X 260 (A)');
        break;
      case TypeCard.MEDIUM1:
        setCTAImageUpload('192 (L) X 324 (A)');
        setCTAImageUploadMobile('280 (L) X 258 (A)');
        break;
      case TypeCard.MEDIUM2:
        setCTAImageUpload('192 (L) X 324 (A)');
        setCTAImageUploadMobile('280 (L) X 258 (A)');
        break;
      case TypeCard.SMALL:
        setCTAImageUpload('242 (L) X 158 (A)');
        setCTAImageUploadMobile('280 (L) X 120 (A)');
        break;
      case TypeCard.SMALL2:
        setCTAImageUpload('242 (L) X 158 (A)');
        setCTAImageUploadMobile('280 (L) X 120 (A)');
        break;
      case TypeCard.BANNER_FOOTER:
        setCTAImageUpload('1010 (L) X 204 (A)');
        setCTAImageUploadMobile('320 (L) X 62 (A)');
        break;
      default:
        setCTAImageUpload('1920 (L) X 650 (A)');
        setCTAImageUploadMobile('736 (L) X 310 (A)');
        break;
    }
  }

  if (permissions.loading || loading) return <LoadingIndicator />;

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              //primeira linha de campos
              inline: !isMobile,
              list: [
                {
                  name: 'position',
                  label: 'Área da home',
                  required: true,
                  disabled: isUpdate,
                  afterChange: changePosition,
                  maxWidth: '250px',
                  options: [
                    { value: TypeCard.BANNER.toString(), label: 'Banner' },
                    { value: TypeCard.LARGE.toString(), label: 'Card Grande' },
                    {
                      value: TypeCard.MEDIUM1.toString(),
                      label: 'Card Medio 01',
                    },
                    {
                      value: TypeCard.MEDIUM2.toString(),
                      label: 'Card Medio 02',
                    },
                    {
                      value: TypeCard.SMALL.toString(),
                      label: 'Card pequeno 01',
                    },
                    {
                      value: TypeCard.SMALL2.toString(),
                      label: 'Card pequeno 02',
                    },
                    {
                      value: TypeCard.BANNER_FOOTER.toString(),
                      label: 'Banner rodapé',
                    },
                  ],
                },
                position !== '' && {
                  name: 'imageDesktop',
                  label: 'Desktop',
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: configPage.kindFile,
                    CTA: `${CTAImageUpload}`,
                    hasPreview: true,
                    imageUrl: imageUrlDesktop,
                    widthPreview: '100px',
                  },
                },
                position !== '' && {
                  name: 'imageMobile',
                  label: 'Mobile',
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: configPage.kindFile,
                    CTA: `${CTAImageUploadMobile}`,
                    hasPreview: true,
                    imageUrl: imageUrlMobile,
                    widthPreview: '100px',
                  },
                },
              ],
            },
            {
              //segunda linha de campos
              inline: !isMobile,
              list: [
                {
                  name: 'title',
                  label: 'Título',
                  required: true,
                  validate: (value: any) => {
                    const format = /[#%&;]/;
                    if (format.test(value)) return 'Os caracteres #%&; não são permitidos';

                    if (value) return !(value.length > 110) ? undefined : 'Máximo de 110 caracteres';

                    return undefined;
                  },
                },
                {
                  name: 'sort',
                  label: 'Ordem',
                  type: 'number',
                  required: true,
                  validate: (value: any) => {
                    if (value) return !(value > 999) ? undefined : 'Número máximo 999';

                    return undefined;
                  },
                },
              ],
            },
            {
              //terceira linha de campos
              inline: !isMobile,
              list: [
                {
                  name: 'link',
                  label: 'Link externo',
                  maxWidth: '48%',
                },
                position === TypeCard.BANNER.toString() && {
                  name: 'textButton',
                  label: 'Texto do botão (até 15 caracteres)',
                },
              ],
              validate: (value: any) => {
                if (value) return !(value.length > 1200) ? undefined : 'Máximo de 1200 caracteres';

                return undefined;
              },
            },
            {
              //quarta linha de campos
              inline: !isMobile,
              list: [
                {
                  name: 'validity.start',
                  label: 'Data de início',
                  type: 'datePicker',
                  required: true,
                  extraProps: {
                    showTime: true,
                    format: 'DD/MM/YYYY HH:mm:ss',
                  },
                  maxWidth: '250px',
                  enableDatePriorToCurrent: isUpdate,
                },
                {
                  name: 'validity.end',
                  label: 'Data de expiração',
                  type: 'datePicker',
                  required: true,
                  extraProps: {
                    showTime: true,
                    format: 'DD/MM/YYYY HH:mm:ss',
                  },
                  maxWidth: '250px',
                },
              ],
            },
            {
              name: 'notifyEmailsBeforeExpire',
              label: 'Notificar antes de expirar',
              placeholder: 'Notificar antes de expirar',
              extraProps: {
                returnProp: 'email',
              },
              component: SearchUser,
            },
            {
              //quinta linha de campos
              className: 'Footer',
              inline: true,
              list: [
                {
                  custom: (
                    <SelecionarPublico
                      initialValues={props.initialValues}
                      selectedChannels={!isUpdate ? selectedChannelsConsumer : []}
                      {...permissions.filterOptions}
                    />
                  ),
                },
              ],
            },
            {
              inline: true,
              list: [
                {
                  name: 'externalCampaignFlag',
                  label: 'Associar campanha externa?',
                  type: 'checkbox',
                  afterChange: () => setShowExternal((e) => !e),
                },
                showExternal && {
                  name: 'externalCampaign',
                  label: 'Campanha externa',
                  type: 'select',
                  required: true,
                  options: parsed,
                  afterChange: (value: any) => {
                    setEndValidity(parsed.find((el) => el.value === value)?.end);
                  },
                },
                !!endValidity && {
                  name: 'endDate',
                  label: 'Válida até',
                  defaultValue: endValidity,
                  disabled: true,
                  maxWidth: '140px',
                },
              ],
            },
          ],
          submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(configPage.routeList),
          },
        }}
        onSubmit={async ({ data }: any) => {
          if (!availableAtRegions || !availableAtChannels || !availableAtRoleGroups) {
            message.warn('Selecione um público');
            return;
          }

          const record = {
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            externalCampaign: data.externalCampaign,
            title: data.title,
            textButton: data.textButton,
            position: data.position,
            validity: {
              notifyEmailsBeforeExpire: data.notifyEmailsBeforeExpire,
              start: data?.validity?.start,
              end: data?.validity?.end,
            },
            link: data.link,
            sort: data.sort,
            images: [
              {
                fileId: data.imageDesktop,
                display: EnumBannerImagesDisplay.Desktop,
              },
              {
                fileId: data.imageMobile,
                display: EnumBannerImagesDisplay.Mobile,
              },
            ],
          };

          if (isUpdate) {
            await updateBanner
              .fetch({
                variables: { record, filter: { _id: data._id } },
                afterMutate: /^Banner/,
              })
              .then((ctx) => {
                if (!ctx.errors) {
                  history.push(configPage.routeList);
                } else {
                  console.error('Erro ao atualizar', ctx.errors);
                }
              });
          } else {
            await createBanner.fetch({ variables: { record } }).then((ctx) => {
              if (ctx?.result) {
                message.success(configPage.messageSuccess);
                history.push(configPage.routeList);
              } else {
                console.error('errors', ctx.errors);
                message.error(configPage.messageError);
              }
            });
          }
        }}
      />
    </Container>
  );
};

export type TFormBannerEditProps = {
  initialValues?: Partial<Banner>;
};
