import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { PageWrapper } from './styles';
import {
  Breadcrumb,
  JoystickIconSVG,
  LoadingIndicator,
  ProviderSelecionarPublico,
  TitleDescription,
  useClient,
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { FormGameficationChallenge } from '../Form/Form';
import { useRouteMatch } from 'react-router';
import { ContainerIcon } from '../List/styles';

const appendToFragment = `
  _id
  title
  description
  thumbnail
  availableAtChannels
  availableAtRegions
  availableAtRoleGroups
  expires
  startDate
  endDate
  action
  typeChallenge
  amountChips
  amountPoints
  indeterminate
  recordId
  typeTime
  amount
  consecutive
  category
  challengeData
  externalLink
  redirect
  url
  faq
`;

export const GameficationEdit = () => {
  const { params }: any = useRouteMatch();
  const getChallenge = useClient('ChallengeFindById', {
    appendToFragment,
    variables: { _id: params?.id! },
    fetchOnMount: true,
  });
  const configPage = {
    pageName: 'Gamificação (Desafios)',
    icon: 'schedule',
    description: 'Edição e consulta de desafios gamificação',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo', link: routes.home.mount() },
      { title: 'Desafios', link: routes.gameficationList.mount() },
      { title: 'Editar', link: routes.gameficationEdit.mount(params?._id!) },
    ],
  };

  return (
    <PageTemplate>
      <React.Fragment>
        <PageWrapper>
          <Breadcrumb items={configPage.breadcrumb} />
          <TitleDescription
            renderCustomIcon={
              <ContainerIcon>
                <JoystickIconSVG />
              </ContainerIcon>
            }
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>
        {getChallenge.loading ? (
          <LoadingIndicator />
        ) : (
          <React.Fragment>
            {getChallenge?.result && (
              <ProviderSelecionarPublico>
                <FormGameficationChallenge initialValues={getChallenge.result} />
              </ProviderSelecionarPublico>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </PageTemplate>
  );
};
