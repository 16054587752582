import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CampanhaIncentivoListWrapper = styled.div`
  width: 100%;
`;

export const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;
  > * {
    max-width: 500px;
  }
`;

export const Button = styled(Link)`
  border: none;
  background: red;
  display: flex;
  justify-content: center;

  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  font-family: TimBold;
  width: 100%;
  cursor: pointer;
  outline: none;
  margin-right: 15px;
  &:hover {
    background: white;
    color: red;
    outline: none !important;
  }
`;

export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;

  cursor: pointer;
`;
