import { Radio as AntdRadio, Modal as AntdModal, Input, message, Icon } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Alternatives } from './QuizAlternativesField';
import { Button } from '@digi-tim-19/components';

interface Props {
  limit?: number;
  total?: number;
  values?: Alternatives[];
  onChange?: (data: Alternatives[]) => void;
}

const Radio = styled(AntdRadio)`
  display: block;
  padding: 8px;
  white-space: break-spaces !important;
  flex: 1;
`;

const RadioContainer = styled.div`
  display: flex;
  width: 100% !important;
  align-items: center;
  align-content: space-between;
  flex-wrap: wrap;
  &:hover {
    background-color: #ededed;
    border-radius: 10px;
  }
`;

const Modal = styled(AntdModal)`
  .ant-modal-body {
    max-height: 350px !important;
    padding: 16px 24px !important;
    overflow: auto;
  }

  .ant-radio-group {
    margin-top: 10px;
    width: 100% !important;
  }
`;

const ModalInputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export const ModalCreateAlternatives = ({ total = 0, limit = 10, onChange, values = [] }: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [alternatives, setAlternatives] = React.useState<Alternatives[]>([]);
  const [alternativeDescription, setAlternativeDescription] = React.useState<string | undefined>();

  const changeCorrect = (id: string) => {
    setAlternatives((prevState) => {
      return [...prevState].map((alternative) => {
        return {
          ...alternative,
          isCorrect: alternative.id === id,
        };
      });
    });
  };

  const addAlternative = (description: string | undefined) => {
    if (!description) return;
    setAlternatives((prevState) => {
      return [
        ...prevState,
        {
          id: uuidv4().replace(/\-/g, ''),
          description: description.trim(),
          isCorrect: false,
        },
      ];
    });
    setAlternativeDescription(undefined);
  };

  const removeAlternative = (id: string) => {
    setAlternatives((prevState) => {
      return [...prevState].filter((el) => el.id !== id);
    });
  };

  React.useEffect(() => {
    setAlternatives([...values]);
  }, [values]);

  return (
    <React.Fragment>
      <Button onClick={() => setOpen(true)}>ALTERNATIVAS ({total})</Button>
      <Modal
        title="Cadastro de alternativas"
        visible={open}
        okText="SALVAR"
        cancelText="FECHAR"
        onOk={() => {
          if (alternatives.length < 2) {
            return message.error('Por favor, cadastre ao menos duas alternativas.');
          } else if (!alternatives.find((el) => el.isCorrect)) {
            return message.error('Por favor, selecione uma alternativa correta.');
          }
          if (onChange) onChange([...alternatives]);
          setOpen(false);
        }}
        onCancel={() => setOpen(false)}
      >
        {alternatives?.length < limit && (
          <ModalInputContainer>
            <Input
              id="question-alternative-field"
              placeholder="Alternativa"
              value={alternativeDescription || ''}
              onChange={(e: any) => {
                setAlternativeDescription(e?.target?.value);
              }}
            />
            <Button
              onClick={() => addAlternative(alternativeDescription)}
              disabled={!alternativeDescription || String(alternativeDescription).trim() === ''}
            >
              INCLUIR
            </Button>
          </ModalInputContainer>
        )}
        {alternatives.length > 0 && (
          <React.Fragment>
            <Radio.Group
              onChange={(e: any) => changeCorrect(e.target.value)}
              value={alternatives.find((alternative) => alternative.isCorrect)?.id}
            >
              {alternatives.map((alternative) => {
                return (
                  <RadioContainer key={`rc-alternative-${alternative.id}`}>
                    <Radio key={`alternative-${alternative.id}`} value={alternative.id}>
                      {alternative.description}
                    </Radio>
                    <Button onClick={() => removeAlternative(alternative.id)}>
                      <Icon type="delete" />
                    </Button>
                  </RadioContainer>
                );
              })}
            </Radio.Group>
          </React.Fragment>
        )}
      </Modal>
    </React.Fragment>
  );
};
