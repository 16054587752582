import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import { clickDownload, formatDate } from '@digi-tim-19/utils/build';
import {
  getColorStatus,
  getStatusTranslation
} from '../../../hooks/contestacao/useContestacao';
import { Button } from '@digi-tim-19/components';
import { useClient } from '../../../autogenerated/client/client';

const ContentWrapperModal = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowModal = styled.div`
  width: 100%;
  margin: 10px 10px 10px;
  display: flex;
  flex-wrap: wrap;
`;

const Field = styled.div<{ widthField: string }>`
  width: ${(props) => props.widthField};
  margin: 10px 0;
`;

const Label = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

const Description = styled.span``;

export const ModalContestation = ({
  modalIsOpen,
  setModalIsOpen,
  dataModal
}: ModalContestationTypes) => {
  const [downloadUrlFileResponse, setDownloadUrlFileResponse] = useState('');
  const [statusName, setStatusName] = useState('');
  const [colorStatus, setColorStatus] = useState('blue');

  const getFileById = useClient('FileFindById');

  useEffect(() => {
    if (dataModal) {
      if (!!dataModal?.response && !!dataModal?.response?.file) {
        getFileById
          .fetch({
            variables: {
              _id: dataModal?.response?.file
            }
          })
          .then((ctx) => {
            if (ctx.result) {
              setDownloadUrlFileResponse(ctx.result.signedUrl || '');
            }
          });
      } else {
        setDownloadUrlFileResponse('');
      }
      setStatusName(getStatusTranslation(dataModal?.response?.status));
    }
  }, [dataModal]);

  useEffect(() => {
    setColorStatus(getColorStatus(statusName));
  }, [statusName]);

  return (
    <Modal
      title="Dados da contestação"
      visible={modalIsOpen}
      width="700px"
      onCancel={() => setModalIsOpen(false)}
      footer={[]}
    >
      <ContentWrapperModal>
        <RowModal>
          <Field widthField="50%">
            <Label>Campanha:</Label>
            <Description>{dataModal?.campaign?.title || ''}</Description>
          </Field>

          <Field widthField="50%">
            <Label>Status:</Label>
            <Description>
              <span style={{ color: colorStatus }}>{statusName}</span>
            </Description>
          </Field>

          <Field widthField="50%">
            <Label>Protocolo:</Label>
            <Description>{dataModal?.protocol || ''}</Description>
          </Field>

          <Field widthField="50%">
            <Label>Indicador:</Label>
            <Description>{dataModal?.indicator || ''}</Description>
          </Field>

          <Field widthField="50%">
            <Label>Data da Contestação:</Label>
            <Description>
              {formatDate(dataModal?.createdAt, 'DD/MM/YYYY')}
            </Description>
          </Field>

          <Field widthField="50%">
            <Label>Evidência:</Label>
            <Description>
              <Button
                type="link"
                onClick={() =>
                  clickDownload(dataModal?.files[0]?.downloadUrl || '', true)
                }
              >
                Download
              </Button>
            </Description>
          </Field>

          <Field widthField="100%">
            <Label>Mensagem:</Label>
            <Description>{dataModal?.message}</Description>
          </Field>
        </RowModal>

        {!!dataModal?.response && (
          <RowModal>
            <Field widthField="50%">
              <Label>Data da desposta:</Label>
              <Description>
                {formatDate(dataModal?.response?.createdAt, 'DD/MM/YYYY')}
              </Description>
            </Field>
            {!!downloadUrlFileResponse && (
              <Field widthField="50%">
                <Label>Evidência:</Label>
                <Description>
                  <Button
                    type="link"
                    onClick={() => clickDownload(downloadUrlFileResponse, true)}
                  >
                    Download
                  </Button>
                </Description>
              </Field>
            )}

            {!!dataModal?.response?.body && (
              <Field widthField="100%">
                <Label>Justificativa:</Label>
                <Description>{dataModal?.response?.body}</Description>
              </Field>
            )}
          </RowModal>
        )}
      </ContentWrapperModal>
    </Modal>
  );
};

export type ModalContestationTypes = {
  modalIsOpen: boolean;
  setModalIsOpen: Function;
  dataModal: any;
};
