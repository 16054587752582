import React, { useState } from 'react';
import { AccessCPF, AccessCPFProps } from './AccessCPF';
import { Input, Form, notification } from 'antd';
import styled from 'styled-components';
import { siteTheme } from '../../styles/theme';
import { Button, Icon, LoadingIndicator } from '@digi-tim-19/components';
import { useClient } from '../../autogenerated/client/client';
import {
  OriginUserDataSession,
  UserSession,
  validatePassword
} from '@digi-tim-19/utils';
import ScreenLogin from './Screens';

const Wrapper = styled.div`
  width: 100%;
  .ant-input {
    height: 50px;
  }

  .ant-input-password {
    margin: 10px 0 0;
  }

  .ant-radio-wrapper {
    a {
      color: #ffcb00;
    }
  }
`;

const LabelRed = styled.div`
  color: ${siteTheme.red};
  font-weight: bold;
`;

const LabelWhite = styled.div`
  color: ${siteTheme.white};
  font-weight: bold;
`;

export type NewRegisterProps = {
  value: string;
  accessCPFProps: AccessCPFProps;
  page?: string;
};

const disabledSubimitButton = (valuePass: string, valueConfirmPass: string) => {
  return (
    valuePass !== '' &&
    valueConfirmPass !== '' &&
    valuePass === valueConfirmPass
  );
};

export const NewPassword = ({
  value,
  accessCPFProps,
  page
}: NewRegisterProps) => {
  const [oldPassword, setOldpassword] = useState(
    () => accessCPFProps.password ?? ''
  );
  const [newPassword, setNewPassword] = useState('');
  const [valueConfirmPass, setValueConfirmPass] = useState('');
  const [sufix, setSufix] = useState(false);
  const [capsPass, setCapsPass] = useState(false);
  const [capsConfirmPass, setConfirCapsPass] = useState(false);
  const [confirmPassNotEquals, setConfirmPassNotEquals] = useState(false);

  const newPasswordClient = useClient('ConsumerNewPassword');

  const loading = newPasswordClient.loading;

  return (
    <Wrapper>
      <Form>
        {page === 'internal' ? null : (
          <AccessCPF
            disabled
            value={value}
            setScreen={() => {}}
            setValue={() => {}}
          />
        )}

        <Input.Password
          suffix={
            sufix ? <Icon type="check-circle" style={{ color: 'green' }} /> : ''
          }
          value={oldPassword}
          onKeyDown={(e) => {
            const isCaps = e.getModifierState('CapsLock');
            setCapsPass(isCaps);
          }}
          onKeyUp={(e) => {
            const isCaps = e.getModifierState('CapsLock');
            setCapsPass(isCaps);
          }}
          onBlur={() => {
            setCapsPass(false);
          }}
          placeholder="Senha Temporária"
          onChange={(e) => {
            setOldpassword(e.target.value);
          }}
        />
        {capsPass && <LabelRed>Capslook ativado</LabelRed>}

        <Input.Password
          suffix={
            sufix ? <Icon type="check-circle" style={{ color: 'green' }} /> : ''
          }
          onKeyDown={(e) => {
            const isCaps = e.getModifierState('CapsLock');
            setCapsPass(isCaps);
          }}
          onKeyUp={(e) => {
            const isCaps = e.getModifierState('CapsLock');
            setCapsPass(isCaps);
          }}
          onBlur={() => {
            setCapsPass(false);
          }}
          placeholder="Nova senha de 8 a 12 caracteres"
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />
        {capsPass && <LabelRed>Capslook ativado</LabelRed>}

        <Input.Password
          placeholder="Confirme sua senha"
          onKeyDown={(e) => {
            const caps = e.getModifierState('CapsLock');
            setConfirCapsPass(caps);
          }}
          onKeyUp={(e) => {
            const caps = e.getModifierState('CapsLock');
            setConfirCapsPass(caps);
          }}
          onBlur={(e) => {
            setConfirCapsPass(false);

            const currentValue = e.target.value;
            setValueConfirmPass(currentValue);

            if (newPassword !== currentValue) setConfirmPassNotEquals(true);
            else {
              setConfirmPassNotEquals(false);
            }
          }}
          suffix={
            sufix ? <Icon type="check-circle" style={{ color: 'green' }} /> : ''
          }
          onChange={(e) => {
            const currentValue = e.target.value;

            if (newPassword === currentValue) setSufix(true);
            else {
              setSufix(false);
            }
          }}
        />
        {capsConfirmPass && <LabelRed>Capslook ativado</LabelRed>}
        {confirmPassNotEquals && <LabelRed>As senhas não são iguais</LabelRed>}
        <LabelWhite> A senha precisa ter no mínimo 8 caracteres </LabelWhite>
        <LabelWhite> Conter pelo menos 1 número </LabelWhite>
        <LabelWhite>
          {' '}
          Conter pelo menos 1 letra maiúscula e 1 letra minúscula{' '}
        </LabelWhite>

        <Button
          style={{
            marginTop: '20px'
          }}
          disabled={!disabledSubimitButton(newPassword, valueConfirmPass)}
          onClick={(ev) => {
            ev.preventDefault();
            const passwordError = validatePassword(newPassword);

            if (passwordError) {
              return notification.error({ message: passwordError });
            }

            if (!newPassword) return;
            newPasswordClient
              .fetch({
                variables: {
                  CPF: value,
                  oldPassword,
                  newPassword
                }
              })
              .then((ctx) => {
                if (ctx.result) {
                  UserSession.clear();
                  OriginUserDataSession.clear();
                  notification.success({ message: 'Senha cadastrada' });
                  accessCPFProps.setScreen(ScreenLogin.LOGIN);
                }
              });
          }}
        >
          {page === 'internal' ? 'Atualizar Senha' : 'Cadastrar'}
        </Button>

        {loading && <LoadingIndicator small color={'white'} />}
      </Form>
    </Wrapper>
  );
};
