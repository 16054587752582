import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Modal, Button, Upload, Avatar, message } from 'antd';
import { User, EnumFileKind } from '../../autogenerated/client/types';
import { useUpload, getBase64, useCurrentUser } from '@digi-tim-19/components';
import { useClient } from '../../autogenerated/client/client';
import { NewPassword } from '../Login/NewPassword';

const EditAvatarWrapper = styled.div``;
const ContentWrapper = styled.div`
  display: flex;
`;
const LeftAvatarWrapper = styled.div`
  width: 70%;
`;
const RightAvatarWrapper = styled.div`
  width: 30%;
`;

type EditAvatarProps = {
  modalVisible: Boolean;
  setVisible: Function;
  user: User;
  imgUser: string;
  setImgUser: Function;
};

export const EditAvatar = (props: EditAvatarProps) => {
  const execute = useUpload(EnumFileKind.ProfileImage);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(props.imgUser);
  const [disableButtonSave, setDisableButtonSave] = useState(
    !imageUrl || props.imgUser === imageUrl
  );

  const [avatarId, setAvatarId] = useState<string>('');

  useEffect(() => {
    setImageUrl(props.imgUser);
  }, [props.imgUser]);

  const handleChange = (info: any) => {
    if (!isLoading && disableButtonSave) setIsLoading(true);

    getBase64(info.file.originFileObj, (imageUrl: string) => {
      setImageUrl(imageUrl);
    });
  };

  const onChangeUpload = (fileId: string) => {
    setIsLoading(false);
    setAvatarId(fileId);
    setDisableButtonSave(false);
  };

  const handleCancel = () => {
    props.setVisible(false);
    setDisableButtonSave(true);
    setImageUrl(props.imgUser);
    setShowChangePassword(false);
  };

  const UserUpdate = useClient('UserFillAvatarId');

  const handleOk = () => {
    UserUpdate.fetch({
      variables: {
        avatarId: avatarId
      },
      afterMutate: /^User/
    }).then((ctx) => {
      if (ctx.result) {
        message.success('Foto de perfil atualizada com sucesso.');
        setDisableButtonSave(true);
        props.setImgUser(imageUrl);
      } else {
        message.error('Ocorreu um erro ao atualizar a foto do perfil.');
        console.error('ctx', ctx.errors);
      }
    });
  };
  const user = useCurrentUser();
  const [value, setValue] = useState('');
  const [password, setPassword] = useState();
  const [showChangePassword, setShowChangePassword] = useState(false);

  const canChangePassword =
    !showChangePassword && !props.user.email?.includes('@timbrasil');

  useEffect(() => {
    setIsLoading(UserUpdate.loading);
    user.fetch({ ignoreCached: true }).then((ctx) => {
      setValue(ctx?.result?.CPF ? ctx?.result?.CPF : '');
    });
  }, [UserUpdate.loading]);

  return (
    <EditAvatarWrapper>
      <Modal
        title="Editar perfil"
        visible={!!props.modalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            disabled={disableButtonSave}
            onClick={handleOk}
          >
            Salvar
          </Button>
        ]}
      >
        <ContentWrapper>
          <LeftAvatarWrapper>
            <p>{props.user.name}</p>
            <p>{props.user.email}</p>
          </LeftAvatarWrapper>

          <RightAvatarWrapper>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={(e: any) => execute(e, onChangeUpload)}
              onChange={handleChange}
            >
              {!!imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
              ) : (
                <Avatar size={50} icon="user" />
              )}
            </Upload>
          </RightAvatarWrapper>
        </ContentWrapper>
        <ContentWrapper>
          {canChangePassword && (
            <Button onClick={() => setShowChangePassword(true)}>
              Alterar a senha
            </Button>
          )}
          {showChangePassword && (
            <NewPassword
              value={value}
              page="internal"
              accessCPFProps={{
                value: value,
                setValue: setValue,
                password: password,
                setPassword: setPassword,
                setScreen: () => {}
              }}
            />
          )}
        </ContentWrapper>
      </Modal>
    </EditAvatarWrapper>
  );
};
