import React, { useState, useMemo, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router';
import { Modal, Divider } from 'antd';
import fields from './fields';
import { Select } from '@digi-tim-19/components';

import {
  Breadcrumb,
  TitleDescription,
  LoadingIndicator,
  useCurrentUser,
  Former,
  IncentiveTable,
  Button,
} from '@digi-tim-19/components';

import { IncentiveCampaign, ValidityEnum } from '../../autogenerated/client/types';
import { useClient } from '../../autogenerated/client/client';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useIncentiveCampaign } from '../../hooks/incentiveCampaign/useIncentiveCampaign';
import { routes } from '../../config/routes';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useCampanhaVigente } from './useCampanhaVigente';
import {
  CampanhaVigenteTitleWrapper,
  CampanhaVigenteImage,
  CampanhaVigenteButtonsContainer,
  ContestationContainer,
  ContestButton,
  ContestationModal,
  ButtonLink,
  ButtonLinkTo,
  CampanhaPeriodTitleWrapper,
} from './stylesCampanhaVigente';
import { EmptyData } from '../../components/utils/Void';
import { useUserContestacao } from '../../hooks/contestacao/useContestacao';

moment.locale('pt-BR');

export const CampanhaIncentivoVigenteOld = () => {
  const currentUser = useCurrentUser();

  const history = useHistory();
  const { params } = useRouteMatch<{ id: string }>();

  const { contestacoes } = useUserContestacao(params.id);

  const [showModalContestation, setShowModalContestation] = useState<boolean>(false);
  const incentiveCampaign = useIncentiveCampaign(params?.id, appendToFragment);

  const mutationAcceptTerm = useClient('UserAcceptTerm', {
    variables: { term: `IncentiveCampaignRegulation-${params?.id}` },
  });

  const [showModalRegulation, setShowModalRegulation] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState<any>();

  const apiAnalyticsDownload = useClient('AnalyticsDownloads');

  const { toIncentiveTable } = useCampanhaVigente(params.id);


  useEffect(() => {
    if (currentUser.result?.acceptedTerms) setAcceptedTerms(currentUser?.result?.acceptedTerms);
  }, [currentUser.result]);

  useEffect(() => {
    if (params && acceptedTerms && incentiveCampaign.result) {
      if (incentiveCampaign?.result?.validityEnum === ValidityEnum.Expired) {
        setShowModalRegulation(false);
      } else {
        let isAptRegulation = !acceptedTerms?.find(
          (item: any) => item?.term === `IncentiveCampaignRegulation-${params?.id}`,
        );

        if (isAptRegulation) setShowModalRegulation(isAptRegulation);
      }
    }
  }, [params, acceptedTerms, incentiveCampaign.result]);

  const configPage = {
    pageName: 'Campanhas de Incentivo',
    icon: 'trophy',
    description: '',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      {
        title: 'Campanhas de Incentivo',
        link: routes.campanhasIncentivoVigentes.mount(),
      },
      { title: 'Campanhas Vigentes' },
    ],
  };

  const campaign: IncentiveCampaign = incentiveCampaign.result || ({} as IncentiveCampaign);

  const addContestationPeriod = moment(campaign.updatedAt).add(Number(campaign.contestationPeriod), 'day');
  const range = addContestationPeriod.fromNow(true);
  const canContestate = moment(addContestationPeriod).isAfter(new Date());

  const initValues = useMemo(
    () => ({
      title: campaign?.title,
      name: currentUser?.result?.name,
      channel: currentUser?.result?.channels?.find((el) => el),
      region: currentUser?.result?.region,
      role: currentUser?.result?.roleGroup?.name,
      subscription: currentUser?.result?.matricula,
      cpf: currentUser?.result?.CPF,
    }),
    [currentUser?.result, campaign], //eslint-disable-line
  );

  const CampaignContestationCreateOne = useClient('CampaignContestationCreateOne');

  function success(protocol: number | null | undefined) {
    Modal.success({
      content: `Contestação realizada com sucesso, para acompanhá-la anote o protocolo de número: ${protocol}`,
    });
  }
  return (
    <PageTemplate>
      <>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription iconType={configPage.icon} title={configPage.pageName} description={configPage.description} />

        {incentiveCampaign.loading ? (
          <LoadingIndicator />
        ) : (
          <>
            {/* <Modal
              title="Regulamento"
              visible={showModalRegulation}
              onOk={() => {
                mutationAcceptTerm.fetch();
                setShowModalRegulation(false);
              }}
              onCancel={() => history.push(routes.home.mount())}
              cancelText="Não"
              okText="Sim"
            >
              Li e concordo com os termos e condições do regulamento
              <ButtonLink
                style={{ marginTop: 5 }}
                onClick={() => {
                  let a = document.createElement('a');
                  a.href = campaign?.regulationFile?.signedUrl || '#';
                  a.download = campaign?.regulationFile?.signedUrl || '';
                  a.target = '_blank';
                  a.click();
                  apiAnalyticsDownload.fetch({ variables: { fileId: campaign?.regulationFile?._id || '' } });
                }}
                target="_blank"
                download
              >
                Clique aqui para baixar o regulamento
              </ButtonLink>
            </Modal> */}
            <CampanhaVigenteTitleWrapper>{campaign.title}</CampanhaVigenteTitleWrapper>
            {campaign.bannerContentFile?.signedUrl && (
              <CampanhaVigenteImage>
                <img src={campaign.bannerContentFile?.signedUrl} alt="thumb" />
              </CampanhaVigenteImage>
            )}

            <CampanhaVigenteButtonsContainer>
              <Button
                onClick={() => {
                  let a = document.createElement('a');
                  a.href = campaign?.regulationFile?.signedUrl || '#';
                  a.download = campaign?.regulationFile?.signedUrl || '';
                  a.target = '_blank';
                  a.click();
                  apiAnalyticsDownload.fetch({ variables: { fileId: campaign?.regulationFile?._id || '' } });
                }}
                type="primary"
                download
              >
                BAIXAR REGULAMENTO
              </Button>
              {!!campaign.presentationFile?.signedUrl ? (
                <ButtonLink
                  onClick={() => {
                    let a = document.createElement('a');
                    a.href = campaign?.presentationFile?.signedUrl || '#';
                    a.download = campaign?.presentationFile?.signedUrl || '';
                    a.target = '_blank';
                    a.click();
                    apiAnalyticsDownload.fetch({ variables: { fileId: campaign?.regulationFile?._id || '' } });
                  }}
                  type="primary"
                  download
                >
                  BAIXAR APRESENTAÇÃO
                </ButtonLink>
              ) : null}
              {!!toIncentiveTable && (
                <ButtonLinkTo type="primary" to={routes.listarMetas.mount(incentiveCampaign.result?._id)}>
                  DETALHAMENTO DE RESULTADOS
                </ButtonLinkTo>
              )}

              {!!contestacoes.length && (
                <ButtonLinkTo type="primary" to={routes.acompanharContestacao.mount(incentiveCampaign.result?._id)}>
                  Acompanhar contestação
                </ButtonLinkTo>
              )}
            </CampanhaVigenteButtonsContainer>

            {/* {periodOptions?.length ? (
              <>
                <CampanhaPeriodTitleWrapper>Selecione o período</CampanhaPeriodTitleWrapper>
                <Select placeholder="Período" onChange={(e) => setPeriod(e)} value={period} options={periodOptions} />
              </>
            ) : null} */}

            <Divider />
            {!!toIncentiveTable ? (
              <>
                <CampanhaVigenteTitleWrapper>
                  {campaign?.status?._id === 'with_results' ? 'Resultados' : 'Resultado'}
                </CampanhaVigenteTitleWrapper>

                <IncentiveTable data={toIncentiveTable} />
              </>
            ) : (
              <EmptyData text="Aguardando apuração da campanha" />
            )}

            {campaign.status?._id === 'with_results' && canContestate && (
              <ContestationContainer>
                <p>
                  No caso de não-concordância com o resultado divulgado da campanha, o participante terá o período
                  limite para a contestação de até {range} dias corridos após a data de divulgação{' '}
                </p>
                <ContestButton onClick={() => setShowModalContestation(true)}>{range} para contestar</ContestButton>
              </ContestationContainer>
            )}

            <ContestationModal
              title="Contestação"
              visible={showModalContestation}
              onCancel={() => setShowModalContestation(false)}
              footer={[]}
            >
              <Former
                initialValues={initValues}
                config={{
                  fields,
                  submitButton: {
                    label: 'enviar',
                  },
                }}
                onSubmit={async ({ data }: any) => {
                  const { indicator, evidence, message } = data;
                  const record = {
                    indicator,
                    message,
                    fileIds: [evidence],
                    parentId: campaign?._id,
                  };
                  await CampaignContestationCreateOne.fetch({
                    variables: { record },
                    appendToFragment: `
                    record {
                      protocol
                    }`,
                  }).then((ctx) => {
                    if (!ctx.result?.record) {
                      message.error('Não foi possível realizar essa ação');
                    } else {
                      setShowModalContestation(false);
                      success(ctx?.result?.record?.protocol);
                    }
                  });
                }}
              />
            </ContestationModal>
          </>
        )}
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  title
  faq
  periodOption,
  status {
    _id
  }
  views {
    count
  }
  likes {
    count
  }
  cardImageFile {
    downloadUrl
  }
  bannerContentFile {
    downloadUrl
    signedUrl
  }
  regulationFile {
    downloadUrl
    signedUrl

  }
  presentationFile {
    downloadUrl
    signedUrl
  }
`;

const appendToFragmentUserCampaign = `
User {
  name
}
IncentiveCampaign {
  campaignType
}
fileStructure {
  goals {
    name
    goal
    reached
  }
}`;
