import React from 'react';
import { Button } from '@digi-tim-19/components';
import { Divider, Icon, Input } from 'antd';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { ModalCreateAlternatives } from './ModalCreateAlternatives';
import { ModalAlternatives } from './ModalAlternatives';

export interface Alternatives {
  id: string;
  description: string;
  isCorrect: boolean;
}

export interface Questions {
  id: string;
  description: string;
  alternatives: Alternatives[];
}

interface Props {
  disabled?: boolean;
  limit?: number;
  values?: Questions[];
  onChange?: (data: Questions[]) => void;
}

const Wrapper = styled.div`
  width: 100%;
`;

const FieldContainer = styled.div`
  width: 98%;
  height: 50px;
  display: flex;
  gap: 10px;

  #question-field {
    padding: 4px 11px 4px !important;
  }
`;

const Alternatives = styled.div`
  width: 100%;
`;

const QuestionsContainer = styled.div`
  width: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 20px;
`;

const Description = styled.div`
  flex: 1;
  padding: 0 10px;
`;

const Action = styled.div`
  height: 100%;
  width: max-content;
  display: flex;
  gap: 10px;
`;

export const QuizAlternativesField = ({ limit = 10, values = [], onChange, disabled = false }: Props) => {
  const [questionDescription, setQuestionDescription] = React.useState<string | undefined>();
  const [questionAlternatives, setQuestionAlternatives] = React.useState<Alternatives[]>([]);
  const [questions, setQuestions] = React.useState<Questions[]>(values);

  const handleAddQuestion = (value: string | undefined, alternatives: Alternatives[]) => {
    if (!value) return;

    setQuestions((prevState) => {
      const data = [...prevState, { description: value.trim(), alternatives }].map((el: any) => {
        return {
          ...el,
          id: el?.id || uuidv4().replace(/\-/g, ''),
        };
      });
      if (onChange) onChange([...data]);
      return [...data];
    });

    setQuestionDescription(undefined);
    setQuestionAlternatives([]);
  };

  const handleEditQuestion = (id: string, value: string) => {
    setQuestions((prevState) => {
      const questionIndex = prevState.findIndex(question => question.id === id)
      const question = prevState.filter(question => question.id === id)[0]
      question.description = value
      prevState.splice(questionIndex, 1, question)
      if (onChange) onChange([...prevState]);
      return prevState
    });
  }

  const handleRemoveQuestion = (id: string) => {
    setQuestions((prevState) => {
      const data = [...prevState].filter((el: any) => el.id !== id);
      if (onChange) onChange([...data]);
      return [...data];
    });
  };

  return (
    <Wrapper>
      {!disabled && questions.length < limit && (
        <React.Fragment>
          <FieldContainer>
            <Input
              id="question-field"
              placeholder="Questão"
              disabled={disabled}
              value={questionDescription || ''}
              onChange={(e: any) => {
                setQuestionDescription(e?.target?.value);
              }}
            />
            <ModalCreateAlternatives
              values={questionAlternatives}
              total={questionAlternatives.length}
              onChange={(alternatives) => setQuestionAlternatives(alternatives)}
            />
            <Button
              disabled={
                disabled ||
                !questionDescription ||
                String(questionDescription).trim() === '' ||
                questionAlternatives.length <= 1
              }
              onClick={() => handleAddQuestion(questionDescription, questionAlternatives)}
            >
              SALVAR
            </Button>
          </FieldContainer>
          <Divider />
        </React.Fragment>
      )}
      <QuestionsContainer>
        {questions.length === 0 ? (
          <p>Nenhuma questão cadastrada</p>
        ) : (
          <React.Fragment>
            {questions.map((el: Questions, index: number) => {
              return (
                <QuestionWrapper key={`question-${index}`}>
                  <Input
                    id="question-field"
                    placeholder="Questão"
                    value={el.description || ''}
                    onChange={(e: any) => {
                      handleEditQuestion(el.id, e?.target?.value);
                    }}
                  />
                  {/* <Description>{el.description}</Description> */}
                  <Action>
                    <ModalAlternatives alternatives={el.alternatives} onChange={(alternatives) => setQuestionAlternatives(alternatives)}/>
                    <Button disabled={disabled} onClick={() => handleRemoveQuestion(el.id)}>
                      <Icon type="delete" />
                    </Button>
                  </Action>
                </QuestionWrapper>
              );
            })}
          </React.Fragment>
        )}
      </QuestionsContainer>
    </Wrapper>
  );
};
