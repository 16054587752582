import React from 'react';
import moment from 'moment';
import { Icon as AntdIcon } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const mapStatus = new Map([
  ['in_progress', 'Em andamento'],
  ['finished', 'Finalizado'],
]);

const Icon = styled(AntdIcon)`
  font-size: 23px;
  cursor: pointer;
`;

const VerificationBtnAction = ({ challengeId }: { challengeId: string }) => {
  const history = useHistory();
  return <Icon type="diff" onClick={() => history.push(`gamificacao-missoes-apuracao/${challengeId}`)} />;
};

export const columns: any[] = [
  {
    title: 'Nome',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>,
  },
  {
    title: 'Status',
    dataIndex: 'challengeData.status',
    key: 'updatedAt',
    render: (text: string) => <p>{mapStatus.get(text)}</p>,
  },
  {
    title: 'Chips',
    dataIndex: 'amountChips',
    key: 'amountChips',
    render: (text: string) => <p>{text}</p>,
  },
  {
    title: 'Data de criação',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string) => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
  },
  {
    title: 'Data de atualização',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (text: string) => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
  },
  {
    title: 'Apurar resultado',
    key: 'action',
    align: 'center',
    render: (_: any, record: any) => <VerificationBtnAction challengeId={record._id} />,
  },
];
