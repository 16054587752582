"use strict";

var _slicedToArray = require("/home/jenkins/tim-consumer/packages/site-consumer/node_modules/@babel/runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var antd_1 = require("antd");

var react_1 = __importStar(require("react"));

var ProviderSelecionarPublico_1 = require("./ProviderSelecionarPublico");

var TreeNode = antd_1.Tree.TreeNode;

exports.Tree = function (_ref) {
  var data = _ref.data,
      name = _ref.name,
      initialValues = _ref.initialValues,
      onChange = _ref.onChange;
  var selecionarHook = ProviderSelecionarPublico_1.useSelecionarPublico();

  var _react_1$default$useS = react_1.default.useState({
    expandedKeys: ['Selecionar Todos', 'residencial', 'corporate'],
    autoExpandParent: true,
    checkedKeys: [],
    selectedKeys: []
  }),
      _react_1$default$useS2 = _slicedToArray(_react_1$default$useS, 2),
      state = _react_1$default$useS2[0],
      setState = _react_1$default$useS2[1];

  react_1.useEffect(function () {
    if (initialValues) {
      selecionarHook.setState(name, initialValues);
      setState(Object.assign(Object.assign({}, state), {
        checkedKeys: initialValues,
        selectedKeys: initialValues
      }));
    }
  }, [initialValues]);

  var onExpand = function onExpand(expandedKeys) {
    setState(Object.assign(Object.assign({}, state), {
      expandedKeys: expandedKeys,
      autoExpandParent: false
    }));
  };

  var onCheck = function onCheck(checkedKeys) {
    if (onChange) onChange(checkedKeys);
    selecionarHook.setState(name, checkedKeys);
    setState(Object.assign(Object.assign({}, state), {
      checkedKeys: checkedKeys
    }));
  };

  var onSelect = function onSelect(selectedKeys) {
    setState(Object.assign(Object.assign({}, state), {
      selectedKeys: selectedKeys
    }));
  };

  var renderTreeNodes = function renderTreeNodes(items) {
    return items.map(function (item) {
      if (item.children) {
        return react_1.default.createElement(TreeNode, {
          title: item.title,
          key: item.key,
          dataRef: item
        }, renderTreeNodes(item.children));
      }

      return react_1.default.createElement(TreeNode, Object.assign({
        key: item.key
      }, item));
    });
  };

  return react_1.default.createElement(antd_1.Tree, {
    checkable: true,
    onExpand: onExpand,
    expandedKeys: state.expandedKeys,
    autoExpandParent: state.autoExpandParent,
    onCheck: onCheck,
    checkedKeys: state.checkedKeys,
    onSelect: onSelect,
    selectedKeys: state.selectedKeys
  }, renderTreeNodes(data));
};