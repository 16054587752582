import React, { useEffect, useMemo, useState } from 'react';

import { Former, SelecionarPublico, usePublico, SearchUser, LoadingIndicator } from '@digi-tim-19/components';

import { EnumFileKind, EnumMaterialKind } from '../../autogenerated/client/types';
import { message } from 'antd';
import { routes } from '../../config/routes';
import { useHistory } from 'react-router';
import { Container } from './Cadastrar/stylesCadastrar';
import { useClient } from '../../autogenerated/client/client';
import { Material } from '../../autogenerated/client/types';
import { useContentTypePermissions } from '../../hooks/useContentTypePermissions';
import { selectedChannels } from '../../utils/selectedChannels';
const selectedChannelsConsumer = selectedChannels();

const DocKind = EnumFileKind.AnyDoc;
const ImageKind = EnumFileKind.CardThumb;

export const Form = (props: TFormMaterialEditProps) => {
  const permissions = useContentTypePermissions();
  const { availableAtChannels, availableAtRegions, availableAtRoleGroups } = usePublico();
  const history = useHistory();

  const isUpdate = !!props.initialValues;

  const initialValues = useMemo(() => {
    return {
      ...props.initialValues,
      documento: (props.initialValues?.files || []).find((el) => el && el.kind === DocKind)?._id,
      cardimage: (props.initialValues?.files || []).find((el) => el && el.kind === ImageKind)?._id,
      categories:
        props?.initialValues?.categories
          ?.filter((el) => el?._id === 'menu:cardapio_de_ofertas')
          ?.map((el) => el?._id) || [],
      subCategories:
        props?.initialValues?.categories
          ?.filter((el) => el?.parentId === 'menu:cardapio_de_ofertas')
          .map((el) => el?._id) || [],
      notifyEmailsBeforeExpire: props.initialValues?.validity?.notifyEmailsBeforeExpire,
    };
  }, [props.initialValues]);

  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const createMaterial = useClient('MaterialCreateOne');

  const [categoriesSelected, selectCategory] = useState<string[]>(initialValues.categories as string[]);
  const [subCategoriesSelected, setSubCategoriesSelected] = useState<string[]>(initialValues.subCategories as string[]);

  useEffect(() => {
    const filterSubCategories = subCategoriesSelected.filter((subCategory) =>
      categoriesSelected.find((category) => subCategory.includes(category)),
    );

    setSubCategoriesSelected(filterSubCategories);
  }, [categoriesSelected]);

  const categories = [
    {
      value: 'menu:cardapio_de_ofertas',
      label: 'Cardápio de Ofertas',
    },
  ];

  const subCategories = useMemo(
    () =>
      [
        {
          id: 'menu:cardapio_de_ofertas:pos_pago',
          value: 'menu:cardapio_de_ofertas:pos_pago',
          title: 'Pós-Pago',
          pId: 'menu:cardapio_de_ofertas',
        },
        {
          id: 'menu:cardapio_de_ofertas:controle',
          value: 'menu:cardapio_de_ofertas:controle',
          title: 'Controle',
          pId: 'menu:cardapio_de_ofertas',
        },
      ].filter((option) => categoriesSelected.includes(option.pId)),
    [categoriesSelected],
  );

  if (permissions.loading) return <LoadingIndicator />;

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              list: [
                {
                  name: 'validity',
                  label: 'Data de Vigência',
                  type: 'rangePicker',
                  required: true,
                  extraProps: {
                    format: 'DD/MM/YYYY',
                  },
                  maxWidth: '250px',
                },
                {
                  name: 'documento',
                  label: '',
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: DocKind,
                    CTA: 'IMPORTAR Documento',
                    excludeCheckType: true,
                  },
                },
                {
                  name: 'cardimage',
                  label: '',
                  type: 'upload',
                  extraProps: {
                    kind: ImageKind,
                    CTA: 'IMPORTAR IMAGEM',
                  },
                },
              ],
            },
            {
              name: 'categories',
              label: 'Categorias',
              options: categories,
              required: true,
              defaultValue: initialValues?.categories || [],
              extraProps: {
                mode: 'tags',
                onSelect: (value: string) => selectCategory([...categoriesSelected, value]),
                onDeselect: (value: string) => selectCategory(categoriesSelected.filter((el) => el !== value)),
              },
            },
            {
              name: 'subCategories',
              label: 'Sub-Categorias',
              treeOptions: subCategories,
              required: true,
              defaultValue: subCategoriesSelected,
              type: 'treeSelect',
              afterChange: (value: string[]) => setSubCategoriesSelected(value),
              extraProps: {
                treeCheckable: true,
              },
            },
            {
              list: [
                {
                  name: 'title',
                  label: 'TÍTULO',
                  required: true,
                  validate: (value: any) => {
                    const format = /[#%&;]/;
                    if (format.test(value)) return 'Os caracteres #%&; não são permitidos';

                    if (value) return !(value.length > 110) ? undefined : 'Máximo de 110 caracteres';

                    return undefined;
                  },
                },
                {
                  name: 'description',
                  label: 'DESCRIÇÃO',
                  type: 'textarea',
                  extraProps: {
                    rows: 5,
                  },
                  validate: (value: any) => {
                    const format = /[#%&]/;
                    if (format.test(value)) return 'Os caracteres #%& não são permitidos';

                    if (value) return !(value.length > 1200) ? undefined : 'Máximo de 1200 caracteres';

                    return undefined;
                  },
                },
              ],
            },
            {
              name: 'tags',
              label: 'tags',
              type: 'tags',
            },
            {
              name: 'notifyEmailsBeforeExpire',
              label: 'Notificar antes de expirar',
              placeholder: 'Notificar antes de expirar',
              extraProps: {
                returnProp: 'email',
              },
              component: SearchUser,
            },
            {
              className: 'Footer',
              inline: true,
              list: [
                {
                  custom: (
                    <SelecionarPublico
                      initialValues={props.initialValues}
                      selectedChannels={!isUpdate ? selectedChannelsConsumer : []}
                      {...permissions.filterOptions}
                    />
                  ),
                },
              ],
            },
          ],
          submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(routes.comparaAiListar.path),
          },
        }}
        onSubmit={async function ({ data }) {
          const categories = [...categoriesSelected, ...subCategoriesSelected];

          const record = {
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            categories: categories.filter(Boolean),
            kind: EnumMaterialKind.CompareThere,
            validity: {
              start: data.validity[0],
              end: data.validity[1],
              notifyEmailsBeforeExpire: data.notifyEmailsBeforeExpire,
            },
            fileIds: [data.documento, data.cardimage].filter(Boolean),
            title: data.title,
            description: data.description,
            tags: data.tags,
          };
          if (isUpdate && data._id) {
            await MaterialUpdateOne.fetch({
              variables: { record, filter: { _id: data._id } },
            }).then((ctx) => {
              if (!ctx.errors) {
                history.push(routes.comparaAiListar.mount());
              }
            });
          } else {
            await createMaterial
              .fetch({
                variables: { record },
              })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success('Conteúdo cadastrado com sucesso');
                  history.push(routes.comparaAiListar.path);
                } else {
                  message.error('Conteúdo não cadastrado');
                }
              });
          }
        }}
      />
    </Container>
  );
};

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
};
