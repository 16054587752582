import { Col as ColAntd, Row } from 'antd';
import styled from 'styled-components';

export const Col = styled(ColAntd)<{ align?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align || 'center'};
  min-height: 50px;
  color: #1a5fc3;

  @media (max-width: 750px) {
    min-height: 25px;

    .userInfo {
      margin-top: 20px !important;
      flex-direction: column;
      gap: 0 !important;
    }

    .userChips {
      padding-top: 4px;
    }
  }

  p {
    margin: 0;
  }
`;

export const ColHeader = styled(ColAntd)<{ align?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align || 'center'};
  min-height: 50px;

  p {
    margin: 0;
  }
`;

export const AvatarCustom = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 2px solid #f80000;
`;

export const HeaderTable = styled(Row)`
  border-radius: 0px 0px 10px 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;

  div:not(:last-child) {
    border-right: 1px solid white;
  }

  .header_mobile {
    display: none;
  }

  @media (max-width: 750px) {
    .header_mobile {
      display: flex;
    }
    .header_desk {
      display: none;
    }
  }
`;

export const PositionDetail = styled(Row)`
  padding: 5;
  height: 40px;
  text-transform: uppercase;
  color: #1e60be;
`;

export const RowTable = styled(Row)`
  :nth-child(even) {
    background: #cfebff;
  }
`;

export const RowMyPosition = styled(Row)`
  border-radius: 10px;

  div {
    color: #1a5fc3 !important;
  }

  div > span,
  div > img {
    border: 2px solid #fff !important;
  }

  div:last-child > img {
    border: none !important;
  }
`;

export const RowLastPosition = styled(Row)`
  border-radius: 10px;

  div {
    color: #fff !important;
  }

  div > span,
  div > img {
    border: 2px solid #fff !important;
  }

  div:last-child > img {
    border: none !important;
  }
`;

export const TableContainer = styled.div`
  border: 2px solid;
  border-color: #0014bb;
  border-radius: 10px;
  overflow: hidden;
`;

export const NothingFound = styled.h1`
  width: 100%;
  text-align: center;
  color: #6c757d;
  font-size: 18px;
`;
