import React, { useState } from 'react';

import {
  Former,
  SelecionarPublico,
  SearchUser,
  usePublico,
  LoadingIndicator
} from '@digi-tim-19/components';
import styled from 'styled-components';

import {
  EnumFileKind,
  EnumMaterialKind,
  EnumMaterialCommunicationItemsConfigKind,
  EnumMaterialStatus
} from '../../autogenerated/client/types';
import { message } from 'antd';
import { routes } from '../../config/routes';
import { useHistory } from 'react-router';
import { Container } from './stylesForm';
import { useClient } from '../../autogenerated/client/client';
import { Material } from '../../autogenerated/client/types';
import { useDimensions } from '@digi-tim-19/utils/build';
import { useContentTypePermissions } from '../../hooks/useContentTypePermissions';
import { selectedChannels } from '../../utils/selectedChannels';
const selectedChannelsConsumer = selectedChannels();

const DocKind = EnumFileKind.AnyDoc;
const ImageKind = EnumFileKind.NotificationImage;

export const Divider = styled.hr`
  border: none;
  background: #ccc;
  height: 1px;
  width: 100%;
  margin: 20px 0;
`;

export const Form = (props: TFormMaterialEditProps) => {
  const permissions = useContentTypePermissions();
  const {
    availableAtChannels = [],
    availableAtRegions = [],
    availableAtRoleGroups = []
  } = usePublico();
  const history = useHistory();
  const [showButton, setShowButton] = useState<boolean>(true);

  const isUpdate = !!props.initialValues;

  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const createMaterial = useClient('MaterialCreateOne');

  const getCategories = useClient('CategoryFindMany');

  function mapCategories(item: any) {
    return {
      label: item?.name?.toUpperCase(),
      value: item?._id
    };
  }

  const [categories, setCategories] = React.useState(
    getCategories?.result?.map(mapCategories)
  );

  React.useEffect(() => {
    if (getCategories.result === null || getCategories.result === undefined) {
      getCategories
        .fetch({ variables: { limit: 123, filter: { parentId: 'menu' } } })
        .then((ctx) => {
          if (ctx.result) {
            const categoriesFiltered = ctx.result.filter((item) => {
              return (
                item?._id !== 'menu:gerenciar_conteudo' &&
                item?._id !== 'menu:outros' &&
                item?._id !== 'menu:favoritos' &&
                item?._id !== 'menu:notificacoes' &&
                item?._id !== 'menu:atividades_recentes'
              );
            });
            setCategories(categoriesFiltered.map(mapCategories));
          }
        });
    }
  }, [getCategories.result]);

  const initialValues = {
    ...props.initialValues,
    mailing: (props.initialValues?.files || []).find(
      (el) => el && el.kind === DocKind
    )?._id,
    category: (props.initialValues?.categories || []).find(
      (el) => el && el._id !== 'menu:notificacoes' && el.name
    )?._id,
    status: props.initialValues?.status?._id,
    validity: props.initialValues?.validity?.start,
    showButton: !props.initialValues?.communicationItemsConfig?.find(
      (item) => item
    )?.templateProps?.HIDE_BUTTON
  };

  React.useEffect(() => {
    if (isUpdate) setShowButton(initialValues.showButton);
  }, [initialValues]);

  const { isMobile } = useDimensions();

  if (permissions.loading) return <LoadingIndicator />;

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              list: [
                {
                  className: 'header',
                  list: [
                    {
                      name: 'validity',
                      label: 'Programar publicação',
                      type: 'datePicker',
                      required: true,
                      extraProps: {
                        showTime: { format: 'HH:mm' },
                        format: 'DD/MM/YYYY HH:mm'
                      },
                      maxWidth: '250px'
                    },
                    {
                      name: 'category',
                      label: 'Categoria do menu',
                      options: categories,
                      maxWidth: '250px'
                    }
                  ]
                },
                {
                  custom: <Divider />
                },
                {
                  name: 'title',
                  label: 'Título',
                  required: true,
                  validate: (value: any) => {
                    const format = /[#%&;]/;
                    if (format.test(value))
                      return 'Os caracteres #%&; não são permitidos';

                    if (value)
                      return !(value.length > 110)
                        ? undefined
                        : 'Máximo de 110 caracteres';

                    return undefined;
                  }
                },
                {
                  name: 'description',
                  label: 'Descrição',
                  type: 'textarea',
                  extraProps: {
                    rows: 5
                  },
                  validate: (value: any) => {
                    const format = /[#%&]/;
                    if (format.test(value))
                      return 'Os caracteres #%& não são permitidos';

                    if (value)
                      return !(value.length > 1200)
                        ? undefined
                        : 'Máximo de 1200 caracteres';

                    return undefined;
                  }
                },
                {
                  custom: <Divider />
                },
                {
                  inline: true,
                  list: [
                    {
                      name: 'showButton',
                      label: 'Exibir botão',
                      type: 'select',
                      initialValue: showButton,
                      afterChange: setShowButton,
                      options: [
                        {
                          label: 'Sim',
                          value: true
                        },
                        {
                          label: 'Não',
                          value: false
                        }
                      ]
                    },
                    {
                      name: 'notificationLink',
                      label:
                        'Link para onde a notificação deve encaminhar o usuário',
                      required: showButton,
                      disabled: !showButton,
                      validate: (value: any) => {
                        if (value)
                          return !(value.length > 1200)
                            ? undefined
                            : 'Máximo de 1200 caracteres';

                        return undefined;
                      }
                    }
                  ]
                },
                {
                  list: [
                    {
                      label: 'Palavra-chave',
                      name: 'tags',
                      type: 'tags'
                    }
                  ]
                },
                {
                  className: 'Footer',
                  inline: !isMobile,
                  list: [
                    {
                      custom: (
                        <SelecionarPublico
                          initialValues={props.initialValues}
                          selectedChannels={
                            !isUpdate ? selectedChannelsConsumer : []
                          }
                          {...permissions.filterOptions}
                        />
                      )
                    },
                    {
                      name: 'mailing',
                      label: '',
                      type: 'upload',
                      extraProps: {
                        kind: 'mailing',
                        CTA: 'IMPORTAR MAILING',
                        excludeCheckType: true,
                      }
                    }
                  ]
                },
                {
                  name: 'availableForUsers',
                  label: 'Responsável',
                  component: SearchUser
                }
              ]
            }
          ],
          submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(routes.notificacoesListar.path)
          }
        }}
        onSubmit={async function ({ data }) {
          const record = {
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            availableForUsers: data.availableForUsers,
            categories: [data.category, 'menu:notificacoes'].filter(Boolean),
            kind: EnumMaterialKind.Communication,
            validity: { start: data.validity },
            fileIds: [data.mailing].filter(Boolean),
            title: data.title,
            tags: data.tags,
            status: EnumMaterialStatus.Approved,
            description: data.description,
            notificationLink: data.notificationLink,
            communicationItemsConfig: [
              {
                kind: EnumMaterialCommunicationItemsConfigKind.Notification,
                templateProps: {
                  HIDE_BUTTON: !data.showButton
                }
              }
            ]
          };

          if (isUpdate && data._id) {
            await MaterialUpdateOne.fetch({
              variables: { record, filter: { _id: data._id } }
            }).then((ctx) => {
              if (!ctx.errors) {
                history.push(routes.notificacoesListar.mount());
              }
            });
          } else {
            await createMaterial
              .fetch({
                variables: { record }
              })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success('Notificação cadastrada com sucesso');
                  history.push(routes.notificacoesListar.path);
                } else {
                  message.error('Notificação não cadastrada');
                }
              });
          }
        }}
      />
    </Container>
  );
};

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
};
