import React, { useEffect, useMemo, useState } from 'react';
import { routes } from '../../config/routes';
import { useHistory } from 'react-router';
import { message } from 'antd';
import moment from 'moment';
import { formatDate } from '@digi-tim-19/utils/build';

import {
  Former,
  LoadingIndicator,
  SearchUser,
  SelecionarPublico,
  usePublico,
  useCurrentUser,
} from '@digi-tim-19/components';
import { Modal } from 'antd';
import { EnumFileKind } from '@digi-tim-19/components/build/main/DataProviders/types';
import { useContentTypePermissions } from '../../hooks/useContentTypePermissions';
import { EnumMaterialKind, Material } from '../../autogenerated/client/types';
import { useClient } from '../../autogenerated/client/client';
import { Container } from './Cadastrar/stylesCadastrar';
import { useCategoriesSubCategoriesForm } from '../../hooks/categories/useCategoriesSubCategoriesForm';
import { selectedChannels } from '../../utils/selectedChannels';
const selectedChannelsConsumer = selectedChannels();

const DocKind = EnumFileKind.AnyDoc;

export const Form = (props: TFormMaterialEditProps) => {
  const user = useCurrentUser().result;
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { availableAtChannels, availableAtRegions = [], availableAtRoleGroups = [] } = usePublico();

  const permissions = useContentTypePermissions();
  const history = useHistory();
  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const createMaterial = useClient('MaterialCreateOne');
  const sendEmailFront = useClient('SendEmailFront');

  const queryMaterial = useClient('MaterialFindOne', {
    appendToFragment: `     
                    _id
                    title
                    updatedAt
                    createdAt
                    numericId
                    files {
                      _id
                      title
                      kind
                      signedUrl
                      extension
                    }
                  `,
  });

  const queryFile = useClient('FileFindById', {
    appendToFragment: `
      _id
      url
      title
    `,
  });

  const {
    categories,
    subCategories,
    categoriesSelected,
    subCategoriesSelected,
    getCategoriesInitialValue,
    getSubCategoriesInitialValue,
    selectCategory,
    setSubCategoriesSelected,
  } = useCategoriesSubCategoriesForm('menu:processos', props.initialValues);

  useEffect(() => {
    if (categoriesSelected.includes('menu:processos:mapa_de_processos')) {
      setOpenModal(true);
    }
  }, [categoriesSelected]);

  const isUpdate = !!props.initialValues;

  const start = props.initialValues?.validity?.start;
  const end = props.initialValues?.validity?.end;

  let validity: any[] = [];

  if (start && end) validity = [moment(start), moment(end)];

  const initialValues = useMemo(() => {
    return {
      ...props.initialValues,
      documento: (props.initialValues?.files || []).find(
        (el) => el && (el.kind === DocKind || el.kind === EnumFileKind.Pdf),
      )?._id,
      categories: getCategoriesInitialValue(props.initialValues?.categories || []),
      subCategories: getSubCategoriesInitialValue(props.initialValues?.categories || []),
      notifyEmailsBeforeExpire: props.initialValues?.validity?.notifyEmailsBeforeExpire,
    };
  }, [props.initialValues]);

  if (permissions.loading) return <LoadingIndicator />;

  const onSubmit = async ({ data }: any) => {
    const fileIds = [data.documento].filter((item) => item !== undefined && item !== '');

    const start = data.validity ? data.validity[0] || data.validity.start : null;
    const end = data.validity ? data.validity[1] || data.validity.end : null;
    const notifyEmailsBeforeExpire = data.notifyEmailsBeforeExpire;

    const subCategories = data.subCategories || [];

    const record = {
      validity: start === null || end === null ? undefined : { start, end, notifyEmailsBeforeExpire },
      fileIds: fileIds,
      categories: [...categoriesSelected, ...subCategories].filter(Boolean),
      title: data.title,
      description: data.description,
      html: data.html ?? '',
      availableAtRegions,
      availableAtChannels,
      availableAtRoleGroups,
      kind: configPage.kind,
      tags: data.tags,
    };
    
    if (isUpdate && data._id) {
      //carrega os dados do material antes dele ser atualizado
      const oldMaterial = await queryMaterial.fetch({
        variables: { filter: { _id: data._id } },
      });

      await MaterialUpdateOne.fetch({
        variables: { record, filter: { _id: data._id } },
      }).then(async (ctx) => {
        if (!ctx.errors) {
          //caso tenha atualizado corretamente, envia um email para a lista de emails que tem q ser notificados casa haja alguma atualização
          //busca o processo para informar a data exata da atualização
          const updatedMaterial = await queryMaterial.fetch({
            variables: { filter: { _id: data._id } },
          });

          let viewLink = '';

          let attach: any = [];

          const oldFileId = oldMaterial?.result?.files ? oldMaterial?.result?.files[0]?._id : '';

          if (fileIds.length > 0 && fileIds[0] !== oldFileId) {
            //quer dizer q enviou um novo arquivo
            //caso tenha um arquivo antigo e este tenha sido alterado
            oldMaterial?.result?.files?.map((item) => {
              // attach.push({
              //   filename: `arquivo_antigo_${item?.title}`,
              //   path: item?.signedUrl
              // });

              attach.push(item?._id);
            });

            const newFile = await queryFile.fetch({
              variables: { _id: fileIds[0] },
            });

            if (newFile) {
              //o novo arquivo, lê o arquivo separadamente
              // attach.push({
              //   filename: `arquivo_novo_${newFile?.result?.title}`,
              //   path: newFile?.result?.signedUrl
              // });
              attach.push(newFile?.result?._id);
              viewLink =
                newFile?.result?.extension == 'pdf'
                  ? `https://tanarede.timbrasil.com.br/visualizar/${updatedMaterial?.result?._id}`
                  : `https://tanarede.timbrasil.com.br/material/conteudo/${updatedMaterial?.result?._id}`;
            }
          }

          const updatedFields = `
            <ul>
              ${
                oldMaterial?.result?.title !== data.title
                  ? `<li>O título foi atualizado de <b>${oldMaterial?.result?.title}</b> para <b>${data.title}</b></li>`
                  : ``
              }
              ${
                oldMaterial?.result?.description !== data.description
                  ? `<li>A descrição foi atualizada de <b>${oldMaterial?.result?.description}</b> para <b>${data.description}</b></li>`
                  : ``
              }
              ${
                fileIds.length > 0 && fileIds[0] !== oldFileId
                  ? `<li>Os arquivos alterados do processo estão em anexo</li>`
                  : ``
              }
            </ul>
          `;

          sendEmailFront.fetch({
            variables: {
              subject: 'Atualização de Processo - Tá Na Rede TIM',
              text: `O processo ${updatedMaterial?.result?.title} - ID ${
                updatedMaterial?.result?.numericId
              } foi atualizado por ${user?.name}, em ${formatDate(
                updatedMaterial?.result?.updatedAt,
                'DD/MM/YYYY HH:mm:ss',
              )}<br/><br/>
                ${updatedFields}
                <br/><br/>
                <small>Link para visualização: <a href='${viewLink}' target='_blank'>${viewLink}</a></small>`,
              attach,
              categories: categoriesSelected,
            },
          });

          history.push(configPage.routeList);
        } else {
          console.error('Erro ao atualizar', ctx.errors);
        }
      });
    } else {
      await createMaterial.fetch({ variables: { record } }).then(async (ctx) => {
        const idCreatedMaterial = ctx?.result?.recordId;

        //busca o processo para informar a data exata da atualização
        const updatedMaterial = await queryMaterial.fetch({
          variables: { filter: { _id: idCreatedMaterial } },
        });
        let viewLink = '';

        let attach: any = [];

        const newFile = await queryFile.fetch({
          variables: { _id: fileIds[0] },
        });

        if (newFile) {
          //o novo arquivo, lê o arquivo separadamente
          // attach.push({
          //   filename: newFile?.result?.title,
          //   path: newFile?.result?.signedUrl
          // });
          attach.push(newFile?.result?._id);
          viewLink =
            newFile?.result?.extension == 'pdf'
              ? `https://tanarede.timbrasil.com.br/visualizar/${updatedMaterial?.result?._id}`
              : `https://tanarede.timbrasil.com.br/material/conteudo/${updatedMaterial?.result?._id}`;
        }

        const updatedFields = `
            <ul>
              ${data.title ? `<li>Título <b>${data.title}</b></li>` : ``}
              ${data.description ? `<li>Descrição <b>${data.description}</b></li>` : ``}
              ${fileIds.length > 0 ? `<li>Os arquivos alterados do processo estão em anexo</li>` : ``}
            </ul>
          `;

        sendEmailFront.fetch({
          variables: {
            subject: 'Cadastro de Processo - Tá Na Rede TIM',
            text: `O processo ${updatedMaterial?.result?.title} - ID ${
              updatedMaterial?.result?.numericId
            } foi criado por ${user?.name}, em ${formatDate(
              updatedMaterial?.result?.createdAt,
              'DD/MM/YYYY HH:mm:ss',
            )}<br/><br/>
                ${updatedFields}
                <br/><br/>
                <small>Link para visualização: <a href='${viewLink}' target='_blank'>${viewLink}</a></small>`,
            attach,
            categories: categoriesSelected,
          },
        });

        if (ctx?.result) {
          message.success(configPage.messageSuccess);
          history.push(configPage.routeList);
        } else {
          console.error('errors', ctx.errors);
          message.error(configPage.messageError);
        }
      });
    }
  };

  return (
    <Container>
      <Modal title="Alerta!" visible={openModal} onOk={() => setOpenModal(false)} onCancel={() => setOpenModal(false)}>
        <p>O Mapa de processos anterior será substituido</p>
      </Modal>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              list: [
                {
                  name: 'validity',
                  label: 'Data de Vigência',
                  type: 'rangePicker',
                  extraProps: {
                    format: 'DD/MM/YYYY',
                    defaultValue: validity,
                    getCalendarContainer: (triggerNode: any) => triggerNode.parentNode,
                  },
                  maxWidth: '250px',
                },
                {
                  name: 'documento',
                  label: '',
                  type: 'upload',
                  extraProps: {
                    kind: DocKind,
                    CTA: 'IMPORTAR PROCESSO',
                    excludeCheckType: true,
                  },
                },
              ],
            },
            {
              name: 'categories',
              label: 'Categorias',
              options: categories,
              required: true,
              extraProps: {
                mode: 'tags',
                onSelect: (value: string) => selectCategory(value, true),
                onDeselect: (value: string) => selectCategory(value, false),
              },
            },
            {
              name: 'subCategories',
              label: 'Sub-Categorias',
              treeOptions: subCategories,
              defaultValue: subCategoriesSelected,
              required: true,
              type: 'treeSelect',
              afterChange: (value: string[]) => setSubCategoriesSelected(value),
              extraProps: {
                treeCheckable: true,
              },
            },
            {
              list: [
                {
                  name: 'title',
                  label: 'TÍTULO',
                  required: true,
                  validate: (value: any) => {
                    const format = /[#%&;]/;
                    if (format.test(value)) return 'Os caracteres #%&; não são permitidos';

                    if (value) return !(value.length > 110) ? undefined : 'Máximo de 110 caracteres';

                    return undefined;
                  },
                },
                {
                  name: 'description',
                  label: 'DESCRIÇÃO',
                  type: 'textarea',
                  extraProps: {
                    rows: 5,
                  },
                  validate: (value: any) => {
                    const format = /[#%&]/;
                    if (format.test(value)) return 'Os caracteres #%& não são permitidos';

                    if (value) return !(value.length > 1200) ? undefined : 'Máximo de 1200 caracteres';

                    return undefined;
                  },
                },
                {
                  name: 'html',
                  label: 'Conteúdo HTML',
                  type: 'textarea',
                  extraProps: {
                    rows: 5,
                  },
                },
                {
                  name: 'tags',
                  label: 'tags',
                  type: 'tags',
                },
                {
                  name: 'notifyEmailsBeforeExpire',
                  label: 'Notificar antes de expirar',
                  placeholder: 'Notificar antes de expirar',
                  extraProps: {
                    returnProp: 'email',
                  },
                  component: SearchUser,
                },
              ],
            },
            {
              className: 'Footer',
              inline: true,
              required: true,
              list: [
                {
                  custom: (
                    <SelecionarPublico
                      initialValues={props.initialValues}
                      selectedChannels={!isUpdate ? selectedChannelsConsumer : []}
                      {...permissions.filterOptions}
                    />
                  ),
                },
              ],
            },
          ],
          submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(routes.processosListar.path),
          },
        }}
        onSubmit={onSubmit}
      />
    </Container>
  );
};

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
};

const configPage = {
  kind: EnumMaterialKind.Process,
  routeList: routes.processosListar.mount(),
  messageSuccess: 'Processo cadastrado com sucesso',
  messageError: 'Processo não cadastrado',
};
