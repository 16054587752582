import { string, number, object, array, ValidationError } from 'yup';

type JSONFormatFileContent = {
  Nome: string;
  Cargo: string;
  Posição: number;
  Regional: string;
  Pontos: number;
  Email: string;
  CPF: string;
  Matricula: number;
  Elegibilidade1: number;
  Elegibilidade2: number;
};

export function parseXlsxToJSON(json: any[]) {
  const regexMeta = /^Meta-/g;
  const regexElegibilidade = /Elegibilidade-/;

  return json?.map((item: JSONFormatFileContent) => {
    return {
      name: item.Nome,
      cpf: String(item?.CPF)
        .normalize('NFD')
        .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
        .match(/[0-9]{11}/g)
        ?.find((el) => el),
      points: item.Pontos,
      subscription: String(item.Matricula),
      eligibilities: Object.keys(item)
        .filter((el) => regexElegibilidade.test(el))
        .map((str) => {
          const objIndex = Object.keys(item).findIndex((element) => element === str);
          return {
            name: str.split('-')[1],
            value: Object.values(item).find((_, index) => index === objIndex),
          };
        }),
      goals: Object.keys(item)
        .filter((el) => regexMeta.test(el))
        .map((str) => {
          const objIndex = Object.keys(item).findIndex((element) => element === str);
          return {
            name: str.split('-')[1],
            goal: Object.values(item).find((_, index) => index === objIndex),
            reached: Object.values(item).find((_, index) => index === objIndex + 1),
          };
        }),
    };
  });
}

export const schemaFileContent = object().shape({
  name: string().required('O nome é obrigatório'),
  //cpf: string().required('CPF inválido'),
  points: number().required('Os pontos são obrigatórios'),
  //subscription: string().required('A matrícula é obrigatória'),
  eligibilities: array().of(
    object().shape({
      name: string().nullable(),
      value: number().nullable(),
    }),
  ),
  goals: array().of(
    object().shape({
      goal: number().nullable(),
      name: string().nullable(),
      reached: number().nullable(),
    }),
  ),
});

export type ValidationErrosType = {
  line: number;
  message: string;
};

export function validateFile(content: any[], validationErrors: ValidationErrosType[], setIsOpen: Function) {
  return Promise.all(
    content?.map(async (c: any, index: any) => {
      try {
        await schemaFileContent.validate(c, {
          abortEarly: false,
        });
      } catch (err) {
        if (err instanceof ValidationError) {
          err.inner.forEach((error) => {
            validationErrors.push({
              line: index + 2,
              message: error.message,
            });
          });
        }
        setIsOpen(true);
      }
    }),
  );
}

export const campaignPeriodOptions = [
  {
    id: 'single',
    value: 'single',
    label: 'Único'
  },
  {
    id: 'fortnightly',
    value: 'fortnightly',
    label: 'Quinzenal'
  },
  {
    id: 'monthly',
    value: 'monthly',
    label: 'Mensal',
  },
  {
    id: 'quarterly',
    value: 'quarterly',
    label: 'Trimestral',
  },
  {
    id: 'semester',
    value: 'semester',
    label: 'Semestral',
  },
  {
    id: 'yearly',
    value: 'yearly',
    label: 'Anual',
  },
];
