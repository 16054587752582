import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  Button,
  JoystickIconSVG,
  LoadingIndicator,
  Table,
  TitleDescription,
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { FilterContainer, Wrapper } from './styles';
import { columns } from './columns';
import { useClient } from '../../../autogenerated/client/client';
import { useRouteMatch } from 'react-router';
import { message } from 'antd';
import { downloadXLSX } from '../../../utils/downloadXlsx';
import { ContainerIcon } from '../../Gamefication/List/styles';

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    userId
    userChallengeData
    challengeId
    createdAt
    updatedAt
    challengeData {
      title
      amountChips
      challengeData
    }
    user {
      name
      matricula
      channel
    }
  }
`;

export const GameficationResearchAnswersList = () => {
  const { params }: any = useRouteMatch();
  const getUserChallenges = useClient('UserChallengePagination');
  const downloadUserChallenges = useClient('UserChallengeAnswersDownload');
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState<any>([]);
  const perPage = 10;
  const total = getUserChallenges?.result?.pageInfo?.itemCount || 0;

  const handleGetGameficationAnswers = () => {
    getUserChallenges
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            challengeId: params.id,
            status: 'completed',
          },
        },
      })
      .then((response: any) => {
        const items = response?.result?.items?.map((userChallenge: any) => {
          return {
            ...userChallenge,
          };
        });
        setData([...items]);
      });
  };

  const handleDownloadBase = () => {
    downloadUserChallenges.fetch({ variables: { challengeId: params.id } }).then((response: any) => {
      if (!response?.result?.data || response?.result?.data?.length === 0) {
        message.error('Não há registro para download de planilha');
        return;
      }

      downloadXLSX('pesquisa-respostas', response?.result?.schema, response?.result?.data);
    });
  };

  React.useEffect(() => {
    handleGetGameficationAnswers();
  }, [page]);

  return (
    <PageTemplate>
      <React.Fragment>
        <Wrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Gamificação (Pesquisas)', link: routes.gameficationResearchesList.mount() },
              { title: 'Respostas' },
            ]}
          />
          <TitleDescription
            renderCustomIcon={
              <ContainerIcon>
                <JoystickIconSVG />
              </ContainerIcon>
            }
            title="Gamificação (Respostas)"
            description="Listar e consultar as respostas da pesquisa da gamificação"
          />
        </Wrapper>
        <FilterContainer>
          <Button
            loading={downloadUserChallenges.loading}
            style={{ width: 'max-content' }}
            onClick={() => handleDownloadBase()}
          >
            EXPORTAR RESPOSTAS
          </Button>
        </FilterContainer>
        {getUserChallenges.loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e: any) => setPage(e),
            }}
          />
        )}
      </React.Fragment>
    </PageTemplate>
  );
};
