import React, { useEffect, useState } from 'react';
import { message, DatePicker } from 'antd';
import { useHistory } from 'react-router';
import { formatDate } from '@digi-tim-19/utils/build';
import { Breadcrumb, TitleDescription, Table } from '@digi-tim-19/components';

import { columns } from './columns';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyMaterialInput
} from '../../../autogenerated/client/types';
import {
  NotificationsWrapper,
  FilterContainer,
  Button,
  CleanButton
} from './stylesListar';
import { useFilterAuthor } from '../../../components/FilterAuthor/useFilterAuthor';
import moment from 'moment';

const { RangePicker } = DatePicker;

export const SistemaVendasListar: React.FC<TlinksUteisListarProps> = () => {
  const filterAuthor = useFilterAuthor();

  const [page, setPage] = useState(1);
  const [resultMaterial, setResultMaterial] = useState<any>([]);

  const [createdAt, setCreatedAt] = useState();
  const [show, setShow] = useState([]);

  const [updatedAt, setUpdatedAt] = useState();
  const [showUpdatedAt, setShowUpdatedAt] = useState([]);

  const history = useHistory();

  const MaterialDeleteOne = useClient('MaterialRemoveOne');
  const getMaterials = useClient('MaterialPagination');

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: 10,
          filter: {
            kind: EnumMaterialKind.LinksUteis,
            validityEnum: ValidityEnum.Any,
            includeDrafts: true,
            filterAuthor: filterAuthor.filter,
            owner: filterAuthor.ownerFilter,
            createdAtRange: createdAt,
            updatedAtRange: updatedAt
          },
          sort: SortFindManyMaterialInput.IdDesc
        }
      })
      .then((ctx) => {
        if (ctx.result && ctx.result.items) {
          setResultMaterial(ctx.result.items);
        }
      });
  }, [page, filterAuthor.signature, createdAt, updatedAt]); //eslint-disable-line

  const total = getMaterials?.result?.pageInfo.itemCount;

  const data = resultMaterial?.map((material: any) => ({
    changedBy: material?.changedBy,
    key: material?._id,
    id: material?.numericId,
    title: material?.title,
    link: material?.description,
    category: material?.categories[0]?.name,
    // expired: material?.validityEnum === ValidityEnum.Expired ? "Sim" : "Não",
    status: material?.status?.label,
    user: material?.author?.name || 'backup',
    cadastrada: formatDate(material?.createdAt, 'DD/MM/YYYY'),
    atualizada: formatDate(material?.updatedAt, 'DD/MM/YYYY'),
    onEdit: () =>
      history.push(routes.sistemasVendasEditar.mount(material?._id)),
    onDelete: () =>
      MaterialDeleteOne.fetch({
        variables: { filter: { _id: material?._id } },
        afterMutate: /^Material/
      }).then((ctx) => {
        message.success('Link deletado com sucesso');
      }),

    onRead: () => history.push(routes.sistemasVendas.mount())
  }));

  const onChange = (e: any) => {
    if (e.length > 0) {
      setCreatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setShow(e);
    } else {
      setShow([]);
      setCreatedAt(undefined);
    }
  };

  const onChangeUpdatedAt = (e: any) => {
    if (e.length > 0) {
      setUpdatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setShowUpdatedAt(e);
    } else {
      setShowUpdatedAt([]);
      setUpdatedAt(undefined);
    }
  };

  const resetFilters = () => {
    setCreatedAt(undefined);
    setShow([]);
    setUpdatedAt(undefined);
    setShowUpdatedAt([]);
  };

  return (
    <PageTemplate>
      <>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Sistemas de Vendas' },
              { title: 'Listar' }
            ]}
          />
          <TitleDescription
            iconType={'laptop'}
            title={'Sistemas de Vendas'}
            description={
              'Nesta página constam os links para acessar os sistemas, pós vendas e administrativos.'
            }
          />
        </NotificationsWrapper>
        <FilterContainer>
          <Button to={routes.sistemasVendasCadastrar.path}>
            Cadastrar link
          </Button>

          {filterAuthor.render()}
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={'DD/MM/YYYY'}
            placeholder={['CADASTRO', '']}
            onChange={(e) => {
              setPage(1);
              onChange(e);
            }}
            value={show}
          />

          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={'DD/MM/YYYY'}
            placeholder={['ATUALIZAÇÃO', '']}
            onChange={(e) => {
              setPage(1);
              onChangeUpdatedAt(e);
            }}
            value={showUpdatedAt}
          />

          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>

        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: 10,
            current: page,
            total: total!,
            onChange: (e) => setPage(e)
          }}
        />
      </>
    </PageTemplate>
  );
};

export type TlinksUteisListarProps = {};

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  count
  items {
    changedBy {
      name
      dateText
    },
    title
    createdAt
    updatedAt
    validityEnum
    author {
    name
    }
    categories {
      name
      parentId
      parentCategory {
        name
        _id
      }
    }
    status {
      label
    }
     _id
  }
`;
