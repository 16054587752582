import React from 'react';
import { useClient } from '../../autogenerated/client/client';
import { SortFindManyCategoryInput } from '../../autogenerated/client/types';

export function useCategoryFindMany(
  parentIdOrIds: string | string[],
  countNivelSubCategories: number
) {
  const filter = {
    parentId: typeof parentIdOrIds === 'string' ? parentIdOrIds : undefined,
    _ids: typeof parentIdOrIds !== 'string' ? parentIdOrIds : undefined
  };
  const variables = {
    filter: filter,
    limit: 100,
    sort: SortFindManyCategoryInput.SortAsc
  };

  const appendToFragment =
    [...Array(countNivelSubCategories)]
      .map(() => {
        return `subCategories (sort: ${SortFindManyCategoryInput.SortAsc}) { _id
    name
    typeIcon
    parentId
    routeName
    createdAt
    updatedAt `;
      })
      .join('') + [...Array(countNivelSubCategories)].map((e) => '}').join(' ');

  const CategoryFindMany = useClient('CategoryFindMany', {
    variables,
    fetchOnMount: true,
    cache: true,
    appendToFragment
  });

  React.useEffect(() => {
    if (CategoryFindMany.result === undefined) {
      CategoryFindMany.fetch();
    }
  }, [CategoryFindMany.result]);

  return { ...CategoryFindMany };
}
