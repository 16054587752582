import React from 'react';
import { colors } from '@digi-tim-19/theme';
import { Avatar } from 'antd';
import { AvatarCustom, PositionRanking } from './stylesRankingTable';

type ConfigColumnRankingTable = {
  id: string;
  key: string;
  label: string;
  style: {
    xl?: number;
    sm?: number;
    align?: string;
  };
  renderRowCell?: (item: any, obj: any, index?: number) => JSX.Element;
};

export type ConfigRankingTable = {
  header?: {
    style?: Record<string, any>;
  };
  body?: {
    style?: Record<string, any>;
  };
  myPosition?: {
    style?: Record<string, any>;
  };
  lastPosition?: {
    style?: Record<string, any>;
  };
  columns: ConfigColumnRankingTable[];
};

export const configRankiingTable: ConfigRankingTable = {
  header: {
    style: { backgroundColor: '#1E60BE', color: colors.white },
  },
  body: {
    style: { padding: 5, color: colors.blue },
  },
  myPosition: {
    style: { backgroundColor: '#cfebff', color: '#1E60BE', padding: 5 },
  },
  lastPosition: {
    style: { backgroundColor: '#1E60BE', color: colors.white, padding: 5 },
  },
  columns: [
    {
      id: 'position',
      key: 'position',
      label: 'Posição',
      style: { xl: 3, sm: 16, align: 'center' },
      renderRowCell: (position) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '45px',
            height: '45px',
            border: '1px solid #28508C',
            borderRadius: '10px',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          {position}
        </div>
      ),
    },
    {
      id: 'user',
      key: 'userDetail.name',
      label: 'Nome',
      style: { xl: 10, sm: 16, align: 'center' },
      renderRowCell: (name: any) => {
        const url = ''; // userContent?.avatar?.signedUrl;
        const formatedName = name?.toUpperCase();
        return (
          <div
            className="userInfo"
            style={{ display: 'flex', width: '100%', padding: '0 20px', alignItems: 'center', gap: '20px' }}
          >
            {url ? (
              <AvatarCustom src={url} />
            ) : (
              <Avatar size={40} icon="user" style={{ border: '2px solid #F80000' }} />
            )}
            {formatedName}
          </div>
        );
      },
    },
    {
      id: 'region',
      key: 'userDetail.region',
      label: 'Regional',
      style: { xl: 3, sm: 16 },
      renderRowCell: (value: string) => <p>{value?.toUpperCase()}</p>,
    },
    {
      id: 'channel',
      key: 'userDetail.roleGroup',
      label: 'Canal',
      style: { xl: 4, sm: 16 },
      renderRowCell: (value: string) => (
        <p>
          {value
            ?.split(':')[0]
            ?.replace(/_/g, ' ')
            .toUpperCase()}
        </p>
      ),
    },
    {
      id: 'chips',
      key: 'points',
      label: 'Pontuação Ranking',
      style: { xl: 4, sm: 16, align: 'center' },
      renderRowCell: (chips) => (
        <div className="userChips" style={{ display: 'flex', alignItems: 'center' }}>
          <PositionRanking isNumber={!isNaN(Number(chips))}>{chips}</PositionRanking>
        </div>
      ),
    },
  ],
};
