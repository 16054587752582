import * as React from 'react';
import { useClient } from '../../../autogenerated/client/client';
import { SortFindManyCategoryInput } from '../../../autogenerated/client/types';

const appendToFragment = `subCategories (sort: ${SortFindManyCategoryInput.SortAsc}) {
    _id
    parentId
    name
    subCategories (sort: ${SortFindManyCategoryInput.SortAsc}) {
      _id
      parentId
      name
      subCategories (sort: ${SortFindManyCategoryInput.SortAsc}) {
        _id
        parentId
        name
      }
    }
  }`;

type Option = {
  id: string;
  pId: number;
  value: string;
  title: string;
  selectable?: boolean;
};

export function useInformativoProdutos() {
  const CategoryFindMany = useClient('CategoryFindMany', {
    fetchOnMount: true,
    variables: {
      limit: 123,
      filter: {
        _ids: [
          'menu:cardapio_de_ofertas',
          'menu:residencial',
          'menu:planos_corporativos',
          'menu:cardapio_smb',
          'menu:processos',
          'menu:tim_tech',
          'menu:outros',
          'menu:veja_mais',
          'menu:campanha_de_incentivo'
        ]
      },
      sort: SortFindManyCategoryInput.SortAsc
    },
    appendToFragment
  });

  function loadSubLevel(category: any, items: any) {
    if (category?.subCategories && category.subCategories.length > 0) {
      const subCategories = category?.subCategories;
      subCategories.forEach((subCategory: any) => {
        items.push(loadSubLevels(subCategory));
        loadSubLevel(subCategory, items);
      });
    }
  }

  function loadLevel1(category: any, index: number): Option {
    return {
      id: category?._id,
      pId: index,
      value: category?._id,
      title: category?.name,
      selectable:
        category?._id === 'menu:outros' ||
        category?._id === 'menu:campanha_de_incentivo'
    };
  }

  function loadSubLevels(item: any): Option {
    return {
      id: item?._id,
      pId: item?.parentId,
      value: item?._id,
      title: item?.name?.toUpperCase()
    };
  }

  return React.useMemo(() => {
    const categories = CategoryFindMany.result;
    const itemsTreeSelect: Option[] = [];

    categories?.forEach((category, index) => {
      if (category?._id !== 'menu:tim_tech') {
        itemsTreeSelect.push(loadLevel1(category, index));
        loadSubLevel(category, itemsTreeSelect);
      } else {
        const newCategory = obterCategoriaTimTech(categories);
        itemsTreeSelect.push(loadLevel1(newCategory, index));
        loadSubLevel(newCategory, itemsTreeSelect);
      }
    });

    return itemsTreeSelect;
  }, [CategoryFindMany.result]); //eslint-disable-line
}

function obterCategoriaTimTech(categories: any[]) {
  const newCategory = categories
    .filter((item) => item?._id === 'menu:tim_tech')
    .map((item) => {
      let categoryFiltered = {
        _id: item?._id,
        name: item?.name,
        subCategories: <any>[]
      };

      item?.subCategories
        ?.filter(
          (itemFilter: any) =>
            itemFilter?._id === 'menu:tim_tech:informativos'
        )
        .map((subItem: any) => {
          subItem?.subCategories?.map((subSubItem: any) => {
            categoryFiltered.subCategories.push({
              ...subSubItem,
              parentId: 'menu:tim_tech'
            });
          });
        });

      const tabelaPrecos = item?.subCategories.find(
        (itemFilter: any) =>
          itemFilter?._id === 'menu:tim_tech:tabela_de_precos'
      );

      if (tabelaPrecos)
        categoryFiltered.subCategories.push({
          ...tabelaPrecos,
          parentId: 'menu:tim_tech'
        });

      return categoryFiltered;
    });

  return newCategory.find((item) => item?._id === 'menu:tim_tech');
}
