import { useClient } from '../../autogenerated/client/client';
import * as React from 'react';

export function useIncentiveCampaign(id: string, appendToFragment: any) {
  const variables = { _id: id };

  const IncentiveCampaignOne = useClient('IncentiveCampaignFindById', {
    variables,
    appendToFragment,
  });

  React.useEffect(() => {
    if (id) {
      IncentiveCampaignOne.fetch({ variables });
    }
  }, [id]); //eslint-disable-line

  return IncentiveCampaignOne;
}

export function useFileContentCampaign(idCampaign: string, appendToFragmentFileContent: any) {
  const variables = {
    filter: {
      inIncentiveCampaignId: [idCampaign],
    },
    limit: 100,
  };

  const getFileContent = useClient('FileContentCampaignFindMany', {
    appendToFragment: appendToFragmentFileContent,
    variables,
  });

  React.useEffect(() => {
    if (idCampaign) {
      getFileContent.fetch({ variables });
    }
  }, [idCampaign]);

  return getFileContent;
}

export function useUserElegibilities(
  incentiveCampaignId: string,
  fileContentCampaignId: string,
  appendToFragment: string,
) {
  const variables = {
    incentiveCampaignId: incentiveCampaignId,
  };

  const getUserElegibilities = useClient('UserEligibilities', {
    appendToFragment: appendToFragment,
    variables,
  });

  React.useEffect(() => {
    if (incentiveCampaignId) {
      getUserElegibilities.fetch({ variables });
    }
  }, [incentiveCampaignId, fileContentCampaignId]);

  return getUserElegibilities;
}
