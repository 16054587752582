"use strict";

var _slicedToArray = require("/home/jenkins/tim-consumer/packages/site-consumer/node_modules/@babel/runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var emoji_mart_1 = require("emoji-mart");

var antd_1 = require("antd");

exports.SufixEmoji = function (props) {
  var textInput = props.textInput,
      setTextInput = props.setTextInput,
      openEmoji = props.openEmoji;

  var _react_1$useState = react_1.useState(!!openEmoji),
      _react_1$useState2 = _slicedToArray(_react_1$useState, 2),
      emojiStatus = _react_1$useState2[0],
      setEmojiStatus = _react_1$useState2[1];

  react_1.useEffect(function () {
    setEmojiStatus(!!openEmoji);
  }, [openEmoji]);
  return react_1.default.createElement("div", {
    className: "emoji-wrapper"
  }, react_1.default.createElement(antd_1.Icon, {
    type: "smile",
    style: {
      color: 'rgba(0,0,0,.45)'
    },
    onClick: function onClick() {
      return emojiStatus ? setEmojiStatus(false) : setEmojiStatus(true);
    }
  }), react_1.default.createElement("div", {
    className: "emoji"
  }, react_1.default.createElement(emoji_mart_1.Picker, {
    set: "emojione",
    style: {
      display: emojiStatus ? 'block' : 'none',
      position: 'absolute',
      top: '25px',
      right: '-10px'
    },
    include: ['people'],
    showPreview: false,
    onSelect: function onSelect(emoji) {
      var selectedEmoji = emoji.native;
      setTextInput(textInput + selectedEmoji);
    },
    i18n: {
      categories: {
        people: 'Emoticons'
      }
    }
  })));
};