import React from 'react';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { useClient } from '../../../autogenerated/client/client';
import { groupBy } from 'lodash';

export function useContentPermissionsOptionsHelper() {
  const ContentPermissionsHelper = useClient('ContentPermissionsHelper', {
    fetchOnMount: true,
    cache: true
  });

  const items = React.useMemo(() => {
    const allKindItems: Option[] = sortBy(
      (ContentPermissionsHelper.result || []).map((el) => {
        return {
          parentName: el?.parentName || undefined,
          title: el?.label || '',
          label: el?.label || '',
          value: el?.value || '',
          pId: el?.pId || undefined,
          id: el?.id || undefined,
          kind: el?.kind || '',
          key: el?.value || ''
        };
      }),
      'title'
    );

    const contentTypes = getContentTypes(allKindItems);
    const regions = allKindItems.filter(
      (el) => el.kind === 'region' && el.value !== 'HQ'
    );
    const actions = allKindItems.filter((el) => el.kind === 'action');

    let roleGroups = allKindItems.filter((el) => el.kind === 'roleGroup');
    roleGroups.forEach((roleGroup) => {
      // adicionando os parents dos roleGroups
      if (roleGroup.pId && roleGroup.parentName) {
        roleGroups.push({
          title: roleGroup.parentName,
          value: roleGroup.pId,
          key: roleGroup.pId,
          id: roleGroup.pId,
          kind: 'roleGroup'
        });
      }
    });
    roleGroups = uniqBy(roleGroups, 'value');

    return {
      contentTypes: addSelectAllOption(contentTypes),
      roleGroups: addSelectAllOption(roleGroups),
      regions: addSelectAllOption(regions),
      actions: addSelectAllOption(actions)
    };
  }, [ContentPermissionsHelper.loading]);

  return {
    loading: ContentPermissionsHelper.loading,
    items
  };
}

function addSelectAllOption(options: Option[]): Option[] {
  const sId = `select-all`;

  const selectAll = {
    title: 'Selecionar todos',
    value: sId,
    id: sId
  };

  return [
    selectAll as any,
    ...options.map((el) => {
      return {
        ...el,
        pId: el.pId || sId
      };
    })
  ];
}

type Option = {
  title: string;
  value: string;
  pId?: string;
  id?: string;
  key?: string;
  parentName?: string;
  kind: string;
};

function getContentTypes(allKindItems: Option[]) {
  const contentTypes = allKindItems.filter(
    (el) => el.kind === 'siteContentType'
  );

  const contentTypesGrouped = groupBy(contentTypes, 'pId');

  let newContentTypes: any[] = [];

  Object.keys(contentTypesGrouped).forEach((key) => {
    if (key === 'undefined') {
      contentTypesGrouped[key].map((item) => {
        newContentTypes.push({
          ...item,
          pId: undefined
        });
      });
    } else {
      const reference = contentTypesGrouped[key][0];

      newContentTypes.push({
        id: `pId_${reference.pId}`,
        label: reference.parentName,
        title: reference.parentName,
        value: reference.pId
      });

      contentTypesGrouped[key].map((item) => {
        newContentTypes.push({
          ...item,
          pId: `pId_${reference.pId}`
        });
      });
    }
  });

  return sortBy(newContentTypes, 'title');
}
