import { Modal, Select, message } from "antd";
import React from "react";
import { LoadingContainer } from "./styles";
import { LoadingIndicator } from "@digi-tim-19/components";
import { useClient } from "../../../autogenerated/client/client";

interface ModalProps {
    open: boolean;
    invoiceOrigins?: any;
    userChallengeId: string[];
    setOpen: (value: boolean) => void;
    setUserSelected: (value: []) => void;
    refetch?: () => void;
}

export const ModalWalletPoints = ({ open, invoiceOrigins, userChallengeId, setOpen, refetch, setUserSelected }: ModalProps) => {
    const userRegisterCreditPoints = useClient('UserCreditChallengePointsForManyAction', {
        afterMutate: /UserChallengePoints/,
    });
    const [walletId, setWalletId] = React.useState<any>(undefined);

    const { Option } = Select;

    return (
        <React.Fragment>
            <Modal
                title="Liberação de TIM Coins"
                visible={open}
                okText="Liberar TIM Coins"
                cancelText="Cancelar"
                onCancel={() => setOpen(false)}
                onOk={() => {
                    userRegisterCreditPoints
                      .fetch({
                        variables: {
                          userChallengeId,
                          walletId,
                        },
                      })
                      .then((response) => {
                        if (response?.errors && response?.errors?.length > 0) {
                          message.error(response?.errors[0]);
                        } else {
                          message.success(`Liberação de TIM Coins efetuada com sucesso`);
                          if (refetch) refetch();
                          setOpen(false);
                          setUserSelected([]);
                        }
                      });
                  }}
            >
                {invoiceOrigins?.data?.length === 0 ? (
                    <LoadingContainer>
                        <LoadingIndicator />
                    </LoadingContainer>
                ) : (
                    <React.Fragment>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="ORIGEM DA FATURA"
                            onChange={(value: any) => setWalletId(value)}
                        >
                            {invoiceOrigins?.data?.map((option: any) => {
                                return (
                                    <Option key={option._id} value={option._id}>
                                        {option?.invoiceOriginData?.name ? `${option?.invoiceOriginData?.name} - ` : ''}
                                        {option?.POIdentifier}
                                    </Option>
                                );
                            })}
                        </Select>
                    </React.Fragment>
                )}
            </Modal>
        </React.Fragment>
    )
}