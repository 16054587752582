import { useClient } from '../../autogenerated/client/client';

export function useInvoiceOriginOptions() {
  const data = useClient('ReconheceInvoiceOriginFindMany', {
    fetchOnMount: true,
    cache: true
  });

  return (data.result || []).map((item) => {
    return {
      value: item?._id || '',
      label: item?.name || ''
    };
  });
}
