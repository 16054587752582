import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { FormInformativos } from '../FormInformativos/FormInformativos';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { InformativosWrapper } from '../Cadastrar/stylesCadastrar';
import { useRouteMatch } from 'react-router';
import { useMaterial } from '../../../hooks/materials/useMaterial';
import { routes } from '../../../config/routes';

const appendToFragment = `
    title
    fileIds
    files {
      _id
      title
      kind
      downloadUrl
      signedUrl
    }
    validity {
      start,
      end,
      notifyEmailsBeforeExpire
    }
    categories {
      name
      parentId
      _id
    }
    description
    availableAtRegions
    availableAtChannels
    availableAtRoleGroups
    communicationItemsConfig {
      attachmentIds
    }
    email {
      templateName
      templateProps
      attachments{
        _id
        downloadUrl
        title
      }
      
      images {
        IMAGE_SRC_1
        IMAGE_SRC_2
      }
    }
`;

export const InformativosEditar = () => {
  const { params } = useRouteMatch();

  const informativo = useMaterial(params?.id, appendToFragment);

  return (
    <PageTemplate>
      <>
        <InformativosWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Informativos' },
              { title: 'Listar', link: routes.informativosListar.mount() },
              { title: 'Editar' }
            ]}
          />
          <TitleDescription
            iconType="info-circle"
            title="Informativos"
            description="Cadastramento e consulta de Informativos"
          />
        </InformativosWrapper>

        {informativo.result && (
          <ProviderSelecionarPublico>
            <FormInformativos initialValues={informativo.result} />
          </ProviderSelecionarPublico>
        )}
      </>
    </PageTemplate>
  );
};
