import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { routes } from '../../config/routes';
import { Input, DatePicker, Pagination } from 'antd';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  TitleDescription,
  MiniCard,
  CardCategory
} from '@digi-tim-19/components';
import moment from 'moment';
import { useClient } from '../../autogenerated/client/client';
import {
  EnumMaterialKind,
  SortFindManyMaterialInput
} from '../../autogenerated/client/types';
import { useHistory } from 'react-router';
import { actionsButton } from '../../utils/material';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';
import { PaginationWrapper } from '../../components/Page/PaginationWrapper';
import { getRouterByMaterial } from '@digi-tim-19/utils/build/routes/routesConsumer';
import { escapeStringRegexp } from '../../utils/formatters';

const PageContent = styled.div``;

export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;

  cursor: pointer;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Regional = () => {
  const history = useHistory();
  const [createdAt, setCreatedAt] = useState();
  const [show, setShow] = useState([]);

  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(1);
  const perPage = 20;

  const [filterSearch, setFilterSearch] = useState();

  const onChange = (e: any) => {
    setCreatedAt({
      start: moment(e[0]!).startOf('day')?.toISOString(),
      end: moment(e[1]!).endOf('day')?.toISOString()
    });
    setShow(e);
  };

  const getMaterials = useClient('MaterialPagination');

  const resetFilters = () => {
    setCreatedAt(undefined);
    setFilterSearch(undefined);
    setShow([]);
  };

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            kind: EnumMaterialKind.SeeMoreRegion,
            createdAtRange: createdAt,
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
          },
          sort: SortFindManyMaterialInput.IdDesc
        }
      })
      .then((response: any) => {
        setItems(response && response.result ? response.result.items : []);
      });
  }, [createdAt, filterSearch, page]); //eslint-disable-line

  const data = getMaterials?.result;

  const total = getMaterials.result?.pageInfo.itemCount;

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
    },
    [data]
  );

  const { listFolders, addFolder } = useFoldersPin();

  const cards = getCards(history);

  const cardsItems = [
    cards.pilulas,
    cards.comunicacaoParaOCanal,
    cards.videos,
    cards.materiaisParaPDV,
    cards.matinais
  ];

  return (
    <PageTemplate>
      <>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Veja Mais' },
            { title: 'Regional' }
          ]}
        />

        <TitleDescription
          iconType={'more'}
          title={'Regional'}
          description={`Aqui você encontra todos os documentos específicos da sua regional`}
        />
        <PageContent>
          <CardsWrapper>
            {cardsItems.map((card, index) => (
              <CardCategory key={index} {...card} />
            ))}
          </CardsWrapper>
        </PageContent>
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    title
    numericId
    createdAt
    fileIds
    files {
      _id
      extension
      kind
      signedUrl
      downloadUrl
    }
    categories {
      _id
      name
    }
    validity {
      start
      end
    }
    author {
      _id,
      name
    }
  }
`;

function getCards(history: any) {
  return {
    pilulas: {
      title: 'Pílulas',
      description:
        'Aqui você encontra todas as pílulas para te ajudar a vender mais.',
      urlBackground: '/defaultImages/Pilula.jpeg',
      icon: 'iconcheckList-copy',
      customIcon: true,
      url: () => history.push(routes.regionais.mount('regional', 'pilulas'))
    },
    comunicacaoParaOCanal: {
      title: 'Comunicação \n para o canal',
      description:
        'Aqui você encontra todas as informações importantes para o Canal.',
      urlBackground: '/defaultImages/Comunicacao_para_o_canal.jpeg',
      icon: 'info-circle',
      url: () =>
        history.push(
          routes.regionais.mount('regional', 'comunicacao_para_o_canal')
        )
    },
    videos: {
      title: 'Vídeos',
      description:
        'Assista os reforços de argumentação, lives e muito mais na nossa playlist do Ontube',
      urlBackground: '/defaultImages/5356_CardVideos.jpg',
      icon: 'play-square',
      url: () => history.push(routes.OnTubePlaylist.mount('regional'))
    },
    materiaisParaPDV: {
      title: 'Materiais para o PDV',
      description: 'Aqui você encontra todos os materiais para o seu PDV.',
      urlBackground: '/defaultImages/Materiais_para_o_PDV.jpeg',
      icon: 'book',
      url: () =>
        history.push(routes.regionais.mount('regional', 'materiais_para_o_pdv'))
    },
    matinais: {
      title: 'Matinais',
      description:
        'Aqui você encontra todas as apresentações com informações completas sobre os nossos produtos, lançamentos, entre outros.',
      urlBackground: '/defaultImages/Matinais.jpeg',
      icon: 'schedule',
      url: () => history.push(routes.regionais.mount('regional', 'matinais'))
    }
  };
}
