export function selectedChannels() {
  const selectedChannels = [
    'canais_diretos:analista',
    'canais_diretos:assistente',
    'canais_diretos:assistente_senior',
    'canais_diretos:consultor',
    'canais_diretos:consultor_senior',
    'canais_diretos:diretor',
    'canais_diretos:diretor_senior',
    'canais_diretos:especialista',
    'canais_diretos:especialista_senior',
    'canais_diretos:estagiario',
    'canais_diretos:executive_manager',
    'canais_diretos:jovem_aprendiz_administrativo',
    'canais_diretos:jovem_aprendiz_profissional_comercial',
    'canais_diretos:manager',
    'canais_diretos:senior_manager',
    'demais_areas:terminais_smb',
    'demais_areas:people_value',
    'demais_areas:operational_planning',
    'demais_areas:marketing_smb',
    'demais_areas:marketing_device_terminais',
    'demais_areas:marketing_consumer',
    'demais_areas:hdc',
    'demais_areas:customer_relations',
    'demais_areas:commissioning',
    'demais_areas:c6',
    'demais_areas:auditoria',
    'demais_areas:antifraude_e_seguranca_da_informacao',
    'demais_areas:altos_executivos',
    'inteligencia:senior_manager',
    'inteligencia:manager',
    'inteligencia:jovem_aprendiz_profissional_comercial',
    'inteligencia:jovem_aprendiz_administrativo',
    'inteligencia:executive_manager',
    'inteligencia:estagiario',
    'inteligencia:especialista_senior',
    'inteligencia:especialista',
    'inteligencia:diretor_senior',
    'inteligencia:diretor',
    'inteligencia:consultor_senior',
    'inteligencia:consultor',
    'inteligencia:assistente_senior',
    'inteligencia:assistente',
    'inteligencia:analista',
    'diretoria_consumer:senior_manager',
    'diretoria_consumer:manager',
    'diretoria_consumer:jovem_aprendiz_profissional_comercial',
    'diretoria_consumer:jovem_aprendiz_administrativo',
    'diretoria_consumer:executivo_de_contas_master',
    'diretoria_consumer:executive_manager',
    'diretoria_consumer:estagiario',
    'diretoria_consumer:especialista_senior',
    'diretoria_consumer:especialista',
    'diretoria_consumer:diretor_senior',
    'diretoria_consumer:diretor',
    'diretoria_consumer:consultor_senior',
    'diretoria_consumer:consultor',
    'diretoria_consumer:assistente_senior',
    'diretoria_consumer:assistente',
    'diretoria_consumer:analista',
    'suporte_a_vendas:spv_operacoes_comerciais',
    'suporte_a_vendas:spv_hdc',
    'suporte_a_vendas:senior_manager',
    'suporte_a_vendas:motorista',
    'suporte_a_vendas:manager',
    'suporte_a_vendas:jovem_aprendiz_profissional_comercial',
    'suporte_a_vendas:jovem_aprendiz_administrativo',
    'suporte_a_vendas:executive_manager',
    'suporte_a_vendas:estagiario',
    'suporte_a_vendas:especialista_senior',
    'suporte_a_vendas:especialista',
    'suporte_a_vendas:diretor_senior',
    'suporte_a_vendas:diretor_executivo',
    'suporte_a_vendas:diretor',
    'suporte_a_vendas:consultor_senior',
    'suporte_a_vendas:consultor',
    'suporte_a_vendas:assistente_senior',
    'suporte_a_vendas:assistente_administrativo',
    'suporte_a_vendas:assistente',
    'suporte_a_vendas:analista_junior',
    'suporte_a_vendas:analista_adm_jr',
    'suporte_a_vendas:analista',
    'trade:senior_manager',
    'trade:manager',
    'trade:jovem_aprendiz_administrativo',
    'trade:estagiario',
    'trade:especialista_senior',
    'trade:especialista',
    'trade:diretor',
    'trade:consultor_senior',
    'trade:consultor_relacionamento_i',
    'trade:consultor',
    'trade:assistente',
    'trade:analista'
  ];

  return selectedChannels;
}
