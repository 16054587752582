import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { PageWrapper } from '../Cadastrar/stylesCadastrar';
import { useRouteMatch } from 'react-router';
import { useMaterial } from '../../../hooks/materials/useMaterial';
import { routes } from '../../../config/routes';
import { EnumMaterialKind } from '../../../autogenerated/client/types';

export const MapaDeProcessosEditar = () => {
  const { params } = useRouteMatch();
  const appendToFragment = `
    title
    fileIds
    files {
      _id
      title
      kind
    }
    validity {
      start
      end
      notifyEmailsBeforeExpire
    }
    categories {
      _id
      name
      parentId
    }
    description
    availableAtRegions
    availableAtChannels
    availableAtRoleGroups
`;
  const material = useMaterial(params?.id, appendToFragment);

  const configPage = {
    pageName: 'Processos',
    icon: 'schedule',
    kind: EnumMaterialKind.Process,
    description: 'Edição de processos',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo', link: routes.home.mount() },
      { title: 'Processos', link: routes.mapaDeProcessosListar.mount() },
      { title: 'Listar', link: routes.mapaDeProcessosListar.mount() },
      { title: 'Editar', link: '' }
    ],
    routeList: routes.mapaDeProcessosListar.mount()
  };

  return (
    <PageTemplate>
      <>
        <PageWrapper>
          <Breadcrumb items={configPage.breadcrumb} />
          <TitleDescription
            iconType={configPage.icon}
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>

        {material.result && (
          <ProviderSelecionarPublico>
            <Form initialValues={material.result} />
          </ProviderSelecionarPublico>
        )}
      </>
    </PageTemplate>
  );
};
