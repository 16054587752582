import React from 'react';
import { useHistory } from 'react-router';
import { useContentTypePermissions } from '../../../hooks/useContentTypePermissions';
import { Container } from '../Create/styles';
import { Former, LoadingIndicator, SelecionarPublico, usePublico } from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { message } from 'antd';
import { useDimensions } from '@digi-tim-19/utils/build';

interface FormGameficationRankingProps {
  initialValues?: any;
}

export const FormGameficationRanking = (props: FormGameficationRankingProps) => {
  const permissions = useContentTypePermissions();
  const { availableAtChannels, availableAtRegions, availableAtRoleGroups } = usePublico();
  const { isMobile } = useDimensions();
  const history = useHistory();
  const [values, setValues] = React.useState(props?.initialValues || {});
  const createGameficationRanking = useClient('GameficationRankingCreateOne');
  const updateGameficationRanking = useClient('GameficationRankingUpdateById');
  const isUpdate = !!props.initialValues;

  const initialValues = React.useMemo(() => {
    return {
      title: values?.title,
      placements: values?.placements,
      availableAtRegions: values.availableAtRegions || [],
      availableAtRoleGroups: values.availableAtRoleGroups || [],
      availableAtChannels: values.availableAtChannels || [],
    };
  }, [values]);

  if (permissions.loading) return <LoadingIndicator />;

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={() => {
          return {
            fields: [
              {
                inline: true,
                list: [
                  {
                    name: 'title',
                    label: 'TÍTULO',
                    required: true,
                    validate: (value: any) => {
                      const format = /[#%&;]/;
                      if (format.test(value)) return 'Os caracteres #%&; não são permitidos';

                      if (value) return !(value.length > 110) ? undefined : 'Máximo de 110 caracteres';

                      return undefined;
                    },
                  },
                  {
                    required: true,
                    name: 'placements',
                    label: 'Quantidade de Posições',
                    type: 'select',
                    defaultValue: initialValues?.placements,
                    options: [
                      { label: '5', value: 5 },
                      { label: '10', value: 10 },
                      { label: '20', value: 20 },
                      { label: '50', value: 50 },
                      { label: '100', value: 100 },
                    ],
                    maxWidth: isMobile ? '100%' : '40%',
                  },
                ],
              },
              {
                inline: true,
                list: [
                  {
                    custom: (
                      <SelecionarPublico
                        initialValues={props.initialValues}
                        platform="consumer"
                        {...permissions.filterOptions}
                      />
                    ),
                  },
                ],
              },
            ],
            submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
            cancelButton: {
              label: 'CANCELAR',
              onClick: () => history.push(routes.gameficationRankingList.path),
            },
          };
        }}
        onSubmit={async ({ data }) => {
          if (availableAtRegions.length === 0 || availableAtRoleGroups.length === 0) {
            return message.error('Por favor Selecione o público');
          }

          const record: any = {
            title: data.title,
            placements: data.placements,
            availableAtRegions: availableAtRegions || [],
            availableAtRoleGroups: availableAtRoleGroups || [],
            availableAtChannels: availableAtChannels || [],
          };

          const callback = (ctx: { result?: any }) => {
            if (ctx?.result) {
              message.success(`Ranking ${isUpdate ? 'atualizado' : 'cadastrado'} com sucesso.`);
              history.push(routes.gameficationRankingList.mount());
            } else {
              setValues({ ...record });
            }
          };

          if (isUpdate) {
            await updateGameficationRanking
              .fetch({ variables: { record: { ...record, _id: props.initialValues!._id } } })
              .then(callback);
          } else {
            await createGameficationRanking.fetch({ variables: { record } }).then(callback);
          }

          return;
        }}
      />
    </Container>
  );
};
