import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useClient } from '../../autogenerated/client/client';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { ShareModalButton } from '@digi-tim-19/components';

const P = styled.p`
  margin-left: 10px;
  margin-bottom: 0px;
  font-size: 30px;
  line-height: 37px;
  font-family: TimLight;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #00508c;
`;

const Iframe = styled.iframe.attrs({
  className: 'ui-flex PageLoadHTML_Iframe'
})`
  border: none;
  width: 100%;
  min-height: 100vh;
`;

export const PageLoadHTML = () => {
  const { id } = useParams();
  const iframeRef = React.useRef<HTMLFrameElement | null>(null);

  const material = useClient('MaterialFindOne', {
    appendToFragment: ` 
      title    
      files {
        _id
        kind
        signedUrl
        extension
      }
      
      sentEmail {
        body
      }
    `,
    fetchOnMount: true
  });

  useEffect(() => {
    material
      .fetch({
        variables: {
          filter: {
            _id: id
          }
        }
      })
      .then((ctx) => {
        iframeRef.current?.contentDocument?.write(
          ctx.result?.sentEmail?.body || ''
        );
        iframeRef.current?.contentDocument?.close();
      });
  }, [id]);

  return (
    <PageTemplate>
      <>
        <P>{material.result?.title}</P>

        <Iframe
          ref={(ref: any) => {
            if (ref) {
              iframeRef.current = ref;
            }
          }}
          id={'iframe-html-preview'}
        />

        {material?.result && <ShareModalButton material={{
          _id: material?.result._id
        }} />}
      </>
    </PageTemplate>
  );
};
