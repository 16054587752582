import React, { useEffect, useState, useCallback } from 'react';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select,
  LoadingIndicator
} from '@digi-tim-19/components';
import { message, DatePicker } from 'antd';
import { useHistory } from 'react-router';
import { columns } from './columns';
import { formatDate } from '@digi-tim-19/utils/build';
import { useClient } from '../../../autogenerated/client/client';
import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyMaterialInput
} from '../../../autogenerated/client/types';

import {
  PageWrapper,
  FilterContainer,
  Button,
  Search,
  CleanButton
} from './stylesGerenciar';
import { routes } from '../../../config/routes';
import moment from 'moment';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { useFilterAuthor } from '../../../components/FilterAuthor/useFilterAuthor';
import { formatCategoriesNamesList } from '../../../utils/categories';
import { isImageExtension } from '@digi-tim-19/utils';
import { escapeStringRegexp } from '../../../utils/formatters';

const { RangePicker } = DatePicker;

export const MapaDeProcessosListar: React.FC<TProcessosListarProps> = () => {
  const [createdAt, setCreatedAt] = useState();
  const [updatedAt, setUpdatedAt] = useState();
  const [filterData, setFilterData] = useState<any>([]);
  const [show, setShow] = useState([]);
  const [showUpdatedAt, setShowUpdatedAt] = useState([]);
  const [filterSearch, setFilterSearch] = useState();
  const [items, setItems] = useState([]);
  const [sort, setSort] = useState(SortFindManyMaterialInput.IdDesc);
  const [updateTable, setUpdateTable] = useState('');
  const filterAuthor = useFilterAuthor();

  const history = useHistory();

  const getMaterials = useClient('MaterialPagination');

  const MaterialDeleteOne = useClient('MaterialRemoveOne');

  const [page, setPage] = useState(1);

  const sendEmailFront = useClient('SendEmailFront');

  const perPage = 10;

  const configPage = {
    pageName: 'Mapa de Processos',
    icon: 'schedule',
    description: 'Cadastramento e consulta de mapa de processos',
    messageDeletedSuccess: 'Mapa de processo deletado com sucesso',
    kind: EnumMaterialKind.Process,
    textButtonNew: 'CADASTRAR PROCESSO',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo' },
      { title: 'Processos' },
      { title: 'Listar' }
    ]
  };

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          perPage: perPage,
          page: page,
          filter: {
            kind: configPage.kind,
            validityEnum: ValidityEnum.Any,
            includeDrafts: true,
            createdAtRange: createdAt,
            updatedAtRange: updatedAt,
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
            filterAuthor: filterAuthor.filter,
            owner: filterAuthor.ownerFilter
          },
          sort: sort
        }
      })
      .then((response: any) => {
        setItems(response.result?.items);
      });
  }, [
    sort,
    createdAt,
    updatedAt,
    page,
    updateTable,
    filterSearch,
    filterAuthor.signature
  ]); //eslint-disable-line

  const resetFilters = () => {
    setSort(SortFindManyMaterialInput.IdDesc);
    setCreatedAt(undefined);
    setUpdatedAt(undefined);
    setFilterSearch(undefined);
    setFilterData([]);
    setShow([]);
    setShowUpdatedAt([]);
    setPage(1);
    filterAuthor.reset();
  };

  const total = getMaterials?.result?.pageInfo.itemCount;

  const data = items?.map((material: any) => {
    const categoria = material?.categories?.filter(
      (item: any) => item.parentId === 'menu:processos:mapa_de_processos'
    );

    const categoriesName = formatCategoriesNamesList(
      categoria?.map((el: any) => el?.name)
    );

    const originalData = material;

    return {
      changedBy: material?.changedBy,
      key: material?._id,
      id: material?.numericId,
      title: material?.title,
      categoria: categoria.length ? categoriesName : '-',
      criador: material?.author?.name,
      cadastrada: formatDate(material?.createdAt, 'DD/MM/YYYY'),
      atualizada: formatDate(material?.updatedAt, 'DD/MM/YYYY'),
      publicada: material.validity?.start
        ? formatDate(material.validity?.start, 'DD/MM/YYYY')
        : '-',
      status: material?.status?.label,
      onRead: () => {
        if (material.fileIds.length) {
          if (isImageExtension(material.files[0].extension)) {
            history.push(routes.imageViewer.mount(material?._id));
          } else {
            history.push(routes.pdfViewer.mount(material?._id));
          }
        } else {
          history.push(routes.conteudoMaterial.mount(material?._id));
        }
      },
      onEdit: () =>
        history.push(routes.mapaDeProcessosEditar.mount(material?._id)),
      onDelete: () =>
        MaterialDeleteOne.fetch({
          variables: { filter: { _id: material?._id } },
          afterMutate: /^Material/
        }).then((ctx) => {
          //quando exclui o processo, informa pra galera que ele foi removido
          let attach: any = [];

          originalData.files?.map((item: any) => {
            // attach.push({
            //   filename: `arquivo_antigo_${item?.title}`,
            //   path: item?.signedUrl
            // });
            attach.push(item?._id);
          });

          const updatedFields = `
            <ul>
              ${
                originalData.title
                  ? `<li>Título <b>${originalData.title}</b></li>`
                  : ``
              }
              ${
                originalData.description
                  ? `<li>Descrição <b>${originalData.description}</b></li>`
                  : ``
              }
              ${
                originalData.files?.length > 0
                  ? `<li>Os arquivos alterados do processo estão em anexo</li>`
                  : ``
              }
            </ul>
          `;
          let arrCategories: any = [];
          originalData?.categories?.map((cat: any) => {
            arrCategories.push(cat._id);
          });

          sendEmailFront.fetch({
            variables: {
              subject: 'Exclusão de Processo - Tá Na Rede TIM',
              text: `O processo ${originalData.title} - ID ${
                originalData.numericId
              } foi excluído em ${formatDate(
                new Date(),
                'DD/MM/YYYY HH:mm:ss'
              )}<br/><br/>
              ${updatedFields}
              <br/><br/>`,
              attach,
              categories: arrCategories
            }
          });

          message.success(configPage.messageDeletedSuccess);
          setUpdateTable(ctx.result?.recordId);
        })
    };
  });

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
      //setFilterData(data?.filter(material => material.title.toLowerCase().includes(e.toLowerCase())))
    },
    [data]
  );

  const onChange = (e: any) => {
    if (e.length > 0) {
      setCreatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setShow(e);
    } else {
      setShow([]);
      setCreatedAt(undefined);
    }
  };

  const onChangeUpdatedAt = (e: any) => {
    if (e.length > 0) {
      setUpdatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setShowUpdatedAt(e);
    } else {
      setShowUpdatedAt([]);
      setUpdatedAt(undefined);
    }
  };

  return (
    <PageTemplate>
      <>
        <PageWrapper>
          <Breadcrumb items={configPage.breadcrumb} />
          <TitleDescription
            iconType={configPage.icon}
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>
        <FilterContainer>
          <Button to={routes.mapaDeProcessosCadastrar.mount()}>
            {configPage.textButtonNew}
          </Button>
          <Select
            placeholder="Ordenar por:"
            options={order}
            onChange={(e) => {
              setPage(1);
              setSort(e);
            }}
            value={sort}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format="DD/MM/YYYY"
            placeholder={['CADASTRO', '']}
            onChange={(e) => {
              setPage(1);
              onChange(e);
            }}
            value={show}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format="DD/MM/YYYY"
            placeholder={['ATUALIZAÇÃO', '']}
            onChange={(e) => {
              setPage(1);
              onChangeUpdatedAt(e);
            }}
            value={showUpdatedAt}
          />
          <Search
            placeholder="Busca por título ou ID"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e) => {
              onSearch(e.target.value);
              setPage(1);
            }}
          />

          {filterAuthor.render()}

          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>
        {!data ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={filterData.length > 0 ? filterData : data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e) => setPage(e)
            }}
          />
        )}
      </>
    </PageTemplate>
  );
};

export type TProcessosListarProps = {};

const order = [
  { label: 'MAIS ANTIGO', value: SortFindManyMaterialInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyMaterialInput.IdDesc }
];

const appendToFragment = ` 
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    changedBy {
      name
      dateText
    },
    _id
    numericId
    title
    description
    createdAt
    updatedAt
    fileIds
    files {
      _id
      kind
      extension
      title
      signedUrl
    }
    status { 
      _id 
      label 
    } ,
    author { 
      _id, 
      name 
    }, 
    categories { 
      _id 
      name 
      parentId
    }
    validity { 
      start 
      end 
    } 
  }
`;
