import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { PageWrapper } from './styles';
import { Breadcrumb, JoystickIconSVG, LoadingIndicator, TitleDescription, useClient } from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { FormGameficationNivel } from '../Form/Form';
import { useRouteMatch } from 'react-router';
import { ContainerIcon } from '../../Gamefication/List/styles';

const appendToFragment = `
  _id
  title
  nivel
  amountChips
`;

export const GameficationNivelEdit = () => {
  const { params }: any = useRouteMatch();
  const getGameficationNivel = useClient('GameficationNivelFindById', {
    appendToFragment,
    variables: { _id: params?.id! },
    fetchOnMount: true,
  });
  const configPage = {
    pageName: 'Gamificação (Níveis)',
    icon: 'bar-chart',
    description: 'Edição e consulta de níveis da gamificação',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo', link: routes.home.mount() },
      { title: 'Gamificação (Níveis)', link: routes.gameficationNivelList.mount() },
      { title: 'Editar', link: routes.gameficationNivelEdit.mount(params?._id!) },
    ],
  };

  return (
    <PageTemplate>
      <React.Fragment>
        <PageWrapper>
          <Breadcrumb items={configPage.breadcrumb} />
          <TitleDescription
            renderCustomIcon={
              <ContainerIcon>
                <JoystickIconSVG />
              </ContainerIcon>
            }
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>
        {getGameficationNivel.loading ? (
          <LoadingIndicator />
        ) : (
          <React.Fragment>
            {getGameficationNivel?.result && <FormGameficationNivel initialValues={getGameficationNivel.result} />}
          </React.Fragment>
        )}
      </React.Fragment>
    </PageTemplate>
  );
};
