import React, { useMemo } from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { PageWrapper } from './styles';
import { Breadcrumb, TitleDescription, useCurrentUser } from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { useUserChallenges } from '../hook/useUserChallenges';
import { UserChallengeCard } from './UserChallengeCard';
import { RenderComponentAsync } from '../../../components/Layout/RenderComponentAsync';
import styled from 'styled-components';
import moment from 'moment';
import { JoystickIconSVG } from '@digi-tim-19/components';
import { orderBy } from 'lodash';

const bgContainerChallenge = ({ type }: any) => {
  const defaultBg = 'linear-gradient(120deg, rgba(1, 40, 185, 1) 5%, rgba(35, 30, 94, 1) 100%)';

  if (type === 'pending') return 'linear-gradient(180deg, rgba(13,38,119,1) 24%, rgba(8,1,56,1) 100%)';

  if (type === 'completed') return 'linear-gradient(180deg, rgba(5,107,234,1) 24%, rgba(10,73,246,1) 100%)';

  if (type === 'not_completed') return 'linear-gradient(180deg, rgba(203,203,203,1) 24%, rgba(138,138,138,1) 100%)';

  if (type === 'in_progress') return defaultBg;

  return defaultBg;
};

const ContainerChallenge = styled.div<{ type: string }>`
  width: 100%;
  overflow-x: scroll;
  padding: 10px 0;
  height: 360px;

  .card-challenge {
    background-image: ${bgContainerChallenge};
  }
`;

const SectionDescription = styled.div`
  width: 100%;
  margin-top: 15px;
  text-align: center;
  border-radius: 10px;
  padding: 4px 10px;
  color: white;
  font-weight: bold;
  background-color: ${(props) => props.theme.blue};
`;

const ContainerIcon = styled.div`
  width: 70px;
  height: 70px;

  svg {
    width: 70px;
    height: 70px;
  }

  svg g {
    fill: ${(props) => props.theme.blue} !important;
  }

  @media (max-width: 630px) {
    width: 40px;
    height: 40px;

    svg {
      width: 50px;
      height: 50px;
    }
  }
`;

export const Gamefication = () => {
  const user = useCurrentUser();

  const { loading, parsed, refetch } = useUserChallenges({
    userId: user.result?._id,
  });

  const expired = useUserChallenges({
    userId: user.result?._id,
    status: 'expired',
  });

  const configPage = {
    pageName: 'Desafios',
    description: 'Veja suas atividades',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gamificação', link: routes.gamefication.mount() },
      { title: 'Área de atividades', link: routes.gamefication.mount() },
    ],
  };

  const statusFilter = new Set(['in_progress', 'awaiting_verification']);

  const availables = useMemo(
    () =>
      parsed.filter(
        (c) =>
          c.status === 'pending' &&
          !moment(c.startDate).isAfter() &&
          (!c.expires || moment(c.endDate).endOf('day').isAfter(moment().endOf('day'))),
      ),
    [JSON.stringify(parsed)],
  );

  const inProgress = useMemo(
    () => parsed.filter((c) => statusFilter.has(c.status as string) && !moment(c.startDate).isAfter()),
    [JSON.stringify(parsed)],
  );

  const complete = useMemo(() => parsed.filter((c) => c.status === 'completed'), [JSON.stringify(parsed)]);

  const notComplete = useMemo(() => parsed.filter((c) => c.status === 'not_completed'), [JSON.stringify(parsed)]);

  const availablesOrderBy = (data: any) => {
    const expiredList = orderBy(
      data.filter((el: any) => el.expires && el.endDate),
      'endDate',
      'asc',
    );
    const list = orderBy(
      data.filter((el: any) => !el?.expires),
      'createdAt',
      'desc',
    );
    return [...expiredList, ...list];
  };

  const inProgressOrderBy = (data: any) => {
    const expiredList = orderBy(
      data.filter((el: any) => el.expires && el.endDate),
      'endDate',
      'desc',
    );
    const list = orderBy(
      data.filter((el: any) => !el.expires),
      'createdAt',
      'asc',
    );
    return [...expiredList, ...list];
  };

  return (
    <PageTemplate>
      <PageWrapper>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription
          renderCustomIcon={
            <ContainerIcon>
              <JoystickIconSVG />
            </ContainerIcon>
          }
          title={configPage.pageName}
          description={configPage.description}
        />
        <RenderComponentAsync loading={!parsed || loading}>
          <SectionDescription>Disponíveis</SectionDescription>
          <ContainerChallenge type="pending">
            <div style={{ width: '100%', display: 'flex', marginTop: '25px' }}>
              {availablesOrderBy(availables).map((el: any) => (
                <div style={{ width: '250px' }}>
                  <UserChallengeCard challenge={{ ...el }} refetch={refetch} />
                </div>
              ))}
            </div>
          </ContainerChallenge>
          <SectionDescription>Em Andamento</SectionDescription>
          <ContainerChallenge type="in_progress">
            <div style={{ width: '100%', display: 'flex', marginTop: '25px' }}>
              {inProgressOrderBy(inProgress).map((el: any) => (
                <div style={{ width: '250px' }}>
                  <UserChallengeCard challenge={el} refetch={refetch} />
                </div>
              ))}
            </div>
          </ContainerChallenge>
          <SectionDescription>Concluídos</SectionDescription>
          <ContainerChallenge type="completed">
            <div style={{ width: '100%', display: 'flex', marginTop: '25px' }}>
              {orderBy([...complete], 'updatedAt', 'desc').map((el: any) => (
                <div style={{ width: '250px' }}>
                  <UserChallengeCard challenge={{ ...el, status: 'completed' }} refetch={refetch} />
                </div>
              ))}
            </div>
          </ContainerChallenge>
          <SectionDescription>Expirados / Não concluídos</SectionDescription>
          <ContainerChallenge type="not_completed">
            <div style={{ width: '100%', display: 'flex', marginTop: '25px' }}>
              {orderBy([...expired.parsed, ...notComplete], 'updatedAt', 'desc').map((el: any) => (
                <div style={{ width: '250px' }}>
                  <UserChallengeCard challenge={el} refetch={refetch} />
                </div>
              ))}
            </div>
          </ContainerChallenge>
        </RenderComponentAsync>
      </PageWrapper>
    </PageTemplate>
  );
};
