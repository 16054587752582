import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Breadcrumb, TitleDescription, Select, LoadingIndicator, useCurrentUser } from '@digi-tim-19/components';
import { EnumIncentiveCampaignStatus, IncentiveCampaign } from '../../autogenerated/client/types';

import { PageTemplate } from '../../components/Layout/PageTemplate';
import { routes } from '../../config/routes';
import { PaginationWrapper } from '../../components/Page/PaginationWrapper';
import { Pagination, Input } from 'antd';
import { useIncentivePagination } from './useIncentivePagination';
import { IncentiveCampaignCard } from './Components/IncentiveCampaignCard';

const { Search: AntdSearch } = Input;

const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

const CampanhaIncentivoSortWrapper = styled.div`
  display: flex;
  max-width: 400px;
  margin-bottom: 15px;

  @media (max-width: 1125px) {
    max-width: 100%;
  }
`;

const NothingFound = styled.h1`
  width: 100%;
  text-align: center;
  color: #6c757d;
  font-size: 18px;
`;

const IncentiveCampaignContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const configPage = {
  pageName: 'Campanhas de Incentivo',
  icon: 'trophy',
  description:
    'Tenha acesso a todas as informações sobre as Campanhas de Incentivos vigente e encerradas, além de poder conferir seu extrato de TIM Coins e trocar por diversos produtos.',
  breadcrumb: [{ title: 'Home', link: routes.home.mount() }, { title: 'Campanhas de Incentivo' }],
  perPage: 25,
};

export const CampanhaIncentivo = () => {
  const user = useCurrentUser();
  const history = useHistory();
  const [filterTitle, setFilterTitle] = useState<string>('');
  const [filterStatus, setFilterStatus] = useState(EnumIncentiveCampaignStatus.InProgress);
  const { count, loading, parsed, pagination } = useIncentivePagination({ status: filterStatus, title: filterTitle });

  const onClickCard = (item: IncentiveCampaign) => {
    let route = '';
    if (item.status?.value === EnumIncentiveCampaignStatus.InProgress && item.pendingAccept && !user.isAdmin) {
      route = routes.campanhasIncentivoVigenteRegulamento.mount(`${item._id}`);
    } else {
      route = routes.campanhasIncentivoVigente.mount(`${item._id}`);
    }

    history.push(route);
  };

  const onSearch = useCallback(
    (e) => {
      setFilterTitle(e.target.value);
    },
    [JSON.stringify(parsed)],
  );

  return (
    <PageTemplate>
      <>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription iconType={configPage.icon} title={configPage.pageName} description={configPage.description} />
        <CampanhaIncentivoSortWrapper>
          <Select
            value={filterStatus}
            placeholder="FILTRAR POR:"
            options={statusOptions}
            onChange={(e) => setFilterStatus(e)}
          />
          <Search placeholder="Busca título" style={{ width: 200 }} value={filterTitle} onChange={onSearch} />
          {/* <RenderButtonModeView isGestor={isGestor} viewGestor={viewGestor} setViewGestor={setViewGestor} /> */}
        </CampanhaIncentivoSortWrapper>
        {loading && <LoadingIndicator />}
        {count === 0 && <NothingFound>Não temos campanhas no momento</NothingFound>}
        {count > 0 && (
          <IncentiveCampaignContainer>
            {parsed.map((el) => (
              <IncentiveCampaignCard key={`incentive-${el._id}`} campaign={el} onClickCard={() => onClickCard(el)} />
            ))}
          </IncentiveCampaignContainer>
        )}
        <PaginationWrapper>
          <Pagination {...pagination} />
        </PaginationWrapper>
      </>
    </PageTemplate>
  );
};

const statusOptions = [
  { label: 'RESULTADOS', value: EnumIncentiveCampaignStatus.WithResults },
  { label: 'ANDAMENTO', value: EnumIncentiveCampaignStatus.InProgress },
  { label: 'APURAÇÃO', value: EnumIncentiveCampaignStatus.InVerification },
  { label: 'ENCERRADAS', value: EnumIncentiveCampaignStatus.Closed },
  {
    label: 'ENCERRADA/SEM GANHADORES',
    value: EnumIncentiveCampaignStatus.ClosedWithoutWinners,
  },
  {
    label: 'ENCERRADA/PARCEIRO',
    value: EnumIncentiveCampaignStatus.ClosedPartner,
  },
  { label: 'TODAS', value: undefined },
];
