import React, { useMemo } from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { TabelaPrecoWrapper } from '../Cadastrar/stylesCadastrar';
import { useRouteMatch } from 'react-router';
import { useMaterial } from '../../../hooks/materials/useMaterial';
import { findCorrectName } from '../../../utils/productNames';

export const MapaPDVEditar = () => {
  const { params } = useRouteMatch();
  const appendToFragment = `
    title
    fileIds
    files {
      _id
      title
      kind
    }
    validity {
      start,
      end,
      notifyEmailsBeforeExpire
    }
    categories {
      _id
      name
      parentId
    }
    description
    availableAtRegions
    availableAtChannels
    availableAtRoleGroups
`;
  const tabelaItem = useMaterial(params?.id, appendToFragment);

  //Acha o nome da categoria corretamente
  const category = useMemo(() => params.category ?? '', [params]);

  const categoryName = useMemo(() => {
    if (category) return findCorrectName(category);

    return '';
  }, [category]);

  //Acha o nome do produto
  const product = useMemo(() => params.product ?? '', [params]);

  const productName = useMemo(() => {
    if (product) return findCorrectName(product);

    return '';
  }, [product]);

  return (
    <PageTemplate>
      <>
        <TabelaPrecoWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: categoryName },
              {
                title: productName,
                link: routes.materiaisPDVListar.mount(
                  params.category ?? '',
                  params.product ?? ''
                )
              },
              { title: 'Editar' }
            ]}
          />
          <TitleDescription
            iconType="more"
            title={productName}
            description="Editar os documentos específicos da sua regional."
          />
        </TabelaPrecoWrapper>

        {tabelaItem.result && (
          <ProviderSelecionarPublico>
            <Form initialValues={tabelaItem.result} />
          </ProviderSelecionarPublico>
        )}
      </>
    </PageTemplate>
  );
};
