import React from 'react';
import { TableActions } from '@digi-tim-19/components';
import { changedByColumn } from '../../../components/utils/changedByColumn';

export const columns = [
  {
    title: 'Notificações',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>
  },
  // {
  //   title: 'Solicitante',
  //   dataIndex: 'user',
  //   key: 'user',
  //   render: (text: string) => <p>{text}</p>
  // },
  {
    title: 'Cadastrada',
    dataIndex: 'cadastrada',
    key: 'cadastrada'
  },
  // {
  //   title: 'Categoria do menu',
  //   dataIndex: 'categoryName',
  //   key: 'categoryName'
  // },
  {
    title: 'Publicada',
    dataIndex: 'publicada',
    key: 'publicada'
  },
  // {
  //   title: 'status',
  //   dataIndex: 'status',
  //   key: 'status'
  // },
  {
    title: 'Criador',
    dataIndex: 'criador',
    key: 'criador'
  },
  changedByColumn(),
  {
    title: 'Ações',
    key: 'action',
    render: (text: DataKey, record: any) => <TableActions data={record} />
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
  status: string;
}
