import React from 'react';
import { TableActions } from '@digi-tim-19/components';
import { changedByColumn } from '../../../components/utils/changedByColumn';

export const columns = [
  {
    title: 'Processos',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Cadastrada',
    dataIndex: 'cadastrada',
    key: 'cadastrada'
  },
  {
    title: 'Publicada',
    dataIndex: 'publicada',
    key: 'publicada'
  },
  {
    title: 'Atualizada',
    dataIndex: 'atualizada',
    key: 'atualizada'
  },
  {
    title: 'Categoria',
    dataIndex: 'categoria',
    key: 'categoria'
  },
  {
    title: 'Criador',
    dataIndex: 'criador',
    key: 'criador'
  },
  changedByColumn(),
  {
    title: 'Action',
    key: 'action',
    render: (text: DataKey, record: any) => <TableActions data={record} />
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
  status: string;
}
