import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription,
  ProviderXlsxJson
} from '@digi-tim-19/components';

import { IncentiveCampaignWrapper } from './styles';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { Form } from '../FormManager/FormManage';
import { routes } from '../../../config/routes';

export const CampanhaIncentivoCadastrar = () => {
  return (
    <PageTemplate>
      <IncentiveCampaignWrapper>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo' },
            { title: 'Campanhas de Incentivo' },
            { title: 'Listar', link: routes.campanhasIncentivoListar.mount() },
            { title: 'Cadastrar' }
          ]}
        />
        <TitleDescription
          iconType="trophy"
          title="Campanhas e resultados"
          description={'Cadastramento de campanhas de incentivo e resultados.'}
        />

        <ProviderSelecionarPublico>
          <ProviderXlsxJson>
            <Form />
          </ProviderXlsxJson>
        </ProviderSelecionarPublico>
      </IncentiveCampaignWrapper>
    </PageTemplate>
  );
};
