import React from 'react';
import { FilterAuthor } from './FilterAuthor';
import { useCurrentUser } from '@digi-tim-19/components';

export function useFilterAuthor() {
  const [channel, setChannel] = React.useState('');
  const [region, setRegion] = React.useState('');
  const currentUser = useCurrentUser();

  const isSuperAdmin = currentUser.isAdmin;
  const viewOtherUsersContent = !!currentUser?.result?.viewOtherUsersContent;

  const render = () => {
    if (!isSuperAdmin) return null;

    return (
      <FilterAuthor
        channel={channel}
        region={region}
        setChannel={setChannel}
        setRegion={setRegion}
      />
    );
  };

  return {
    ownerFilter:
      isSuperAdmin || viewOtherUsersContent
        ? undefined
        : currentUser.result?._id,
    filter: {
      channel,
      region
    },
    reset: function () {
      setChannel('');
      setRegion('');
    },
    render,
    signature: channel + region
  };
}
