import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { DatePicker, message } from 'antd';
import { useHistory } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select,
  LoadingIndicator
} from '@digi-tim-19/components';
import { formatDate } from '@digi-tim-19/utils/build';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { useMaterialCategoriesSelect } from '../../../hooks/useMaterialCategories';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import {
  EnumMaterialKind,
  SortFindManyMaterialInput,
  ValidityEnum
} from '../../../autogenerated/client/types';

import {
  OnTubeListWrapper,
  FilterContainer,
  Button,
  Search,
  CleanButton
} from './stylesListar';
import { columns } from './columns';
import { EnumMaterialStatus } from '@digi-tim-19/components/build/main/DataProviders/types';
import { useFilterAuthor } from '../../../components/FilterAuthor/useFilterAuthor';
import ModalVideoDestaques from './ModalVideoDestaques';
import { escapeStringRegexp } from '../../../utils/formatters';

export const OnTubeListar = () => {
  const filterAuthor = useFilterAuthor();
  const [createdAt, setCreatedAt] = useState<{ start: string; end: string }>();
  const [updatedAt, setUpdatedAt] = useState<{ start: string; end: string }>();
  const [range, setRange] = useState([]);
  const [publishedAt, setPublishedAt] = useState<{
    start: string;
    end: string;
  }>();
  const [show, setShow] = useState([]);
  const [showUpdatedAt, setShowUpdatedAt] = useState([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [filterSearch, setFilterSearch] = useState();

  const [filterStatus, setFilterStatus] = useState('STATUS');
  const [isLiveOption, setIsLiveOption] = useState();
  const [filterCategory, setFilterCategory] = useState();
  const [sort, setSort] = useState(SortFindManyMaterialInput.IdDesc);
  const categoriesOptions: any = useMaterialCategoriesSelect('video_playlist');

  const [items, setItems] = useState<any>([]);
  const [updateTable, setUpdateTable] = useState('');
  const [page, setPage] = useState(1);
  const perPage = 10;

  const [published, setPublished] = useState<EnumMaterialStatus>();
  const [filterStatusFuture, setFilterStatusFuture] = useState<ValidityEnum>();

  const resetFilters = () => {
    setSort(SortFindManyMaterialInput.IdDesc);
    setFilterCategory(undefined);
    setFilterStatus('STATUS');
    setFilterSearch(undefined);
    setCreatedAt(undefined);
    setUpdatedAt(undefined);
    setPublishedAt(undefined);
    setRange([]);
    setFilterData([]);
    setShow([]);
    setShowUpdatedAt([]);
    setPage(1);
    setPublished(undefined);
    setFilterStatusFuture(undefined);
    setIsLiveOption(undefined);
    filterAuthor.reset();
  };

  const history = useHistory();
  const { RangePicker } = DatePicker;

  const MaterialDeleteOne = useClient('MaterialRemoveOne');

  const getMaterials = useClient('MaterialPagination');

  const total = getMaterials.result?.pageInfo.itemCount;

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            kind: EnumMaterialKind.Video,
            inCategories: filterCategory,
            createdAtRange: createdAt,
            updatedAtRange: updatedAt,
            publishedAtRange: publishedAt,
            includeDrafts: true,
            validityEnum: filterStatusFuture || ValidityEnum.Any,
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
            isLive:
              isLiveOption === undefined
                ? undefined
                : isLiveOption === 'live'
                ? true
                : false,
            status: published,
            filterAuthor: filterAuthor.filter,
            owner: filterAuthor.ownerFilter
          },
          sort
        }
      })
      .then((response: any) => {
        setItems(response && response.result ? response.result.items : []);
      });
  }, [
    sort,
    filterCategory,
    createdAt,
    updatedAt,
    publishedAt,
    page,
    updateTable,
    filterSearch,
    published,
    filterStatusFuture,
    filterAuthor.signature,
    isLiveOption
  ]); //eslint-disable-line

  const data = items?.map((material: any) => {
    let status = ' - ';
    const validityStart = material?.validity?.start
      ? moment(material?.validity?.start).subtract(3, 'hours')?.toISOString()
      : moment(material?.createdAt);

    if (validityStart) {
      const dateNow = moment(new Date()).subtract(3, 'hours').toISOString();
      const isBefore = moment(validityStart).isBefore(dateNow);

      status = isBefore ? 'Publicado' : 'Agendado';
    }

    return {
      changedBy: material?.changedBy,
      key: material?._id,
      id: material?.numericId,
      validityStart: validityStart,
      title: material?.title,
      cadastrada: formatDate(material?.createdAt, 'DD/MM/YYYY'),
      atualizada: formatDate(material?.updatedAt, 'DD/MM/YYYY'),
      criador: material?.author?.name,
      isLive: !!material?.isLive ? 'Sim' : 'Não',
      publicada: material.validity?.start
        ? formatDate(material.validity?.start, 'DD/MM/YYYY HH:mm')
        : formatDate(material?.createdAt, 'DD/MM/YYYY HH:mm'),
      status: status,
      onRead: () => {
        history.push(routes.OnTubePlayer.mount(material?._id));
      },
      onEdit: () => history.push(routes.OnTubeEditar.mount(material?._id)),
      onDelete: () =>
        MaterialDeleteOne.fetch({
          variables: { filter: { _id: material?._id } },
          afterMutate: /^Material/
        }).then((ctx) => {
          message.success('Vídeo deletado com sucesso');
          setUpdateTable(ctx.result?.recordId);
        })
    };
  });

  const filterStatusVideo = (status: string) => {
    switch (status) {
      case 'publicado':
        setPublished(EnumMaterialStatus.Published);
        setFilterStatus('PUBLICADO');
        setFilterStatusFuture(undefined);
        break;
      case 'agendado':
        setFilterStatusFuture(ValidityEnum.Future);
        setFilterStatus('AGENDADO');
        setPublished(undefined);
      default:
        break;
    }
  };

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
      // const byId = data?.filter(material => String(material?.id).includes(e))
    },
    [data]
  );

  const onChange = (e: any) => {
    if (e.length > 0) {
      setCreatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setShow(e);
    } else {
      setShow([]);
      setCreatedAt(undefined);
    }
  };

  const onChangeUpdatedAt = (e: any) => {
    if (e.length > 0) {
      setUpdatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setShowUpdatedAt(e);
    } else {
      setShowUpdatedAt([]);
      setUpdatedAt(undefined);
    }
  };

  const onChangePublished = (e: any) => {
    if (e.length > 0) {
      setPublishedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setRange(e);
    } else {
      setPublishedAt(undefined);
      setRange([]);
    }
  };

  return (
    <PageTemplate>
      <OnTubeListWrapper>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo', link: '' },
            { title: 'Ontube', link: '' },
            { title: 'Listar', link: '' }
          ]}
        />
        <TitleDescription
          iconType="play-square"
          title="OnTube"
          description="Listar e consultar vídeos cadastrados"
        />

        <FilterContainer>
          <Button to={routes.OnTubeCadastrar.mount()}>CADASTRAR VÍDEO</Button>
          <Select
            placeholder="Ordenar por:"
            options={order}
            onChange={(e) => {
              setPage(1);
              setSort(e);
            }}
          />
          <Select
            placeholder="categoria do menu"
            options={categoriesOptions}
            value={filterCategory}
            onChange={(e) => {
              setPage(1);
              setFilterCategory(e);
            }}
          />
          <Select
            placeholder="status"
            options={status}
            value={filterStatus}
            onChange={(e) => {
              setPage(1);
              filterStatusVideo(e);
            }}
          />
          <Select
            placeholder="Live"
            options={liveOptions}
            value={isLiveOption}
            onChange={(e) => {
              setPage(1);
              setIsLiveOption(e);
            }}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format="DD/MM/YYYY"
            placeholder={['CADASTRO', '']}
            onChange={(e) => {
              setPage(1);
              onChange(e);
            }}
            value={show}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={dateFormatList}
            placeholder={['PUBLICAÇÃO', '']}
            onChange={(e) => {
              setPage(1);
              onChangePublished(e);
            }}
            value={range}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={'DD/MM/YYYY'}
            placeholder={['ATUALIZAÇÃO', '']}
            onChange={(e) => {
              setPage(1);
              onChangeUpdatedAt(e);
            }}
            value={showUpdatedAt}
          />
          <Search
            placeholder="Busca título"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e) => {
              setPage(1);
              onSearch(e.target.value);
            }}
          />

          {filterAuthor.render()}

          <ModalVideoDestaques />

          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>
        {getMaterials.loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e) => setPage(e)
            }}
          />
        )}
      </OnTubeListWrapper>
    </PageTemplate>
  );
};

const order = [
  { label: 'MAIS ANTIGO', value: SortFindManyMaterialInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyMaterialInput.IdDesc }
];
const status = [
  { label: 'PUBLICADO', value: 'publicado' },
  { label: 'AGENDADO', value: 'agendado' }
];
const liveOptions = [
  { label: 'SIM', value: 'live' },
  { label: 'NÃO', value: 'notLive' }
];
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    changedBy {
      name
      dateText
    },
    _id
    pinned
    title
    numericId
    createdAt
    updatedAt
    fileIds
    validityEnum
    isLive
    files {
      _id
      extension
      kind
      signedUrl
      downloadUrl
    }
    categories {
      _id
      name
    }
    validity { 
      start 
      end 
    } 
    author { 
      _id, 
      name 
    }
  }
`;
