import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  SocialFeed,
  CardSocialFeedProps,
  TypeContentSocialFeed,
  MaterialProvider,
  ShareModal,
  ProviderSelecionarPublico,
  useCurrentUser
} from '@digi-tim-19/components';
import { useClient } from '../../autogenerated/client/client';
import {
  EnumMaterialKind,
  EnumFileKind,
  EnumMaterialStatus,
  SortFindManyMaterialInput
} from '../../autogenerated/client/types';
import { formatDate } from '@digi-tim-19/utils/build';
import { message } from 'antd';

export const MainSocialFeed = () => {
  const getMaterialPagination = useClient('MaterialPagination');
  const getMaterialPinned = useClient('MaterialFindPinned');
  const createMaterial = useClient('MaterialCreateOne');
  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const MaterialDeleteOne = useClient('MaterialRemoveOne');
  const MaterialLikeToggle = useClient('MaterialLikeToggle');
  const PinCreateOne = useClient('PinCreateOne');
  const PinRemoveOne = useClient('PinRemoveOne');

  const user = useCurrentUser().result;

  const [items, setItems] = useState<CardSocialFeedProps[]>([]);
  const [publish, setPublish] = useState(false);
  const [filterPinned, setFilterPinned] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [resultMaterial, setResultMaterial] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [isOpenOneTime, setIsOpenOneTime] = useState(false);

  const [isTrade, setIsTrade] = useState(false);

  const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    description
    publishedAt
    pinned
    likes {
      liked
    }
    files {
      _id
      kind
      signedUrl
      downloadUrl
    }
    availableAtRegions
    availableAtChannels
    availableAtRoleGroups
  }
  `;

  const configFetch = {
    appendToFragment,
    variables: {
      filter: {
        kind: EnumMaterialKind.SocialFeed
      },
      sort: SortFindManyMaterialInput.CreatedatDesc,
      perPage: 50,
      page: 1
    }
  };

  useEffect(() => {
    if (isOpenOneTime) {
      setIsLoading(true);
      if (!filterPinned) {
        getMaterialPagination.fetch(configFetch).then(setThen);
      } else {
        getMaterialPinned.fetch(configFetch).then(setThen);
      }
    }

    function setThen(ctx: any) {
      if (ctx.result && ctx.result.items) {
        setResultMaterial(ctx.result.items);
      }
      setIsLoading(false);
    }
  }, [publish, filterPinned, isOpenOneTime]);

  useEffect(() => {
    const itemsResult = resultMaterial.map((item: any) =>
      mapResultMaterial(item)
    );

    setItems(itemsResult);
  }, [resultMaterial, isTrade]);

  useMemo(() => {
    const trade = user?.channels?.filter(
      (channel: any) => channel.toUpperCase() === 'TRADE'
    );

    setIsTrade(!!trade);
  }, [user]);

  const onSearchCallback = useCallback(
    (e) => {
      setFilterData(
        items?.filter((material) =>
          material.description.toLowerCase().includes(e.toLowerCase())
        )
      );
    },
    [items]
  );

  const configSocialFeed = useMemo(() => {
    return {
      urlImgIcon: '/defaultImages/icon-socialFeed.png',
      isLoading: isLoading,
      items: filterData.length > 0 ? filterData : items,
      onSearch: onSearchCallback,
      toggle: (isOpen: boolean) => {
        if (!isOpenOneTime && isOpen) {
          setIsOpenOneTime(true);
        }
      },
      onClickPinFilter: () => {
        setFilterPinned(!filterPinned);
      },
      onClickPublish: async (
        idFile: string,
        legend: string,
        publicSelected: any,
        _idEdit?: string
      ) => {
        const title = legend.length > 15 ? legend.substr(0, 15) : legend;

        const record = {
          availableAtRegions: publicSelected.availableAtRegions,
          availableAtChannels: publicSelected.availableAtChannels,
          availableAtRoleGroups: publicSelected.availableAtRoleGroups,
          kind: EnumMaterialKind.SocialFeed,
          status: EnumMaterialStatus.Approved,
          validity: {
            start: new Date().toISOString()
          },
          fileIds: [idFile].filter(Boolean),
          title: `Post ${title}`,
          description: legend
        };

        if (_idEdit) {
          await MaterialUpdateOne.fetch({
            variables: { record, filter: { _id: _idEdit } },
            afterMutate: /^Material/
          }).then((ctx) => {
            if (ctx.errors) {
              message.warn('Erro ao atualizar post');
            }
          });
        } else {
          await createMaterial
            .fetch({ variables: { record }, afterMutate: /^Material/ })
            .then((ctx) => {
              if (ctx?.result) {
                setPublish(!publish);
              } else {
                message.warn('Erro ao publicar post');
              }
            });
        }
      }
    };
  }, [items, filterData, isLoading]);

  //console.log('arrItemsPinned', arrItemsPinned);

  return (
    <ProviderSelecionarPublico>
      <SocialFeed
        styleForDesktop={{
          top: '-570px',
          right: '-54px'
        }}
        {...configSocialFeed}
      />
    </ProviderSelecionarPublico>
  );

  function mapResultMaterial(item?: any): CardSocialFeedProps {
    const fileSocial = item?.files?.find(
      (file: any) =>
        file?.kind === EnumFileKind.SocialFeedImage ||
        file?.kind === EnumFileKind.SocialFeedVideo
    );

    const initialValues = {
      _id: item?._id || '',
      idFile: fileSocial?._id || '',
      legend: item?.description || '',
      selectedPublic: {
        availableAtRegions: item?.availableAtRegions,
        availableAtChannels: item?.availableAtChannels,
        availableAtRoleGroups: item?.availableAtRoleGroups
      }
    };

    return {
      _id: item?._id,
      platform: 'consumer',
      urlImageUser: '/defaultImages/icon-socialFeed.png',
      description: item?.description || '',
      publishedAt: formatDate(item?.publishedAt, 'DD/MM/YYYY'),
      urlContent: fileSocial?.signedUrl || '',
      typeContent:
        fileSocial?.kind === EnumFileKind.SocialFeedImage
          ? TypeContentSocialFeed.IMAGE
          : TypeContentSocialFeed.VIDEO,
      pinned: item.pinned,
      liked: item.likes.liked,
      canEdit: isTrade,
      initialValues,
      onClickDelete: (_id: any) => {
        MaterialDeleteOne.fetch({
          variables: { filter: { _id: _id } },
          afterMutate: /^Material/
        }).then((ctx) => {
          if (ctx.result) {
            message.success('Post excluido com sucesso');
            setPublish(!publish);
          }
        });
      },
      onClickLike: (_id: string) => {
        MaterialLikeToggle.fetch({
          variables: { recordId: _id }
        }).then((ctx) => {
          if (ctx.errors) message.warn('Erro ao curtir post');
        });
      },
      renderShareModal: () => {
        return (
          <MaterialProvider material={item as any}>
            <ShareModal
              style={{
                height: 'auto',
                width: 'auto'
              }}
            />
          </MaterialProvider>
        );
      },
      onClickPin: (_id: any, isPinned: boolean) => {
        if (isPinned) {
          PinCreateOne.fetch({ variables: { record: { recordId: _id } } }).then(
            (ctx) => {
              if (ctx.errors) {
                message.warn('Erro ao favoritar post');
              } else {
                setPublish(!publish);
              }
            }
          );
        } else {
          PinRemoveOne.fetch({ variables: { filter: { recordId: _id } } }).then(
            (ctx) => {
              if (ctx.errors) {
                message.warn('Erro ao desfavoritar post');
              } else {
                setPublish(!publish);
              }
            }
          );
        }
      }
    };
  }
};
