import {
  createStore as _createStore,
  applyMiddleware,
  compose,
  Middleware
} from 'redux';

import thunk from 'redux-thunk';

import { isDev, onBrowser } from '@digi-tim-19/utils';

export default function createStore(data?: any) {
  const middleware: Middleware[] = [thunk];

  let finalCreateStore;

  const devTool: any = onBrowser(() => {
    // @ts-ignore
    return window.__REDUX_DEVTOOLS_EXTENSION__;
  });

  if (devTool) {
    // @ts-ignore
    finalCreateStore = compose(
      applyMiddleware(...middleware),
      devTool()
      // @ts-ignore
    )(_createStore);
  } else {
    finalCreateStore = applyMiddleware(...middleware)(_createStore);
  }

  const reducer = require('./reducer').default;

  const store = finalCreateStore(reducer, data);

  onBrowser(() => {
    // @ts-ignore
    window.appreduxstore = store;
  });

  // @ts-ignore
  if (isDev() && module.hot) {
    // @ts-ignore
    module.hot.accept('./reducer', () => {
      store.replaceReducer(require('./reducer').default);
    });
  }

  return store;
}
