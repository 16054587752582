import styled from 'styled-components';

export const StyledContent = styled.section`
  background: transparent;
  display: flex;
  flex-wrap: wrap;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const OrderWrapper = styled.div`
  margin-bottom: 40px;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const ChooseVisualWrapper = styled.div`
  width: 100%;
  i {
    padding-right: 20px;
  }
`;

export const CardsVisual = styled.div`
  width: 100%;

  @media screen and (min-width: 768px) {
    > div {
      align-items: flex-end;
    }
  }
`;
