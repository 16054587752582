import { useEffect } from 'react';
import { usePagination } from '../../hooks/usePagination';
import {
  EnumIncentiveCampaignStatus,
  SortFindManyIncentiveCampaignInput,
  ValidityEnum,
} from '../../autogenerated/client/types';
import { useCurrentUser } from '@digi-tim-19/components';
import { escapeStringRegexp } from '../../utils/formatters';

export const useIncentivePagination = ({ status, title }: { status: EnumIncentiveCampaignStatus; title: string }) => {
  const user = useCurrentUser();

  const { loading, count, parsed, updateFetch, pagination } = usePagination('IncentiveCampaignPagination', {
    initial: {
      page: 1,
      perPage: 25,
      filter: {
        status,
        validityEnum: ValidityEnum.Any,
        ...(user.isAdmin ? {} : { inAvailableAtRoleGroups: [user.result?.roleGroup?._id as string] }),
      },
      sort: SortFindManyIncentiveCampaignInput.CreatedatDesc,
    },
    itemsFragment: `
            _id
            title
            description
            createdAt
            pinned
            pendingAccept
            availableAtRoleGroups
            participantList
            cardImageFile {
                signedUrl
                downloadUrl
            }
            validity {
                start
                end
            }
            status {
                _id
                label
                value
            }
        `,
    parseResult: (incentiveCampaigns) => incentiveCampaigns,
    fetchOnMount: true,
  });

  useEffect(() => {
    updateFetch((state) => ({
      ...state,
      filter: {
        status,
        title: title === '' ? undefined : `regex(${escapeStringRegexp(title)})`,
        validityEnum: ValidityEnum.Any,
        ...(user.isAdmin || user.result?.roleGroup?._id === 'sac_digi'
          ? {}
          : { inAvailableAtRoleGroups: [user.result?.roleGroup?._id as string] }),
      },
    }));
  }, [status, title]);

  return {
    loading,
    parsed,
    count,
    pagination,
  };
};
