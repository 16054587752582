import styled from 'styled-components';
import { Icon, Progress as AntdProgress } from 'antd';

export const DetailParticipant = styled.div`
  width: 100%;
  min-height: 130px;
  background-color: #014996;
  border-radius: 25px;
  display: flex;
  text-transform: uppercase;
  font-size: 12px;
`;

export const DetailParticipantLeft = styled.div`
  width: 65%;
  background-color: #eeeeee;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const DetailParticipantPositionLeft = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  span {
    color: #93ade7;
    font-weight: bold;
    text-align: center;
  }

  div {
    background-color: #d8d8d8;
    width: 50px;
    height: 50px;
    shape-outside: circle();
    clip-path: circle();
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  div > span {
    color: #ee7c00;
    font-size: 25px;
    font-weight: bold;
  }
`;

export const DetailParticipantPositionRight = styled.div`
  width: 70%;
  height: 100%;
  text-align: center;

  div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  div span:nth-child(2) {
    font-size: 35px;
    color: #014996;
    font-weight: bold;
  }
`;

export const DividerPosition = styled.span`
  width: 1px;
  height: 70%;
  border: 1px dashed #b0b0b0;
  margin: 0 10px 0 10px;
`;

export const DetailParticipantRight = styled.div<{ isNumber: boolean}>`
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 12px;

  span:nth-child(2) {
    font-size: ${(props) => (props.isNumber ? '30px' : '20px')};
    font-weight: bold;
  }

  @media (max-width: 450px) {
    span:nth-child(2) {
    font-size: ${(props) => (props.isNumber ? '25px' : '15px')};
    font-weight: bold;
  }
  }
`;

/* CARD DETAIL */

export const InfoContainerRanking = styled.div`
  margin-top: 15px;
  padding: 16px 22px;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 25px;

  h3 {
    margin: 0;
    font-size: 12pt;
    color: ${(props) => props.theme.blue};
    font-size: 10pt;
  }
`;

export const ContentDetailRanking = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContainerRanking = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1d1d1;
`;

export const ProgressContainerRanking = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 7px;
`;

export const ProgressRanking = styled(AntdProgress)`
  .ant-progress-inner {
    background-color: #e3e3e3;
  }

  .ant-progress-bg {
    background-color: #014996;
  }
`;

export const PercentInfoRanking = styled.span`
  font-size: 10pt;
`;

export const EmojiContainerRanking = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 0 5px 2px #7e7e7e;
  margin-top: -20px;
`;

export const ButtonsCampaignContainer2 = styled.div`
  width: 100%;
  border-radius: 15px;
  border: 1px dashed #b0b0b0;
  display: flex;
  min-height: 60px;
  margin-bottom: 15px;
  margin-top: 32px;
`;

export const ButtonRegulation = styled.div`
  border-radius: 15px;
  background: linear-gradient(234deg, rgba(19, 213, 238, 1) 0%, rgba(3, 90, 161, 1) 100%);
  width: 48%;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;

  span {
    margin-left: 5px;
  }
`;

export const ButtonPeriod = styled.div`
  border-radius: 15px;
  width: 48%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #6a6a6a;
  font-size: 12px;
  font-weight: bold;

  div {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
`;
