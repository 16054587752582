import React from 'react';
import { useCurrentUser, Button, Table } from '@digi-tim-19/components';
import { Icon, Modal as ModalAnt, Tooltip } from 'antd';
import { useHistory } from 'react-router';
import moment from 'moment';
import { routes } from '../../config/routes';
import { useDimensions } from '@digi-tim-19/utils';
import styled from 'styled-components';

const Modal = styled(ModalAnt)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-header {
    background: #00508c;
    border-bottom: none;
    .ant-modal-title {
      color: #fff;
    }
  }
`;

export const ModalAccept: React.FC = () => {
  const history = useHistory();
  const { result: user }: any = useCurrentUser();
  const { isMobile } = useDimensions();

  const acceptHighligh = user?.acceptsPending?.[0];

  return (
    <Modal
      visible={Boolean(acceptHighligh) && !history.location.pathname.includes('regulamento')}
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            {user?.acceptsPending?.length
              ? `Atualmente, você tem ${user?.acceptsPending?.length} Campanhas em vigência para aceite.`
              : `Atualmente, você tem 1 Campanha em vigência para aceite.`}
          </div>
          <div>
            <Tooltip
              placement="top"
              title={`Para sair dessa tela, você precisa conferir a(s) campanha(s) e dar ou não aceite no regulamento.`}
            >
              <Icon type="info-circle" />
            </Tooltip>
          </div>
        </div>
      }
      footer={null}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <h4 style={{ margin: 0 }}>{acceptHighligh?.title}</h4>
        <img src={acceptHighligh?.bannerSignedUrl}></img>
        <h4 style={{ fontWeight: 'bold' }}>Confira o Regulamento agora mesmo!</h4>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px' }}>
          <p style={{ margin: 0 }}>Válido até {moment(acceptHighligh?.validity?.end).format('DD/MM/YYYY')}</p>
          <Button
            key="submit"
            type="primary"
            onClick={() => history.push(routes.campanhasIncentivoVigenteRegulamento.mount(acceptHighligh?._id))}
          >
            Conferir
          </Button>
        </div>

        <div>
          <Table
            columns={[
              {
                title: 'Título',
                dataIndex: 'title',
                key: 'title',
                width: isMobile ? undefined : '330px',
                render: (text: string) => {
                  return (
                    <p style={{ fontWeight: 'bold' }}>
                      {isMobile && text.length > 15 ? `${String(text).substring(0, 15)}...` : text}
                    </p>
                  );
                },
              },
              {
                title: '',
                dataIndex: 'btnAccept',
                key: 'btnAccept',
                render: (_text: string, ctx: any) => (
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => history.push(routes.campanhasIncentivoVigenteRegulamento.mount(ctx?._id))}
                  >
                    Conferir
                  </Button>
                ),
              },
            ]}
            dataSource={user?.acceptsPending?.slice(1, user?.acceptsPending?.length)}
          />
        </div>
      </div>
    </Modal>
  );
};
