import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 20px 15px;
  margin-bottom: 25px;

  .Former__submit-button {
    background: ${(props) => props.theme.green};
    color: ${(props) => props.theme.white};
    border: none;
  }
  .Footer {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    .FieldWrapper:nth-of-type(1) {
      max-width: 210px;
      margin-right: 15px;
    }
    .FieldWrapper:nth-of-type(2) {
      max-width: 210px;
      margin-right: 15px;
      position: relative;
    }
  }
  .FieldWrapper:nth-of-type(1) {
    align-items: center !important;
    justify-content: flex-start;
    flex-wrap: wrap;

    .name-categories {
      margin-left: 15px;
    }
  }
  .name-status {
    margin-left: 15px;
  }
  .name-templateName {
    .ant-radio-group {
      height: auto;
      img {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
        margin-top: 15px;
      }
      p {
        display: none;
      }
    }
    .ant-radio-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .name-documento {
    max-width: 30% !important;
    justify-content: flex-end !important;
    > span {
      display: flex;

      .anticon-loading {
        display: none;
      }
      .ant-upload-list-item-progress {
        display: none;
      }
      .anticon-close {
        right: -2px;
      }
    }
  }
`;
export const Divider = styled.hr`
  border: none;
  background: #ccc;
  height: 1px;
  width: 100%;
  margin: 20px 0;
`;
export const UploadWrapper = styled.div`
  width: 100%;
`;
