import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useContentTypePermissions } from '../../../hooks/useContentTypePermissions';
import { Container, Footer } from '../Create/styles';
import {
  AnalyticsEventsEnum,
  Button,
  Former,
  LoadingIndicator,
  SelecionarPublico,
  usePublico,
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import {
  Challenge,
  EnumAnalyticsEvent,
  EnumAnalyticsParentKind,
  EnumFileKind,
  EnumMaterialKind,
  Material,
  Maybe,
} from '../../../autogenerated/client/types';
import { useDimensions } from '@digi-tim-19/utils';
import { useClient } from '../../../autogenerated/client/client';
import { message, Button as AntdButton, Modal } from 'antd';
import { QuizTextField } from './QuizTextField';
import { QuizAlternativesField } from './QuizAlternatives/QuizAlternativesField';
import moment from 'moment';
import { escapeStringRegexp } from '../../../utils/formatters';

const ImageKind = EnumFileKind.CardThumb;

interface FormGameficationChallengeProps {
  initialValues?: Partial<Challenge>;
}

export enum TypeChallenge {
  video = 'video',
  login = 'login',
  communication = 'communication',
  offer_map = 'offer_map',
  incentive = 'incentive',
  rescue = 'rescue',
  time = 'time',
  share = 'share',
  research = 'research',
  externalLink = 'externalPageView',
  mission = 'mission',
  quiz = 'quiz',
  view = 'view',
}

type Options = { label: string; value: string }[];

export const typesChallenge = [
  { label: 'Vídeo OnTube', value: TypeChallenge.video },
  { label: 'Acesso ao portal', value: TypeChallenge.login },
  { label: 'Informativos', value: TypeChallenge.communication },
  { label: 'Mapa de ofertas', value: TypeChallenge.offer_map },
  { label: 'Compartilhamento de conteúdo', value: TypeChallenge.share },
  { label: 'Campanha de incentivo', value: TypeChallenge.incentive },
  { label: 'Tempo de cadastro no portal', value: TypeChallenge.time },
  { label: 'Pesquisa', value: TypeChallenge.research },
  { label: 'Acesso a link externo', value: TypeChallenge.externalLink },
  { label: 'Missão', value: TypeChallenge.mission },
  { label: 'Quiz', value: TypeChallenge.quiz },
  { label: 'Visualizar Conteúdo', value: TypeChallenge.view },
];

export const FormGameficationChallenge = (props: FormGameficationChallengeProps) => {
  const [values, setValues] = useState(props?.initialValues || {});
  const permissions = useContentTypePermissions();
  const { availableAtRegions, availableAtRoleGroups } = usePublico();
  const { isMobile } = useDimensions();
  const history = useHistory();
  const getMaterialOne = useClient('MaterialFindById');
  const getMaterial = useClient('MaterialFindMany');
  const getIncentiveCampaignOne = useClient('IncentiveCampaignFindById');
  const getMaterials = useClient('MaterialFindMany');
  const getMaterialsViews = useClient('GameficationMaterialSearch');
  const getIncentiveCampaign = useClient('IncentiveCampaignFindMany');
  const createChallenge = useClient('ChallengeCreateOne');
  const updateChallenge = useClient('ChallengeUpdateOne');
  const [initialSelected, setInitialSelected] = useState<any>();
  const [options, setOptions] = useState<any>([]);
  const isUpdate = !!props.initialValues;
  const pushedState = history.location.state as any;
  const isDuplicate = pushedState?.duplicate;

  useEffect(() => {
    if (props?.initialValues?.recordId) {
      if (props.initialValues.typeChallenge === TypeChallenge.incentive) {
        getIncentiveCampaignOne
          .fetch({
            appendToFragment: `_id title`,
            variables: { _id: props?.initialValues?.recordId },
          })
          .then((ctx: any) => {
            if (ctx?.result) {
              setInitialSelected(ctx.result);
            }
          });
      } else {
        getMaterialOne
          .fetch({
            appendToFragment: `_id title`,
            variables: { _id: props?.initialValues?.recordId },
          })
          .then((ctx: any) => {
            if (ctx?.result) {
              setInitialSelected(ctx.result);
            }
          });
      }
    }
  }, [props?.initialValues?.recordId]);

  const generateOptions = (values: any): Options => {
    return (
      values?.map((material: any) => {
        return { label: material.title, value: material._id };
      }) || []
    );
  };

  const getMaterialVideos = async (value: any): Promise<Options> => {
    const { result } = await getMaterials.fetch({
      variables: {
        limit: 100,
        filter: {
          kind: EnumMaterialKind.Video,
          title: value === '' ? undefined : `regex(${escapeStringRegexp(value)})`,
        },
      },
    });

    return generateOptions(
      result?.map((video: any) => {
        return {
          ...video,
          title: `${video?.title} - ${
            moment(video.publishedAt).isValid() ? `(${moment(video.publishedAt).format('DD/MM/YYYY HH:mm')})` : ''
          }`,
        };
      }),
    );
  };

  const getMaterialInformatives = async (value: any): Promise<Options> => {
    const { result } = await getMaterials.fetch({
      variables: {
        limit: 100,
        filter: {
          title: value === '' ? undefined : `regex(${escapeStringRegexp(value)})`,
          kind: EnumMaterialKind.Communication,
          inCategories: ['informativos', 'menu:informativos'],
        },
      },
    });

    return generateOptions(result);
  };

  const getMaterialOffers = async (value: any): Promise<Options> => {
    const { result } = await getMaterials.fetch({
      variables: {
        limit: 100,
        filter: {
          title: value === '' ? undefined : `regex(${escapeStringRegexp(value)})`,
          kind: EnumMaterialKind.OfferMap,
        },
      },
    });

    return generateOptions(result);
  };

  const getMaterialContent = async (value: any): Promise<Options> => {
    const { result } = await getMaterials.fetch({
      variables: {
        limit: 100,
        filter: {
          title: value === '' ? undefined : `regex(${escapeStringRegexp(value)})`,
        },
      },
      fragment: '_id, title',
    });

    return generateOptions(result);
  };

  const getMaterialViewsContent = async (value: any): Promise<Options> => {
    const { result } = await getMaterialsViews.fetch({
      variables: {
        search: value === '' ? undefined : value,
      },
      fragment: '_id, title',
    });

    return generateOptions(result);
  };

  const getIncentiveCampaigns = async (value: any): Promise<Options> => {
    const { result } = await getIncentiveCampaign.fetch({
      variables: {
        limit: 100,
        filter: {
          title: value === '' ? undefined : `regex(${escapeStringRegexp(value)})`,
        },
      },
    });

    return generateOptions(result);
  };

  const getOptionsSearchInput = async (value: any, ypeChallenge?: any): Promise<Options> => {
    switch (ypeChallenge) {
      case TypeChallenge.video:
        return await getMaterialVideos(value);
      case TypeChallenge.communication:
        return await getMaterialInformatives(value);
      case TypeChallenge.offer_map:
        return await getMaterialOffers(value);
      case TypeChallenge.incentive:
        return await getIncentiveCampaigns(value);
      case TypeChallenge.share:
      case TypeChallenge.view:
        return await getMaterialViewsContent(value);
      default:
        return await getMaterialContent(value);
    }
  };

  const getLabelSearchInput = (typeChallenge?: string): string => {
    switch (typeChallenge) {
      case TypeChallenge.video:
        return 'Pesquisar vídeo';
      case TypeChallenge.communication:
        return 'Pesquisar informativo';
      case TypeChallenge.offer_map:
        return 'Pesquisar mapa de ofertas';
      case TypeChallenge.incentive:
        return 'Pesquisar campanha de incentivo';
      case TypeChallenge.rescue:
        return 'Pesquisar produto do catálogo de prêmios';
      case TypeChallenge.share:
      case TypeChallenge.view:
      default:
        return 'Pesquisar conteúdo';
    }
  };

  const getLabelAmountInput = (typeChallenge?: string): string => {
    switch (typeChallenge) {
      case TypeChallenge.video:
      case TypeChallenge.view:
      case TypeChallenge.offer_map:
      case TypeChallenge.communication:
        return 'Quantidade de visualizações';
      case TypeChallenge.login:
        return 'Quantidade de dias';
      case TypeChallenge.share:
        return 'Quantidade de compartilhamentos';
      case TypeChallenge.time:
        return 'Quantidade de dias, meses ou anos';
      default:
        return 'Quantidade';
    }
  };

  const getLabelInput = (typeChallenge?: string): string => {
    switch (typeChallenge) {
      case TypeChallenge.research:
        return 'Título da Pesquisa para Modal';
      case TypeChallenge.quiz:
        return 'Título do Quiz para Modal';
      default:
        return 'Título';
    }
  };

  const validateNumberInput = (value: any) => {
    const format = /^\d+$/;
    if (!format.test(value) || Number(value) === 0) {
      return 'Precisa ser um número maior que zero.';
    }
    return undefined;
  };

  const validateTextInput = (size: number) => (value: any) => {
    if (value) return !(value.length > size) ? undefined : `Máximo de ${size} caracteres`;
    return undefined;
  };

  function generateRange(total: number) {
    let options = [];
    for (let index = 1; index <= total; index++) {
      options.push({ label: index, value: index });
    }
    return options;
  }

  const initialValues = React.useMemo(() => {
    const data: any = { ...values };

    if (data.typeChallenge === TypeChallenge.communication && data.category === 'informativos') {
      data.download = data.action !== 'view' ? true : false;
    }

    if (data.typeChallenge === TypeChallenge.incentive) {
      data.awarded = data.action !== 'accept_term' ? true : false;
    }

    return {
      typePayment: data.typePayment,
      amountPoints: data.amountPoints,
      title: data.title,
      description: data.description,
      thumbnail: data.thumbnail,
      availableAtRegions: data.availableAtRegions || [],
      availableAtRoleGroups: data.availableAtRoleGroups || [],
      availableAtChannels: [],
      expires: data.expires,
      startDate: data.startDate,
      endDate: data.endDate,
      kind: data.kind,
      typeChallenge: data.typeChallenge,
      amountChips: data.amountChips,
      indeterminate: data.indeterminate,
      recordId: data.recordId,
      typeTime: data.typeTime,
      amount: data.amount,
      consecutive: data.consecutive,
      download: data.download,
      awarded: data.awarded,
      titleQuestions: data?.challengeData?.title,
      questions: data?.challengeData?.questions,
      hitsComplete: data?.challengeData?.hitsComplete,
      maxAttempts: data?.challengeData?.maxAttempts,
      externalLink: data?.externalLink,
      redirect: data?.redirect,
      url: data?.url,
      faq: data?.faq,
    };
  }, [values]);

  function infoChangeToPoints() {
    Modal.info({
      title: 'Premiação com TIM Coins',
      content: (
        <div style={{ margin: '20px 0px 0px -38px' }}>
          <p>
            Você alterou a opção de premiação para TIM Coins, cada ponto é equivalente a R$1 no catálogo de prêmios.
          </p>
          <p>
            Quando o usuário concluir este desafio, será necessário fazer a liberação de TIM Coins na opção do menu:{' '}
            {`Gerenciar conteúdo > Gamificação > Liberação (TIM Coins)`}
          </p>
        </div>
      ),
      onOk() {},
    });
  }

  function infoConfirmCreateChallenge(onConfirm: () => void) {
    Modal.confirm({
      title: 'Criar desafio com premiação em TIM Coins?',
      content: (
        <div style={{ margin: '20px 0px 0px -38px' }}>
          <p>
            Você está criando um desafio com a premiação em forma de TIM Coins, cada ponto é equivalente a R$1 no
            catálogo de prêmios.
          </p>
          <p>
            Quando o usuário concluir este desafio, será necessário fazer a liberação de TIM Coins na opção do menu:{' '}
            {`Gerenciar conteúdo > Gamificação > Liberação (TIM Coins)`}
          </p>
          <p>Deseja prosseguir?</p>
        </div>
      ),
      okText: 'SIM, SALVAR',
      cancelText: 'NÃO',
      onCancel() {},
      onOk() {
        onConfirm();
      },
    });
  }

  if (permissions.loading || getIncentiveCampaignOne.loading || getMaterialOne.loading) return <LoadingIndicator />;

  const getObjectId = (str: string) => {
    const checkForMongoId = /[0-9a-fA-F]{24}/;
    const result = str?.match(checkForMongoId) as string[];
    return result && result[0];
  };

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={(renderProps) => {
          const { form } = renderProps;
          const typeChallenge: any = form.getFieldState('typeChallenge')?.value;
          const indeterminate: any = form.getFieldState('indeterminate')?.value;
          const typePayment: any = form.getFieldState('typePayment')?.value;
          const expires: any = form.getFieldState('expires')?.value;
          form.registerField('questions', () => {}, {});

          return {
            fields: [
              {
                list: [
                  {
                    required: true,
                    name: 'typePayment',
                    label: 'Tipo de premiação',
                    type: 'select',
                    defaultValue: initialValues?.typePayment || 'chips',
                    options: [
                      { label: 'Chips', value: 'chips' },
                      { label: 'TIM Coins', value: 'points' },
                    ],
                    extraProps: {
                      onSelect: (value: string) => {
                        if (value === 'points') infoChangeToPoints();
                      },
                    },
                  },
                  typePayment &&
                    typePayment === 'chips' && {
                      name: 'amountChips',
                      label: 'Quantidade de chips',
                      type: 'text',
                      required: true,
                      validate: validateNumberInput,
                    },
                  typePayment &&
                    typePayment === 'points' && {
                      name: 'amountPoints',
                      label: 'Quantidade de TIM Coins',
                      type: 'text',
                      required: true,
                      validate: validateNumberInput,
                    },
                ],
              },
              {
                list: [
                  {
                    name: 'title',
                    label: 'Nome do desafio (Cadastro)',
                    required: true,
                    validate: (value: any) => {
                      if (value) return !(value.length > 70) ? undefined : 'Máximo de 70 caracteres';

                      return undefined;
                    },
                  },
                  {
                    name: 'description',
                    label: 'Nome do desafio (Visão usuário)',
                    type: 'text',
                    required: true,
                    validate: validateTextInput(70),
                  },
                  {
                    name: 'faq',
                    label: 'FAQ',
                    type: 'textarea',
                    required: false,
                    extraProps: {
                      rows: 5,
                      maxLength: 121,
                    },
                    validate: validateTextInput(120),
                  },
                ],
              },
              {
                inline: true,
                list: [
                  {
                    custom: (
                      <SelecionarPublico
                        initialValues={props.initialValues}
                        platform="consumer"
                        {...permissions.filterOptions}
                      />
                    ),
                  },
                ],
              },
              {
                inline: !isMobile,
                list: [
                  {
                    required: false,
                    name: 'thumbnail',
                    label: '',
                    type: 'upload',
                    extraProps: {
                      kind: ImageKind,
                      CTA: 'IMPORTAR THUMB',
                    },
                  },
                  typePayment &&
                    typePayment !== 'points' && {
                      required: true,
                      name: 'expires',
                      label: 'Expiração',
                      type: 'select',
                      defaultValue: initialValues?.expires,
                      options: [
                        { label: 'Não', value: false },
                        { label: 'Sim', value: true },
                      ],
                      maxWidth: isMobile ? '100%' : '40%',
                    },
                  {
                    name: 'startDate',
                    label: 'Data de início',
                    type: 'datePicker',
                    required: true,
                    extraProps: {
                      format: 'DD/MM/YYYY',
                      setStartDay: true,
                      mode: 'startDay',
                      noBeforeDate: !isUpdate || isDuplicate,
                    },
                    maxWidth: isMobile ? '100%' : '40%',
                    enableDatePriorToCurrent: true,
                  },
                  (expires || (typePayment && typePayment === 'points')) && {
                    name: 'endDate',
                    label: 'Data de expiração',
                    type: 'datePicker',
                    required: true,
                    extraProps: {
                      format: 'DD/MM/YYYY',
                      setStartDay: false,
                      mode: 'endDay',
                      noBeforeDate: !isUpdate || isDuplicate,
                    },
                    maxWidth: isMobile ? '100%' : '40%',
                  },
                ],
              },
              {
                inline: !isMobile,
                list: [
                  {
                    inline: true,
                    name: 'typeChallenge',
                    label: 'Ações',
                    type: 'select',
                    required: true,
                    options: typesChallenge,
                    maxWidth: '100%',
                    extraProps: {
                      onSelect: (_: any) => {
                        setInitialSelected(undefined);
                        setOptions([]);
                        form.change('recordId', null);
                      },
                    },
                  },
                ],
              },
              !indeterminate &&
                typeChallenge &&
                [TypeChallenge.incentive].includes(typeChallenge) && {
                  inline: true,
                  list: [
                    {
                      className: 'search-parent-id',
                      name: 'recordId',
                      required: true,
                      label: getLabelSearchInput(typeChallenge),
                      placeholder: getLabelSearchInput(typeChallenge),
                      initialValue: initialSelected?.title,
                      options: options,
                      onFetch: async (value: string): Promise<{ label: string; value: string }[]> => {
                        const data = await getOptionsSearchInput(value, form.getFieldState('typeChallenge')?.value);
                        setOptions([...data]);
                        return data;
                      },
                      onChange: () => {},
                      type: 'select-search',
                      extraProps: {
                        searchSize: 2,
                      },
                    },
                  ],
                },
              !indeterminate &&
                typeChallenge &&
                [
                  TypeChallenge.video,
                  TypeChallenge.communication,
                  TypeChallenge.offer_map,
                  TypeChallenge.share,
                  TypeChallenge.rescue,
                  TypeChallenge.view,
                ].includes(typeChallenge) && {
                  name: 'materialname',
                  custom: (
                    <div style={{ display: 'flex', width: '100%', marginLeft: '10px' }}>
                      {`Conteúdo: ${getMaterial.result?.[0]?.title || getMaterialOne.result?.title || ''}`}
                    </div>
                  ),
                },
              !indeterminate &&
                typeChallenge &&
                [
                  TypeChallenge.video,
                  TypeChallenge.communication,
                  TypeChallenge.offer_map,
                  TypeChallenge.share,
                  TypeChallenge.rescue,
                  TypeChallenge.view,
                ].includes(typeChallenge) && {
                  required: true,
                  name: 'redirect',
                  label: 'Redirecionar?',
                  type: 'select',
                  defaultValue: Boolean(initialValues?.redirect),
                  options: [
                    { label: 'Não', value: false },
                    { label: 'Sim', value: true },
                  ],
                  maxWidth: '100%',
                },

              !indeterminate &&
                typeChallenge &&
                [
                  TypeChallenge.video,
                  TypeChallenge.communication,
                  TypeChallenge.offer_map,
                  TypeChallenge.share,
                  TypeChallenge.rescue,
                  TypeChallenge.view,
                ].includes(typeChallenge) && {
                  name: 'url',
                  label: 'URL',
                  required: true,
                  validate: async (value: any) => {
                    if (value === initialValues.url) return undefined;

                    const checkForMongoId = /[0-9a-fA-F]{24}/;
                    const validUrl = value?.match(checkForMongoId) as string[];

                    if (!validUrl) return 'URL inválido';

                    const materialId = validUrl[0];

                    const material = await getMaterial.fetch({
                      appendToFragment: `_id title`,
                      variables: {
                        filter: {
                          _id: materialId,
                        },
                        limit: 1,
                      },
                    });

                    if (!material?.result?.[0]) return 'URL inválido';

                    const data = material?.result[0];

                    const validateMaterial = (type: TypeChallenge, material: Maybe<Material> | undefined) => {
                      const isOfferMap = material?.kind === EnumMaterialKind.OfferMap;
                      const isCommunication = material?.kindName === 'Informativos';
                      const isVideo = material?.kind === EnumMaterialKind.Video;

                      if (type === TypeChallenge.offer_map) return isOfferMap;

                      if (type === TypeChallenge.communication) return isCommunication;

                      if (type === TypeChallenge.video) return isVideo;

                      if (isOfferMap || isCommunication || isVideo) return false;

                      return true;
                    };

                    const valid = validateMaterial(typeChallenge, data);

                    if (!valid) return 'Não foi possível localizar conteúdo';

                    return undefined;
                  },
                },
              typeChallenge &&
                [
                  TypeChallenge.video,
                  TypeChallenge.communication,
                  TypeChallenge.offer_map,
                  TypeChallenge.share,
                  TypeChallenge.incentive,
                  TypeChallenge.rescue,
                  TypeChallenge.view,
                ].includes(typeChallenge) && {
                  inline: true,
                  list: [
                    {
                      required: true,
                      name: 'indeterminate',
                      label: 'Indeterminado',
                      type: 'select',
                      defaultValue: false,
                      options: [
                        { label: 'Não', value: false },
                        { label: 'Sim', value: true },
                      ],
                    },
                  ],
                },
              typeChallenge &&
                [TypeChallenge.time].includes(typeChallenge) && {
                  inline: true,
                  list: [
                    {
                      inline: true,
                      list: [
                        {
                          required: true,
                          name: 'typeTime',
                          label: 'Tipo',
                          type: 'select',
                          defaultValue: initialValues?.typeTime || 'days',
                          options: [
                            { label: 'Dias', value: 'days' },
                            { label: 'Meses', value: 'months' },
                            { label: 'Anos', value: 'years' },
                          ],
                        },
                      ],
                    },
                  ],
                },
              typeChallenge &&
                [
                  TypeChallenge.video,
                  TypeChallenge.login,
                  TypeChallenge.share,
                  TypeChallenge.rescue,
                  TypeChallenge.time,
                  TypeChallenge.view,
                  TypeChallenge.offer_map,
                  TypeChallenge.communication,
                ].includes(typeChallenge) && {
                  inline: true,
                  list: [
                    {
                      name: 'amount',
                      label: getLabelAmountInput(typeChallenge),
                      type: 'number',
                      required: true,
                      validate: validateNumberInput,
                    },
                  ],
                },
              typeChallenge &&
                [TypeChallenge.login].includes(typeChallenge) && {
                  inline: true,
                  list: [
                    {
                      required: true,
                      name: 'consecutive',
                      label: 'Consecutivos',
                      type: 'select',
                      defaultValue: initialValues?.indeterminate,
                      options: [
                        { label: 'Não', value: false },
                        { label: 'Sim', value: true },
                      ],
                    },
                  ],
                },
              typeChallenge &&
                [TypeChallenge.communication, TypeChallenge.offer_map].includes(typeChallenge) && {
                  inline: true,
                  list: [
                    {
                      name: 'download',
                      label: 'Download',
                      type: 'select',
                      defaultValue: initialValues?.download || false,
                      options: [
                        { label: 'Não', value: false },
                        { label: 'Sim', value: true },
                      ],
                    },
                  ],
                },
              typeChallenge &&
                [TypeChallenge.incentive].includes(typeChallenge) && {
                  inline: true,
                  list: [
                    {
                      name: 'awarded',
                      label: 'Premiado',
                      type: 'select',
                      defaultValue: initialValues?.awarded,
                      options: [
                        { label: 'Não', value: false },
                        { label: 'Sim', value: true },
                      ],
                    },
                  ],
                },
              typeChallenge &&
                [TypeChallenge.research, TypeChallenge.quiz].includes(typeChallenge) && {
                  inline: true,
                  list: [
                    {
                      name: 'titleQuestions',
                      label: getLabelInput(typeChallenge),
                      required: true,
                      validate: (value: any) => {
                        const format = /[#%&;]/;
                        if (format.test(value)) return 'Os caracteres #%&; não são permitidos';

                        if (value) return !(value.length > 70) ? undefined : 'Máximo de 70 caracteres';

                        return undefined;
                      },
                    },
                  ],
                },
              typeChallenge &&
                [TypeChallenge.research].includes(typeChallenge) && {
                  inline: true,
                  custom: (
                    <QuizTextField
                      disabled={isUpdate && !isDuplicate}
                      values={form.getFieldState('questions')?.value}
                      onChange={(data) => {
                        form.change('questions', data);
                      }}
                    />
                  ),
                },
              typeChallenge &&
                [TypeChallenge.quiz].includes(typeChallenge) && {
                  inline: true,
                  custom: (
                    <QuizAlternativesField
                      disabled={isUpdate && !isDuplicate}
                      values={form.getFieldState('questions')?.value}
                      onChange={(data) => {
                        form.change('questions', data);
                      }}
                    />
                  ),
                },
              typeChallenge &&
                [TypeChallenge.quiz].includes(typeChallenge) && {
                  inline: true,
                  list: [
                    {
                      name: 'hitsComplete',
                      label: 'Acertos para concluir',
                      type: 'select',
                      defaultValue: initialValues?.hitsComplete || 1,
                      options: generateRange(form.getFieldState('questions')?.value?.length || 1),
                    },
                    {
                      name: 'maxAttempts',
                      label: 'Máximo de tentativas',
                      type: 'number',
                      required: true,
                      validate: validateNumberInput,
                    },
                  ],
                },
              [TypeChallenge.externalLink].includes(typeChallenge) && {
                inline: true,
                list: [
                  {
                    required: true,
                    name: 'externalLink',
                    label: 'URL',
                    type: 'text',
                    validate(value: string) {
                      const vHttps = new RegExp(/^(https):\/\/[^ "]+$/);

                      if (!vHttps.test(value))
                        return 'Insira um URL seguro utilizando https. Ex: https://seusite.com.br';
                    },
                  },
                ],
              },
            ],
            customRenderFooter: (renderProps: any) => {
              const { form, invalid } = renderProps;
              const typePayment = form.getFieldState('typePayment')?.value;

              const onSubmitForm = () => {
                form.submit();
              };

              return (
                <Footer>
                  <AntdButton onClick={() => history.push(routes.gameficationList.path)}>CANCELAR</AntdButton>
                  <Button
                    loading={updateChallenge.loading || createChallenge.loading}
                    disabled={invalid || updateChallenge.loading || createChallenge.loading}
                    onClick={() => {
                      if (typePayment === 'points') {
                        infoConfirmCreateChallenge(onSubmitForm);
                      } else {
                        onSubmitForm();
                      }
                    }}
                  >
                    {isUpdate && !isDuplicate ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Footer>
              );
            },
          };
        }}
        onSubmit={async ({ data }) => {
          let record: any = {
            typePayment: data.typePayment,
            amountPoints: data?.amountPoints ? Number(data.amountPoints) : 0,
            title: data.title,
            description: data.description,
            thumbnail: data.thumbnail,
            availableAtRegions: availableAtRegions || [],
            availableAtRoleGroups: availableAtRoleGroups || [],
            availableAtChannels: [],
            expires: data.typePayment === 'points' ? false : data.expires,
            startDate: data?.startDate ? moment(data.startDate).startOf('day').toString() : undefined,
            endDate: data?.endDate ? moment(data.endDate).endOf('day').toString() : undefined,
            typeChallenge: data.typeChallenge,
            kind: undefined,
            amountChips: data?.amountChips ? Number(data.amountChips) : 0,
            indeterminate: data.indeterminate,
            recordId: !initialSelected || initialSelected?._id !== data.recordId ? data.recordId : initialSelected._id,
            typeTime: data.typeTime,
            amount: data?.amount ? Number(data.amount) : null,
            consecutive: data.consecutive,
            redirect: data?.redirect,
            url: data.url,
            faq: data?.faq && String(data?.faq).trim() !== '' ? String(data.faq).trim() : null,
          };

          if (data.typeChallenge === TypeChallenge.video) {
            record.action = EnumAnalyticsEvent.View;
            record.kind = TypeChallenge.video;
            record.recordId = getObjectId(data.url);
          }

          if (data.typeChallenge === TypeChallenge.login) {
            record.action = 'login';
            record.kind = undefined;
          }

          if (data.typeChallenge === TypeChallenge.communication) {
            record.action = data?.download ? EnumAnalyticsEvent.Download : EnumAnalyticsEvent.View;
            record.kind = TypeChallenge.communication;
            record.recordId = getObjectId(data.url);
          }

          if (data.typeChallenge === TypeChallenge.offer_map) {
            record.action = record.action = data?.download ? EnumAnalyticsEvent.Download : EnumAnalyticsEvent.View;
            record.kind = EnumAnalyticsParentKind.OfferMap;
            record.recordId = getObjectId(data.url);
          }

          if (data.typeChallenge === TypeChallenge.share) {
            record.action = TypeChallenge.share;
            record.kind = undefined;
            record.recordId = getObjectId(data.url);
          }

          if (data.typeChallenge === TypeChallenge.incentive) {
            record.action = data?.awarded ? 'awarded' : EnumAnalyticsEvent.AcceptTerm;
            record.kind = !data?.awarded ? undefined : EnumAnalyticsParentKind.IncentiveCampaign;
            record.amount = 1;
          }

          if (data.typeChallenge === TypeChallenge.time) {
            record.action = TypeChallenge.time;
            record.kind = undefined;
          }

          if (data.typeChallenge === TypeChallenge.research) {
            record.action = TypeChallenge.research;
            record.kind = TypeChallenge.research;
            record.amount = 1;
            record.challengeData = {
              title: data.titleQuestions,
              questions: data?.questions || [],
            };
            if (!data?.questions || data?.questions?.length === 0) {
              setValues({ ...record });
              return message.error('Por favor, adicione questões para a pesquisa.');
            }
          }

          if (data.typeChallenge === TypeChallenge.quiz) {
            record.action = TypeChallenge.quiz;
            record.kind = TypeChallenge.quiz;
            record.amount = 1;
            record.challengeData = {
              hitsComplete: data.hitsComplete || 1,
              maxAttempts: data.maxAttempts || 1,
              title: data.titleQuestions,
              questions: data?.questions || [],
            };
            if (!data?.questions || data?.questions?.length === 0) {
              setValues({ ...record });
              return message.error('Por favor, adicione questões para o quiz.');
            }
          }

          if (data.typeChallenge === TypeChallenge.externalLink) {
            record.action = EnumAnalyticsEvent.ExternalLink;
            record.kind = EnumAnalyticsParentKind.User;
            record.externalLink = data.externalLink;
            record.amount = 1;
          }

          if (data.typeChallenge === TypeChallenge.mission) {
            record.action = TypeChallenge.mission;
            record.kind = undefined;
            record.challengeData = { status: 'in_progress' };
            record.amount = 1;
          }

          if (data.typeChallenge === TypeChallenge.view) {
            record.action = EnumAnalyticsEvent.View;
            record.recordId = getObjectId(data.url);
          }

          if (availableAtRegions.length === 0 || availableAtRoleGroups.length === 0) {
            setValues({ ...record });
            return message.error('Por favor Selecione o público');
          }

          if (!record.action) return message.error('Não foi possível localizar ação');

          const callback = (ctx: { result?: any }) => {
            if (ctx?.result) {
              message.success(`Desafio ${isUpdate && !isDuplicate ? 'atualizado' : 'cadastrado'} com sucesso.`);
              history.push(routes.gameficationList.mount());
            } else {
              setValues({ ...record });
              message.error(`Desafio não ${isUpdate && !isDuplicate ? 'atualizado' : 'criado.'}`);
            }
          };

          if (isUpdate && !isDuplicate) {
            await updateChallenge
              .fetch({ variables: { filter: { _id: props.initialValues!._id }, record } })
              .then(callback);
          } else {
            await createChallenge.fetch({ variables: { record } }).then(callback);
          }
          return;
        }}
      />
    </Container>
  );
};
