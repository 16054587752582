import { User } from '../autogenerated/client/types';
import { extractUserChannelName } from './extractUserChannelName';

export function parseUser(user?: Partial<User> | null, alternative = '-') {
  user = user || {};

  const result = {
    _id: user._id || '',
    name: user.name || alternative,
    matricula: user.matricula || alternative,
    CPF: user.CPF || alternative,
    UF: user.UF || alternative,
    region: user.region || alternative,
    regionPath: user.regionPath || alternative,
    DDD: user.allowedDDDs?.length ? user.allowedDDDs?.join(', ') : alternative,
    channel: extractUserChannelName(user),
    roleGroup: user.roleGroup?.name || '',
    viewOtherUsersContent: !!user.viewOtherUsersContent
  };

  if (result.DDD === '1') {
    result.DDD = 'THQ';
  }

  return result;
}
