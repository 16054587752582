import { useEffect, useState } from 'react';
import { useCurrentUser } from '@digi-tim-19/components';
import { useClient } from '../../autogenerated/client/client';
import {
  EnumCampaignContestationResponseStatus,
  SortFindManyCampaignContestationInput
} from '../../autogenerated/client/types';
import { formatDate } from '@digi-tim-19/utils/build';

export function useUserContestacao(
  incentiveCampaignId: string,
  setDataModal?: Function
) {
  const user = useCurrentUser().result;

  const CampaignContestationPagination = useClient(
    'CampaignContestationPagination'
  );

  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (user && incentiveCampaignId && data.length === 0) {
      CampaignContestationPagination.fetch({
        appendToFragment,
        variables: {
          filter: {
            owner: user._id,
            parentId: incentiveCampaignId
          },
          sort: SortFindManyCampaignContestationInput.IdAsc,
          perPage: 100,
          page: 1
        }
      }).then((ctx) => {
        if (ctx.result && ctx.result.items?.length) {
          setData(
            ctx.result.items?.map((contestation) => {
              return {
                key: contestation?._id,
                dateContestation: formatDate(
                  contestation?.createdAt,
                  'DD/MM/YYYY'
                ),
                status: getStatusTranslation(contestation?.response?.status),
                message: contestation?.message,
                protocol: contestation?.protocol,
                response: contestation?.response?.body,
                dateResponse: contestation?.response?.createdBy,
                files: contestation?.files,
                onRead: () => {
                  if (setDataModal) setDataModal(contestation);
                },
                blockChangeActiveHandler: true
              };
            })
          );
        }
      });
    }
  }, [user, incentiveCampaignId]);

  return {
    loading: CampaignContestationPagination.loading,
    contestacoes: data
  };
}

export const getStatusTranslation = (
  word: EnumCampaignContestationResponseStatus | null | undefined
) => {
  switch (word) {
    case 'unfounded':
      return 'Improcedente';
    case 'founded':
      return 'Procedente';
    default:
      return 'Em contestação';
  }
};

const appendToFragment = `
  pageInfo {
    itemCount
  }
  items {
    _id
    message
    protocol
    createdAt
    indicator
    files {
      title
      downloadUrl
    }
    response {
      body
      status
      createdAt
      file
    }
    campaign {
      createdAt
      title
    }
  }
`;

export function getColorStatus(statusName: string): string {
  let color = 'blue';

  if (statusName === 'Procedente') color = 'green';
  if (statusName === 'Improcedente') color = 'red';

  return color;
}
