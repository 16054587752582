"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ContestationStatusFilterEnum;

(function (ContestationStatusFilterEnum) {
  ContestationStatusFilterEnum["Founded"] = "founded";
  ContestationStatusFilterEnum["Unfounded"] = "unfounded";
  ContestationStatusFilterEnum["Empty"] = "empty";
})(ContestationStatusFilterEnum = exports.ContestationStatusFilterEnum || (exports.ContestationStatusFilterEnum = {}));

var EmailTemplateNames;

(function (EmailTemplateNames) {
  EmailTemplateNames["Template1"] = "template1";
  EmailTemplateNames["Template2"] = "template2";
  EmailTemplateNames["Template3"] = "template3";
  EmailTemplateNames["Template4"] = "template4";
})(EmailTemplateNames = exports.EmailTemplateNames || (exports.EmailTemplateNames = {}));

var EnumAnalyticsParentKind;

(function (EnumAnalyticsParentKind) {
  EnumAnalyticsParentKind["PriceTable"] = "price_table";
  EnumAnalyticsParentKind["Material"] = "material";
  EnumAnalyticsParentKind["Video"] = "video";
  EnumAnalyticsParentKind["Communication"] = "communication";
  EnumAnalyticsParentKind["Regulation"] = "regulation";
  EnumAnalyticsParentKind["Tanamao"] = "tanamao";
  EnumAnalyticsParentKind["OfferMap"] = "offer_map";
  EnumAnalyticsParentKind["Process"] = "process";
  EnumAnalyticsParentKind["SalesArgument"] = "sales_argument";
  EnumAnalyticsParentKind["DevicesComparator"] = "devices_comparator";
  EnumAnalyticsParentKind["PartsMap"] = "parts_map";
  EnumAnalyticsParentKind["SupportMaterials"] = "support_materials";
  EnumAnalyticsParentKind["LinksUteis"] = "links_uteis";
  EnumAnalyticsParentKind["News"] = "news";
  EnumAnalyticsParentKind["Document"] = "document";
  EnumAnalyticsParentKind["CompaniesMaterials"] = "companies_materials";
  EnumAnalyticsParentKind["SeeMoreChannel"] = "see_more_channel";
  EnumAnalyticsParentKind["SeeMoreRegion"] = "see_more_region";
  EnumAnalyticsParentKind["Live"] = "live";
  EnumAnalyticsParentKind["IncentiveCampaign"] = "incentiveCampaign";
})(EnumAnalyticsParentKind = exports.EnumAnalyticsParentKind || (exports.EnumAnalyticsParentKind = {}));

var EnumBannerImagesDisplay;

(function (EnumBannerImagesDisplay) {
  EnumBannerImagesDisplay["Desktop"] = "desktop";
  EnumBannerImagesDisplay["Mobile"] = "mobile";
  EnumBannerImagesDisplay["Both"] = "both";
})(EnumBannerImagesDisplay = exports.EnumBannerImagesDisplay || (exports.EnumBannerImagesDisplay = {}));

var EnumCampaignContestationResponseStatus;

(function (EnumCampaignContestationResponseStatus) {
  EnumCampaignContestationResponseStatus["Founded"] = "founded";
  EnumCampaignContestationResponseStatus["Unfounded"] = "unfounded";
})(EnumCampaignContestationResponseStatus = exports.EnumCampaignContestationResponseStatus || (exports.EnumCampaignContestationResponseStatus = {}));

var EnumEmailStatus;

(function (EnumEmailStatus) {
  EnumEmailStatus["Scheduled"] = "scheduled";
  EnumEmailStatus["Sent"] = "sent";
  EnumEmailStatus["Draft"] = "draft";
  EnumEmailStatus["Error"] = "error";
})(EnumEmailStatus = exports.EnumEmailStatus || (exports.EnumEmailStatus = {}));

var EnumEmailTemplateName;

(function (EnumEmailTemplateName) {
  EnumEmailTemplateName["Template1"] = "template1";
  EnumEmailTemplateName["Template2"] = "template2";
  EnumEmailTemplateName["Template3"] = "template3";
  EnumEmailTemplateName["Template4"] = "template4";
  EnumEmailTemplateName["Template5"] = "template5";
  EnumEmailTemplateName["TemplateConsumer01"] = "templateConsumer01";
  EnumEmailTemplateName["TemplateConsumer02"] = "templateConsumer02";
  EnumEmailTemplateName["TemplateConsumer03"] = "templateConsumer03";
  EnumEmailTemplateName["TemplateConsumer04"] = "templateConsumer04";
  EnumEmailTemplateName["TemplateConsumer05"] = "templateConsumer05";
  EnumEmailTemplateName["TemplateCorp01"] = "templateCorp01";
  EnumEmailTemplateName["TemplateCorp02"] = "templateCorp02";
  EnumEmailTemplateName["TemplateCorp03"] = "templateCorp03";
  EnumEmailTemplateName["TemplateCorp04"] = "templateCorp04";
  EnumEmailTemplateName["TemplateCorp05"] = "templateCorp05";
  EnumEmailTemplateName["PlainHtml"] = "plainHTML";
})(EnumEmailTemplateName = exports.EnumEmailTemplateName || (exports.EnumEmailTemplateName = {}));

var EnumEventLiveKind;

(function (EnumEventLiveKind) {
  EnumEventLiveKind["Live"] = "live";
})(EnumEventLiveKind = exports.EnumEventLiveKind || (exports.EnumEventLiveKind = {}));

var EnumEventLiveStatus;

(function (EnumEventLiveStatus) {
  EnumEventLiveStatus["Editing"] = "editing";
  EnumEventLiveStatus["WaitingApproval"] = "waiting_approval";
  EnumEventLiveStatus["Approved"] = "approved";
  EnumEventLiveStatus["Disapproved"] = "disapproved";
  EnumEventLiveStatus["Cancelled"] = "cancelled";
  EnumEventLiveStatus["Published"] = "published";
})(EnumEventLiveStatus = exports.EnumEventLiveStatus || (exports.EnumEventLiveStatus = {}));
/** Status da criação do video após fim da live */


var EnumEventLiveTransferCompositionStatus;

(function (EnumEventLiveTransferCompositionStatus) {
  EnumEventLiveTransferCompositionStatus["Empty"] = "empty";
  EnumEventLiveTransferCompositionStatus["CompositionStarted"] = "compositionStarted";
  EnumEventLiveTransferCompositionStatus["CompositionComplete"] = "compositionComplete";
  EnumEventLiveTransferCompositionStatus["WaitingComposition"] = "waitingComposition";
  EnumEventLiveTransferCompositionStatus["Transferred"] = "transferred";
  EnumEventLiveTransferCompositionStatus["Errored"] = "errored";
})(EnumEventLiveTransferCompositionStatus = exports.EnumEventLiveTransferCompositionStatus || (exports.EnumEventLiveTransferCompositionStatus = {}));
/** O tipo de uso do material. Ex: "video_poster" */


var EnumFileKind;

(function (EnumFileKind) {
  EnumFileKind["CardThumb"] = "card_thumb";
  EnumFileKind["VideoMp4"] = "video_mp4";
  EnumFileKind["VideoThumb"] = "video_thumb";
  EnumFileKind["VideoBanner"] = "video_banner";
  EnumFileKind["Pdf"] = "pdf";
  EnumFileKind["Notification"] = "notification";
  EnumFileKind["Mailing"] = "mailing";
  EnumFileKind["NotificationImage"] = "notification_image";
  EnumFileKind["EmailAsset"] = "email_asset";
  EnumFileKind["BannerImage"] = "banner_image";
  EnumFileKind["AnyImage"] = "any_image";
  EnumFileKind["AnyDoc"] = "any_doc";
  EnumFileKind["ProfileImage"] = "profile_image";
  EnumFileKind["Xlsx"] = "xlsx";
  EnumFileKind["BannerMaterialMobile"] = "banner_material_mobile";
  EnumFileKind["BannerMaterialDesktop"] = "banner_material_desktop";
  EnumFileKind["SocialFeedImage"] = "social_feed_image";
  EnumFileKind["SocialFeedVideo"] = "social_feed_video";
  EnumFileKind["SensitiveFile"] = "sensitive_file";
  EnumFileKind["StructuralFiles"] = "structural_files";
})(EnumFileKind = exports.EnumFileKind || (exports.EnumFileKind = {}));

var EnumFolderKind;

(function (EnumFolderKind) {
  EnumFolderKind["Favorite"] = "favorite";
})(EnumFolderKind = exports.EnumFolderKind || (exports.EnumFolderKind = {}));

var EnumIncentiveCampaignStatus;

(function (EnumIncentiveCampaignStatus) {
  EnumIncentiveCampaignStatus["Current"] = "current";
  EnumIncentiveCampaignStatus["WithResults"] = "with_results";
  EnumIncentiveCampaignStatus["InProgress"] = "in_progress";
  EnumIncentiveCampaignStatus["InVerification"] = "in_verification";
  EnumIncentiveCampaignStatus["WaitingPointsDistribution"] = "waiting_points_distribution";
  EnumIncentiveCampaignStatus["Closed"] = "closed";
  EnumIncentiveCampaignStatus["ClosedWithoutWinners"] = "closed_without_winners";
  EnumIncentiveCampaignStatus["ClosedPartner"] = "closed_partner";
})(EnumIncentiveCampaignStatus = exports.EnumIncentiveCampaignStatus || (exports.EnumIncentiveCampaignStatus = {}));

var EnumIncentiveCampaignCampaignType;

(function (EnumIncentiveCampaignCampaignType) {
  EnumIncentiveCampaignCampaignType["Ranking"] = "ranking";
  EnumIncentiveCampaignCampaignType["BateuLevou"] = "bateu_levou";
  EnumIncentiveCampaignCampaignType["Valoriza"] = "valoriza";
  EnumIncentiveCampaignCampaignType["Conversao"] = "conversao";
})(EnumIncentiveCampaignCampaignType = exports.EnumIncentiveCampaignCampaignType || (exports.EnumIncentiveCampaignCampaignType = {}));

var EnumMaterialCommunicationItemsConfigKind;

(function (EnumMaterialCommunicationItemsConfigKind) {
  EnumMaterialCommunicationItemsConfigKind["Email"] = "email";
  EnumMaterialCommunicationItemsConfigKind["Notification"] = "notification";
  EnumMaterialCommunicationItemsConfigKind["Bulletin"] = "bulletin";
})(EnumMaterialCommunicationItemsConfigKind = exports.EnumMaterialCommunicationItemsConfigKind || (exports.EnumMaterialCommunicationItemsConfigKind = {}));

var EnumMaterialKind;

(function (EnumMaterialKind) {
  EnumMaterialKind["PriceTable"] = "price_table";
  EnumMaterialKind["Material"] = "material";
  EnumMaterialKind["Video"] = "video";
  EnumMaterialKind["Communication"] = "communication";
  EnumMaterialKind["Regulation"] = "regulation";
  EnumMaterialKind["Tanamao"] = "tanamao";
  EnumMaterialKind["OfferMap"] = "offer_map";
  EnumMaterialKind["Process"] = "process";
  EnumMaterialKind["SalesArgument"] = "sales_argument";
  EnumMaterialKind["DevicesComparator"] = "devices_comparator";
  EnumMaterialKind["PartsMap"] = "parts_map";
  EnumMaterialKind["SupportMaterials"] = "support_materials";
  EnumMaterialKind["LinksUteis"] = "links_uteis";
  EnumMaterialKind["News"] = "news";
  EnumMaterialKind["Document"] = "document";
  EnumMaterialKind["CompaniesMaterials"] = "companies_materials";
  EnumMaterialKind["SeeMoreChannel"] = "see_more_channel";
  EnumMaterialKind["SeeMoreRegion"] = "see_more_region";
  EnumMaterialKind["SocialFeed"] = "social_feed";
})(EnumMaterialKind = exports.EnumMaterialKind || (exports.EnumMaterialKind = {}));

var EnumMaterialPreviousStatus;

(function (EnumMaterialPreviousStatus) {
  EnumMaterialPreviousStatus["Editing"] = "editing";
  EnumMaterialPreviousStatus["WaitingApproval"] = "waiting_approval";
  EnumMaterialPreviousStatus["Approved"] = "approved";
  EnumMaterialPreviousStatus["Disapproved"] = "disapproved";
  EnumMaterialPreviousStatus["Cancelled"] = "cancelled";
  EnumMaterialPreviousStatus["Published"] = "published";
})(EnumMaterialPreviousStatus = exports.EnumMaterialPreviousStatus || (exports.EnumMaterialPreviousStatus = {}));

var EnumMaterialStatus;

(function (EnumMaterialStatus) {
  EnumMaterialStatus["Editing"] = "editing";
  EnumMaterialStatus["WaitingApproval"] = "waiting_approval";
  EnumMaterialStatus["Approved"] = "approved";
  EnumMaterialStatus["Disapproved"] = "disapproved";
  EnumMaterialStatus["Cancelled"] = "cancelled";
  EnumMaterialStatus["Published"] = "published";
})(EnumMaterialStatus = exports.EnumMaterialStatus || (exports.EnumMaterialStatus = {}));

var EnumEventStatus;

(function (EnumEventStatus) {
  EnumEventStatus["Programming"] = "programming";
  EnumEventStatus["In_progress"] = "in_progress";
  EnumEventStatus["Closed"] = "closed";
  EnumEventStatus["Cancelled"] = "cancelled";
})(EnumEventStatus = exports.EnumEventStatus || (exports.EnumEventStatus = {}));

var EnumPlaceCluster;

(function (EnumPlaceCluster) {
  EnumPlaceCluster["Premium"] = "premium";
  EnumPlaceCluster["MiddleUp"] = "middle_up";
  EnumPlaceCluster["MiddleDown"] = "middle_down";
  EnumPlaceCluster["Basic"] = "basic";
  EnumPlaceCluster["Prime"] = "prime";
  EnumPlaceCluster["Standard"] = "standard";
  EnumPlaceCluster["CurvaA"] = "curva_a";
  EnumPlaceCluster["CurvaB"] = "curva_b";
  EnumPlaceCluster["CurvaC"] = "curva_c";
  EnumPlaceCluster["CurvaD"] = "curva_d";
  EnumPlaceCluster["CurvaE"] = "curva_e";
})(EnumPlaceCluster = exports.EnumPlaceCluster || (exports.EnumPlaceCluster = {}));

var EnumPlaceFoco_De_Comunicacao;

(function (EnumPlaceFoco_De_Comunicacao) {
  EnumPlaceFoco_De_Comunicacao["Controle"] = "controle";
  EnumPlaceFoco_De_Comunicacao["Pos"] = "pos";
})(EnumPlaceFoco_De_Comunicacao = exports.EnumPlaceFoco_De_Comunicacao || (exports.EnumPlaceFoco_De_Comunicacao = {}));

var EnumPlaceLocalizacao;

(function (EnumPlaceLocalizacao) {
  EnumPlaceLocalizacao["Rua"] = "rua";
  EnumPlaceLocalizacao["Galeria"] = "galeria";
  EnumPlaceLocalizacao["Shopping"] = "shopping";
})(EnumPlaceLocalizacao = exports.EnumPlaceLocalizacao || (exports.EnumPlaceLocalizacao = {}));

var EnumPlaceModelo_De_Loja;

(function (EnumPlaceModelo_De_Loja) {
  EnumPlaceModelo_De_Loja["LojaAntiga"] = "loja_antiga";
  EnumPlaceModelo_De_Loja["Compacta"] = "compacta";
  EnumPlaceModelo_De_Loja["Experiencia"] = "experiencia";
  EnumPlaceModelo_De_Loja["Store"] = "store";
  EnumPlaceModelo_De_Loja["LojaHighDigital"] = "loja_high_digital";
  EnumPlaceModelo_De_Loja["LojaPrimeDigital"] = "loja_prime_digital";
  EnumPlaceModelo_De_Loja["QuiosqueFechado"] = "quiosque_fechado";
  EnumPlaceModelo_De_Loja["QuiosqueAberto"] = "quiosque_aberto";
  EnumPlaceModelo_De_Loja["Lounge"] = "lounge";
})(EnumPlaceModelo_De_Loja = exports.EnumPlaceModelo_De_Loja || (exports.EnumPlaceModelo_De_Loja = {}));

var EnumPlaceProdutos_Comercializados;

(function (EnumPlaceProdutos_Comercializados) {
  EnumPlaceProdutos_Comercializados["Pre"] = "pre";
  EnumPlaceProdutos_Comercializados["Pos"] = "pos";
  EnumPlaceProdutos_Comercializados["Controle"] = "controle";
  EnumPlaceProdutos_Comercializados["Fixo"] = "fixo";
  EnumPlaceProdutos_Comercializados["Live"] = "live";
  EnumPlaceProdutos_Comercializados["Wttx"] = "wttx";
  EnumPlaceProdutos_Comercializados["Smb"] = "smb";
  EnumPlaceProdutos_Comercializados["Seguro"] = "seguro";
  EnumPlaceProdutos_Comercializados["TradeIn"] = "trade_in";
  EnumPlaceProdutos_Comercializados["Acessorios"] = "acessorios";
})(EnumPlaceProdutos_Comercializados = exports.EnumPlaceProdutos_Comercializados || (exports.EnumPlaceProdutos_Comercializados = {}));

var EnumPlaceRevestimento_Externo;

(function (EnumPlaceRevestimento_Externo) {
  EnumPlaceRevestimento_Externo["AcmCinza"] = "acm_cinza";
  EnumPlaceRevestimento_Externo["AcmBranca"] = "acm_branca";
  EnumPlaceRevestimento_Externo["AcmAzul"] = "acm_azul";
  EnumPlaceRevestimento_Externo["Porcelanato"] = "porcelanato";
  EnumPlaceRevestimento_Externo["Marmorato"] = "marmorato";
  EnumPlaceRevestimento_Externo["Pintura"] = "pintura";
})(EnumPlaceRevestimento_Externo = exports.EnumPlaceRevestimento_Externo || (exports.EnumPlaceRevestimento_Externo = {}));

var EnumPlaceTipo_De_Fachada;

(function (EnumPlaceTipo_De_Fachada) {
  EnumPlaceTipo_De_Fachada["FlutuanteTransparente"] = "flutuante_transparente";
  EnumPlaceTipo_De_Fachada["FlutuanteAzul"] = "flutuante_azul";
  EnumPlaceTipo_De_Fachada["Acm"] = "acm";
  EnumPlaceTipo_De_Fachada["Nicho"] = "nicho";
  EnumPlaceTipo_De_Fachada["Pescador"] = "pescador";
})(EnumPlaceTipo_De_Fachada = exports.EnumPlaceTipo_De_Fachada || (exports.EnumPlaceTipo_De_Fachada = {}));

var EnumPlaceTipo_De_Loja;

(function (EnumPlaceTipo_De_Loja) {
  EnumPlaceTipo_De_Loja["Cessao"] = "cessao";
  EnumPlaceTipo_De_Loja["SapSetorDeAtendimentoPresencial"] = "sap__setor_de_atendimento_presencial_";
})(EnumPlaceTipo_De_Loja = exports.EnumPlaceTipo_De_Loja || (exports.EnumPlaceTipo_De_Loja = {}));

var EnumPlaceType;

(function (EnumPlaceType) {
  EnumPlaceType["Hq"] = "hq";
  EnumPlaceType["EscritorioRegional"] = "escritorio_regional";
  EnumPlaceType["CentroDeDistribuicaoLogistico"] = "centro_de_distribuicao_logistico";
  EnumPlaceType["LojaPropria"] = "loja_propria";
  EnumPlaceType["Revenda"] = "revenda";
  EnumPlaceType["Master"] = "master";
  EnumPlaceType["Varejo"] = "varejo";
  EnumPlaceType["Distribuicao"] = "distribuicao";
})(EnumPlaceType = exports.EnumPlaceType || (exports.EnumPlaceType = {}));

var EnumPlaceUltima_Modificacao;

(function (EnumPlaceUltima_Modificacao) {
  EnumPlaceUltima_Modificacao["Abertura"] = "abertura";
  EnumPlaceUltima_Modificacao["Reforma"] = "reforma";
  EnumPlaceUltima_Modificacao["ReformaParcial"] = "reforma_parcial";
  EnumPlaceUltima_Modificacao["TrocaDePonto"] = "troca_de_ponto";
  EnumPlaceUltima_Modificacao["Expansao"] = "expansao";
  EnumPlaceUltima_Modificacao["Facelift"] = "facelift";
})(EnumPlaceUltima_Modificacao = exports.EnumPlaceUltima_Modificacao || (exports.EnumPlaceUltima_Modificacao = {}));

var EnumPlaceVideo_WallKind;

(function (EnumPlaceVideo_WallKind) {
  EnumPlaceVideo_WallKind["StandAlone"] = "stand_alone";
  EnumPlaceVideo_WallKind["Testeira"] = "testeira";
  EnumPlaceVideo_WallKind["PainelInterno"] = "painel_interno";
  EnumPlaceVideo_WallKind["Portico"] = "portico";
  EnumPlaceVideo_WallKind["TotemExterno"] = "totem_externo";
  EnumPlaceVideo_WallKind["TotemInterno"] = "totem_interno";
  EnumPlaceVideo_WallKind["VitrineEmbutido"] = "vitrine_embutido";
  EnumPlaceVideo_WallKind["VitrineAquario"] = "vitrine_aquario";
})(EnumPlaceVideo_WallKind = exports.EnumPlaceVideo_WallKind || (exports.EnumPlaceVideo_WallKind = {}));

var EnumPlaceVideo_WallOrientation;

(function (EnumPlaceVideo_WallOrientation) {
  EnumPlaceVideo_WallOrientation["Horizontal"] = "horizontal";
  EnumPlaceVideo_WallOrientation["Vertical"] = "vertical";
})(EnumPlaceVideo_WallOrientation = exports.EnumPlaceVideo_WallOrientation || (exports.EnumPlaceVideo_WallOrientation = {}));

var EnumShareKind;

(function (EnumShareKind) {
  EnumShareKind["Material"] = "material";
  EnumShareKind["IncentiveCampaign"] = "incentiveCampaign";
})(EnumShareKind = exports.EnumShareKind || (exports.EnumShareKind = {}));

var EnumUserReconheceHistoryOp;

(function (EnumUserReconheceHistoryOp) {
  EnumUserReconheceHistoryOp["Reserve"] = "reserve";
  EnumUserReconheceHistoryOp["Debit"] = "debit";
  EnumUserReconheceHistoryOp["Release"] = "release";
  EnumUserReconheceHistoryOp["Chargeback"] = "chargeback";
})(EnumUserReconheceHistoryOp = exports.EnumUserReconheceHistoryOp || (exports.EnumUserReconheceHistoryOp = {}));

var Sort;

(function (Sort) {
  Sort["CreatedatAsc"] = "CREATEDAT_ASC";
  Sort["CreatedatDesc"] = "CREATEDAT_DESC";
})(Sort = exports.Sort || (exports.Sort = {}));

var SortFindByIdsCategoryInput;

(function (SortFindByIdsCategoryInput) {
  SortFindByIdsCategoryInput["IdAsc"] = "_ID_ASC";
  SortFindByIdsCategoryInput["IdDesc"] = "_ID_DESC";
  SortFindByIdsCategoryInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindByIdsCategoryInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindByIdsCategoryInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindByIdsCategoryInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindByIdsCategoryInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindByIdsCategoryInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindByIdsCategoryInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindByIdsCategoryInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindByIdsCategoryInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindByIdsCategoryInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortFindByIdsCategoryInput["SortAsc"] = "SORT_ASC";
  SortFindByIdsCategoryInput["SortDesc"] = "SORT_DESC";
})(SortFindByIdsCategoryInput = exports.SortFindByIdsCategoryInput || (exports.SortFindByIdsCategoryInput = {}));

var SortFindByIdsContractInput;

(function (SortFindByIdsContractInput) {
  SortFindByIdsContractInput["IdAsc"] = "_ID_ASC";
  SortFindByIdsContractInput["IdDesc"] = "_ID_DESC";
})(SortFindByIdsContractInput = exports.SortFindByIdsContractInput || (exports.SortFindByIdsContractInput = {}));

var SortFindByIdsFileInput;

(function (SortFindByIdsFileInput) {
  SortFindByIdsFileInput["IdAsc"] = "_ID_ASC";
  SortFindByIdsFileInput["IdDesc"] = "_ID_DESC";
})(SortFindByIdsFileInput = exports.SortFindByIdsFileInput || (exports.SortFindByIdsFileInput = {}));

var SortFindByIdsSupplyInput;

(function (SortFindByIdsSupplyInput) {
  SortFindByIdsSupplyInput["IdAsc"] = "_ID_ASC";
  SortFindByIdsSupplyInput["IdDesc"] = "_ID_DESC";
  SortFindByIdsSupplyInput["NameAsc"] = "NAME_ASC";
  SortFindByIdsSupplyInput["NameDesc"] = "NAME_DESC";
})(SortFindByIdsSupplyInput = exports.SortFindByIdsSupplyInput || (exports.SortFindByIdsSupplyInput = {}));

var SortFindByIdsUserInput;

(function (SortFindByIdsUserInput) {
  SortFindByIdsUserInput["IdAsc"] = "_ID_ASC";
  SortFindByIdsUserInput["IdDesc"] = "_ID_DESC";
  SortFindByIdsUserInput["EmailAsc"] = "EMAIL_ASC";
  SortFindByIdsUserInput["EmailDesc"] = "EMAIL_DESC";
  SortFindByIdsUserInput["NameAsc"] = "NAME_ASC";
  SortFindByIdsUserInput["NameDesc"] = "NAME_DESC";
  SortFindByIdsUserInput["NameMatriculaAsc"] = "NAME__MATRICULA_ASC";
  SortFindByIdsUserInput["NameMatriculaDesc"] = "NAME__MATRICULA_DESC";
})(SortFindByIdsUserInput = exports.SortFindByIdsUserInput || (exports.SortFindByIdsUserInput = {}));

var SortFindManyBannerInput;

(function (SortFindManyBannerInput) {
  SortFindManyBannerInput["IdAsc"] = "_ID_ASC";
  SortFindManyBannerInput["IdDesc"] = "_ID_DESC";
  SortFindManyBannerInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindManyBannerInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindManyBannerInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindManyBannerInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindManyBannerInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindManyBannerInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindManyBannerInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindManyBannerInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindManyBannerInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindManyBannerInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortFindManyBannerInput["OwnerAsc"] = "OWNER_ASC";
  SortFindManyBannerInput["OwnerDesc"] = "OWNER_DESC";
  SortFindManyBannerInput["SortAsc"] = "SORT_ASC";
  SortFindManyBannerInput["SortDesc"] = "SORT_DESC";
})(SortFindManyBannerInput = exports.SortFindManyBannerInput || (exports.SortFindManyBannerInput = {}));

var SortFindManyCampaignContestationInput;

(function (SortFindManyCampaignContestationInput) {
  SortFindManyCampaignContestationInput["IdAsc"] = "_ID_ASC";
  SortFindManyCampaignContestationInput["IdDesc"] = "_ID_DESC";
  SortFindManyCampaignContestationInput["OwnerAsc"] = "OWNER_ASC";
  SortFindManyCampaignContestationInput["OwnerDesc"] = "OWNER_DESC";
})(SortFindManyCampaignContestationInput = exports.SortFindManyCampaignContestationInput || (exports.SortFindManyCampaignContestationInput = {}));

var SortFindManyCategoryInput;

(function (SortFindManyCategoryInput) {
  SortFindManyCategoryInput["IdAsc"] = "_ID_ASC";
  SortFindManyCategoryInput["IdDesc"] = "_ID_DESC";
  SortFindManyCategoryInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindManyCategoryInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindManyCategoryInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindManyCategoryInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindManyCategoryInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindManyCategoryInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindManyCategoryInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindManyCategoryInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindManyCategoryInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindManyCategoryInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortFindManyCategoryInput["SortAsc"] = "SORT_ASC";
  SortFindManyCategoryInput["SortDesc"] = "SORT_DESC";
})(SortFindManyCategoryInput = exports.SortFindManyCategoryInput || (exports.SortFindManyCategoryInput = {}));

var SortFindManyChannelInput;

(function (SortFindManyChannelInput) {
  SortFindManyChannelInput["IdAsc"] = "_ID_ASC";
  SortFindManyChannelInput["IdDesc"] = "_ID_DESC";
  SortFindManyChannelInput["NameAsc"] = "NAME_ASC";
  SortFindManyChannelInput["NameDesc"] = "NAME_DESC";
})(SortFindManyChannelInput = exports.SortFindManyChannelInput || (exports.SortFindManyChannelInput = {}));

var SortFindManyCommentInput;

(function (SortFindManyCommentInput) {
  SortFindManyCommentInput["IdAsc"] = "_ID_ASC";
  SortFindManyCommentInput["IdDesc"] = "_ID_DESC";
  SortFindManyCommentInput["OwnerAsc"] = "OWNER_ASC";
  SortFindManyCommentInput["OwnerDesc"] = "OWNER_DESC";
  SortFindManyCommentInput["CreatedatAsc"] = "CREATEDAT_ASC";
  SortFindManyCommentInput["CreatedatDesc"] = "CREATEDAT_DESC";
  SortFindManyCommentInput["LikecountAsc"] = "LIKECOUNT_ASC";
  SortFindManyCommentInput["LikecountDesc"] = "LIKECOUNT_DESC";
  SortFindManyCommentInput["SubcommentcountAsc"] = "SUBCOMMENTCOUNT_ASC";
  SortFindManyCommentInput["SubcommentcountDesc"] = "SUBCOMMENTCOUNT_DESC";
})(SortFindManyCommentInput = exports.SortFindManyCommentInput || (exports.SortFindManyCommentInput = {}));

var SortFindManyContractInput;

(function (SortFindManyContractInput) {
  SortFindManyContractInput["IdAsc"] = "_ID_ASC";
  SortFindManyContractInput["IdDesc"] = "_ID_DESC";
})(SortFindManyContractInput = exports.SortFindManyContractInput || (exports.SortFindManyContractInput = {}));

var SortFindManyDeviceInput;

(function (SortFindManyDeviceInput) {
  SortFindManyDeviceInput["IdAsc"] = "_ID_ASC";
  SortFindManyDeviceInput["IdDesc"] = "_ID_DESC";
})(SortFindManyDeviceInput = exports.SortFindManyDeviceInput || (exports.SortFindManyDeviceInput = {}));

var SortFindManyDigitalBrochureInput;

(function (SortFindManyDigitalBrochureInput) {
  SortFindManyDigitalBrochureInput["IdAsc"] = "_ID_ASC";
  SortFindManyDigitalBrochureInput["IdDesc"] = "_ID_DESC";
})(SortFindManyDigitalBrochureInput = exports.SortFindManyDigitalBrochureInput || (exports.SortFindManyDigitalBrochureInput = {}));

var SortFindManyEmailInput;

(function (SortFindManyEmailInput) {
  SortFindManyEmailInput["IdAsc"] = "_ID_ASC";
  SortFindManyEmailInput["IdDesc"] = "_ID_DESC";
})(SortFindManyEmailInput = exports.SortFindManyEmailInput || (exports.SortFindManyEmailInput = {}));

var SortFindManyEventLiveInput;

(function (SortFindManyEventLiveInput) {
  SortFindManyEventLiveInput["IdAsc"] = "_ID_ASC";
  SortFindManyEventLiveInput["IdDesc"] = "_ID_DESC";
  SortFindManyEventLiveInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindManyEventLiveInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindManyEventLiveInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindManyEventLiveInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindManyEventLiveInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindManyEventLiveInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindManyEventLiveInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindManyEventLiveInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindManyEventLiveInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindManyEventLiveInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortFindManyEventLiveInput["OwnerAsc"] = "OWNER_ASC";
  SortFindManyEventLiveInput["OwnerDesc"] = "OWNER_DESC";
  SortFindManyEventLiveInput["EventdateAsc"] = "EVENTDATE_ASC";
  SortFindManyEventLiveInput["EventdateDesc"] = "EVENTDATE_DESC";
  SortFindManyEventLiveInput["EventdateEndtimeAsc"] = "EVENTDATE__ENDTIME_ASC";
  SortFindManyEventLiveInput["EventdateEndtimeDesc"] = "EVENTDATE__ENDTIME_DESC";
  SortFindManyEventLiveInput["EventdateEndtimeTransfercompositionstatusAsc"] = "EVENTDATE__ENDTIME__TRANSFERCOMPOSITIONSTATUS_ASC";
  SortFindManyEventLiveInput["EventdateEndtimeTransfercompositionstatusDesc"] = "EVENTDATE__ENDTIME__TRANSFERCOMPOSITIONSTATUS_DESC";
})(SortFindManyEventLiveInput = exports.SortFindManyEventLiveInput || (exports.SortFindManyEventLiveInput = {}));

var SortFindManyFolderInput;

(function (SortFindManyFolderInput) {
  SortFindManyFolderInput["IdAsc"] = "_ID_ASC";
  SortFindManyFolderInput["IdDesc"] = "_ID_DESC";
  SortFindManyFolderInput["OwnerAsc"] = "OWNER_ASC";
  SortFindManyFolderInput["OwnerDesc"] = "OWNER_DESC";
})(SortFindManyFolderInput = exports.SortFindManyFolderInput || (exports.SortFindManyFolderInput = {}));

var SortFindManyIncentiveCampaignInput;

(function (SortFindManyIncentiveCampaignInput) {
  SortFindManyIncentiveCampaignInput["IdAsc"] = "_ID_ASC";
  SortFindManyIncentiveCampaignInput["IdDesc"] = "_ID_DESC";
  SortFindManyIncentiveCampaignInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindManyIncentiveCampaignInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindManyIncentiveCampaignInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindManyIncentiveCampaignInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindManyIncentiveCampaignInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindManyIncentiveCampaignInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindManyIncentiveCampaignInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindManyIncentiveCampaignInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindManyIncentiveCampaignInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindManyIncentiveCampaignInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortFindManyIncentiveCampaignInput["OwnerAsc"] = "OWNER_ASC";
  SortFindManyIncentiveCampaignInput["OwnerDesc"] = "OWNER_DESC";
})(SortFindManyIncentiveCampaignInput = exports.SortFindManyIncentiveCampaignInput || (exports.SortFindManyIncentiveCampaignInput = {}));

var SortFindManyJobTitleInput;

(function (SortFindManyJobTitleInput) {
  SortFindManyJobTitleInput["IdAsc"] = "_ID_ASC";
  SortFindManyJobTitleInput["IdDesc"] = "_ID_DESC";
})(SortFindManyJobTitleInput = exports.SortFindManyJobTitleInput || (exports.SortFindManyJobTitleInput = {}));

var SortFindManyMaterialInput;

(function (SortFindManyMaterialInput) {
  SortFindManyMaterialInput["IdAsc"] = "_ID_ASC";
  SortFindManyMaterialInput["IdDesc"] = "_ID_DESC";
  SortFindManyMaterialInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindManyMaterialInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindManyMaterialInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindManyMaterialInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindManyMaterialInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindManyMaterialInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindManyMaterialInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindManyMaterialInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindManyMaterialInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindManyMaterialInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortFindManyMaterialInput["OwnerAsc"] = "OWNER_ASC";
  SortFindManyMaterialInput["OwnerDesc"] = "OWNER_DESC";
  SortFindManyMaterialInput["KindAsc"] = "KIND_ASC";
  SortFindManyMaterialInput["KindDesc"] = "KIND_DESC";
  SortFindManyMaterialInput["KindNumericidAsc"] = "KIND__NUMERICID_ASC";
  SortFindManyMaterialInput["KindNumericidDesc"] = "KIND__NUMERICID_DESC";
  SortFindManyMaterialInput["CreatedatAsc"] = "CREATEDAT_ASC";
  SortFindManyMaterialInput["CreatedatDesc"] = "CREATEDAT_DESC";
})(SortFindManyMaterialInput = exports.SortFindManyMaterialInput || (exports.SortFindManyMaterialInput = {}));

var SortFindManyEventInput;

(function (SortFindManyEventInput) {
  SortFindManyEventInput["IdAsc"] = "_ID_ASC";
  SortFindManyEventInput["IdDesc"] = "_ID_DESC";
  SortFindManyEventInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindManyEventInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindManyEventInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindManyEventInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindManyEventInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindManyEventInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindManyEventInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindManyEventInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindManyEventInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindManyEventInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortFindManyEventInput["OwnerAsc"] = "OWNER_ASC";
  SortFindManyEventInput["OwnerDesc"] = "OWNER_DESC";
  SortFindManyEventInput["CreatedatAsc"] = "CREATEDAT_ASC";
  SortFindManyEventInput["CreatedatDesc"] = "CREATEDAT_DESC";
})(SortFindManyEventInput = exports.SortFindManyEventInput || (exports.SortFindManyEventInput = {}));

var SortFindManyNotificationInput;

(function (SortFindManyNotificationInput) {
  SortFindManyNotificationInput["IdAsc"] = "_ID_ASC";
  SortFindManyNotificationInput["IdDesc"] = "_ID_DESC";
  SortFindManyNotificationInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindManyNotificationInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindManyNotificationInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindManyNotificationInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindManyNotificationInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindManyNotificationInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindManyNotificationInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindManyNotificationInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindManyNotificationInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindManyNotificationInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
})(SortFindManyNotificationInput = exports.SortFindManyNotificationInput || (exports.SortFindManyNotificationInput = {}));

var SortFindManyPinInput;

(function (SortFindManyPinInput) {
  SortFindManyPinInput["IdAsc"] = "_ID_ASC";
  SortFindManyPinInput["IdDesc"] = "_ID_DESC";
  SortFindManyPinInput["OwnerAsc"] = "OWNER_ASC";
  SortFindManyPinInput["OwnerDesc"] = "OWNER_DESC";
})(SortFindManyPinInput = exports.SortFindManyPinInput || (exports.SortFindManyPinInput = {}));

var SortFindManyPlaceInput;

(function (SortFindManyPlaceInput) {
  SortFindManyPlaceInput["IdAsc"] = "_ID_ASC";
  SortFindManyPlaceInput["IdDesc"] = "_ID_DESC";
})(SortFindManyPlaceInput = exports.SortFindManyPlaceInput || (exports.SortFindManyPlaceInput = {}));

var SortFindManyProviderInput;

(function (SortFindManyProviderInput) {
  SortFindManyProviderInput["IdAsc"] = "_ID_ASC";
  SortFindManyProviderInput["IdDesc"] = "_ID_DESC";
})(SortFindManyProviderInput = exports.SortFindManyProviderInput || (exports.SortFindManyProviderInput = {}));

var SortFindManyRegionInput;

(function (SortFindManyRegionInput) {
  SortFindManyRegionInput["IdAsc"] = "_ID_ASC";
  SortFindManyRegionInput["IdDesc"] = "_ID_DESC";
  SortFindManyRegionInput["NameAsc"] = "NAME_ASC";
  SortFindManyRegionInput["NameDesc"] = "NAME_DESC";
})(SortFindManyRegionInput = exports.SortFindManyRegionInput || (exports.SortFindManyRegionInput = {}));

var SortFindManyRegionItemInput;

(function (SortFindManyRegionItemInput) {
  SortFindManyRegionItemInput["IdAsc"] = "_ID_ASC";
  SortFindManyRegionItemInput["IdDesc"] = "_ID_DESC";
})(SortFindManyRegionItemInput = exports.SortFindManyRegionItemInput || (exports.SortFindManyRegionItemInput = {}));

var SortFindManyRoleGroupInput;

(function (SortFindManyRoleGroupInput) {
  SortFindManyRoleGroupInput["IdAsc"] = "_ID_ASC";
  SortFindManyRoleGroupInput["IdDesc"] = "_ID_DESC";
  SortFindManyRoleGroupInput["NameAsc"] = "NAME_ASC";
  SortFindManyRoleGroupInput["NameDesc"] = "NAME_DESC";
})(SortFindManyRoleGroupInput = exports.SortFindManyRoleGroupInput || (exports.SortFindManyRoleGroupInput = {}));

var SortFindManySearchCountInput;

(function (SortFindManySearchCountInput) {
  SortFindManySearchCountInput["IdAsc"] = "_ID_ASC";
  SortFindManySearchCountInput["IdDesc"] = "_ID_DESC";
  SortFindManySearchCountInput["TermAsc"] = "TERM_ASC";
  SortFindManySearchCountInput["TermDesc"] = "TERM_DESC";
  SortFindManySearchCountInput["CountAsc"] = "COUNT_ASC";
  SortFindManySearchCountInput["CountDesc"] = "COUNT_DESC";
})(SortFindManySearchCountInput = exports.SortFindManySearchCountInput || (exports.SortFindManySearchCountInput = {}));

var SortFindManyShareInput;

(function (SortFindManyShareInput) {
  SortFindManyShareInput["IdAsc"] = "_ID_ASC";
  SortFindManyShareInput["IdDesc"] = "_ID_DESC";
  SortFindManyShareInput["OwnerAsc"] = "OWNER_ASC";
  SortFindManyShareInput["OwnerDesc"] = "OWNER_DESC";
})(SortFindManyShareInput = exports.SortFindManyShareInput || (exports.SortFindManyShareInput = {}));

var SortFindManySupplyInput;

(function (SortFindManySupplyInput) {
  SortFindManySupplyInput["IdAsc"] = "_ID_ASC";
  SortFindManySupplyInput["IdDesc"] = "_ID_DESC";
  SortFindManySupplyInput["NameAsc"] = "NAME_ASC";
  SortFindManySupplyInput["NameDesc"] = "NAME_DESC";
})(SortFindManySupplyInput = exports.SortFindManySupplyInput || (exports.SortFindManySupplyInput = {}));

var SortFindManyTablePriceInput;

(function (SortFindManyTablePriceInput) {
  SortFindManyTablePriceInput["IdAsc"] = "_ID_ASC";
  SortFindManyTablePriceInput["IdDesc"] = "_ID_DESC";
})(SortFindManyTablePriceInput = exports.SortFindManyTablePriceInput || (exports.SortFindManyTablePriceInput = {}));

var SortFindManyUserInput;

(function (SortFindManyUserInput) {
  SortFindManyUserInput["IdAsc"] = "_ID_ASC";
  SortFindManyUserInput["IdDesc"] = "_ID_DESC";
  SortFindManyUserInput["EmailAsc"] = "EMAIL_ASC";
  SortFindManyUserInput["EmailDesc"] = "EMAIL_DESC";
  SortFindManyUserInput["NameAsc"] = "NAME_ASC";
  SortFindManyUserInput["NameDesc"] = "NAME_DESC";
  SortFindManyUserInput["NameMatriculaAsc"] = "NAME__MATRICULA_ASC";
  SortFindManyUserInput["NameMatriculaDesc"] = "NAME__MATRICULA_DESC";
  SortFindManyUserInput["CreatedatAsc"] = "CREATEDAT_ASC";
  SortFindManyUserInput["CreatedatDesc"] = "CREATEDAT_DESC";
  SortFindManyUserInput["DisabledAsc"] = "DISABLED_ASC";
  SortFindManyUserInput["DisabledDesc"] = "DISABLED_DESC";
})(SortFindManyUserInput = exports.SortFindManyUserInput || (exports.SortFindManyUserInput = {}));

var SortFindOneBannerInput;

(function (SortFindOneBannerInput) {
  SortFindOneBannerInput["IdAsc"] = "_ID_ASC";
  SortFindOneBannerInput["IdDesc"] = "_ID_DESC";
  SortFindOneBannerInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindOneBannerInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindOneBannerInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindOneBannerInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindOneBannerInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindOneBannerInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindOneBannerInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindOneBannerInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindOneBannerInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindOneBannerInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortFindOneBannerInput["OwnerAsc"] = "OWNER_ASC";
  SortFindOneBannerInput["OwnerDesc"] = "OWNER_DESC";
  SortFindOneBannerInput["SortAsc"] = "SORT_ASC";
  SortFindOneBannerInput["SortDesc"] = "SORT_DESC";
})(SortFindOneBannerInput = exports.SortFindOneBannerInput || (exports.SortFindOneBannerInput = {}));

var SortFindOneCampaignContestationInput;

(function (SortFindOneCampaignContestationInput) {
  SortFindOneCampaignContestationInput["IdAsc"] = "_ID_ASC";
  SortFindOneCampaignContestationInput["IdDesc"] = "_ID_DESC";
  SortFindOneCampaignContestationInput["OwnerAsc"] = "OWNER_ASC";
  SortFindOneCampaignContestationInput["OwnerDesc"] = "OWNER_DESC";
})(SortFindOneCampaignContestationInput = exports.SortFindOneCampaignContestationInput || (exports.SortFindOneCampaignContestationInput = {}));

var SortFindOneCategoryInput;

(function (SortFindOneCategoryInput) {
  SortFindOneCategoryInput["IdAsc"] = "_ID_ASC";
  SortFindOneCategoryInput["IdDesc"] = "_ID_DESC";
  SortFindOneCategoryInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindOneCategoryInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindOneCategoryInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindOneCategoryInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindOneCategoryInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindOneCategoryInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindOneCategoryInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindOneCategoryInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindOneCategoryInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindOneCategoryInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortFindOneCategoryInput["SortAsc"] = "SORT_ASC";
  SortFindOneCategoryInput["SortDesc"] = "SORT_DESC";
})(SortFindOneCategoryInput = exports.SortFindOneCategoryInput || (exports.SortFindOneCategoryInput = {}));

var SortFindOneChannelInput;

(function (SortFindOneChannelInput) {
  SortFindOneChannelInput["IdAsc"] = "_ID_ASC";
  SortFindOneChannelInput["IdDesc"] = "_ID_DESC";
  SortFindOneChannelInput["NameAsc"] = "NAME_ASC";
  SortFindOneChannelInput["NameDesc"] = "NAME_DESC";
})(SortFindOneChannelInput = exports.SortFindOneChannelInput || (exports.SortFindOneChannelInput = {}));

var SortFindOneCommentInput;

(function (SortFindOneCommentInput) {
  SortFindOneCommentInput["IdAsc"] = "_ID_ASC";
  SortFindOneCommentInput["IdDesc"] = "_ID_DESC";
  SortFindOneCommentInput["OwnerAsc"] = "OWNER_ASC";
  SortFindOneCommentInput["OwnerDesc"] = "OWNER_DESC";
})(SortFindOneCommentInput = exports.SortFindOneCommentInput || (exports.SortFindOneCommentInput = {}));

var SortFindOneContractInput;

(function (SortFindOneContractInput) {
  SortFindOneContractInput["IdAsc"] = "_ID_ASC";
  SortFindOneContractInput["IdDesc"] = "_ID_DESC";
})(SortFindOneContractInput = exports.SortFindOneContractInput || (exports.SortFindOneContractInput = {}));

var SortFindOneDeviceInput;

(function (SortFindOneDeviceInput) {
  SortFindOneDeviceInput["IdAsc"] = "_ID_ASC";
  SortFindOneDeviceInput["IdDesc"] = "_ID_DESC";
})(SortFindOneDeviceInput = exports.SortFindOneDeviceInput || (exports.SortFindOneDeviceInput = {}));

var SortFindOneDigitalBrochureInput;

(function (SortFindOneDigitalBrochureInput) {
  SortFindOneDigitalBrochureInput["IdAsc"] = "_ID_ASC";
  SortFindOneDigitalBrochureInput["IdDesc"] = "_ID_DESC";
})(SortFindOneDigitalBrochureInput = exports.SortFindOneDigitalBrochureInput || (exports.SortFindOneDigitalBrochureInput = {}));

var SortFindOneEmailInput;

(function (SortFindOneEmailInput) {
  SortFindOneEmailInput["IdAsc"] = "_ID_ASC";
  SortFindOneEmailInput["IdDesc"] = "_ID_DESC";
})(SortFindOneEmailInput = exports.SortFindOneEmailInput || (exports.SortFindOneEmailInput = {}));

var SortFindOneEventLiveInput;

(function (SortFindOneEventLiveInput) {
  SortFindOneEventLiveInput["IdAsc"] = "_ID_ASC";
  SortFindOneEventLiveInput["IdDesc"] = "_ID_DESC";
  SortFindOneEventLiveInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindOneEventLiveInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindOneEventLiveInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindOneEventLiveInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindOneEventLiveInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindOneEventLiveInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindOneEventLiveInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindOneEventLiveInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindOneEventLiveInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindOneEventLiveInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortFindOneEventLiveInput["OwnerAsc"] = "OWNER_ASC";
  SortFindOneEventLiveInput["OwnerDesc"] = "OWNER_DESC";
  SortFindOneEventLiveInput["EventdateAsc"] = "EVENTDATE_ASC";
  SortFindOneEventLiveInput["EventdateDesc"] = "EVENTDATE_DESC";
  SortFindOneEventLiveInput["EventdateEndtimeAsc"] = "EVENTDATE__ENDTIME_ASC";
  SortFindOneEventLiveInput["EventdateEndtimeDesc"] = "EVENTDATE__ENDTIME_DESC";
  SortFindOneEventLiveInput["EventdateEndtimeTransfercompositionstatusAsc"] = "EVENTDATE__ENDTIME__TRANSFERCOMPOSITIONSTATUS_ASC";
  SortFindOneEventLiveInput["EventdateEndtimeTransfercompositionstatusDesc"] = "EVENTDATE__ENDTIME__TRANSFERCOMPOSITIONSTATUS_DESC";
})(SortFindOneEventLiveInput = exports.SortFindOneEventLiveInput || (exports.SortFindOneEventLiveInput = {}));

var SortFindOneIncentiveCampaignInput;

(function (SortFindOneIncentiveCampaignInput) {
  SortFindOneIncentiveCampaignInput["IdAsc"] = "_ID_ASC";
  SortFindOneIncentiveCampaignInput["IdDesc"] = "_ID_DESC";
  SortFindOneIncentiveCampaignInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindOneIncentiveCampaignInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindOneIncentiveCampaignInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindOneIncentiveCampaignInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindOneIncentiveCampaignInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindOneIncentiveCampaignInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindOneIncentiveCampaignInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindOneIncentiveCampaignInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindOneIncentiveCampaignInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindOneIncentiveCampaignInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortFindOneIncentiveCampaignInput["OwnerAsc"] = "OWNER_ASC";
  SortFindOneIncentiveCampaignInput["OwnerDesc"] = "OWNER_DESC";
})(SortFindOneIncentiveCampaignInput = exports.SortFindOneIncentiveCampaignInput || (exports.SortFindOneIncentiveCampaignInput = {}));

var SortFindOneJobTitleInput;

(function (SortFindOneJobTitleInput) {
  SortFindOneJobTitleInput["IdAsc"] = "_ID_ASC";
  SortFindOneJobTitleInput["IdDesc"] = "_ID_DESC";
})(SortFindOneJobTitleInput = exports.SortFindOneJobTitleInput || (exports.SortFindOneJobTitleInput = {}));

var SortFindOneMaterialInput;

(function (SortFindOneMaterialInput) {
  SortFindOneMaterialInput["IdAsc"] = "_ID_ASC";
  SortFindOneMaterialInput["IdDesc"] = "_ID_DESC";
  SortFindOneMaterialInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindOneMaterialInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindOneMaterialInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindOneMaterialInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindOneMaterialInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindOneMaterialInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindOneMaterialInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindOneMaterialInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindOneMaterialInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindOneMaterialInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortFindOneMaterialInput["OwnerAsc"] = "OWNER_ASC";
  SortFindOneMaterialInput["OwnerDesc"] = "OWNER_DESC";
  SortFindOneMaterialInput["KindAsc"] = "KIND_ASC";
  SortFindOneMaterialInput["KindDesc"] = "KIND_DESC";
  SortFindOneMaterialInput["KindNumericidAsc"] = "KIND__NUMERICID_ASC";
  SortFindOneMaterialInput["KindNumericidDesc"] = "KIND__NUMERICID_DESC";
  SortFindOneMaterialInput["CreatedatAsc"] = "CREATEDAT_ASC";
  SortFindOneMaterialInput["CreatedatDesc"] = "CREATEDAT_DESC";
})(SortFindOneMaterialInput = exports.SortFindOneMaterialInput || (exports.SortFindOneMaterialInput = {}));

var SortFindOneEventInput;

(function (SortFindOneEventInput) {
  SortFindOneEventInput["IdAsc"] = "_ID_ASC";
  SortFindOneEventInput["IdDesc"] = "_ID_DESC";
  SortFindOneEventInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindOneEventInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindOneEventInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindOneEventInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindOneEventInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindOneEventInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindOneEventInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindOneEventInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindOneEventInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindOneEventInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortFindOneEventInput["OwnerAsc"] = "OWNER_ASC";
  SortFindOneEventInput["OwnerDesc"] = "OWNER_DESC";
  SortFindOneEventInput["CreatedatAsc"] = "CREATEDAT_ASC";
  SortFindOneEventInput["CreatedatDesc"] = "CREATEDAT_DESC";
})(SortFindOneEventInput = exports.SortFindOneEventInput || (exports.SortFindOneEventInput = {}));

var SortFindOneNotificationInput;

(function (SortFindOneNotificationInput) {
  SortFindOneNotificationInput["IdAsc"] = "_ID_ASC";
  SortFindOneNotificationInput["IdDesc"] = "_ID_DESC";
  SortFindOneNotificationInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortFindOneNotificationInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortFindOneNotificationInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortFindOneNotificationInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortFindOneNotificationInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortFindOneNotificationInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortFindOneNotificationInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortFindOneNotificationInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortFindOneNotificationInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortFindOneNotificationInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
})(SortFindOneNotificationInput = exports.SortFindOneNotificationInput || (exports.SortFindOneNotificationInput = {}));

var SortFindOnePlaceInput;

(function (SortFindOnePlaceInput) {
  SortFindOnePlaceInput["IdAsc"] = "_ID_ASC";
  SortFindOnePlaceInput["IdDesc"] = "_ID_DESC";
})(SortFindOnePlaceInput = exports.SortFindOnePlaceInput || (exports.SortFindOnePlaceInput = {}));

var SortFindOneProviderInput;

(function (SortFindOneProviderInput) {
  SortFindOneProviderInput["IdAsc"] = "_ID_ASC";
  SortFindOneProviderInput["IdDesc"] = "_ID_DESC";
})(SortFindOneProviderInput = exports.SortFindOneProviderInput || (exports.SortFindOneProviderInput = {}));

var SortFindOneRegionInput;

(function (SortFindOneRegionInput) {
  SortFindOneRegionInput["IdAsc"] = "_ID_ASC";
  SortFindOneRegionInput["IdDesc"] = "_ID_DESC";
  SortFindOneRegionInput["NameAsc"] = "NAME_ASC";
  SortFindOneRegionInput["NameDesc"] = "NAME_DESC";
})(SortFindOneRegionInput = exports.SortFindOneRegionInput || (exports.SortFindOneRegionInput = {}));

var SortFindOneRegionItemInput;

(function (SortFindOneRegionItemInput) {
  SortFindOneRegionItemInput["IdAsc"] = "_ID_ASC";
  SortFindOneRegionItemInput["IdDesc"] = "_ID_DESC";
})(SortFindOneRegionItemInput = exports.SortFindOneRegionItemInput || (exports.SortFindOneRegionItemInput = {}));

var SortFindOneRoleGroupInput;

(function (SortFindOneRoleGroupInput) {
  SortFindOneRoleGroupInput["IdAsc"] = "_ID_ASC";
  SortFindOneRoleGroupInput["IdDesc"] = "_ID_DESC";
  SortFindOneRoleGroupInput["NameAsc"] = "NAME_ASC";
  SortFindOneRoleGroupInput["NameDesc"] = "NAME_DESC";
})(SortFindOneRoleGroupInput = exports.SortFindOneRoleGroupInput || (exports.SortFindOneRoleGroupInput = {}));

var SortFindOneTablePriceInput;

(function (SortFindOneTablePriceInput) {
  SortFindOneTablePriceInput["IdAsc"] = "_ID_ASC";
  SortFindOneTablePriceInput["IdDesc"] = "_ID_DESC";
})(SortFindOneTablePriceInput = exports.SortFindOneTablePriceInput || (exports.SortFindOneTablePriceInput = {}));

var SortFindOneUserInput;

(function (SortFindOneUserInput) {
  SortFindOneUserInput["IdAsc"] = "_ID_ASC";
  SortFindOneUserInput["IdDesc"] = "_ID_DESC";
  SortFindOneUserInput["EmailAsc"] = "EMAIL_ASC";
  SortFindOneUserInput["EmailDesc"] = "EMAIL_DESC";
  SortFindOneUserInput["NameAsc"] = "NAME_ASC";
  SortFindOneUserInput["NameDesc"] = "NAME_DESC";
  SortFindOneUserInput["NameMatriculaAsc"] = "NAME__MATRICULA_ASC";
  SortFindOneUserInput["NameMatriculaDesc"] = "NAME__MATRICULA_DESC";
})(SortFindOneUserInput = exports.SortFindOneUserInput || (exports.SortFindOneUserInput = {}));

var SortMenuEnum;

(function (SortMenuEnum) {
  SortMenuEnum["SortAsc"] = "SORT_ASC";
  SortMenuEnum["SortDesc"] = "SORT_DESC";
})(SortMenuEnum = exports.SortMenuEnum || (exports.SortMenuEnum = {}));

var SortRemoveOneFolderInput;

(function (SortRemoveOneFolderInput) {
  SortRemoveOneFolderInput["IdAsc"] = "_ID_ASC";
  SortRemoveOneFolderInput["IdDesc"] = "_ID_DESC";
  SortRemoveOneFolderInput["OwnerAsc"] = "OWNER_ASC";
  SortRemoveOneFolderInput["OwnerDesc"] = "OWNER_DESC";
})(SortRemoveOneFolderInput = exports.SortRemoveOneFolderInput || (exports.SortRemoveOneFolderInput = {}));

var SortRemoveOneMaterialInput;

(function (SortRemoveOneMaterialInput) {
  SortRemoveOneMaterialInput["IdAsc"] = "_ID_ASC";
  SortRemoveOneMaterialInput["IdDesc"] = "_ID_DESC";
  SortRemoveOneMaterialInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortRemoveOneMaterialInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortRemoveOneMaterialInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortRemoveOneMaterialInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortRemoveOneMaterialInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortRemoveOneMaterialInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortRemoveOneMaterialInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortRemoveOneMaterialInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortRemoveOneMaterialInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortRemoveOneMaterialInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortRemoveOneMaterialInput["OwnerAsc"] = "OWNER_ASC";
  SortRemoveOneMaterialInput["OwnerDesc"] = "OWNER_DESC";
  SortRemoveOneMaterialInput["KindAsc"] = "KIND_ASC";
  SortRemoveOneMaterialInput["KindDesc"] = "KIND_DESC";
  SortRemoveOneMaterialInput["KindNumericidAsc"] = "KIND__NUMERICID_ASC";
  SortRemoveOneMaterialInput["KindNumericidDesc"] = "KIND__NUMERICID_DESC";
  SortRemoveOneMaterialInput["CreatedatAsc"] = "CREATEDAT_ASC";
  SortRemoveOneMaterialInput["CreatedatDesc"] = "CREATEDAT_DESC";
})(SortRemoveOneMaterialInput = exports.SortRemoveOneMaterialInput || (exports.SortRemoveOneMaterialInput = {}));

var SortRemoveOnePinInput;

(function (SortRemoveOnePinInput) {
  SortRemoveOnePinInput["IdAsc"] = "_ID_ASC";
  SortRemoveOnePinInput["IdDesc"] = "_ID_DESC";
  SortRemoveOnePinInput["OwnerAsc"] = "OWNER_ASC";
  SortRemoveOnePinInput["OwnerDesc"] = "OWNER_DESC";
})(SortRemoveOnePinInput = exports.SortRemoveOnePinInput || (exports.SortRemoveOnePinInput = {}));

var SortRemoveOneRegionInput;

(function (SortRemoveOneRegionInput) {
  SortRemoveOneRegionInput["IdAsc"] = "_ID_ASC";
  SortRemoveOneRegionInput["IdDesc"] = "_ID_DESC";
  SortRemoveOneRegionInput["NameAsc"] = "NAME_ASC";
  SortRemoveOneRegionInput["NameDesc"] = "NAME_DESC";
})(SortRemoveOneRegionInput = exports.SortRemoveOneRegionInput || (exports.SortRemoveOneRegionInput = {}));

var SortRemoveOneShareInput;

(function (SortRemoveOneShareInput) {
  SortRemoveOneShareInput["IdAsc"] = "_ID_ASC";
  SortRemoveOneShareInput["IdDesc"] = "_ID_DESC";
  SortRemoveOneShareInput["OwnerAsc"] = "OWNER_ASC";
  SortRemoveOneShareInput["OwnerDesc"] = "OWNER_DESC";
})(SortRemoveOneShareInput = exports.SortRemoveOneShareInput || (exports.SortRemoveOneShareInput = {}));

var SortUpdateOneBannerInput;

(function (SortUpdateOneBannerInput) {
  SortUpdateOneBannerInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneBannerInput["IdDesc"] = "_ID_DESC";
  SortUpdateOneBannerInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortUpdateOneBannerInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortUpdateOneBannerInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortUpdateOneBannerInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortUpdateOneBannerInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortUpdateOneBannerInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortUpdateOneBannerInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortUpdateOneBannerInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortUpdateOneBannerInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortUpdateOneBannerInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortUpdateOneBannerInput["OwnerAsc"] = "OWNER_ASC";
  SortUpdateOneBannerInput["OwnerDesc"] = "OWNER_DESC";
})(SortUpdateOneBannerInput = exports.SortUpdateOneBannerInput || (exports.SortUpdateOneBannerInput = {}));

var SortUpdateOneCategoryInput;

(function (SortUpdateOneCategoryInput) {
  SortUpdateOneCategoryInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneCategoryInput["IdDesc"] = "_ID_DESC";
  SortUpdateOneCategoryInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortUpdateOneCategoryInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortUpdateOneCategoryInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortUpdateOneCategoryInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortUpdateOneCategoryInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortUpdateOneCategoryInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortUpdateOneCategoryInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortUpdateOneCategoryInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortUpdateOneCategoryInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortUpdateOneCategoryInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
})(SortUpdateOneCategoryInput = exports.SortUpdateOneCategoryInput || (exports.SortUpdateOneCategoryInput = {}));

var SortUpdateOneCommentInput;

(function (SortUpdateOneCommentInput) {
  SortUpdateOneCommentInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneCommentInput["IdDesc"] = "_ID_DESC";
  SortUpdateOneCommentInput["OwnerAsc"] = "OWNER_ASC";
  SortUpdateOneCommentInput["OwnerDesc"] = "OWNER_DESC";
})(SortUpdateOneCommentInput = exports.SortUpdateOneCommentInput || (exports.SortUpdateOneCommentInput = {}));

var SortUpdateOneContractInput;

(function (SortUpdateOneContractInput) {
  SortUpdateOneContractInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneContractInput["IdDesc"] = "_ID_DESC";
})(SortUpdateOneContractInput = exports.SortUpdateOneContractInput || (exports.SortUpdateOneContractInput = {}));

var SortUpdateOneDeviceInput;

(function (SortUpdateOneDeviceInput) {
  SortUpdateOneDeviceInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneDeviceInput["IdDesc"] = "_ID_DESC";
})(SortUpdateOneDeviceInput = exports.SortUpdateOneDeviceInput || (exports.SortUpdateOneDeviceInput = {}));

var SortUpdateOneDigitalBrochureInput;

(function (SortUpdateOneDigitalBrochureInput) {
  SortUpdateOneDigitalBrochureInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneDigitalBrochureInput["IdDesc"] = "_ID_DESC";
})(SortUpdateOneDigitalBrochureInput = exports.SortUpdateOneDigitalBrochureInput || (exports.SortUpdateOneDigitalBrochureInput = {}));

var SortUpdateOneEventLiveInput;

(function (SortUpdateOneEventLiveInput) {
  SortUpdateOneEventLiveInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneEventLiveInput["IdDesc"] = "_ID_DESC";
  SortUpdateOneEventLiveInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortUpdateOneEventLiveInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortUpdateOneEventLiveInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortUpdateOneEventLiveInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortUpdateOneEventLiveInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortUpdateOneEventLiveInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortUpdateOneEventLiveInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortUpdateOneEventLiveInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortUpdateOneEventLiveInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortUpdateOneEventLiveInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortUpdateOneEventLiveInput["OwnerAsc"] = "OWNER_ASC";
  SortUpdateOneEventLiveInput["OwnerDesc"] = "OWNER_DESC";
  SortUpdateOneEventLiveInput["EventdateAsc"] = "EVENTDATE_ASC";
  SortUpdateOneEventLiveInput["EventdateDesc"] = "EVENTDATE_DESC";
  SortUpdateOneEventLiveInput["EventdateEndtimeAsc"] = "EVENTDATE__ENDTIME_ASC";
  SortUpdateOneEventLiveInput["EventdateEndtimeDesc"] = "EVENTDATE__ENDTIME_DESC";
  SortUpdateOneEventLiveInput["EventdateEndtimeTransfercompositionstatusAsc"] = "EVENTDATE__ENDTIME__TRANSFERCOMPOSITIONSTATUS_ASC";
  SortUpdateOneEventLiveInput["EventdateEndtimeTransfercompositionstatusDesc"] = "EVENTDATE__ENDTIME__TRANSFERCOMPOSITIONSTATUS_DESC";
})(SortUpdateOneEventLiveInput = exports.SortUpdateOneEventLiveInput || (exports.SortUpdateOneEventLiveInput = {}));

var SortUpdateOneFolderInput;

(function (SortUpdateOneFolderInput) {
  SortUpdateOneFolderInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneFolderInput["IdDesc"] = "_ID_DESC";
  SortUpdateOneFolderInput["OwnerAsc"] = "OWNER_ASC";
  SortUpdateOneFolderInput["OwnerDesc"] = "OWNER_DESC";
})(SortUpdateOneFolderInput = exports.SortUpdateOneFolderInput || (exports.SortUpdateOneFolderInput = {}));

var SortUpdateOneIncentiveCampaignInput;

(function (SortUpdateOneIncentiveCampaignInput) {
  SortUpdateOneIncentiveCampaignInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneIncentiveCampaignInput["IdDesc"] = "_ID_DESC";
  SortUpdateOneIncentiveCampaignInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortUpdateOneIncentiveCampaignInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortUpdateOneIncentiveCampaignInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortUpdateOneIncentiveCampaignInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortUpdateOneIncentiveCampaignInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortUpdateOneIncentiveCampaignInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortUpdateOneIncentiveCampaignInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortUpdateOneIncentiveCampaignInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortUpdateOneIncentiveCampaignInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortUpdateOneIncentiveCampaignInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortUpdateOneIncentiveCampaignInput["OwnerAsc"] = "OWNER_ASC";
  SortUpdateOneIncentiveCampaignInput["OwnerDesc"] = "OWNER_DESC";
})(SortUpdateOneIncentiveCampaignInput = exports.SortUpdateOneIncentiveCampaignInput || (exports.SortUpdateOneIncentiveCampaignInput = {}));

var SortUpdateOneMaterialInput;

(function (SortUpdateOneMaterialInput) {
  SortUpdateOneMaterialInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneMaterialInput["IdDesc"] = "_ID_DESC";
  SortUpdateOneMaterialInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortUpdateOneMaterialInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortUpdateOneMaterialInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortUpdateOneMaterialInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortUpdateOneMaterialInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortUpdateOneMaterialInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortUpdateOneMaterialInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortUpdateOneMaterialInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortUpdateOneMaterialInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortUpdateOneMaterialInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortUpdateOneMaterialInput["OwnerAsc"] = "OWNER_ASC";
  SortUpdateOneMaterialInput["OwnerDesc"] = "OWNER_DESC";
  SortUpdateOneMaterialInput["KindAsc"] = "KIND_ASC";
  SortUpdateOneMaterialInput["KindDesc"] = "KIND_DESC";
  SortUpdateOneMaterialInput["KindNumericidAsc"] = "KIND__NUMERICID_ASC";
  SortUpdateOneMaterialInput["KindNumericidDesc"] = "KIND__NUMERICID_DESC";
  SortUpdateOneMaterialInput["CreatedatAsc"] = "CREATEDAT_ASC";
  SortUpdateOneMaterialInput["CreatedatDesc"] = "CREATEDAT_DESC";
})(SortUpdateOneMaterialInput = exports.SortUpdateOneMaterialInput || (exports.SortUpdateOneMaterialInput = {}));

var SortUpdateOneEventInput;

(function (SortUpdateOneEventInput) {
  SortUpdateOneEventInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneEventInput["IdDesc"] = "_ID_DESC";
  SortUpdateOneEventInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortUpdateOneEventInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortUpdateOneEventInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortUpdateOneEventInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortUpdateOneEventInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortUpdateOneEventInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortUpdateOneEventInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortUpdateOneEventInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortUpdateOneEventInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortUpdateOneEventInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
  SortUpdateOneEventInput["OwnerAsc"] = "OWNER_ASC";
  SortUpdateOneEventInput["OwnerDesc"] = "OWNER_DESC";
  SortUpdateOneEventInput["CreatedatAsc"] = "CREATEDAT_ASC";
  SortUpdateOneEventInput["CreatedatDesc"] = "CREATEDAT_DESC";
})(SortUpdateOneEventInput = exports.SortUpdateOneEventInput || (exports.SortUpdateOneEventInput = {}));

var SortUpdateOneNotificationInput;

(function (SortUpdateOneNotificationInput) {
  SortUpdateOneNotificationInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneNotificationInput["IdDesc"] = "_ID_DESC";
  SortUpdateOneNotificationInput["AvailableatregionsAsc"] = "AVAILABLEATREGIONS_ASC";
  SortUpdateOneNotificationInput["AvailableatregionsDesc"] = "AVAILABLEATREGIONS_DESC";
  SortUpdateOneNotificationInput["AvailableatchannelsAsc"] = "AVAILABLEATCHANNELS_ASC";
  SortUpdateOneNotificationInput["AvailableatchannelsDesc"] = "AVAILABLEATCHANNELS_DESC";
  SortUpdateOneNotificationInput["AvailableatrolegroupsAsc"] = "AVAILABLEATROLEGROUPS_ASC";
  SortUpdateOneNotificationInput["AvailableatrolegroupsDesc"] = "AVAILABLEATROLEGROUPS_DESC";
  SortUpdateOneNotificationInput["AvailableforusersAsc"] = "AVAILABLEFORUSERS_ASC";
  SortUpdateOneNotificationInput["AvailableforusersDesc"] = "AVAILABLEFORUSERS_DESC";
  SortUpdateOneNotificationInput["DestinationemailsfrommailingfileAsc"] = "DESTINATIONEMAILSFROMMAILINGFILE_ASC";
  SortUpdateOneNotificationInput["DestinationemailsfrommailingfileDesc"] = "DESTINATIONEMAILSFROMMAILINGFILE_DESC";
})(SortUpdateOneNotificationInput = exports.SortUpdateOneNotificationInput || (exports.SortUpdateOneNotificationInput = {}));

var SortUpdateOnePlaceInput;

(function (SortUpdateOnePlaceInput) {
  SortUpdateOnePlaceInput["IdAsc"] = "_ID_ASC";
  SortUpdateOnePlaceInput["IdDesc"] = "_ID_DESC";
})(SortUpdateOnePlaceInput = exports.SortUpdateOnePlaceInput || (exports.SortUpdateOnePlaceInput = {}));

var SortUpdateOneProviderInput;

(function (SortUpdateOneProviderInput) {
  SortUpdateOneProviderInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneProviderInput["IdDesc"] = "_ID_DESC";
})(SortUpdateOneProviderInput = exports.SortUpdateOneProviderInput || (exports.SortUpdateOneProviderInput = {}));

var SortUpdateOneSupplyInput;

(function (SortUpdateOneSupplyInput) {
  SortUpdateOneSupplyInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneSupplyInput["IdDesc"] = "_ID_DESC";
  SortUpdateOneSupplyInput["NameAsc"] = "NAME_ASC";
  SortUpdateOneSupplyInput["NameDesc"] = "NAME_DESC";
})(SortUpdateOneSupplyInput = exports.SortUpdateOneSupplyInput || (exports.SortUpdateOneSupplyInput = {}));

var SortUpdateOneTablePriceInput;

(function (SortUpdateOneTablePriceInput) {
  SortUpdateOneTablePriceInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneTablePriceInput["IdDesc"] = "_ID_DESC";
})(SortUpdateOneTablePriceInput = exports.SortUpdateOneTablePriceInput || (exports.SortUpdateOneTablePriceInput = {}));

var SortUpdateOneUserInput;

(function (SortUpdateOneUserInput) {
  SortUpdateOneUserInput["IdAsc"] = "_ID_ASC";
  SortUpdateOneUserInput["IdDesc"] = "_ID_DESC";
  SortUpdateOneUserInput["EmailAsc"] = "EMAIL_ASC";
  SortUpdateOneUserInput["EmailDesc"] = "EMAIL_DESC";
  SortUpdateOneUserInput["NameAsc"] = "NAME_ASC";
  SortUpdateOneUserInput["NameDesc"] = "NAME_DESC";
  SortUpdateOneUserInput["NameMatriculaAsc"] = "NAME__MATRICULA_ASC";
  SortUpdateOneUserInput["NameMatriculaDesc"] = "NAME__MATRICULA_DESC";
})(SortUpdateOneUserInput = exports.SortUpdateOneUserInput || (exports.SortUpdateOneUserInput = {}));

var ValidityEnum;

(function (ValidityEnum) {
  ValidityEnum["Expired"] = "EXPIRED";
  ValidityEnum["Future"] = "FUTURE";
  ValidityEnum["Current"] = "CURRENT";
  ValidityEnum["Any"] = "ANY";
})(ValidityEnum = exports.ValidityEnum || (exports.ValidityEnum = {}));

var EnumFileContentCampaignTypeFileUpload;

(function (EnumFileContentCampaignTypeFileUpload) {
  EnumFileContentCampaignTypeFileUpload["Partial"] = "partial";
  EnumFileContentCampaignTypeFileUpload["Final"] = "final";
})(EnumFileContentCampaignTypeFileUpload = exports.EnumFileContentCampaignTypeFileUpload || (exports.EnumFileContentCampaignTypeFileUpload = {}));