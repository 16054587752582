import React, { useState, useEffect } from 'react';
import { Breadcrumb, TitleDescription, Table, Select, LoadingIndicator, useCurrentUser } from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  NotificationsWrapper,
  FilterContainer,
  Button,
  CleanButton,
  SumWrapper,
  SumRow,
  SumValue,
} from './styleListar';
import { routes } from '../../../config/routes';
import { dateFormatList } from '../../../utils';
import moment from 'moment';
import { DatePicker, message } from 'antd';
import { useInvoiceOriginOptions } from '../../CampanhaIncentivo/useInvoiceOriginOptions';

import { formatDate, formatCurrency } from '@digi-tim-19/utils';
import { Link } from 'react-router-dom';
import { usePaginationClient } from './usePagination';
import { EnumReconheceWalletStatus } from '../../../autogenerated/client/types';
import { useClient } from '../../../autogenerated/client/client';

const { RangePicker } = DatePicker;

const configPage = {
  pageName: 'Ordens de pagamento',
  icon: 'dollar',
  description: 'Listagem de ordens de pagamento.',
  messageDeletedSuccess: 'Ordem removida com sucesso',
  textButtonNew: 'CADASTRAR NOVA',
  routeNew: routes.ordemPagamentoCadastrar.mount(),
  routeView: routes.ordemPagamentoEditar,
  routeEdit: routes.ordemPagamentoEditar,
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciamento', link: '' },
    { title: 'Ordens de pagamento', link: '' },
    { title: 'Listar', link: '' },
  ],
  limit: 10,
};

export const OrdemPagamentoListar: React.FC<TListarFaturas> = () => {
  const user = useCurrentUser();
  const isFinanceiro = user.userIsInRole('financeiro');

  const invoiceOrigins = useInvoiceOriginOptions();

  const columns = useColumns(isFinanceiro, invoiceOrigins);

  const client = usePaginationClient('ReconheceWalletPagination', {
    initial: {
      perPage: configPage.limit,
      page: 1,
    },
    itemsFragment: `
      _id
      createdAt
      initialCredit
      usedCredit
      availableCredit
      status
      POIdentifier
      NFIdentifier
      paidAt
      invoiceOrigin
      # createdBy: String
      # POImages: [String]
      # NFImages: [String]
      # inInvoiceOrigin: [String]
      # NFImageUrls(...): [File]
      # POImageUrls(...): [File]
    `,
    parseResult(result) {
      return result;
    },
  });

  const [objectSum, setObjectSum] = useState<any>({
    initialCreditTotal: 0,
    usedCreditTotal: 0,
    availableCreditTotal: 0,
  });

  const sumWallet = useClient('ReconheceWalletSum');

  useEffect(() => {
    sumWallet
      .fetch({
        variables: {
          invoiceOrigin: client.state.inInvoiceOrigin,
        },
      })
      .then((ctx) => {
        if (ctx && ctx.result) {
          setObjectSum({
            initialCreditTotal: ctx.result.initialCredit,
            usedCreditTotal: ctx.result.usedCredit,
            availableCreditTotal: ctx.result.availableCredit,
          });
        }
      });
  }, [client.state.inInvoiceOrigin]);

  const resetFilters = () => {
    client.updateFetch({
      inInvoiceOrigin: undefined,
      start: undefined,
      end: undefined,
    });
  };

  return (
    <PageTemplate>
      <>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo', link: routes.home.mount() },
              { title: 'Incentivo', link: '' },
              { title: 'Faturas', link: '' },
            ]}
          />
          <TitleDescription
            iconType={configPage.icon}
            title={configPage.pageName}
            description={configPage.description}
          />
        </NotificationsWrapper>
        <FilterContainer>
          {isFinanceiro && <Button to={configPage.routeNew}>{configPage.textButtonNew}</Button>}

          <Select
            placeholder="TIPO DE FATURA"
            options={invoiceOrigins}
            onChange={(value) => {
              client.updateFetch({ inInvoiceOrigin: value });
            }}
            value={client.state.inInvoiceOrigin}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={dateFormatList}
            placeholder={['PERÍODO', '']}
            onChange={(e: any) => {
              client.updateFetch({
                start: e[0] ? moment(e[0]).startOf('day')?.toISOString() : undefined,

                end: e[1] ? moment(e[1]).endOf('day')?.toISOString() : undefined,
              });
            }}
          />
          <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
        </FilterContainer>
        <SumWrapper>
          <SumRow>
            <SumValue>Valor total</SumValue>
            <SumValue>{formatCurrency(objectSum.initialCreditTotal)}</SumValue>
          </SumRow>
          <SumRow>
            <SumValue>Saldo usado total</SumValue>
            <SumValue>{formatCurrency(objectSum.usedCreditTotal)}</SumValue>
          </SumRow>
          <SumRow>
            <SumValue>Saldo disponível total</SumValue>
            <SumValue>{formatCurrency(objectSum.availableCreditTotal)}</SumValue>
          </SumRow>
        </SumWrapper>

        {client.loading ? (
          <LoadingIndicator />
        ) : (
          <Table columns={columns} dataSource={client.parsed} pagination={client.pagination} />
        )}
      </>
    </PageTemplate>
  );
};

export type TListarFaturas = {};

const useColumns = (isFinanceiro?: boolean, invoiceOrigins?: any[]) => {
  const PaymentOrderRemoveById = useClient('ReconheceWalletRemoveById');

  return [
    {
      title: 'NÚMERO DA P.O',
      dataIndex: 'POIdentifier',
      key: 'POIdentifier',
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: 'DATA DE EMISSÃO',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => <p>{formatDate(text, 'DD/MM/YYYY')}</p>,
    },
    {
      title: 'DATA DE PAGAMENTO',
      dataIndex: 'paidAt',
      key: 'paidAt',
      render: (text: string) => <p>{formatDate(text, 'DD/MM/YYYY')}</p>,
    },
    {
      title: 'VALOR',
      dataIndex: 'initialCredit',
      key: 'initialCredit',
      render: (text: string) => <p>{formatCurrency(text)}</p>,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => <p>{statusMap[text]}</p>,
    },
    {
      title: 'ORIGEM DA FATURA',
      dataIndex: 'invoiceOrigin',
      key: 'invoiceOrigin',
      render: (text: string) => <p>{invoiceOrigins?.find((item: any) => item.value === text)?.label || ''}</p>,
    },
    {
      title: 'SALDO',
      children: [
        {
          title: 'Usado',
          dataIndex: 'usedCredit',
          key: 'usedCredit',
          render: (text: string) => <p>{formatCurrency(text)}</p>,
        },
        {
          title: 'Disponível',
          dataIndex: 'availableCredit',
          key: 'availableCredit',
          render: (text: string) => <p>{formatCurrency(text)}</p>,
        },
      ],
    },
    isFinanceiro && {
      title: 'Editar',
      children: [
        {
          dataIndex: 'editar',
          key: 'editar',
          render: (text: string, doc: any) =>
            doc.status === EnumReconheceWalletStatus.Draft && (
              <Link to={configPage.routeEdit.mount(doc._id)}>
                <a>Editar</a>
              </Link>
            ),
        },
        {
          dataIndex: 'delete',
          key: 'delete',
          render: (_: any, doc: any) =>
            doc.status === EnumReconheceWalletStatus.Draft && (
              <a
                onClick={(event) => {
                  event.preventDefault();
                  PaymentOrderRemoveById.fetch({
                    variables: { _id: doc._id },
                    afterMutate: /^ReconheceWallet/,
                  }).then((ctx) => {
                    message.success(configPage.messageDeletedSuccess);
                  });
                }}
              >
                Apagar
              </a>
            ),
        },
      ],
    },
  ].filter(Boolean) as any[];
};

const statusMap: any = {
  [EnumReconheceWalletStatus.Available]: 'Disponível',
  [EnumReconheceWalletStatus.Draft]: 'Não confirmado',
};
