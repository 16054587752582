import { useState, useEffect } from 'react';

import { useClient } from '../../autogenerated/client/client';
import { EnumFolderKind } from '../../autogenerated/client/types';

export function useFoldersPin() {
  const [listFolders, setListFolders] = useState<any[]>([]);

  const getFolders = useClient('FolderPagination');
  const createFolder = useClient('FolderCreateOne');

  useEffect(() => {
    getFolders.fetch({
      appendToFragment: appendToFragmentFolder,
      variables: {
        filter: {
          kind: EnumFolderKind.Favorite
        },
        page: 1,
        perPage: 100
      }
    });
  }, []);

  useEffect(() => {
    if (getFolders.result?.items) setListFolders(getFolders?.result.items);
  }, [getFolders.result?.items]);

  function addFolder(
    nameFolder: string,
    cb: (error: null | string, result?: any) => any
  ) {
    createFolder
      .fetch({
        variables: {
          record: {
            name: nameFolder,
            kind: EnumFolderKind.Favorite
          }
        },
        afterMutate: /^Folder/
      })
      .then((ctx) => {
        if (ctx.errors) cb(ctx.errors.join());
        else cb(null, ctx.result);
      });
  }

  return {
    listFolders,
    addFolder
  };
}

const appendToFragmentFolder = `
items { 
  createdAt, 
  kind, 
  name, 
  owner, 
  updatedAt, 
  _id 
}`;
