import React from 'react';
import { verdade } from '@digi-tim-19/components';
import { useClient } from '../../../autogenerated/client/client';
import { SortFindManyReconheceWalletInput } from '../../../autogenerated/client/types';
import { Unpacked } from 'graphql-clientgen';
import { Methods } from '../../../autogenerated/client/types';

export const usePaginationClient = <K extends keyof Methods = 'any'>(methodName: K, props: UsePaginationConfig<K>) => {
  const { initial, itemsFragment = '_id', parseResult } = props;

  const stateRef = React.useRef<ClientState>(initial);

  const [state, setState] = React.useState(stateRef.current);

  function parseState(state: ClientState) {
    return {
      sort: SortFindManyReconheceWalletInput.IdDesc,
      start: state.start,
      end: state.end,
      invoiceOrigin: state.invoiceOrigin,
      perPage: state.perPage,
      page: state.page,
    };
  }

  const client = useClient(methodName, {
    variables: parseState(stateRef.current),
    fetchOnMount: true,
    appendToFragment: `
      count
      pageInfo { hasNextPage, hasPreviousPage, perPage, pageCount, currentPage }
      items {
        ${itemsFragment}
      }
    `,
  });

  const { loading, result } = client;
  const count = result?.count || 0;
  const { hasNextPage, hasPreviousPage, perPage, pageCount, currentPage } = result?.pageInfo || {};

  function updateFetch(newState?: Partial<ClientState>) {
    stateRef.current = {
      ...stateRef.current,
      ...newState,
    };

    setState(stateRef.current);

    client.fetch({
      variables: parseState(stateRef.current),
    });
  }

  function setPage(page: number) {
    if (!loading) {
      stateRef.current.page = page;
      updateFetch();
    }
  }

  function nextPage() {
    if (!loading && hasNextPage) {
      ++stateRef.current.page;
      updateFetch();
    }
  }

  function previousPage() {
    if (!loading && hasPreviousPage) {
      --stateRef.current.page;
      updateFetch();
    }
  }

  const parsed = React.useMemo(() => {
    return parseResult(verdade(client.result?.items));
  }, [result?.items]);

  return {
    ...client,
    hasNextPage,
    hasPreviousPage,
    perPage,
    pageCount,
    currentPage,
    nextPage,
    previousPage,
    setPage,
    updateFetch,
    state,
    parsed,
    count,
    downloadUrl: result?.downloadUrl,
    pagination: {
      count: count,
      total: count,
      current: currentPage || 1,
      pageSize: perPage,
      onChange: (page: number) => {
        setPage(page);
      },
    },
  };
};

type ClientState = {
  page: number;
  perPage: number;
  [key: string]: any;
};

export type UsePaginationConfig<
  K extends keyof Methods = 'any', // method key (name)
  Parsed = any,
  R = NonNullable<Unpacked<Items<K>>>[] // return type without promise
> = {
  initial: ClientState;
  itemsFragment: string;
  parseResult: (result: R) => Parsed;
};

// retorna o result.items ignorando null ou undefined
type Items<K extends keyof Methods> = NonNullable<NonNullable<Unpacked<ReturnType<Methods[K]>>['result']>['items']>;
