import React, { useState } from 'react';
import { AccessCPFProps } from './AccessCPF';
import { Input, notification } from 'antd';
import styled from 'styled-components';
import { Button, LoadingIndicator } from '@digi-tim-19/components';
import { useClient } from '../../autogenerated/client/client';
import ScreenLogin from './Screens';

const Wrapper = styled.div`
  width: 100%;
  .ant-input {
    height: 50px;
  }

  .ant-input-password {
    margin: 10px 0 0;
  }

  .ant-radio-wrapper {
    a {
      color: #ffcb00;
    }
  }
`;

export type NewRegisterProps = {
  value: string;
  accessCPFProps: AccessCPFProps;
};

export const NewRegister = ({ value, accessCPFProps }: NewRegisterProps) => {
  const [email, setEmail] = useState('');

  const maskedEmail = useClient('ConsumerPublicUser', {
    variables: { CPF: value },
    fragment: `
      email
      msgWrogEmail
      firstAccess
    `,
    fetchOnMount: true
  });

  const tempPassword = useClient('ConsumerTempPassword');

  const loading = maskedEmail.loading || tempPassword.loading;

  return (
    <Wrapper className="ui-flex NewRegister_Wrapper">
      <p
        style={{ color: 'white' }}
      >{`Bem-vindo(a)! No campo abaixo está seu e-mail cadastrado. Digite-o e clique em “confirmar” para receber uma senha temporária.`}</p>

      <Input
        placeholder={maskedEmail.result?.email ?? ''}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />

      <p style={{ color: 'white', fontSize: '10px' }}>
        Caso o e-mail não esteja correto, entre em contato com o{' '}
        <b>{maskedEmail.result?.msgWrogEmail}</b> para atualizar o cadastro.
      </p>

      <Button
        disabled={!email}
        onClick={() => {
          if (email) {
            tempPassword
              .fetch({
                variables: { CPF: value, email }
              })
              .then((ctx) => {
                if (ctx.errors?.length) {
                  return;
                }

                accessCPFProps.setValue(value);
                accessCPFProps.setScreen(ScreenLogin.NEW_PASSWORD);
                notification.success({
                  message: `Senha enviada com sucesso!`
                });
              })
              .catch(() => {
                notification.error({
                  message: `Não foi possível enviar a senha. Tente novamente.`
                });
              });
          }
        }}
      >
        Confirmar E-mail
      </Button>

      {loading && <LoadingIndicator small color={'white'} />}
    </Wrapper>
  );
};
