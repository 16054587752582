import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { RegulamentoWrapper } from './stylesCadastrar';

export const SistemaVendasCadastrar = () => {
  return (
    <PageTemplate>
      <>
        <RegulamentoWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: 'home' },
              { title: 'Gerenciar conteúdo' },
              { title: 'Sistemas de Vendas' },
              { title: 'Cadastrar' }
            ]}
          />
          <TitleDescription
            iconType={'laptop'}
            title={'Sistemas de Vendas'}
            description={
              'Nesta página constam os links para acessar os sistemas, pós vendas e administrativos.'
            }
          />
        </RegulamentoWrapper>

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
