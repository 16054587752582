import { useState, useEffect } from 'react';
import { useClient } from '../../autogenerated/client/client';
import { SortFindManyPinInput } from '../../autogenerated/client/types';

export const usePin = () => {
  const PinFindMany = useClient('PinFindMany', {
    afterMutate: /^Pin/,
    fetchOnMount: true,
    appendToFragment: appendToFragmentPin
  });

  return PinFindMany;
};

export function useFavoritos(
  userId: string,
  folderId: string,
  sort: SortFindManyPinInput
) {
  const getPin = useClient('PinFindMany');
  const [pins, setPins] = useState<any[]>([]);
  const [recordIds, setRecordIds] = useState<string[]>([]);

  useEffect(() => {
    if (userId && folderId) {
      getPin
        .fetch({
          variables: {
            filter: {
              owner: userId,
              folderId: folderId
            },
            sort: sort,
            limit: 200
          }
        })
        .then((ctx) => {
          if (ctx.result && ctx.result.length) {
            const ids = ctx.result.map((x) => x?.recordId || '');
            setPins(ctx.result);
            setRecordIds(ids);
          }
        });
    }
  }, [userId, folderId, sort]);

  return {
    pins,
    recordIds
  };
}

export function useGetMaterial(ids: string[]) {
  const [materials, setMaterials] = useState<any[]>([]);
  const [isLoadingMaterial, setIsLoadingMaterial] = useState(true);
  const getMaterials = useClient('MaterialFindMany');

  useEffect(() => {
    if (ids.length) {
      getMaterials
        .fetch({
          // cache: true,
          appendToFragment: appendToFragmentMaterial,
          variables: {
            filter: {
              _ids: ids
            },
            limit: 200
          }
        })
        .then((ctx) => {
          if (ctx.result && ctx.result.length) {
            setMaterials(ctx.result);
          }
          setIsLoadingMaterial(false);
        })
        .catch(() => {
          setIsLoadingMaterial(false);
        });
    } else {
      setIsLoadingMaterial(false);
    }
  }, [ids]);

  return {
    materials,
    isLoadingMaterial
  };
}

export function useGetIncentiveCampaign(ids: string[]) {
  const [incentiveCampaigns, setIncentiveCampaigns] = useState<any[]>([]);
  const [isLoadingIncentiveCampaign, setIsLoadingIncentiveCampaign] =
    useState(true);
  const getIncentiveCampaigns = useClient('IncentiveCampaignFindMany');

  useEffect(() => {
    if (ids.length) {
      getIncentiveCampaigns
        .fetch({
          appendToFragment: appendToFragmentIncentiveCampaign,
          variables: {
            filter: {
              _ids: ids
            },
            limit: 200
          }
        })
        .then((ctx) => {
          if (ctx.result && ctx.result.length) {
            setIncentiveCampaigns(ctx.result);
          }
          setIsLoadingIncentiveCampaign(false);
        })
        .catch(() => {
          setIsLoadingIncentiveCampaign(false);
        });
    } else {
      setIsLoadingIncentiveCampaign(false);
    }
  }, [ids]);

  return {
    incentiveCampaigns,
    isLoadingIncentiveCampaign
  };
}

const appendToFragmentPin = `
folder {
  _id
  name
  createdAt
}
material {
  _id
}
campaign {
  _id
}`;

const appendToFragmentMaterial = `
categories {
  name
  parentCategory {
    _id
    name
    parentCategory {
      _id
      name
    }
  }
}
communicationItemsConfig{
  kind
  templateName
}
files {
  _id
  signedUrl
  downloadUrl
  extension
  kind
}
`;

const appendToFragmentIncentiveCampaign = `
cardImageFile {
  signedUrl
  downloadUrl
}
validity {
  start
  end
}
status {
  _id
  label
  value
}`;
