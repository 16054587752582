import React, { useEffect } from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageWrapper } from '../styleForm';
import { useRouteMatch } from 'react-router';
import { routes } from '../../../../config/routes';
import { PageTemplate } from '../../../../components/Layout/PageTemplate';
import { useClient } from '../../../../autogenerated/client/client';

export const BannerEditar = () => {
  const { params } = useRouteMatch();
  const idBanner = params?.id;

  const getBanner = useClient('BannerFindOne');

  useEffect(() => {
    const variables = { filter: { _id: idBanner } };
    getBanner.fetch({
      appendToFragment: ` textButton images { link fileId display signedUrl }  validity { start end notifyEmailsBeforeExpire } `,
      variables
    });
  }, [idBanner]);

  const configPage = {
    pageName: 'Banners e cards',
    icon: 'file-image',
    description: 'Gerenciamento de banners e cards da home',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo' },
      { title: 'Banners e cards' },
      { title: 'Editar' }
    ]
  };

  return (
    <PageTemplate>
      <>
        <PageWrapper>
          <Breadcrumb items={configPage.breadcrumb} />
          <TitleDescription
            iconType={configPage.icon}
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>

        {getBanner.result && (
          <ProviderSelecionarPublico>
            <Form initialValues={getBanner.result} />
          </ProviderSelecionarPublico>
        )}
      </>
    </PageTemplate>
  );
};
