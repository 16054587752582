import * as React from 'react';
import { Select, verdade } from '@digi-tim-19/components';

import { useChannelFindMany } from '../../hooks/channels/channelFindMany';
import { useRegionFindMany } from '../../hooks/regions/useRegionsFindMany';

export const FilterAuthor = (props: TFilterAuthorProps) => {
  const { channel, region, setChannel, setRegion } = props;

  const ChannelFindMany = useChannelFindMany({
    filter: {},
    sort: '_ID_ASC' as any
  });

  const RegionFindMany = useRegionFindMany({
    filter: {},
    sort: '_ID_ASC' as any
  });

  const loading = RegionFindMany.loading || ChannelFindMany.loading;

  const channels = parseOptions(
    verdade(ChannelFindMany.result),
    loading ? 'carregando...' : 'CANAL DO AUTOR'
  );

  const regions = parseOptions(
    verdade(RegionFindMany.result),
    loading ? 'carregando...' : 'REGIÃO DO AUTOR'
  ).filter((el) => el.value !== 'HQ');

  return (
    <>
      <Select
        placeholder="Canal do autor"
        options={channels}
        onChange={(e) => {
          setChannel(e);
        }}
        value={channel}
      />

      <Select
        placeholder="Região do autor"
        options={regions}
        onChange={(e) => {
          setRegion(e);
        }}
        value={region}
      />
    </>
  );
};

function parseOptions(items: any[], placeholder = '') {
  return [{ label: placeholder, value: '' }].concat(
    items.map((el) => ({
      label: el.name || '',
      value: el._id || ''
    }))
  );
}

export type TFilterAuthorProps = {
  channel: string;
  region: string;
  setChannel: (channel: string) => any;
  setRegion: (region: string) => any;
};
