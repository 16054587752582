import React, { useState, useEffect } from 'react';
import {
  Breadcrumb,
  TitleDescription,
  ProviderXlsxJson
} from '@digi-tim-19/components';
import { useRouteMatch } from 'react-router';

import { IncentiveCampaignWrapper } from '../Cadastrar/styles';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { ArquivosCampanhaForm } from './ArquivosCampanhaForm';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { EnumIncentiveCampaignStatus } from '../../../autogenerated/client/types';

export const ArquivosCampanhaCadastrar = () => {
  const {
    params: { idCampaign }
  } = useRouteMatch();

  const [statusCampaign, setStatusCampaign] =
    useState<EnumIncentiveCampaignStatus>(
      EnumIncentiveCampaignStatus.InVerification
    );

  const getCampaign = useClient('IncentiveCampaignFindById', {
    fetchOnMount: true,
    appendToFragment: `
      status {
        value
      }`,
    variables: {
      _id: idCampaign
    }
  });

  useEffect(() => {
    if (getCampaign.result)
      setStatusCampaign(
        getCampaign.result.status?.value || EnumIncentiveCampaignStatus.Closed
      );
  }, [getCampaign.result]);

  return (
    <PageTemplate>
      <IncentiveCampaignWrapper>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo' },
            { title: 'Campanhas de Incentivo' },
            { title: 'Editar arquivos de Campanha de incentivo' },
            { title: 'Cadastrar' }
          ]}
        />
        <TitleDescription
          iconType="trophy"
          title="Cadastrar arquivos de campanhas de incentivo"
          description={'Cadastramento de arquivos para campanhas de incentivo.'}
        />

        <ProviderXlsxJson>
          <ArquivosCampanhaForm statusCampaign={statusCampaign} />
        </ProviderXlsxJson>
      </IncentiveCampaignWrapper>
    </PageTemplate>
  );
};
