import React from 'react';
import { TableActions } from '@digi-tim-19/components';
import { DataKey } from '../../OnTube/Listar/columns';
import { Icon, Switch } from 'antd';

export const ListUsersTableColumnsConfig = {
  matricula: {
    key: 'matricula',
    title: 'Matricula'
  },
  nome: {
    key: 'nome',
    title: 'Nome'
  },
  CPF: {
    key: 'CPF',
    title: 'CPF'
  },
  channel: {
    key: 'channel',
    title: 'Canal'
  },
  region: {
    key: 'region',
    title: 'Regional'
  },
  actions: {
    title: 'Editar',
    key: 'action',
    render: (text: DataKey, record: any) => {
      console.log(record);
      return !record.disabled ? <TableActions data={record} /> : '';
    }
  },
  blockedUser: {
    title: 'Bloqueado de comentar',
    key: 'blockedComments',
    render: (_text: DataKey, record: any) => {
      return !record.disabled ? (
        <Switch
          disabled={record.disabledSwitchBlockUser}
          checked={record.blockedCommenting}
          onClick={() => record.onBlockComment(record)}
        />
      ) : (
        ''
      );
    }
  },
  viewBlocks: {
    title: 'Bloqueios',
    key: 'viewBlocks',
    render: (_text: DataKey, record: any) => (
      <Icon type="history" onClick={() => record.onHistory()} />
    )
  }
};

export const columns = Object.values(ListUsersTableColumnsConfig).map(
  (col) => ({
    title: col.title,
    dataIndex: col.key,
    key: col.key,
    render: (col as any).render
  })
);
