import React, { useState } from 'react';
import { LoadingIndicator, SuperBanner, SuperBannerConfig, TypeCard } from '@digi-tim-19/components';
import { useClient } from '../../autogenerated/client/client';
import { useHistory } from 'react-router';
import { EnumBannerImagesDisplay } from '@digi-tim-19/components/build/main/DataProviders/types';
import styled from 'styled-components';
import { Button, Empty, Modal } from 'antd';
import { EnumAnalyticsParentKind, ExternalCampaign, SortFindManyBannerInput } from '../../autogenerated/client/types';
import { usePagination } from '../../hooks/usePagination';
import moment from 'moment';

const Void = styled.div.attrs({ className: 'ui-flex Banner_Void' })`
  width: 100%;
  min-height: 450px;
  align-items: center;
  justify-content: center;
`;

export const Banner = () => {
  const history = useHistory();
  const analitcsPageView = useClient('AnalyticsPageView');
  const acceptExternalCampaign = useClient('AcceptExternalCampaign');
  const [data, setData] = useState<ExternalCampaign>();
  const [isCampaignEnded, setIsCampaignEnded] = useState(false);

  const { parsed, loading } = usePagination('BannerPagination', {
    initial: {
      page: 1,
      perPage: 100,
      sort: SortFindManyBannerInput.SortAsc,
      filter: { position: TypeCard.BANNER.toString() },
    },
    cache: true,
    fetchOnMount: true,
    itemsFragment: `
      images { link fileId display signedUrl }
      validity { start end }
      externalCampaign
      link
      externalData { _id pendingAccept hasCNPJ redirectURL regulationFile { signedUrl } endDate }
    `,
    parseResult: (data) =>
      data
        .filter((banner) => {
          const currentDate = moment();
          const endDate = moment(banner.externalData?.endDate);
          const hideBanner = banner.externalData && banner.externalData.pendingAccept && currentDate.isAfter(endDate);
          return !hideBanner;
        })
        .map((banner) => {
          const config: SuperBannerConfig = {
            urlImgDesktop:
              banner.images?.find((item) => item?.display === EnumBannerImagesDisplay.Desktop)?.signedUrl ?? '',
            urlImgMobile:
              banner.images?.find((item) => item?.display === EnumBannerImagesDisplay.Mobile)?.signedUrl ?? '',
            backgroundColor: '#fff',
            title: '',
            detailTitle: '',
            titleFinal: '',
            description: '',
            textButton: banner.textButton ?? '',
            hasOnClick: !!banner.link || !!banner.externalCampaign,
            functionButton: () => {
              const currentDate = moment();
              const endDate = moment(banner.externalData?.endDate);

              if (!banner.externalData && banner.link) {
                if (banner.link.indexOf('https://') === 0 || banner.link.indexOf('http://') === 0) {
                  window.open(banner.link, '_blank');
                } else {
                  history.push(banner.link);
                }

                analitcsPageView.fetch({
                  variables: {
                    title: 'Link Externo',
                    externalLink: banner.link,
                    kind: EnumAnalyticsParentKind.ExternalPageView,
                  },
                });
              }

              if (banner.externalData && banner.externalData.pendingAccept) {
                if (currentDate.isAfter(endDate)) {
                  setIsCampaignEnded(true);
                  return;
                }
                setData(banner.externalData);
              }

              if (banner.externalData && !banner.externalData.pendingAccept) {
                window.open(banner.externalData.redirectURL, '_blank');
              }
            },
          };
          return config;
        }),
  });

  if (loading) {
    return <LoadingIndicator margin small />;
  }

  if (parsed.length === 0) {
    return (
      <Void>
        <Empty description={'Nenhum Banner cadastrado'} />
      </Void>
    );
  }

  return (
    <>
      <Modal
        title="Campanha Encerrada"
        visible={isCampaignEnded}
        onCancel={() => setIsCampaignEnded(false)}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p>A campanha já foi encerrada.</p>
      </Modal>

      <Modal
        title="Regulamento"
        visible={!!data}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setData(undefined)}
      >
        {data?.hasCNPJ ? (
          <>
            <div style={{ height: '400px', overflow: 'hidden' }}>
              <iframe
                style={{ width: '100%', height: '100%' }}
                id={data?.regulationFile?.signedUrl ?? ''}
                src={data?.regulationFile?.signedUrl ?? ''}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '12px' }}>
              <Button
                onClick={() => {
                  let a = document.createElement('a');
                  a.href = data?.regulationFile?.signedUrl ?? '';
                  a.download = data?.regulationFile?.signedUrl ?? '';
                  a.click();
                }}
              >
                Download
              </Button>
              <Button onClick={() => setData(undefined)}>Cancelar</Button>
              <Button
                onClick={() => {
                  acceptExternalCampaign
                    .fetch({
                      variables: {
                        externalTerm: data?._id,
                      },
                    })
                    .then(() => {
                      setData(undefined);
                      window.open(data?.redirectURL, '_blank');
                    });
                }}
              >
                Concordo com os termos
              </Button>
            </div>
          </>
        ) : (
          <>
            <div style={{ height: '400px', overflow: 'hidden' }}>
              <p>No momento os dados necessários para participar da campanha ainda não estão atualizados no portal.</p>
              <p>Este problema deve se resolver em até 24h, caso contrário entre em contato com o SAC.</p>
            </div>
          </>
        )}
      </Modal>
      <SuperBanner className="supper-banner" items={parsed} />
    </>
  );
};
