import {
  makeField,
  TemplateEmailConsumer01,
  TemplateEmailConsumer02,
  TemplateEmailConsumer03,
  TemplateEmailConsumer04,
  TemplateEmailConsumer05,
  useField
} from '@digi-tim-19/components';
import React from 'react';

export const TemplateFields = () => {
  const HIDE_BUTTON = useField('templateProps.HIDE_BUTTON').input.value;
  const BUTTON_TITLE = useField('templateProps.BUTTON_TITLE').input.value || '';
  const BUTTON_LINK = useField('templateProps.BUTTON_LINK').input.value || '';

  const templateName = useField('templateName').input.value;
  const [hideButton, setHideButton] = React.useState(!!HIDE_BUTTON);

  const templateProps = React.useMemo(() => {
    return {
      headerLogo: '/defaultImages/template-header-consumer.png',
      footerLogo: '/defaultImages/template-footer-consumer.png',
      defaultProps: {},
      hideButton: hideButton
    };
  }, [hideButton]);

  const child = React.useMemo(() => {
    return makeField({
      key: 'templateFields',
      field: {
        list: [
          {
            custom: (
              <h4 style={{ width: '100%', margin: '0' }}>
                Selecione o template de email:
              </h4>
            )
          },
          templateNameField,
          {
            name: 'template',
            label: ' ',
            custom: {
              render: function () {
                switch (templateName) {
                  case 'templateConsumer01':
                    return <TemplateEmailConsumer01 {...templateProps} />;
                  case 'templateConsumer02':
                    return <TemplateEmailConsumer02 {...templateProps} />;
                  case 'templateConsumer03':
                    return <TemplateEmailConsumer03 {...templateProps} />;
                  case 'templateConsumer04':
                    return <TemplateEmailConsumer04 {...templateProps} />;
                  case 'templateConsumer05':
                    return <TemplateEmailConsumer05 {...templateProps} />;
                }

                return null;
              }
            }
          },

          templateName &&
            templateName !== 'templateConsumer01' && {
              inline: true,
              list: [
                {
                  initialValue: !!HIDE_BUTTON,
                  name: 'templateProps.HIDE_BUTTON',
                  label: 'Ocultar botão?',
                  options: [
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false }
                  ],
                  afterChange: (v: boolean) => setHideButton(v)
                },
                {
                  name: 'templateProps.BUTTON_TITLE',
                  label: 'Titulo do botão',
                  defaultValue: BUTTON_TITLE || 'Clique aqui',
                  disabled: hideButton
                },
                {
                  name: 'templateProps.BUTTON_LINK',
                  label: 'Link do botão',
                  defaultValue: BUTTON_LINK || 'https://',
                  disabled: hideButton
                }
              ]
            },
          templateName &&
            templateName === 'templateConsumer01' && {
              inline: true,
              list: [
                {
                  initialValue: HIDE_BUTTON,
                  name: 'templateProps.HIDE_BUTTON',
                  label: 'Possui link?', //Lógica invertida do HIDE_BUTTON para melhor entendimento do usuário
                  options: [
                    { label: 'Sim', value: false },
                    { label: 'Não', value: true }
                  ],
                  afterChange: (v: boolean) => setHideButton(v)
                },
                {
                  name: 'templateProps.BUTTON_LINK',
                  label: 'Link da imagem',
                  defaultValue: BUTTON_LINK || 'https://',
                  disabled: hideButton
                }
              ]
            }
        ]
      }
    });
  }, [templateName, templateProps, hideButton]);

  return <>{child}</>;
};

const templateNameField = {
  name: 'templateName',
  label: ' ',
  options: [
    {
      label: '1',
      value: 'templateConsumer01',
      extraProps: {
        image: '/defaultImages/template-consumer-01.png',
        widthImage: '150px'
      }
    },
    {
      label: '2',
      value: 'templateConsumer02',
      extraProps: {
        image: '/defaultImages/template-consumer-02.png',
        widthImage: '150px'
      }
    },
    {
      label: '3',
      value: 'templateConsumer03',
      extraProps: {
        image: '/defaultImages/template-consumer-03.png',
        widthImage: '150px'
      }
    },
    {
      label: '4',
      value: 'templateConsumer04',
      extraProps: {
        image: '/defaultImages/template-consumer-04.png',
        widthImage: '150px'
      }
    },
    {
      label: '5',
      value: 'templateConsumer05',
      extraProps: {
        image: '/defaultImages/template-consumer-05.png',
        widthImage: '150px'
      }
    }
  ],
  type: 'radio',
  required: true
};
