import React from 'react';
import { Breadcrumb, Button, Icon, PDF, useAnalytics, useClient, useCurrentUser } from '@digi-tim-19/components';
import { useHistory, useRouteMatch } from 'react-router';
import { useIncentiveCampaign } from '../../hooks/incentiveCampaign/useIncentiveCampaign';
import {
  EnumAnalyticsParentKind,
  EnumIncentiveCampaignStatus,
  IncentiveCampaign,
} from '../../autogenerated/client/types';
import { routes } from '../../config/routes';
import { PageTitle } from './Components/PageTitle';
import { RenderComponentAsync } from '../../components/Layout/RenderComponentAsync';
import { Modal } from 'antd';

export const CampanhaVigenteRegulamento = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const { refetch: userFetch, loading }: any = useCurrentUser();
  const user = useCurrentUser();
  const history = useHistory();
  const incentiveCampaign = useIncentiveCampaign(params?.id, appendToFragment);
  const apiAnalyticsDownload = useClient('AnalyticsDownloads');
  const mutationAcceptTerm = useClient('UserAcceptTerm', {
    variables: { term: `IncentiveCampaignRegulation-${params?.id}` },
  });
  const participate = useClient('ParticipateCampaign', {
    variables: { campaignId: `${params?.id}` },
  });
  const highlightRemover = useClient('HighlightedRejectedCampaign', {
    variables: { campaignId: `${params?.id}` },
  });
  const analytics = useAnalytics();
  const campaign: IncentiveCampaign = incentiveCampaign.result || ({} as IncentiveCampaign);

  const handleDownload = () => {
    let a = document.createElement('a');
    a.href = campaign?.regulationFile?.signedUrl ?? campaign?.externalData?.regulationFile?.signedUrl ?? '#';
    a.download = campaign?.regulationFile?.signedUrl ?? campaign?.externalData?.regulationFile?.signedUrl ?? '';
    a.target = '_blank';
    a.click();
    const fileId = campaign?.regulationFile?._id ?? campaign.externalData?.regulationFile?._id ?? '';
    apiAnalyticsDownload.fetch({ variables: { fileId } });
  };

  const handleAccept = async () => {
    await mutationAcceptTerm.fetch();
    await participate.fetch();
    await analytics.track({
      event: 'accept_term',
      kind: EnumAnalyticsParentKind.IncentiveCampaign,
      recordId: params?.id,
      entityName: 'IncentiveCampaign',
      singleDoc: true,
    });
    await userFetch();
    Modal.success({
      content: 'Aceite concluído com sucesso.',
      onOk: () => history.push(routes.homePortal.mount()),
    });
  };

  async function handleReject() {
    await highlightRemover.fetch();
    await userFetch();
    Modal.error({
      title: 'Você não aceitou a campanha',
      content:
        'Caso queira aceitar, deverá acessar o regulamento através do menu em "Campanhas de Incentivo" e dar o aceite.',
      onOk: () => history.push(routes.homePortal.mount()),
    });
  }

  const configPage = {
    pageName: 'Campanhas de Incentivo Regulamento',
    icon: 'trophy',
    description: '',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      {
        title: 'Campanhas de Incentivo',
        link: routes.campanhasIncentivoVigentes.mount(),
      },
      {
        title: campaign?.title || '',
      },
      { title: 'Regulamento' },
    ],
  };

  const signedUrl = campaign?.regulationFile?.signedUrl ?? campaign.externalData?.regulationFile?.signedUrl ?? '';

  const isAccept =
    campaign.pendingAccept && campaign.status?.value === EnumIncentiveCampaignStatus.InProgress && !user.isAdmin;

  return (
    <RenderComponentAsync loading={incentiveCampaign.loading}>
      <div style={{ padding: '30px' }}>
        <Breadcrumb items={configPage.breadcrumb} />
        <PageTitle icon={<Icon type="trophy" />} title="CAMPANHAS DE INCENTIVO" />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
          <div style={{ height: '1200px', overflow: 'hidden' }}>
            <iframe style={{ width: '100%', height: '100%' }} id={signedUrl} src={signedUrl} />
          </div>
          <div style={{ display: 'flex', gap: '16px' }}>
            <Button style={{ backgroundColor: '#FFF', color: '#00508C' }} onClick={handleDownload}>
              Download
            </Button>
            <Button style={{ backgroundColor: '#FFF', color: '#00508C' }} onClick={handleReject} loading={loading}>
              Não aceito
            </Button>
            {isAccept && (
              <Button onClick={handleAccept} loading={loading}>
                Concordo com os termos e aceito o regulamento
              </Button>
            )}
          </div>
        </div>
      </div>
    </RenderComponentAsync>
  );
};

const appendToFragment = `
  title
  status {
    _id
    value
  }
  regulationFile {
    _id
    downloadUrl
    signedUrl
  }
  externalData {
    regulationFile {
      _id
      downloadUrl
      signedUrl
    }
  }
`;
