import React, { useState, useMemo, Dispatch, SetStateAction, useEffect } from 'react';
import { Divider } from 'antd';
import { Select } from '@digi-tim-19/components';
import { IncentiveCampaign, IncentiveCampaignData } from '../../autogenerated/client/types';
import { useClient } from '../../autogenerated/client/client';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  CampanhaTitleWrapper,
  CampanhaButtonsContainer,
  CampanhaPeriodTitleWrapper,
  CampanhaSubTitleWrapper,
} from './stylesCampanhaVigente';
import { BateuLevouElegibility, BateuLevouResults } from './Components/BateuLevou';
import { CanvassRangeOfReach } from './Components/Canvass';
import { RankingIncentive } from './Components/Ranking';
import { VendeuGanhou } from './Components/VendeuGanhou';

interface Props {
  campaign: IncentiveCampaign;
  detailData: IncentiveCampaignData[];
  setDetailData: Dispatch<SetStateAction<any>>;
}

type Option = { label: string; value: string };

export const CampanhaVigenteViewMode = ({ campaign, detailData, setDetailData }: Props) => {
  const results = Object.values(detailData)
    .filter((el) => el?.reference)
    .sort((a, b) => (a.reference! < b.reference! ? -1 : 1));
  const [detail, setDetail] = useState<IncentiveCampaignData>(results?.[0]);

  const periodOptions = useMemo(
    () => results?.map((el) => ({ label: moment(el?.reference!).format('MM/YYYY'), value: el?.reference })),
    [],
  );

  const rankingApi = useClient('CampaignGeRanking');

  const isRanking = useMemo(
    () => new Set(['ranking', 'record_de_vendas', 'multi_league']).has(campaign?.campaignType!),
    [],
  );

  useEffect(() => {
    if (!isRanking) return;

    rankingApi.fetch({
      variables: {
        reference: detail?.reference!,
        campaignId: campaign._id,
      },
    });
  }, [detail]);

  return (
    <>
      <span style={{ color: '#00508C', cursor: 'pointer' }} onClick={() => setDetailData(undefined)}>
        Voltar
      </span>
      <CampanhaButtonsContainer>
        <CampanhaTitleWrapper>{campaign.title}</CampanhaTitleWrapper>
        <CampanhaSubTitleWrapper>{detail?.userDetail?.name}</CampanhaSubTitleWrapper>
      </CampanhaButtonsContainer>
      {periodOptions?.length ? (
        <>
          <CampanhaPeriodTitleWrapper>Selecione o período</CampanhaPeriodTitleWrapper>
          <Select
            placeholder="Período"
            onChange={(period) => {
              const detailSelected = results.find((el) => el?.reference === period);
              setDetail(detailSelected!);
            }}
            options={periodOptions as Option[]}
            value={detail.reference!}
          />
        </>
      ) : null}

      <Divider />
      {campaign?.campaignType === 'bateu_levou' && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
          <div>
            {(detail.elegibility ?? []).map((el: any, index: number, data: any) => (
              <BateuLevouElegibility
                key={el.kpiId}
                firstElement={index === 0}
                lastElement={index === data.length - 1}
                points={el.points}
                tend={el.tend}
                userBooster={el.userBooster}
                goal={el.goal}
                result={el.result}
                percent={(el.result / el.goal) * 100}
                description={el.description}
                lastUpdate={campaign?.resultData?.[0]?.updatedAt}
              />
            ))}
          </div>

          <div>
            {(detail.results ?? []).map((el: any, index: number, data: any) => (
              <BateuLevouResults
                key={el.kpiId}
                firstElement={index === 0}
                lastElement={index === data.length - 1}
                points={el.points || detail.points}
                tend={el.tend}
                userBooster={el.userBooster}
                goal={el.goal}
                result={el.result}
                percent={(el.result / el.goal) * 100}
                description={el.description}
                lastUpdate={campaign?.resultData?.[0]?.updatedAt}
              />
            ))}
          </div>
        </div>
      )}

      {campaign?.campaignType === 'canvass' && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
          {detail?.results?.map((el: any) => (
            <CanvassRangeOfReach
              key={el.kpiId}
              faixa1={el.resultados1}
              faixa2={el.resultados2}
              faixa3={el.resultados3}
              points={el.points}
              tend={el.tend}
              userBooster={el.userBooster}
              description={el.description}
              lastUpdate={campaign?.resultData?.[0]?.updatedAt}
              eligibility={el.eligibility}
            />
          ))}
        </div>
      )}

      {isRanking && (
        <RankingIncentive detail={detail} loadingRanking={rankingApi.loading} rankingData={rankingApi?.result || []} />
      )}

      {campaign?.campaignType === 'valoriza' && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
          <div>
            {(detail.elegibility ?? []).map((el: any, index: number, data: any) => (
              <BateuLevouElegibility
                key={el.kpiId}
                firstElement={index === 0}
                lastElement={index === data.length - 1}
                points={el.points}
                tend={el.tend}
                userBooster={el.userBooster}
                goal={el.goal}
                result={el.result}
                percent={(el.result / el.goal) * 100}
                description={el.description}
                lastUpdate={campaign?.resultData?.[0]?.updatedAt}
              />
            ))}
          </div>

          <div>
            {(detail.results ?? []).map((el: any, index: number, data: any) => (
              <BateuLevouResults
                key={el.kpiId}
                firstElement={index === 0}
                lastElement={index === data.length - 1}
                points={el.points || detail.points}
                tend={el.tend}
                userBooster={el.userBooster}
                goal={el.goal}
                result={el.result}
                percent={(el.result / el.goal) * 100}
                description={el.description}
                lastUpdate={campaign?.resultData?.[0]?.updatedAt}
              />
            ))}
          </div>
        </div>
      )}

      {campaign?.campaignType === 'conversao' && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
          <div>
            {(detail.elegibility ?? []).map((el: any, index: number, data: any) => (
              <BateuLevouElegibility
                key={el.kpiId}
                firstElement={index === 0}
                lastElement={index === data.length - 1}
                points={el.points}
                tend={el.tend}
                userBooster={el.userBooster}
                goal={el.goal}
                result={el.result}
                percent={(el.result / el.goal) * 100}
                description={el.description}
                lastUpdate={campaign?.resultData?.[0]?.updatedAt}
              />
            ))}
          </div>

          <div>
            {(detail.results ?? []).map((el: any, index: number, data: any) => (
              <BateuLevouResults
                key={el.kpiId}
                firstElement={index === 0}
                lastElement={index === data.length - 1}
                points={el.points || detail.points}
                tend={el.tend}
                userBooster={el.userBooster}
                goal={el.goal}
                result={el.result}
                percent={(el.result / el.goal) * 100}
                description={el.description}
                lastUpdate={campaign?.resultData?.[0]?.updatedAt}
              />
            ))}
          </div>
        </div>
      )}

      {(campaign?.campaignType === 'vendeu_ganhou' || campaign?.campaignType === 'free_style') && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
          <div>
            <VendeuGanhou details={detail.results ?? []} points={detail.points!} />
          </div>
        </div>
      )}

      {campaign?.campaignType === 'estrutuout' && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
          <div>
            {(detail.results ?? []).map((el: any, index: number, data: any) => (
              <BateuLevouResults
                key={el.kpiId}
                firstElement={index === 0}
                lastElement={index === data.length - 1}
                points={el.points || detail.points}
                tend={el.tend}
                userBooster={el.userBooster}
                goal={el.goal}
                result={el.result}
                percent={(el.result / el.goal) * 100}
                description={el.description}
                lastUpdate={campaign?.resultData?.[0]?.updatedAt}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
