import React from 'react';
import { isEmpty, get } from 'lodash';
import { List, Pagination } from 'antd';
import { Col, ColHeader, HeaderTable, NothingFound, RowTable, TableContainer } from './stylesRankingTable';
import { configRankiingTable } from './configRankingTable';

type DataRow = Record<string, any> & { _id: string };
type PaginationOptions = {
  count: number;
  total: number;
  current: number;
  pageSize: number;
  onChange: (page: number) => void;
};
interface Props {
  data: DataRow[];
  rankingSlice: DataRow[];
  pagination?: PaginationOptions;
}

const RenderRowTable = ({ item, index }: { item: any; index: number }) => {
  return (
    <RowTable style={{ ...configRankiingTable?.body?.style }}>
      {configRankiingTable.columns.map((column) => {
        const value = get(item, column.key);

        return (
          <Col key={'row-body' + column.key + column.id} {...column.style}>
            {column?.renderRowCell ? column.renderRowCell(value, item, index) : <p>{value}</p>}
          </Col>
        );
      })}
    </RowTable>
  );
};

export const RankingTable = (props: Props) => {
  const { data, pagination, rankingSlice } = props;
  if (!data?.length) return <NothingFound>Não existe nenhum ranking disponível</NothingFound>;

  const showRankingSlice = rankingSlice.length !== 0 && !data.some((el) => el.isSelf);
  return (
    <>
      <TableContainer>
        <HeaderTable style={{ ...configRankiingTable?.header?.style }}>
          {configRankiingTable.columns.map((column) => (
            <ColHeader className="header_desk" key={'row-header' + column.key + column.id} {...column.style}>
              {column.label}
            </ColHeader>
          ))}

          <ColHeader className="header_mobile">Ranking</ColHeader>
        </HeaderTable>
        <List dataSource={data} renderItem={(item, index) => <RenderRowTable item={item} index={index} />} />
      </TableContainer>

      {showRankingSlice && (
        <div style={{ width: '100%', marginTop: '32px' }}>
          <List dataSource={rankingSlice} renderItem={(item, index) => <RenderRowTable item={item} index={index} />} />
        </div>
      )}

      {/* {!isEmpty(pagination) && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '32px' }}>
          <Pagination {...pagination} />
        </div>
      )} */}
    </>
  );
};
