import React from 'react';
import { useParams, Redirect } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  LoadingIndicator
} from '@digi-tim-19/components';

import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';

export const OnTubePlaylist = () => {
  const { id } = useParams();

  let filterId = id;
  if (id === 'pre_pago') {
    filterId = 'video_playlist:tim_pre';
  }
  if (id === 'controle') {
    filterId = 'video_playlist:tim_controle';
  }
  if (id === 'pos_pago') {
    filterId = 'video_playlist:tim_black';
  }

  if (id === 'c6_bank') {
    filterId = 'video_playlist:tim___c6_bank';
  }

  if (id === 'ampli') {
    filterId = 'video_playlist:tim___ampli';
  }

  if (
    id === 'tim_fixo' ||
    id === 'tim_live_internet' ||
    id === 'tim_ultrafibra'
  ) {
    filterId = 'video_playlist:residencial';
  }

  if (id === 'regional') {
    filterId = 'video_playlist:regionais';
  }

  const playlist = useClient('CategoryFindById', {
    variables: {
      _id: filterId
    },
    fetchOnMount: true,
    appendToFragment: `
    materials(
      kind: video
      sort: CREATEDAT_DESC
      page: 1
      perPage: 500
      ) {
        items {
          _id
          title
          createdAt
        }
      }
      `
  });

  const playlistVideos = playlist.result?.materials?.items;
  const videoID = (playlistVideos || []).find((el) => el)?._id;

  return (
    <PageTemplate>
      <>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Ontube', link: routes.OnTube.mount() },
            { title: 'Vídeo' }
          ]}
        />

        <TitleDescription
          iconType="play-square"
          title="OnTube"
          description={
            'Seu canal de vídeos com todas as informações que você precisa. \n Encontre o que deseja com filtros de busca, comente, curta, compartilhe e muito mais.'
          }
        />

        {playlist.loading ? (
          <LoadingIndicator />
        ) : videoID ? (
          <Redirect to={routes.OnTubePlayer.mount(videoID)} />
        ) : (
          <Redirect to={routes.OnTube.mount()} />
        )}
      </>
    </PageTemplate>
  );
};
