import { useState, useEffect } from 'react';
import { useClient } from '@digi-tim-19/components';
import { formatDate } from '@digi-tim-19/utils/build';
import { groupBy } from 'lodash';
import {
  SortFindManyUserCampaignInput,
  EnumIncentiveCampaignCampaignType
} from '../../autogenerated/client/types';

export function useMetas(incentiveCampaignId: string, userId: string) {
  const variables = {
    filter: {
      userId,
      incentiveCampaignId
    },
    sort: SortFindManyUserCampaignInput.CreatedatAsc,
    limit: 100
  };

  const userCampaign = useClient('UserCampaignFindMany');
  const [goalsUser, setGoalsUser] = useState<any[]>([]);
  const [goalsUserSpeeder, setGoalsUserSpeeder] = useState<any[]>([]);
  const [datesChart, setDatesChart] = useState<string[]>(['1']);
  const [showSpeeder, setShowSpeeder] = useState(false);
  const [series, setSeries] = useState<any[]>([]);
  const [maxValue, setMaxValue] = useState(100);

  useEffect(() => {
    if (incentiveCampaignId && userId) {
      userCampaign
        .fetch({
          appendToFragment: appendToFragmentUserCampaign,
          variables
        })
        .then((ctx) => {
          if (ctx.result && ctx.result.length) {
            const resultGoals: any[] = [];
            const dates: string[] = [];

            ctx.result.forEach((item: any) => {
              item?.fileStructure?.goals?.forEach((goal: any) => {
                resultGoals.push(goal);
              });
              dates.push(formatDate(item?.createdAt, `DD/MM`));
            });

            setGoalsUser(resultGoals);
            setDatesChart(dates);

            const lengthResult = ctx.result.length;
            const lastUserCampaing = ctx.result[lengthResult - 1];

            if (lastUserCampaing?.fileStructure?.goals) {
              setGoalsUserSpeeder(lastUserCampaing?.fileStructure?.goals || []);
              if (
                lastUserCampaing?.IncentiveCampaign?.campaignType ===
                EnumIncentiveCampaignCampaignType.Ranking
              )
                setShowSpeeder(true);
            }
          }
        });
    }
  }, [incentiveCampaignId, userId]);

  useEffect(() => {
    const grouped = groupBy(goalsUser, 'name');

    let preSeries: any[] = [];
    let maxValueTemp = 0;

    Object.keys(grouped).forEach((key) => {
      let data: any[] = [];
      let reachedCurrent = 0;

      grouped[key].forEach((item) => {
        data.push((item.reached - reachedCurrent).toString());
        reachedCurrent = item.reached;

        if (reachedCurrent > maxValueTemp) maxValueTemp = reachedCurrent;
      });

      preSeries.push({
        data: ['0', ...data],
        name: key
      });
    });

    setMaxValue(maxValueTemp);
    setSeries(preSeries);
  }, [goalsUser]);

  return {
    goalsUser,
    goalsUserSpeeder,
    datesChart,
    showSpeeder,
    series,
    maxValue
  };
}

const appendToFragmentUserCampaign = `
User {
  name
}
IncentiveCampaign {
  campaignType
}
fileStructure {
  goals {
    name
    goal
    reached
  }
}`;
