import styled from 'styled-components';
import { Input } from 'antd';
const { Search: AntdSearch } = Input;

export const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

export const PageContent = styled.div`
  .actions {
    display: block;
    & > * {
      width: 100%;
      margin-left: 0;
      margin-bottom: 5px;
    }
    @media screen and (min-width: 1024px) {
      display: flex;
      align-items: center;
      & > * {
        margin-right: 40px;
      }
    }
  }
`;
export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;

  @media screen and (min-width: 768px) {
    justify-content: left;
  }
`;
export const CurrentEditionWrapper = styled.div`
  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
`;
export const PreviousEditionsWrapper = styled.div`
  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
`;

export const TitleEdition = styled.div`
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  font-family: TimBold;
`;
export const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;

  .ant-calendar-range-picker-separator {
    display: none !important;
  }
  @media only screen and (max-width: 900px) {
    .ant-calendar-picker-container {
      width: 100%;
      padding: 0 15px;
    }
    .ant-calendar-range {
      width: 100% !important;
    }
    .ant-calendar-date-panel {
      display: flex !important;
      flex-direction: column !important;
      .ant-calendar-range-part {
        width: 100%;
      }
    }
  }
`;

export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;

  cursor: pointer;
`;
