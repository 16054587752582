import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { Breadcrumb, JoystickIconSVG, LoadingIndicator, Table, TitleDescription } from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { Button, CleanButton, ContainerIcon, FilterContainer, NotificationsWrapper, Search } from './styles';
import { columns } from './columns';
import { useClient } from '../../../autogenerated/client/client';
import { Challenge, SortFindManyChallengeInput } from '../../../autogenerated/client/types';
import { useHistory } from 'react-router';
import { message } from 'antd';
import { escapeStringRegexp } from '../../../utils/formatters';

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    challengeNumericId
    title
    action
    kind
    category
    startDate
    endDate
    typePayment
    amountChips
    amountPoints
    createdAt
    updatedAt
    updatedBy {
      name
    }
    owner {
      name
    }
    typeChallenge
  }
`;

export const GameficationList = () => {
  const history = useHistory();
  const getChallenges = useClient('ChallengePagination');
  const ChallengeDeleteOne = useClient('ChallengeRemoveOne');
  const [page, setPage] = React.useState(1);
  const [filterSearch, setFilterSearch] = React.useState();
  const [data, setData] = React.useState();
  const perPage = 10;
  const total = getChallenges?.result?.pageInfo?.itemCount || 0;

  const resetFilters = () => {
    setFilterSearch(undefined);
    setPage(1);
  };

  const handleGetChallenges = (filterSearch: any) => {
    getChallenges
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
          },
          sort: SortFindManyChallengeInput.CreatedatDesc,
        },
      })
      .then((response: any) => {
        setData(
          response?.result?.items?.map((challengeEl: Partial<Challenge>) => {
            return {
              ...challengeEl,
              hideOnRead: true,
              onEdit: () => {
                history.push(routes.gameficationEdit.mount(challengeEl?._id));
              },
              onDuplicate: () => {
                history.push(routes.gameficationEdit.mount(challengeEl?._id), { duplicate: true });
              },
              onDelete: () => {
                ChallengeDeleteOne.fetch({
                  variables: { filter: { _id: challengeEl?._id } },
                }).then((_: any) => {
                  handleGetChallenges(filterSearch);
                  message.success('Desafio deletado com sucesso.');
                });
              },
            };
          }),
        );
      });
  };

  React.useEffect(() => {
    handleGetChallenges(filterSearch);
  }, [filterSearch, page]);

  return (
    <PageTemplate>
      <React.Fragment>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Gamificação' },
              { title: 'Listar' },
            ]}
          />
          <TitleDescription
            renderCustomIcon={
              <ContainerIcon>
                <JoystickIconSVG />
              </ContainerIcon>
            }
            title="Gamificação (Desafios)"
            description="Listar e consultar os desafios da Gamificação"
          />
        </NotificationsWrapper>
        <FilterContainer>
          <Button to={routes.gameficationCreate.mount()}>CADASTRAR DESAFIO</Button>
          <Search
            placeholder="Busca por título"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e: any) => {
              setFilterSearch(e.target.value);
              setPage(1);
            }}
          />

          <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
        </FilterContainer>
        {getChallenges.loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e: any) => setPage(e),
            }}
          />
        )}
      </React.Fragment>
    </PageTemplate>
  );
};
