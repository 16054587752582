import React from 'react';
import { get } from 'lodash';
import { Col, ColHeader, HeaderTable, NothingFound, RowTable, TableContainer } from './stylesRankingTable';
import { configRankiingTable } from './configRankingTable';
import { IncentiveCampaignData } from '../../../autogenerated/client/types';
import { isEqual } from 'lodash';

type DataRow = Record<string, any>;

interface Props {
  data: DataRow[];
  myData: IncentiveCampaignData;
}

const RenderRowTable = ({ item, index }: { item: any; index?: number }) => {
  return (
    <RowTable style={{ ...configRankiingTable?.body?.style }}>
      {configRankiingTable.columns.map((column) => {
        const value = get(item, column.key);

        return (
          <Col key={'row-body' + column.key + column.id} {...column.style}>
            {column?.renderRowCell ? column.renderRowCell(value, item, index) : <p>{value}</p>}
          </Col>
        );
      })}
    </RowTable>
  );
};

export const RankingIncentivo = (props: Props) => {
  const { data } = props;
  if (!data?.length) return <NothingFound>Não existe nenhum ranking disponível</NothingFound>;

  const indexMyPosition = props.data.findIndex((el) => el.userId === props.myData.userId);

  if (indexMyPosition === -1) return <NothingFound>Não existe nenhum ranking disponível</NothingFound>;
  const currentPosition = props.data[indexMyPosition];
  const firstPosition = indexMyPosition > 1 && props.data[indexMyPosition - 1];
  const lastPosition = indexMyPosition <= props.data?.length - 1 && props.data[indexMyPosition + 1];

  return (
    <>
      <TableContainer>
        <HeaderTable style={{ ...configRankiingTable?.header?.style }}>
          {configRankiingTable.columns.map((column) => (
            <ColHeader className="header_desk" key={'row-header' + column.key + column.id} {...column.style}>
              {column.label}
            </ColHeader>
          ))}

          <ColHeader className="header_mobile">Ranking</ColHeader>
        </HeaderTable>
        {firstPosition && (
          <RenderRowTable
            item={{ ...firstPosition, userDetail: { ...firstPosition.userDetail, name: '------------------' } }}
          />
        )}
        <RenderRowTable item={currentPosition} />
        {lastPosition && (
          <RenderRowTable
            item={{ ...lastPosition, userDetail: { ...lastPosition.userDetail, name: '------------------' } }}
          />
        )}
      </TableContainer>
    </>
  );
};
