import * as React from 'react';
import styled from 'styled-components';

const ChangedByWrapper = styled.div`
  width: 100%;
  > span {
    display: block;
    width: 100%;
  }
`;

export function changedByColumn() {
  return {
    title: 'Edição',
    dataIndex: 'changedBy',
    key: 'changedBy',
    render(data: any) {
      if (!data || typeof data !== 'object') return '-';
      return (
        <ChangedByWrapper>
          <span>{data.name}</span>
          <span>{data.dateText}</span>
        </ChangedByWrapper>
      );
    }
  };
}
