import { EnumMaterialKind } from '../autogenerated/client/types';
import { routes } from '../config/routes';

export const findCorrectName = (productRoute: string) => {
  const correctNames = [
    {
      name: 'cardapio_de_ofertas',
      normalizedName: 'Cardápio de Ofertas',
    },
    {
      name: 'residencial',
      normalizedName: 'Residencial',
    },
    {
      name: 'planos_corporativos',
      normalizedName: 'Planos Corporativos',
    },
    {
      name: 'cardapio_smb',
      normalizedName: 'Cardápio SMB',
    },
    {
      name: 'pre_pago',
      normalizedName: 'Pré-Pago',
    },
    {
      name: 'pos_pago',
      normalizedName: 'Pós-Pago',
    },
    {
      name: 'controle',
      normalizedName: 'Controle',
    },
    {
      name: 'tim_fixo',
      normalizedName: 'TIM Fixo',
    },
    {
      name: 'fixo',
      normalizedName: 'Fixo',
    },
    {
      name: 'tim_live_internet',
      normalizedName: 'TIM Live Internet',
    },
    {
      name: 'tim_ultrafibra',
      normalizedName: 'TIM Ultra Fibra',
    },
    {
      name: 'tim_empresa_digital',
      normalizedName: 'TIM Empresa Digital',
    },
    {
      name: 'tim_black_empresa__',
      normalizedName: 'TIM Black Empresa +',
    },
    {
      name: 'voz',
      normalizedName: 'Voz',
    },
    {
      name: 'internet',
      normalizedName: 'Internet',
    },
    {
      name: 'servicos',
      normalizedName: 'Serviços',
    },
    {
      name: 'solucoes',
      normalizedName: 'Soluções',
    },
    {
      name: 'para_o_cliente',
      normalizedName: 'Para o Cliente',
    },
    {
      name: 'vendas',
      normalizedName: 'Vendas',
    },
    {
      name: 'pos_vendas',
      normalizedName: 'Pós-Vendas',
    },
    {
      name: 'administrativo',
      normalizedName: 'Administrativo',
    },
    {
      name: 'administrativo',
      normalizedName: 'Administrativo',
    },
    {
      name: 'hdc_op__comerciais',
      normalizedName: 'HDC/OP. Comerciais',
    },
    {
      name: 'pedidos',
      normalizedName: 'Pedidos',
    },
    {
      name: 'suporte',
      normalizedName: 'Suporte',
    },
    {
      name: 'outros_documentos',
      normalizedName: 'Outros documentos',
    },
    {
      name: 'mapa_de_pecas',
      normalizedName: 'Mapa de peças',
    },
    {
      name: 'nota_fiscal',
      normalizedName: 'Nota Fiscal',
    },
    {
      name: 'abastecimento',
      normalizedName: 'Abastecimento',
    },
    {
      name: 'produtos',
      normalizedName: 'Produtos',
    },
    {
      name: 'processos',
      normalizedName: 'Processos',
    },
    {
      name: 'tim_tech',
      normalizedName: 'Tim Tech',
    },
    {
      name: 'veja_mais',
      normalizedName: 'Veja mais',
    },
    {
      name: 'outros',
      normalizedName: 'Outros',
    },
    {
      name: 'c6_bank',
      normalizedName: 'C6 Bank',
    },
    {
      name: 'pilulas',
      normalizedName: 'Pílulas',
    },
    {
      name: 'comunicacao_para_o_canal',
      normalizedName: 'Comunicação para o Canal',
    },
    {
      name: 'materiais_para_o_pdv',
      normalizedName: 'Materiais para o PDV',
    },
    {
      name: 'matinais',
      normalizedName: 'Matinais',
    },
    {
      name: 'ampli',
      normalizedName: 'Ampli',
    },
    {
      name: 'mapa_de_pdv',
      normalizedName: 'Mapa de PDV',
    },
    {
      name: 'planograma',
      normalizedName: 'Planograma',
    },
    {
      name: 'pecas_de_pdv',
      normalizedName: 'Peças de PDV',
    },
    {
      name: 'guia_de_bolso',
      normalizedName: 'Guia de Bolso',
    },
    {
      name: 'materiais_pdv_gerenciar',
      normalizedName: 'Materiais para o PDV',
    },
    {
      name: 'compare_there',
      normalizedName: 'Compara Aí',
    },
  ];
  let name = '';
  correctNames.map((product) => {
    if (product.name === productRoute) {
      name = product.normalizedName;
    }
  });
  return name;
};

export const findCorrectKind = (kind: string) => {
  switch (kind) {
    case 'pilulas':
      return EnumMaterialKind.Pilulas;
    case 'matinais':
      return EnumMaterialKind.Matinais;
    case 'comunicacao_para_o_canal':
      return EnumMaterialKind.ComunicacaoParaOCanal;
    case 'materiais_para_o_pdv':
      return EnumMaterialKind.MateriaisParaOPdv;
    case 'mapa_de_pdv':
      return EnumMaterialKind.PdvMap;
    case 'planograma':
      return EnumMaterialKind.Planogram;
    case 'pecas_de_pdv':
      return EnumMaterialKind.PdvPieces;
    case 'guia_de_bolso':
      return EnumMaterialKind.PocketGuide;
    case 'tabela-de-preco':
      return EnumMaterialKind.PriceTable;
    default:
      return EnumMaterialKind.Pilulas;
  }
};

export const findRedirect = (productRoute: string) => {
  const routesMap = new Map([['tim_tech', routes.timTech]]);

  const routeRedirect = routesMap.get(productRoute)?.mount();

  return routeRedirect;
};
