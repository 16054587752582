import React, { useState } from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  TitleDescription,
  LoadingIndicator,
  Modal,
  Button,
  Select,
  JoystickIconSVG,
  useCurrentUser,
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { RankingTable } from '../RankingTable/RankingTable';
import styled from 'styled-components';
import { useRanking } from './useRanking';
import { DatePicker } from 'antd';
import { ContainerIcon } from '../../Gamefication/List/styles';
import moment from 'moment';
import { useClient } from '../../../autogenerated/client/client';
import { downloadXLSX } from '../../../utils/downloadXlsx';

const FilterButton = styled.button`
  border: none;
  background-color: #dc3545;
  color: white;
  cursor: pointer;
  padding: 4px 20px;
  border-radius: 8px;
`;

export const configPage = {
  pageName: 'Ranking',
  icon: 'trophy',
  description: '',
  breadcrumb: [{ title: 'Home', link: routes.home.mount() }, { title: 'Gamificação' }, { title: 'Ranking' }],
  perPage: 5,
};

export const GameficationRankingHandler = () => {
  const [showFilters, setShowFilters] = useState(false);
  const user = useCurrentUser();
  
  const handleDownloadRanking = async () => {
    window.location.href = "https://assets-tim-dev-public.s3-accelerate.amazonaws.com/rankingData.xlsx"
  }

  const {
    ranking,
    rankingSlice,
    loading,
    pagination,
    rankinfOrderOptions,
    rankingType,
    setRankingType,
    channels,
    regions,
    levels,
    filter,
    setFilter,
    resetFilters,
  } = useRanking();

  return (
    <PageTemplate>
      <>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription
          renderCustomIcon={
            <ContainerIcon>
              <JoystickIconSVG />
            </ContainerIcon>
          }
          title={configPage.pageName}
          description={configPage.description}
        />

        {showFilters && (
          <Modal
            title="Filtros"
            visible={showFilters}
            onCancel={() => setShowFilters(false)}
            footer={[
              <Button type="primary" onClick={() => resetFilters()}>
                Limpar
              </Button>,
              <Button type="primary" onClick={() => setShowFilters(false)}>
                Fechar
              </Button>,
            ]}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px' }}>
              {/*TODO MES MULTISELECT */}
              <DatePicker value={moment()} mode="month" disabled />

              {/*TODO MULTISELECT */}
              <Select
                value={filter.channel ?? undefined}
                placeholder="Canal"
                options={channels}
                onChange={(e) => setFilter((f) => ({ ...f, channel: Boolean(e) ? e : undefined }))}
              />

              {/*TODO MULTISELECT */}
              <Select
                value={filter.region ?? undefined}
                placeholder="Regional"
                options={regions}
                onChange={(e) => setFilter((f) => ({ ...f, region: Boolean(e) ? e : undefined }))}
              />

              <Select
                value={rankingType}
                placeholder="Critérios de Ordenamento"
                options={rankinfOrderOptions}
                onChange={setRankingType}
              />

              <Select
                value={String(levels.find((level) => level.value === filter.nivel)?.label)} 
                placeholder="Nível"
                options={levels}
                onChange={(e) => setFilter((f) => ({ ...f, nivel: e ? e : undefined  }))}
              />  

              {loading && <LoadingIndicator />}
            </div>
          </Modal>
        )}

        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px 20px', gap: '20px' }}>
          {(!user.loading && user.isAdmin) && <FilterButton onClick={handleDownloadRanking}>DOWNLOAD</FilterButton>}
          <FilterButton onClick={() => setShowFilters(true)}>FILTROS</FilterButton>
        </div>

        {loading && <LoadingIndicator />}

        {!loading && <RankingTable data={ranking} pagination={pagination} rankingSlice={rankingSlice} />}
      </>
    </PageTemplate>
  );
};
