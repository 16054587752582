import React from 'react';
import { TableActions } from '@digi-tim-19/components';
import { changedByColumn } from '../../../components/utils/changedByColumn';

export const columns = [
  {
    title: 'Informativos',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (text: string) => {
      return <p>{text ? text : 'Backup'}</p>;
    }
  },
  {
    title: 'Categoria',
    dataIndex: 'category',
    key: 'category',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Produto',
    dataIndex: 'product',
    key: 'product',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Cadastrada',
    dataIndex: 'cadastrada',
    key: 'cadastrada'
  },
  {
    title: 'Atualizada',
    dataIndex: 'atualizada',
    key: 'atualizada'
  },
  {
    title: 'Data de Envio',
    dataIndex: 'sendDate',
    key: 'sendDate'
  },
  {
    title: 'Criador',
    dataIndex: 'criador',
    key: 'criador'
  },
  changedByColumn(),
  {
    title: 'Ações',
    key: 'action',
    render: (text: DataKey, record: any) => (
      <TableActions
        data={record}
        confirmationMessage={'Deseja excluir esse informativo?'}
      />
    )
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
}
