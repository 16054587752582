import React, { useMemo } from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';

import { Form } from '../Form';
import { useHistory, useRouteMatch } from 'react-router';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { TabelaPrecoWrapper } from './stylesCadastrar';
import { findCorrectName } from '../../../utils/productNames';

export const MapaPDVCadastrar = () => {
  const { params } = useRouteMatch();

  //Acha o nome da categoria corretamente
  const category = useMemo(() => params.category ?? '', [params]);

  const categoryName = useMemo(() => {
    if (category) return findCorrectName(category);

    return '';
  }, [category]);

  //Acha o nome do produto
  const product = useMemo(() => params.product ?? '', [params]);

  const productName = useMemo(() => {
    if (product) return findCorrectName(product);

    return '';
  }, [product]);

  return (
    <PageTemplate>
      <>
        <TabelaPrecoWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: categoryName },
              {
                title: productName,
                link: routes.materiaisPDVListar.mount(
                  params.category ?? '',
                  params.product ?? ''
                )
              },
              { title: 'Cadastrar' }
            ]}
          />
          <TitleDescription
            iconType="more"
            title={productName}
            description="Cadastrar os documentos específicos do Mapa de PDV"
          />
        </TabelaPrecoWrapper>

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
