import bgNiic from './niic.jpg';
import bgInformativos from './informativos.jpg';
import bgArgumentacao from './argumentacao-fdv.jpg';
import bgVideos from './videos.jpg';
import bgProcessos from './processos.jpg';

export const imgResidencial = {
  bgNiic,
  bgInformativos,
  bgArgumentacao,
  bgVideos,
  bgProcessos
};
