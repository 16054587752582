import React, { useEffect, useState, useCallback } from 'react';
import { Breadcrumb, TitleDescription, Table, Select, LoadingIndicator } from '@digi-tim-19/components';
import { message, DatePicker } from 'antd';
import { useHistory } from 'react-router';
import { columns } from './columns';
import { formatDate, dayjs, getRouterByMaterial } from '@digi-tim-19/utils/build';
import { useClient } from '../../../autogenerated/client/client';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { EnumMaterialKind, ValidityEnum, SortFindManyMaterialInput } from '../../../autogenerated/client/types';

import { NotificationsWrapper, FilterContainer, Search, Button, CleanButton } from './stylesListar';
import { routes } from '../../../config/routes';

import moment from 'moment';
import { useFilterAuthor } from '../../../components/FilterAuthor/useFilterAuthor';
import { escapeStringRegexp } from '../../../utils/formatters';

const { RangePicker } = DatePicker;

export const ComparaAiListar: React.FC = () => {
  const filterAuthor = useFilterAuthor();
  const perPage = 20;
  const [createdAt, setCreatedAt] = useState();
  const [items, setItems] = useState([]);
  const [updateTable, setUpdateTable] = useState('');
  const [show, setShow] = useState([]);
  const [filterSearch, setFilterSearch] = useState();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(SortFindManyMaterialInput.IdDesc);
  const [vigenteFilter, setVigenteFilter] = useState<ValidityEnum>(ValidityEnum.Any);
  const [updatedAt, setUpdatedAt] = useState();
  const [showUpdatedAt, setShowUpdatedAt] = useState([]);

  function isVigente(validity: any) {
    const validityEnd = validity ? validity.end : null;

    if (validityEnd) {
      const isPast = dayjs(validityEnd).isBefore(new Date());
      return isPast ? 'Não' : 'Sim';
    } else {
      return 'Não';
    }
  }
  const history = useHistory();

  const MaterialDeleteOne = useClient('MaterialRemoveOne');

  const getMaterials = useClient('MaterialPagination');

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            kind: EnumMaterialKind.CompareThere,
            validityEnum: vigenteFilter,
            includeDrafts: true,
            createdAtRange: createdAt,
            updatedAtRange: updatedAt,
            title: !filterSearch ? undefined : `regex(${escapeStringRegexp(filterSearch)})`,
            filterAuthor: filterAuthor.filter,
            owner: filterAuthor.ownerFilter,
          },
          sort: sort,
        },
      })
      .then((response: any) => {
        setItems(response.result?.items);
      });
  }, [sort, vigenteFilter, createdAt, page, updateTable, filterSearch, filterAuthor.signature, updatedAt]); //eslint-disable-line

  const total = getMaterials.result?.pageInfo.itemCount;

  const resetFilters = () => {
    setSort(SortFindManyMaterialInput.IdDesc);
    setCreatedAt(undefined);
    setFilterSearch(undefined);
    //setFilterData([]);
    setShow([]);
    setVigenteFilter(ValidityEnum.Any);
    setPage(1);
    filterAuthor.reset();
    setUpdatedAt(undefined);
    setShowUpdatedAt([]);
  };

  const data = items?.map((material: any) => ({
    changedBy: material.changedBy,
    key: material?._id,
    id: material?.numericId,
    title: material?.title,
    validity: isVigente(material?.validity),
    cadastrada: formatDate(material?.createdAt, 'DD/MM/YYYY'),
    atualizada: formatDate(material?.updatedAt, 'DD/MM/YYYY'),
    publicada: material.validity?.start ? formatDate(material.validity?.start, 'DD/MM/YYYY') : '-',
    criador: material?.author?.name,
    onRead: () => {
      const routesMaterial = getRouterByMaterial(material, routes);
      history.push(routesMaterial.routerAccess);
    },
    onEdit: () => history.push(routes.comparaAiEditar.mount(material?._id)),
    onDelete: () =>
      MaterialDeleteOne.fetch({
        variables: { filter: { _id: material?._id } },
        afterMutate: /^Material/,
      }).then((ctx) => {
        message.success('Conteúdo deletado com sucesso');
        setUpdateTable(ctx.result?.recordId);
      }),
  }));

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
    },
    [data],
  );
  const onChange = (e: any) => {
    if (e.length > 0) {
      setCreatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString(),
      });
      setShow(e);
    } else {
      setShow([]);
      setCreatedAt(undefined);
    }
  };

  const onChangeUpdatedAt = (e: any) => {
    if (e.length > 0) {
      setUpdatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString(),
      });
      setShowUpdatedAt(e);
    } else {
      setShowUpdatedAt([]);
      setUpdatedAt(undefined);
    }
  };
  return (
    <PageTemplate>
      <>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Compara Aí' },
              { title: 'Listar' },
            ]}
          />
          <TitleDescription iconType="column-width" title="Compara Aí" description="Listar e consultar" />
        </NotificationsWrapper>
        <FilterContainer>
          <Button to={routes.comparaAiCadastrar.path}>CADASTRAR</Button>
          <Select
            placeholder="Ordenar por:"
            options={order}
            onChange={(e) => {
              setSort(e);
              setPage(1);
            }}
            value={sort}
          />
          <Select
            placeholder="Vigente:"
            options={vigente}
            onChange={(e) => {
              setVigenteFilter(e);
              setPage(1);
            }}
            value={vigenteFilter}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format="DD/MM/YYYY"
            placeholder={['PERÍODO', '']}
            onChange={(e) => {
              onChange(e);
              setPage(1);
            }}
            value={show}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={'DD/MM/YYYY'}
            placeholder={['ATUALIZAÇÃO', '']}
            onChange={(e) => {
              setPage(1);
              onChangeUpdatedAt(e);
            }}
            value={showUpdatedAt}
          />
          <Search
            placeholder="Busca título"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e) => {
              onSearch(e.target.value);
              setPage(1);
            }}
          />

          {filterAuthor.render()}

          <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
        </FilterContainer>

        {getMaterials.loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e) => setPage(e),
            }}
          />
        )}
      </>
    </PageTemplate>
  );
};

const order = [
  { label: 'MAIS ANTIGO', value: SortFindManyMaterialInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyMaterialInput.IdDesc },
];
const vigente = [
  { label: 'TODOS', value: ValidityEnum.Any },
  { label: 'VIGENTE', value: ValidityEnum.Current },
  { label: 'NÃO VIGENTE', value: ValidityEnum.Expired },
];

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    changedBy {
      name
      dateText
    }
    files {
      kind
      extension
    }
    fileIds
    _id
    title
    numericId
    createdAt
    updatedAt
    validity { 
      start 
      end 
    }
    author { 
      _id
      name 
    }
  }
`;
