import * as React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

const LivePlayerWrapper = styled.div`
  width: 100%;
`;

const IframeVideoWrapper = styled.div.attrs({
  className: 'LivePlayer_IframeVideoWrapper'
})`
  width: 100%;
`;

export const LivePlayer = (props: TLivePlayerProps) => {
  const { className, url } = props;

  const iframeUrl = React.useMemo(() => {
    const videoId = url?.match(/vimeo.com\/(\d*)\/?/)?.[1] || '';
    return `https://player.vimeo.com/video/${videoId}`;
  }, [url]);

  return (
    <LivePlayerWrapper
      className={cx(className, 'ui-flex LivePlayer LivePlayer_Wrapper')}
    >
      <IframeVideoWrapper
        dangerouslySetInnerHTML={{
          __html: vimeoVideoHTML(iframeUrl)
        }}
      />
    </LivePlayerWrapper>
  );
};

function vimeoVideoHTML(src = '') {
  return `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="${src}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`;
}

export type TLivePlayerProps = {
  className?: string;
  url: string;
  includeChat?: boolean;
};
