import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { Breadcrumb, JoystickIconSVG, LoadingIndicator, Table, TitleDescription } from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { Button, CleanButton, FilterContainer, NotificationsWrapper, Search } from './styles';
import { columns } from './columns';
import { useClient } from '../../../autogenerated/client/client';
import { useHistory } from 'react-router';
import { message } from 'antd';
import { orderBy } from 'lodash';
import { ContainerIcon } from '../../Gamefication/List/styles';
import { escapeStringRegexp } from '../../../utils/formatters';

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    title
    nivel
    amountChips
    createdAt
    updatedAt
  }
`;

export const GameficationNivelList = () => {
  const history = useHistory();
  const getGameficationNivel = useClient('GameficationNivelPagination');
  const GameficationNivelDeleteOne = useClient('GameficationNivelRemoveOne');
  const [page, setPage] = React.useState(1);
  const [filterSearch, setFilterSearch] = React.useState();
  const [data, setData] = React.useState<any>([]);
  const perPage = 10;
  const total = getGameficationNivel?.result?.pageInfo?.itemCount || 0;

  const resetFilters = () => {
    setFilterSearch(undefined);
    setPage(1);
  };

  const handleGetGameficationNivel = (filterSearch: any) => {
    getGameficationNivel
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
          },
        },
      })
      .then((response: any) => {
        const items = response?.result?.items?.map((nivel: any) => {
          return {
            ...nivel,
            hideOnRead: true,
            onEdit: () => {
              history.push(routes.gameficationNivelEdit.mount(nivel?._id));
            },
            onDelete: () => {
              GameficationNivelDeleteOne.fetch({
                variables: { filter: { _id: nivel?._id } },
              }).then((_: any) => {
                handleGetGameficationNivel(filterSearch);
                message.success('Nível deletado com sucesso.');
              });
            },
          };
        });

        setData(orderBy(items || [], ['nivel'], ['asc']));
      });
  };

  React.useEffect(() => {
    handleGetGameficationNivel(filterSearch);
  }, [filterSearch, page]);

  return (
    <PageTemplate>
      <React.Fragment>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Gamificação (Níveis)' },
              { title: 'Listar' },
            ]}
          />
          <TitleDescription
            renderCustomIcon={
              <ContainerIcon>
                <JoystickIconSVG />
              </ContainerIcon>
            }
            title="Gamificação (Níveis)"
            description="Listar e consultar os níveis da Gamificação"
          />
        </NotificationsWrapper>
        <FilterContainer>
          <Button to={routes.gameficationNivelCreate.mount()}>CADASTRAR NÍVEL</Button>
          <Search
            placeholder="Busca por título"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e: any) => {
              setFilterSearch(e.target.value);
              setPage(1);
            }}
          />

          <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
        </FilterContainer>
        {getGameficationNivel.loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e: any) => setPage(e),
            }}
          />
        )}
      </React.Fragment>
    </PageTemplate>
  );
};
