import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  LoadingIndicator,
  useClient,
  Former,
  EnumFileKind,
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import {
  CreateOneExternalCampaignInput,
  EnumExternalCampaignStatus,
  UpdateOneExternalCampaignInput,
} from '../../../autogenerated/client/types';
import { message } from 'antd';

const configPage = {
  pageName: 'Campanhas externas',
  icon: 'trophy',
  description: 'Cadastramento de campanhas externas.',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo' },
    { title: 'Campanhas Externas' },
    { title: 'Campanhas e resultados' },
  ],
  routeList: routes.campanhasIncentivoListar.mount(),
};

export const CampanhaExternaEditar = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const history = useHistory();
  const isUpdate = params?.id !== 'new';

  const externalCampaign = useClient('ExternalCampaignFindById', {
    variables: { _id: params.id },
    fetchOnMount: isUpdate,
  });

  const externalCampaignCreate = useClient('ExternalCampaignCreateOne');
  const externalCampaignUpdate = useClient('ExternalCampaignUpdateOne');

  return (
    <PageTemplate>
      <>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription iconType={configPage.icon} title={configPage.pageName} description={configPage.description} />

        {externalCampaign.loading ? (
          <LoadingIndicator />
        ) : (
          <div style={{ paddingRight: '20px' }}>
            <Former
              initialValues={externalCampaign.result}
              config={() => {
                return {
                  fields: [
                    {
                      name: 'title',
                      label: 'TÍTULO DA CAMPANHA',
                      type: 'text',
                      required: true,
                      validate: (value: any) => {
                        const format = /[#%&;]/;
                        if (format.test(value)) return 'Os caracteres #%&; não são permitidos';

                        if (value) return !(value.length > 110) ? undefined : 'Máximo de 110 caracteres';

                        return undefined;
                      },
                    },
                    {
                      name: 'description',
                      label: 'DESCRIÇÃO DA CAMPANHA',
                      type: 'textarea',
                      required: true,
                      extraProps: {
                        rows: 5,
                        maxLength: 112,
                      },
                      validate: (value: any) => {
                        const format = /[#%&]/;
                        if (format.test(value)) return 'Os caracteres #%& não são permitidos';

                        if (value) return !(value.length > 112) ? undefined : 'Máximo de 112 caracteres';

                        return undefined;
                      },
                    },
                    {
                      inline: true,
                      list: [
                        {
                          name: 'regulation',
                          label: '',
                          type: 'upload',
                          required: true,
                          extraProps: {
                            kind: EnumFileKind.Pdf,
                            CTA: 'REGULAMENTO',
                            allowedExtensions: ['application/pdf'],
                          },
                        },
                        {
                          name: 'invoiceOrigin',
                          label: 'CARTEIRA DE CONCILIAÇÃO',
                          type: 'select',
                          required: true,
                          options: [
                            {
                              label: 'Carteira de conciliação incentiveme',
                              value: 'carteira_incentiveme',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      name: 'externalUrl',
                      label: 'URL DE REDIRECIONAMENTO',
                      type: 'text',
                      required: true,
                      validate: (value: string) => {
                        if (!value.includes('https://')) return 'URL inválida';
                        return undefined;
                      },
                    },
                  ],
                  submitButton: {
                    label: isUpdate ? 'EDITAR' : 'CRIAR',
                  },
                };
              }}
              onSubmit={async (form: any) => {
                const { data } = form;
                if (isUpdate) {
                  const filter = { _id: params.id };
                  const record: UpdateOneExternalCampaignInput = {
                    status: EnumExternalCampaignStatus.InProgress,
                    description: data.description,
                    externalUrl: data.externalUrl,
                    title: data.title,
                    regulation: data.regulation,
                  };

                  externalCampaignUpdate.fetch({ variables: { filter, record } }).then((ctx) => {
                    if (!ctx.errors?.length) {
                      message.success('Campanha externa atualizada com sucesso!');
                      history.goBack();
                    }
                  });
                } else {
                  const record: CreateOneExternalCampaignInput = {
                    status: EnumExternalCampaignStatus.InProgress,
                    description: data.description,
                    externalUrl: data.externalUrl,
                    title: data.title,
                    regulation: data.regulation,
                  };

                  externalCampaignCreate.fetch({ variables: { record } }).then((ctx) => {
                    if (!ctx.errors?.length) {
                      message.success('Campanha externa criada com sucesso!');
                      history.goBack();
                    }
                  });
                }
              }}
            />
          </div>
        )}
      </>
    </PageTemplate>
  );
};
