"use strict";

var _slicedToArray = require("/home/jenkins/tim-consumer/packages/site-consumer/node_modules/@babel/runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var S = __importStar(require("./Styled"));

var antd_1 = require("antd");

var PlacePositivationTypes_1 = require("../PlacePositivationTypes");

var PositivationProvider_1 = require("../PositivationProvider");

exports.CategoryPositivation = function (props) {
  var mode = props.mode,
      title = props.title,
      onDelete = props.onDelete;

  var _react_1$useState = react_1.useState(title),
      _react_1$useState2 = _slicedToArray(_react_1$useState, 2),
      value = _react_1$useState2[0],
      setValue = _react_1$useState2[1];

  var _react_1$useState3 = react_1.useState(mode === PlacePositivationTypes_1.modesPlacePositivation.read_only),
      _react_1$useState4 = _slicedToArray(_react_1$useState3, 2),
      isReadOnly = _react_1$useState4[0],
      setIsReadOnly = _react_1$useState4[1];

  var _PositivationProvider = PositivationProvider_1.usePositivationContext(),
      handleDragStart = _PositivationProvider.handleDragStart,
      dragging = _PositivationProvider.dragging,
      getStyles = _PositivationProvider.getStyles,
      handleDragEnter = _PositivationProvider.handleDragEnter;

  return react_1.default.createElement(S.CategoryPositivationWrapper, {
    mode: mode,
    draggable: isReadOnly,
    onDragStart: function onDragStart(e) {
      handleDragStart(e, props);
    },
    onDragEnter: dragging ? function (e) {
      handleDragEnter(e, props);
    } : undefined,
    className: dragging ? getStyles(props) : ''
  }, react_1.default.createElement("input", {
    type: "text",
    placeholder: "Escreva aqui",
    value: value,
    onChange: function onChange(e) {
      var val = e.target.value;
      setValue(val);
    },
    disabled: isReadOnly
  }), react_1.default.createElement(S.CategoryPositivationIconsWrapper, null, react_1.default.createElement(antd_1.Icon, {
    type: "edit",
    onClick: function onClick() {
      return setIsReadOnly(!isReadOnly);
    }
  }), react_1.default.createElement(antd_1.Popconfirm, {
    title: "Tem certeza que deseja excluir essa categoria\uFF1F",
    onConfirm: function onConfirm() {
      return onDelete();
    },
    onCancel: function onCancel() {
      return console.log('onCancel');
    }
  }, react_1.default.createElement(antd_1.Icon, {
    type: "delete"
  }))));
};