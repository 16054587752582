import * as React from 'react';
import { Former, UserSession } from '@digi-tim-19/components';
import { useHistory } from 'react-router';
import { useClient } from '../../autogenerated/client/client';
import { routes } from '../../config/routes';
import { message } from 'antd';
import { OriginUserDataSession } from '@digi-tim-19/utils/build';
import { AccessCPFProps } from './AccessCPF';
import ScreenLogin from './Screens';

export const LoginForm = ({
  value,
  accessCPFProps
}: {
  value?: string;
  accessCPFProps: AccessCPFProps;
}) => {
  const publicUser = useClient('ConsumerPublicUser', {
    variables: { CPF: value ?? '' },
    fragment: `
      isTempPassword
    `,
    fetchOnMount: true
  });

  const UserLoginWithPassword = useClient('UserLoginWithPassword', {
    appendToFragment: `
      isTempPassword
      token { token, iat }
      `
  });

  const history = useHistory();

  return (
    <Former
      initialValues={{ user: value }}
      config={{
        fields: [
          {
            name: 'user',
            label: 'Digite seu CPF',
            required: true
          },
          {
            name: 'password',
            label: 'senha',
            type: 'password',
            required: true
          }
        ],
        submitButton: { label: 'ACESSAR', disabled: publicUser.loading }
      }}
      onSubmit={async ({ data }) => {
        UserSession.clear();
        OriginUserDataSession.clear();

        UserLoginWithPassword.fetch({
          variables: { user: data.user, password: data.password }
        }).then((ctx) => {
          if (publicUser.result?.isTempPassword) {
            accessCPFProps.setValue(value);

            if (accessCPFProps.setPassword) {
              accessCPFProps.setPassword(data.password);
            }

            accessCPFProps.setScreen(ScreenLogin.NEW_PASSWORD);
          } else {
            if (ctx.errors) {
              message.error(ctx.errors);
            }

            const token = ctx.result?.token;

            if (token) {
              UserSession.setToken(token.token);

              if (ctx.result?.channels?.includes('distribuicao')) {
                //caso seja do canal distribuição, redireciona para o extrato de pontos direto
                history.push(routes.informacoesExtrato.mount());
              } else {
                const returnTo = sessionStorage.getItem('returnTo') || ''
                sessionStorage.removeItem('returnTo');

                history.push(routes.home.mount() + returnTo);
              }
            }
          }
        });
      }}
    />
  );
};
