import styled from 'styled-components';

export const OnTubeCadastroWrapper = styled.div`
  width: 100%;
`;

export const OnTubeCadastroFormer = styled.div`
  background: #f5f5f5;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
  padding: 20px 15px;
  width: 100%;

  .formHeader {
    justify-content: center !important;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      margin-bottom: 30px !important;

      > .name-video,
      > .name-thumbnail {
        flex-basis: auto;
        margin: 0 30px;
        width: auto;
      }
    }
  }

  .formFooter > div {
    justify-content: flex-start !important;
  }
`;
