import { useState, useEffect } from 'react';
import { Category } from '../../autogenerated/client/types';
import { useCategoryFindMany } from './useCategoryFindMany';
import { compact } from 'lodash';

export function useCategoriesSubCategoriesForm(
  categoryFilter: string | string[],
  initialValues: any,
  isInformatives?: boolean
) {
  const getCategories = useCategoryFindMany(categoryFilter, 3);

  const [categories, setCategories] = useState<any[]>([]);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [categoriesSelected, setCategoriesSelected] = useState<string[]>([]);
  const [subCategoriesSelected, setSubCategoriesSelected] = useState<string[]>(
    []
  );
  const [allCategories, setAllCategories] = useState<Category[]>([]);

  function selectCategory(value: string, isSelect: boolean) {
    if (getCategories.result) {
      const selectedCategories = setCategoriesChanged(
        categoriesSelected,
        value
      );
      setCategoriesSelected(selectedCategories);

      if (!isSelect) {
        setSubCategoriesSelected((prevState) => {
          return prevState.filter((item) => {
            return !item.includes(value);
          });
        });
      }
    }
  }

  function getCategoriesInitialValue(categories: any[]): string[] {
    if (categories.length === 1) {
      const catSplit = categories[0]._id.split(':');
      if (catSplit.length >= 3) {
        return [`${catSplit[0]}:${catSplit[1]}`];
      }
    }

    return categories
      .filter(
        (el) => el && (el.parentId === categoryFilter || el.parentId === 'menu')
      )
      .map((x) => x?._id || '');
  }

  function getSubCategoriesInitialValue(categories: any[]) {
    return categories
      .filter(
        (el) => el && el.parentId != categoryFilter && el.parentId != 'menu'
      )
      .map((x) => x?._id || '');
  }

  function filterSubCategories(
    categories: any[],
    selectedCategories: string[]
  ) {
    let arrSubCategories: any = [];

    categories
      .filter((category: any) =>
        selectedCategories.includes(category?._id || '')
      )
      .map((category) => {
        arrSubCategories.push({
          id: category?._id,
          value: category?._id,
          title: category?.name,
          disabled: true
        });

        category?.subCategories?.map((subCategory: any) => {
          const isTimTechInformativos =
            isInformatives &&
            subCategory?._id === 'menu:tim_tech:informativos';
          if (!isTimTechInformativos) {
            arrSubCategories.push({
              id: subCategory?._id,
              value: subCategory?._id,
              title: subCategory?.name,
              pId: category?._id
            });
          }

          subCategory?.subCategories?.map((subSubCategory: any) => {
            arrSubCategories.push({
              id: subSubCategory?._id,
              value: subSubCategory?._id,
              title: subSubCategory?.name,
              pId: !isTimTechInformativos
                ? subCategory?._id
                : category?._id
            });

            subSubCategory?.subCategories?.map((subSubSubCategory: any) => {
              arrSubCategories.push({
                id: subSubSubCategory?._id,
                value: subSubSubCategory?._id,
                title: subSubSubCategory?.name,
                pId: subSubCategory?._id
              });
            });
          });
        });
      });

    return arrSubCategories;
  }

  function setCategoriesChanged(categoriesSelected: string[], value: string) {
    if (!categoriesSelected.includes(value)) {
      return [...categoriesSelected, value];
    } else {
      return categoriesSelected.filter((x) => x != value);
    }
  }

  useEffect(() => {
    if (getCategories.result) {
      setCategories(
        getCategories.result.map((category) => ({
          label: category?.name,
          value: category?._id
        }))
      );

      setAllCategories(compact(getCategories.result));
    }
  }, [getCategories.result]);

  useEffect(() => {
    if (initialValues && categoriesSelected.length === 0) {
      const categoryValue = getCategoriesInitialValue(
        initialValues?.categories.filter(
          (item: any) => item?._id !== 'menu:informativos'
        ) || []
      );

      const subs = getSubCategoriesInitialValue(
        initialValues?.categories.filter(
          (item: any) => item?._id !== 'menu:informativos'
        ) || []
      );

      setCategoriesSelected(categoryValue);
      setSubCategoriesSelected(subs);
    }
  }, [initialValues]);

  useEffect(() => {
    if (getCategories.result) {
      const arrSubCategories: string[] = filterSubCategories(
        getCategories.result,
        categoriesSelected
      );
      setSubCategories(arrSubCategories);
    }
  }, [categoriesSelected, getCategories.result]);

  return {
    categories,
    subCategories,
    categoriesSelected,
    subCategoriesSelected,
    allCategories,
    getCategoriesInitialValue,
    getSubCategoriesInitialValue,
    selectCategory,
    setSubCategoriesSelected
  };
}
