import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const NivelContainer = styled.div`
  width: 90%;
  position: relative;
  height: 130px;
  display: flex;
  align-items: center;
  margin: 20px auto;
`;

export const Nivel = styled.div`
  width: 160px;
  height: 145px;
  position: absolute;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const DescriptionContainer = styled.div<{ disablePadding?: boolean }>`
  width: 600px;
  border-radius: 20px;
  margin-left: 65px;
  background-color: #002bff;
  padding: ${(props) => (props?.disablePadding ? '10px 20px' : '10px 10px 10px 75px')};
  color: white;

  @media (max-width: 630px) {
    width: 100%;
  }
`;

export const Title = styled.h3`
  color: white;
  font-weight: bold;
`;

export const Description = styled.p`
  color: white;
  font-size: 12pt;
  margin: 5px 0;
`;
