import { useClient } from '../autogenerated/client/client';
import React from 'react';
import {
  SelecionarPublicoProps,
  useCurrentUser,
  verdade
} from '@digi-tim-19/components';

type Filter = NonNullable<SelecionarPublicoProps['filterChannels']>;

// - retorna as premissoes que o usuario logado tem, se for THQ não possui contentTypePermission
// - validado no backend
export function useContentTypePermissions() {
  const currentUser = useCurrentUser();
  const find = useClient('UserContentPermissionFindOne', {
    fetchOnMount: true
  });

  return React.useMemo(() => {
    const isTHQ = currentUser.isAdmin;

    const roleGroups = verdade(find.result?.roleGroups),
      ddds = verdade(find.result?.DDDs),
      regions = verdade(find.result?.regions),
      contentTypes = verdade(find.result?.siteContentTypes);

    let filterOptions: { filterChannels: Filter; filterRegions: Filter };

    filterOptions = {
      filterChannels: function filterChannels(allChannels) {
        if (isTHQ) return allChannels;

        return allChannels.filter(function _filter(tree: any) {
          if (tree.children) {
            return (tree.children = tree.children.filter(_filter)).length;
          }
          return roleGroups.some((el: any) => {
            return el.startsWith(tree.value);
          });
        });
      },
      filterRegions: function filterRegions(items) {
        if (isTHQ) return items;
        const allowed = ddds.concat(regions);
        return items.filter((i) => allowed.indexOf(i.key) > -1);
      }
    };

    return {
      roleGroups,
      contentTypes,
      regions,
      filterOptions,
      loading: find.loading || currentUser.loading
    };
  }, [find.result?.roleGroups, find.result?.regions, currentUser.result]);
}
