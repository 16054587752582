import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import { TreeSelect, Input, Empty, Pagination, DatePicker } from 'antd';
import {
  Breadcrumb,
  TitleDescription,
  ListGrid,
  LoadingIndicator,
  verdade,
  Select,
  MaterialProvider,
  ShareModal,
  PinFolderModal,
  CardConsumer
} from '@digi-tim-19/components';
import {
  actionsCardConsumer,
  obterImagemPadraoPorMaterial
} from '../../utils/material';
import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { findCorrectKind, findCorrectName } from '../../utils/productNames';
import {
  SortFindManyCategoryInput,
  EnumMaterialKind,
  EnumFileKind,
  ValidityEnum,
  Maybe,
  Material,
  SortFindManyMaterialInput
} from '../../autogenerated/client/types';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';
import { PaginationWrapper } from '../../components/Page/PaginationWrapper';
import { getRouterByMaterial } from '@digi-tim-19/utils/build';
import { formatDate } from '@digi-tim-19/utils/build';
import { escapeStringRegexp } from '../../utils/formatters';

const { RangePicker } = DatePicker;
const PageContent = styled.div``;

const Search = styled(Input.Search)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

const WrapperCard = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;

  .ant-calendar-range-picker-separator {
    display: none !important;
  }
  @media only screen and (max-width: 900px) {
    .ant-calendar-picker-container {
      width: 100%;
      padding: 0 15px;
    }
    .ant-calendar-range {
      width: 100% !important;
    }
    .ant-calendar-date-panel {
      display: flex !important;
      flex-direction: column !important;
      .ant-calendar-range-part {
        width: 100%;
      }
    }
  }
`;

export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;
  cursor: pointer;
`;

export const CategoriasRegionais = () => {
  const { category, subcategory } = useParams<{
    category: string;
    subcategory: string;
  }>();
  const [productFilter, setProductFilter] = useState('');
  const [procedureFilter, setProcedureFilter] = useState('');
  const [inCategories, setInCategories] = useState([
    procedureFilter,
    productFilter
  ]);

  const kind = useMemo(() => subcategory || '', [subcategory]);

  const apiAnalyticsDownload = useClient('AnalyticsDownloads');

  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [subCategoryFilter, setSubCategoryFilter] = useState('');
  const [createdAt, setCreatedAt] = useState<{ start: string; end: string }>();
  const [show, setShow] = useState([]);
  const [data, setData] = useState<any[]>();

  const perPage = 20;

  useEffect(() => {
    setInCategories([procedureFilter, productFilter]);
  }, [procedureFilter, productFilter]);

  const titlePage = useMemo(() => {
    if (kind) return findCorrectName(kind);

    return '';
  }, [kind]);

  const history = useHistory();

  // const paramsFilter = product
  //   ? `regex(${product})`
  //   : `menu:veja_mais:${category}`;
  const paramsFilter = `menu:veja_mais:${category}:${kind}`;

  const [filterSearch, setFilterSearch] = useState('');
  const [sort, setSort] = useState(SortFindManyMaterialInput.IdDesc);

  const resetFilters = () => {
    setCreatedAt(undefined);
    setSubCategoryFilter('');
    setShow([]);
    setProcedureFilter('');
    setProductFilter('');
    setFilterSearch('');
    setSort(SortFindManyMaterialInput.IdDesc);
    setPage(1);
  };

  const getMaterials = useClient('MaterialPagination');

  const total = getMaterials.result?.pageInfo.itemCount;

  const getCategories = useClient('CategoryFindMany', {
    //Monta o SELECT de filtro
    fetchOnMount: true,
    appendToFragment: categoryFragment,
    variables: {
      filter: {
        parentId: paramsFilter
      },
      sort: SortFindManyCategoryInput.SortAsc,
      limit: 123
    }
  });

  useEffect(() => {
    const allCategories = subCategoryFilter
      ? [subCategoryFilter].filter(Boolean)
      : inCategories.filter(Boolean).length
      ? inCategories.filter(Boolean)
      : undefined;

    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            kind: findCorrectKind(kind),
            validityEnum: ValidityEnum.Current,
            createdAtRange: createdAt,
            allCategories,
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
          },
          sort: SortFindManyMaterialInput.CreatedatDesc
        }
      })
      .then((response: any) => {
        if (response?.result?.items) setItems(response.result.items);
      })
      .catch((err) => console.log({ err }));
  }, [
    paramsFilter,
    sort,
    procedureFilter,
    productFilter,
    inCategories,
    filterSearch,
    page,
    subCategoryFilter,
    createdAt,
    kind
  ]); //eslint-disable-line

  useEffect(() => {
    resetFilters();

    getCategories.fetch({
      variables: {
        filter: {
          parentId: paramsFilter
        },
        sort: SortFindManyCategoryInput.SortAsc,
        limit: 123
      }
    });
  }, [paramsFilter]); //eslint-disable-line

  const { listFolders, addFolder } = useFoldersPin();

  const getData = () => {
    const mountListGrid = (materialArray: Maybe<Material>[]) => {
      return materialArray?.map((material) => {
        const thumb = (material?.files || []).find(
          (item) => item?.kind === EnumFileKind.AnyImage
        )?.signedUrl;
        const fileDoc = (material?.files || []).find(
          (item) =>
            item?.kind === EnumFileKind.Pdf ||
            item?.kind === EnumFileKind.AnyDoc
        );

        const routesMaterial = getRouterByMaterial(material, routes);

        return {
          tituloCard: material?.title,
          descricao: material?.description,
          data: formatDate(material?.createdAt, 'DD/MM/YYYY'),
          prefixDate: 'Cadastrado em - ',
          dataUpdate: formatDate(material?.updatedAt, 'DD/MM/YYYY'),
          prefixDateUpdate: 'Atualizado em - ',
          imgBody: thumb,
          defaultImage: '/defaultImages/Thumb-PROCESSOS_C.png',
          renderPin: () => (
            <MaterialProvider material={material as any}>
              <PinFolderModal
                listFolders={listFolders}
                actionAddFolder={addFolder}
                pinned={material?.pinned}
              />
            </MaterialProvider>
          ),
          renderShare: () => (
            <MaterialProvider material={material as any}>
              <ShareModal />
            </MaterialProvider>
          ),
          actions: {
            pin: true,
            share: true,
            delete: false,
            access: !!routesMaterial.routerAccess,
            download: material?.html === null || !!fileDoc,
            onDownload: () => {
              if (fileDoc) {
                let a = document.createElement('a');
                a.href = fileDoc?.signedUrl || '#';
                a.download = fileDoc?.title || '';
                a.target = '_blank';
                a.click();
                apiAnalyticsDownload.fetch({ variables: { fileId: fileDoc?._id || '' } });
              }
            },
            onAccess: () => {
              history.push(routesMaterial.routerAccess);
            }
          }
        };
      });
    };

    if (getMaterials.loading) return [];

    if (filterSearch.length > 0) return mountListGrid(items);

    return mountListGrid(items);
  };

  useEffect(() => {
    setData(getData());
  }, [items]);

  const mountTitle = () => {
    switch (kind) {
      case 'pilulas':
        return 'Aqui você encontra todas as pílulas para te ajudar a vender mais.';
      case 'comunicacao_para_o_canal':
        return 'Aqui você encontra todas as informações importantes para o Canal.';
      case 'materiais_para_o_pdv':
        return 'Aqui você encontra todos os materiais para o seu PDV.';
      default:
        // matinais
        return 'Aqui você encontra todas as apresentações com informações completas sobre os nossos produtos, lançamentos, entre outros.';
    }
  };

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
    },
    [getMaterials?.result]
  );

  const categories = React.useMemo(() => {
    const cat = verdade(getCategories.result);

    return cat.map((category) => {
      const selectable = category.subCategories?.length === 0;

      return {
        title: category?.name,
        value: category?._id,
        key: category?._id,
        selectable,
        children: category?.subCategories?.map((subCategory) => ({
          title: subCategory?.name,
          value: subCategory?._id,
          key: subCategory?._id,
          children: subCategory?.subCategories?.map((subSubCategory) => ({
            title: subSubCategory?.name,
            value: subSubCategory?._id,
            key: subSubCategory?._id
          }))
        }))
      };
    });
  }, [getCategories.result]);

  const onChange = (e: any) => {
    setCreatedAt({
      start: moment(e[0]!).startOf('day')?.toISOString(),
      end: moment(e[1]!).endOf('day')?.toISOString()
    });
    setShow(e);
  };

  const renderComponentByCategory = () => {
    if (kind === 'comunicacao_para_o_canal')
      return <ListGrid items={data as any} />;

    return (
      <WrapperCard>
        {items?.map((item: any, index: any) => {
          const thumb = obterImagemPadraoPorMaterial(item, undefined);

          return (
            <CardConsumer
              key={index}
              hideTitle={true}
              material={item}
              defaultImage={thumb}
              actions={(material) =>
                actionsCardConsumer(
                  material,
                  routes,
                  history,
                  listFolders,
                  addFolder,
                  true
                )
              }
            />
          );
        })}
      </WrapperCard>
    );
  };

  return (
    <PageTemplate>
      <>
        {category && kind && (
          <>
            <Breadcrumb
              items={[
                { title: 'Home', link: routes.home.mount() },
                { title: 'Veja Mais' },
                { title: 'Regional', link: routes.regional.mount() },
                { title: titlePage }
              ]}
            />
            <TitleDescription
              iconType="schedule"
              title={titlePage}
              description={kind && mountTitle()}
            />
          </>
        )}

        <PageContent>
          <FilterContainer>
            <TreeSelect
              className={cx('informative-filter')}
              dropdownStyle={{ maxHeight: 200, overflow: 'auto' }}
              treeData={categories}
              placeholder="Filtrar"
              treeDefaultExpandAll={false}
              onChange={(e: any) => {
                setPage(1);
                setSubCategoryFilter(e);
              }}
              value={subCategoryFilter}
            />

            <RangePicker
              getCalendarContainer={(triggerNode: any) =>
                triggerNode.parentNode
              }
              format="DD/MM/YYYY"
              placeholder={['PERÍODO', '']}
              onChange={(e) => {
                setPage(1);
                onChange(e);
              }}
              value={show}
            />
            <Search
              placeholder="Busca título"
              style={{ width: 200 }}
              value={filterSearch}
              onChange={(e) => {
                setPage(1);
                onSearch(e.target.value);
              }}
            />
            <CleanButton onClick={() => resetFilters()}>
              Limpar filtros
            </CleanButton>
          </FilterContainer>

          {!getMaterials.loading && data?.length === 0 && <Empty />}
          {getMaterials.loading ? (
            <LoadingIndicator />
          ) : (
            data && renderComponentByCategory()
          )}

          <PaginationWrapper>
            <Pagination
              pageSize={perPage}
              current={page}
              total={total!}
              onChange={(page) => setPage(page)}
            />
          </PaginationWrapper>
        </PageContent>
      </>
    </PageTemplate>
  );
};

const order = [
  { label: 'MAIS ANTIGO', value: SortFindManyMaterialInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyMaterialInput.IdDesc }
];

const categoryFragment = `
  parentId
  _id
  subCategories {
    name
    _id
    subCategories {
      name
      _id
      subCategories {
        name
        _id
      }
    }
  }
`;

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    title
    pinned
    numericId
    createdAt
    updatedAt
    fileIds
    html
    kind
    files {
      _id
      extension
      kind
      signedUrl
      downloadUrl
      url
    }
    categories {
      _id
      name
    }
    validity {
      start
      end
    }
    author {
      _id,
      name
    }
  }
`;
