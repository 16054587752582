import React from 'react';
import { TableActions } from '@digi-tim-19/components';
import { getColorStatus } from '../../../hooks/contestacao/useContestacao';

export const columns = [
  {
    title: 'Protocolo',
    dataIndex: 'protocol',
    key: 'protocol',
    render: (text: string) => {
      return <p>{text}</p>;
    }
  },
  {
    title: 'Data da contestação',
    dataIndex: 'dateContestation',
    key: 'dateContestation'
  },
  {
    title: 'Mensagem',
    dataIndex: 'message',
    key: 'message',
    render: (text: string) => {
      const partText = text.length > 20 ? `${text.substr(0, 20)}...` : text;
      return <p>{partText}</p>;
    }
  },
  {
    title: 'Resposta',
    dataIndex: 'response',
    key: 'response',
    render: (text: string) => {
      if (!text) return '';
      const partText = text.length > 20 ? `${text.substr(0, 20)}...` : text;
      return <p>{partText}</p>;
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text: string) => {
      const color = getColorStatus(text);

      return <p style={{ color: color }}>{text}</p>;
    }
  },
  {
    title: 'Ações',
    key: 'action',
    render: (_: DataKey, record: any) => <TableActions data={record} />
  }
];

export interface DataKey {
  key: string;
  name: string;
  status: string;
}
