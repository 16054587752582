import React, { useMemo } from 'react';
import { Breadcrumb, JoystickIconSVG, LoadingIndicator, TitleDescription } from '@digi-tim-19/components';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { Container, Description, DescriptionContainer, Nivel, NivelContainer, Title, Wrapper } from './styles';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { ContainerIcon } from '../../Gamefication/List/styles';

export const GameficationNivelParticipant = () => {
  const niveis = useClient('GameficationNivelFindMany', {
    appendToFragment: `title nivel amountChips`,
    fetchOnMount: true,
  });

  const { parsedData } = useMemo(() => {
    const imgsMap: Record<number, string> = {
      1: '/defaultImages/gamefication/start-level.png',
      2: '/defaultImages/gamefication/mid-level.png',
      3: '/defaultImages/gamefication/final-level.png',
    };

    const altImgMap: Record<number, string> = {
      1: 'Nível inicial',
      2: 'Nível médio',
      3: 'Nível final',
    };

    const textMap: Record<number, string> = {
      1: 'inicial',
      2: 'intermediário',
      3: 'avançado',
    };

    const parsedData =
      niveis?.result?.map((el, i) => {
        const next = (niveis?.result ?? [])[i + 1];
        const finalText = !!next
          ? `de ${el?.amountChips ?? 0} a ${(next?.amountChips ?? 0) - 1} chips`
          : `a partir de ${el?.amountChips ?? 0} chips`;

        return {
          _id: `${el?._id}`,
          imgUrl: imgsMap[el?.nivel ?? 1],
          imgAlt: altImgMap[el?.nivel ?? 1],
          title: `${el?.title}`,
          text: `Nível ${textMap[el?.nivel ?? 1]} que contempla ${finalText}`,
        };
      }) ?? [];

    return { parsedData };
  }, [niveis?.result]);

  return (
    <PageTemplate>
      <React.Fragment>
        <Wrapper>
          <Breadcrumb items={[{ title: 'Home', link: routes.home.mount() }, { title: 'Gamificação (Níveis)' }]} />
          <TitleDescription
            renderCustomIcon={
              <ContainerIcon>
                <JoystickIconSVG />
              </ContainerIcon>
            }
            title="Gamificação (Níveis)"
            description="Consultar os níveis da Gamificação"
          />
        </Wrapper>
        <Container>
          {niveis.loading ? (
            <LoadingIndicator />
          ) : (
            <React.Fragment>
              {parsedData.map(({ _id, title, imgUrl, imgAlt, text }) => {
                return (
                  <NivelContainer key={`${_id}`}>
                    <Nivel>
                      <img src={imgUrl} alt={imgAlt} />
                    </Nivel>
                    <DescriptionContainer
                      disablePadding={!!title && !['3G', '4G', '5G'].includes(String(title).trim())}
                    >
                      <Title>{title}</Title>
                      <Description>{text}</Description>
                    </DescriptionContainer>
                  </NivelContainer>
                );
              })}
            </React.Fragment>
          )}
        </Container>
      </React.Fragment>
    </PageTemplate>
  );
};
