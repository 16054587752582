"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var invariantType_1 = require("./invariantType");

var _require = require('normalize-diacritics'),
    normalizeSync = _require.normalizeSync;

exports.nameToID = function (name, parentId) {
  invariantType_1.invariantType({
    name: name
  }, 'string');
  var id = normalizeSync(name).replace(/ /g, '_').toLowerCase().replace(/[^a-z0-9]/gi, '_');
  var suffix = parentId ? "".concat(parentId, ":") : '';
  return "".concat(suffix).concat(id);
}; // faz parse de acentos e caracteres especiais
// ex: São Paulo -> Sao Paulo


function normalizeDiacritics(name) {
  return normalizeSync(name);
}

exports.normalizeDiacritics = normalizeDiacritics;