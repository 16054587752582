import React from 'react';
import moment from 'moment';
import { AnswerInterface, QuestionInterface } from '../../Gamefication/Participant/ModalResearhChallenge';
import { Icon, Modal, ModalAnswer, ModalQuestion } from './styles';

interface ModalUserAnswersProps {
  questions?: QuestionInterface[];
  answers?: AnswerInterface[];
}

const ModalUserAnswers = ({ questions = [], answers = [] }: ModalUserAnswersProps) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleQuestionAnswer = (questions: QuestionInterface[], answers: AnswerInterface[]) => {
    return questions.map((question) => {
      const questionAnswer = answers.find((answer) => answer.questionId === question.id);
      return {
        question: question.description,
        answer: questionAnswer?.answer,
      };
    });
  };

  return (
    <React.Fragment>
      <Icon type="eye" onClick={() => setOpen(true)} />
      <Modal
        title="Perguntas e respostas"
        visible={open}
        okText="Ok"
        cancelText="Fechar"
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      >
        {handleQuestionAnswer(questions, answers)?.map((el) => {
          return (
            <React.Fragment>
              <ModalQuestion>Pergunta: {el.question}</ModalQuestion>
              <ModalAnswer>Resposta: {Array.isArray(el.answer) ? el.answer.join('; ') : el.answer}</ModalAnswer>
            </React.Fragment>
          );
        })}
      </Modal>
    </React.Fragment>
  );
};

export const columns = [
  {
    title: 'Nome',
    dataIndex: 'user',
    key: 'user',
    render: (data: any) => <p>{data?.name || ''}</p>,
  },
  {
    title: 'Matrícula',
    dataIndex: 'user',
    key: 'matricula',
    render: (data: any) => <p>{data?.matricula || ''}</p>,
  },
  {
    title: 'Canal',
    dataIndex: 'user',
    key: 'channel',
    render: (data: any) => <p>{data?.channel || ''}</p>,
  },
  {
    title: 'Data de resposta',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (text: string) => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
  },
  {
    title: 'Ações',
    key: 'action',
    render: (_: any, record: any) => (
      <ModalUserAnswers
        questions={record?.challengeData?.challengeData?.questions}
        answers={record?.userChallengeData?.answers}
      />
    ),
  },
];
