import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  LoadingIndicator,
  ProviderXlsxJson
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import { ArquivosCampanhaForm } from './ArquivosCampanhaForm';
import { useClient } from '../../../autogenerated/client/client';
import { EnumIncentiveCampaignStatus } from '../../../autogenerated/client/types';

export const ArquivosCampanhaEditar = () => {
  const {
    params: { idFileContent }
  } = useRouteMatch();

  const [statusCampaign, setStatusCampaign] =
    useState<EnumIncentiveCampaignStatus>(
      EnumIncentiveCampaignStatus.InVerification
    );

  const getFileContent = useClient('FileContentCampaignFindById', {
    fetchOnMount: true,
    appendToFragment: `
      IncentiveCampaign {
        status {
          value
        }
      }`,
    variables: {
      _id: idFileContent
    }
  });

  useEffect(() => {
    if (getFileContent.result)
      setStatusCampaign(
        getFileContent.result.IncentiveCampaign?.status?.value ||
          EnumIncentiveCampaignStatus.Closed
      );
  }, [getFileContent.result]);

  return (
    <PageTemplate>
      <>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        {getFileContent.loading ? (
          <LoadingIndicator />
        ) : (
          <ProviderXlsxJson>
            <ArquivosCampanhaForm
              statusCampaign={statusCampaign}
              initialValues={getFileContent.result || {}}
            />
          </ProviderXlsxJson>
        )}
      </>
    </PageTemplate>
  );
};

const configPage = {
  pageName: 'Editar arquivos de campanhas de incentivo',
  icon: 'trophy',
  description: 'Editar de arquivos de campanhas de incentivo.',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo' },
    { title: 'Campanhas de Incentivo' },
    { title: 'Listar' },
    { title: 'Editar arquivos de Campanha de incentivo' }
  ]
};
