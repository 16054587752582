import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { PageWrapper } from './styles';
import { Breadcrumb, JoystickIconSVG, LoadingIndicator, TitleDescription, useClient } from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { useRouteMatch } from 'react-router';
import { FormGameficationNivelCustom } from '../Form/Form';
import { ContainerIcon } from '../../Gamefication/List/styles';

const appendToFragment = `
  _id
  title
  nivelId
  roleGroupId
`;

export const GameficationNivelCustomEdit = () => {
  const { params }: any = useRouteMatch();
  const getGameficationNivel = useClient('GameficationNivelCustomFindById', {
    appendToFragment,
    variables: { _id: params?.id! },
    fetchOnMount: true,
  });
  const configPage = {
    pageName: 'Gamificação (Níveis Custom)',
    icon: 'bar-chart',
    description: 'Edição e consulta de níveis custom da gamificação',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo', link: routes.home.mount() },
      { title: 'Gamificação (Níveis Custom)', link: routes.gameficationNivelCustomList.mount() },
      { title: 'Editar', link: routes.gameficationNivelCustomEdit.mount(params?._id!) },
    ],
  };

  return (
    <PageTemplate>
      <React.Fragment>
        <PageWrapper>
          <Breadcrumb items={configPage.breadcrumb} />
          <TitleDescription
            renderCustomIcon={
              <ContainerIcon>
                <JoystickIconSVG />
              </ContainerIcon>
            }
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>
        {getGameficationNivel.loading ? (
          <LoadingIndicator />
        ) : (
          <React.Fragment>
            {getGameficationNivel?.result && (
              <FormGameficationNivelCustom initialValues={getGameficationNivel.result} />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </PageTemplate>
  );
};
