import React from 'react';
import moment from 'moment';

const mapStatus = new Map([
  ['expired', 'Expirado'],
  ['pending', 'Pendente'],
  ['in_progress', 'Em andamento'],
  ['awaiting_verification', 'Aguardando apuração'],
  ['completed', 'Premiado'],
  ['not_completed', 'Não concluído']
]);

export const columns: any[] = [
  {
    title: 'Nome',
    dataIndex: 'userChallengeData.name',
    key: 'userChallengeData.nam',
    render: (text: string) => <p>{text}</p>,
  },
  {
    title: 'CPF',
    dataIndex: 'userChallengeData.cpf',
    key: 'userChallengeData.cpf',
    render: (text: string) => <p>{text}</p>,
  },
  {
    title: 'Canal',
    dataIndex: 'userChallengeData.channel',
    key: 'userChallengeData.channel',
    render: (text: string) => <p>{text.replace('_', ' ').toLocaleUpperCase()}</p>,
  },
  {
    title: 'Aceite',
    dataIndex: 'acceptDate',
    key: 'acceptDate',
    render: (text: string) => <p>{text ? moment(text).format('DD/MM/YYYY') : ''}</p>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text: string) => <p>{mapStatus.get(text)}</p>,
  },
];
