import React from 'react';
import { useAnalytics, useClient } from '@digi-tim-19/components';
import chipIcon from './assets/GAMEFICATION_CHIP.png';
import timCoin from './assets/TIM-Coin.png';
import {
  CardChallenge,
  CardChallengeContainer,
  ChallengeButtonStart,
  ChallengeStateBox,
  ContentMainBox,
  ContentMainBoxReward,
  ContentMainContainer,
  ContentPercent,
  ContentProgressBarContainer,
  ContentProgressChallengeCard,
  ContentTotalPercent,
  DividerChallengeCard,
  FooterChallengeCard,
  HeaderChallengeCard,
  IconChallengeCard,
  IconChallengeFaq,
  MainChallengeCard,
  ProgressBarFill,
  ProgressCurrent,
  RewardChip,
  RewardContainer,
  RewardTitle,
  RewardValue,
  ContentId,
} from './stylesCardChallenge';
import { challengesImage } from './challengeConstants';
import { TypeChallenge } from '../Form/Form';
import { Button, Icon, Popover, Tooltip, message } from 'antd';
import { ModalQuizChallenge } from './ModalQuizChallenge';
import { ModalExternalLink } from './ModalExternalLink';
import { ModalResearchChallenge } from './ModalResearhChallenge';
import { EnumAnalyticsEvent } from '../../../autogenerated/client/types';
import moment from 'moment';
import { CheckIcon, CloseIcon } from './assets/challengesIcon';

export interface ChallengeProps {
  _id: string;
  description: string;
  amountPoints: number;
  challengeNumericId: number;
  amountChips: number;
  title: string;
  thumbnail: string;
  progress: {
    amount: number;
    total: number;
  };
  action: string;
  category?: string;
  kind?: string;
  typePayment?: string;
  typeChallenge?: TypeChallenge | string;
  challengeData?: Record<string, any>;
  userChallengeData?: Record<string, any>;
  status: string;
  externalLink: string;
  updatedAt: string;
  endDate: string;
  startDate: string;
  challengeId: string;
  userId: string;
  redirect?: boolean;
  url?: string;
  faq?: string;
}

interface Props {
  refetch: () => void;
  challenge: ChallengeProps;
}

export const UserChallengeCard = (props: Props) => {
  const { challenge } = props;
  const userChallengeUpdate = useClient('UserChallengeUpdateById');
  const portalCheck = useClient('PortalCheck');
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openModalExternalLink, setOpenModalExternalLink] = React.useState<boolean>(false);
  const analytics = useAnalytics();

  const challengeImage = challengesImage.get(props.challenge.typeChallenge as string);

  const handleAccept = async () => {
    if (
      challenge.status !== 'pending' &&
      challenge.status !== 'in_progress' &&
      challenge?.typeChallenge !== TypeChallenge.quiz
    ) {
      return;
    }

    if (challenge.status === 'in_progress' && challenge?.typeChallenge !== TypeChallenge.quiz) {
      return;
    }

    if (challenge?.typeChallenge === TypeChallenge.time) {
      portalCheck
        .fetch({
          variables: {
            challengeId: challenge.challengeId,
            userId: challenge.userId,
            userChallengeId: challenge._id,
            acceptDate: new Date(Date.now() - 108e5),
          },
        })
        .then(() => {
          message.success('Desafio aceito com sucesso!');
          props.refetch();
        })
        .catch(() => message.error('Não foi possível aceitar desafio'));
      return;
    }

    if (challenge?.typeChallenge === TypeChallenge.externalLink) {
      setOpenModalExternalLink(true);
      return;
    }

    if (challenge?.typeChallenge === TypeChallenge.research || challenge?.typeChallenge === TypeChallenge.quiz) {
      setOpenModal(true);
      return;
    }

    await userChallengeUpdate
      .fetch({
        variables: {
          record: {
            _id: challenge._id,
            status: 'in_progress',
            acceptDate: new Date(Date.now() - 108e5),
          },
        },
      })
      .then(async () => {
        await message.success({ duration: 1, content: 'Desafio aceito com sucesso!' });
        if (
          challenge?.typeChallenge &&
          challenge?.redirect &&
          challenge?.url &&
          [
            TypeChallenge.video,
            TypeChallenge.communication,
            TypeChallenge.offer_map,
            TypeChallenge.share,
            TypeChallenge.rescue,
            TypeChallenge.view,
          ].includes(challenge.typeChallenge as TypeChallenge)
        ) {
          window.location.href = challenge.url;
        } else {
          props.refetch();
        }
      })
      .catch(() => message.error('Não foi possível aceitar desafio'));
  };

  const RenderProgress = () => {
    const currentProgress = (challenge.progress.amount / challenge.progress.total) * 100;

    return (
      <ContentProgressChallengeCard>
        <ContentPercent status={props.challenge.status} currentProgress={currentProgress}>
          {Math.round(currentProgress)}%
        </ContentPercent>
        <ContentProgressBarContainer>
          <ProgressBarFill>
            <ProgressCurrent currentProgress={currentProgress} />
          </ProgressBarFill>
        </ContentProgressBarContainer>
        <ContentTotalPercent status={props.challenge.status} currentProgress={currentProgress}>
          100%
        </ContentTotalPercent>
      </ContentProgressChallengeCard>
    );
  };

  const RenderMain = () => {
    const btnTitle = (status: string) => {
      if (status === 'pending') return 'INICIAR';
      if (status === 'expired' || status === 'not_completed') return <CloseIcon />;
      if (status === 'completed') return <CheckIcon />;

      return 'INICIADO';
    };

    const img = props.challenge.typePayment === 'chips' ? chipIcon : timCoin;

    return (
      <ContentMainContainer>
        <ContentMainBox>
          <ContentMainBoxReward>
            <RewardTitle>RECOMPENSA</RewardTitle>
            <RewardContainer>
              <RewardValue>{props.challenge?.amountChips || props.challenge?.amountPoints}</RewardValue>
              <RewardChip status={props.challenge.status}>
                <img src={img} />
              </RewardChip>
            </RewardContainer>
          </ContentMainBoxReward>
        </ContentMainBox>
        <ContentMainBox>
          <ChallengeStateBox
            status={props.challenge.status}
            cursorPointer={
              props.challenge.status === 'pending' ||
              (props.challenge.status === 'in_progress' && props.challenge.typeChallenge === TypeChallenge.quiz)
            }
          >
            <ChallengeButtonStart
              status={props.challenge.status}
              onClick={async () => {
                if (portalCheck.loading || userChallengeUpdate.loading) {
                  return;
                }
                await handleAccept();
              }}
            >
              {portalCheck.loading || userChallengeUpdate.loading ? (
                <Icon type="loading" />
              ) : (
                <React.Fragment>{btnTitle(props.challenge.status)}</React.Fragment>
              )}
            </ChallengeButtonStart>
          </ChallengeStateBox>
        </ContentMainBox>
      </ContentMainContainer>
    );
  };

  const footerInfo = () => {
    const updated = moment(props.challenge.updatedAt).format('DD/MM/YYYY');
    const endDate = moment(props.challenge.endDate);

    if (props.challenge.status === 'completed') {
      return `Concluído em ${updated}`;
    }

    if (props.challenge.status === 'expired') {
      return `Expirado em ${updated}`;
    }

    if (props.challenge.typeChallenge === TypeChallenge.quiz && props.challenge.status === 'not_completed') {
      return `Última tentativa em ${updated}`;
    }

    return endDate.isValid() ? `Disponível até ${endDate.format('DD/MM/YYYY')}` : 'Não expira';
  };

  return (
    <>
      <CardChallengeContainer>
        {props?.challenge?.faq && (
          <IconChallengeFaq>
            <Tooltip placement="top" title={props?.challenge?.faq}>
              <Icon type="info-circle" />
            </Tooltip>
          </IconChallengeFaq>
        )}
        <IconChallengeCard src={challengeImage} status={props.challenge.status} />
        <CardChallenge status={props.challenge.status} className="card-challenge">
          <ContentId>#{props.challenge.challengeNumericId}</ContentId>
          <HeaderChallengeCard>
            <p>{props.challenge?.description?.toLocaleUpperCase()}</p>
          </HeaderChallengeCard>
          <DividerChallengeCard />
          <MainChallengeCard>
            <RenderProgress />
            <RenderMain />
            <FooterChallengeCard>{footerInfo()}</FooterChallengeCard>
          </MainChallengeCard>
        </CardChallenge>
      </CardChallengeContainer>
      <RenderModalResearch
        challenge={challenge}
        openModal={openModal}
        setOpenModal={setOpenModal}
        userChallengeUpdate={userChallengeUpdate}
        refetch={props.refetch}
        analytics={analytics}
      />
      <RenderModalQuiz
        challenge={challenge}
        openModal={openModal}
        setOpenModal={setOpenModal}
        userChallengeUpdate={userChallengeUpdate}
        refetch={props.refetch}
      />
      <RenderModalExternalLink
        challenge={challenge}
        refetch={props.refetch}
        openModalExternalLink={openModalExternalLink}
        setOpenModalExternalLink={setOpenModalExternalLink}
      />
    </>
  );
};

const RenderModalExternalLink = ({ challenge, refetch, openModalExternalLink, setOpenModalExternalLink }: any) => {
  if (challenge?.typeChallenge !== TypeChallenge.externalLink) return null;

  return (
    <ModalExternalLink
      challenge={challenge}
      open={openModalExternalLink}
      setOpen={setOpenModalExternalLink}
      refetch={refetch}
    />
  );
};

const RenderModalQuiz = React.memo(({ challenge, openModal, setOpenModal, userChallengeUpdate, refetch }: any) => {
  const includeStatus = new Set(['pending', 'in_progress']);

  if (challenge?.typeChallenge !== TypeChallenge.quiz || !includeStatus.has(challenge?.status)) return null;

  return (
    <ModalQuizChallenge
      userChallenge={challenge}
      quizTitle={challenge?.challengeData?.title}
      questions={challenge?.challengeData?.questions || []}
      visible={openModal}
      loading={userChallengeUpdate.loading}
      onFinish={(refetchAfter) => {
        if (refetchAfter) {
          refetch();
        }
        setOpenModal(false);
      }}
    />
  );
});

const RenderModalResearch = ({ challenge, userChallengeUpdate, analytics, openModal, setOpenModal, refetch }: any) => {
  const includeStatus = new Set(['pending', 'in_progress']);

  if (challenge?.typeChallenge !== TypeChallenge.research || !includeStatus.has(challenge?.status)) return null;

  const onOk = async (answers: any) => {
    await userChallengeUpdate
      .fetch({
        variables: {
          record: {
            _id: challenge._id,
            status: 'completed_waiting_process',
            userChallengeData: { answers },
            progress: 1,
            acceptDate: new Date(Date.now() - 108e5),
          },
        },
      })
      .then(() => {
        analytics.track({
          event: EnumAnalyticsEvent.Research,
          kind: EnumAnalyticsEvent.Research,
          entityName: 'UserChallenge',
          recordId: challenge._id,
          singleDoc: true,
        });
        message.success('Pesquisa respondida com sucesso!');
        setOpenModal(false);
        refetch();
      })
      .catch(() => {
        message.error('Não foi possível responder a pesquisa.');
      });
  };

  return (
    <ModalResearchChallenge
      challenge={challenge}
      visible={openModal}
      loading={userChallengeUpdate.loading}
      onOk={onOk}
      onCancel={() => setOpenModal(false)}
    />
  );
};
