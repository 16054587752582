import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { fetchGraphql } from 'graphql-clientgen';

import {
  Breadcrumb,
  LoadingIndicator,
  MaterialSample,
  TitleDescription,
  useQueryParam,
  VideoPlaylist,
  useAnalytics,
  useCurrentUser,
} from '@digi-tim-19/components';

import { PageTemplate } from '../../components/Layout/PageTemplate';
import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';

import { EnumFileKind, EventLive, Material, SortFindManyMaterialInput } from '../../autogenerated/client/types';
import { EnumAnalyticsParentKind } from '@digi-tim-19/components/build/main/DataProviders/types';
import { LivePlayer } from './LivePlayer';
import { message } from 'antd';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';
import { useEntitySubscription, useEntityViewsSubscription } from '@digi-tim-19/utils';
import { escapeStringRegexp } from '../../utils/formatters';

const OnTubeViewerWrapper = styled.div``;

export const OnTubePlayer = () => {
  const { id } = useParams();
  const [kind] = useQueryParam('kind');

  const [sameCategoryItems, setSameCategoryItems] = useState<MaterialSample[]>([]);
  const pageConfigurationSameCategories = useState<{
    page: number;
    currentPage: number;
    totalCount: number;
    pageSize: number;
  }>({
    page: 1,
    currentPage: 1,
    totalCount: 1,
    pageSize: 20,
  });
  const [searchPlayList, setSearchPlayList] = useState<any>();

  const userId = useCurrentUser().result?._id;

  const videoSearch = useClient('MaterialFindOne', {
    appendToFragment: `
      ${commonFragment}
    `,
  });

  const videosPlayList = useClient('MaterialPagination', {
    appendToFragment: `${fragmentMaterialPagination}`,
  });

  const eventSearch = useClient('EventLiveFindOne', {
    appendToFragment: `
      ${commonFragment}
    `,
  });

  useEffect(() => {
    const setPageConfigurationSameCategories = pageConfigurationSameCategories[1] as Function;
    setPageConfigurationSameCategories((prevState: any) => {
      return {
        ...prevState,
        page: 1,
      };
    });
  }, [searchPlayList]);

  useEffect(() => {
    if (videoSearch.result?.categories && videoSearch.result?.categories.length) {
      const playList = videoSearch.result?.categories.find((item) => item?.parentId === 'video_playlist');

      if (playList) {
        videosPlayList
          .fetch({
            variables: {
              page: pageConfigurationSameCategories[0]?.page || 1,
              perPage: pageConfigurationSameCategories[0]?.pageSize || 1,
              sort: SortFindManyMaterialInput.CreatedatDesc,
              filter: {
                inCategories: [playList._id],
                title: !searchPlayList ? undefined : `regex(${escapeStringRegexp(searchPlayList)})`,
              },
            },
          })
          .then((ctx) => {
            if (ctx.result) {
              const items = ctx.result.items?.filter((x) => x?._id !== id);
              setSameCategoryItems(items as MaterialSample[]);
              const setPageConfigurationSameCategories = pageConfigurationSameCategories[1] as Function;
              setPageConfigurationSameCategories((prevState: any) => {
                return {
                  ...prevState,
                  currentPage: ctx.result?.pageInfo.currentPage,
                  totalCount: ctx.result?.pageInfo.itemCount,
                };
              });
            }
          });
      }
    }
  }, [
    id,
    videoSearch.result?.categories,
    pageConfigurationSameCategories[0]?.page,
    pageConfigurationSameCategories[0]?.pageSize,
    searchPlayList,
  ]);

  const isLive = kind === 'live';

  function refetch() {
    if (!id) return;

    if (isLive) {
      eventSearch.fetch({ variables: { filter: { _id: id! } } });
    } else {
      videoSearch.fetch({ variables: { filter: { _id: id! } } });
    }
  }

  React.useEffect(() => {
    refetch();
  }, [id, isLive]);

  const result = eventSearch.result || videoSearch.result;
  const loading = !result && (eventSearch.loading || videoSearch.loading);

  return (
    <PageTemplate>
      <OnTubeViewerWrapper>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Ontube', link: routes.OnTube.mount() },
            { title: 'Vídeo' },
          ]}
        />

        <TitleDescription
          iconType="play-square"
          title="OnTube"
          description={
            'Seu canal de vídeos com todas as informações que você precisa. \n Encontre o que deseja com filtros de busca, comente, curta, compartilhe e muito mais.'
          }
        />

        {(() => {
          if (loading) return <LoadingIndicator />;
          if (!result) return <p>Material não encontrado</p>;
          return (
            <PageContent
              contentItem={result}
              isLive={isLive}
              userId={userId}
              videosPlayListLoading={videosPlayList.loading}
              sameCategoryItems={sameCategoryItems}
              pageConfigurationSameCategories={pageConfigurationSameCategories}
              setSearchPlayList={setSearchPlayList}
            />
          );
        })()}
      </OnTubeViewerWrapper>
    </PageTemplate>
  );
};

const PageContent = (props: {
  contentItem: Material | (EventLive & { pinned?: boolean; endTime?: any; externalSrc?: any });
  isLive?: boolean;
  userId?: string;
  videosPlayListLoading: boolean;
  sameCategoryItems: MaterialSample[];
  pageConfigurationSameCategories: any[];
  setSearchPlayList: Function;
}) => {
  const {
    isLive,
    contentItem,
    userId,
    videosPlayListLoading,
    sameCategoryItems,
    pageConfigurationSameCategories,
    setSearchPlayList,
  } = props;

  const likes = useLikesSubscription({
    isLive: !!isLive,
    id: contentItem._id,
    initial: {
      count: contentItem.likes?.count || 0,
      liked: contentItem.likes?.liked || false,
    },
  });

  const liveViews = useEntityViewsSubscription(
    {
      roomId: props.contentItem._id,
      entity: isLive ? 'eventLive' : 'material',
    },
    undefined,
    undefined,
    3000,
  );

  const closeLive = useLiveClose(contentItem._id);

  const { listFolders, addFolder } = useFoldersPin();

  const analytics = useAnalytics();

  const history = useHistory();

  const trackedAnalytics = React.useRef('');

  const isLiveClosed = !!(isLiveContent(contentItem) && contentItem?.endTime);

  React.useEffect(() => {
    if (isLiveClosed) {
      history.push(routes.OnTube.mount());
    }
  }, [isLiveClosed]);

  React.useEffect(() => {
    //Se o video for uma live deve fazer o track quando entra na página
    if (isLive) {
      trackVideo();
    }
  }, [isLive]);

  const src = isLive ? undefined : contentItem.files?.find((f) => f?.kind === EnumFileKind.VideoMp4)?.signedUrl;

  const isOwner = userId === contentItem.owner;

  if (isLiveClosed) {
    return <h1>Live encerrada.</h1>;
  }

  let customVideoUrl = '';

  if (isLive) {
    customVideoUrl = (contentItem as EventLive).videoUrl || '';
  } else {
    customVideoUrl = (contentItem as Material & { externalSrc?: string }).externalSrc || '';
  }

  function trackVideo() {
    // videos não tem o track feito automaticamento no backend, pois o track é feito no play do video.
    if (isLive) return; // No caso das lives o track é feito automaticamente no backend, igual os demais materiais

    if (trackedAnalytics.current === contentItem._id) return;
    trackedAnalytics.current = contentItem._id;

    analytics.track({
      event: 'view',
      kind: EnumAnalyticsParentKind.Video,
      recordId: contentItem._id,
      entityName: 'Material',
      singleDoc: true,
    });
  }

  return (
    <VideoPlaylist
      videoRef={function(ref) {
        ref.onplay = function() {
          //Se não for live deve fazer o track do video quando clica no botão de play
          if (!isLive) {
            trackVideo();
          }
        };
      }}
      renderPlayer={customVideoUrl ? () => <LivePlayer url={customVideoUrl} /> : undefined}
      defaultImage="/defaultImages/tim.jpg"
      isLive={isLive}
      videosPlaylist={sameCategoryItems}
      search={true}
      firstVideo={{
        _id: contentItem._id,
        createdAt: contentItem.createdAt,
        updatedAt: contentItem.updatedAt,
        title: contentItem.title || '',
        description: contentItem.description || '',
        categories: contentItem.categories || [],
        likes: likes,
        views: isLive ? liveViews.views : contentItem.views,
        src: src || undefined,
        pinned: contentItem.pinned,
      }}
      onCloseLive={isLive && isOwner ? closeLive : undefined}
      onClickVideo={(_id: any) => history.push(routes.OnTubePlayer.mount(_id))}
      pageConfigurationSameCategories={pageConfigurationSameCategories}
      isLoadingPlayList={videosPlayListLoading}
      setSearchAPI={setSearchPlayList}
      actions={{
        pinFolder: {
          actionAddFolder: addFolder,
          listFolders,
        },
      }}
      plataform="consumer"
    />
  );
};

function useLiveClose(id?: string) {
  const history = useHistory();
  const client = useClient('EventLiveCloseRoom');

  return function() {
    if (!id) return null;

    return client
      .fetch({
        variables: {
          eventId: id,
        },
        afterMutate: /EventLive/,
      })
      .then((ctx) => {
        if (!ctx.errors?.length) {
          message.success('Live encerrada.');
          history.push(routes.OnTube.mount());
        }
      });
  };
}

function isLiveContent(item: any): item is EventLive {
  return typeof item?.videoUrl === 'string';
}

function useLikesSubscription(props: { isLive: boolean; id?: string; initial: { count: number; liked: boolean } }) {
  const { isLive, id } = props;
  const [likes, setLikes] = React.useState(props.initial);
  const methodName = isLive ? 'EventLiveFindById' : 'MaterialFindById';
  const client = useClient(methodName);

  React.useMemo(() => {
    setLikes(props.initial);
  }, [props.initial.count, props.initial.liked]);

  useEntitySubscription(
    {
      entity: isLive ? 'eventLive' : 'material',
      roomId: id!,
    },
    (ev) => {
      if (ev._id === id) {
        fetchGraphql(methodName, { variables: { _id: id }, fragment: fragmentSearchLikes }, client.store)().then(
          (ctx) => {
            if (ctx.result) {
              setLikes({
                count: ctx.result.likes.count,
                liked: ctx.result.likes.liked,
              });
            }
          },
        );
      }
    },
  );

  return likes;
}

// fragmento comum entre video e live event
const commonFragment = `
  _id
  title
  description
  publishedAt
  pinned
  categories {
    _id
    parentId
    name
  }

  likes {
    liked
    count
  }
  
  views {
    count
  }
  
  files {
    title
    kind
    signedUrl
  }
`;

const fragmentSearchLikes = `
likes {
  liked
  count
}
`;

const fragmentMaterialPagination = `
pageInfo {
  currentPage
  pageCount
  itemCount
}
items {
  _id
  createdAt
  updatedAt
  title
  description
  pinned
  likes {
    liked
    count
  }

  views {
    count
  }
  
  files {
    _id
    kind
    signedUrl
  }

  categories {
    name
  }
}
`;
