import React, { useEffect, useState, useCallback } from 'react';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select,
  TypeCard,
  TypeCardDescription,
  LoadingIndicator,
} from '@digi-tim-19/components';
import { useHistory } from 'react-router';
import { columns } from './columns';
import { formatDate, useDimensions } from '@digi-tim-19/utils/build';
import { useClient } from '../../../../autogenerated/client/client';
import { ValidityEnum, SortFindManyBannerInput, EnumAnalyticsParentKind } from '../../../../autogenerated/client/types';

import { PageWrapper, FilterContainer, Button, Search, CleanButton } from './styleListar';
import { routes } from '../../../../config/routes';
import moment from 'moment';
import { PageTemplate } from '../../../../components/Layout/PageTemplate';
import { Empty, message, DatePicker } from 'antd';
import { useFilterAuthor } from '../../../../components/FilterAuthor/useFilterAuthor';

const { RangePicker } = DatePicker;

function getPosition(value: string): TypeCard {
  switch (value) {
    case '1':
      return TypeCard.LARGE;
    case '2':
      return TypeCard.MEDIUM1;
    case '3':
      return TypeCard.MEDIUM2;
    case '4':
      return TypeCard.SMALL;
    case '5':
      return TypeCard.BANNER;
    case '6':
      return TypeCard.SMALL2;
    case '7':
      return TypeCard.BANNER_FOOTER;
    default:
      return TypeCard.LARGE;
  }
}

export const BannerListar: React.FC<TBannerListarProps> = () => {
  const filterAuthor = useFilterAuthor();

  const configPage = {
    pageName: 'Banners e cards',
    icon: 'file-image',
    description: 'Gerenciamento de banners e cards da home.',
    messageDeletedSuccess: 'Banner deletado com sucesso',
    textButtonNew: 'CADASTRAR',
    routeNewRegister: routes.bannersCadastrar.mount(),
    routeEdit: (id: string) => routes.bannersEditar.mount(id),
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo' },
      { title: 'Banners e cards' },
    ],
  };

  const { isMobile } = useDimensions();

  const [sort, setSort] = useState(SortFindManyBannerInput.IdDesc);
  const [status, setStatus] = useState();
  const [channel, setChannel] = useState();
  const [position, setPosition] = useState();
  const [filterSearch, setFilterSearch] = useState();
  const [filterData, setFilterData] = useState<any>([]);

  const [channels, setChannels] = useState();
  const [positions, setPositions] = useState<any>();

  const [createdAt, setCreatedAt] = useState();
  const [show, setShow] = useState([]);

  const [updatedAt, setUpdatedAt] = useState();
  const [showUpdatedAt, setShowUpdatedAt] = useState([]);

  const history = useHistory();

  const getChannels = useClient('ChannelFindMany');
  const getBanners = useClient('BannerFindMany');
  const BannersOnDelete = useClient('BannerRemoveById');
  const analitcsPageView = useClient('AnalyticsPageView');

  useEffect(() => {
    getChannels.fetch().then((ctx) => {
      if (ctx.result) {
        const resultChannels = ctx.result.map((item) => ({
          label: item?.name,
          value: item?._id,
        }));

        setChannels(resultChannels);
      }
    });

    let resultPositions = [];

    for (const item in TypeCardDescription) {
      if (TypeCardDescription.hasOwnProperty(item)) {
        const num = getPosition(item);
        const element = TypeCardDescription[num];
        resultPositions.push({
          label: element,
          value: item,
        });
      }
    }

    setPositions(resultPositions);
  }, []); //eslint-disable-line

  useEffect(() => {
    let availableAtChannels = channel ? [channel] : undefined;

    getBanners.fetch({
      variables: {
        limit: 123,
        filter: {
          validityEnum: ValidityEnum.Any,
          availableAtChannels,
          position,
          filterAuthor: filterAuthor.filter,
          owner: filterAuthor.ownerFilter,
          createdAtRange: createdAt,
          updatedAtRange: updatedAt,
        },
        sort,
      },
      appendToFragment: ` images { link fileId display signedUrl }  validity { start end } user { name }
    changedBy {
      name
      dateText
    },`,
    });
  }, [sort, channel, position, filterAuthor.signature, createdAt, updatedAt]); // eslint-disable-line

  const resetFilters = () => {
    setSort(SortFindManyBannerInput.IdDesc);
    setStatus(undefined);
    setChannel(undefined);
    setPosition(undefined);
    setFilterSearch(undefined);
    setFilterData([]);
    filterAuthor.reset();
    setUpdatedAt(undefined);
    setShowUpdatedAt([]);
  };

  const [data, setData] = useState<any[]>([]);

  const showBanner = (banner: any) => {
    let urlSigned = '';

    if (!isMobile) {
      const url = banner.images.filter((e: any) => e.display != 'mobile');
      window.open(url[0].signedUrl);
      urlSigned = url[0].signedUrl;
    } else {
      const url = banner.images.filter((e: any) => e.display == 'mobile');
      window.open(url[0].signedUrl);
      urlSigned = url[0].signedUrl;
    }

    if (urlSigned) {
      analitcsPageView.fetch({
        variables: {
          title: 'Link Externo',
          externalLink: urlSigned,
          kind: EnumAnalyticsParentKind.ExternalPageView,
        },
      });
    }
  };

  function builData(banner: any) {
    const validity = banner?.validity
      ? `${formatDate(banner?.validity.start, 'DD/MM/YYYY')} até ${formatDate(banner?.validity.end, 'DD/MM/YYYY')}`
      : ' - ';

    const isAfter = banner?.validity ? moment(banner?.validity.end).isAfter(new Date()) : true;

    const positionBanner = getPosition(banner?.position);

    return {
      changedBy: banner?.changedBy,
      key: banner?._id,
      createdAt: banner ? formatDate(banner.createdAt, 'DD/MM/YYYY') : '',
      atualizada: banner ? formatDate(banner.updatedAt, 'DD/MM/YYYY') : '',
      title: banner?.title,
      position: TypeCardDescription[positionBanner],
      author: banner?.user?.name,
      validity: validity,
      sort: banner?.sort,
      status: isAfter ? 'Ativo' : 'Inativo',
      onEdit: () => history.push(configPage.routeEdit(banner?._id)),
      onRead: () => showBanner(banner),
      onDelete: () =>
        BannersOnDelete.fetch({
          variables: { _id: banner?._id },
          afterMutate: /^Banner/,
        }).then((ctx) => {
          message.success(configPage.messageDeletedSuccess);
        }),
    };
  }

  useEffect(() => {
    if (getBanners.result) {
      const dataResult = getBanners.result.map(builData);
      setData(dataResult);
    }
  }, [getBanners.result]); //eslint-disable-line

  useEffect(() => {
    if (getBanners.result) {
      if (status) {
        if (status === 1) {
          const dataFiltered = getBanners.result.filter((banner) => {
            const active = banner?.validity ? moment(banner?.validity.end).isAfter(new Date()) : true;
            return active;
          });
          setData(dataFiltered.map(builData));
        } else if (status === 2) {
          const dataFiltered = getBanners.result.filter((banner) => {
            const active = banner?.validity ? moment(banner?.validity.end).isAfter(new Date()) : true;
            return !active;
          });
          setData(dataFiltered.map(builData));
        }
      } else {
        const dataResult = getBanners.result.map(builData);
        setData(dataResult);
      }
    }
  }, [status]); //eslint-disable-line

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
      setFilterData(data?.filter((material) => material.title.toLowerCase().includes(e.toLowerCase())));
    },
    [data],
  );

  const onChange = (e: any) => {
    if (e.length > 0) {
      setCreatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString(),
      });
      setShow(e);
    } else {
      setShow([]);
      setCreatedAt(undefined);
    }
  };

  const onChangeUpdatedAt = (e: any) => {
    if (e.length > 0) {
      setUpdatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString(),
      });
      setShowUpdatedAt(e);
    } else {
      setShowUpdatedAt([]);
      setUpdatedAt(undefined);
    }
  };

  function render() {
    if (getBanners.loading) {
      return <LoadingIndicator />;
    }

    if (!data?.length) return <Empty description={'Nenhum dado encontrado.'} />;

    return <Table columns={columns} dataSource={filterData.length > 0 ? filterData : data} />;
  }

  return (
    <PageTemplate>
      <>
        <PageWrapper>
          <Breadcrumb items={configPage.breadcrumb} />
          <TitleDescription
            iconType={configPage.icon}
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>
        <FilterContainer>
          <Button to={configPage.routeNewRegister}>{configPage.textButtonNew}</Button>
          <Select placeholder="Ordenar por" options={orders} onChange={(e: any) => setSort(e)} value={sort} />
          <Select placeholder="Canais" options={channels} onChange={(e: any) => setChannel(e)} value={channel} />
          <Select placeholder="Status" options={statusList} onChange={(e: any) => setStatus(e)} value={status} />
          <Select placeholder="Posição" options={positions} onChange={(e: any) => setPosition(e)} value={position} />
          <Search
            placeholder="Busca título"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e) => onSearch(e.target.value)}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={'DD/MM/YYYY'}
            placeholder={['CADASTRO', '']}
            onChange={(e) => {
              onChange(e);
            }}
            value={show}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={'DD/MM/YYYY'}
            placeholder={['ATUALIZAÇÃO', '']}
            onChange={(e) => {
              onChangeUpdatedAt(e);
            }}
            value={showUpdatedAt}
          />

          {filterAuthor.render()}

          <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
        </FilterContainer>

        {render()}
      </>
    </PageTemplate>
  );
};

export type TBannerListarProps = {};

const orders = [
  { label: 'MAIS ANTIGO', value: SortFindManyBannerInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyBannerInput.IdDesc },
];

const statusList = [
  { label: 'Ativo', value: 1 },
  { label: 'Inativo', value: 2 },
];
