import { useClient } from '../../autogenerated/client/client';
import * as React from 'react';

export function useReconheceWallet(id: string, appendToFragment: any) {
  const variables = { _id: id };

  const ReconheceWalletFindOne = useClient('ReconheceWalletFindById', {
    variables,
    appendToFragment
  });

  React.useEffect(() => {
    if (id) {
      ReconheceWalletFindOne.fetch({ variables });
    }
  }, [id]);

  return ReconheceWalletFindOne;
}
