import { useMemo } from 'react';
import { SortFindManyPinInput } from '../../autogenerated/client/types';

export type KindFavorito = 'video' | null;
export enum ListMode {
  card,
  list
}

export function useSelectsOrders(setSort: Function) {
  const selectsOrders = useMemo(() => {
    return [
      {
        defaultValue: 'Ordenar Por',
        action: (value: SortFindManyPinInput) => {
          setSort(value);
        },
        options: [
          {
            name: 'Mais antigo',
            value: SortFindManyPinInput.IdAsc
          },
          {
            name: 'Mais recente',
            value: SortFindManyPinInput.IdDesc
          }
        ]
      }
    ];
  }, []);

  return {
    selectsOrders
  };
}
