import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { RegulamentoWrapper } from '../Cadastrar/stylesCadastrar';
import { useRouteMatch } from 'react-router';
import { useMaterial } from '../../../hooks/materials/useMaterial';

export const SistemaVendasEditar = () => {
  const { params } = useRouteMatch();
  const appendToFragment = `
    title
    description
    fileIds
    files {
      _id
      title
      kind
    }
    categories {
      name
      parentId
      _id
    }
    availableAtRegions
    availableAtChannels
    availableAtRoleGroups
`;
  const linksUteis = useMaterial(params?.id, appendToFragment);

  return (
    <PageTemplate>
      <>
        <RegulamentoWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: 'home' },
              { title: 'Gerenciar conteúdo' },
              { title: 'Sistemas de Vendas' },
              { title: 'Cadastrar' }
            ]}
          />
          <TitleDescription
            iconType={'laptop'}
            title={'Sistemas de Vendas'}
            description={
              'Nesta página constam os links para acessar os sistemas, pós vendas e administrativos.'
            }
          />
        </RegulamentoWrapper>

        {linksUteis.result && (
          <ProviderSelecionarPublico>
            <Form initialValues={linksUteis.result} />
          </ProviderSelecionarPublico>
        )}
      </>
    </PageTemplate>
  );
};
