import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  Breadcrumb,
  TitleDescription,
  MiniCard
} from '@digi-tim-19/components';
import { Select, Input, DatePicker, Pagination } from 'antd';
import { useHistory, useParams } from 'react-router';

import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyMaterialInput
} from '../../autogenerated/client/types';
import { routes } from '../../config/routes';
import { findCorrectName } from '../../utils/productNames';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';
import { PaginationWrapper } from '../../components/Page/PaginationWrapper';
import { getRouterByMaterial } from '@digi-tim-19/utils/build/routes/routesConsumer';
import { escapeStringRegexp } from '../../utils/formatters';

const { Search: AntdSearch } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const ConsumerContent = styled.section`
  .actions {
    & .action-controls {
      margin-top: 20px;
      .ant-checkbox-wrapper {
        margin-left: 0px;
        margin-bottom: 5px;
        .ant-checkbox + span {
          font-size: 16px;
          font-family: TimMedium;
          color: ${(props) => props.theme.grayMedium2};
        }
      }
    }
  }

  /* .cards {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
  } */
`;

const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;

  .ant-calendar-range-picker-separator {
    display: none !important;
  }
  @media only screen and (max-width: 900px) {
    .ant-calendar-picker-container {
      width: 100%;
      padding: 0 15px;
    }
    .ant-calendar-range {
      width: 100% !important;
    }
    .ant-calendar-date-panel {
      display: flex !important;
      flex-direction: column !important;
      .ant-calendar-range-part {
        width: 100%;
      }
    }
  }
`;

const WrapperCard = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;

  cursor: pointer;
`;

export const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

type TCreatedAt = {
  start?: string;
  end?: string;
};

export const MapaPecas = () => {
  const history = useHistory();

  const perPage = 10;
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);

  const [createdAt, setCreatedAt] = useState<TCreatedAt>();
  const { product, category } = useParams();
  const [filterSelectInitial, setFilterSelectInitial] = useState('Status');
  const [filterSearch, setFilterSearch] = useState();
  const [show, setShow] = useState<any[]>([]);
  const [validityEnumFilter, setValidityEnumFilter] = useState<ValidityEnum>();

  const getMaterials = useClient('MaterialPagination');

  const total = getMaterials.result?.pageInfo.itemCount;

  const resetFilters = () => {
    setCreatedAt(undefined);
    setFilterSearch(undefined);
    setValidityEnumFilter(ValidityEnum.Any);
    setFilterSelectInitial('all');
    setShow([]);
    setPage(1);
  };

  const filterSelected = (v: string) => {
    if (v === 'active') {
      setValidityEnumFilter(ValidityEnum.Current);
      setFilterSelectInitial('Ativo');
    }
    if (v === 'inactive') {
      setValidityEnumFilter(ValidityEnum.Expired);
      setFilterSelectInitial('Inativo');
    }
    if (v === 'all') {
      setValidityEnumFilter(ValidityEnum.Any);
      setFilterSelectInitial('Todos');
    }
  };

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          sort: SortFindManyMaterialInput.CreatedatDesc,
          filter: {
            kind: EnumMaterialKind.PartsMap,
            validityEnum: validityEnumFilter,
            createdAtRange: createdAt,
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
          }
        }
      })
      .then((response: any) => {
        setItems(response.result?.items);
      });
  }, [category, product, validityEnumFilter, createdAt, page, filterSearch]); //eslint-disable-line

  const data = getMaterials?.result;

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
    },
    [data]
  );

  const onChange = (e: any[]) => {
    if (e.length === 0) setCreatedAt(undefined);
    else {
      setCreatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
    }
    setShow(e);
  };

  const { listFolders, addFolder } = useFoldersPin();

  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: category ? findCorrectName(category) : '' },
            {
              title: product ? findCorrectName(product) : '',
              link:
                category && product
                  ? routes.selectFilter.mount(category, product)
                  : ''
            },
            {
              title: 'Book de Soluções e Mapa de Peças'
            }
          ]}
        />

        <TitleDescription
          customIcon
          iconType={'iconmap2'}
          title={'Book de Soluções e Mapa de Peças'}
          description={'Soluções da TIM e guia dos materiais pra clientes.'}
        />

        <ConsumerContent>
          <FilterContainer>
            <Select
              defaultValue="Status"
              value={filterSelectInitial}
              onChange={(value: string) => filterSelected(value)}
            >
              <Option value="active">Ativo</Option>
              <Option value="inactive">Inativo</Option>
              <Option value="all">Todos</Option>
            </Select>

            <RangePicker
              getCalendarContainer={(triggerNode: any) =>
                triggerNode.parentNode
              }
              format="DD/MM/YYYY"
              placeholder={['PERÍODO', '']}
              onChange={(e) => {
                setPage(1);
                onChange(e);
              }}
              value={show}
            />
            <Search
              placeholder="Busca por título"
              style={{ width: 200 }}
              value={filterSearch}
              onChange={(e) => {
                setPage(1);
                onSearch(e.target.value);
              }}
            />
            <CleanButton onClick={() => resetFilters()}>
              Limpar filtros
            </CleanButton>
          </FilterContainer>
          <WrapperCard>
            {items.map((item: any, index: number) => {
              const routesMaterial = getRouterByMaterial(item, routes);

              return (
                <MiniCard
                  key={index}
                  material={item}
                  btn={() => history.push(routesMaterial.routerAccess)}
                  // actions={() => actionsButton(listFolders, addFolder)}
                />
              );
            })}
          </WrapperCard>
          <PaginationWrapper>
            <Pagination
              pageSize={perPage}
              current={page}
              total={total!}
              onChange={(page) => setPage(page)}
            />
          </PaginationWrapper>
        </ConsumerContent>
      </React.Fragment>
    </PageTemplate>
  );
};

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    fileIds
    _id
    title
    numericId
    createdAt
    kind
    files {
      _id
      extension
      signedUrl
      kind
      downloadUrl
      url
    }
    validity { 
      start 
      end 
    }
    author { 
      _id
      name 
    }
  }
`;
