import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Pagination } from 'antd';
import {
  Breadcrumb,
  TitleDescription,
  CardConsumer,
  LoadingIndicator,
  CardInternoFragment
} from '@digi-tim-19/components';
import { useHistory } from 'react-router';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';
import { EnumMaterialKind } from '../../autogenerated/client/types';
import {
  obterImagemPadraoPorMaterial,
  actionsCardConsumer
} from '../../utils/material';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';
import { PaginationWrapper } from '../../components/Page/PaginationWrapper';

const PageContent = styled.div`
  .actions {
    display: block;
    & > * {
      width: 100%;
      margin-left: 0;
      margin-bottom: 5px;
    }
    @media screen and (min-width: 1024px) {
      display: flex;
      align-items: center;
      & > * {
        margin-right: 40px;
      }
    }
  }
`;
const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const MapaDeOfertas = () => {
  const perPage = 20;
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);

  const getMaterials = useClient('MaterialPagination');

  const total = getMaterials.result?.pageInfo.itemCount;

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            kind: EnumMaterialKind.OfferMap
          }
        }
      })
      .then((response: any) => {
        setItems(response.result?.items);
      });
  }, [page]); //eslint-disable-line

  const { listFolders, addFolder } = useFoldersPin();

  const history = useHistory();
  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Mapa De Ofertas' }
          ]}
        />

        <TitleDescription
          customIcon
          iconType={'iconmap'}
          title={'Mapa de Ofertas'}
          description={
            'Aqui você irá encontrar o cardápio e argumentário de todas as ofertas.'
          }
        />
        <PageContent>
          {getMaterials.loading ? (
            <LoadingIndicator />
          ) : (
            <CardsWrapper>
              {items &&
                items.map((item: any, index: number) => {
                  const thumb = obterImagemPadraoPorMaterial(item);

                  return (
                    <CardConsumer
                      key={index}
                      hideTitle={true}
                      material={item}
                      defaultImage={thumb}
                      actions={(material) =>
                        actionsCardConsumer(
                          material,
                          routes,
                          history,
                          listFolders,
                          addFolder,
                          true
                        )
                      }
                    />
                  );
                })}
            </CardsWrapper>
          )}
          <PaginationWrapper>
            <Pagination
              pageSize={perPage}
              current={page}
              total={total!}
              onChange={(page) => setPage(page)}
            />
          </PaginationWrapper>
        </PageContent>
      </React.Fragment>
    </PageTemplate>
  );
};

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    fileIds
    _id
    pinned
    title
    numericId
    createdAt
    updatedAt
    kind
    files {
      _id
      extension
      signedUrl
      kind
      downloadUrl
      url
    }
    validity { 
      start 
      end 
    }
    author { 
      _id
      name 
    }
  }
`;
