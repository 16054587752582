import React from 'react';
import styled from 'styled-components';

import { PageTemplate } from '../../components/Layout/PageTemplate';
import { Breadcrumb, TitleDescription, ProviderXlsxJson, useXlsxJsonActions, Former } from '@digi-tim-19/components';
import { routes } from '../../config/routes';
import {
  EnumFileKind,
  EnumImportUsersPlanStatusImportUser,
  EnumImportUsersPlanOrigin,
} from '../../autogenerated/client/types';
import { useClient } from '../../autogenerated/client/client';
import { message } from 'antd';

const optionsOrigin = [
  {
    label: EnumImportUsersPlanOrigin.Sigtim,
    value: EnumImportUsersPlanOrigin.Sigtim,
  },
  {
    label: EnumImportUsersPlanOrigin.Radar,
    value: EnumImportUsersPlanOrigin.Radar,
  },
  {
    label: EnumImportUsersPlanOrigin.Import,
    value: EnumImportUsersPlanOrigin.Import,
  },
  {
    label: EnumImportUsersPlanOrigin.Basemulti,
    value: EnumImportUsersPlanOrigin.Basemulti,
  },
];

const ImportPlanUsersWrapper = styled.div``;

export const ImportPlanUsers = () => {
  const xlsxJsonActions = useXlsxJsonActions();

  const uploadFileImportUsers = useClient('ImportUsersPlanCreateOne');

  return (
    <PageTemplate>
      <ImportPlanUsersWrapper>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo' },
            { title: 'Importação de usuários' },
          ]}
        />
        <TitleDescription
          iconType="user"
          title="Importaçao de usuários"
          description={'Cadastramento de usuários por importação de planilha.'}
        />

        <ProviderXlsxJson>
          <Former
            config={{
              fields: [
                {
                  inline: true,
                  list: [
                    {
                      inline: true,
                      name: 'fileId',
                      label: '',
                      type: 'upload',
                      required: true,
                      extraProps: {
                        kind: EnumFileKind.Xlsx,
                        CTA: 'PLANILHA',
                        multiple: false,
                        onSelectFile: (info: any) => {
                          const file = info.file.originFileObj;
                          if (file) {
                            xlsxJsonActions.setState('file', file);
                          }
                        },
                      },
                    },
                    {
                      inline: true,
                      name: 'origin',
                      label: 'Origem',
                      options: optionsOrigin,
                      extraProps: {
                        maxWidth: '250px',
                      },
                    },
                  ],
                },
              ],
              submitButton: {
                label: 'Enviar',
              },
            }}
            onSubmit={async ({ data }: any) => {
              uploadFileImportUsers
                .fetch({
                  variables: {
                    record: {
                      fileId: data.fileId,
                      statusImportUser: EnumImportUsersPlanStatusImportUser.Pending,
                      origin: data.origin,
                    },
                  },
                })
                .then((ctx) => {
                  if (ctx.result) {
                    message.success('Você receberá um e-mail ao final do processamento do arquivo');
                  }
                });
            }}
          />
        </ProviderXlsxJson>
      </ImportPlanUsersWrapper>
    </PageTemplate>
  );
};
