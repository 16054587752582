import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  VideoBanner,
  LastVideoSection,
  PlaylistSection,
  LoadingIndicator
} from '@digi-tim-19/components';

import { PageTemplateNoMargin } from '../../components/Layout/PageTemplateNoMargin';
import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';
import {
  EnumMaterialKind,
  SortFindManyMaterialInput,
  SortFindManyEventLiveInput,
  ValidityEnum
} from '../../autogenerated/client/types';
import { actionsButton } from '../../utils/material';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';

export const OnTube = () => {
  const history = useHistory();
  const [lastVideos, setLastVideos] = useState<any[]>([]);

  const eventFind = useClient('EventLivePagination', {
    variables: {
      perPage: 1,
      sort: SortFindManyEventLiveInput.EventdateAsc,
      filter: {
        endTime: null
      }
    },
    fetchOnMount: true,
    appendToFragment: bannerFragment
  });

  const lastVideosConfigured = useClient('MaterialFindMany', {
    variables: {
      limit: 3,
      sort: SortFindManyMaterialInput.CreatedatDesc,
      filter: {
        latestPublications: true,
        kind: EnumMaterialKind.Video,
        validityEnum: ValidityEnum.Current
      }
    },
    fetchOnMount: true,
    appendToFragment: lastVideosFragment
  });

  const lastVideosDate = useClient('MaterialFindMany', {
    variables: {
      limit: 3,
      sort: SortFindManyMaterialInput.CreatedatDesc,
      filter: {
        kind: EnumMaterialKind.Video,
        inCategories: ['regex(^video_playlist)']
      }
    },
    fetchOnMount: true,
    appendToFragment: lastVideosFragment
  });

  const detachedVideos = useClient('MaterialFindMany', {
    variables: {
      filter: {
        kind: EnumMaterialKind.Video,
        detachedVideo: true,
        validityEnum: ValidityEnum.Current
      },
      limit: 1
    },
    fetchOnMount: true,
    appendToFragment: lastVideosFragment
  });

  const playlistsFind: any = useClient('CategoryFindMany', {
    variables: {
      limit: 0,
      filter: {
        parentId: 'video_playlist'
      }
    },
    fetchOnMount: true,
    appendToFragment: playlistFragment
  });

  useEffect(() => {
    if (lastVideosDate.result && lastVideosConfigured.result) {
      const videosConfigured: any[] = (lastVideosConfigured?.result || []).sort(
        (a: any, b: any) => {
          if (a.orderLatestPublications > b.orderLatestPublications) return 1;
          else if (b.orderLatestPublications > a.orderLatestPublications)
            return -1;
          else return 0;
        }
      );
      const lastVideos: any[] = lastVideosDate?.result || [];

      let result = [];
      let indexLastVideo = 0;
      for (let index = 0; index < 3; index++) {
        const order = index + 1;
        const videoFound = videosConfigured.find(
          (item) => item.orderLatestPublications === order
        );
        if (videoFound) {
          result.push(videoFound);
        } else {
          result.push(lastVideos[indexLastVideo]);
          indexLastVideo++;
        }
      }

      setLastVideos(result);
    }
  }, [lastVideosDate.result, lastVideosConfigured.result]);

  function changePlaylistName(pl: any) {
    if (pl?.name === 'Live Consumer') {
      return { ...pl, name: 'Live Consumer & SMB' };
    }
    return pl;
  }

  function changePlaylistCategoryName(pl: any) {
    let result = pl;
    if (pl?.categories[0]?.name === 'Live Consumer') {
      result = {
        ...pl,
        categories: [
          { name: 'Live Consumer & SMB', _id: 'video_playlist:live_consumer' }
        ]
      };
    }

    if (pl.mainCategory) {
      result = {
        ...result,
        categories: [pl.mainCategory]
      };
    }

    return result;
  }

  const eventResult = (eventFind.result?.items || [])
    .map(changePlaylistCategoryName)
    .find((item: any) => item._id);
  const videoResult = (lastVideosDate.result?.slice(0, 1) || [])
    .map(changePlaylistCategoryName)
    .find((item: any) => item._id);

  const detachedVideo = (detachedVideos?.result || [])
    .map(changePlaylistCategoryName)
    .find((item: any) => item._id);

  const banner = eventResult || detachedVideo || videoResult;
  const bannerLoading =
    eventFind.loading && lastVideosConfigured.loading && lastVideosDate.loading;

  const playlistSort = playlistsFind?.result
    ?.map(changePlaylistName)
    .sort((a: any, b: any) => {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    });

  const { listFolders, addFolder } = useFoldersPin();

  return (
    <PageTemplateNoMargin>
      <>
        {!bannerLoading && !!banner ? (
          <VideoBanner
            videoBanner={{
              defaultImageOntubeMobile:
                '/defaultImages/banner-ontube-mobile-default.jpg',
              defaultImageOntubeDesktop:
                '/defaultImages/banner-ontube-desktop-default.jpg',
              defaultImageLiveMobile:
                '/defaultImages/banner-live-mobile-default.jpg',
              defaultImageLiveDesktop:
                '/defaultImages/banner-live-desktop-default.jpg',
              _id: banner._id || '',
              createdAt: banner.createdAt || '',
              eventDate: banner.eventDate || '',
              kind: banner.kind || '',
              title: banner.title || '',
              description: banner.description || '',
              categories: banner.categories || [],
              files: banner.files || []
            }}
            onClickBanner={(_id) =>
              history.push(routes.OnTubePlayer.mount(_id))
            }
          />
        ) : (
          <LoadingIndicator large />
        )}

        <LastVideoSection
          sectionTitle="Últimas publicações"
          lastVideos={lastVideos.map(changePlaylistCategoryName)}
          loading={lastVideosConfigured.loading || lastVideosDate.loading}
          onClickLastVideos={(_id) =>
            history.push(routes.OnTubePlayer.mount(_id))
          }
          actions={actionsButton(listFolders, addFolder)}
        />

        <PlaylistSection
          sectionTitle="Playlists"
          playlists={playlistSort}
          loading={playlistsFind.loading}
          onClickPlaylist={(videoID) =>
            history.push(routes.OnTubePlayer.mount(videoID))
          }
        />
      </>
    </PageTemplateNoMargin>
  );
};

const bannerFragment = `
  items {
    _id
    createdAt
    eventDate
    kind
    title
    description

    categories {
      name
    }

    files {
      kind
      signedUrl
    }
  }
`;

const lastVideosFragment = `
  _id
  title
  description
  createdAt

  likes {
    liked
    count
  }

  views { count }

  categories { name _id }
  mainCategory { name _id }
  orderLatestPublications

  files {
    kind
    signedUrl
  }
`;

const playlistFragment = `
  _id
  name
  parentId
  description
  materials(
    kind: video
    sort: CREATEDAT_DESC
    page: 1
    perPage: 500
  ) {
    count
    items {
      title
      createdAt
      _id
      pinned
      files {
        _id
        kind
        signedUrl
      }
    }
  }
`;
