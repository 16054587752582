import React from 'react';
import styled from 'styled-components';

interface Props {
  icon: React.ReactNode;
  title: string;
}

export const PageTitle = (props: Props) => {
  return (
    <PageTitleContainer>
      <IconContainer>{props.icon}</IconContainer>
      <Title>{props.title.toLocaleUpperCase()}</Title>
    </PageTitleContainer>
  );
};

const PageTitleContainer = styled.div`
  width: max-content;
  margin: -10px auto 30px -20px;
  padding: 10px 50px 10px 100px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #eeeeee;
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: 450px) {
    width: 100%;
    padding: 10px 20px 10px 90px;
  }
`;

const Title = styled.h3`
  margin: 0;
  font-weight: bold;
  color: ${(props) => props.theme.blue};

  @media (max-width: 450px) {
    font-size: 12pt;
  }
`;

const IconContainer = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  left: 15px;
  box-shadow: 0 0 5px 2px #727272;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  svg {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 450px) {
    width: 60px;
    height: 60px;
  }
`;
