import React, { useState, useCallback, useEffect } from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { TreeSelect, Input, DatePicker, Pagination } from 'antd';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import {
  Breadcrumb,
  TitleDescription,
  CardConsumer,
  verdade,
  LoadingIndicator
} from '@digi-tim-19/components';

import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { findCorrectName, findRedirect } from '../../utils/productNames';
import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyCategoryInput,
  SortFindManyMaterialInput
} from '../../autogenerated/client/types';
import {
  obterImagemPadraoPorMaterial,
  actionsCardConsumer
} from '../../utils/material';
import { EmptyData } from '../../components/utils/Void';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';
import { PaginationWrapper } from '../../components/Page/PaginationWrapper';
import { escapeStringRegexp } from '../../utils/formatters';

const { Search: AntdSearch } = Input;
const { RangePicker } = DatePicker;

const PageContent = styled.div``;

const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

const WrapperCard = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;
  .ant-calendar-range-picker-separator {
    display: none !important;
  }
  @media only screen and (max-width: 900px) {
    .ant-calendar-picker-container {
      width: 100%;
      padding: 0 15px;
    }
    .ant-calendar-range {
      width: 100% !important;
    }
    .ant-calendar-date-panel {
      display: flex !important;
      flex-direction: column !important;
      .ant-calendar-range-part {
        width: 100%;
      }
    }
  }
`;

export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;
  cursor: pointer;
`;

function removeInvalidCategoryId(category?: string, categories?: any[]) {
  if (!category) return '';

  let result = '';

  const categoryMatch = category.match(/undefined/) ? '' : category;

  if (categoryMatch.includes('regex')) {
    result = categoryMatch.replace(/regex/g, '').replace(/[{()}]/g, '');
  } else result = categoryMatch;

  if (!categories || !categories.length) return null;

  const someCategories = categories?.some((item: any) => item.value === result);

  const someChildren = categories?.some((item: any) =>
    item.children?.some((subItem: any) => subItem.value === result)
  );

  const someChildren2 = categories?.some((item: any) =>
    item.children?.some((subItem: any) =>
      subItem.children?.some((subItem2: any) => subItem2.value === result)
    )
  );

  const someChildren3 = categories?.some((item: any) =>
    item.children?.some((subItem: any) =>
      subItem.children?.some((subItem2: any) =>
        subItem2.children?.some((subItem3: any) => subItem3.value === result)
      )
    )
  );

  if (!someCategories && !someChildren && !someChildren2 && !someChildren3)
    return null;

  return result;
}

export const Informativos = () => {
  const { category, product } = useParams<{category: string, product: string}>();
  const history = useHistory();

  const perPage = 20;
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);

  let correctName = '';
  let firstFilter = '';

  if (category === 'tim_tech') correctName = findCorrectName(category);
  if (category === 'cardapio_smb') {
    firstFilter = `regex(menu:${category}:${product})`;
    correctName = findCorrectName(product || '');
  } else {
    if (!product) {
      firstFilter = !category ? 'regex(menu:informativos)' : `menu:${category}`;
    } else {
      correctName = product ? findCorrectName(product) : '';
      firstFilter = !category
        ? 'regex(menu:informativos)'
        : `menu:${category}:${product}`;
    }
  }

  const [categoryFilter, setCategoryFilter] = useState(firstFilter);
  const [filterSearch, setFilterSearch] = useState('');
  const [createdAt, setCreatedAt] = useState<{ start: string; end: string }>();
  const [show, setShow] = useState([]);

  const onChange = (e: any) => {
    setCreatedAt({
      start: moment(e[0]!).startOf('day')?.toISOString(),
      end: moment(e[1]!).endOf('day')?.toISOString()
    });
    setShow(e);
  };

  const getMaterials = useClient('MaterialPagination');

  const total = getMaterials.result?.pageInfo.itemCount;

  const getCategories = useClient('CategoryFindMany', {
    fetchOnMount: true,
    appendToFragment: `
    parentId
    _id
    subCategories {
      name
      _id
      subCategories {
        name
        _id
        subCategories {
          name
          _id
        }
      }
    }
    `,
    variables: {
      filter: {
        parentId:
          category === 'tim_tech'
            ? 'menu:tim_tech:informativos'
            : 'menu'
      },
      sort: SortFindManyCategoryInput.SortAsc, //FIXME: Verificar index para o campo de createdAt
      limit: 123
    }
  });
  const resetFilters = () => {
    setCreatedAt(undefined);
    setFilterSearch('');
    setCategoryFilter(firstFilter);
    setShow([]);
    setPage(1);
  };

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          limit: 50000,
          filter: {
            kind: EnumMaterialKind.Communication,
            validityEnum: ValidityEnum.Current,
            createdAtRange: createdAt,
            allCategories: [categoryFilter]
              .filter(Boolean)
              .concat(`regex(^menu:informativos)`),
              title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
          },
          sort: SortFindManyMaterialInput.IdDesc
        }
      })
      .then((response: any) => {
        setItems(response.result?.items);
      });
  }, [createdAt, categoryFilter, page, filterSearch]); //eslint-disable-line

  const corretPrefix = () => {
    switch (product) {
      case 'controle':
      case 'pos_pago':
      case 'pre_pago':
      case 'tim_fixo':
      case 'tim_empresa_digital':
      case 'tim_live_internet':
      case 'tim_ultrafibra':
      case 'tim_black_empresa__':
      case 'fixo':
        return 'do';
      default:
        if (category === 'tim_tech') return 'do';
        else return 'de';
    }
  };
  const data = getMaterials?.result;
  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
    },
    [data]
  );

  const categories = React.useMemo(() => {
    const cat = verdade(getCategories.result).filter((item) => {
      return (
        item._id !== 'menu:gerenciar_conteudo' &&
        item._id !== 'menu:mapa_de_ofertas' &&
        item._id !== 'menu:atividades_recentes' &&
        item._id !== 'menu:notificacoes' &&
        item._id !== 'menu:favoritos' &&
        item._id !== 'menu:informativos' &&
        item._id !== 'menu:sistemas_de_vendas' &&
        item._id !== 'menu:ontube' &&
        item._id !== 'menu:veja_mais'
      );
    });

    return cat.map((categoryItem) => {
      const selectable =
        category === 'tim_tech' ||
        categoryItem?._id === 'menu:outros' ||
        categoryItem?._id === 'menu:campanha_de_incentivo';
      return {
        title: categoryItem?.name,
        selectable: selectable,
        value: categoryItem?._id,
        key: categoryItem?._id,
        children: categoryItem?.subCategories?.map((subCategoryItem) => ({
          title: subCategoryItem?.name,
          value: subCategoryItem?._id,
          key: subCategoryItem?._id,
          children: subCategoryItem?.subCategories?.map(
            (subSubCategoryItem) => ({
              title: subSubCategoryItem?.name,
              value: subSubCategoryItem?._id,
              key: subSubCategoryItem?._id,
              children: subSubCategoryItem?.subCategories?.map(
                (subSubSubCategoryItem) => ({
                  title: subSubSubCategoryItem?.name,
                  value: subSubSubCategoryItem?._id,
                  key: subSubSubCategoryItem?._id
                })
              )
            })
          )
        }))
      };
    });
  }, [getCategories.result]);

  let descriptionPage = 'Aqui você encontra todos os informativos';
  if (!category && !product) descriptionPage += '.';
  else if (
    category === 'cardapio_smb' &&
    product !== 'solucoes' &&
    product !== 'servicos'
  )
    descriptionPage += ` dos produtos ${corretPrefix()} ${correctName}`;
  else if (category === 'cardapio_smb' && product === 'servicos')
    descriptionPage += ` dos serviços de valor agregado (VAS)`;
  else if (category === 'cardapio_smb' && product === 'solucoes')
    descriptionPage += ` das Soluções`;
  else descriptionPage += ` ${corretPrefix()} ${correctName}`;

  const { listFolders, addFolder } = useFoldersPin();

  const getRedirectUrl = (category: string, product: string) => {
    const routeRedirect = findRedirect(product);

    if (routeRedirect) return routeRedirect;

    if (category && product) return routes.selectFilter.mount(category, product);

    return '';
  };

  return (
    <PageTemplate>
      <>
        {category && !product ? (
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: category ? findCorrectName(category) : '', link: findRedirect(category) },
              { title: 'Informativos' }
            ]}
          />
        ) : null}
        {category && product ? (
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: category ? findCorrectName(category) : '' },
              {
                title: product ? findCorrectName(product) : '',
                link: getRedirectUrl(category, product)
              },
              { title: 'Informativos' }
            ]}
          />
        ) : null}
        {!category && !product ? (
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Informativos' }
            ]}
          />
        ) : null}

        <TitleDescription
          iconType={'info-circle'}
          title={'Informativos'}
          description={descriptionPage}
        />
        <PageContent>
          <FilterContainer>
            <TreeSelect
              className={cx('informative-filter')}
              dropdownStyle={{ maxHeight: 200, overflow: 'auto' }}
              treeData={categories}
              placeholder="Filtrar"
              treeDefaultExpandAll={false}
              onChange={(e: any) => {
                setPage(1);
                setCategoryFilter(e);
              }}
              value={
                removeInvalidCategoryId(categoryFilter, categories) || undefined
              }
            />

            <RangePicker
              getCalendarContainer={(triggerNode: any) =>
                triggerNode.parentNode
              }
              format="DD/MM/YYYY"
              placeholder={['PERÍODO', '']}
              onChange={(e) => {
                setPage(1);
                onChange(e);
              }}
              value={show}
            />
            <Search
              placeholder="Busca título"
              style={{ width: 200 }}
              value={filterSearch}
              onChange={(e) => {
                setPage(1);
                onSearch(e.target.value);
              }}
            />
            <CleanButton onClick={() => resetFilters()}>
              Limpar filtros
            </CleanButton>
          </FilterContainer>
          {getMaterials.loading ? (
            <LoadingIndicator />
          ) : (
            <WrapperCard>
              {getMaterials.loading ? (
                <LoadingIndicator />
              ) : items && items.length === 0 ? (
                <EmptyData />
              ) : (
                items &&
                items.map((item: any, index: number) => {
                  const thumb = obterImagemPadraoPorMaterial(item);

                  return (
                    <CardConsumer
                      key={index}
                      hideTitle={true}
                      material={item}
                      defaultImage={thumb}
                      actions={(material) =>
                        actionsCardConsumer(
                          material,
                          routes,
                          history,
                          listFolders,
                          addFolder
                        )
                      }
                    />
                  );
                })
              )}
            </WrapperCard>
          )}
          <PaginationWrapper>
            <Pagination
              pageSize={perPage}
              current={page}
              total={total!}
              onChange={(page) => setPage(page)}
            />
          </PaginationWrapper>
        </PageContent>
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    fileIds
    _id
    title
    numericId
    createdAt
    kind
    files {
      _id
      extension
      signedUrl
      kind
      downloadUrl
      url
    }
    validity { 
      start 
      end 
    }
    author { 
      _id
      name 
    }
    communicationItemsConfig {
      kind
    }
  }
`;
