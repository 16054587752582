import React, { useState, useEffect } from 'react';
import { formatDate } from '@digi-tim-19/utils';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  LoadingIndicator,
  Select
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';

import {
  NotificationsWrapper,
  FilterContainer,
  CleanButton,
  WrapperDescriptionCountedBlocked,
  DescriptionCountedBlocked,
  ListuserBlockWrapper
} from './stylesListar';

import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { useRouteMatch } from 'react-router';
import { SortFindManyUserHistoryBlockInput } from '../../../autogenerated/client/types';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

export const ListUserBlock: React.FC = () => {
  const { params } = useRouteMatch();

  const userId = params?.userId || '';

  const getUserBlocks = useClient('UserHistoryBlockFindMany');
  const getUser = useClient('UserFindOne');

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [userName, setUserName] = useState<string>('');
  const [period, setPeriod] = useState([]);
  const [createdAt, setCreatedAt] = useState<any>();
  const [isBlocked, setIsBlocked] = useState<string>();
  const [countBlocked, setCountBlocked] = useState<number>(0);
  const [countUnblocked, setCountUnblocked] = useState<number>(0);

  useEffect(() => {
    if (userId) {
      getUser
        .fetch({
          variables: {
            filter: {
              _id: userId
            }
          }
        })
        .then((ctx) => {
          if (ctx.result) {
            setUserName(ctx.result.name || '');
          }
        });
    }
  }, [userId]);

  useEffect(() => {
    getUserBlocks
      .fetch({
        fragment: fragmentBlocks,
        variables: {
          limit: 100,
          filter: {
            userId: userId,
            createdAtRange: createdAt,
            isBlocked:
              isBlocked === 'true'
                ? true
                : isBlocked === 'false'
                ? false
                : undefined
          },
          sort: SortFindManyUserHistoryBlockInput.CreatedatDesc
        }
      })
      .then((ctx) => {
        if (ctx.result) {
          let _countBlocked = 0;
          let _countUnblocked = 0;

          let color = '';

          const dtSource = ctx.result.map((item) => {
            let title = item?.Material?.title || 'Gerenciador de conteúdo';
            if (!!item?.isBlocked) {
              if (item?.Material?.kind === 'social_feed')
                title = item?.Material.description || '';

              _countBlocked++;
            } else {
              if (color === 'gray') color = '';
              else color = 'gray';

              title = 'Gerenciador de conteúdo';
              _countUnblocked++;
            }

            const hasFilterIsBlock = isBlocked !== undefined;

            return {
              owner: item?.Owner?.name,
              blocked: !!item?.isBlocked ? 'Sim' : 'Não',
              date: formatDate(item?.createdAt),
              title: title,
              kind: item?.Material?.kind || '',
              backgroundColor: hasFilterIsBlock ? '' : color
            };
          });

          setCountBlocked(_countBlocked);
          setCountUnblocked(_countUnblocked);

          setDataSource(dtSource);
        }
      });
  }, [userId, isBlocked, createdAt]);

  const onChangePeriod = (e: any) => {
    setCreatedAt({
      start: moment(e[0]!).startOf('day')?.toISOString(),
      end: moment(e[1]!).endOf('day')?.toISOString()
    });
    setPeriod(e);
  };

  function cleanFilters() {
    setIsBlocked(undefined);
    setCreatedAt(undefined);
    setPeriod([]);
  }

  return (
    <PageTemplate>
      <ListuserBlockWrapper>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              {
                title: 'Gerenciar Permissões',
                link: routes.gerenciarPermissoesListar.mount()
              },
              { title: 'Histórico de bloqueios' }
            ]}
          />
          <TitleDescription
            iconType="user"
            title="Histórico de bloqueios"
            description={`Histórico de bloqueios do usuário: ${userName}`}
          />
          <WrapperDescriptionCountedBlocked>
            <DescriptionCountedBlocked>
              Bloqueado {countBlocked} vezes
            </DescriptionCountedBlocked>
            <DescriptionCountedBlocked>
              Desbloqueado {countUnblocked} vezes
            </DescriptionCountedBlocked>
          </WrapperDescriptionCountedBlocked>
        </NotificationsWrapper>
        <FilterContainer>
          <Select
            placeholder="Bloqueado"
            options={[
              {
                label: 'Sim',
                value: 'true'
              },
              {
                label: 'Não',
                value: 'false'
              }
            ]}
            value={isBlocked}
            onChange={(e: string) => {
              setIsBlocked(e);
            }}
          />

          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format="DD/MM/YYYY"
            placeholder={['PERÍODO', '']}
            onChange={(e) => {
              onChangePeriod(e);
            }}
            value={period}
          />

          <CleanButton onClick={() => cleanFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>

        {getUserBlocks.loading && <LoadingIndicator />}
        {!getUserBlocks.loading && (
          <Table
            rowClassName={(record: any) => {
              const className = 'row-ListBlock';
              if (record.backgroundColor === 'gray') return `${className} gray`;

              return className;
            }}
            columns={columns}
            dataSource={dataSource}
          />
        )}
      </ListuserBlockWrapper>
    </PageTemplate>
  );
};

const limitCharacteres = 80;
const columns = [
  {
    title: 'Responsável pelo bloqueio',
    dataIndex: 'owner',
    key: 'owner'
  },
  {
    title: 'Título',
    dataIndex: 'title',
    key: 'title',
    render: (title: any) => {
      const text =
        title.length > limitCharacteres
          ? `${title.substring(0, limitCharacteres)}...`
          : title;
      return <p>{text}</p>;
    }
  },
  {
    title: 'Categoria',
    dataIndex: 'kind',
    key: 'kind',
    render: (text: any) => {
      return <p>{getKind(text)}</p>;
    }
  },
  {
    title: 'Bloqueado',
    dataIndex: 'blocked',
    key: 'blocked'
  },
  {
    title: 'Data',
    dataIndex: 'date',
    key: 'date'
  }
];

const fragmentBlocks = `
createdAt
isBlocked
User {
  name
}
Owner {
  name
}
Material {
  title
  description
  kind
}
`;

function getKind(text: string): string {
  switch (text) {
    case 'video':
      return 'OnTube';
    case 'social_feed':
      return 'Social Feed';
    default:
      return 'Gerenciador';
  }
}
