import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Breadcrumb,
  TitleDescription,
  CardCategory
} from '@digi-tim-19/components';
import { useParams, useHistory } from 'react-router';
import { routes } from '../../config/routes';
import { imgMateriaisPdv } from './images';

import { PageTemplate } from '../../components/Layout/PageTemplate';
import { findCorrectName } from '../../utils/productNames';

const useData = () => {
  const history = useHistory();

  const cards = getCards(history);

  //carrega todos os cards por padrão
  return [cards.mapa_pdv, cards.planograma, cards.guia_bolso];
};

const StyledContent = styled.section`
  background: transparent;
  display: flex;
  flex-wrap: wrap;
`;

export const MateriaisPDV = () => {
  const { product, category } = useParams<{
    product: string;
    category: string;
  }>();

  const items = useData();
  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            {
              title: 'Materiais para o PDV',
              link: routes.materiaisPDV.mount()
            },
            {
              title: product ? findCorrectName(product) : '',
              link:
                category && product ? routes.selectFilterPDV.mount(product) : ''
            }
          ]}
        />

        <TitleDescription
          iconType="shopping"
          customIcon={false}
          title={product ? findCorrectName(product) : 'Materiais para o PDV'}
          description="Aqui você encontra todas as informações necessárias sobre Materiais para o PDV"
        />
        <StyledContent>
          {items.map((card, index) => (
            <CardCategory key={index} {...card} />
          ))}
        </StyledContent>
      </React.Fragment>
    </PageTemplate>
  );
};

function getCards(history: any) {
  return {
    mapa_pdv: {
      title: 'Mapa de PDV',
      description:
        'Consulte todas as orientações que você precisa para deixar o PDV ainda mais atrativo para os clientes.',
      urlBackground: imgMateriaisPdv.imgMapaPdv,
      icon: 'environment',
      url: () => history.push(routes.selectFilterPDV.mount('mapa_de_pdv'))
    },
    planograma: {
      title: 'Planograma',
      description:
        'Consulte todas as orientações para auxiliar na organização dos acessórios nas lojas.',
      urlBackground: imgMateriaisPdv.imgPlanograma,
      icon: 'smile',
      url: () => history.push(routes.selectFilterPDV.mount('planograma'))
    },
    guia_bolso: {
      title: 'Guia de Bolso Ferramentas de Merchan',
      description:
        'Consulte os links importantes de merchan para o seu dia a dia.',
      urlBackground: imgMateriaisPdv.imgGuiaBolso,
      icon: 'mobile',
      url: () => history.push(routes.selectFilterPDV.mount('guia_de_bolso'))
    }
  };
}
