import React from 'react';
import moment from 'moment';
import { TableActions } from '@digi-tim-19/components';

export const columns = [
  {
    title: 'Nome',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>,
  },
  {
    title: 'Quantidade Colocações',
    dataIndex: 'placements',
    key: 'placements',
    align: 'center',
    render: (text: string) => <p style={{ textAlign: 'center' }}>{text}</p>,
  },
  {
    title: 'Data de criação',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string) => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
  },
  {
    title: 'Data de atualização',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (text: string) => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
  },
  {
    title: 'Ações',
    key: 'action',
    render: (_: any, record: any) => (
      <TableActions data={record} confirmationMessage={'Deseja excluir esse ranking?'} />
    ),
  },
] as any[];
