import {
  SortFindManyMaterialInput,
  EnumMaterialStatus
} from '../autogenerated/client/types';

export const order = [
  { label: 'MAIS ANTIGO', value: SortFindManyMaterialInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyMaterialInput.IdDesc }
];
export const status = [
  { label: 'EM EDIÇÃO', value: EnumMaterialStatus.Editing },
  { label: 'PUBLICADO', value: EnumMaterialStatus.Published },
  { label: 'CANCELADO', value: EnumMaterialStatus.Cancelled },
  { label: 'APROVADA', value: EnumMaterialStatus.Approved },
  { label: 'AGUARDANDO APROVAÇÃO', value: EnumMaterialStatus.WaitingApproval },
  { label: 'REPROVADA', value: EnumMaterialStatus.Disapproved }
];
export const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
