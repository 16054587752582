import casal from './casal.png';
import logoTim from './tim_logo.png';
import logoTimTemplateEmail from './logo-tim-template-email.png';
//import logoConsumer from './consumer.png';
import logoConsumer from './consumer20062023.png';
import tim_rodape from './img_tim_marca_rodape.png';
import iconYoutube from './footer_youtube.png';
import iconTwitter from './footer_twitter.png';
import iconLinkedin from './footer_linkedin.png';
import iconInstagram from './footer_Instagram.png';
import iconFacebook from './footer_facebook.png';
import googleStore from './google_store.png';
import appleStore from './apple_store.png';
import bgTemplateEmail640x300 from './bg-template-email640x300.jpg';
import bgTemplateEmail600x900 from './bg-template-email600x900.png';
import bgTemplateEmail640x200 from './bg-template-email640x200.jpg';
import bgTemplateEmail640x250White from './bg-template-email640x250-white.jpg';
import bgTemplateEmail640x250 from './bg-template-email640x250.jpg';
import bgTemplateEmail1000x420 from './bg-template-email1000x420.jpg';
import headerInformativoConsumer from './header_informativo_consumer.jpeg';
import footerInformativoConsumer from './footer_informativo_consumer.jpeg';

export const siteImages = {
  casal,
  logoTim,
  logoTimTemplateEmail,
  logoConsumer,
  iconYoutube,
  iconTwitter,
  iconLinkedin,
  iconInstagram,
  iconFacebook,
  googleStore,
  appleStore,
  tim_rodape,
  bgTemplateEmail640x300,
  bgTemplateEmail640x200,
  bgTemplateEmail640x250White,
  bgTemplateEmail640x250,
  bgTemplateEmail1000x420,
  headerInformativoConsumer,
  footerInformativoConsumer,
  bgTemplateEmail600x900,
};
