import React from 'react';
import * as S from './styles';
import { useDimensions } from '@digi-tim-19/utils';
import { Button } from '@digi-tim-19/components';

export const MeuDiaNoPdvEncerrado = () => {
  const dimensions = useDimensions();

  const downloadRegulamento = () => {
    var element = document.createElement('a');
    element.setAttribute('href', '/files/Regulamento_Meu_Dia_no_PDV_mães_24.pdf');
    element.setAttribute('download', 'Regulamento_Meu_Dia_no_PDV_mães_24.pdf');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  return (
    <S.Wrapper isMobile={dimensions.isMobile}>
      <S.Container>
        <S.MeuDiaPdvWrapper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <div style={{ width: '40%' }}>
              <img
                style={{ justifySelf: 'start' }}
                src="/defaultImages/meudianopdv.png"
                alt="meu-dia-no-pdv-logo"
                width="150px"
                height="150px"
              />
            </div>
            <div style={{ width: '60%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              <div>
                <h1 style={{ fontWeight: 'bold', textAlign: 'center' }}>MEU DIA NO PDV</h1>
              </div>
            </div>
          </div>
          <S.StyledContainer>
            <S.StyledTitle>Inscrições Encerradas!</S.StyledTitle>
          </S.StyledContainer>
          <Button onClick={downloadRegulamento}>Regulamento</Button>
        </S.MeuDiaPdvWrapper>
      </S.Container>
    </S.Wrapper>
  );
};
