import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { routes } from '../../config/routes';
import { Input, DatePicker, Pagination } from 'antd';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  TitleDescription,
  MiniCard
} from '@digi-tim-19/components';
import moment from 'moment';
import { useClient } from '../../autogenerated/client/client';
import {
  EnumMaterialKind,
  SortFindManyMaterialInput,
  ValidityEnum
} from '../../autogenerated/client/types';
import { useHistory } from 'react-router';
import { actionsButton } from '../../utils/material';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';
import { PaginationWrapper } from '../../components/Page/PaginationWrapper';
import { getRouterByMaterial } from '@digi-tim-19/utils/build/routes/routesConsumer';
import { escapeStringRegexp } from '../../utils/formatters';

const { Search: AntdSearch } = Input;
const { RangePicker } = DatePicker;
const PageContent = styled.div``;

const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

const WrapperCard = styled.div`
  .cards {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
  }
`;

const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;

  .ant-calendar-range-picker-separator {
    display: none !important;
  }
  @media only screen and (max-width: 900px) {
    .ant-calendar-picker-container {
      width: 100%;
      padding: 0 15px;
    }
    .ant-calendar-range {
      width: 100% !important;
    }
    .ant-calendar-date-panel {
      display: flex !important;
      flex-direction: column !important;
      .ant-calendar-range-part {
        width: 100%;
      }
    }
  }
`;

export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;

  cursor: pointer;
`;

export const VejaMaisCanal = () => {
  const history = useHistory();
  const [createdAt, setCreatedAt] = useState<{ start: string; end: string }>();
  const [show, setShow] = useState([]);

  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(1);
  const perPage = 20;

  const [filterSearch, setFilterSearch] = useState();

  const onChange = (e: any) => {
    setCreatedAt({
      start: moment(e[0]!).startOf('day')?.toISOString(),
      end: moment(e[1]!).endOf('day')?.toISOString()
    });
    setShow(e);
  };

  const getMaterials = useClient('MaterialPagination');

  const data = getMaterials?.result;

  const total = getMaterials.result?.pageInfo.itemCount;

  const resetFilters = () => {
    setCreatedAt(undefined);
    setFilterSearch(undefined);
    setShow([]);
    setPage(1);
  };

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            kind: EnumMaterialKind.SeeMoreChannel,
            createdAtRange: createdAt,
            validityEnum: ValidityEnum.Current,
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
          },
          sort: SortFindManyMaterialInput.IdDesc
        }
      })
      .then((response: any) => {
        setItems(response && response.result ? response.result.items : []);
      });
  }, [createdAt, filterSearch, page]); //eslint-disable-line

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
    },
    [data]
  );

  const { listFolders, addFolder } = useFoldersPin();

  return (
    <PageTemplate>
      <>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Veja Mais' },
            { title: 'Canal' }
          ]}
        />

        <TitleDescription
          iconType={'more'}
          title={'Canal'}
          description={`Aqui você encontra todos os documentos específicos do seu canal.`}
        />
        <PageContent>
          <FilterContainer>
            <RangePicker
              getCalendarContainer={(triggerNode: any) =>
                triggerNode.parentNode
              }
              format="DD/MM/YYYY"
              placeholder={['PERÍODO', '']}
              onChange={(e) => {
                setPage(1);
                onChange(e);
              }}
              value={show}
            />
            <Search
              placeholder="Busca título"
              style={{ width: 200 }}
              value={filterSearch}
              onChange={(e) => {
                setPage(1);
                onSearch(e.target.value);
              }}
            />
            <CleanButton onClick={() => resetFilters()}>
              Limpar filtros
            </CleanButton>
          </FilterContainer>
          <WrapperCard>
            <div className="cards">
              {items?.map((item: any, index: number) => {
                const routesMaterial = getRouterByMaterial(item, routes);

                return (
                  <MiniCard
                    key={index}
                    material={item}
                    btn={() => history.push(routesMaterial.routerAccess)}
                    hideAccessButton={!routesMaterial.routerAccess}
                    actions={() => actionsButton(listFolders, addFolder)}
                  />
                );
              })}
            </div>
          </WrapperCard>
          <PaginationWrapper>
            <Pagination
              pageSize={perPage}
              current={page}
              total={total!}
              onChange={(page) => setPage(page)}
            />
          </PaginationWrapper>
        </PageContent>
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    title
    pinned
    numericId
    createdAt
    updatedAt
    fileIds
    files {
      _id
      extension
      kind
      signedUrl
      downloadUrl
    }
    categories {
      _id
      name
    }
    validity { 
      start 
      end 
    } 
    author { 
      _id, 
      name 
    }
  }
`;
