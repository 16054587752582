import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { PageWrapper } from './stylesCadastrar';
import { PageTemplate } from '../../../components/Layout/PageTemplate';

import { Form } from '../Form';

export const ProcessosCadastrar = () => {
  const configPage = {
    pageName: 'Processos',
    icon: 'schedule',
    description: 'Cadastramento e consulta de processos',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo', link: routes.home.mount() },
      { title: 'Processos', link: routes.processosListar.mount() },
      { title: 'Listar', link: routes.processosListar.mount() },
      { title: 'Cadastrar', link: routes.processosCadastrar.mount() }
    ]
  };

  return (
    <PageTemplate>
      <>
        <PageWrapper>
          <Breadcrumb items={configPage.breadcrumb} />
          <TitleDescription
            iconType={configPage.icon}
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
