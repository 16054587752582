import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Breadcrumb, TitleDescription, CardCategory } from '@digi-tim-19/components';
import { useHistory } from 'react-router';
import { routes } from '../../config/routes';
import { imgsMapaDeProcessos } from './images';

import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { EnumMaterialKind, ValidityEnum, SortFindManyMaterialInput } from '../../autogenerated/client/types';

import { LoadingIndicator } from '@digi-tim-19/components';
import { orderBy } from 'lodash';

const baseConfig = {
  title: 'Mapa de Processos',
  icon: 'environment',
  description: 'Aqui você encontra de maneira rápida, em arquivo único e separados por tema, os Processos publicados.',
  data: [],
};

const subCategoriesItems = {
  smb: {
    ...baseConfig,
    title: 'Mapa de Processos SMB',
    urlBackground: imgsMapaDeProcessos.imgMapaDeProcessosSMB,
    materialId: '62b4c7dd13743e7a4bb3086c',
  },
  consumer: {
    ...baseConfig,
    title: 'Mapa de Processos CONSUMER',
    urlBackground: imgsMapaDeProcessos.imgMapaDeProcessos,
    materialId: '62162876b2dc4c6e09fbfbab',
  },
  ta_no_app: {
    ...baseConfig,
    title: 'Tá no App',
    urlBackground: imgsMapaDeProcessos.imgTaNoApp,
    description: 'Consulte na palma da sua mão, tela a tela, as principais funcionalidades do App TIM Vendas.',
    icon: 'mobile',
    materialId: '',
  },
  ta_no_meu_tim: {
    ...baseConfig,
    title: 'Tá no Meu TIM',
    urlBackground: imgsMapaDeProcessos.imgMeuTim,
    description: 'Consulte aqui, tela a tela, as principais funcionalidades do App Meu TIM.',
    icon: 'user',
    materialId: '',
  },
};

type Items = typeof subCategoriesItems;

type SubCategories = keyof typeof subCategoriesItems;

const StyledContent = styled.section`
  background: transparent;
  display: flex;
  flex-wrap: wrap;
`;

export const MapaDeProcessos = () => {
  const getMaterials = useClient('MaterialPagination');
  const [items, setItems] = useState<Items>(subCategoriesItems);
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  const getMaterialBySubCategory = async (subcategory: SubCategories) => {
    const subcategoryId = 'menu:processos:mapa_de_processos:' + subcategory;

    const { result } = await getMaterials.fetch({
      appendToFragment,
      variables: {
        perPage: 0,
        page: 1,
        filter: {
          kind: EnumMaterialKind.Process,
          validityEnum: ValidityEnum.Any,
          includeDrafts: true,
          inCategories: [subcategoryId],
        },
        sort: SortFindManyMaterialInput.IdDesc,
      },
    });

    return result?.items;
  };

  const fetchInitial = async () => {
    setLoading(true);
    const result = subCategoriesItems;
    const subCategoriesKeys = Object.keys(subCategoriesItems);

    for (const key of subCategoriesKeys) {
      const response = (await getMaterialBySubCategory(key as SubCategories)) as any;

      result[key as SubCategories] = {
        ...result[key as SubCategories],
        data: response,
      };
    }

    setItems(result);
    setLoading(false);
  };

  useEffect(() => {
    fetchInitial();
  }, []);

  const handleView = (data: any) => {
    if (data?.files?.[0]?.extension === 'pdf') {
      return history.push(routes.pdfViewer.mount(data?._id));
    }

    return history.push(routes.conteudoMaterial.mount(data?._id));
  };
  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            {
              title: 'Tá no Processo',
              link: routes.mapaDeProcessos.mount(),
            },
          ]}
        />

        <TitleDescription
          iconType="shopping"
          customIcon={false}
          title="Tá no Processo"
          description="Aqui você encontra materiais complementares aos Processos Publicados."
        />
        <StyledContent>
          {loading ? (
            <LoadingIndicator />
          ) : (
            Object.values(items).map((item: any, idx) => (
              <CardCategory
                key={item.title + idx}
                icon={item.icon}
                title={item.title}
                description={item.description}
                urlBackground={item.urlBackground}
                minHeight="430px"
                url={() =>
                  handleView(
                    item.materialId
                      ? item?.data.find((el: any) => el._id.toString() === item.materialId)
                      : orderBy(item?.data, 'updatedAt', 'desc')?.[0],
                  )
                }
              />
            ))
          )}
        </StyledContent>
      </React.Fragment>
    </PageTemplate>
  );
};

const appendToFragment = ` 
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    changedBy {
      name
      dateText
    },
    _id
    numericId
    title
    description
    createdAt
    updatedAt
    fileIds
    files {
      _id
      kind
      extension
      title
      signedUrl
    }
    status { 
      _id 
      label 
    } ,
    author { 
      _id, 
      name 
    }, 
    categories { 
      _id 
      name 
      parentId
    }
    validity { 
      start 
      end 
    } 
  }
`;
