import React from 'react';
import styled from 'styled-components';
import { Breadcrumb, TitleDescription, CardCategory } from '@digi-tim-19/components';
import { useHistory } from 'react-router';
import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { imgResidencial } from '../SelecaoCategoria/images';

const StyledContent = styled.section`
  background: transparent;
  display: flex;
  flex-wrap: wrap;
`;

export const TimTech = () => {
  const history = useHistory();

  const items = [
    {
      title: 'Tabela de Preços',
      description: 'Aqui você encontra as tabelas de preços de smartphones e acessórios',
      urlBackground: '/defaultImages/5356_CardMateriaisApoio.jpg',
      icon: 'book',
      url: () => history.push(routes.smTabelaPrecos.mount()),
    },
    {
      title: 'Informativos',
      description: 'Conteúdos resumidos que você recebeu por e-mail.',
      urlBackground: '/defaultImages/5356_CardInformativos.jpg',
      icon: 'info-circle',
      url: () => history.push(routes.catInformativos.mount('tim_tech')),
    },
    {
      title: 'Vídeos',
      description: 'Reforço, argumentação e processos, LIVEs disponíveis no OnTube sobre esse serviço',
      urlBackground: '/defaultImages/5356_CardVideos.jpg',
      icon: 'play-square',
      url: () => history.push(routes.OnTubePlaylist.mount('tim_tech')),
    },
    {
      title: 'Processos',
      description:
        'Documentos de vendas, pós vendas e administrativos referente a Suporte a Vendas, HDC e operações comerciais',
      urlBackground: imgResidencial.bgProcessos,
      icon: 'schedule',
      url: () => history.push(routes.catProcessos.mount('', 'tim_tech')),
    },
    {
      title: 'Materiais de Apoio',
      description:
        'Matinais, guias rápidos e outros materiais de consulta sobre oferta.',
      urlBackground: '/defaultImages/5356_CardMateriaisApoio.jpg',
      icon: 'book',
      url: () => history.push(routes.materiaisApoio.mount('cardapio_smb', 'tim_tech'))
    },
  ];

  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb items={[{ title: 'Home', link: routes.home.mount() }, { title: 'TIM Tech' }]} />

        <TitleDescription
          iconType="mobile"
          customIcon={false}
          title="TIM Tech"
          description="Aqui você encontra todas as informações necessárias sobre smartphones e acessórios."
        />
        <StyledContent>
          {items.map((card, index) => (
            <CardCategory key={index} {...card} />
          ))}
        </StyledContent>
      </React.Fragment>
    </PageTemplate>
  );
};
