import * as React from 'react';
import uniq from 'lodash/uniq';
import styled from 'styled-components';
import cx from 'classnames';

import {
  Breadcrumb,
  Former,
  LoadingIndicator,
  TitleDescription
} from '@digi-tim-19/components';

import { PageWrapper } from '../../Processos/Cadastrar/stylesCadastrar';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import { useHtmlDimensions } from '@digi-tim-19/utils';
import { useUserEditPermissions } from './useUserEditPermissios';
import { SelectContentPermissions } from './SelectContentPermissions';
import { useContentPermissionsOptionsHelper } from './useContentPermissionsOptionsHelper';

const configPage = {
  pageName: 'Gerenciar Permissões',
  icon: 'user',
  description: 'Editar de permissões',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    {
      title: 'Gerenciar permissões',
      link: routes.gerenciarPermissoesListar.mount()
    },
    { title: 'Editar', link: '' }
  ],
  routeList: routes.gerenciarPermissoesListar.mount()
};

const GerenciarPermissoesWrapper = styled.div`
  width: 100%;
  &&&& .FieldWrapper {
    &.disabled {
      opacity: 0.8;
    }
  }
`;

export const GerenciarPermissoes = (props: TGerenciarPermissoesProps) => {
  const { className } = props;
  const { isMobile } = useHtmlDimensions();
  const editHelper = useUserEditPermissions();
  const contentPermissionsOptionsHelper = useContentPermissionsOptionsHelper();

  const loadingInitialData =
    contentPermissionsOptionsHelper.loading || editHelper.loadingUser;

  const initialData = React.useMemo(() => {
    const initialData = editHelper.user;

    // a regiao do usuario deve vir pre-selecionada
    const userDefaultRegions = contentPermissionsOptionsHelper.items.regions
      .filter((el) => el.id === initialData.region)
      .map((el) => el.key);

    const regions = uniq(
      userDefaultRegions.concat(initialData.permissions.regions)
    );

    return {
      ...initialData,
      viewOtherUsersContent: initialData.viewOtherUsersContent ? 'Sim' : 'Não',
      permissions: {
        ...initialData.permissions,
        regions
      }
    };
  }, [loadingInitialData]);

  return (
    <PageTemplate>
      <PageWrapper>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <GerenciarPermissoesWrapper
          className={cx(
            className,
            'ui-flex GerenciarPermissoes GerenciarPermissoes_Wrapper'
          )}
        >
          {loadingInitialData ? (
            <LoadingIndicator />
          ) : (
            <>
              <Former
                showLoadingState={editHelper.loadingSave}
                initialValues={initialData}
                config={{
                  title: <b>{editHelper.user.name.toUpperCase()}</b>,
                  fields: [
                    {
                      inline: !isMobile,
                      list: [
                        {
                          name: 'matricula',
                          label: 'Matrícula',
                          disabled: true
                        },
                        {
                          name: 'name',
                          label: 'Nome',
                          disabled: true
                        },
                        {
                          name: 'CPF',
                          label: 'CPF',
                          disabled: true
                        },
                        {
                          name: 'region',
                          label: 'Regional',
                          disabled: true
                        }
                      ]
                    },
                    {
                      inline: !isMobile,
                      list: [
                        {
                          name: 'UF',
                          label: 'UF',
                          disabled: true
                        },
                        {
                          name: 'DDD',
                          label: 'DDD',
                          disabled: true
                        },
                        {
                          name: 'roleGroup',
                          label: 'Cargo',
                          disabled: true
                        }
                      ]
                    },
                    {
                      custom: (
                        <SelectContentPermissions
                          data={contentPermissionsOptionsHelper}
                        />
                      )
                    },
                    {
                      name: 'viewOtherUsersContent',
                      label:
                        'Usuário poderá visualizar todo o conteúdo além do dele?',
                      type: 'radio',
                      options: [
                        { label: 'Sim', value: 'Sim' },
                        { label: 'Não', value: 'Não' }
                      ]
                    }
                  ],
                  submitButton: { label: 'Salvar' },
                  cancelButton: {
                    label: 'Remover todas permissões',
                    onClick: function () {
                      editHelper.submit({
                        _id: editHelper.user._id,
                        permissions: {
                          roleGroups: [],
                          contentTypes: [],
                          regions: []
                        },
                        viewOtherUsersContent: false
                      });
                    }
                  }
                }}
                onSubmit={async (form) => {
                  const viewOtherUsersContent =
                    form.data?.viewOtherUsersContent === 'Sim';
                  return editHelper.submit({
                    ...form.data,
                    viewOtherUsersContent
                  });
                }}
              />
            </>
          )}
        </GerenciarPermissoesWrapper>
      </PageWrapper>
    </PageTemplate>
  );
};

export type TGerenciarPermissoesProps = {
  className?: string;
};
