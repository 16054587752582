import React, { useState, useEffect, useMemo } from 'react';
import {
  FileContentCampaign,
  EnumFileKind,
  EnumIncentiveCampaignStatus,
  EnumFileContentCampaignTypeFileUpload,
  EnumFileContentCampaignStatusFileContent,
} from '../../../autogenerated/client/types';
import { FormContainer } from '../Cadastrar/styles';
import { Former, OptionType, useXlsxJsonActions } from '@digi-tim-19/components';
import { useHistory, useRouteMatch } from 'react-router';
import { routes } from '../../../config/routes';
import { useDimensions } from '@digi-tim-19/utils/build';
import { parseXlsxToJSON, validateFile, ValidationErrosType } from './utilsArquivoCampanha';

import { useClient } from '../../../autogenerated/client/client';
import { message, Alert } from 'antd';
import { ModalErrors } from './ModalErrors';
import { rankingTypes } from '../FormManager/utilsIncentiveCampaign';

export const ArquivosCampanhaForm = (props: TFormFileContentEditProps) => {
  const {
    params: { idCampaign },
  } = useRouteMatch();
  const xlsxJsonActions = useXlsxJsonActions();
  const { isMobile } = useDimensions();
  const [fileContent, setFileContent] = useState<any>([]);
  const [isOpenModalErros, setIsOpenModalErros] = useState(false);
  const [statusCampaignIsResult, setStatusCampaignIsResult] = useState(false);
  const [disabledTypeFileUpload, setDisabledTypeFileUpload] = useState(false);
  const [errorsValidationFile, setErrorsValidationFiles] = React.useState<ValidationErrosType[]>([]);
  const [periodOptionsSelect, setPeriodOptionsSelect] = useState<OptionType[]>([]);
  const [periodDefault, setPeriodDefault] = useState<OptionType>();
  const insertFileContentAPI = useClient('FileContentCampaignCreateOne');
  const updateFileContentAPI = useClient('FileContentCampaignUpdateOne');
  const findFileContentAPI = useClient('FileContentCampaignFindMany');
  const incentiveCampaignAPI = useClient('IncentiveCampaignFindOne', {
    fetchOnMount: true,
    variables: {
      filter: {
        _id: idCampaign,
      },
    },
  });

  const isRanking = incentiveCampaignAPI?.result?.ranking;

  useEffect(() => {
    if (incentiveCampaignAPI.result?._id) {
      findFileContentAPI
        .fetch({
          variables: {
            limit: 12,
            filter: {
              inIncentiveCampaignId: [idCampaign],
            },
          },
        })
        .then((ctx) => {
          const options = incentiveCampaignAPI.result?.periodOption?.map((period) => ({
            label: period,
            value: period,
            disabled: !!ctx.result?.find((f) => f?.period === period),
          }));

          setPeriodOptionsSelect(options as any);

          const periodDefault = options?.find((c) => c.value === props.initialValues?.period);
          console.log({ periodDefault });
          setPeriodDefault(periodDefault);
        });
    }
  }, [incentiveCampaignAPI?.result]);

  const history = useHistory();

  const isUpdate = !!props.initialValues;

  const configPage = useMemo(() => {
    return {
      routeList: routes.visualizarArquivosCampanha.mount(idCampaign),
      messageSuccess: 'Arquivo cadastrado com sucesso',
      messageError: 'Arquivo não cadastrado',
    };
  }, []);

  useEffect(() => {
    const jsonParsed = parseXlsxToJSON(xlsxJsonActions.state.json);
    setFileContent(jsonParsed);
  }, [xlsxJsonActions.state.json]);

  const initialValues = React.useMemo(() => {
    const typeFileUpload = statusCampaignIsResult
      ? EnumFileContentCampaignTypeFileUpload.Final
      : props.initialValues?.typeFileUpload;
    return {
      ...props.initialValues,
      typeFileUpload,
    };
  }, [props.initialValues, statusCampaignIsResult]);

  useEffect(() => {
    if (props.statusCampaign === EnumIncentiveCampaignStatus.WithResults) {
      setStatusCampaignIsResult(true);
      setDisabledTypeFileUpload(true);
    } else if (isUpdate) setDisabledTypeFileUpload(true);
  }, [props.statusCampaign]);

  async function createFileContent(record: any) {
    await insertFileContentAPI.fetch({ variables: { record } }).then((ctx) => {
      if (ctx?.result) {
        message.success(configPage.messageSuccess);
        history.push(configPage.routeList);
      } else {
        console.error('errors', ctx.errors);
        message.error(configPage.messageError);
      }
    });
  }

  async function updateFileContent(record: any, _id: string) {
    await updateFileContentAPI
      .fetch({
        variables: { record, filter: { _id: _id } },
      })
      .then((ctx) => {
        if (!ctx.errors) {
          history.push(configPage.routeList);
        } else {
          console.error('Erro ao atualizar', ctx.errors);
        }
      });
  }

  return (
    <FormContainer>
      <ModalErrors
        isOpen={isOpenModalErros}
        errors={errorsValidationFile}
        onCancel={() => {
          setIsOpenModalErros(false);
          setErrorsValidationFiles([]);
        }}
        onOk={() => {
          setIsOpenModalErros(false);
          setErrorsValidationFiles([]);
        }}
      />
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              inline: !isMobile,
              list: [
                {
                  name: 'typeFileUpload',
                  label: 'RESULTADO',
                  type: 'select',
                  required: true,
                  disabled: disabledTypeFileUpload,
                  options: typeOptions,
                },
                isRanking
                  ? {
                      name: 'period',
                      label: 'Opções',
                      type: 'select',
                      defaultValue: periodDefault?.value,
                      required: true,
                      options: periodOptionsSelect,
                      maxWidth: '200px',
                    }
                  : null,
                {
                  name: 'fileId',
                  label: '',
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: EnumFileKind.Xlsx,
                    CTA: 'ESCOLHER ARQUIVO',
                    multiple: false,
                    onSelectFile: (info: any) => {
                      const file = info.file.originFileObj;
                      if (file) {
                        xlsxJsonActions.setState('file', file);
                      }
                    },
                  },
                },
                {
                  custom: (
                    <div>
                      <a style={{ textDecoration: 'underline' }} href="/files/MODELO PLANILHA INCENTIVO.xlsx" download>
                        Baixar Planilha Modelo
                      </a>
                    </div>
                  ),
                },
              ],
            },
            {
              custom: (
                <Alert
                  message='Ao clicar em "ENVIAR" o arquivo será processado. Ao final do processamento será enviado um e-mail com o status final da validação do arquivo.'
                  type="info"
                />
              ),
            },
          ],
          submitButton: {
            label: isUpdate ? 'Atualizar' : 'Enviar',
          },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(configPage.routeList),
          },
        }}
        onSubmit={async ({ data }: any) => {
          if (props.statusCampaign === EnumIncentiveCampaignStatus.Closed) {
            return message.error('Não é possivel alterar a campanha encerrada.');
          }

          if (
            props.statusCampaign === EnumIncentiveCampaignStatus.WithResults &&
            data.typeFileUpload !== EnumFileContentCampaignTypeFileUpload.Final
          ) {
            return message.error(
              'A camapnha está configurada com Status de RESULTADO, a planilha só poderá ser como resultado FINAL.',
            );
          }

          const record = {
            //fileStructure: fileContent,
            fileId: data.fileId,
            typeFileUpload: data.typeFileUpload,
            incentiveCampaignId: idCampaign,
            statusFileContent: EnumFileContentCampaignStatusFileContent.Pending,
            period: isRanking ? data.period : undefined,
          };

          let validationErrors: ValidationErrosType[] = [];

          validateFile(fileContent, validationErrors, setIsOpenModalErros).then(async () => {
            setErrorsValidationFiles(validationErrors);
            if (validationErrors.length !== 0) return;

            if (isUpdate) {
              await updateFileContent(record, data._id);
            } else {
              await createFileContent(record);
            }
          });
        }}
      />
    </FormContainer>
  );
};

type TFormFileContentEditProps = {
  statusCampaign: EnumIncentiveCampaignStatus;
  initialValues?: Partial<FileContentCampaign>;
};

const typeOptions = [
  {
    value: 'partial',
    label: 'Parcial',
  },
  {
    value: 'final',
    label: 'Final',
  },
];
