import imgGuiaBolso from './thumb-guias-bolso.png';
import imgMapaPdv from './thumb-mapa-pdv.png';
import imgPecasPdv from './thumb-pecas-pdv.png';
import imgPlanograma from './thumb-planograma.png';

import imgGuiaBolso2 from './old/thumb-guias-bolso.jpg';
import imgMapaPdv2 from './old/thumb-mapa-pdv.jpg';
import imgPecasPdv2 from './old/thumb-pecas-pdv.jpg';
import imgRedesSociais2 from './old/thumb-redes-sociais.jpg';

export const imgMateriaisPdv = {
  imgGuiaBolso,
  imgMapaPdv,
  imgPecasPdv,
  imgPlanograma,
  imgGuiaBolso2,
  imgMapaPdv2,
  imgPecasPdv2,
  imgRedesSociais2
};
