import React, { useState, useEffect, useCallback } from 'react';
import {
  Breadcrumb,
  TitleDescription,
  CardConsumerTaNaMao,
  useCurrentUser
} from '@digi-tim-19/components';
import { Select, Pagination } from 'antd';
import { useHistory } from 'react-router';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';
import {
  EnumMaterialKind,
  SortFindManyMaterialInput
} from '../../autogenerated/client/types';
import { actionsButton } from '../../utils/material';
import { useProfileSimulationState } from '@digi-tim-19/components';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';
import {
  PageContent,
  FilterContainer,
  Search,
  CleanButton,
  CurrentEditionWrapper,
  TitleEdition,
  CardsWrapper,
  PreviousEditionsWrapper
} from './styles';
import { PaginationWrapper } from '../../components/Page/PaginationWrapper';
import { escapeStringRegexp } from '../../utils/formatters';

const CardTaNaMao = (
  data: any,
  actions?: any,
  cardType?: any,
  validityEnumFilter?: any
) => {
  const history = useHistory();
  const { listFolders, addFolder } = actions;

  return data?.map((item: any, index: number) => {
    if (cardType == 'current' && validityEnumFilter != 'all') {
      return validityEnumFilter == 'active' ? (
        <CardConsumerTaNaMao
          key={index}
          material={item}
          btn={() => history.push(routes.pdfViewer.mount(item._id))}
          spotlight
          //defaultImage="/defaultImages/Thumb-Tá-na-Mão_C.png"
          defaultImage="/defaultImages/thumb-card-ta-na-mao.jpg"
          actions={(material) => actionsButton(listFolders, addFolder)}
        />
      ) : null;
    }

    if (cardType == 'previous' && validityEnumFilter != 'all') {
      return validityEnumFilter == 'inactive' ? (
        <CardConsumerTaNaMao
          key={index}
          material={item}
          btn={() => history.push(routes.pdfViewer.mount(item._id))}
          spotlight
          //defaultImage="/defaultImages/Thumb-Tá-na-Mão_C.png"
          defaultImage="/defaultImages/thumb-card-ta-na-mao.jpg"
          actions={(material) => actionsButton(listFolders, addFolder)}
        />
      ) : null;
    }
    return (
      <CardConsumerTaNaMao
        key={index}
        material={item}
        btn={() => history.push(routes.pdfViewer.mount(item._id))}
        spotlight
        //defaultImage="/defaultImages/Thumb-Tá-na-Mão_C.png"
        defaultImage="/defaultImages/thumb-card-ta-na-mao.jpg"
        actions={(material) => actionsButton(listFolders, addFolder)}
      />
    );
  });
};

export const TaNaMao = () => {
  const { Option } = Select;

  const [validityEnumFilter, setValidityEnumFilter] = useState('all');
  const [materialsVigents, setMaterialsVigents] = useState<any[]>([]);
  const [materialsPrevious, setMaterialsPrevious] = useState<any[]>([]);

  const { listFolders, addFolder } = useFoldersPin();

  const userPropsSimulation = useProfileSimulationState().value;
  const userChannels = useCurrentUser().result?.channels;
  const isTrade =
    (userChannels || [])?.filter(
      (channel: any) => channel.toUpperCase() === 'TRADE'
    ).length! > 0;

  const [filterSearch, setFilterSearch] = useState();

  const [page, setPage] = useState(1);
  const perPage = 16;

  const getMaterials = useClient('MaterialPagination');

  const data = getMaterials?.result;

  const total = getMaterials.result?.pageInfo.itemCount;

  const onSearch = useCallback(
    (valueSearch) => {
      setFilterSearch(valueSearch);
    },
    [data]
  );

  const resetFilters = () => {
    setFilterSearch(undefined);
    setPage(1);
  };

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          limit: 50000,
          filter: {
            kind: EnumMaterialKind.Tanamao,
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
          },
          sort: SortFindManyMaterialInput.CreatedatDesc
        }
      })
      .then((response: any) => {
        if (response.result) {
          let lengthVigents = 1;

          if (
            (userPropsSimulation.channel &&
              userPropsSimulation.channel.toUpperCase() === 'TRADE') ||
            (isTrade && userPropsSimulation.channel.length <= 0)
          )
            lengthVigents = 8;

          setMaterialsVigents(response.result.items.slice(0, lengthVigents));
          setMaterialsPrevious(
            response.result.items.slice(lengthVigents, response.result.length)
          );
        }
      });
  }, [page, filterSearch]);
  //eslint-disable-line

  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Veja Mais' },
            { title: 'TÁ NA MÃO' }
          ]}
        />

        <TitleDescription
          iconType={'read'}
          title={'TÁ NA MÃO'}
          description={'Aqui você encontra todas as edições do TÁ NA MÃO'}
        />

        <PageContent>
          <div className="actions">
            <FilterContainer>
              <Select
                defaultValue="all"
                onChange={(value: string) => setValidityEnumFilter(value)}
              >
                <Option value="active">Vigentes</Option>
                <Option value="inactive">Não vigentes</Option>
                <Option value="all">Todos</Option>
              </Select>

              <Search
                placeholder="Busca título"
                style={{ width: 200 }}
                value={filterSearch}
                onChange={(e) => onSearch(e.target.value)}
              />
              <CleanButton onClick={() => resetFilters()}>
                Limpar filtros
              </CleanButton>
            </FilterContainer>
          </div>

          {ContentCards(
            materialsVigents,
            materialsPrevious,
            validityEnumFilter,
            listFolders,
            addFolder
          )}

          <PaginationWrapper>
            <Pagination
              pageSize={perPage}
              current={page}
              total={total!}
              onChange={(page) => setPage(page)}
            />
          </PaginationWrapper>
        </PageContent>
      </React.Fragment>
    </PageTemplate>
  );
};

function ContentCards(
  materialsVigents: any[],
  materialsPrevious: any[],
  validityEnumFilter: any,
  listFolders: any,
  addFolder: Function
) {
  return (
    <React.Fragment>
      <CurrentEditionWrapper
      // className={
      //   validityEnumFilter !== 'all' && validityEnumFilter !== 'active'
      //     ? 'hide'
      //     : 'show'
      // }
      >
        <TitleEdition>
          {' '}
          Edições
          {validityEnumFilter == 'active'
            ? ' Vigentes'
            : validityEnumFilter == 'inactive'
            ? ' Anteriores'
            : ''}{' '}
        </TitleEdition>
        <CardsWrapper>
          {CardTaNaMao(
            materialsVigents,
            { listFolders, addFolder },
            'current',
            validityEnumFilter
          )}
          {CardTaNaMao(
            materialsPrevious,
            { listFolders, addFolder },
            'previous',
            validityEnumFilter
          )}
        </CardsWrapper>
      </CurrentEditionWrapper>

      {/* <PreviousEditionsWrapper
        // className={
        //   validityEnumFilter !== 'all' && validityEnumFilter !== 'inactive'
        //     ? 'hide'
        //     : 'show'
        // }
      >
        <TitleEdition>Edições anteriores</TitleEdition>
        <CardsWrapper>
          {CardTaNaMao(materialsPrevious, { listFolders, addFolder }, 'previous', validityEnumFilter)}
        </CardsWrapper>
      </PreviousEditionsWrapper> */}
    </React.Fragment>
  );
}

const appendToFragment = `
pageInfo {
  perPage
  hasNextPage
  hasPreviousPage
  itemCount
  pageCount
}

items {
  validityEnum
  isDraft
  description
  kind
  tags
  title
  fileIds
  _id
  updatedAt
  createdAt
  numericId
  pinned
  validity {
    start
    end
  }
  
  files {
    signedUrl
    downloadUrl
    _id
    title
    kind
  }
}`;
