import styled from 'styled-components';

const notComplete = new Set(['expired', 'not_completed']);

export const CardChallengeContainer = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  width: 200px;
  margin-right: 20px;
`;

export const IconChallengeFaq = styled.div`
  width: max-content;
  height: auto;
  position: absolute;
  top: 68px;
  right: 10px;
  cursor: pointer;
  z-index: 1;

  > i svg {
    fill: white !important;
    font-size: 16pt;
  }
`;

export const IconChallengeCard = styled.img<{ status: string }>`
  width: 170px;
  height: 170px;
  position: absolute;
  /* top: -20px; */
  /* margin-bottom: 150px; */
  margin-bottom: 77%;
  left: 50%;
  transform: translateX(-50%);
  filter: grayscale(${(props) => (notComplete.has(props.status) ? '100' : '0')}%);
`;

export const ContentId = styled.p`
  padding: 5px;
  color: #fff;
  font-size: 8px;
  margin: 0;
`

export const CardChallenge = styled.div<{ status: string }>`
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 240px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const HeaderChallengeCard = styled.div`
  height: 38%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 4px 4px;
  overflow-y: scroll;

  p {
    text-align: center;
    color: #fff;
    font-size: 12px;
    margin: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  };
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const DividerChallengeCard = styled.div`
  border-bottom: 1px solid white;
  width: 90%;
  margin: 0 auto;
`;

export const MainChallengeCard = styled.div`
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FooterChallengeCard = styled.div`
  height: 10%;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: 13px;
  margin-top: 5px;
`;

/* CONTENT PROGRESS BAR */
export const ContentProgressChallengeCard = styled.div`
  height: 35%;
  padding: 8px 4px;
  display: flex;
  justify-content: center;
  color: white;
`;

export const ContentPercent = styled.div<{ status: string; currentProgress: number }>`
  height: 100%;
  width: 37px;
  text-align: center;
  background-color: ${(props) => (notComplete.has(props.status) ? '#848484' : '#c02d42')};
  font-size: 10px;
  display: ${({ currentProgress: currentValue }) => (currentValue < 100 ? 'block' : 'none')};
`;

const bgContentTotalPercent = ({ status }: any) => {
  if (status === 'completed') return '#001989';

  if (notComplete.has(status)) return '#848484';

  return '#1C51CF';
};

export const ContentTotalPercent = styled.div<{ status: string; currentProgress: number }>`
  width: 37px;
  text-align: center;
  height: 100%;
  background-color: ${bgContentTotalPercent};
  font-size: 10px;
  display: ${({ currentProgress: currentValue }) => (currentValue >= 100 ? 'block' : 'none')};
`;

export const ContentProgressBarContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
`;

export const ProgressBarFill = styled.div`
  height: 70%;
  width: 100%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.1);
`;

export const ProgressCurrent = styled.span<{ currentProgress: number }>`
  width: ${(props) => props.currentProgress}%;
  height: 100%;
  background-color: white;
  border-top-right-radius: ${(props) => (props.currentProgress >= 100 ? '0' : '100px')};
  border-bottom-right-radius: ${(props) => (props.currentProgress >= 100 ? '0' : '100px')};
`;

/* CONTENT PROGRESS BAR */
export const ContentMainContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
`;

export const ContentMainBox = styled.div`
  width: 40%;
  height: 100%;
  padding: 3px;
  border: 1px solid rgba(255, 255, 255, 0.29);
  borderradius: 10px;
  color: white;
`;

export const ContentMainBoxReward = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const RewardTitle = styled.div`
  width: 100%;
  height: 20%;
  font-size: 10px;
  margin-bottom: 10px;
`;

export const RewardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-items: center;
  align-items: center;
`;

export const RewardValue = styled.div`
  font-size: 20px;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export const RewardChip = styled.div<{ status: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 35px;
    filter: grayscale(${(props) => (notComplete.has(props.status) ? '100' : '0')}%);
  }
`;

export const ChallengeStateBox = styled.div<{ status: string; cursorPointer?: boolean }>`
  width: 100%;
  height: 100%;
  padding: 3px;
  border-radius: 10px;
  background: ${(props) =>
    notComplete.has(props.status)
      ? 'linear-gradient(180deg, rgba(192,45,66,1) 24%, rgba(182,0,24,1) 100%)'
      : 'linear-gradient(36deg, rgba(0, 155, 15, 1) 0%, rgba(0, 96, 9, 1) 100%)'};
  cursor: ${(props) => (props?.cursorPointer ? 'pointer' : 'auto')};
`;

export const ChallengeButtonStart = styled.div<{ status: string }>`
  color: white;
  font-size: 80%;
  border: 1px solid rgba(255, 255, 255, 0.29);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  overflow: hidden;

  &:hover {
    opacity: ${(props) => (props.status === 'pending' ? '0.7' : '1')};
  }
`;
