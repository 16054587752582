"use strict";

var _slicedToArray = require("/home/jenkins/tim-consumer/packages/site-consumer/node_modules/@babel/runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var PlacePositivationTypes_1 = require("./PlacePositivationTypes");

exports.PositivationContext = react_1.default.createContext({});
var Provider = exports.PositivationContext.Provider;

exports.PositivationProvider = function (props) {
  var children = props.children,
      store = props.store;

  var _react_1$useState = react_1.useState(1),
      _react_1$useState2 = _slicedToArray(_react_1$useState, 2),
      countItems = _react_1$useState2[0],
      setCountItens = _react_1$useState2[1];

  var _react_1$useState3 = react_1.useState(store.itemsRepository),
      _react_1$useState4 = _slicedToArray(_react_1$useState3, 2),
      cardsRepository = _react_1$useState4[0],
      setCardsRepository = _react_1$useState4[1];

  var _react_1$useState5 = react_1.useState(store.itemsContainer),
      _react_1$useState6 = _slicedToArray(_react_1$useState5, 2),
      cardsContainer = _react_1$useState6[0],
      setCardsContainer = _react_1$useState6[1];

  var _react_1$useState7 = react_1.useState(false),
      _react_1$useState8 = _slicedToArray(_react_1$useState7, 2),
      dragging = _react_1$useState8[0],
      setDragging = _react_1$useState8[1];

  var dragItem = react_1.useRef();
  var dragNode = react_1.useRef();

  var handleDragStart = function handleDragStart(e, params) {
    dragItem.current = params;
    dragNode.current = e.target;
    dragNode.current.addEventListener('dragend', handleDragEnd);
    setTimeout(function () {
      setDragging(true);
    }, 0);
  };

  var handleDragEnter = function handleDragEnter(e, targetItem) {
    var sourceItem = dragItem.current;
    if (!sourceItem) return;

    if (e.target !== dragNode.current) {
      setCardsContainer(function (oldList) {
        var newList = oldList.map(function (x) {
          return x;
        });
        var currentItemSelectedRepository = cardsRepository.find(function (x) {
          return x.id === sourceItem.id;
        });

        if (!!currentItemSelectedRepository && !oldList.length) {
          newList.push(currentItemSelectedRepository);
          dragItem.current = targetItem;
          setCardsRepository(cardsRepository.filter(function (x) {
            return x.id !== sourceItem.id;
          }));
        } else {
          var sourceItemIndex = oldList.findIndex(function (x) {
            return x.id === sourceItem.id;
          });
          var targetIndex = oldList.findIndex(function (x) {
            return x.id === targetItem.id;
          });

          if (sourceItemIndex >= 0) {
            var newItem = newList.splice(sourceItemIndex, 1)[0];
            newList.splice(targetIndex, 0, newItem);
          } else {
            newList.splice(targetIndex, 0, sourceItem);
            setCardsRepository(cardsRepository.filter(function (x) {
              return x.id !== sourceItem.id;
            }));
          }
        }

        return newList;
      });
    }
  };

  var handleDragEnd = function handleDragEnd() {
    setDragging(false);
    dragNode.current.removeEventListener('dragend', handleDragEnd);
    dragItem.current = null;
    dragNode.current = null;
  };

  var getStyles = function getStyles(params) {
    var _a, _b;

    var currentItem = dragItem.current;
    if (((_a = currentItem) === null || _a === void 0 ? void 0 : _a.id) === ((_b = params) === null || _b === void 0 ? void 0 : _b.id)) return 'current';
    return '';
  };

  var addCategory = function addCategory() {
    var cardsTemp = cardsRepository.map(function (x) {
      return x;
    });
    var id = countItems.toString();
    var newCards = cardsTemp.concat(Object.assign(Object.assign({}, itemTemplate), {
      id: id,
      kind: PlacePositivationTypes_1.kindPlacePositivation.category
    }));
    setCardsRepository(newCards);
    setCountItens(countItems + 1);
  };

  var addPlace = function addPlace() {
    var cardsTemp = cardsRepository.map(function (x) {
      return x;
    });
    var id = countItems.toString();
    var newCards = cardsTemp.concat(Object.assign(Object.assign({}, itemTemplate), {
      id: id,
      kind: PlacePositivationTypes_1.kindPlacePositivation.place
    }));
    setCardsRepository(newCards);
    setCountItens(countItems + 1);
  };

  return react_1.default.createElement(Provider, {
    value: {
      itemsRepository: cardsRepository,
      itemsContainer: cardsContainer,
      addCategory: addCategory,
      addPlace: addPlace,
      dragging: dragging,
      handleDragEnter: handleDragEnter,
      handleDragStart: handleDragStart,
      getStyles: getStyles
    }
  }, children);
};

function usePositivationContext() {
  return react_1.default.useContext(exports.PositivationContext);
}

exports.usePositivationContext = usePositivationContext;
var itemTemplate = {
  id: '',
  channels: [],
  imgUrl: '',
  kind: PlacePositivationTypes_1.kindPlacePositivation.place,
  mode: PlacePositivationTypes_1.modesPlacePositivation.read_only,
  title: '',
  onDelete: function onDelete() {
    return console.log('onDelete');
  },
  onEdit: function onEdit() {
    return console.log('onEdit');
  },
  onSave: function onSave() {
    return console.log('onSave');
  }
};