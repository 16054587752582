import React from 'react';
import styled from 'styled-components';
import { Empty } from 'antd';

export const Void = styled.div`
  width: 100%;
  min-height: 450px;
  align-items: center;
  justify-content: center;
`;

type PropsEmptyData = {
  text?: string;
};

export const EmptyData = ({ text }: PropsEmptyData) => {
  return (
    <Void>
      <Empty description={text || 'Nenhum dado encontrado'} />
    </Void>
  );
};
