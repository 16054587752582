import { useClient } from '@digi-tim-19/components';
import { Modal } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { EnumAnalyticsEvent, EnumAnalyticsParentKind } from '../../../autogenerated/client/types';
import { ChallengeProps } from './UserChallengeCard';
import { useAnalytics } from '@digi-tim-19/components';

interface Props {
  challenge: ChallengeProps;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refetch: any;
  open: boolean;
}

export const ModalExternalLink = ({ challenge, setOpen, open, refetch }: Props) => {
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const userChallengeUpdate = useClient('UserChallengeUpdateById');

  const analytics = useAnalytics();

  const handleOk = () => {
    setIsSubmit(true);

    userChallengeUpdate
      .fetch({
        variables: {
          record: {
            _id: challenge._id,
            status: 'in_progress',
            acceptDate: new Date(Date.now() - 108e5),
          },
        },
      })
      .then(async () => {
        await analytics.track({
          event: EnumAnalyticsEvent.ExternalLink,
          kind: EnumAnalyticsParentKind.User,
          externalLink: challenge.externalLink,
          label: 'Link Externo',
          singleDoc: false,
        });

        window.open(challenge.externalLink, '_blank');
        setOpen(false);
        refetch();
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      title="Desafio link externo"
      visible={open}
      onOk={handleOk}
      confirmLoading={isSubmit}
      onCancel={handleCancel}
      okText="Aceitar"
    >
      <p>Para concluir esse desafio clique em aceitar e acesse o conteúdo</p>
    </Modal>
  );
};
