"use strict";

var _slicedToArray = require("/home/jenkins/tim-consumer/packages/site-consumer/node_modules/@babel/runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var antd_1 = require("antd");

var RegionalProfilesSelection_1 = require("./RegionalProfilesSelection");

exports.SelecionarPublicoCorp = function (props) {
  var _a;

  var _onChange = props.onChange,
      initialValues = props.initialValues;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      visible = _React$useState2[0],
      setVisible = _React$useState2[1];

  return React.createElement(React.Fragment, null, React.createElement(antd_1.Button, {
    type: 'primary',
    onClick: function onClick() {
      setVisible(!visible);
    }
  }, "Selecionar P\xFAblico"), React.createElement(antd_1.Modal, {
    width: '90vw',
    closable: false,
    visible: visible,
    onCancel: function onCancel() {
      setVisible(false);
    },
    onOk: function onOk() {
      setVisible(false);
    },
    cancelButtonProps: {
      style: {
        display: 'none'
      }
    }
  }, React.createElement(RegionalProfilesSelection_1.RegionalProfilesSelection, {
    initialSelection: (_a = initialValues) === null || _a === void 0 ? void 0 : _a.availableAtRegionalProfiles,
    onChange: function onChange(selected) {
      return _onChange(selected);
    }
  })));
};