import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Banner } from './Banner';
import { CardsHome } from './CardsHome';
import { SubHeader } from '@digi-tim-19/components';
import { MainHeader } from '../../components/Layout/MainHeader';
import { MainMenu } from '../../components/Layout/MainMenu';
import { SiteFooter } from '../../components/Layout/SiteFooter';

import { siteImages } from '../../assets/images';
import { MainSocialFeed } from '../../components/Layout/MainSocialFeed';
import { useDimensions } from '@digi-tim-19/utils/build';

import { useCurrentUser, getRoutes } from '@digi-tim-19/components';
import { useHistory } from 'react-router';

const ContentHome = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
`;

export const Home = (props: THomeProps) => {
  const user = useCurrentUser();
  const history = useHistory();
  const routes = getRoutes();

  const [menuIsOpen, toggleMenu] = useState(false);
  const { isMobile } = useDimensions();

  useEffect(() => {
    user.fetch({ ignoreCached: true }).then((ctx) => {
      if (ctx.result?.channels?.includes('distribuicao')) {
        //caso seja do canal distribuição, redireciona para o extrato de pontos direto
        history.push(routes.informacoesExtrato.mount());
      } else {
        history.push(routes.home.mount());
      }
    });
  }, []);

  return (
    <React.Fragment>
      <SubHeader logoURL={siteImages.logoTim} />
      <MainHeader menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
      <MainMenu menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />

      <Banner />
      <ContentHome>
        {!isMobile && <MainSocialFeed />}
        <CardsHome />
      </ContentHome>
      <SiteFooter />
    </React.Fragment>
  );
};

export type THomeProps = {
  className?: string;
  children?: React.ReactNode;
};
