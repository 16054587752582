import { useCurrentUser } from '@digi-tim-19/components';
import { useState, useEffect } from 'react';
import { useClient } from '../../autogenerated/client/client';

//const term = 'LGPD';
const term = 'LGPD_20_06_2023';

export const useRegulations = () => {
  const user = useCurrentUser().result;
  const mutationAcceptTerm = useClient('UserAcceptTerm');

  const [pendingTerm, setPendingTerm] = useState<string>();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (user?.acceptedTerms) {
      const findTerm = user.acceptedTerms.find((item) => item?.term === term);

      if (!findTerm) setPendingTerm(term);
    }
  }, [user]);

  const updateAcceptTerm = (term: string) => {
    mutationAcceptTerm
      .fetch({
        variables: {
          term,
        },
        afterMutate: /User/,
      })
      .then((ctx) => {
        if (ctx.result) {
          setVisible(false);
          setPendingTerm(undefined);
        }
      });
  };

  useEffect(() => {
    setVisible(!!pendingTerm);
  }, [pendingTerm]);

  return {
    pendingTerm,
    visible,
    updateAcceptTerm,
  };
};
