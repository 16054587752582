import {
  EnumFileKind,
  EnumIncentiveCampaignStatus,
  EnumIncentiveCampaignCampaignType,
} from '../../../autogenerated/client/types';
import { routes } from '../../../config/routes';
import moment from 'moment';

export const configPage = {
  kindCardImage: EnumFileKind.AnyImage,
  kindBannerContent: EnumFileKind.AnyImage,
  kindPresentation: EnumFileKind.Pdf,
  kindRegulation: EnumFileKind.Pdf,
  KindTable: EnumFileKind.Xlsx,
  routeList: routes.campanhasIncentivoListar.mount(),
  messageSuccess: 'Campanha cadastrada com sucesso',
  messageError: 'Campanha não cadastrada',
};

export const statusOptions = [
  {
    id: EnumIncentiveCampaignStatus.InProgress,
    value: EnumIncentiveCampaignStatus.InProgress,
    label: 'ANDAMENTO',
  },
  {
    id: EnumIncentiveCampaignStatus.InVerification,
    value: EnumIncentiveCampaignStatus.InVerification,
    label: 'APURAÇÃO',
  },
  {
    id: EnumIncentiveCampaignStatus.WithResults,
    value: EnumIncentiveCampaignStatus.WithResults,
    label: 'RESULTADOS',
  },
  {
    id: EnumIncentiveCampaignStatus.WaitingPointsDistribution,
    value: EnumIncentiveCampaignStatus.WaitingPointsDistribution,
    label: 'DISTRIBUIR TIM COINS',
  },
  {
    id: EnumIncentiveCampaignStatus.ClosedWithoutWinners,
    value: EnumIncentiveCampaignStatus.ClosedWithoutWinners,
    label: 'ENCERRADA/SEM GANHADORES',
  },
  {
    id: EnumIncentiveCampaignStatus.ClosedPartner,
    value: EnumIncentiveCampaignStatus.ClosedPartner,
    label: 'ENCERRADA/PARCEIRO',
  },
];

// Definir EnumIncentiveCampaignCampaignType para canais e subcanais
export const channelOptions = [
  {
    label: 'Lojas TIM',
    value: 'lojas_tim',
  },
  {
    label: 'Canal Retail',
    value: 'retail',
  },
  {
    label: 'Canal SMB',
    value: 'smb',
  },
  {
    label: 'Varejo',
    value: 'varejo',
  },
  {
    label: 'Mass Channel',
    value: 'mass_channel',
  },
  {
    label: 'Diretoria Consumer',
    value: 'diretoria_consumer',
  },
];

export const subChannelOptions: Record<string, { label: string; value: string }[]> = {
  mass_channel: [
    { label: 'Estrutural', value: 'estrutural' },
    { label: 'Out', value: 'out' },
    { label: 'Multichannel', value: 'multichannel' },
  ],
  varejo: [
    { label: 'Coordenadores habitados', value: 'coordenadores_habitados' },
    { label: 'Coordenadores desabitados', value: 'coordenadores_desabitados' },
  ],
};

export const typeOptionsDefault = [
  {
    id: EnumIncentiveCampaignCampaignType.Ranking,
    value: EnumIncentiveCampaignCampaignType.Ranking,
    label: 'RANKING',
  },
  {
    id: EnumIncentiveCampaignCampaignType.BateuLevou,
    value: EnumIncentiveCampaignCampaignType.BateuLevou,
    label: 'BATEU LEVOU',
  },
  {
    id: EnumIncentiveCampaignCampaignType.Canvass,
    value: EnumIncentiveCampaignCampaignType.Canvass,
    label: 'CANVASS',
  },
  {
    id: EnumIncentiveCampaignCampaignType.VendeuGanhou,
    value: EnumIncentiveCampaignCampaignType.VendeuGanhou,
    label: 'VENDEU GANHOU!',
  },
  {
    id: EnumIncentiveCampaignCampaignType.Coringa,
    value: EnumIncentiveCampaignCampaignType.Coringa,
    label: 'CORINGA!',
  },
];

export const typeOptionsLojas = [
  {
    id: EnumIncentiveCampaignCampaignType.Ranking,
    value: EnumIncentiveCampaignCampaignType.Ranking,
    label: 'RANKING',
  },
  {
    id: EnumIncentiveCampaignCampaignType.BateuLevou,
    value: EnumIncentiveCampaignCampaignType.BateuLevou,
    label: 'BATEU LEVOU',
  },
  {
    id: EnumIncentiveCampaignCampaignType.Canvass,
    value: EnumIncentiveCampaignCampaignType.Canvass,
    label: 'CANVASS',
  },
  {
    id: EnumIncentiveCampaignCampaignType.VendeuGanhou,
    value: EnumIncentiveCampaignCampaignType.VendeuGanhou,
    label: 'VENDEU GANHOU',
  },
  {
    id: EnumIncentiveCampaignCampaignType.FreeStyle,
    value: EnumIncentiveCampaignCampaignType.FreeStyle,
    label: 'FREESTYLE',
  },
  {
    id: EnumIncentiveCampaignCampaignType.Coringa,
    value: EnumIncentiveCampaignCampaignType.Coringa,
    label: 'CORINGA!',
  },
];

export const typeOptionsVarejo = [
  {
    id: EnumIncentiveCampaignCampaignType.Ranking,
    value: EnumIncentiveCampaignCampaignType.Ranking,
    label: 'RANKING',
  },
  {
    id: EnumIncentiveCampaignCampaignType.BateuLevou,
    value: EnumIncentiveCampaignCampaignType.BateuLevou,
    label: 'BATEU LEVOU',
  },
  {
    id: EnumIncentiveCampaignCampaignType.Canvass,
    value: EnumIncentiveCampaignCampaignType.Canvass,
    label: 'CANVASS',
  },
  {
    id: EnumIncentiveCampaignCampaignType.MultiLeague,
    value: EnumIncentiveCampaignCampaignType.MultiLeague,
    label: 'MULTI LEAGUE',
  },
  {
    id: EnumIncentiveCampaignCampaignType.MultiLeague,
    value: EnumIncentiveCampaignCampaignType.RecordDeVendas,
    label: 'RECORD DE VENDAS',
  },
  {
    id: EnumIncentiveCampaignCampaignType.Coringa,
    value: EnumIncentiveCampaignCampaignType.Coringa,
    label: 'CORINGA!',
  },
];

export const typeOptionsSMB = [
  {
    id: EnumIncentiveCampaignCampaignType.Ranking,
    value: EnumIncentiveCampaignCampaignType.Ranking,
    label: 'RANKING',
  },
  {
    id: EnumIncentiveCampaignCampaignType.BateuLevou,
    value: EnumIncentiveCampaignCampaignType.BateuLevou,
    label: 'BATEU LEVOU',
  },
  {
    id: EnumIncentiveCampaignCampaignType.Valoriza,
    value: EnumIncentiveCampaignCampaignType.Valoriza,
    label: 'VALORIZA',
  },
  {
    id: EnumIncentiveCampaignCampaignType.Canvass,
    value: EnumIncentiveCampaignCampaignType.Canvass,
    label: 'CANVASS',
  },
  {
    id: EnumIncentiveCampaignCampaignType.Coringa,
    value: EnumIncentiveCampaignCampaignType.Coringa,
    label: 'CORINGA!',
  },
];

export const typeOptionsMassChannel = [
  {
    id: EnumIncentiveCampaignCampaignType.Ranking,
    value: EnumIncentiveCampaignCampaignType.Ranking,
    label: 'RANKING',
  },
  {
    id: EnumIncentiveCampaignCampaignType.BateuLevou,
    value: EnumIncentiveCampaignCampaignType.BateuLevou,
    label: 'BATEU LEVOU',
  },
  {
    id: EnumIncentiveCampaignCampaignType.Canvass,
    value: EnumIncentiveCampaignCampaignType.Canvass,
    label: 'CANVASS',
  },
  {
    id: EnumIncentiveCampaignCampaignType.Estrutuout,
    value: EnumIncentiveCampaignCampaignType.Estrutuout,
    label: 'ESTRUTUOUT',
  },
  {
    id: EnumIncentiveCampaignCampaignType.Coringa,
    value: EnumIncentiveCampaignCampaignType.Coringa,
    label: 'CORINGA!',
  },
];

export const rankingTypes = [
  {
    id: 'single',
    value: 'single',
    label: 'Único',
  },
  {
    id: 'fortnightly',
    value: 'fortnightly',
    label: 'Quinzenal',
  },
  {
    id: 'quarterly',
    value: 'quarterly',
    label: 'Trimestral',
  },
  {
    id: 'semester',
    value: 'semester',
    label: 'Semestral',
  },
  {
    id: 'monthly',
    value: 'monthly',
    label: 'Mensal',
  },
  {
    id: 'yearly',
    value: 'yearly',
    label: 'Anual',
  },
];

export function isWaitingPointsValidityEnd(record: any) {
  return (
    moment(record.validity.end).isAfter(new Date(), 'day') &&
    record.status === EnumIncentiveCampaignStatus.WaitingPointsDistribution
  );
}
