import React, { useEffect, useState, useCallback } from 'react';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select,
  LoadingIndicator
} from '@digi-tim-19/components';
import { message, DatePicker } from 'antd';
import { useHistory } from 'react-router';
import { columns } from './columns';
import { formatDate, dayjs } from '@digi-tim-19/utils/build';
import { useClient } from '../../../autogenerated/client/client';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyMaterialInput
} from '../../../autogenerated/client/types';

import {
  NotificationsWrapper,
  FilterContainer,
  Button,
  Search,
  CleanButton
} from './stylesListar';
import { routes } from '../../../config/routes';

import moment from 'moment';
import { useFilterAuthor } from '../../../components/FilterAuthor/useFilterAuthor';
import { formatCategoriesNamesList } from '../../../utils/categories';
import { isImageExtension } from '@digi-tim-19/utils';

const { RangePicker } = DatePicker;
export const RegulamentoListar: React.FC<TRegulamentoListarProps> = () => {
  const filterAuthor = useFilterAuthor();
  const [createdAt, setCreatedAt] = useState();
  const [filterData, setFilterData] = useState<any>([]);
  const [show, setShow] = useState([]);
  const [filterSearch, setFilterSearch] = useState();
  const [sort, setSort] = useState(SortFindManyMaterialInput.IdDesc);
  const [vigenteFilter, setVigenteFilter] = useState<ValidityEnum>(
    ValidityEnum.Any
  );
  const [updatedAt, setUpdatedAt] = useState();
  const [showUpdatedAt, setShowUpdatedAt] = useState([]);

  function isVigente(validity: any) {
    const validityEnd = validity ? validity.end : null;
    if (validityEnd) {
      const isPast = dayjs(validityEnd).isBefore(new Date());
      return isPast ? 'Não' : 'Sim';
    }
  }
  const history = useHistory();

  const MaterialDeleteOne = useClient('MaterialRemoveOne');

  const getMaterials = useClient('MaterialFindMany', {
    appendToFragment: `
      validity { start, end }
      categories { _id, name, parentId, parentCategory { name, _id parentCategory { name, _id}}}
      status { label }
      author { _id, name }
    changedBy {
      name
      dateText
    },
    `
  });

  useEffect(() => {
    getMaterials.fetch({
      variables: {
        filter: {
          kind: EnumMaterialKind.Regulation,
          validityEnum: vigenteFilter,
          includeDrafts: true,
          createdAtRange: createdAt,
          updatedAtRange: updatedAt,
          filterAuthor: filterAuthor.filter,
          owner: filterAuthor.ownerFilter
        },
        limit: 123,
        sort: sort
      }
    });
  }, [sort, vigenteFilter, createdAt, filterAuthor.signature, updatedAt]); //eslint-disable-line

  const resetFilters = () => {
    setSort(SortFindManyMaterialInput.IdDesc);
    setCreatedAt(undefined);
    setFilterSearch(undefined);
    setFilterData([]);
    setShow([]);
    setVigenteFilter(ValidityEnum.Any);
    filterAuthor.reset();
    setUpdatedAt(undefined);
    setShowUpdatedAt([]);
  };

  const data = getMaterials.result?.map((material: any) => {
    const categories = material.categories
      .filter((item: any) => item._id.split(':').length === 2)
      .map((item: any) => item.name);

    const subCategories = material.categories
      .filter((item: any) => item._id.split(':').length > 2)
      .map((item: any) => item.name);

    const categoriesName =
      material.categories.length === 1
        ? material.categories[0].parentCategory.name
        : formatCategoriesNamesList(categories);
    const subCategoriesName = formatCategoriesNamesList(subCategories);

    return {
      changedBy: material?.changedBy,
      key: material?._id,
      id: material?.numericId,
      title: material?.title,
      validity: isVigente(material?.validity),
      status: material?.status?.label,
      category: categoriesName,
      product: subCategoriesName,
      cadastrada: formatDate(material?.createdAt, 'DD/MM/YYYY'),
      atualizada: formatDate(material?.updatedAt, 'DD/MM/YYYY'),
      publicada: material.validity?.start
        ? formatDate(material.validity?.start, 'DD/MM/YYYY')
        : '-',
      criador: material?.author?.name,
      onEdit: () => history.push(routes.regulamentoEditar.mount(material?._id)),
      onRead: () => {
        if (material?.fileIds.length) {
          if (
            material?.files &&
            material?.files.length > 0 &&
            isImageExtension(material?.files[0].extension)
          ) {
            history.push(routes.imageViewer.mount(material?._id));
          } else {
            history.push(routes.pdfViewer.mount(material?._id));
          }
        } else {
          history.push(routes.conteudoMaterial.mount(material?._id));
        }
      },
      onDelete: () =>
        MaterialDeleteOne.fetch({
          variables: { filter: { _id: material?._id } },
          afterMutate: /^Material/
        }).then((ctx) => {
          message.success('Regulamento deletada com sucesso');
        })
    };
  });

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
      setFilterData(
        data?.filter((material) =>
          material.title.toLowerCase().includes(e.toLowerCase())
        )
      );
      // const byId = data?.filter(material => String(material?.id).includes(e))
    },
    [data]
  );

  const onChange = (e: any) => {
    if (e.length > 0) {
      setCreatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setShow(e);
    } else {
      setShow([]);
      setCreatedAt(undefined);
    }
  };

  const onChangeUpdatedAt = (e: any) => {
    if (e.length > 0) {
      setUpdatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
      setShowUpdatedAt(e);
    } else {
      setShowUpdatedAt([]);
      setUpdatedAt(undefined);
    }
  };

  return (
    <PageTemplate>
      <>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Regulamento' },
              { title: 'Listar' }
            ]}
          />
          <TitleDescription
            customIcon
            iconType="iconcheckList-copy"
            title="Regulamento"
            description="Cadastrar e consultar os NIICS/regulamentos"
          />
        </NotificationsWrapper>
        <FilterContainer>
          <Button to={routes.regulamentoCadastrar.path}>
            CADASTRAR REGULAMENTO
          </Button>
          <Select
            placeholder="Ordenar por:"
            options={order}
            onChange={(e) => setSort(e)}
            value={sort}
          />
          <Select
            placeholder="Vigente:"
            options={vigente}
            onChange={(e) => setVigenteFilter(e)}
            value={vigenteFilter}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format="DD/MM/YYYY"
            placeholder={['PERÍODO', '']}
            onChange={(e) => onChange(e)}
            value={show}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={'DD/MM/YYYY'}
            placeholder={['ATUALIZAÇÃO', '']}
            onChange={(e) => {
              onChangeUpdatedAt(e);
            }}
            value={showUpdatedAt}
          />
          <Search
            placeholder="Busca título"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e) => onSearch(e.target.value)}
          />

          {filterAuthor.render()}

          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>

        {(() => {
          if (!data) return <LoadingIndicator />;

          return (
            <Table
              columns={columns}
              dataSource={filterData.length > 0 ? filterData : data}
            />
          );
        })()}
      </>
    </PageTemplate>
  );
};

export type TRegulamentoListarProps = {};

const order = [
  { label: 'MAIS ANTIGO', value: SortFindManyMaterialInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyMaterialInput.IdDesc }
];
const vigente = [
  { label: 'TODOS', value: ValidityEnum.Any },
  { label: 'VIGENTE', value: ValidityEnum.Current },
  { label: 'NÃO VIGENTE', value: ValidityEnum.Expired }
];
