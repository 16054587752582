import { useClient } from '@digi-tim-19/components';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { EnumAnalyticsEvent, EnumAnalyticsParentKind } from '../../autogenerated/client/types';

export const ModalChallenge = ({ userId }: { userId: string }) => {
  const [open, setOpen] = useState<boolean>(false);
  const analitcsPageView = useClient('AnalyticsPageView');

  const userChallenges = useClient('UserChallengeFindOne', {
    variables: {
      filter: {
        userId,
        complete: false,
        status: 'pending',
        challenge: {
          action: EnumAnalyticsEvent.PageView,
          kind: EnumAnalyticsParentKind.ExternalPageView,
        },
      },
    },
    fetchOnMount: true,
    fragment: fragmentUserChallenge,
  });

  useEffect(() => {
    // if (userChallenges?.result) setOpen(true); // VALIDAR
  }, [userChallenges?.result]);

  const handleOk = () => {
    analitcsPageView.fetch({
      variables: {
        title: 'Link Externo',
        externalLink: userChallenges?.result?.challengeData?.externalLink,
        kind: EnumAnalyticsParentKind.ExternalPageView,
      } as any,
    });

    window.open(userChallenges?.result?.challengeData?.externalLink, '_blank');

    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal title="Um novo desafio foi lançado!" visible={open} onOk={handleOk} onCancel={handleCancel} okText="Aceitar">
      <p>Para concluir esse desafio clique em aceitar e acesse o conteúdo</p>
    </Modal>
  );
};

const fragmentUserChallenge = `
  userId
  complete
  challengeId,
  challengeData {
    action
    amountChips
    kind
    externalLink
  }
`;
